import { Locale } from './localeHelper';

export const Distance = {
  KM: 'km',
  MI: 'mi',
};

export const MILES_TO_METERS = 1609.34;

// Offset for rounding distances up to the nearest bigger value
export const DISTANCE_ROUNDING_OFFSET = 0.499;

export function metersToMiles(meters: number) {
  const miles = meters / MILES_TO_METERS;

  return Math.round(miles);
}

export function milesToMeters(miles: number) {
  const meters = miles * MILES_TO_METERS;

  return Math.round(meters);
}

export const metersToKilometers = (meters: number) => Math.round(meters / 1000);

export const kilometersToMeters = (kilometers: number) => Math.round(kilometers * 1000);

export const getLocaleDistanceUnits = (locale = Locale.DEFAULT_LOCALE) => {
  switch (locale) {
    case Locale.EN_US:
    case Locale.EN_GB:
      return Distance.MI;
    default:
      return Distance.KM;
  }
};

export const getLocaleDistanceUnitsName = (locale = Locale.DEFAULT_LOCALE) =>
  getLocaleDistanceUnits(locale) === Distance.MI ? 'miles' : Distance.KM;

export const getLocaleDistance = (distanceInMeters: number, locale = Locale.DEFAULT_LOCALE) => {
  switch (getLocaleDistanceUnits(locale)) {
    case Distance.MI:
      return metersToMiles(distanceInMeters);
    default:
      return metersToKilometers(distanceInMeters);
  }
};

export const convertLocaleDistanceArrayToMeters = (distances: number[], locale = Locale.DEFAULT_LOCALE) =>
  distances.map((distance) =>
    getLocaleDistanceUnits(locale) === Distance.MI
      ? milesToMeters(distance + DISTANCE_ROUNDING_OFFSET)
      : kilometersToMeters(distance + DISTANCE_ROUNDING_OFFSET),
  );
