import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type ValidateCartItemsStockQueryVariables = Types.Exact<{
  input: Types.ValidateCartItemsStockInput;
}>;

export type ValidateCartItemsStockQuery = {
  __typename?: 'Query';
  validateCartItemsStock: {
    __typename?: 'ValidateCartItemsStockResponse';
    success: boolean;
    itemAtps: Array<{ __typename?: 'ItemAtp'; atp: number; lineItem: { __typename?: 'LineItem'; id: string } }>;
  };
};

export const ValidateCartItemsStockDocument = gql`
  query validateCartItemsStock($input: ValidateCartItemsStockInput!) {
    validateCartItemsStock(input: $input) {
      itemAtps {
        atp
        lineItem {
          id
        }
      }
      success
    }
  }
`;

/**
 * __useValidateCartItemsStockQuery__
 *
 * To run a query within a React component, call `useValidateCartItemsStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateCartItemsStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateCartItemsStockQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateCartItemsStockQuery(
  baseOptions: Apollo.QueryHookOptions<ValidateCartItemsStockQuery, ValidateCartItemsStockQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateCartItemsStockQuery, ValidateCartItemsStockQueryVariables>(
    ValidateCartItemsStockDocument,
    options,
  );
}
export function useValidateCartItemsStockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidateCartItemsStockQuery, ValidateCartItemsStockQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateCartItemsStockQuery, ValidateCartItemsStockQueryVariables>(
    ValidateCartItemsStockDocument,
    options,
  );
}
export type ValidateCartItemsStockQueryHookResult = ReturnType<typeof useValidateCartItemsStockQuery>;
export type ValidateCartItemsStockLazyQueryHookResult = ReturnType<typeof useValidateCartItemsStockLazyQuery>;
export type ValidateCartItemsStockQueryResult = Apollo.QueryResult<
  ValidateCartItemsStockQuery,
  ValidateCartItemsStockQueryVariables
>;
