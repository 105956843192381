import { useState } from 'react';
import { fetchIsBadPassword } from '../services/apiRoute/badPassword';

export function useIsBadPasswords() {
  const [isBadPassword, setIsBadPassword] = useState(false);

  async function checkPassword(password: string) {
    if (!password || password.length < 10) {
      setIsBadPassword(false);
      return false;
    }
    try {
      const result = await fetchIsBadPassword(password);
      setIsBadPassword(result);
      return !!result;
    } catch (e) {
      console.error('Bad password API', e);
      return true;
    }
  }

  return { isBadPassword, checkPassword };
}
