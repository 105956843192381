import { useMemo } from 'react';
import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { useTranslation } from 'next-i18next';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { sendApplyCategoryFilterEvent } from '../../../../utils/gtm/events/apply_category_filter/sendApplyCategoryFilterEvent';
import { sendRemoveCategoryFilterEvent } from '../../../../utils/gtm/events/remove_category_filter/sendRemoveCategoryFilterEvent';
import FilterAccordion from '../FilterAccordion';
import { FilterItem, FilterList, SizeHeading } from './SizeFilter.styles';

export type SizeFilterProps = {
  items: RefinementListItem[];
  refine: (value: string) => void;
  productCount: number;
  sendEvent: (eventType: string, facetValue: string, eventName?: string) => void;
};

enum AgeGroup {
  ADULT = 'adult',
  JUNIOR = 'junior',
  INFANT = 'infant',
}

type SizeType = {
  label: string;
  value: string;
  highlighted?: string;
  count: number;
  isRefined: boolean;
};

type SizeListItemProps = {
  sizes: SizeType[];
  label: string;
  handleOnClick: (item: SizeType, label: string, ageGroup: string) => () => void;
};

const createSizesArray = (items: RefinementListItem[], ageGroupKey?: AgeGroup): SizeType[] =>
  items
    .filter((item) => {
      const ageGroup = item.label.split('_')[1] || '';
      return ageGroupKey ? ageGroupKey === ageGroup : !ageGroup;
    })
    .map((item) => ({
      ...item,
      label: item.label.split('_')[0],
    }))
    .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));

function SizeItem({ item, handleOnClick }: { item: RefinementListItem; handleOnClick: () => void }) {
  return (
    <FilterItem
      role="button"
      aria-pressed={item.isRefined}
      key={item.value}
      $isActive={item.isRefined}
      type="button"
      onClick={handleOnClick}
      data-testid="sizeFilter"
    >
      {item.label}
    </FilterItem>
  );
}
function SizeListItem({ sizes, label, handleOnClick }: SizeListItemProps) {
  const { t } = useTranslation(['lib-global-common']);

  const heading = t(`filter.menu.filter.size.${label}`);
  const ageGroup = `${capitalizeFirstLetter(label)} size`;
  if (sizes.length === 0) return null;

  return (
    <>
      <SizeHeading>{heading}</SizeHeading>
      <FilterList label={heading}>
        {sizes.map((item) => (
          <SizeItem item={item} handleOnClick={handleOnClick(item, 'Size', ageGroup)} key={item.value} />
        ))}
      </FilterList>
    </>
  );
}

function SizeList({ items, refine, sendEvent }: Omit<SizeFilterProps, 'productCount'>) {
  const { t } = useTranslation(['lib-global-common']);

  const { adultSizes, juniorSizes, infantSizes, noAgeGroupSizes } = useMemo(
    () => ({
      adultSizes: createSizesArray(items, AgeGroup.ADULT),
      juniorSizes: createSizesArray(items, AgeGroup.JUNIOR),
      infantSizes: createSizesArray(items, AgeGroup.INFANT),
      noAgeGroupSizes: createSizesArray(items),
    }),
    [items],
  );
  const handleOnClick = (item: SizeType, label: string, ageGroup: string) => () => {
    const { value, isRefined } = item;
    refine(value);
    if (!isRefined) {
      sendApplyCategoryFilterEvent({
        filter_category: `${label}`,
        filter_value: `${ageGroup} ${value.split('_')[0]}`,
      });
    } else {
      sendRemoveCategoryFilterEvent({
        filter_category: `${label}`,
        filter_value: `${ageGroup} ${value.split('_')[0]}`,
      });
    }
  };

  return (
    <>
      <SizeListItem sizes={adultSizes} label={AgeGroup.ADULT} handleOnClick={handleOnClick} />
      <SizeListItem sizes={infantSizes} label={AgeGroup.INFANT} handleOnClick={handleOnClick} />
      <SizeListItem sizes={juniorSizes} label={AgeGroup.JUNIOR} handleOnClick={handleOnClick} />
      <FilterList label={t('filter.menu.filter.size')}>
        {noAgeGroupSizes.map((item) => (
          <SizeItem item={item} handleOnClick={handleOnClick(item, 'Size', 'Size')} key={item.value} />
        ))}
      </FilterList>
    </>
  );
}
export function SizeFilter({ items, refine, sendEvent, productCount }: SizeFilterProps) {
  const { t } = useTranslation(['lib-global-common']);

  if (items.length === 0 || (items.length === 1 && items[0].count === productCount)) return null;
  const isOpen = !!items.find((item) => item.isRefined);

  return (
    <FilterAccordion
      isOpen={isOpen}
      triggerLabel={t('filter.menu.filter.size')}
      content={<SizeList items={items} refine={refine} sendEvent={sendEvent} />}
      testId="sizeFilterAccordion"
    />
  );
}

export default SizeFilter;
