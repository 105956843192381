import styled from 'styled-components';
import { colours } from '../../../stylings';

export const Color = styled.span<{ $color?: string }>`
  background-color: ${({ $color }) => $color || `${colours.WHITE}`};
  border: ${({ $color }) => $color === `${colours.WHITE}` && `0.1px solid ${colours.BLACK}`};
  border-radius: 50%;
  display: inline-block;
  min-height: 24px;
  min-width: 24px;
`;

export const Multi = styled.span`
  background-color: rgba(0, 0, 0, 0);
  background-image: repeating-conic-gradient(
    from 0deg at center center,
    rgba(0, 165, 223, 1) 0%,
    rgba(62, 20, 123, 1) 20%,
    rgba(226, 0, 121, 1) 40%,
    rgba(223, 19, 44, 1) 60%,
    rgba(243, 239, 21, 1) 80%,
    rgba(0, 152, 71, 1) 100%
  );

  background-position: 0% 0%;

  background-repeat: repeat;

  background-size: 24px 24px;
  border-radius: 50%;
  display: inline-block;
  min-height: 24px;
  min-width: 24px;
`;

export const Metallic = styled.span`
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(
    to right bottom,
    rgba(130, 130, 130, 1) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(224, 173, 2, 1) 99%
  );

  background-position: 0% 0%;

  background-repeat: repeat;

  background-size: 24px 24px;
  border-radius: 50%;
  display: inline-block;
  min-height: 24px;
  min-width: 24px;
`;

export const ColorLabel = styled.span`
  text-transform: capitalize;
`;
