import type { ReactNode } from 'react';
import styled from 'styled-components';
import { BlueButton, WhiteButton } from '../../baseComponents/button';
import { DialogContent, DialogOverlay, DialogPortal, DialogRoot } from '../../baseComponents/dialog';
import { TypographyStyles, colours, media, spacing, zMobileHamburgerMenu } from '../../stylings';

const S = {
  DialogOverlay: styled(DialogOverlay)`
    background-color: rgba(0, 0, 0, 0.4);
    inset: 0;
    position: fixed;
    z-index: ${zMobileHamburgerMenu};
  `,
  DialogContentWrapper: styled(DialogContent)`
    align-items: center;
    background-color: ${colours.WHITE};
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXS};
    justify-content: center;
    left: 50%;
    overflow-y: auto;
    padding: ${spacing.S};
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    z-index: ${zMobileHamburgerMenu};
    @media ${media.greaterThan('lg')} {
      gap: ${spacing.XXS};
      padding: ${spacing.L};
      width: 400px;
    }
  `,
  TextInfo: styled.h4`
    ${TypographyStyles.Headings.H4SemiBold}
    color: ${colours.BLACK};
    margin: 0;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
  `,
  Description: styled.p`
    ${TypographyStyles.Body.Small.Regular}
    color: ${colours.BLACK};
    margin: 0;
    padding: 0;
    width: 100%;
  `,
  ConfirmButton: styled(BlueButton)`
    margin: 0;
    text-transform: uppercase;
    width: 100%;
  `,
  CancelButton: styled(WhiteButton)`
    text-transform: uppercase;
    width: 100%;
  `,
  MessageWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXS};
  `,
};

type ConfirmationModalProps = {
  isOverLayOpen: boolean;
  handleOpenOverlay: () => void;
  message: string;
  description: string;
  confirmationLabel: ReactNode;
  cancelLabel: ReactNode;
  onConfirm: () => void;
  cancelDisabled?: boolean;
  closeOnConfirm?: boolean;
  onCancel?: () => void;
  children?: ReactNode;
};

export function ConfirmationModal({
  isOverLayOpen,
  handleOpenOverlay,
  message,
  description,
  onCancel,
  onConfirm,
  confirmationLabel,
  cancelLabel,
  cancelDisabled = false,
  closeOnConfirm = true,
  children,
}: ConfirmationModalProps) {
  const handleOnCancel = () => {
    onCancel?.();
    handleOpenOverlay();
  };

  const handleOnConfirm = () => {
    onConfirm?.();
    if (closeOnConfirm) {
      handleOpenOverlay();
    }
  };

  return (
    <DialogRoot open={isOverLayOpen} onOpenChange={handleOpenOverlay}>
      <DialogPortal>
        <S.DialogOverlay />
        <S.DialogContentWrapper data-testid="confirmationModal">
          <S.MessageWrapper role="status">
            <S.TextInfo>{message}</S.TextInfo>
            <S.Description>{description}</S.Description>
            {children}
          </S.MessageWrapper>

          <S.ConfirmButton onClick={handleOnConfirm} data-testid="confirmModalAction">
            {confirmationLabel}
          </S.ConfirmButton>
          <S.CancelButton onClick={handleOnCancel} disabled={cancelDisabled} data-testid="cancelModalAction">
            {cancelLabel}
          </S.CancelButton>
        </S.DialogContentWrapper>
      </DialogPortal>
    </DialogRoot>
  );
}
