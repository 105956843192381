import { useCallback } from 'react';
import { usePaymentContext } from '../context/paymentContext';
import type { CancelOrderResponse } from '../types/payment';

export function useCancelGiftCardOrder() {
  const { giftCardOrder, adyenCheckout, setGiftCardOrder } = usePaymentContext();

  const cancelGiftCardOrder = useCallback(async () => {
    if (giftCardOrder) {
      try {
        const cancelResult = (await adyenCheckout?.session.cancelOrder({
          order: giftCardOrder,
        })) as CancelOrderResponse;

        // eslint-disable-next-line no-console
        console.info('Cancelled previous checkout session', cancelResult);

        if (cancelResult?.resultCode === 'Received') {
          setGiftCardOrder(null);
        }
      } catch (e) {
        console.error('There was a problem cancelling your previous gift card payment', e);
      }
    }
  }, [adyenCheckout?.session, giftCardOrder, setGiftCardOrder]);

  return { cancelGiftCardOrder };
}
