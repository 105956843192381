import type { ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import singletonRouter, { useRouter } from 'next/router';
import { history } from 'instantsearch.js/es/lib/routers';
import { InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch';
import type { InstantSearchServerState } from 'react-instantsearch';
// react-instantsearch-hooks-router-nextjs deprecated, change to react-instantsearch-router-nextjs
import { createInstantSearchRouterNext } from 'react-instantsearch-hooks-router-nextjs';
import { getAlgoliaProductIndex, searchClient } from '../services';
import { createFacetAttributeNames, createInitialRefinementList, isAllowedAlgoliaAttribute } from '../utils';
import { checkConsent } from '../utils/checkConsent';
import { useAfterCookieBannerContext } from './afterCookieBannerContext';
import { InstantSearchProvider } from './instantSearchContext';
import { useLocaleContext } from './localeContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createRouteToStateRefinementList = (routeState: any, locale: string) => {
  let routeStateRefinementList = {};
  const attributeNames = createFacetAttributeNames(locale);
  attributeNames.forEach((attributeName) => {
    routeStateRefinementList = {
      ...routeStateRefinementList,
      [attributeName]: routeState[attributeName],
    };
  });

  return routeStateRefinementList;
};
const routing = (indexName: string, locale: string, serverUrl?: string) => {
  const router = serverUrl
    ? createInstantSearchRouterNext({
        serverUrl,
        singletonRouter,
      })
    : history();
  return {
    router,
    stateMapping: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stateToRoute(uiState: any) {
        const indexUiState = uiState[indexName];
        const minPrice = indexUiState.range?.['price.centAmount']?.split(':')[0];
        const maxPrice = indexUiState.range?.['price.centAmount']?.split(':')[1];

        const routerQuery = router.read();

        const filterParams = {
          query: indexUiState.query,
          minPrice: minPrice || undefined,
          maxPrice: maxPrice || undefined,
          ...indexUiState.refinementList,
          sortBy: indexUiState.sortBy,
          page: indexUiState.page,
          pageSize: indexUiState.configure?.hitsPerPage,
        };

        // parameters that are not defined as filters, e.g. marketing params
        const customParams = Object.fromEntries(
          Object.entries(routerQuery).filter(
            ([key]) => !isAllowedAlgoliaAttribute(key) && !Object.keys(filterParams).includes(key),
          ),
        );

        return {
          ...filterParams,
          ...customParams,
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      routeToState(routeState: any) {
        return {
          [indexName]: {
            query: routeState.query,
            refinementList: createRouteToStateRefinementList(routeState, locale),
            page: routeState.page,
            sortBy: routeState.sortBy,
            range: {
              'price.centAmount': `${routeState.minPrice ?? ''}:${routeState.maxPrice ?? ''}`,
            },
            configure: {
              hitsPerPage: routeState.pageSize,
            },
          },
        };
      },
    },
  };
};

type SearchContextProviderProps = {
  children: ReactNode;
  serverState?: InstantSearchServerState;
  serverUrl?: string;
};

export function SearchContextProvider({ children, serverState, serverUrl }: SearchContextProviderProps) {
  const { locale } = useLocaleContext();
  const algoliaIndex = getAlgoliaProductIndex(locale);
  const router = useRouter();
  const { query = null } = { ...router };

  const { promptRedirectAfterCookie } = useAfterCookieBannerContext();
  const [hasConsent, setHasConsent] = useState(false);
  const initialState = useMemo(
    () => ({
      [algoliaIndex]: {
        query: query && Array.isArray(query?.query) ? query.query?.[0] : query?.query ?? '',
        refinementList: createInitialRefinementList(query, locale),
        sortBy: query && Array.isArray(query?.sortBy) ? query.sortBy?.[0] : query?.sortBy ?? '',
        range: {
          'price.centAmount': `${query?.minPrice}:${query?.maxPrice}`,
        },
        page: query?.page,
        configure: {
          hitsPerPage: query?.pageSize,
        },
      },
    }),
    [algoliaIndex, locale, query],
  );

  useEffect(() => {
    setHasConsent(checkConsent());
  }, [promptRedirectAfterCookie]);

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        searchClient={searchClient}
        indexName={algoliaIndex}
        initialUiState={initialState}
        routing={routing(algoliaIndex, locale, serverUrl)}
        insights
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <InstantSearchProvider>{children}</InstantSearchProvider>
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
}
