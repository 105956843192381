import styled, { css } from 'styled-components';
import { Fieldset } from '../../../../baseComponents/inputs/Fieldset';
import { TypographyStyles, colours, media, spacing } from '../../../../stylings';

export const FilterList = styled(Fieldset)`
  display: grid;
  gap: ${spacing.XXXS};
  grid-template-columns: repeat(4, 1fr);
  margin: ${spacing.XS} 0 ${spacing.S};
  padding: 0;
  width: 262px;
  @media ${media.greaterThan('lg')} {
    gap: ${spacing.XXXS};
    margin: ${spacing.XS} 0 ${spacing.M};
    width: 302px;
  }
`;
export const FilterItem = styled.button<{ $isActive?: boolean }>`
  align-items: center;
  background-color: ${colours.WHITE};
  border: 1px solid ${colours.GREY};
  color: ${colours.BLACK};
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: center;
  padding: ${spacing.XXS} 10px;
  width: 59.5px;
  ${TypographyStyles.Body.Small.Regular}

  @media ${media.greaterThan('lg')} {
    height: 36px;
    padding: ${spacing.XXXXS} 10px;
    width: 69.5px;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${colours.LIGHT_BLUE};
      border: 1px solid ${colours.NAVY_BLUE};
      font-weight: 600;
    `}

  &:hover,&:focus {
    border: 2px solid ${colours.NAVY_BLUE};
  }
`;

export const SizeHeading = styled.span`
  ${TypographyStyles.Body.Tiny.Regular}
  height: 22px;
  width: 302px;
  @media ${media.greaterThan('lg')} {
    width: 262px;
  }
`;
