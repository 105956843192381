import { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { usePopper } from 'react-popper';
import { useOutsideClick } from 'rooks';
import styled from 'styled-components';
import { InfoSmall } from '../../icons';
import { TypographyStyles, colours, media, zCheckoutAdyenTooltip, zCheckoutAdyenTooltipBubble } from '../../stylings';

const S = {
  Container: styled.div`
    margin: 14px 18px 17px 14px;
    position: absolute;
    right: 0;
    top: -2px;

    @media ${media.greaterThan('lg')} {
      margin-top: 15px;
      top: 0;
    }
  `,
  GiftcardBalanceTooltipIcon: styled.button<{ $isInputError?: boolean }>`
    background: none;
    border: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;

    & path {
      fill: ${({ $isInputError }) => ($isInputError ? colours.RED : colours.BLACK)};
    }
  `,
  GiftCardBalanceTooltipArrow: styled.div`
    margin-left: -20px;
    position: absolute;
    z-index: ${zCheckoutAdyenTooltip};

    &:before,
    &:after {
      border: 20px solid transparent;
      bottom: 0;
      content: '';
      position: absolute;
    }

    &:before {
      border-bottom: 30px solid ${colours.WHITE};
      border-top: 0;
      bottom: 0;
    }
  `,
  GiftCardBalanceTooltipBubble: styled.div`
    background: ${colours.WHITE};
    filter: drop-shadow(0 0 1px ${colours.BLACK});
    padding: 20px;
    top: 100%;
    width: 230px;
    z-index: ${zCheckoutAdyenTooltipBubble};

    &[data-popper-placement^='top'] > .arrow {
      bottom: 10px;
    }

    &[data-popper-placement^='bottom'] > .arrow {
      top: 10px;
    }

    &[data-popper-placement^='left'] > .arrow {
      right: 30px;
    }

    &[data-popper-placement^='right'] > .arrow {
      left: 10px;
    }

    @media ${media.greaterThan('lg')} {
      width: 300px;
    }
  `,
  GiftCardBalanceTooltipBodyText: styled.p`
    color: ${colours.BLACK};
    ${TypographyStyles.Body.Tiny.Regular}
  `,
};

export function GiftCardBalanceTooltip({ isInputError }: { isInputError?: boolean }) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const giftCardTooltipRef = useRef<HTMLButtonElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { t } = useTranslation(['lib-products-common']);
  const { styles, attributes } = usePopper(giftCardTooltipRef.current, containerRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-start'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [-30, 25],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: { left: 5, right: 15 },
        },
      },
    ],
  });

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  useOutsideClick(containerRef, () => setIsOpen(false));

  return (
    <S.Container role="tooltip">
      <S.GiftcardBalanceTooltipIcon
        onClick={handleClick}
        ref={giftCardTooltipRef}
        $isInputError={isInputError}
        aria-label={t('checkout.toggle.tooltip')}
        type="button"
      >
        <InfoSmall />
      </S.GiftcardBalanceTooltipIcon>
      {isOpen && (
        <S.GiftCardBalanceTooltipBubble ref={containerRef} style={styles.popper} {...attributes.popper}>
          <S.GiftCardBalanceTooltipArrow ref={setArrowRef} style={styles.arrow} className="arrow" />
          <S.GiftCardBalanceTooltipBodyText>{t('giftcard.tooltip')}</S.GiftCardBalanceTooltipBodyText>
        </S.GiftCardBalanceTooltipBubble>
      )}
    </S.Container>
  );
}
