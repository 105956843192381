import styled from 'styled-components';
import { TypographyStyles, colours, media } from '../../stylings';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 328px;
  @media ${media.greaterThan('lg')} {
    max-width: 408px;
  }

  a {
    ${TypographyStyles.Body.Tiny.Medium}
    color: ${colours.NOBEL_GREY};
  }
`;
