import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type UserData = {
  status: string;
  customer_number?: string | null;
  customer_email?: string;
};

export const sendUserDataLoadedEvent = (user: UserData) => {
  sendDataLayerEvent(EventNamesType.user_data_loaded, { user });
};
