import { useCmsSlotsContext } from '../../context/cmsSlotsContext';
import type { CmsSlotsDeliveryKeys } from '../../utils/amplience';
import { HandlebarTemplate } from '../handlebarTemplate';

type CmsSlotProps = {
  deliveryKey: CmsSlotsDeliveryKeys;
};

export function CmsSlot({ deliveryKey }: CmsSlotProps) {
  const { getSlotComponent } = useCmsSlotsContext();
  const component = getSlotComponent(deliveryKey);

  if (!component) return null;

  return <HandlebarTemplate input={component.input} template={component.template} />;
}
