import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type OptOutMutationVariables = Types.Exact<{
  input: Types.OptOutMarketingPreferencesInput;
}>;

export type OptOutMutation = { __typename?: 'Mutation'; optOut: boolean };

export const OptOutDocument = gql`
  mutation optOut($input: OptOutMarketingPreferencesInput!) {
    optOut(input: $input)
  }
`;
export type OptOutMutationFn = Apollo.MutationFunction<OptOutMutation, OptOutMutationVariables>;

/**
 * __useOptOutMutation__
 *
 * To run a mutation, you first call `useOptOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optOutMutation, { data, loading, error }] = useOptOutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptOutMutation(baseOptions?: Apollo.MutationHookOptions<OptOutMutation, OptOutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OptOutMutation, OptOutMutationVariables>(OptOutDocument, options);
}
export type OptOutMutationHookResult = ReturnType<typeof useOptOutMutation>;
export type OptOutMutationResult = Apollo.MutationResult<OptOutMutation>;
export type OptOutMutationOptions = Apollo.BaseMutationOptions<OptOutMutation, OptOutMutationVariables>;
