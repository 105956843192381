import { defer } from '../../defer';
import { logger } from '../../logger';
import { newRelicAvailabilityChecker } from '../../newrelic';
import EventNamesType from '../events/eventNamesType';

/**
 * Function send insights events to NewRelic and GTM dataLayer
 * @param eventName - name of event. All available event names are stored in EventNamesType
 * @param data - custom object of event data needed to be sent to insights
 * @param clearEcomData - boolean to check if `clear_ecom_data` event should be sent. Default true
 * @param extras - used to pass any additional data to which should be different from original data object.
 */
export function sendDataLayerEvent<T>(
  eventName: string,
  data?: T,
  clearEcomData = true,
  extras: Record<string, unknown> | undefined = undefined,
) {
  const isDataLayerValid = !(typeof window === 'undefined' || !Array.isArray(window.dataLayer));
  // page_loaded event should be fired first after the page is loaded, so other events must be delayed
  const eventTimeout = +(eventName !== EventNamesType.page_loaded) * 100;

  newRelicAvailabilityChecker(() =>
    // send data to New Relic
    window.newrelic.addPageAction(eventName, {
      ...data,
      noop: isDataLayerValid,
      // if there is `cartId` added to extras, send it to NewRelic
      ...(extras?.cartId ? { cart_id: extras.cartId } : null),
    }),
  );

  if (!isDataLayerValid) {
    return;
  }

  try {
    defer(() => {
      if (clearEcomData && 'ecommerce' in (data || {})) {
        window.dataLayer.push({
          event: 'clear_ecom_data',
          ecommerce: null,
        });
      }

      if ('custom_data' in (data || {})) {
        window.dataLayer.push({
          event: 'clear_custom_data',
          custom_data: null,
        });
      }

      window.dataLayer.push({
        event: eventName,
        ...data,
      });
    }, eventTimeout);
  } catch (error) {
    logger.error(`Add ${eventName} in Data Layer Error`, error);
  }
}
