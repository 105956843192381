import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { storeKeyMapper } from '@shared/utils/src/ct';
import { type DeliveryOption, DeliveryOptionsScope } from '../codegen/types';
import { useCartContext, useLocaleContext } from '../context';
import { useGetDeliveryOptionsForProductsQuery } from '../graphqlDocument/deliveryMethods/getDeliveryOptionsForProducts.generated';
import { getCtLocale } from '../utils';
import { useStar2 } from './useStar2';

export function useGetDeliveryOptionsForProductsHook() {
  const { locale } = useLocaleContext();
  const { miniCart, cartId, anonymousId, shouldUpdateDeliveryOptions, setShouldUpdateDeliveryOptions, isCartLoading } =
    useCartContext();
  const { lineItems } = { ...miniCart };
  const { asPath } = useRouter();
  const { isStar2 } = useStar2();

  const isPaymentPage = asPath.includes('/checkout/payment');
  const isConfirmationPage = asPath.includes('/checkout/confirmation');

  const skus = lineItems?.flatMap((lineItem) => lineItem.variant.sku ?? []);
  const destinationCountry = isStar2 ? locale?.split('-')[1].toUpperCase() : false;

  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOption[]>([]);
  const { loading: isDeliveryOptionsLoading, refetch: getDeliveryOptions } = useGetDeliveryOptionsForProductsQuery({
    variables: {
      input: {
        cartId,
        anonymousId,
        storeKey: storeKeyMapper(locale),
        locale: getCtLocale(locale),
        skus,
        inclusive: true,
        scope: DeliveryOptionsScope.BASKET,
        ...(destinationCountry ? { destinationCountry } : {}),
      },
    },
    onCompleted: ({ getDeliveryOptionsForProducts }) => {
      const nonNullOptions = getDeliveryOptionsForProducts?.deliveryOptions.flatMap((option) => option ?? []);

      const sortedDeliveryOptions = nonNullOptions?.slice()?.sort((a, b) => a.deliveryPriority - b.deliveryPriority);

      setDeliveryOptions(sortedDeliveryOptions);
      setShouldUpdateDeliveryOptions(false);
    },
    fetchPolicy: 'network-only',
    skip:
      (!!deliveryOptions && deliveryOptions.length > 0 && !shouldUpdateDeliveryOptions) ||
      skus?.length < 1 ||
      isCartLoading ||
      isPaymentPage ||
      isConfirmationPage,
  });

  useEffect(() => {
    if (shouldUpdateDeliveryOptions) {
      getDeliveryOptions();
    }
  }, [getDeliveryOptions, shouldUpdateDeliveryOptions]);

  return {
    deliveryOptions,
    isDeliveryOptionsLoading,
    isFreeDeliveryEligible: deliveryOptions?.some((deliveryOption) => deliveryOption.isFreeEligible),
  };
}
