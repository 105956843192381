import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type OptDownMutationVariables = Types.Exact<{
  input: Types.OptDownMarketingPreferencesInput;
}>;

export type OptDownMutation = { __typename?: 'Mutation'; optDown: boolean };

export const OptDownDocument = gql`
  mutation optDown($input: OptDownMarketingPreferencesInput!) {
    optDown(input: $input)
  }
`;
export type OptDownMutationFn = Apollo.MutationFunction<OptDownMutation, OptDownMutationVariables>;

/**
 * __useOptDownMutation__
 *
 * To run a mutation, you first call `useOptDownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptDownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optDownMutation, { data, loading, error }] = useOptDownMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptDownMutation(
  baseOptions?: Apollo.MutationHookOptions<OptDownMutation, OptDownMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OptDownMutation, OptDownMutationVariables>(OptDownDocument, options);
}
export type OptDownMutationHookResult = ReturnType<typeof useOptDownMutation>;
export type OptDownMutationResult = Apollo.MutationResult<OptDownMutation>;
export type OptDownMutationOptions = Apollo.BaseMutationOptions<OptDownMutation, OptDownMutationVariables>;
