import type { Dispatch, MouseEvent, SetStateAction } from 'react';
import { useEffect, useReducer } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { storeKeyMapper } from '@shared/utils/src/ct';
import type { Cart, DiscountDescription, LineItem } from '../../../codegen/types';
import { DiscountType } from '../../../codegen/types';
import { useCartContext, useLocaleContext, useOverlayContext, useStaticContext } from '../../../context';
import { useRemoveItemMutation } from '../../../graphqlDocument/cart/removeItem.generated';
import { useLoadingCursor, useMediaMatch, useResizeScrollSize } from '../../../hooks';
import { useItemDetailsHelpers } from '../../../hooks/useItemDetailsHelpers';
import { useNRBASession } from '../../../hooks/useNRBASession';
import { Delete } from '../../../icons';
import { ImagePartial } from '../../../partials';
import { media } from '../../../stylings';
import { getCtLocale } from '../../../utils';
import { sendRemoveFromCartFromProductLineItemEvent } from '../../../utils/gtm/events/remove_from_cart/sendRemoveFromCartFromProductLineItemEvent';
import { FinalSale } from '../../finalSale/FinalSale';
import { NotifyMeForm } from '../../notifyMe';
import { ProductItemDetail } from './ProductItemDetail';
import {
  DeleteButton,
  DetailsWrapper,
  Discount,
  DiscountWrapper,
  FinalSaleContainer,
  GiftCardImageContainer,
  IconWrapper,
  ImageContentWrapper,
  LineItemWrapper,
  ProductDetailsBlock,
  ProductDetailsBlockWrapper,
  ProductName,
  ProductNameWrapper,
  SkuCapError,
  StockBanner,
} from './ProductLineItem.styles';
import { ProductLineItemActionType, productLineItemReducer } from './ProductLineItem.utils';
import Images, { ProductLineItemType } from './ProductLineItemImages';
import type { LineItemStatus } from './ProductLineItemList';
import { ValidateStockStatusType } from './ProductLineItemList';
import LineItemPrices from './ProductLineItemPrices';

export enum BadgeType {
  SALE = 'sale',
}

type ProductLineItemProps = {
  lineItem: LineItem;
  type: ProductLineItemType.MULTI_IMAGE | ProductLineItemType.SINGLE_IMAGE;
  setLineItemStatus: Dispatch<SetStateAction<LineItemStatus[]>>;
  validateAndUpdateStockStatus?: ValidateStockStatusType;
  isStar2?: boolean;
  handlePDPLinkFromCheckout?: (pdpLink: string) => void;
};

export function ProductLineItem({
  lineItem,
  type,
  setLineItemStatus,
  validateAndUpdateStockStatus,
  isStar2 = false,
  handlePDPLinkFromCheckout,
}: ProductLineItemProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { isGiftCard } = useItemDetailsHelpers();
  const router = useRouter();
  const { locale } = useLocaleContext();
  const { cartId, anonymousId, setHasLastItemDeleted, setMiniCart, setShouldUpdateDeliveryOptions } = useCartContext();
  const { storeData } = useStaticContext();
  const { setIsMiniCartOpen } = useOverlayContext();
  const { skuCap } = { ...storeData };
  const {
    name,
    size,
    fit,
    fitLabel,
    colour,
    quantity,
    imageURLs,
    price,
    id,
    discounts,
    discountsDescriptions = [],
    isLastItem,
    badges = [],
    giftCardOccasion,
    giftCardMessage,
    giftCardRecipientEmail,
    variant,
    availableQuantity,
    backInStockNotificationsEnabled,
    finalSale,
    productKey,
    productSlug,
  } = { ...lineItem };
  const { miniCart } = useCartContext();

  const [{ lineItemQuantity, isSkuCapReached, adjustedQuantity, hasValidated, hasGenericError }, dispatch] = useReducer(
    productLineItemReducer,
    {
      lineItemQuantity: quantity,
      isSkuCapReached: skuCap ? quantity >= skuCap : false,
      adjustedQuantity: 0,
      hasValidated: false,
      hasGenericError: false,
    },
  );
  const nrbaContextHeader = useNRBASession();

  const [removeItemMutation, { loading: isRemoveItemLoading }] = useRemoveItemMutation(nrbaContextHeader);
  const { scrollHeight } = useResizeScrollSize({});

  const shouldDetailsColumn = scrollHeight >= 26;
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const saleBadgeCode = badges?.find((badge) => badge.type === BadgeType.SALE)?.code || '';
  const tickIconUrl = 'success-round-tick-green';

  const productUrlSlug = productSlug ? `/${productSlug}` : '';
  const pdpLink = `${productUrlSlug}/${productKey}-p`;

  const isOutOfStock =
    validateAndUpdateStockStatus === ValidateStockStatusType.OUT_OF_STOCK ||
    (!!availableQuantity && availableQuantity < 0);
  const showNotifyMeForm = type === ProductLineItemType.MULTI_IMAGE && backInStockNotificationsEnabled && isOutOfStock;

  const isBasketPage = router.asPath.includes('/basket') || router.asPath.includes('/cart');

  const productDetailsMapping = [
    [
      size
        ? {
            label: t('cart.line.item.size'),
            value: size,
          }
        : {
            label: t('cart.line.item.card'),
            value: giftCardOccasion,
          },
      colour
        ? {
            label: t('cart.line.item.colour'),
            value: colour,
          }
        : {
            label:
              type === ProductLineItemType.MULTI_IMAGE && isDesktop
                ? t('cart.line.item.message')
                : t('cart.line.item.send.to'),
            value: type === ProductLineItemType.MULTI_IMAGE && isDesktop ? giftCardMessage : giftCardRecipientEmail,
          },
    ],
    [
      fit
        ? {
            label: t('cart.line.item.width'),
            value: fitLabel ?? fit,
          }
        : {
            label:
              type === ProductLineItemType.MULTI_IMAGE && isDesktop
                ? t('cart.line.item.send.to')
                : t('cart.line.item.message'),
            value: type === ProductLineItemType.MULTI_IMAGE && isDesktop ? giftCardRecipientEmail : giftCardMessage,
          },
      {
        label: t('cart.line.item.quantity'),
        value: lineItemQuantity,
      },
    ],
  ];

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    setIsMiniCartOpen(false);
    router.push(pdpLink);
  };

  const onDeleteItem = (lineItemId: string) => async () => {
    const input = {
      cartId,
      anonymousId,
      storeKey: storeKeyMapper(locale),
      locale: getCtLocale(locale),
      lineItemId,
    };

    setHasLastItemDeleted(true);

    await removeItemMutation({
      variables: {
        input,
      },
      onCompleted: ({ removeItem: removedItem }) => {
        setMiniCart(removedItem as Cart);
        setShouldUpdateDeliveryOptions(true);

        setLineItemStatus((prev) =>
          prev.map((item) => (item.lineItem.id === lineItemId ? { ...item, isDeleted: true } : item)),
        );

        sendRemoveFromCartFromProductLineItemEvent(miniCart, lineItemId, removedItem as Cart);
        if (isStar2) {
          router.reload();
        }
      },
      onError: () => {
        setHasLastItemDeleted(false);
      },
    });
  };

  useLoadingCursor(isRemoveItemLoading);

  useEffect(() => {
    dispatch({
      type: ProductLineItemActionType.SET_LINE_ITEM_QUANTITY,
      value: quantity,
    });

    if (hasValidated) {
      dispatch({
        type: ProductLineItemActionType.SET_ADJUSTED_QUANTITY,
        adjustedQuantity: quantity,
      });
      dispatch({
        type: ProductLineItemActionType.SET_HAS_VALIDATED,
        hasValidated: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  useEffect(() => {
    dispatch({
      type: ProductLineItemActionType.SET_HAS_VALIDATED,
      hasValidated: true,
    });
  }, [validateAndUpdateStockStatus]);

  return (
    <>
      <LineItemWrapper $type={type} data-testid="minicartLineItem">
        <ImageContentWrapper>
          {!size || !colour || !fit || !name ? (
            <GiftCardImageContainer $type={type} onClick={handleClick}>
              <ImagePartial
                src={imageURLs[0]}
                layout="fill"
                objectFit="contain"
                altText={`${t('cart.line.item.link.text', { item: name })}`}
                role="link"
                tabIndex={0}
              />
            </GiftCardImageContainer>
          ) : (
            <Images
              type={type}
              imageURLs={imageURLs}
              saleBadgeCode={saleBadgeCode}
              handleClick={handleClick}
              altText={`${t('cart.line.item.link.text', { item: name })}`}
            />
          )}

          {validateAndUpdateStockStatus ? (
            <StockBanner $status={validateAndUpdateStockStatus}>
              {validateAndUpdateStockStatus === ValidateStockStatusType.OUT_OF_STOCK && t('out.of.stock')}
              {validateAndUpdateStockStatus === ValidateStockStatusType.QUANTITY_ADJUSTED &&
                adjustedQuantity === 1 &&
                t('last.one.item')}
              {validateAndUpdateStockStatus === ValidateStockStatusType.QUANTITY_ADJUSTED &&
                adjustedQuantity === 2 &&
                t('last.two.item')}
            </StockBanner>
          ) : (
            isLastItem && (
              <StockBanner $status={ValidateStockStatusType.QUANTITY_ADJUSTED}>{t('last.one.item')}</StockBanner>
            )
          )}
        </ImageContentWrapper>
        <DetailsWrapper>
          <ProductNameWrapper $type={type}>
            {name && (
              <ProductName
                onClick={handleClick}
                aria-label={`${t('cart.line.item.link.text', { item: name })}`}
                role="link"
                tabIndex={0}
              >
                {name}
              </ProductName>
            )}
            <DeleteButton
              type="button"
              aria-label={`${t('cart.line.item.remove', { item: name })}`}
              onClick={onDeleteItem(id)}
              disabled={isRemoveItemLoading}
              data-testid="minicartRemoveProductButton"
            >
              <Delete aria-hidden />
            </DeleteButton>
          </ProductNameWrapper>
          <LineItemPrices price={price} quantity={lineItemQuantity} />
          {finalSale?.text && (
            <FinalSaleContainer>
              <FinalSale
                text={finalSale.text}
                textColor={finalSale.textColor}
                backgroundColor={finalSale.backgroundColor}
              />
            </FinalSaleContainer>
          )}
          <ProductDetailsBlockWrapper
            $isProductDetail={
              type === ProductLineItemType.MULTI_IMAGE && !shouldDetailsColumn && !isGiftCard(lineItem.productTypeKey)
            }
          >
            {productDetailsMapping?.map((productDetailsGroup, productDetailsBlockIndex) => (
              <ProductDetailsBlock key={productDetailsBlockIndex}>
                <tbody>
                  {productDetailsGroup?.map((productDetail, productDetailsItemIndex) => {
                    const isQuantity = productDetail.label === t('cart.line.item.quantity');

                    if (isQuantity && isGiftCard(lineItem.productTypeKey) && (productDetail.value as number) === 1)
                      return null;

                    return (
                      productDetail.value && (
                        <ProductItemDetail
                          key={`${productDetailsBlockIndex}-${productDetailsItemIndex}`}
                          item={{
                            id,
                            name,
                            label: productDetail.label,
                            value: productDetail.value,
                            productTypeKey: lineItem.productTypeKey,
                          }}
                          indexes={{ productDetailsBlockIndex, productDetailsItemIndex }}
                          quantityButtonProps={{
                            sku: {
                              cap: skuCap,
                              isCapReached: isSkuCapReached,
                            },
                            isStar2,
                            dispatch,
                            setLineItemStatus,
                          }}
                        />
                      )
                    );
                  })}
                </tbody>
              </ProductDetailsBlock>
            ))}
          </ProductDetailsBlockWrapper>
          <DiscountWrapper role="status">
            {discountsDescriptions.map((discountMessage: DiscountDescription, index: number) => {
              const discountText =
                isBasketPage || discountMessage.type === DiscountType.PRODUCT
                  ? discountMessage.description
                  : discountMessage.shortDescription;
              return (
                discountText && (
                  <Discount data-testid="discountApplied" key={`discount-${index}`}>
                    <IconWrapper>
                      <ImagePartial assetType="static" src={tickIconUrl} width={16} height={16} />
                    </IconWrapper>
                    <span data-testid="discountText">{t('cart.discount', { discount: discountText })}</span>
                  </Discount>
                )
              );
            })}
          </DiscountWrapper>
          {isSkuCapReached && (
            <SkuCapError data-testid="skuCapError" role="alert">
              {t('cart.line.item.sku.cap.limit')}
            </SkuCapError>
          )}
          {validateAndUpdateStockStatus === ValidateStockStatusType.QUANTITY_ADJUSTED && (
            <SkuCapError data-testid="skuCapError" role="alert">
              {t('cart.line.item.quantity.adjusted')}
            </SkuCapError>
          )}
          {validateAndUpdateStockStatus === ValidateStockStatusType.OUT_OF_STOCK && (
            <SkuCapError data-testid="skuCapError" role="alert">
              {t('cart.line.item.out.of.stock')}
            </SkuCapError>
          )}
          {hasGenericError && <SkuCapError role="alert">{t('cart.line.item.generic.error')}</SkuCapError>}
          {showNotifyMeForm && isDesktop && (
            <NotifyMeForm
              productName={name}
              productSku={variant.sku as string}
              productSize={size as string}
              productWidth={fit as string}
              isHorizontal={type === ProductLineItemType.MULTI_IMAGE}
            />
          )}
        </DetailsWrapper>
      </LineItemWrapper>
      {showNotifyMeForm && !isDesktop && (
        <NotifyMeForm
          productName={name}
          productSku={variant.sku as string}
          productSize={size as string}
          productWidth={fit as string}
          isHorizontal={type === ProductLineItemType.MULTI_IMAGE}
        />
      )}
    </>
  );
}
