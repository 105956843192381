import { Cookies } from './cookies';

export function checkConsent() {
  const params = new URLSearchParams(Cookies.get('OptanonConsent') ?? '');
  const groups = params.get('groups')?.split(',') ?? [];

  // Only checks for performance and advertising cookies
  const cookiesRequiredForConsent = ['C0004', 'C0002'];
  // '0' = didn't consent, '1' = did consent
  return cookiesRequiredForConsent.every((cookie) => groups?.includes(`${cookie}:1`));
}
