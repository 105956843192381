// result: {startDay, endDay (can be empty), openingTime (can be null, if store is Closed), closingTime}
// usage: {tr(startDay)} {endDay ? ' - ' tr(endDay) : ''}: {openingTime ? openingTime - closingTime : tr('closed')}
import { format, isValid, parse } from 'date-fns';
import type { TFunction } from 'next-i18next';
import { useTranslation } from 'next-i18next';
import { colours } from '../../stylings';
import type { OpeningHoursType } from '../../types';
import { WEEK_DAYS } from '../../utils';

export type FormattedScheduleType = {
  startDay: string;
  endDay?: string;
  openingTime: string | null;
  closingTime: string | null;
};
const getDate = (originalTime: string) => {
  // There are currently two known formats of time coming from the API
  // Extend if necessary
  const date = parse(originalTime, 'hh:mm:ss a', new Date());
  return isValid(date) ? date : parse(originalTime, 'HH:mm', new Date());
};
const getFormattedTime = (originalTime: string, timeFormat = 'hh:mm a') => {
  try {
    return format(getDate(originalTime), timeFormat);
  } catch (e) {
    console.error(`Invalid time format: ${originalTime}, ${e}`);
    return null;
  }
};

export const useFormattedSchedule = (openingHours: OpeningHoursType[], shouldGroup: boolean, timeFormat = 'h:mm a') => {
  const { t } = useTranslation('lib-global-common');
  const formattedSchedule: FormattedScheduleType[] = [];

  WEEK_DAYS.forEach((weekDayName) => {
    const daySchedule = openingHours?.find((element) => weekDayName.toUpperCase() === element.day);
    const openingTime = daySchedule ? getFormattedTime(daySchedule.openingTime, timeFormat) : null;
    const closingTime = daySchedule ? getFormattedTime(daySchedule.closingTime, timeFormat) : null;

    if (
      shouldGroup &&
      formattedSchedule.length !== 0 &&
      formattedSchedule[formattedSchedule.length - 1].openingTime === openingTime &&
      formattedSchedule[formattedSchedule.length - 1].closingTime === closingTime
    )
      formattedSchedule[formattedSchedule.length - 1].endDay = t(`week.day.${weekDayName}`);
    else formattedSchedule.push({ startDay: t(`week.day.${weekDayName}`), openingTime, closingTime });
  });

  return formattedSchedule;
};

export const featureNames = {
  mens: 'mens',
  womens: 'womens',
  childrens: 'childrens',
  kidsFitting: 'kidsFitting',
  placeOrderInStore: 'placeOrderInStore',
  collectInStore: 'collectInStore',
  originals: 'originals',
};

export function colorizeFeatureTag(featureName: string) {
  switch (featureName) {
    case featureNames.mens:
    case featureNames.womens:
      return {
        bgColor: colours.DARK_GREY,
        fontColor: colours.WHITE,
      };
    case featureNames.childrens:
    case featureNames.kidsFitting:
      return {
        bgColor: colours.TURQUOISE,
        fontColor: colours.BLACK,
      };

    case featureNames.placeOrderInStore:
    case featureNames.collectInStore:
      return {
        bgColor: colours.LIGHT_BLUE,
        fontColor: colours.BLACK,
      };
    case featureNames.originals:
      return {
        bgColor: colours.VIVID_SKY,
        fontColor: colours.BLACK,
      };
    default:
      return {
        bgColor: colours.DARK_GREY,
        fontColor: colours.WHITE,
      };
  }
}

export const formatOpeningHours = (
  { startDay, endDay, openingTime, closingTime }: FormattedScheduleType,
  t: TFunction,
) => {
  const days = `${startDay}${endDay ? ` ${t('pdp.store.days.divider')} ${endDay}` : ''}`;
  const hours = `${openingTime ? `${openingTime} - ${closingTime}` : t('pdp.store.closed')}`;
  return `${days}: ${hours}`;
};
