/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans, useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useLocaleContext } from '../../context/localeContext';
import { colours, fontWeights } from '../../stylings';
import { createAnchorTagHref } from '../../utils/transformers';

const S = {
  Text: styled.span`
    color: ${colours.RED};
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0;
    padding: 0;
    a {
      color: inherit;
      color: ${colours.BLACK};
      font-weight: ${fontWeights.SemiBold600};
      margin: 0;
      padding: 0;
    }
  `,
};

export function ExistingEmailError() {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();

  const login = t('register.login');
  const resetPassword = t('register.reset.password');

  return (
    <S.Text data-testid="existingUserError">
      <Trans
        i18nKey="register.email.exist"
        values={{
          login,
          reset: resetPassword,
        }}
        components={[
          <a
            href={createAnchorTagHref({
              locale,
              path: 'login',
            })}
            aria-label={login}
            data-testid="loginLink"
            key="login"
          />,
          <a
            href={createAnchorTagHref({
              locale,
              path: 'reset-password',
            })}
            aria-label={resetPassword}
            data-testid="resetPasswordLink"
            key="reset-password"
          />,
        ]}
        t={t}
      />
    </S.Text>
  );
}

export default ExistingEmailError;
