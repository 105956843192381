import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { SiteSwitcherDropdown } from '@amplience/content-types/typings/c-site-switcher';
import * as Dialog from '@radix-ui/react-dialog';
import { DialogContent, DialogOverlay, DialogPortal, DialogRoot, DialogTrigger } from '../../baseComponents/dialog';
import { Select } from '../../baseComponents/select/Select';
import { useSiteSwitcher } from '../../hooks/useSiteSwitcher';
import { TypographyStyles, colours, hoverUp, media, spacing } from '../../stylings';
import { getBasketPageLink } from '../../utils/cartUtils';
import { getCountryKey } from '../../utils/localeHelper';
import { getLocalizedValue } from '../../utils/transformers';
import { CountryFlag } from '../countryFlag/CountryFlag';

const S = {
  SelectedCountry: styled.button`
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    gap: ${spacing.XXXS};
    height: 18px;
    padding: 0;
  `,
  FlagIcon: styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      height: 17px;
      width: 18px;
    }
  `,
  SelectedCountryText: styled.span`
    color: ${colours.BLACK};
    ${TypographyStyles.CallToAction.Tiny.SemiBold}
    text-decoration: underline;
  `,
  SelectedCountryArrow: styled.div`
    margin-left: ${spacing.XXS};
  `,
  Wrapper: styled.div<{ $hasMargin?: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-area: siteSwitcher;
    margin-bottom: ${({ $hasMargin }) => ($hasMargin ? spacing.XXL : 0)};
    padding: ${spacing.M} 0;

    @media ${media.greaterThan('lg')} {
      align-items: flex-end;
      margin: unset;
    }
  `,
  DialogOverlay: styled(DialogOverlay)`
    background-color: rgba(0, 0, 0, 0.4);
    inset: 0;
    position: fixed;
    z-index: 1;
  `,
  DialogContentWrapper: styled(DialogContent)`
    background-color: ${colours.WHITE};
    max-height: max-content;
    max-width: min(80vw, 328px);
    overflow-y: auto;
    padding: ${spacing.M};
    width: 100%;
    z-index: 2;

    @media ${media.greaterThan('lg')} {
      max-width: 450px;
    }
  `,
  DialogCloseContainer: styled.div`
    text-align: right;
    width: 100%;
  `,
  DialogCloseIcon: styled(Dialog.Close)`
    all: unset;
    cursor: pointer;
    ${hoverUp()}

    &:focus-visible {
      outline: 2px solid ${colours.OUTLINE_BORDER};
    }
  `,
  DialogTitleWrapper: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 ${spacing.XXS} 0;
    text-transform: uppercase;
    > h2 {
      ${TypographyStyles.Headings.H3}
      margin-top: 0;
    }
  `,
  DialogBodyWrapper: styled.div`
    border-top: 1px solid ${colours.BLACK};
    padding-top: ${spacing.M};
    & > * {
      &:not(:last-child) {
        margin-bottom: ${spacing.M};
      }
    }
  `,
  SelectTitle: styled.span`
    ${TypographyStyles.Label.Large.Medium}
    display: inline-block;
    margin-bottom: ${spacing.XXXS};
    text-transform: uppercase;
  `,
  UpdatePreferencesButton: styled.button`
    ${TypographyStyles.Button.SemiBold}
    background-color: ${colours.DARK_BLUE};
    border-radius: ${spacing.XXXXXS};
    color: ${colours.WHITE};
    cursor: pointer;
    display: block;
    min-height: 52px;
    padding: ${spacing.XXS};
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    &:focus-visible {
      outline-color: ${colours.DARK_ORANGE};
    }
  `,
  CurrencyText: styled.p`
    ${TypographyStyles.Headings.H5}
  `,
  HelperText: styled.p`
    ${TypographyStyles.Body.Small.Regular}
  `,
};

export type SiteSwitcherProps = SiteSwitcherDropdown;

export function SiteSwitcher({ helperText }: SiteSwitcherProps) {
  const { t } = useTranslation(['lib-global-common']);
  const router = useRouter();
  const {
    dialogSelectedCountry,
    dialogSelectedLanguage,
    onChangeCountry,
    onChangeLanguage,
    onUpdatePreferences,
    selectedCountry,
    siteSwitcherSortedData,
    locale,
  } = useSiteSwitcher();

  if (!siteSwitcherSortedData || !selectedCountry || !dialogSelectedCountry || !dialogSelectedLanguage) return null;

  const isPDP = router.query.slug?.[router.query.slug.length - 1].toLocaleLowerCase().endsWith('-p');
  const isCartPage = router.asPath.includes(getBasketPageLink(locale));

  return (
    <S.Wrapper $hasMargin={isPDP || isCartPage}>
      <DialogRoot>
        <DialogTrigger asChild>
          <S.SelectedCountry
            data-testid="openSiteSwitcher"
            aria-label={`${t('footer.siteswitcher.selected.country')}: ${t(
              `country.${getCountryKey(selectedCountry.key)}`,
              { ns: 'lib-global-countries-common' },
            )}`}
            type="button"
          >
            <S.FlagIcon>
              <CountryFlag
                country={
                  selectedCountry.key.includes('-')
                    ? selectedCountry.key.split('-')[1].toLocaleLowerCase()
                    : selectedCountry.key.toLocaleLowerCase()
                }
                viewBox="5 -1 180 200"
                aria-hidden
              />
            </S.FlagIcon>
            <S.SelectedCountryText data-testid="selectedCountryName">
              {t(`country.${getCountryKey(selectedCountry.key)}`, { ns: 'lib-global-countries-common' })}
            </S.SelectedCountryText>
          </S.SelectedCountry>
        </DialogTrigger>
        <DialogPortal>
          <S.DialogOverlay />
          <S.DialogContentWrapper data-testid="siteSwitcherDialog">
            <S.DialogTitleWrapper>
              <Dialog.Title>{t('footer.siteswitcher.preferences')}</Dialog.Title>
            </S.DialogTitleWrapper>
            <S.DialogBodyWrapper>
              <div>
                <S.SelectTitle id="footer-siteswitcher-shopin">{t('footer.siteswitcher.shopin')}</S.SelectTitle>
                <Select
                  data-testid="selectCountry"
                  value={dialogSelectedCountry.key}
                  onChange={onChangeCountry}
                  aria-labelledby="footer-siteswitcher-shopin"
                >
                  {siteSwitcherSortedData.map((country) => (
                    <option key={country.key} value={country.key}>
                      {t(`country.${getCountryKey(country.key)}`, { ns: 'lib-global-countries-common' })}
                    </option>
                  ))}
                </Select>
              </div>
              {dialogSelectedCountry.languages.length > 1 && (
                <div>
                  <S.SelectTitle id="footer-siteswitcher-language">{t('footer.siteswitcher.language')}</S.SelectTitle>
                  <Select
                    data-testid="selectLanguage"
                    value={dialogSelectedLanguage}
                    onChange={onChangeLanguage}
                    aria-labelledby="footer-siteswitcher-language"
                  >
                    {dialogSelectedCountry.languages.map((lang) => (
                      <option key={lang} value={lang}>
                        {t(`language.${lang.split('-')[0]}`)}
                      </option>
                    ))}
                  </Select>
                </div>
              )}
              <S.HelperText>{getLocalizedValue(helperText)}</S.HelperText>
              <Dialog.Close asChild>
                <S.UpdatePreferencesButton
                  data-testid="updatePreferencesButton"
                  onClick={onUpdatePreferences}
                  type="button"
                >
                  {t('footer.siteswitcher.button')}
                </S.UpdatePreferencesButton>
              </Dialog.Close>
              <S.CurrencyText data-testid="currencyText">
                {t('footer.siteswitcher.currency')}: {dialogSelectedCountry.currencySymbol}
                {dialogSelectedCountry.currency}
              </S.CurrencyText>
            </S.DialogBodyWrapper>
          </S.DialogContentWrapper>
        </DialogPortal>
      </DialogRoot>
    </S.Wrapper>
  );
}
