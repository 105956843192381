import styled from 'styled-components';
import { colours, spacing } from '../../../../stylings';

export const CircleIcon = styled.li`
  height: 14px;
  margin: 0 36px 0 0;
  position: relative;
  width: 14px;

  > * {
    height: 100%;
    width: 100%;
  }

  > *:before {
    background-color: ${colours.LIGHT_GREY};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${spacing.L};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${spacing.L};
  }

  > a:focus-visible::before {
    outline: 1px solid;
    outline-color: inherit;
  }

  &:hover,
  &:focus {
    > *:before {
      background-color: ${colours.LIGHT_BLUE};
    }
  }
`;
