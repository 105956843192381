import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetStoreQueryVariables = Types.Exact<{
  input: Types.GetStoreInput;
}>;

export type GetStoreQuery = {
  __typename?: 'Query';
  getStore?: {
    __typename?: 'Store';
    collectionShippingEnabled?: boolean | null;
    id: string;
    key: string;
    skuCap?: number | null;
    productQuantityCap?: number | null;
    basketValueCap?: number | null;
    basketValueCapAtPeak?: number | null;
    basketValueCapAtPeakFrom?: string | null;
    basketValueCapAtPeakUntil?: string | null;
    billingCountries?: Array<string | null> | null;
    shippingCountries?: Array<string | null> | null;
    pudoEnabled?: boolean | null;
  } | null;
};

export const GetStoreDocument = gql`
  query getStore($input: GetStoreInput!) {
    getStore(input: $input) {
      collectionShippingEnabled
      id
      key
      skuCap
      productQuantityCap
      basketValueCap
      basketValueCapAtPeak
      basketValueCapAtPeakFrom
      basketValueCapAtPeakUntil
      billingCountries
      shippingCountries
      pudoEnabled
    }
  }
`;

/**
 * __useGetStoreQuery__
 *
 * To run a query within a React component, call `useGetStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoreQuery(baseOptions: Apollo.QueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
}
export function useGetStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
}
export type GetStoreQueryHookResult = ReturnType<typeof useGetStoreQuery>;
export type GetStoreLazyQueryHookResult = ReturnType<typeof useGetStoreLazyQuery>;
export type GetStoreQueryResult = Apollo.QueryResult<GetStoreQuery, GetStoreQueryVariables>;
