import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type CreatePaymentMutationVariables = Types.Exact<{
  input: Types.CreatePaymentInput;
}>;

export type CreatePaymentMutation = {
  __typename?: 'Mutation';
  createPayment: {
    __typename?: 'CreatePaymentResponse';
    id: string;
    paymentMethods: Array<{
      __typename?: 'PaymentMethods';
      brands?: Array<string> | null;
      name: string;
      type: string;
      configuration?: {
        __typename?: 'PaymentMethodConfiguration';
        gatewayMerchantId?: string | null;
        merchantId?: string | null;
      } | null;
    }>;
    session: {
      __typename?: 'Session';
      channel?: string | null;
      expiresAt?: string | null;
      id: string;
      merchantAccount?: string | null;
      reference?: string | null;
      returnUrl: string;
      sessionData: string;
      shopperLocale?: string | null;
      amount?: { __typename?: 'AdyenAmount'; currency: string; value: number } | null;
    };
  };
};

export const CreatePaymentDocument = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      paymentMethods {
        brands
        name
        type
        configuration {
          gatewayMerchantId
          merchantId
        }
      }
      session {
        channel
        expiresAt
        id
        merchantAccount
        reference
        returnUrl
        sessionData
        shopperLocale
        amount {
          currency
          value
        }
      }
    }
  }
`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
}
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;
