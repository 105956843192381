import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { PromotionalLinksNav as PromotionalLinksNavSchema } from '@amplience/content-types/typings/c-promotional-links-nav';
import { LeftArrow } from '../../icons';
import { ImagePartial } from '../../partials/image';
import { LinkPartial } from '../../partials/link';
import { TitlePartial } from '../../partials/title';
import { TypographyStyles, colours, maxWidthPartial, media, spacing } from '../../stylings';
import { sendNavigationEvent } from '../../utils/gtm/events/navigation/sendNavigationEvent';
import { getLocalizedValue } from '../../utils/transformers';

const S = {
  Content: styled.div`
    align-items: center;
    display: flex;
    height: 40px;

    & .link-text {
      color: ${colours.BLACK};
      margin-right: ${spacing.XXXXS};
    }

    & svg {
      height: 10px;
      width: 10px;
    }

    &:hover,
    &:focus {
      svg {
        transform: translateX(4px);
      }
    }
  `,
  ImageWrapper: styled.div`
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    margin-right: ${spacing.XXXS};
    overflow: hidden;
    width: 40px;
  `,
  List: styled.ul`
    ${maxWidthPartial({ withPaddingXL: true })}
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ${spacing.XS};
    justify-content: space-evenly;
    list-style-type: none;
    width: 100%;

    & li {
      display: block;

      & a {
        text-decoration: none;
        &:hover,
        &:focus {
          color: ${colours.BLACK};
          text-decoration: underline;
        }
      }
    }

    @media ${media.greaterThan('lg')} {
      display: block;
      flex-wrap: unset;
      justify-content: unset;
      width: auto;

      & li {
        display: inline-block;

        &:not(:last-child) {
          margin-right: ${spacing.L};
        }
      }
    }

    @media ${media.greaterThan('xl')} {
      & li {
        &:not(:last-child) {
          margin-right: ${spacing.XXL};
        }
      }
    }
  `,
  Wrapper: styled.div<{ $forDesktop: boolean }>`
    align-items: center;
    display: ${({ $forDesktop }) => $forDesktop && 'none'};
    justify-content: center;
    @media ${media.greaterThan('lg')} {
      align-items: center;
      display: flex;
      height: 100px;
      justify-content: center;
      width: 100%;
    }
  `,
  TitlePartial: styled(TitlePartial)`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
};

export type PromotionalLinksNavProps = PromotionalLinksNavSchema & {
  forDesktop?: boolean;
};

export function PromotionalLinksNav({ promotionalLinks, forDesktop }: PromotionalLinksNavProps): ReactElement {
  const handleCLick = (linkText: string) => () => {
    sendNavigationEvent({
      component_type: 'main nav - text link',
      link_text: linkText,
    });
  };
  return (
    <S.Wrapper $forDesktop={!!forDesktop}>
      <S.List>
        {promotionalLinks.map((promotionalLink) => {
          const linkText = getLocalizedValue(promotionalLink.text.text);
          return (
            linkText && (
              <li key={linkText}>
                <LinkPartial onClick={handleCLick(linkText)} {...promotionalLink.link}>
                  <S.Content>
                    <S.ImageWrapper aria-hidden>
                      <ImagePartial
                        height={80}
                        width={80}
                        layout="intrinsic"
                        src={promotionalLink.image.image?.name ?? ''}
                        assetType={promotionalLink.image.assetType}
                        defaultHost={promotionalLink.image.image?.defaultHost}
                        useOriginalSize={promotionalLink.image.useOriginalSize}
                      />
                    </S.ImageWrapper>
                    <S.TitlePartial
                      tag="span"
                      {...promotionalLink.text}
                      className="link-text"
                      textTransform={promotionalLink.text.textTransform}
                    />
                    <LeftArrow viewBox="5 4 6 14" aria-hidden />
                  </S.Content>
                </LinkPartial>
              </li>
            )
          );
        })}
      </S.List>
    </S.Wrapper>
  );
}
