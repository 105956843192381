import { useInstantSearch } from 'react-instantsearch';
import styled from 'styled-components';

const S = {
  LoadingContainer: styled.p`
    height: 100%;
  `,
};
export function AlgoliaLoading() {
  const { status } = useInstantSearch();

  if (status === 'loading' || status === 'stalled') {
    return <S.LoadingContainer>Loading search results</S.LoadingContainer>;
  }
  return null;
}
