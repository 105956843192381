import type { Keyframes } from 'styled-components';
import { css, keyframes } from 'styled-components';
import { colours } from './colours';
import { media } from './media';

export const hoverUp = (hasBgColor?: boolean) =>
  hasBgColor
    ? css`
        &:hover > *,
        &:focus {
          transform: translateY(-2px);
          span {
            background-color: ${hasBgColor ? colours.LIGHT_BLUE : 'unset'};
          }
        }
      `
    : css`
        &:hover > *,
        &:focus {
          transform: translateY(-2px);
        }
      `;

export const hoverUpEditIcon = (hasBgColor?: boolean) =>
  hasBgColor
    ? css`
        &:hover,
        &:focus {
          svg {
            transform: translateY(-2px);
          }
          span {
            background-color: ${hasBgColor ? colours.LIGHT_BLUE : 'unset'};
          }
        }
      `
    : css`
        &:hover,
        &:focus {
          svg {
            transform: translateY(-2px);
          }
        }
      `;

export const hoverUpDeleteIcon = (hasBgColor?: boolean) =>
  hasBgColor
    ? css`
        &:hover,
        &:focus {
          svg {
            background-color: ${hasBgColor ? colours.LIGHT_BLUE : 'unset'};
            transform: translateY(-2px);
          }
        }
      `
    : css`
        &:hover,
        &:focus {
          svg {
            transform: translateY(-2px);
          }
        }
      `;

export const slideToRight = keyframes`
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(20%);
  }
  100% {
    transform: translateX(0%)
  }
`;

export const slideToLeftColours = keyframes`
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0%)
  }
`;

const slideToRightFullWidthCarousel = keyframes`
  0% {
    transform: translateX(0%) translateY(-50%);
  }
  50% {
    transform: translateX(20%) translateY(-50%);
  }
  100% {
    transform: translateX(0%) translateY(-50%);
  }
`;

const slideIntoViewLeft = keyframes`
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
`;

const slideOutOfViewRight = keyframes`
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
`;

const slideIntoViewTop = keyframes`
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
`;

const slideOutOfViewBottom = keyframes`
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
`;

export const slideToLeftArrowsColourSelectorAnimation = css`
  @media ${media.lessThan('lg')} {
    &:not(:disabled):active {
      animation: ${slideToLeftColours} 0.3s linear forwards;
      transition: transform 0.3s ease-in;

      @media screen and (prefers-reduced-motion: reduce) {
        animation: none;
        transition: none;
      }
    }
  }

  @media ${media.greaterThan('lg')} {
    &:not(:disabled):focus-visible,
    &:not(:disabled):hover {
      animation: ${slideToLeftColours} 0.3s linear forwards;
      transition: transform 0.3s ease-in;

      @media screen and (prefers-reduced-motion: reduce) {
        animation: none;
        transition: none;
      }
    }
  }
`;

export const slideToRightArrowsAnimation = css`
  @media ${media.lessThan('lg')} {
    &:not(:disabled):active {
      animation: ${slideToRight} 0.3s linear forwards;
      transition: transform 0.3s ease-in;

      @media screen and (prefers-reduced-motion: reduce) {
        animation: none;
        transition: none;
      }
    }
  }

  @media ${media.greaterThan('lg')} {
    &:not(:disabled):focus-visible,
    &:not(:disabled):hover {
      animation: ${slideToRight} 0.3s linear forwards;
      transition: transform 0.3s ease-in;

      @media screen and (prefers-reduced-motion: reduce) {
        animation: none;
        transition: none;
      }
    }
  }
`;

export const slideToRightArrowsFullWidthCarouselAnimation = css`
  @media ${media.greaterThan('lg')} {
    &:not(:disabled):focus-visible,
    &:not(:disabled):hover {
      animation: ${slideToRightFullWidthCarousel} 0.3s linear forwards;
      transition: transform 0.3s ease-in;

      @media screen and (prefers-reduced-motion: reduce) {
        animation: none;
        transition: none;
      }
    }
  }
`;

export const slideToLeftTraysAnimation = css`
  animation: ${slideIntoViewLeft} 0.3s linear forwards;
  transform: translateX(100%);
  transition: transform 0.3s ease-in;

  &.is-closing,
  .is-closing & {
    animation: ${slideOutOfViewRight} 0.3s linear forwards;

    @media screen and (prefers-reduced-motion) {
      animation: none;
      transform: translateX(0);
    }
  }

  @media screen and (prefers-reduced-motion) {
    animation: none;
    transform: translateX(0);
  }
`;

export const slideToLeftHamburgerMenuTraysAnimation = css`
  animation: ${slideIntoViewLeft} 0.3s linear forwards;
  transform: translateX(100%);
  transition: transform 0.5s ease;

  &.is-closing,
  .is-closing & {
    animation: ${slideOutOfViewRight} 0.3s linear forwards;

    @media screen and (prefers-reduced-motion) {
      animation: none;
      transform: translateX(0);
    }
  }

  @media screen and (prefers-reduced-motion) {
    animation: none;
    transform: translateX(0);
  }
`;

export const checkoutCtaTraysAnimation = css`
  animation: ${slideIntoViewTop} 0.3s linear forwards;
  transform: translateX(100%);
  transition: transform 0.3s ease-in;

  &.is-closing {
    animation: ${slideOutOfViewBottom} 0.3s linear forwards;

    @media screen and (prefers-reduced-motion) {
      animation: none;
      transform: translateX(0);
    }
  }
  @media ${media.greaterThan('lg')} {
    animation: ${slideIntoViewLeft} 0.3s linear forwards;

    &.is-closing {
      animation: ${slideOutOfViewRight} 0.3s linear forwards;

      @media screen and (prefers-reduced-motion) {
        animation: none;
        transform: translateX(0);
      }
    }
  }

  @media screen and (prefers-reduced-motion) {
    animation: none;
    transform: translateX(0);
  }
`;

export const pulseIn = keyframes`
  0% {
		animation-timing-function: ease-out;
		transform: translate(-50%, -50%) scale(0.7);
	}
	25% {
		animation-timing-function: ease-out;
		transform: translate(-50%, -50%) scale(1.4);
	}
	50% {
		animation-timing-function: ease-out;
		transform: translate(-50%, -50%) scale(0.9);
	}
	100% {
		animation-timing-function: ease-out;
		transform: translate(-50%, -50%) scale(1);
	}
`;

export const pulseInAnimation = css`
  animation: ${pulseIn} 1s ease 0s 1 normal forwards;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
    transition: none;
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeInAnimation = css`
  animation: ${fadeIn} 0.3s linear forwards;
  transition: transform 0.3s ease-in;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
    transition: none;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
`;

export const fadeOutAnimation = css`
  animation: ${fadeOut} 0.3s linear forwards;
  transition: transform 0.3s ease-in;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
    transition: none;
  }
`;

export const maxHeightAccordionOpen = keyframes`
    from {
       max-height: 0;
    }

    to {
      max-height: 3000px;
    }
`;

export const maxHeightAccordionClose = keyframes`
    from {
       max-height: 3000px;
    }

    to {
       max-height: 0;
    }
`;

export const accordionAnimation = (isOpen: boolean, customKeyFrameOpen?: Keyframes, customKeyFrameClose?: Keyframes) =>
  isOpen
    ? css`
        animation: ${customKeyFrameOpen ||
          css`
            ${maxHeightAccordionOpen}
          `}
          0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
        @media screen and (prefers-reduced-motion: reduce) {
          animation: none;
        }
      `
    : css`
        animation: ${customKeyFrameClose ||
          css`
            ${maxHeightAccordionClose}
          `}
          0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
        @media screen and (prefers-reduced-motion: reduce) {
          animation: none;
        }
      `;

export const radixAccordionSlideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
`;

export const radixAccordionSlideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }`;

export const accordionArrowAnimation = (isOpen?: boolean) =>
  isOpen
    ? css`
        .accordion-arrow-down {
          flex-shrink: 0;
          transform: ${isOpen ? `rotate(180deg)` : `rotate(0deg)`};

          transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);
        }

        @media (prefers-reduced-motion) {
          .accordion-arrow-down {
            transition: none;
          }
        }
      `
    : css`
        .accordion-arrow-down {
          flex-shrink: 0;
          transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);
        }

        &[data-state='open'] > .accordion-arrow-down {
          transform: rotate(180deg);
        }
        @media (prefers-reduced-motion) {
          .accordion-arrow-down {
            transition: none;
          }
        }
      `;

export const skeletonPulse = keyframes`
 0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;
