/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Locale, countryCodeMapperForStoreLocator } from '../utils';

export enum GeocodingErrors {
  MAPS_API_INIT_ERROR = 'Unable to initialize Google Maps API',
  GEOCODER_NOT_INITIALIZED = 'Geocoder API is not initialized',
  LOCATION_NOT_FOUND = 'Unable to find location',
  UNKNOWN_ERROR = 'Something went wrong',
}

const ERROR_CODE_ZERO_RESULTS = 'ZERO_RESULTS';

// https://clarks.atlassian.net/browse/CICD-3429 - Extending US boundaries to include Puerto Rico
const US_MAP_BOUNDARIES = [
  { lat: 17.889887, lng: -124.746602 },
  { lat: 49.003195, lng: -65.21347 },
];

// https://clarks.atlassian.net/browse/CICD-4205 - Extending UK boundaries to include Ireland
const UK_MAP_BOUNDARIES = [
  { lat: 49.006911, lng: -8.716707 },
  { lat: 61.088592, lng: 2.401457 },
];

export function useGeocodingAPI() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
  });
  const geocoder = useMemo(() => {
    if (isLoaded) {
      return new google.maps.Geocoder();
    }
    return null;
  }, [isLoaded]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleGeocodingError = (e: any) => {
    window.console.error(e);
    if (e?.code === ERROR_CODE_ZERO_RESULTS) {
      throw new Error(GeocodingErrors.LOCATION_NOT_FOUND);
    } else {
      window.console.error('Geocoder API error: ', e);
      throw new Error(GeocodingErrors.UNKNOWN_ERROR);
    }
  };

  const getLatLngBounds = useCallback((locale?: string) => {
    switch (locale) {
      case Locale.EN_US:
        return new google.maps.LatLngBounds(US_MAP_BOUNDARIES[0], US_MAP_BOUNDARIES[1]);
      case Locale.EN_GB:
        return new google.maps.LatLngBounds(UK_MAP_BOUNDARIES[0], UK_MAP_BOUNDARIES[1]);
      default:
        return undefined;
    }
  }, []);

  const isUnrestrictedArea = useCallback(
    (locale?: string) => locale && [Locale.EN_US, Locale.EN_GB].includes(locale),
    [],
  );

  const getLatLngFromText = useCallback(
    async (locationQuery: string, locale?: string) => {
      if (loadError) {
        throw new Error(GeocodingErrors.MAPS_API_INIT_ERROR);
      }
      if (!geocoder) {
        throw new Error(GeocodingErrors.GEOCODER_NOT_INITIALIZED);
      }

      try {
        const bounds = getLatLngBounds(locale);
        const componentRestrictions = locale
          ? {
              country: isUnrestrictedArea(locale) ? undefined : countryCodeMapperForStoreLocator[locale],
            }
          : {};
        const response = await geocoder.geocode({ address: locationQuery, bounds, componentRestrictions });

        const {
          geometry: { location },
          address_components,
        } = response.results[0];

        return {
          lat: location.lat(),
          lng: location.lng(),
          addressComponents: address_components,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        handleGeocodingError(e);
        return undefined;
      }
    },
    [geocoder, getLatLngBounds, isUnrestrictedArea, loadError],
  );

  const lookupAddress = useCallback(
    async (lat: number, lng: number, locale?: string) => {
      if (loadError) {
        throw new Error(GeocodingErrors.MAPS_API_INIT_ERROR);
      }
      if (!geocoder) {
        throw new Error(GeocodingErrors.GEOCODER_NOT_INITIALIZED);
      }

      try {
        const response = await geocoder.geocode({ location: { lat, lng } });
        const { address_components, formatted_address } = response.results[0];

        if (
          locale &&
          !address_components.some(
            (address) =>
              address.types.some((addressType) => addressType === countryCodeMapperForStoreLocator[locale]) ||
              Object.values(address).includes(countryCodeMapperForStoreLocator[locale]),
          )
        ) {
          window.console.error('Unsupported country for locale');
          throw new Error(GeocodingErrors.UNKNOWN_ERROR);
        }

        return {
          formattedAddress: formatted_address,
          addressComponents: address_components,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        handleGeocodingError(e);
        return undefined;
      }
    },
    [geocoder, loadError],
  );

  return {
    getLatLngFromText,
    lookupAddress,
    isGeocoderLoaded: !!geocoder,
    geocoderLoadError: loadError,
  };
}
