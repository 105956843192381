import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

export function useTruncated(textRef: RefObject<HTMLElement>) {
  const [isTruncated, setIsTruncated] = useState(true);
  const [lineHeight, setLineHeight] = useState('');

  const handleResize = useCallback(() => {
    const textEl = textRef.current;
    if (textEl) {
      const lineHeightValue = window.getComputedStyle(textEl, null).getPropertyValue('line-height');
      const textHeight = textEl.clientHeight;
      const numberOfLines = textHeight / parseInt(lineHeightValue, 10);
      setLineHeight(lineHeightValue);
      setIsTruncated(numberOfLines > 1);
    }
  }, [textRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, { passive: true });
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return { isTruncated, lineHeight };
}
