import * as yup from 'yup';

type TapeOrGauge = 'tape' | 'gauge';

export const sizeCalculatorSchema = (device: TapeOrGauge, locale?: string) => {
  //  other locales - gauge
  let minLength = 97;
  let maxLength = 281;
  let minWidth = 67;
  let maxWidth = 227;
  if (locale !== 'en-US' && device === 'tape') {
    minLength = 101;
    maxLength = 280;
    minWidth = 115;
    maxWidth = 284;
  } else if (locale === 'en-US') {
    //  en-US - gauge
    minLength = 3.82;
    maxLength = 11.06;
    minWidth = 2.64;
    maxWidth = 8.94;
    if (device === 'tape') {
      minLength = 3.98;
      maxLength = 11.02;
      minWidth = 4.53;
      maxWidth = 11.18;
    }
  }

  const lengthError = `${minLength}-${maxLength}`;
  const widthError = `${minWidth}-${maxWidth}`;

  return yup.object({
    length: yup.number().min(minLength, lengthError).max(maxLength, lengthError).required(lengthError),
    width: yup.number().min(minWidth, widthError).max(maxWidth, widthError).required(widthError),
  });
};
