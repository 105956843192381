import styled, { css } from 'styled-components';
import { TypographyStyles, colours, fontWeights, fonts, hoverUpDeleteIcon, media, spacing } from '../../../stylings';
import { ProductLineItemType, getNonMultiImageSize } from './ProductLineItemImages';
import { ValidateStockStatusType } from './ProductLineItemList';

export const ProductNameWrapper = styled.div<{ $type?: ProductLineItemType }>`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.XXXS};

  @media ${media.greaterThan('lg')} {
    margin-bottom: ${({ $type }) => ($type === ProductLineItemType.MULTI_IMAGE ? spacing.XXS : spacing.XXXS)};
  }
`;
export const ProductName = styled.span`
  ${TypographyStyles.Label.Large.Medium}
  text-transform: uppercase;

  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
  }
`;
export const ProductDetailsBlock = styled.table`
  width: 100%;
`;

export const DetailsWrapper = styled.div`
  padding-left: ${spacing.XXS};

  @media ${media.greaterThan('lg')} {
    padding-left: ${spacing.XS};
  }
`;
export const LineItemWrapper = styled.div<{ $type?: string }>`
  display: grid;
  grid-template-columns: 100px 1fr;
  max-width: 740px;

  @media ${media.greaterThan('lg')} {
    grid-template-columns: ${({ $type }) => ($type === ProductLineItemType.MULTI_IMAGE ? '301px' : '110px')} 1fr;
  }
`;
export const DeleteButton = styled.button`
  background: none;
  border: 0;

  ${hoverUpDeleteIcon(true)}
  &:disabled {
    cursor: not-allowed;

    & svg path {
      fill: ${colours.GREY};
    }
  }
`;
export const ProductDetailsBlockWrapper = styled.div<{
  $isProductDetail: boolean;
}>`
  & table {
    background: none;
    border: none;
  }

  & th {
    background: none;
    border: none;
  }

  & td {
    border: none;

    &::before {
      display: none;
    }
  }

  @media ${media.greaterThan('lg')} {
    ${({ $isProductDetail }) =>
      $isProductDetail
        ? css`
            align-items: flex-start;
            display: flex;
          `
        : null}
  }
`;
export const SkuCapError = styled.p`
  ${TypographyStyles.Body.Tiny.SemiBold}
  color: ${colours.ERROR_PRIMARY};
  margin: 0;
`;
export const DiscountWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 0.7rem;
  padding-left: 0;
`;
export const Discount = styled.li`
  align-items: center;
  color: ${colours.SUCCESS_PRIMARY};
  display: flex;
  font-size: 14px;
  ${fonts.Montserrat}
  ${fontWeights.SemiBold600}
  gap: 0.4rem;
  line-height: 20px;
  overflow-wrap: anywhere;
  padding: 2px 0;
`;
export const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;
export const StockBanner = styled.div<{ $status?: ValidateStockStatusType | null }>`
  align-items: center;
  background-color: ${({ $status }) =>
    $status === ValidateStockStatusType.QUANTITY_ADJUSTED ? colours.WARNING_SECONDARY : colours.ERROR_SECONDARY};
  display: flex;
  height: 20px;
  justify-content: center;
  ${TypographyStyles.Label.Small.Medium}
  margin-top: ${spacing.XXXXS};
  text-transform: uppercase;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    height: 18px;
  }
`;
export const ImageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const GiftCardImageContainer = styled.a<{ $type?: string }>`
  background-color: ${colours.PALE_GREY};
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  @media ${media.greaterThan('lg')} {
    height: ${({ $type }) => getNonMultiImageSize($type, '198px')};
    margin-left: auto;
    width: ${({ $type }) => getNonMultiImageSize($type, '198px')};
  }
`;

export const FinalSaleContainer = styled.div`
  padding-bottom: ${spacing.XXXS};
`;
