import type { ReactNode } from 'react';
import { ApolloClient, ApolloProvider as ApolloClientProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { isServer } from '../../utils/ssr';

const httpLink = new HttpLink({
  uri: `${process.env.STOREFRONT_CDN_URL}/api/graphql`,
  headers: {
    ...(isServer() && {
      'x-api-key': process.env.APPSYNC_API_KEY,
    }),
  },
});

const apolloClientStoryBook = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([httpLink]),
});

export function ApolloStoryBook({ children }: { children: ReactNode }) {
  return <ApolloClientProvider client={apolloClientStoryBook}>{children}</ApolloClientProvider>;
}

export default apolloClientStoryBook;
