import { forwardRef } from 'react';
import { Root } from '@radix-ui/react-accordion';
import type { AccordionMultipleProps, AccordionSingleProps } from '@radix-ui/react-accordion';

export const AccordionRoot = forwardRef<HTMLDivElement, AccordionSingleProps | AccordionMultipleProps>(
  ({ children, ...props }, forwardedRef) => (
    <Root {...props} ref={forwardedRef}>
      {children}
    </Root>
  ),
);

export default AccordionRoot;
