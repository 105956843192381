import type { FinalSaleAttributes } from '../../codegen/types';
import { FinalSaleText } from './FinalSale.styles';

export function FinalSale({ text, textColor = '#D21C29', backgroundColor = undefined }: FinalSaleAttributes) {
  return (
    <FinalSaleText data-testid="finalSale" $textColor={textColor} $bgColor={backgroundColor}>
      {text}
    </FinalSaleText>
  );
}
