import { transformStringToNumber } from './transformers';

export const ascendingAlphaNum = (a: string, b: string) => {
  const numA = transformStringToNumber(a);
  const numB = transformStringToNumber(b);

  if (!Number.isNaN(numA) && Number.isNaN(numB)) return -1;
  if (Number.isNaN(numA) && !Number.isNaN(numB)) return 1;
  if (!Number.isNaN(numA) && !Number.isNaN(numB)) return numA - numB;

  return a?.localeCompare(b) ?? 1;
};
