import type { ReactElement } from 'react';
import {
  Amex,
  ApplePay,
  Dankort,
  Diners,
  Discover,
  Dotpay,
  EPS as EPSLogo,
  GiftCard,
  GiroPay,
  GooglePay,
  JCB as JCBLogo,
  Klarna,
  Mastercard,
  MisterCashMobile,
  MobilePay,
  PayPal,
  Przelewy24,
  Revolut,
  Sofortuberweisung,
  Trustly,
  Twint,
  Visa,
  iDeal,
} from '../../icons';

export enum PaymentMethodKeys {
  AMEX = 'amex',
  APPLE_PAY = 'applePay',
  GIFT_CARD = 'giftCard',
  GOOGLE_PAY = 'gPay',
  KLARNA = 'klarna',
  MASTER_CARD = 'masterCard',
  PAYPAL = 'payPal',
  VISA = 'visa',
  DINERS = 'diners',
  EPS = 'eps',
  SOFORTUBERWEISUNG = 'sofortuberweisung',
  MISTERCASH_MOBILE = 'mistercashMobile',
  MOBILE_PAY = 'mobilePay',
  GIRO_PAY = 'giroPay',
  IDEAL = 'iDeal',
  REVOLUT = 'revolut',
  DOT_PAY = 'dotPay',
  PRZELEWY24 = 'przelewy24',
  TWINT = 'twint',
  TRUSTLY = 'trustly',
  VISA_DANKORT = 'visaDankort',
  DISCOVER = 'discover',
  JCB = 'jcb',
}

enum MediaMatch {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

const iconMapper: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (props: any) => ReactElement | null;
} = {
  [PaymentMethodKeys.AMEX]: Amex,
  [PaymentMethodKeys.APPLE_PAY]: ApplePay,
  [PaymentMethodKeys.DINERS]: Diners,
  [PaymentMethodKeys.GIFT_CARD]: GiftCard,
  [PaymentMethodKeys.GIRO_PAY]: GiroPay,
  [PaymentMethodKeys.GOOGLE_PAY]: GooglePay,
  [PaymentMethodKeys.IDEAL]: iDeal,
  [PaymentMethodKeys.KLARNA]: Klarna,
  [PaymentMethodKeys.MASTER_CARD]: Mastercard,
  [PaymentMethodKeys.MISTERCASH_MOBILE]: MisterCashMobile,
  [PaymentMethodKeys.MOBILE_PAY]: MobilePay,
  [PaymentMethodKeys.PAYPAL]: PayPal,
  [PaymentMethodKeys.PRZELEWY24]: Przelewy24,
  [PaymentMethodKeys.TRUSTLY]: Trustly,
  [PaymentMethodKeys.TWINT]: Twint,
  [PaymentMethodKeys.VISA]: Visa,
  [PaymentMethodKeys.REVOLUT]: Revolut,
  [PaymentMethodKeys.EPS]: EPSLogo,
  [PaymentMethodKeys.SOFORTUBERWEISUNG]: Sofortuberweisung,
  [PaymentMethodKeys.DOT_PAY]: Dotpay,
  [PaymentMethodKeys.VISA_DANKORT]: Dankort,
  [PaymentMethodKeys.DISCOVER]: Discover,
  [PaymentMethodKeys.JCB]: JCBLogo,
};

export enum PaymentMethodsPlacement {
  PDP = 'PDP',
  CART = 'Cart',
  MINI_CART = 'Mini-Cart',
  FOOTER = 'Footer',
  CHECKOUT = 'Checkout',
}

const PaymentIconsSizes = {
  [PaymentMethodsPlacement.PDP]: {
    [PaymentMethodKeys.AMEX]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '24px',
        viewBox: '1 1 17 17',
      },
      [MediaMatch.MOBILE]: {
        height: '18.6px',
        width: '18.6px',
        viewBox: '1 1 17 17',
      },
    },
    [PaymentMethodKeys.APPLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '14px',
        width: '36px',
        viewBox: '0 0 33 14',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '28px',
        viewBox: '0 0 33 14',
      },
    },
    [PaymentMethodKeys.GIFT_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '13px',
        width: '19px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
      [MediaMatch.MOBILE]: {
        height: '13px',
        width: '19px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
    },
    [PaymentMethodKeys.GOOGLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '14px',
        width: '36px',
        viewBox: '0 0 36 15',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '28px',
        viewBox: '0 0 36 15',
      },
    },
    [PaymentMethodKeys.KLARNA]: {
      [MediaMatch.DESKTOP]: {
        height: '9px',
        width: '40px',
        viewBox: '0 0 41 10',
      },
      [MediaMatch.MOBILE]: {
        height: '6px',
        width: '25px',
        viewBox: '0 0 41 10',
      },
    },
    [PaymentMethodKeys.MASTER_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '28px',
        viewBox: '0 0 23 18',
      },
      [MediaMatch.MOBILE]: {
        height: '18px',
        width: '22px',
        viewBox: '0 0 23 18',
      },
    },
    [PaymentMethodKeys.PAYPAL]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '24px',
        viewBox: '5 3 14 18',
      },
      [MediaMatch.MOBILE]: {
        height: '18.6px',
        width: '18.6px',
        viewBox: '5 3 14 18',
      },
    },
    [PaymentMethodKeys.VISA]: {
      [MediaMatch.DESKTOP]: {
        height: '11px',
        width: '34px',
        viewBox: '0 0 37 12',
      },
      [MediaMatch.MOBILE]: {
        height: '8.5px',
        width: '26.2px',
        viewBox: '0 0 37 12',
      },
    },
    [PaymentMethodKeys.DINERS]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.EPS]: {
      [MediaMatch.DESKTOP]: {
        height: '40px',
        width: '34px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '34px',
        viewBox: '-10 0 143 142',
      },
    },
    [PaymentMethodKeys.SOFORTUBERWEISUNG]: {
      [MediaMatch.DESKTOP]: {
        height: '46px',
        width: '48px',
        viewBox: '0 0 108 41',
      },
      [MediaMatch.MOBILE]: {
        height: '24px',
        width: '28px',
        viewBox: '0 0 108 41',
      },
    },
    [PaymentMethodKeys.MISTERCASH_MOBILE]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.MOBILE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '54px',
        width: '54px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.GIRO_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.IDEAL]: {
      [MediaMatch.DESKTOP]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.REVOLUT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.DOT_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '24px',
        width: '24px',
        viewBox: '0 0 143 142',
      },
    },
    [PaymentMethodKeys.PRZELEWY24]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TWINT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TRUSTLY]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 1250 416',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '34px',
        viewBox: '0 0 1250 416',
      },
    },
    [PaymentMethodKeys.VISA_DANKORT]: {
      [MediaMatch.DESKTOP]: {
        height: '48px',
        width: '48px',
        viewBox: '0 0 2006 1209',
      },
      [MediaMatch.MOBILE]: {
        height: '28px',
        width: '28px',
        viewBox: '0 0 2006 1209',
      },
    },
    [PaymentMethodKeys.DISCOVER]: {
      [MediaMatch.DESKTOP]: {
        height: '52px',
        width: '52px',
        viewBox: '0 0 780 500',
      },
      [MediaMatch.MOBILE]: {
        height: '52px',
        width: '52px',
        viewBox: '0 0 780 500',
      },
    },
    [PaymentMethodKeys.JCB]: {
      [MediaMatch.DESKTOP]: {
        height: '52px',
        width: '52px',
        viewBox: '0 0 512 512',
      },
      [MediaMatch.MOBILE]: {
        height: '52px',
        width: '52px',
        viewBox: '0 0 512 512',
      },
    },
  },
  [PaymentMethodsPlacement.CART]: {
    [PaymentMethodKeys.AMEX]: {
      [MediaMatch.DESKTOP]: {
        height: '15px',
        width: '15px',
        viewBox: '1 1 17 17',
      },
      [MediaMatch.MOBILE]: {
        height: '18.6px',
        width: '18.6px',
        viewBox: '1 1 17 17',
      },
    },
    [PaymentMethodKeys.APPLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '9.6px',
        width: '22px',
        viewBox: '0 0 33 14',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '28px',
        viewBox: '0 0 33 14',
      },
    },
    [PaymentMethodKeys.GIFT_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '13px',
        width: '19px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
      [MediaMatch.MOBILE]: {
        height: '13px',
        width: '19px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
    },
    [PaymentMethodKeys.GOOGLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '9.2px',
        width: '22.9px',
        viewBox: '0 0 36 15',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '28px',
        viewBox: '0 0 36 15',
      },
    },
    [PaymentMethodKeys.KLARNA]: {
      [MediaMatch.DESKTOP]: {
        height: '8.2px',
        width: '25.5px',
        viewBox: '0 0 41 10',
      },
      [MediaMatch.MOBILE]: {
        height: '6px',
        width: '25px',
        viewBox: '0 0 41 10',
      },
    },
    [PaymentMethodKeys.MASTER_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '15px',
        width: '18px',
        viewBox: '0 0 23 18',
      },
      [MediaMatch.MOBILE]: {
        height: '18px',
        width: '22px',
        viewBox: '0 0 23 18',
      },
    },
    [PaymentMethodKeys.PAYPAL]: {
      [MediaMatch.DESKTOP]: {
        height: '15px',
        width: '15px',
        viewBox: '5 3 14 18',
      },
      [MediaMatch.MOBILE]: {
        height: '18.6px',
        width: '18.6px',
        viewBox: '5 3 14 18',
      },
    },
    [PaymentMethodKeys.VISA]: {
      [MediaMatch.DESKTOP]: {
        height: '7px',
        width: '21.3px',
        viewBox: '0 0 37 12',
      },
      [MediaMatch.MOBILE]: {
        height: '8.5px',
        width: '26.2px',
        viewBox: '0 0 37 12',
      },
    },
    [PaymentMethodKeys.DINERS]: {
      [MediaMatch.DESKTOP]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.EPS]: {
      [MediaMatch.DESKTOP]: {
        height: '30px',
        width: '24px',
        viewBox: '-10 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '24px',
        viewBox: '-10 0 143 142',
      },
    },
    [PaymentMethodKeys.SOFORTUBERWEISUNG]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '48px',
        viewBox: '0 0 108 41',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '28px',
        viewBox: '0 0 108 41',
      },
    },
    [PaymentMethodKeys.MISTERCASH_MOBILE]: {
      [MediaMatch.DESKTOP]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.MOBILE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '54px',
        width: '54px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.GIRO_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.IDEAL]: {
      [MediaMatch.DESKTOP]: {
        height: '20px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.REVOLUT]: {
      [MediaMatch.DESKTOP]: {
        height: '36px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.DOT_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '20px',
        width: '30px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '20px',
        width: '30px',
        viewBox: '0 0 143 142',
      },
    },
    [PaymentMethodKeys.PRZELEWY24]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TWINT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TRUSTLY]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 1250 416',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '34px',
        viewBox: '0 0 1250 416',
      },
    },
    [PaymentMethodKeys.VISA_DANKORT]: {
      [MediaMatch.DESKTOP]: {
        height: '48px',
        width: '48px',
        viewBox: '0 0 2006 1209',
      },
      [MediaMatch.MOBILE]: {
        height: '28px',
        width: '28px',
        viewBox: '0 0 2006 1209',
      },
    },
    [PaymentMethodKeys.DISCOVER]: {
      [MediaMatch.DESKTOP]: {
        height: '48px',
        width: '48px',
        viewBox: '0 0 780 500',
      },
      [MediaMatch.MOBILE]: {
        height: '28px',
        width: '28px',
        viewBox: '0 0 780 500',
      },
    },
    [PaymentMethodKeys.JCB]: {
      [MediaMatch.DESKTOP]: {
        height: '48px',
        width: '48px',
        viewBox: '0 0 512 512',
      },
      [MediaMatch.MOBILE]: {
        height: '28px',
        width: '28px',
        viewBox: '0 0 512 512',
      },
    },
  },
  [PaymentMethodsPlacement.MINI_CART]: {
    [PaymentMethodKeys.AMEX]: {
      [MediaMatch.DESKTOP]: {
        height: '15.1px',
        width: '15.1px',
        viewBox: '1 1 17 17',
      },
      [MediaMatch.MOBILE]: {
        height: '12.5px',
        width: '12.5px',
        viewBox: '1 1 17 17',
      },
    },
    [PaymentMethodKeys.APPLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '9.6px',
        width: '22px',
        viewBox: '0 0 33 14',
      },
      [MediaMatch.MOBILE]: {
        height: '8px',
        width: '19px',
        viewBox: '0 0 33 14',
      },
    },
    [PaymentMethodKeys.GIFT_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '10px',
        width: '13px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '17px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
    },
    [PaymentMethodKeys.GOOGLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '9.2px',
        width: '22.9px',
        viewBox: '0 0 36 15',
      },
      [MediaMatch.MOBILE]: {
        height: '7.6px',
        width: '18.9px',
        viewBox: '0 0 36 15',
      },
    },
    [PaymentMethodKeys.KLARNA]: {
      [MediaMatch.DESKTOP]: {
        height: '5.7px',
        width: '25.5px',
        viewBox: '0 0 41 10',
      },
      [MediaMatch.MOBILE]: {
        height: '4.7px',
        width: '21px',
        viewBox: '0 0 41 10',
      },
    },
    [PaymentMethodKeys.MASTER_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '14.9px',
        width: '18px',
        viewBox: '0 0 23 18',
      },
      [MediaMatch.MOBILE]: {
        height: '12.3px',
        width: '14.9px',
        viewBox: '0 0 23 18',
      },
    },
    [PaymentMethodKeys.PAYPAL]: {
      [MediaMatch.DESKTOP]: {
        height: '15px',
        width: '15px',
        viewBox: '5 3 14 18',
      },
      [MediaMatch.MOBILE]: {
        height: '16px',
        width: '16px',
        viewBox: '5 3 14 18',
      },
    },
    [PaymentMethodKeys.VISA]: {
      [MediaMatch.DESKTOP]: {
        height: '6.9px',
        width: '21.2px',
        viewBox: '0 0 37 12',
      },
      [MediaMatch.MOBILE]: {
        height: '5.7px',
        width: '17.5px',
        viewBox: '0 0 37 12',
      },
    },
    [PaymentMethodKeys.DINERS]: {
      [MediaMatch.DESKTOP]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.EPS]: {
      [MediaMatch.DESKTOP]: {
        height: '30px',
        width: '24px',
        viewBox: '-10 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '24px',
        viewBox: '-10 0 143 142',
      },
    },
    [PaymentMethodKeys.SOFORTUBERWEISUNG]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '28px',
        viewBox: '0 0 108 41',
      },
      [MediaMatch.MOBILE]: {
        height: '24px',
        width: '28px',
        viewBox: '0 0 108 41',
      },
    },
    [PaymentMethodKeys.MISTERCASH_MOBILE]: {
      [MediaMatch.DESKTOP]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '25px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.MOBILE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '54px',
        width: '54px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.GIRO_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.IDEAL]: {
      [MediaMatch.DESKTOP]: {
        height: '20px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '20px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.REVOLUT]: {
      [MediaMatch.DESKTOP]: {
        height: '36px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.DOT_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '20px',
        width: '30px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '18px',
        width: '30px',
        viewBox: '0 0 143 142',
      },
    },
    [PaymentMethodKeys.PRZELEWY24]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TWINT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TRUSTLY]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 1250 416',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '34px',
        viewBox: '0 0 1250 416',
      },
    },
    [PaymentMethodKeys.VISA_DANKORT]: {
      [MediaMatch.DESKTOP]: {
        height: '48px',
        width: '48px',
        viewBox: '0 0 2006 1209',
      },
      [MediaMatch.MOBILE]: {
        height: '28px',
        width: '28px',
        viewBox: '0 0 2006 1209',
      },
    },
    [PaymentMethodKeys.DISCOVER]: {
      [MediaMatch.DESKTOP]: {
        height: '48px',
        width: '48px',
        viewBox: '0 0 780 500',
      },
      [MediaMatch.MOBILE]: {
        height: '28px',
        width: '28px',
        viewBox: '0 0 780 500',
      },
    },
    [PaymentMethodKeys.JCB]: {
      [MediaMatch.DESKTOP]: {
        height: '48px',
        width: '48px',
        viewBox: '0 0 512 512',
      },
      [MediaMatch.MOBILE]: {
        height: '28px',
        width: '28px',
        viewBox: '0 0 512 512',
      },
    },
  },
  [PaymentMethodsPlacement.CHECKOUT]: {
    [PaymentMethodKeys.AMEX]: {
      [MediaMatch.DESKTOP]: {
        height: '15.1px',
        width: '15.1px',
        viewBox: '1 1 17 17',
      },
      [MediaMatch.MOBILE]: {
        height: '12.5px',
        width: '12.5px',
        viewBox: '1 1 17 17',
      },
    },
    [PaymentMethodKeys.APPLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '9.6px',
        width: '22px',
        viewBox: '0 0 33 14',
      },
      [MediaMatch.MOBILE]: {
        height: '8px',
        width: '19px',
        viewBox: '0 0 33 14',
      },
    },
    [PaymentMethodKeys.GIFT_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '10px',
        width: '13px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '17px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
    },
    [PaymentMethodKeys.GOOGLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '9.2px',
        width: '22.9px',
        viewBox: '0 0 36 15',
      },
      [MediaMatch.MOBILE]: {
        height: '7.6px',
        width: '18.9px',
        viewBox: '0 0 36 15',
      },
    },
    [PaymentMethodKeys.KLARNA]: {
      [MediaMatch.DESKTOP]: {
        height: '5.7px',
        width: '25.5px',
        viewBox: '0 0 41 10',
      },
      [MediaMatch.MOBILE]: {
        height: '4.7px',
        width: '21px',
        viewBox: '0 0 41 10',
      },
    },
    [PaymentMethodKeys.MASTER_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '14.9px',
        width: '18px',
        viewBox: '0 0 23 18',
      },
      [MediaMatch.MOBILE]: {
        height: '12.3px',
        width: '14.9px',
        viewBox: '0 0 23 18',
      },
    },
    [PaymentMethodKeys.PAYPAL]: {
      [MediaMatch.DESKTOP]: {
        height: '15px',
        width: '15px',
        viewBox: '5 3 14 18',
      },
      [MediaMatch.MOBILE]: {
        height: '16px',
        width: '16px',
        viewBox: '5 3 14 18',
      },
    },
    [PaymentMethodKeys.VISA]: {
      [MediaMatch.DESKTOP]: {
        height: '6.9px',
        width: '21.2px',
        viewBox: '0 0 37 12',
      },
      [MediaMatch.MOBILE]: {
        height: '5.7px',
        width: '17.5px',
        viewBox: '0 0 37 12',
      },
    },

    [PaymentMethodKeys.DINERS]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.EPS]: {
      [MediaMatch.DESKTOP]: {
        height: '40px',
        width: '34px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '34px',
        viewBox: '-10 0 143 142',
      },
    },
    [PaymentMethodKeys.SOFORTUBERWEISUNG]: {
      [MediaMatch.DESKTOP]: {
        height: '46px',
        width: '48px',
        viewBox: '0 0 108 41',
      },
      [MediaMatch.MOBILE]: {
        height: '24px',
        width: '28px',
        viewBox: '0 0 108 41',
      },
    },
    [PaymentMethodKeys.MISTERCASH_MOBILE]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.MOBILE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '54px',
        width: '54px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.GIRO_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.IDEAL]: {
      [MediaMatch.DESKTOP]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.REVOLUT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.DOT_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '24px',
        width: '24px',
        viewBox: '0 0 143 142',
      },
    },
    [PaymentMethodKeys.PRZELEWY24]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TWINT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TRUSTLY]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 1250 416',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '34px',
        viewBox: '0 0 1250 416',
      },
    },
    [PaymentMethodKeys.VISA_DANKORT]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 2006 1209',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 2006 1209',
      },
    },
    [PaymentMethodKeys.DISCOVER]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 780 500',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 780 500',
      },
    },
    [PaymentMethodKeys.JCB]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 512 512',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 512 512',
      },
    },
  },
  [PaymentMethodsPlacement.FOOTER]: {
    [PaymentMethodKeys.AMEX]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '24px',
        viewBox: '1 1 17 17',
      },
      [MediaMatch.MOBILE]: {
        height: '18.6px',
        width: '18.6px',
        viewBox: '1 1 17 17',
      },
    },
    [PaymentMethodKeys.APPLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '14px',
        width: '36px',
        viewBox: '0 0 33 14',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '28px',
        viewBox: '0 0 33 14',
      },
    },
    [PaymentMethodKeys.GIFT_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '13px',
        width: '19px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
      [MediaMatch.MOBILE]: {
        height: '13px',
        width: '19px',
        viewBox: '0.2 0.2 14.5 10.5',
      },
    },
    [PaymentMethodKeys.GOOGLE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '14px',
        width: '36px',
        viewBox: '0 0 36 15',
      },
      [MediaMatch.MOBILE]: {
        height: '11px',
        width: '28px',
        viewBox: '0 0 36 15',
      },
    },
    [PaymentMethodKeys.KLARNA]: {
      [MediaMatch.DESKTOP]: {
        height: '9px',
        width: '40px',
        viewBox: '0 0 41 10',
      },
      [MediaMatch.MOBILE]: {
        height: '6px',
        width: '25px',
        viewBox: '0 0 41 10',
      },
    },
    [PaymentMethodKeys.MASTER_CARD]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '28px',
        viewBox: '0 0 23 18',
      },
      [MediaMatch.MOBILE]: {
        height: '18px',
        width: '22px',
        viewBox: '0 0 23 18',
      },
    },
    [PaymentMethodKeys.PAYPAL]: {
      [MediaMatch.DESKTOP]: {
        height: '24px',
        width: '24px',
        viewBox: '5 3 14 18',
      },
      [MediaMatch.MOBILE]: {
        height: '18.6px',
        width: '18.6px',
        viewBox: '5 3 14 18',
      },
    },
    [PaymentMethodKeys.VISA]: {
      [MediaMatch.DESKTOP]: {
        height: '11px',
        width: '34px',
        viewBox: '0 0 37 12',
      },
      [MediaMatch.MOBILE]: {
        height: '8.5px',
        width: '26.2px',
        viewBox: '0 0 37 12',
      },
    },
    [PaymentMethodKeys.DINERS]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.EPS]: {
      [MediaMatch.DESKTOP]: {
        height: '40px',
        width: '34px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '34px',
        viewBox: '-10 0 143 142',
      },
    },
    [PaymentMethodKeys.SOFORTUBERWEISUNG]: {
      [MediaMatch.DESKTOP]: {
        height: '46px',
        width: '48px',
        viewBox: '0 0 108 41',
      },
      [MediaMatch.MOBILE]: {
        height: '24px',
        width: '28px',
        viewBox: '0 0 108 41',
      },
    },
    [PaymentMethodKeys.MISTERCASH_MOBILE]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.MOBILE_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '54px',
        width: '54px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.GIRO_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.IDEAL]: {
      [MediaMatch.DESKTOP]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '26px',
        width: '26px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.REVOLUT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.DOT_PAY]: {
      [MediaMatch.DESKTOP]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 143 142',
      },
      [MediaMatch.MOBILE]: {
        height: '24px',
        width: '24px',
        viewBox: '0 0 143 142',
      },
    },
    [PaymentMethodKeys.PRZELEWY24]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '34px',
        width: '34px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TWINT]: {
      [MediaMatch.DESKTOP]: {
        height: '44px',
        width: '44px',
        viewBox: '0 0 34 34',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 34 34',
      },
    },
    [PaymentMethodKeys.TRUSTLY]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 1250 416',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '34px',
        viewBox: '0 0 1250 416',
      },
    },
    [PaymentMethodKeys.VISA_DANKORT]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 2006 1209',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 2006 1209',
      },
    },
    [PaymentMethodKeys.DISCOVER]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 780 500',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 780 500',
      },
    },
    [PaymentMethodKeys.JCB]: {
      [MediaMatch.DESKTOP]: {
        height: '50px',
        width: '50px',
        viewBox: '0 0 512 512',
      },
      [MediaMatch.MOBILE]: {
        height: '30px',
        width: '30px',
        viewBox: '0 0 512 512',
      },
    },
  },
};

type RenderIconArgs = {
  iconKey: PaymentMethodKeys;
  labelledBy: string;
  isDesktop: boolean;
  placement: PaymentMethodsPlacement;
};

export const renderIcon = ({ iconKey, labelledBy, isDesktop, placement }: RenderIconArgs) => {
  const SVGIcon = iconMapper[iconKey];
  const { height, width, viewBox } = PaymentIconsSizes[placement]?.[iconKey]?.[
    isDesktop ? MediaMatch.DESKTOP : MediaMatch.MOBILE
  ] ?? { height: '', width: '', viewBox: '' };

  return SVGIcon && <SVGIcon height={height} width={width} viewBox={viewBox} aria-labelledby={labelledBy} />;
};
