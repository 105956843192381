import type { ChangeEvent, SyntheticEvent } from 'react';
import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import type { ApolloError } from '@apollo/client';
import { storeKeyMapper, upperCasePromoCode } from '@shared/utils/src/ct';
import { BlueButton } from '../../../baseComponents/button';
import type { Cart } from '../../../codegen/types';
import { useCartContext } from '../../../context/cartContext';
import { useCheckoutConfirmationContext } from '../../../context/checkoutConfirmationContext';
import { useLocaleContext } from '../../../context/localeContext';
import { useApplyDiscountMutation } from '../../../graphqlDocument/cart/applyDiscount.generated';
import { useRemoveDiscountMutation } from '../../../graphqlDocument/cart/removeDiscount.generated';
import { useNRBASession } from '../../../hooks/useNRBASession';
import { ArrowDown, CheckoutCloseSmall, PriceTag } from '../../../icons';
import type { BffError } from '../../../services/errors';
import {
  TypographyStyles,
  accordionAnimation,
  accordionArrowAnimation,
  colours,
  media,
  spacing,
} from '../../../stylings';
import { getAmount } from '../../../utils/currencyHelper';
import { sendAddCouponEvent } from '../../../utils/gtm/events/add_coupon/sendAddCouponEvent';
import { Locale, getCtLocale } from '../../../utils/localeHelper';

const S = {
  Container: styled.div<{ $isShowing?: boolean }>`
    margin-bottom: ${spacing.S};

    @media ${media.greaterThan('lg')} {
      max-width: 400px;
    }
  `,
  TitleContainer: styled.div`
    align-items: center;
    display: flex;
    gap: ${spacing.XXXS};
    margin-bottom: ${spacing.XXS};
  `,
  Title: styled.p`
    ${TypographyStyles.Label.Large.Medium}
    color: ${colours.BLACK};
    margin: 0;
    text-transform: uppercase;
  `,
  ButtonContainer: styled.button<{ $isShowing: boolean }>`
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    user-select: none;

    ${({ $isShowing }) => accordionArrowAnimation($isShowing)}
  `,
  FormContainer: styled.div<{ $isShowing: boolean; $isInitialLoad: boolean }>`
    ${({ $isShowing, $isInitialLoad }) => ($isInitialLoad ? null : accordionAnimation($isShowing))}
    max-height: ${({ $isShowing }) => ($isShowing ? '2000px' : '0')};
    overflow: hidden;
  `,
  PromoBoxForm: styled.form`
    display: flex;
    justify-content: space-between;
  `,
  PromoInput: styled.input<{ $isError: boolean }>`
    ${TypographyStyles.Body.Medium.Regular}
    border: 1px solid ${colours.BLACK};
    margin-right: ${spacing.XXXS};
    padding: ${spacing.XXXS} ${spacing.XXS};
    width: 100%;
    ${({ $isError }) =>
      $isError &&
      css`
        border: 1px solid transparent;
        outline: 1px solid ${colours.ERROR_PRIMARY};
        outline-offset: -1px;
      `}

    &::placeholder {
      color: ${colours.GREY};
    }
  `,
  AddButton: styled(BlueButton)`
    height: auto;
    margin: 0;
    padding: ${spacing.XXS} 27px;

    &:disabled {
      background-color: ${colours.LIGHT_GREY_2};
      color: ${colours.GREY};
      cursor: not-allowed;
    }
  `,
  ErrorMessage: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
    color: ${colours.ERROR_PRIMARY};
    overflow-wrap: anywhere;
  `,
  NotificationMessage: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
    color: ${colours.SUCCESS_PRIMARY};
    overflow-wrap: anywhere;
  `,
  LozengeContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${spacing.XXXS};
    margin-top: ${spacing.XXS};
  `,
  Lozenge: styled.div`
    ${TypographyStyles.Body.Tiny.Regular}
    align-items: center;
    background-color: ${colours.NAVY_BLUE};
    border: none;
    color: ${colours.WHITE};
    display: flex;
    max-width: fit-content;
    padding: 0 6px;
  `,
  PromoName: styled.span`
    margin: 0 7px 0 ${spacing.XXXXS};
  `,
  RemoveButton: styled.button`
    align-items: center;
    background: transparent;
    border: none;
    display: flex;
    padding: 0;
    path {
      fill: ${colours.WHITE};
    }
  `,
  OrderSummaryTitle: styled.h3`
    color: ${colours.BLACK};
    margin: 0 0 20px;
    text-transform: uppercase;
    ${TypographyStyles.Headings.H3}

    @media ${media.greaterThan('lg')} {
      margin-bottom: ${spacing.S};
    }
  `,
};

enum DiscountCodeError {
  DUPLICATE_PROMO_ERROR = 'DiscountCodeErrorDuplicatedPromoCode',
  PROMO_LIMIT_EXCEEDED = 'DiscountCodeErrorPromoCodeLimitExceeded',
  DUPLICATE_CART_PROMOTION = 'DiscountCodeErrorDuplicatedPromotion',
  DISCOUNT_GENERAL_ERROR = 'DiscountCodeErrorDiscountCodeNonApplicable',
  DISCOUNT_CART_HAS_GIFT_CARD = 'DiscountCodeErrorCartHasGiftCard',
}

export function PromoBox({ isStar2 = false }: { isStar2?: boolean }) {
  const { t } = useTranslation(['lib-global-common']);
  const router = useRouter();
  const { cartId, anonymousId, miniCart, setMiniCart, setShouldUpdateDeliveryOptions } = useCartContext();
  const { orderData } = useCheckoutConfirmationContext();
  const { discountCodes } = { ...miniCart };
  const { locale } = useLocaleContext();
  const [togglePromoBox, setTogglePromoBox] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const currentLocale = locale ?? Locale.DEFAULT_LOCALE;
  const storeKey = storeKeyMapper(currentLocale);
  const nrbaContextHeader = useNRBASession();
  const [applyDiscountMutation, { loading: applyIsLoading }] = useApplyDiscountMutation(nrbaContextHeader);
  const [removeDiscountMutation] = useRemoveDiscountMutation(nrbaContextHeader);
  const isConfirmationPage = router.asPath === '/checkout/confirmation';
  const isConfirmationPageDiscountCodes = isConfirmationPage ? orderData?.getOrderedCart.discountCodes : discountCodes;
  const initialLoad = useRef(true);

  const handleToggle = () => {
    initialLoad.current = false;
    setTogglePromoBox((prev) => !prev);
  };
  const handleInputPromo = (event: ChangeEvent<HTMLInputElement>) => setPromoCode(event.target.value);

  const applyDiscount = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (promoCode) {
      const input = {
        cartId,
        anonymousId,
        storeKey,
        locale: getCtLocale(currentLocale),
        discountCode: upperCasePromoCode(promoCode),
      };

      try {
        const { data } = await applyDiscountMutation({
          variables: { input },
          onCompleted: ({ applyDiscount: applyDiscountData }) => {
            setMiniCart(applyDiscountData as Cart);
            setShouldUpdateDeliveryOptions(true);
            setPromoCode('');
          },
        });

        if (data) {
          if (errorMessage) setErrorMessage('');

          const coupons = data.applyDiscount?.discountCodes
            ?.map((code) => code?.discountCode || code?.discountName)
            .join(', ');

          if (!coupons) return;

          sendAddCouponEvent(
            { coupon: coupons, coupon_value: +getAmount(data.applyDiscount?.discountTotal) },
            data.applyDiscount.id,
          );

          if (isStar2) {
            setNotificationMessage(t('cart.promo.notification.message.generic'));
            router.reload();
          }
        }
      } catch (e) {
        const error = e as ApolloError;
        const bffError = error.graphQLErrors?.[0] as unknown as BffError;
        const bffErrorInfo = bffError?.errorInfo?.[0];
        if (notificationMessage) setNotificationMessage('');

        switch (bffError.errorType) {
          case DiscountCodeError.DUPLICATE_PROMO_ERROR:
            setErrorMessage(t('cart.promo.error.message.duplicate'));
            break;
          case DiscountCodeError.PROMO_LIMIT_EXCEEDED:
            setErrorMessage(t('cart.promo.error.message.limit.exceeded'));
            break;
          case DiscountCodeError.DUPLICATE_CART_PROMOTION:
            setErrorMessage(t('cart.promo.error.message.promo.duplicate', { discountName: bffErrorInfo?.code }));
            break;
          case DiscountCodeError.DISCOUNT_CART_HAS_GIFT_CARD:
            setErrorMessage(t('cart.promo.error.has.gift.card'));
            break;
          default:
            setErrorMessage(t('cart.promo.error.message.generic'));
            break;
        }
        console.error(error);
      }
    }
  };

  const removeDiscount = (discountId: string) => async () => {
    const input = {
      cartId,
      anonymousId,
      storeKey,
      locale: getCtLocale(currentLocale),
      discountId,
    };
    try {
      await removeDiscountMutation({
        variables: { input },
        onCompleted: ({ removeDiscount: removeDiscountData }) => {
          setMiniCart(removeDiscountData as Cart);
          setShouldUpdateDeliveryOptions(true);
          if (isStar2) {
            router.reload();
          }
        },
      });
      if (notificationMessage) setNotificationMessage('');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <S.Container data-testid="promoBoxContainer">
      {!isConfirmationPage ? (
        <>
          <S.TitleContainer>
            <S.ButtonContainer
              type="button"
              onClick={handleToggle}
              data-testid="openPromoboxButton"
              aria-label={t('cart.promo.title')}
              $isShowing={togglePromoBox}
            >
              <S.Title>{t('cart.promo.title')}</S.Title>
              <ArrowDown aria-hidden className="accordion-arrow-down" />
            </S.ButtonContainer>
          </S.TitleContainer>
          <S.FormContainer $isShowing={togglePromoBox} $isInitialLoad={initialLoad.current}>
            <S.PromoBoxForm onSubmit={applyDiscount} method="post">
              <S.PromoInput
                placeholder={t('cart.promo.input.placeholder')}
                aria-label={t('cart.promo.input.placeholder')}
                type="text"
                maxLength={163}
                value={promoCode}
                onChange={handleInputPromo}
                $isError={!!errorMessage}
                data-testid="promoInput"
                tabIndex={!togglePromoBox ? -1 : undefined}
                onBlur={(e) => {
                  e.target.value = promoCode;
                }}
              />
              <S.AddButton
                type="submit"
                disabled={applyIsLoading}
                data-testid="addPromoButton"
                tabIndex={!togglePromoBox ? -1 : undefined}
              >
                {t('cart.promo.add')}
              </S.AddButton>
            </S.PromoBoxForm>
            <S.ErrorMessage role="alert" data-testid="promoErrorMessage">
              {errorMessage}
            </S.ErrorMessage>
          </S.FormContainer>
        </>
      ) : null}

      {isConfirmationPage ? <S.OrderSummaryTitle>{t('promo.box.order.summary')}</S.OrderSummaryTitle> : null}

      {!!isConfirmationPageDiscountCodes?.length && (
        <S.LozengeContainer>
          {isConfirmationPageDiscountCodes?.map((code) => {
            const { discountCode, discountId } = { ...code };
            return (
              <S.Lozenge key={discountId} data-testid="promoLozenge">
                <PriceTag />
                <S.PromoName data-testid="promoName">{discountCode}</S.PromoName>
                {!isConfirmationPage ? (
                  <S.RemoveButton
                    aria-label={t('cart.aria.remove.discount', { discountName: discountCode })}
                    type="button"
                    onClick={removeDiscount(discountId as string)}
                    data-testid="removePromoButton"
                  >
                    <CheckoutCloseSmall />
                  </S.RemoveButton>
                ) : null}
              </S.Lozenge>
            );
          })}
        </S.LozengeContainer>
      )}
      <S.NotificationMessage role="alert">
        {isStar2 && discountCodes?.length ? t('cart.promo.notification.message.generic') : null}
      </S.NotificationMessage>
    </S.Container>
  );
}
