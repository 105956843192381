import type { RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange';
import { useTranslation } from 'next-i18next';
import FilterAccordion from './FilterAccordion';
import PriceSlider from './PriceSlider';

export type PriceFilterProps = Omit<RangeRenderState, 'canRefine' | 'sendEvent' | 'format'>;

export function PriceFilter({ start, range, refine }: PriceFilterProps) {
  const { t } = useTranslation(['lib-global-common']);

  if (range?.min === range?.max) {
    return null;
  }

  const isFilterActive = start[0] !== -Infinity || start[1] !== Infinity;

  const handleOnChange = ({ min, max }: { min: number; max: number }) => {
    let adjustedMin = min * 100;
    let adjustedMax = max * 100;

    if (range?.min && adjustedMin < range?.min) {
      adjustedMin = range?.min;
    }

    if (range?.max && (Math.trunc(adjustedMax / 100) === Math.trunc(range.max / 100) || adjustedMax > range.max)) {
      adjustedMax = range?.max;
    }

    refine([adjustedMin, adjustedMax]);
  };

  return (
    <FilterAccordion
      triggerLabel={t('filter.menu.filter.price')}
      content={<PriceSlider range={range} start={start} onChange={handleOnChange} />}
      isOpen={isFilterActive}
      testId="priceFilterAccordion"
    />
  );
}
