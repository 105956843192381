import type { Hit } from '@algolia/client-search';
import type { StoreType } from '../../types/store';
import { getAlgoliaStoreIndex } from './searchClient';

export type AlgoliaStoreType = Hit<StoreType>;
export const getStoresWithDistanceByIds = async (
  storeIds: string[],
  aroundLat: number,
  aroundLng: number,
): Promise<AlgoliaStoreType[]> => {
  if (!storeIds.length) return [];

  const storeFilter = `objectID:${storeIds.join(' OR objectID:')}`;
  const index = getAlgoliaStoreIndex();

  try {
    const storesData = await index.search<StoreType>('', {
      hitsPerPage: 1000,
      getRankingInfo: true,
      aroundLatLng: `${aroundLat}, ${aroundLng}`,
      filters: storeFilter,
    });
    return storesData.hits;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export interface StoreFilters {
  kidsFittingOnly?: boolean;
}

export enum StoreFeatures {
  KIDS_FITTING = 'kidsFitting',
}

// NOTE: Algolia OOTB doesn't support 'AND' conditions on multiple nested objects in an array
const filterStores = async (stores: AlgoliaStoreType[], filters?: StoreFilters): Promise<AlgoliaStoreType[]> =>
  stores.filter((store) =>
    filters?.kidsFittingOnly
      ? store.features?.some(({ name, isActive }) => name === StoreFeatures.KIDS_FITTING && isActive)
      : true,
  );

export const getAllStores = async (
  aroundLat: number,
  aroundLng: number,
  filters?: StoreFilters,
): Promise<AlgoliaStoreType[]> => {
  const index = getAlgoliaStoreIndex();

  try {
    const storesData = await index.search<StoreType>('', {
      hitsPerPage: 1000,
      getRankingInfo: true,
      aroundLatLng: `${aroundLat}, ${aroundLng}`,
      facetFilters: filters?.kidsFittingOnly
        ? [`features.name:${StoreFeatures.KIDS_FITTING}`, `features.isActive:${filters.kidsFittingOnly}`]
        : [],
    });
    return filterStores(storesData.hits, filters);
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getStoresByAttributeValues = async (
  attributeName: string,
  attributeValues: string[],
): Promise<AlgoliaStoreType[]> => {
  if (!attributeValues.length) return [];
  const storeFilter = `${attributeName}:${attributeValues.join(` OR ${attributeName}:`)}`;
  const index = getAlgoliaStoreIndex();

  try {
    const storesData = await index.search<StoreType>('', {
      hitsPerPage: 1000,
      filters: storeFilter,
    });
    return storesData.hits;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getStoresByIds = async (storeIds: string[]): Promise<AlgoliaStoreType[]> =>
  getStoresByAttributeValues('objectID', storeIds);

export const getStoresByAppointeddIds = async (storeAppointeddIds: string[]): Promise<AlgoliaStoreType[]> => {
  // Since several appointments can be booked in the same store, for performance improvement it is better to remove duplicates before searching
  const uniqueStoreAppointeddIds = storeAppointeddIds.filter((id, index, ids) => ids.indexOf(id) === index);
  return getStoresByAttributeValues('appointeddStoreId', uniqueStoreAppointeddIds);
};

export const getStoresAroundRadius = async (
  aroundLat: number,
  aroundLng: number,
  radius?: number,
  hitsPerPage?: number,
): Promise<AlgoliaStoreType[]> => {
  const index = getAlgoliaStoreIndex();

  try {
    const storesData = await index.search<StoreType>('', {
      hitsPerPage,
      getRankingInfo: true,
      aroundLatLng: `${aroundLat}, ${aroundLng}`,
      aroundRadius: radius || 'all',
    });

    return storesData.hits;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getStoreByObjectId = async (storeId: string) => {
  const index = getAlgoliaStoreIndex();

  try {
    const result = await index.search<StoreType>('', {
      filters: `objectID:${storeId}`,
      hitsPerPage: 1,
    });

    return result.hits || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};
