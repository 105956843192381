import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type UserData = {
  user_logged_in: boolean;
  user_id?: string | null;
  user_email_hash?: string | null;
};

export const sendLoginSuccessEvent = (user: UserData) => {
  sendDataLayerEvent(EventNamesType.login_success, {
    custom_data: { ...user },
  });
};
