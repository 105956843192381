import { getAmount } from '../../../currencyHelper';
import { getCtLocale } from '../../../localeHelper';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';
import type EventDataList from './EventDataListType';
import type ExtendedBaseHit from './ExtendedBaseHitType';

type PrimaryCategory = {
  [key: string]: string;
};

export const sendViewItemListFromRelatedCarouselEvent = (eventData: EventDataList) => {
  const { algoliaCarouselProducts, itemList, locale } = { ...eventData };
  const ctLocale = getCtLocale(locale);

  sendDataLayerEvent(EventNamesType.view_item_list, {
    ecommerce: {
      item_list_id: itemList?.itemListId,
      item_list_name: itemList?.itemListName,
      items:
        algoliaCarouselProducts?.map((algoliaCarousel, index) => {
          const {
            [`name.${ctLocale}`]: productName,
            [`mediumColor.${ctLocale}`]: mediumColor,
            [`brand.${ctLocale}`]: brand,
            price,
            wasPrice,
            thenPrice,
            objectID,
            primaryCategory = {},
          } = algoliaCarousel as ExtendedBaseHit;
          const { [`primaryCategory.${ctLocale}`]: primaryName, key: primaryKey } =
            primaryCategory as unknown as PrimaryCategory;

          const priceAmount = +getAmount(price?.centAmount);
          const thenPriceAmount = +getAmount(thenPrice?.centAmount);
          const wasPriceAmount = +getAmount(wasPrice?.centAmount);
          const discountAmount = wasPriceAmount && priceAmount ? +wasPriceAmount - +priceAmount : 0;

          return {
            index,
            quantity: 1,
            item_name: productName,
            item_id: objectID,
            item_category: primaryName ?? undefined,
            item_category_id: primaryKey ?? undefined,
            item_brand: brand,
            item_variant: mediumColor,
            item_list_id: itemList?.itemListId,
            item_list_name: itemList?.itemListName,
            price: priceAmount,
            discount: +discountAmount.toFixed(2),
            was_price: thenPriceAmount,
            then_price: wasPriceAmount,
          };
        }) ?? [],
    },
  });
};
