import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { Description } from '@radix-ui/react-dialog';
import type { DialogDescriptionProps as RadixDescription } from '@radix-ui/react-dialog';

type DialogDescriptionProps = RadixDescription & {
  children: ReactNode;
};

export const DialogDescription = forwardRef<HTMLParagraphElement, DialogDescriptionProps>(
  ({ children, ...rest }, ref) => (
    <Description ref={ref} {...rest}>
      {children}
    </Description>
  ),
);
