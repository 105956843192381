import { useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useLocaleContext, useStaticContext } from '../../../context';
import { useMediaMatch, useTruncated } from '../../../hooks';
import { sendClickAfterSearchEvent } from '../../../services';
import { media } from '../../../stylings';
import { getItem, toSnakeCase } from '../../../utils';
import { sendSelectItemEvent } from '../../../utils/gtm/events/select_item/sendSelectItemEvent';
import { sendViewProductImageEvent } from '../../../utils/gtm/events/view_product_image/sendViewProductImageEvent';
import { ImageBadge, TextualBadge } from '../../badges';
import { Skeleton } from '../../skeleton';
import { ProductItemPrice } from '../productItemPrice/ProductItemPrice';
import type { CarouselLayoutItemProps } from './AlgoliaCarouselItem';
import {
  CarouselImagePartial,
  ImageAndBadgeContainer,
  Price,
  ProductDetails,
  ProductItemContainer,
  ProductLink,
  ProductName,
} from './AlgoliaCarouselItem.styles';

function AlgoliaCarouselSingleImage({
  isLoading,
  algoliaIndexName,
  position,
  primaryCategory,
  brand,
  productColor,
  imageUrls,
  productName,
  prices,
  slug,
  productKey,
  categoryName,
  carouselTitle,
  isPDP,
  badgesV2,
}: Omit<CarouselLayoutItemProps, 'productId'>) {
  const { asPath } = useRouter();
  const { host } = useStaticContext();
  const { locale } = useLocaleContext();
  const singleProductNameRef = useRef<HTMLHeadingElement>(null);
  const { isTruncated, lineHeight } = useTruncated(singleProductNameRef);

  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { t } = useTranslation(['lib-global-common']);

  const componentType = !isPDP
    ? `${asPath === '/' ? 'Home' : `${asPath.toUpperCase()}`.replace(/\//g, '')} Page`
    : 'PDP';

  const handleOnClick = (typeName: string, linkUrl: string, index: number) => () => {
    sendClickAfterSearchEvent(
      'Algolia Carousel: Product Clicked',
      algoliaIndexName,
      getItem('queryID') as string,
      productKey,
      position,
    );
    sendSelectItemEvent(
      {
        itemListName: carouselTitle || undefined,
        itemListId: toSnakeCase(carouselTitle as string) || undefined,
        primaryCategoryKey: primaryCategory?.key ?? undefined,
        key: productKey,
        primaryCategoryName: primaryCategory?.name ?? undefined,
        name: productName ?? '',
        brand,
        color: productColor ?? '',
      },
      prices,
    );
    sendViewProductImageEvent({
      component_type: `${componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };

  return (
    <ProductItemContainer>
      <Link href={`/${slug}/${productKey}-p`} passHref>
        <ProductLink
          tag="a"
          forceMount
          isLoading={isLoading}
          onClick={handleOnClick('Product Card', `/${slug}/${productKey}-p`, 0)}
          aria-label={t('go.to.pdp.page', { productName })}
        >
          {isLoading && <Skeleton aspectRatio="1/1" skeletonHeight={isDesktop ? 424 : 280} isLoading={isLoading} />}
          {!isLoading && !!imageUrls?.length && (
            <ImageAndBadgeContainer>
              <TextualBadge badge={badgesV2?.textual} />
              <ImageBadge badge={badgesV2?.image} />

              <CarouselImagePartial
                src={imageUrls[0]}
                hoveredSrc={imageUrls[1]}
                width={isDesktop ? 424 : 272}
                height={isDesktop ? 424 : 280}
                hasLoadingState
              />
            </ImageAndBadgeContainer>
          )}
          <ProductName
            tag="h4"
            isLoading={isLoading}
            skeletonWidth={200}
            title={productName}
            ref={singleProductNameRef}
            $lineHeight={lineHeight}
            className={`${isTruncated ? 'truncated' : ''}`}
          >
            {productName}
          </ProductName>
          <ProductDetails>
            <Skeleton tag="p" skeletonWidth={150} isLoading={isLoading} title={productColor}>
              {productColor}
            </Skeleton>
            <Skeleton tag="p" skeletonWidth={150} isLoading={isLoading} title={categoryName}>
              {categoryName}
            </Skeleton>
          </ProductDetails>
          <Price>
            {prices?.map((price, i) => (
              <ProductItemPrice price={price} key={`${price}-${i}`} />
            ))}
          </Price>
        </ProductLink>
      </Link>
    </ProductItemContainer>
  );
}

export default AlgoliaCarouselSingleImage;
