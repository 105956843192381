import type { ReactElement } from 'react';
import type { Footer as FooterSchema } from '@amplience/content-types/typings/c-footer';
import type { MasterLayoutContextType } from '../../context';
import { createGlobalAmplienceComponent } from '../globalAmplienceComponents.utils';
import { BottomContent, ColumnContent, ColumnWrapper, Content } from './Footer.styles';
import { NewsletterSignUp } from './NewsletterSignUp';
import { ScrollToTop } from './scrollToTop/ScrollToTop';

export type FooterComponentProps = FooterSchema;

export function FooterComponent({ footerColumn, scrollToTop, newsletterSignUp, footerBottom }: FooterComponentProps) {
  if (!footerColumn && !scrollToTop && !newsletterSignUp && !footerBottom) return null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let footerColumnAmplienceComponents: any = {};
  const footerBottomAmplienceComponents = {
    ...createGlobalAmplienceComponent(footerBottom?.bottomContent ?? []),
  };

  footerColumn?.forEach((column) => {
    footerColumnAmplienceComponents = {
      ...footerColumnAmplienceComponents,
      ...createGlobalAmplienceComponent(column.columnContent ?? []),
    };
  });

  return (
    <footer role="contentinfo">
      {scrollToTop && <ScrollToTop {...scrollToTop} />}
      {newsletterSignUp && <NewsletterSignUp {...newsletterSignUp} />}
      <ColumnWrapper data-testid="columnSection">
        <ColumnContent data-testid="columnContent">
          {footerColumn?.map((column, i) => (
            <Content key={i}>
              {column.columnContent?.map((columnItem, j) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any

                const Component: (props: any) => ReactElement | null =
                  footerColumnAmplienceComponents[columnItem._meta.schema];

                return Component && <Component {...columnItem} key={`${columnItem._meta.schema}-${j}`} />;
              })}
            </Content>
          ))}
        </ColumnContent>
      </ColumnWrapper>
      <ColumnWrapper>
        <BottomContent $numOfItems={footerBottom?.bottomContent?.length}>
          {footerBottom?.bottomContent?.map((bottomItem, k) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any

            const Component: (props: any) => ReactElement | null =
              footerBottomAmplienceComponents[bottomItem._meta.schema];

            return Component && <Component {...bottomItem} key={`${bottomItem._meta.schema}-${k}`} />;
          })}
        </BottomContent>
      </ColumnWrapper>
    </footer>
  );
}

export type FooterProps = {
  footerData?: MasterLayoutContextType['masterLayoutData']['footerComponent'];
};

export function Footer({ footerData }: FooterProps) {
  if (!footerData) return null;

  return <FooterComponent {...footerData} />;
}

export default Footer;
