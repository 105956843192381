import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import type { SizeCalculator as SizeCalculatorSchema } from '@amplience/content-types/typings/c-size-calculator';
import { useApolloClient } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { B2C } from '@shared/validation';
import { WhiteButton } from '../../baseComponents/button';
import { FieldContainer } from '../../baseComponents/containers/FieldContainer';
import { ErrorMessage } from '../../baseComponents/errors';
import { Fieldset } from '../../baseComponents/inputs/Fieldset';
import type { DeviceType, SizeWithCategory } from '../../codegen/types';
import { useLocaleContext } from '../../context/localeContext';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { CancelCircle, CheckCircle } from '../../icons';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import { LinkPartial } from '../../partials/link';
import { RichTextPartial } from '../../partials/richText';
import { getSizeCalculator } from '../../services/gql/getSizeCalculator';
import { TypographyStyles, TypographyStylesType, colours, maxWidthPartial, media, spacing } from '../../stylings';
import { Locale, getCtLocale } from '../../utils/localeHelper';
import { getLocalizedValue } from '../../utils/transformers';

export type SizeCalculatorProps = SizeCalculatorSchema;

const BlockAlignment = {
  left: 'flex-start',
  centre: 'center',
  right: 'flex-end',
};

const S = {
  Buttons: styled.ul<{ $numOfCategories: number }>`
    align-items: center;
    display: grid;
    gap: ${spacing.XXXS};
    grid-template-columns: ${({ $numOfCategories }) => `repeat(${Math.min(2, $numOfCategories)}, 1fr)`};
    justify-content: center;
    margin-top: ${spacing.XXXS};
    padding: 0;
    width: 100%;

    > li {
      &:nth-child(2) + li {
        grid-column: 1 / -1;
        justify-self: center;
        width: 50%;
      }
    }

    @media ${media.greaterThan('lg')} {
      grid-template-columns: ${({ $numOfCategories }) => `repeat(${$numOfCategories}, 1fr)`};
      > li:nth-child(2) + li {
        grid-column: unset;
        justify-self: unset;
        width: unset;
      }
    }
  `,

  Button: styled(LinkPartial)`
    ${TypographyStyles.Button.SemiBold}
    align-items: center;
    background-color: ${colours.DARK_BLUE};
    border: none;
    color: ${colours.WHITE};
    display: flex;
    justify-content: center;
    min-height: 44px;
    padding: ${spacing.XXS};
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background-color: ${colours.WHITE};
      color: ${colours.DARK_BLUE};
      outline: 1px solid ${colours.DARK_BLUE};
    }

    @media ${media.greaterThan('lg')} {
      min-height: 50px;
    }
  `,

  CalculateButton: styled(WhiteButton)`
    height: 100%;
    &:hover,
    &:focus {
      background-color: ${colours.LIGHT_BLUE};
    }

    &:disabled {
      background-color: ${colours.VERY_LIGHT_GREY};
      border: 1px solid ${colours.MID_GREY};
      color: ${colours.GREY};
    }
  `,

  Container: styled(Fieldset)`
    display: grid;
    gap: ${spacing.XXXS};
    grid-template-columns: repeat(2, 1fr);
    margin-inline: 0;
    width: 100%;

    > * {
      position: relative;
    }
  `,

  Description: styled(RichTextPartial)<{ $textColor?: string }>`
    margin: 0;

    * {
      color: ${({ $textColor }) => $textColor};
    }
  `,

  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXS};
    margin-bottom: ${spacing.S};

    > button {
      min-height: 44px;
      width: 100%;

      @media ${media.greaterThan('lg')} {
        min-height: 50px;
        padding: 14px;
      }
    }
  `,

  IconWrapper: styled.div`
    margin: 17px 18px 17px 14px;
    position: absolute;
    right: 0;
    top: 0;
  `,

  Input: styled.input<{ $isInputError?: boolean }>`
    ${TypographyStyles.Body.Medium.Regular}
    border: 1px solid ${({ $isInputError }) => ($isInputError ? colours.ERROR_PRIMARY : colours.GREY)};
    color: ${colours.BLACK};
    height: 100%;
    padding: 0 ${spacing.L} 0 ${spacing.XXS};
    position: relative;
    width: 100%;

    &:focus {
      border: 2px solid ${({ $isInputError }) => ($isInputError ? colours.ERROR_PRIMARY : colours.DARK_GREY)};
      outline: none;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      ${TypographyStyles.Body.Tiny.Regular}
      background-color: ${colours.WHITE};
      margin-right: 0;
      transform: translateY(calc(-100% - 15px));
      width: max-content;
    }

    &::placeholder {
      color: transparent;
    }
  `,

  InputWrapper: styled.div`
    height: 50px;
    position: relative;
  `,

  Label: styled.label<{ $isInputError?: boolean }>`
    ${TypographyStyles.Body.Medium.Regular}
    color: ${({ $isInputError }) => ($isInputError ? colours.RED : colours.BLACK)};
    left: ${spacing.XXXS};
    margin-right: 30px;
    overflow: hidden;
    padding: 0 5px;
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1); /* stylelint-disable-line */
    white-space: nowrap;
    width: ${({ $isInputError }) => ($isInputError ? 'calc(100% - 50px)' : 'calc(100% - 30px)')};

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  Results: styled.div`
    text-align: center;

    p {
      ${TypographyStyles.Body.Small.Regular}
      color: inherit;
      margin: 0;
    }
  `,

  SizeCalculator: styled.div<{
    $bgColor?: string;
    $alignment?: string | null;
    $isStandalone?: boolean;
    $textColor?: string;
  }>`
    background-color: ${({ $bgColor }) => $bgColor};
    color: ${({ $textColor }) => $textColor};
    padding: ${({ $bgColor }) => $bgColor && spacing.S};

    > div {
      width: 100%;
    }

    @media ${media.greaterThan('lg')} {
      > div {
        max-width: 641px;
      }
    }

    ${({ $isStandalone, $alignment }) =>
      $isStandalone &&
      css`
        display: grid;
        padding: ${spacing.S};
        place-items: ${BlockAlignment[$alignment as keyof typeof BlockAlignment]};

        @media ${media.greaterThan('lg')} {
          padding: 80px;
        }

        @media ${media.greaterThan('xx')} {
          ${maxWidthPartial({ maxWidth: 1300, withPadding: false })}
        }
      `}
  `,

  Title: styled(FlexibleTextPartial)`
    margin: 0 0 ${spacing.XXXS} 0;
  `,
};

type FormValues = {
  length: number;
  width: number;
};

function Icon({ isError }: { isError?: boolean }) {
  if (isError) {
    return (
      <S.IconWrapper>
        <CancelCircle />
      </S.IconWrapper>
    );
  }

  return (
    <S.IconWrapper>
      <CheckCircle />
    </S.IconWrapper>
  );
}

export function SizeCalculator({
  isStandalone = false,
  title,
  description,
  alignment,
  bgColor,
  calculatorCategories,
  localizedCalculatorCategories,
  deviceType = 'tape',
}: SizeCalculatorProps) {
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common']);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitted },
    getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(B2C.sizeCalculatorSchema(deviceType, getCtLocale(locale) ?? Locale.DEFAULT_LOCALE)),
  });
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const [focusedStates, setFocusedStates] = useState({
    length: false,
    width: false,
  });
  const unitToDisplay = locale === Locale.EN_US ? '(in)' : '(mm)';
  const unit = deviceType === 'tape' ? unitToDisplay : '';
  const isWidthTouched = !!getValues('width');
  const isLengthTouched = !!getValues('length');
  const isWidthValid = !errors.width;
  const isLengthValid = !errors.length;
  const lengthPlaceholder = `${t(
    !focusedStates.length && !isLengthTouched && isDesktop ? 'calculator.enter.length' : 'calculator.length',
    {
      unit,
    },
  )}`;
  const widthPlaceholder = `${t(
    !focusedStates.width && !isWidthTouched && isDesktop ? 'calculator.enter.width' : 'calculator.width',
    {
      unit,
    },
  )}`;

  const [result, setResult] = useState<SizeWithCategory>();
  const apolloClient = useApolloClient();

  const onSubmit = async (data: FormValues) => {
    try {
      const { data: sizeCalculator } = await getSizeCalculator(apolloClient, {
        input: {
          device: deviceType as DeviceType,
          locale: getCtLocale(locale),
          length: data.length,
          width: data.width,
        },
      });

      setResult(sizeCalculator);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFocus = (type: 'width' | 'length') => () => {
    setFocusedStates((prev) => ({ ...prev, [type]: true }));
  };

  const handleBlur = (type: 'width' | 'length') => () => {
    setFocusedStates((prev) => ({ ...prev, [type]: false }));
  };

  const validator = {
    setValueAs: (value: string) => {
      const val = Number(value);
      return Number.isNaN(val) ? 1 : val;
    },
  };

  const categories = localizedCalculatorCategories || calculatorCategories;

  return (
    <S.SizeCalculator
      $bgColor={bgColor}
      $alignment={isStandalone ? alignment : null}
      $isStandalone={isStandalone}
      $textColor={title?.textColor || title?.desktopTextColor || (description?.textColor as string) || colours.BLACK}
    >
      <div>
        {!!title?.text && (
          <S.Title
            {...title}
            text={title?.text}
            defaultTextSize={TypographyStylesType.HEADINGS_H5}
            defaultTextColor={colours.BLACK}
          />
        )}
        {!!description?.richText && (
          <S.Description
            text={getLocalizedValue(description?.richText)}
            fontSize={TypographyStyles.Body.Small.Regular}
            $textColor={(description?.textColor as string) || colours.BLACK}
          />
        )}
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Container label={t('calculator.measurements.label')}>
            <FieldContainer>
              <S.InputWrapper>
                <S.Input
                  {...register('length', validator)}
                  type="text"
                  placeholder={lengthPlaceholder}
                  aria-label={lengthPlaceholder}
                  aria-invalid={!isLengthValid}
                  aria-describedby="length-invalid"
                  $isInputError={!isLengthValid}
                  onFocus={handleFocus('length')}
                  onBlur={handleBlur('length')}
                />
                <S.Label $isInputError={!isLengthValid}>{lengthPlaceholder}</S.Label>
                {!isLengthValid ? <Icon isError /> : null}
                {isLengthTouched && isLengthValid ? <Icon /> : null}
              </S.InputWrapper>
              <ErrorMessage id="length-invalid">
                {!isLengthValid ? t('calculator.validation.message', { range: errors.length?.message }) : null}
              </ErrorMessage>
            </FieldContainer>
            <FieldContainer>
              <S.InputWrapper>
                <S.Input
                  {...register('width', validator)}
                  type="text"
                  placeholder={widthPlaceholder}
                  aria-label={widthPlaceholder}
                  aria-invalid={!isWidthValid}
                  aria-describedby="width-invalid"
                  $isInputError={!isWidthValid}
                  onFocus={handleFocus('width')}
                  onBlur={handleBlur('width')}
                />
                <S.Label $isInputError={!isWidthValid}>{widthPlaceholder}</S.Label>
                {!isWidthValid ? <Icon isError /> : null}
                {isWidthTouched && isWidthValid ? <Icon /> : null}
              </S.InputWrapper>
              <ErrorMessage id="width-invalid">
                {!isWidthValid ? t('calculator.validation.message', { range: errors.width?.message }) : null}
              </ErrorMessage>
            </FieldContainer>
          </S.Container>
          <S.CalculateButton type="submit" disabled={isSubmitting}>
            {t('calculator.calculate.size')}
          </S.CalculateButton>
        </S.Form>
        {result && (
          <S.Results>
            <div role="status">
              <p>
                {t('calculator.results')}:{' '}
                <strong>
                  {result?.size} {result?.fit}
                </strong>
              </p>
            </div>
            {categories && (
              <S.Buttons $numOfCategories={categories.length}>
                {categories.map(({ categoryName, categoryId }, index) => (
                  <li key={`${categoryName}-${index}`}>
                    <S.Button
                      ariaLabel={t('go.to.plp.page', {
                        categoryName: (localizedCalculatorCategories
                          ? getLocalizedValue(categoryName)
                          : categoryName) as string,
                      })}
                      internalLink={createInternalLink({
                        categoryId: (localizedCalculatorCategories
                          ? getLocalizedValue(categoryId)
                          : categoryId) as string,
                        size: result.size,
                        ageGroup: result.ageGroup,
                      })}
                    >
                      {t('calculator.shop')}{' '}
                      {`${localizedCalculatorCategories ? getLocalizedValue(categoryName) : categoryName} ${
                        result.size
                      }${result.fit}`}
                    </S.Button>
                  </li>
                ))}
              </S.Buttons>
            )}
          </S.Results>
        )}
        {isSubmitted && !result && (
          <S.Results role="status">
            <p>
              <strong>{t('calculator.error.message')}</strong>
            </p>
          </S.Results>
        )}
      </div>
    </S.SizeCalculator>
  );
}

function createInternalLink({ categoryId, size, ageGroup }: { categoryId: string; size: string; ageGroup: string }) {
  const urlParams = new URLSearchParams();
  urlParams.append('size[0]', `${size ?? ''}_${ageGroup ?? ''}`);

  return `${categoryId}-c?${urlParams.toString()}`;
}
export default SizeCalculator;
