import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { ErrorMessage } from '../../baseComponents';
import { BlueButton, WhiteButton } from '../../baseComponents/button';
import { Fieldset } from '../../baseComponents/inputs/Fieldset';
import { InputField } from '../../baseComponents/inputs/Input';
import { Radio } from '../../baseComponents/inputs/Radio';
import { MarketingChannel } from '../../codegen/types';
import { useLocaleContext } from '../../context/localeContext';
import { useOptDownMutation } from '../../graphqlDocument/newsLetter/optDown.generated';
import { useOptOutMutation } from '../../graphqlDocument/newsLetter/optOut.generated';
import { useLoadingCursor } from '../../hooks/useLoadingCursor';
import { TypographyStyles, colours, media } from '../../stylings';
import { UserOpt } from '../../types/newsletter';
import { Locale, getCtLocale } from '../../utils/localeHelper';
import { OptLabel } from './OptLabel';
import { OptSuccessMessage } from './OptSuccessMessage';

type NewsletterFormValues = {
  email: string;
  opt: UserOpt;
};

const S = {
  Container: styled.div`
    height: 100%;
    margin: 0 auto;
    max-width: 1440px;
  `,
  SectionContainer: styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 32px 24px;
    @media ${media.greaterThan('lg')} {
      margin: 80px 0;
    }
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 328px;
    @media ${media.greaterThan('lg')} {
      max-width: initial;
      width: 628px;
    }
    .checked {
      background-color: ${colours.LIGHT_BLUE};
    }
  `,
  CTAWrapper: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;

    gap: 16px;
    justify-content: center;

    @media ${media.greaterThan('lg')} {
      flex-direction: row;
    }
    button {
      ${TypographyStyles.Button.SemiBold}

      height: 50px;
      margin: 0;
      padding: 14px;
      width: 100%;
    }
  `,
  RadioGroup: styled(Fieldset)`
    margin: 0;
  `,
};

export function NewsletterForm({ email: customerEmail }: { email: string }) {
  const { register, handleSubmit, control } = useForm<NewsletterFormValues>({
    mode: 'onChange',
    defaultValues: {
      email: customerEmail,
      opt: UserOpt.OPT_DOWN,
    },
  });

  const { t } = useTranslation(['lib-global-common']);

  const router = useRouter();
  const { locale } = useLocaleContext();
  const [currentEmail, currentOpt] = useWatch({
    control,
    name: ['email', 'opt'],
  });
  const [optDown, { data: optDownData, error: optDownError, loading: optDownLoading }] = useOptDownMutation();
  const [optOut, { data: optOutData, error: optOutError, loading: optOutLoading }] = useOptOutMutation();

  useLoadingCursor(optDownLoading || optOutLoading);

  const onSubmit: SubmitHandler<NewsletterFormValues> = async (props: NewsletterFormValues) => {
    const { email, opt } = props;
    if (!email) {
      return;
    }

    try {
      if (opt === UserOpt.OPT_DOWN) {
        await optDown({
          variables: {
            input: {
              emailAddress: email,
              locale: Locale.STOREFRONT_OUTLET_LOCALE === locale ? Locale.CT_OUTLET_LOCALE : locale,
              channel:
                locale === Locale.STOREFRONT_OUTLET_LOCALE ? MarketingChannel.OUTLET : MarketingChannel.FULLPRICE,
            },
          },
        });
      } else {
        await optOut({
          variables: {
            input: {
              channel:
                locale === Locale.STOREFRONT_OUTLET_LOCALE ? MarketingChannel.OUTLET : MarketingChannel.FULLPRICE,
              emailAddress: email,
              locale: Locale.STOREFRONT_OUTLET_LOCALE === locale ? Locale.CT_OUTLET_LOCALE : locale,
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnClickCancel = () => {
    router.push('/');
  };

  return (
    <S.Container>
      <S.SectionContainer>
        {optDownData?.optDown || optOutData?.optOut ? (
          <OptSuccessMessage email={currentEmail} opt={optOutData?.optOut ? UserOpt.OPT_OUT : UserOpt.OPT_DOWN} />
        ) : (
          <S.Form noValidate onSubmit={handleSubmit(onSubmit)} data-testid="newsletterForm">
            <InputField
              register={register('email')}
              aria-label={t('register.email.address')}
              placeholder={`${t('register.email.address')}`}
              disabled
            />

            <S.RadioGroup id="newsletter-unsubscribe-radio">
              <Radio
                checked={currentOpt === UserOpt.OPT_DOWN}
                register={register('opt')}
                label={<OptLabel opt={UserOpt.OPT_DOWN} />}
                name="newsletter-unsubscribe-radio"
                value={UserOpt.OPT_DOWN}
                key={UserOpt.OPT_DOWN}
                className={currentOpt === UserOpt.OPT_DOWN ? 'checked' : ''}
                data-testid="optDown"
              />

              <Radio
                checked={currentOpt === UserOpt.OPT_OUT}
                label={<OptLabel opt={UserOpt.OPT_OUT} />}
                name="newsletter-unsubscribe-radio"
                register={register('opt')}
                value={UserOpt.OPT_OUT}
                key={UserOpt.OPT_OUT}
                className={currentOpt === UserOpt.OPT_OUT ? 'checked' : ''}
                data-testid="optDown"
              />
            </S.RadioGroup>

            <ErrorMessage errorMessage={!(optDownError || optOutError)}>{t('newsletter.generic.error')}</ErrorMessage>

            <S.CTAWrapper>
              <BlueButton
                type="submit"
                disabled={optDownLoading || optOutLoading}
                data-testid="updateUnsubscribeButton"
              >
                {t('newsletter.update.frequency')}
              </BlueButton>
              <WhiteButton type="button" onClick={handleOnClickCancel} data-testid="cancelUnsubscribe">
                {t('newsletter.cancel')}
              </WhiteButton>
            </S.CTAWrapper>
          </S.Form>
        )}
      </S.SectionContainer>
    </S.Container>
  );
}

export default NewsletterForm;
