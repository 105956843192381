export enum Country {
  AUSTRIA = 'Austria',
  BELGIUM = 'Belgium',
  BOSNIA_AND_HERZEGOVINA = 'Bosnia and Herzegovina',
  BULGARIA = 'Bulgaria',
  CROATIA = 'Croatia',
  CYPRUS = 'Cyprus',
  CZECH_REPUBLIC = 'Czech Republic',
  DENMARK = 'Denmark',
  ESTONIA = 'Estonia',
  FINLAND = 'Finland',
  FRANCE = 'France',
  GEORGIA = 'Georgia',
  GERMANY = 'Germany',
  GIBRALTAR = 'Gibraltar',
  GREECE = 'Greece',
  HUNGARY = 'Hungary',
  ICELAND = 'Iceland',
  ITALY = 'Italy',
  LATVIA = 'Latvia',
  LIECHTENSTEIN = 'Liechtenstein',
  LITHUANIA = 'Lithuania',
  LUXEMBOURG = 'Luxembourg',
  MALTA = 'Malta',
  MONACO = 'Monaco',
  MONTENEGRO = 'Montenegro',
  NETHERLANDS = 'Netherlands',
  NORTH_MACEDONIA = 'North Macedonia',
  NORWAY = 'Norway',
  POLAND = 'Poland',
  PORTUGAL = 'Portugal',
  REPUBLIC_OF_MOLDOVA = 'Republic of Moldova',
  ROMANIA = 'Romania',
  SAN_MARINO = 'San Marino',
  SERBIA = 'Serbia',
  SLOVAKIA = 'Slovakia',
  SLOVENIA = 'Slovenia',
  SPAIN = 'Spain',
  SWEDEN = 'Sweden',
  SWITZERLAND = 'Switzerland',
  TURKEY = 'Turkey',
  UNITED_KINGDOM = 'United Kingdom',
  UNITED_STATES_OF_AMERICA = 'United States of America',
  PUERTO_RICO = 'Puerto Rico',
  IRELAND = 'Ireland',
}

export const countryCodeToCountryName: { [key: string]: Country } = {
  AT: Country.AUSTRIA,
  BE: Country.BELGIUM,
  BA: Country.BOSNIA_AND_HERZEGOVINA,
  BG: Country.BULGARIA,
  HR: Country.CROATIA,
  CY: Country.CYPRUS,
  CZ: Country.CZECH_REPUBLIC,
  DK: Country.DENMARK,
  EE: Country.ESTONIA,
  FI: Country.FINLAND,
  FR: Country.FRANCE,
  GE: Country.GEORGIA,
  DE: Country.GERMANY,
  GI: Country.GIBRALTAR,
  GR: Country.GREECE,
  HU: Country.HUNGARY,
  IS: Country.ICELAND,
  IT: Country.ITALY,
  LV: Country.LATVIA,
  LI: Country.LITHUANIA,
  LT: Country.LIECHTENSTEIN,
  LU: Country.LUXEMBOURG,
  MT: Country.MALTA,
  MC: Country.MONACO,
  ME: Country.MONTENEGRO,
  NL: Country.NETHERLANDS,
  MK: Country.NORTH_MACEDONIA,
  NO: Country.NORWAY,
  PL: Country.POLAND,
  PT: Country.PORTUGAL,
  MD: Country.REPUBLIC_OF_MOLDOVA,
  RO: Country.ROMANIA,
  SM: Country.SAN_MARINO,
  RS: Country.SERBIA,
  SK: Country.SLOVAKIA,
  SI: Country.SLOVENIA,
  ES: Country.SPAIN,
  SE: Country.SWEDEN,
  CH: Country.SWITZERLAND,
  TR: Country.TURKEY,
  GB: Country.UNITED_KINGDOM,
  US: Country.UNITED_STATES_OF_AMERICA,
  PR: Country.PUERTO_RICO,
  IE: Country.IRELAND,
};
