import { useEffect, useMemo, useState } from 'react';
import { useDidMount } from './useDidMount';

/**
 * SSR - friendly `window.matchMedia`
 * @param query CSS media query
 * @returns A boolean value that is true if the document currently matches the media query list, or false if not.
 */
export function useMediaMatch(query: string): boolean {
  const didMount = useDidMount();
  const mediaQueryList: MediaQueryList | undefined = useMemo(() => globalThis?.matchMedia?.(query), [query]);
  const [isMediaMatch, setIsMediaMatch] = useState(mediaQueryList?.matches ?? false);

  useEffect(() => {
    const mediaMatchListener = ({ matches }: MediaQueryListEvent) => setIsMediaMatch(matches);

    try {
      mediaQueryList?.addEventListener('change', mediaMatchListener);
    } catch (e1) {
      try {
        // Fallback for iOS13, Safari supports addEventListener('change', ...) from version 14
        mediaQueryList?.addListener(mediaMatchListener);
      } catch (e2) {
        console.error(e2);
      }
    }

    return () => {
      if (mediaQueryList?.removeEventListener) {
        mediaQueryList?.removeEventListener('change', mediaMatchListener);
      }
      if (mediaQueryList?.removeListener) {
        mediaQueryList.removeListener(mediaMatchListener);
      }
    };
  }, [mediaQueryList]);

  return didMount ? isMediaMatch : false;
}
