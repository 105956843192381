import type { ReactNode } from 'react';
import { useStaticContext } from '../../context/staticContext';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { media } from '../../stylings';
import { transformTextToId } from '../../utils/transformers';
import { Container, IconContainer, IconList, PaymentMethodsWrapper } from './PaymentMethods.styles';
import { renderIcon } from './PaymentMethods.utils';
import type { PaymentMethodKeys, PaymentMethodsPlacement } from './PaymentMethods.utils';

type PaymentMethodsContainerProps = {
  placement: PaymentMethodsPlacement;
  children: ReactNode;
};

export function PaymentMethodsContainer({ placement, children }: PaymentMethodsContainerProps) {
  return <PaymentMethodsWrapper $placement={placement}>{children}</PaymentMethodsWrapper>;
}

type IconProps = {
  iconKey: PaymentMethodKeys;
  iconLabel: string;
  placement: PaymentMethodsPlacement;
};

function Icon({ iconKey, iconLabel, placement }: IconProps) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const id = transformTextToId(`${iconKey}-${placement}`);

  return (
    <IconContainer $placement={placement} aria-label={iconLabel} id={id}>
      {renderIcon({
        iconKey,
        labelledBy: id,
        isDesktop,
        placement,
      })}
    </IconContainer>
  );
}

type PaymentMethodsProps = {
  placement: PaymentMethodsPlacement;
};

export function PaymentOptions({ placement }: PaymentMethodsProps) {
  const { paymentMethods } = useStaticContext();

  if (!paymentMethods) return null;

  return (
    <Container>
      <IconList $placement={placement}>
        {paymentMethods?.map((paymentMethod) => (
          <Icon
            key={`${paymentMethod.key}-${placement}`}
            iconKey={paymentMethod.key as PaymentMethodKeys}
            iconLabel={paymentMethod.label}
            placement={placement}
          />
        ))}
      </IconList>
    </Container>
  );
}
