import { memo, useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import { useOverlayContext } from '../../context/overlayContext';
import { Search } from '../../icons';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

const widthForDesktop = css`
  width: 100%;
`;

const S = {
  SearchBar: styled.div<{ $forDesktop?: boolean; $isSearchBarActive?: boolean }>`
    align-items: center;
    border: 1px solid ${colours.GREY};
    display: ${({ $forDesktop }) => ($forDesktop ? 'none' : 'block')};
    flex-direction: row;
    margin: ${spacing.XXXS} ${spacing.S};

    & form {
      position: relative;

      & input[type='search'] {
        ${TypographyStyles.Body.Tiny.Regular}
        border: none;
        height: ${spacing.L};
        padding: 0 46px 0 53px;
        width: 100%;
        /* Removes the built-in 'x' clear button for search inputs in Chrome browsers. */
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
        &::placeholder {
          color: ${colours.BLACK};
          @media ${media.greaterThan('lg')} {
            color: ${colours.GREY};
          }
        }
        @media ${media.greaterThan('lg')} {
          height: ${spacing.L};
          padding: ${({ $isSearchBarActive }) => ($isSearchBarActive ? '0 46px 0 42px' : '0 20px')};
          width: ${({ $isSearchBarActive }) => ($isSearchBarActive ? '100%' : '0')};
        }
        @media ${media.greaterThan('xl')} {
          height: ${spacing.L};
          padding: 0 46px 0 42px;
          width: ${({ $isSearchBarActive }) => ($isSearchBarActive ? '100%' : 'auto')};
        }
      }
      & input[type='search']:focus {
        outline: ${colours.BLACK} solid 1px;
      }

      & button {
        align-content: center;
        display: flex;
        gap: ${spacing.XXXS};

        & span {
          color: ${colours.GREY};
          font-size: 16px;
          @media ${media.greaterThan('lg')} {
            display: none;
          }
        }
      }

      & button[type='button'] {
        background: none;
        border: 0;
        left: ${spacing.XXS};
        padding: 0;
        position: absolute;
        top: 10px;

        @media ${media.greaterThan('lg')} {
          ${({ $forDesktop, $isSearchBarActive }) => $forDesktop && !$isSearchBarActive && widthForDesktop}
          left: 0;
          margin: 0;
          padding: 10px;
          top: 0;
        }
      }

      @media ${media.greaterThan('lg')} {
        margin: 0;
        width: 100%;
      }
    }

    @media ${media.greaterThan('lg')} {
      display: ${({ $forDesktop }) => ($forDesktop ? 'flex' : 'none')};
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: flex-end;
      margin: 0;
      width: 100%;
    }

    @media ${media.greaterThan('xx')} {
      margin: 0;
    }
  `,
};

export type SearchBarProps = {
  forDesktop?: boolean;
  idPrefix?: string;
  className?: string;
};

export function SearchBar({ forDesktop, idPrefix, className }: SearchBarProps) {
  const { t } = useTranslation(['lib-global-common']);

  const { isSearchOverlayOpen, setIsSearchOverlayOpen, searchQuery } = useOverlayContext();
  const handleSearchIconClick = useCallback(() => {
    setIsSearchOverlayOpen((prev) => !prev);
  }, [setIsSearchOverlayOpen]);

  return (
    <S.SearchBar $forDesktop={forDesktop} className={className} $isSearchBarActive={isSearchOverlayOpen}>
      <form role="search" aria-label={t('search.placeholder')}>
        <button
          aria-label={t('search.placeholder')}
          id={`${idPrefix}-search-button`}
          type="button"
          className={searchQuery?.length === 0 ? 'active' : 'inactive'}
          onClick={handleSearchIconClick}
          data-testid="searchButtonDesktop"
        >
          <Search aria-hidden />
        </button>
        <input
          type="search"
          placeholder={t('search.placeholder')}
          value={searchQuery}
          readOnly
          aria-label={t('search.placeholder')}
          tabIndex={-1}
        />
      </form>
    </S.SearchBar>
  );
}

export default memo(SearchBar);
