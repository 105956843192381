import type { Reference, StoreObject } from '@apollo/client';
import type { ApolloClientInstance } from '../services/gql/apolloClient';

export const ONE_MINUTE = 1000 * 60;

type QueryTimeouts = {
  [queryName: string]: NodeJS.Timeout | null;
};

type CacheEvictorOptions = {
  /**
   * If the object to evict is a field of a store object (it is not
   * properly normalized due to lack of `__typename` or `id`), e.g.
   * `Query > siteSwitch`, then `isField` is true. Otherwise, if the field
   * is properly normalized by `InMemoryCache` then `isField` should be false.
   */
  isField: boolean;
  /**
   * The cache ID for when `isField` is true. Usually "ROOT_QUERY"
   */
  id?: string;
  /**
   * The field name of the cache object to be evicted, e.g.
   * if evicting `siteSwitch` from `ROOT_QUERY`, then `fieldName: 'siteSwitch'`
   */
  fieldName?: string;
  /**
   * If the field accepts arguments, then this is recorded in the cache as
   * `${fieldName}(${...args})` and will have to be evicted using this pattern:
   *
   * @example
   * cache.evict({
   *  id: 'ROOT_QUERY',
   *  fieldName: 'someField',
   *  args: {
   *    locale: 'en-gb'
   *  }
   * })
   */
  args?: Record<string, string | boolean>;
  /**
   * The cache object to be identified when `isField` is false.
   */
  storeObject?: StoreObject | Reference | undefined | null;
};

const queryTimeouts: QueryTimeouts = {};

/**
 * Sets a timer and evicts cache object after timer expires
 *
 * @param cacheEvictorOptions Options containing details of how to evict the cache object
 * @param timeoutDuration Timer length in milliseconds
 * @param apolloClient Apollo Client used in the query
 * @returns void
 *
 * @example
 * const response = await apolloClient.query({
 *    query: GetStoreDocument,
 *    ...
 * });
 *
 * const result = response.data.getStore;
 *
 * queryCacheEvictor({ isField: false, storeObject: result }, 30000, apolloClient);
 */
export function queryCacheEvictor(
  cacheEvictorOptions: CacheEvictorOptions,
  timeoutDuration: number,
  apolloClient: ApolloClientInstance,
) {
  const { isField, id, fieldName, args, storeObject } = cacheEvictorOptions;

  if (!isField) {
    if (!storeObject) return;

    const cacheId = apolloClient.cache.identify(storeObject);

    if (!cacheId) return;

    if (!queryTimeouts[cacheId]) {
      queryTimeouts[cacheId] = setTimeout(() => {
        apolloClient.cache.evict({ id: cacheId });
        queryTimeouts[cacheId] = null;
      }, timeoutDuration);
    }
  } else {
    if (!id) return;

    const customCacheId = [id, fieldName, JSON.stringify(args)].filter((value) => value).join(':');

    if (!queryTimeouts[customCacheId]) {
      queryTimeouts[customCacheId] = setTimeout(() => {
        apolloClient.cache.evict({ id, fieldName, args });
        queryTimeouts[customCacheId] = null;
      }, timeoutDuration);
    }
  }
}
