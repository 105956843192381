/* eslint-disable security/detect-unsafe-regex,prefer-regex-literals */
import * as yup from 'yup';

/**
 * Regex verifies:
 * 1) `?!\s` - negative lookahead: value does not start with space
 * 2) `?!.*\s{3}` - negative lookahead: value does not contain 3 spaces in a row
 * 3) `?!.*(http|www) - negative lookahead: value does not contain www or http
 * 4) `?!.*[&$=+#|{}()£[\]<>?!:;@"%^*\p{Extended_Pictographic}\p{Emoji}\p{Math}]` - negative lookahead: value does not contain any of these characters
 *    \p{Extended_Pictographic} used for identify extended pictographics
 *    \p{Emoji} used for identify general emojis, including flags
 *    \p{Math} used to identify styles bold and/or italic
 * 5) `.{1,40}` - length between 1 and 40 chars
 */
export const FIRST_NAME_REGEXP =
  /^(?!\s)(?!.*\s{3})(?!.*(http|www))(?!.*[&$=+#|{}()£[\]<>?!:;@"%^*\p{Extended_Pictographic}\p{Emoji}\p{Math}]).{1,40}$/iu;

export const LAST_NAME_REGEXP = FIRST_NAME_REGEXP;

export const TEXT_FIELD_REGEXP = /^(?=.{1,255}$)[0-9a-z\u00C0-\u024F\u1E00-\u1EFF '’,?.()-]+(?:['_.\s][-z]+)*$/i;
export const PASSWORD_REGEX = /^[\u0020-\u04FF]{10,256}$/;
export const PHONE_FIELD_REGEXP = /^\+(?:\d{1,4})?\d{5,15}$/;

export const EMAIL_REGEX =
  // eslint-disable-next-line
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.[a-zA-Z]{1,})$/;

export const RegisterSchema = yup.object({
  firstName: yup
    .string()
    .required('register.invalid.first.name')
    .matches(FIRST_NAME_REGEXP, 'register.validation.first.name'),
  lastName: yup
    .string()
    .required('register.invalid.last.name')
    .matches(LAST_NAME_REGEXP, 'register.validation.last.name'),
  email: yup
    .string()
    .required('register.validation.empty.email')
    .matches(EMAIL_REGEX, 'register.validation.email')
    .max(128, 'register.validation.email'),
  password: yup
    .string()
    .required('register.validation.password')
    .matches(PASSWORD_REGEX, 'register.validation.password'),
});

export const SocialLoginRegisterSchema = yup.object({
  firstName: yup
    .string()
    .matches(FIRST_NAME_REGEXP, 'register.validation.first.name')
    .required('register.validation.first.name'),
  lastName: yup
    .string()
    .matches(LAST_NAME_REGEXP, 'register.validation.last.name')
    .required('register.validation.last.name'),
  email: yup
    .string()
    .required('register.validation.email')
    .matches(EMAIL_REGEX, 'register.validation.email')
    .max(128, 'register.validation.email'),
});

export const GuestUserStubRegisterSchema = yup.object({
  email: yup
    .string()
    .required('checkout.validation.email')
    .matches(EMAIL_REGEX, 'checkout.validation.email')
    // The email is prepended with "<timestamp>+" which require 14 characters
    .max(114, 'checkout.validation.email'),
});

export const GuestUserRegisterSchema = GuestUserStubRegisterSchema.shape({
  firstName: yup
    .string()
    .required('checkout.invalid.first.name')
    .matches(FIRST_NAME_REGEXP, 'checkout.validation.first.name'),
  lastName: yup
    .string()
    .required('checkout.invalid.last.name')
    .matches(LAST_NAME_REGEXP, 'checkout.validation.last.name'),
  phone: yup
    .string()
    .matches(PHONE_FIELD_REGEXP, 'checkout.validation.phone.number')
    .max(16, 'checkout.validation.phone.number')
    .required('checkout.validation.phone.number')
    .nullable(),
});
