import { configService } from '@shared/config/lib/configService';
import { envConfigProvider } from '@shared/config/lib/providers/env';
import { jsonConfigProvider } from '@shared/config/lib/providers/json';
import { singleton } from '@shared/utils/src/singleton';
import { isDevelopment } from '../../utils';

export const config = singleton(() => {
  const configProviders = [envConfigProvider()];
  if (isDevelopment()) {
    configProviders.unshift(jsonConfigProvider({ REDIS_CACHE_ENABLED: 'false' }));
  }
  return configService(configProviders);
});
