import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FlexibleTextPartial } from '../../../partials';
import { colours, maxWidthPartial, media, slideToRightArrowsAnimation, spacing } from '../../../stylings';

export const CarouselContainer = styled.div`
  ${maxWidthPartial({ withPaddingXL: true, shouldBleedRight: true })}

  @media ${media.greaterThan('xx')} {
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled.div`
  @media ${media.greaterThan('xx')} {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;

  .products-count {
    font-size: 14px;
    margin-left: ${spacing.XXXS};

    @media ${media.greaterThan('lg')} {
      font-size: 16px;
      margin-left: 10px;
    }
  }
`;

export const CarouselTitle = styled(FlexibleTextPartial)<{ $lineHeight: string }>`
  margin: 0;

  &.truncated {
    -webkit-box-orient: vertical;
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    height: ${({ $lineHeight }) => `calc(${$lineHeight} * 2)`};
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

export const SwiperContainer = styled(Swiper)`
  display: flex;
  flex-direction: row;
`;
export const SwiperSlideContainer = styled(SwiperSlide)<{ $isMultiImage: boolean }>`
  width: ${({ $isMultiImage }) => ($isMultiImage ? '328px' : '272px')};

  &:nth-of-type(1) {
    margin-left: 0;
  }

  video {
    width: 100%;
  }
  @media ${media.greaterThan('lg')} {
    width: ${({ $isMultiImage }) => ($isMultiImage ? '634px' : '424px')};
  }
`;
export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-top: 0;

  @media ${media.greaterThan('lg')} {
    margin-right: ${spacing.S};
  }

  @media ${media.greaterThan('xl')} {
    margin-right: ${spacing.XXXL};
  }

  @media ${media.greaterThan('xx')} {
    margin-right: 0;
  }
`;

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  margin-left: 19px;
  margin-right: 27px;

  &.product-icons {
    display: none;

    @media ${media.greaterThan('lg')} {
      display: flex;
    }
  }

  @media ${media.greaterThan('lg')} {
    margin-right: 0;
  }
`;
export const IconButton = styled.button`
  align-items: center;
  background-color: ${colours.WHITE};
  border: 2px solid ${colours.BLACK};
  border-radius: 50%;
  display: flex;
  height: 25px;
  justify-content: center;
  min-width: 25px;
  padding: 0;
  ${slideToRightArrowsAnimation}

  &.swiper-button-disabled {
    border: 2px solid ${colours.MEDIUM_GREY};
    cursor: not-allowed;

    .arrow-icon {
      path {
        fill: ${colours.MEDIUM_GREY};
      }
    }
  }

  &.swiper-button-lock {
    display: none;
  }

  svg {
    height: 10px;
    width: 10px;
  }

  &.nav-prev {
    margin-right: ${spacing.S};
  }
`;
