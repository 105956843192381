import { forwardRef } from 'react';
import { Header } from '@radix-ui/react-accordion';
import type { AccordionHeaderProps } from '@radix-ui/react-accordion';

export const AccordionHeader = forwardRef<HTMLDivElement, AccordionHeaderProps>(
  ({ children, ...props }, forwardedRef) => (
    <Header {...props} ref={forwardedRef}>
      {children}
    </Header>
  ),
);

export default AccordionHeader;
