import type { TypedMoney } from '@commercetools/platform-sdk';

/**
 * In future when more we will need to support more currencies we can decide to move it in some storage (CT?).
 */
export const currenciesConfig: Record<string, Pick<TypedMoney, 'fractionDigits'>> = {
  AED: {
    fractionDigits: 2,
  },
  SAR: {
    fractionDigits: 2,
  },
};

export function getCurrencyFractionDigits(currencyCode: string): number {
  const currencyFractionDigits = currenciesConfig[currencyCode]?.fractionDigits;

  if (!currencyFractionDigits) {
    throw new Error(`There is no fraction digits configuration for ${currencyCode}`);
  }

  return currencyFractionDigits;
}

/**
 * For high precision money we use 4 fraction digits.
 */
export const highPrecisionFractionDigits = 4;
