import type {
  AltTextTextualDescriptionOfTheImage,
  FooterIcon,
} from '@amplience/content-types/typings/c-footer-icons-block';
import { ImagePartial } from '../../../../partials/image';
import { LinkPartial } from '../../../../partials/link';
import { sendSocialLinkEvent } from '../../../../utils/gtm/events/social_link/sendSocialLinkEvent';
import { RectangleIcon as RectangleIconStyle } from './RectangleIcon.styles';

export function RectangleIcon({ image, link }: FooterIcon) {
  if (!image) return null;

  const handleClick = () => {
    sendSocialLinkEvent(
      image?.altText as AltTextTextualDescriptionOfTheImage,
      (link?.localizedExternalLink || link?.externalLink) as string,
    );
  };

  return (
    <RectangleIconStyle onClick={handleClick}>
      {link ? (
        <LinkPartial {...link} ariaLabel={image.altText}>
          <ImagePartial
            layout="fill"
            altText={image.altText}
            src={image.image?.name ?? ''}
            assetType={image.assetType}
            aria-hidden
          />
        </LinkPartial>
      ) : (
        <ImagePartial
          layout="fill"
          altText={image?.altText}
          src={image.image?.name ?? ''}
          assetType={image.assetType}
          aria-hidden
        />
      )}
    </RectangleIconStyle>
  );
}
