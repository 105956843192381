import * as yup from 'yup';
import { EMAIL_REGEX } from '../b2c';

export const NewsletterSchema = yup.object({
  email: yup
    .string()
    .required('footer.newsletter.validation.email')
    .matches(EMAIL_REGEX, 'footer.newsletter.validation.email')
    .max(128, 'footer.newsletter.validation.email'),
});
