import { useEffect } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { storeKeyMapper } from '@shared/utils/src/ct';
import type { Cart } from '../../../codegen/types';
import { useCartContext } from '../../../context/cartContext';
import { useLocaleContext } from '../../../context/localeContext';
import { useUpdateItemQuantityMutation } from '../../../graphqlDocument/cart/updateItemQuantity.generated';
import { useLoadingCursor } from '../../../hooks/useLoadingCursor';
import { useNRBASession } from '../../../hooks/useNRBASession';
import { MinusCircled, PlusCircled } from '../../../icons';
import { colours } from '../../../stylings';
import { sendAddToCartFromQuantityButtonsEvent } from '../../../utils/gtm/events/add_to_cart/sendAddToCartFromQuantityButtonsEvent';
import { sendRemoveFromCartFromQuantityButtonsEvent } from '../../../utils/gtm/events/remove_from_cart/sendRemoveFromCartFromQuantityButtonsEvent';
import { getCtLocale } from '../../../utils/localeHelper';
import type { ProductLineItemAction } from './ProductLineItem.utils';
import { ProductLineItemActionType } from './ProductLineItem.utils';
import type { LineItemStatus } from './ProductLineItemList';

const S = {
  QuantityButton: styled.button`
    background: none;
    border: 0;
    padding: 0;
    position: relative;
    top: 1px;

    svg {
      border-radius: 50%;
    }

    &:disabled {
      cursor: not-allowed;

      & svg path {
        fill: ${colours.GREY};
      }
    }

    &:hover,
    &:focus {
      svg {
        background-color: ${colours.LIGHT_BLUE};
      }
    }
  `,
};

enum BasketError {
  SKU_CAP_ERROR = 'SkuCapError',
}

type WithQuantityButtonsProps = {
  name?: string;
  quantity: number;
  id: string;
  disableIncrease: boolean;
  skuCap: number | undefined | null;
  dispatch: Dispatch<ProductLineItemAction>;
  setLineItemStatus: Dispatch<SetStateAction<LineItemStatus[]>>;
  isStar2?: boolean;
};

export function WithQuantityButtons({
  name,
  quantity: lineItemQuantity,
  id,
  disableIncrease,
  skuCap,
  dispatch,
  setLineItemStatus,
  isStar2,
}: WithQuantityButtonsProps) {
  const { miniCart } = useCartContext();
  const { t } = useTranslation(['lib-global-common']);
  const { locale: contextLocale } = useLocaleContext();
  const { cartId, anonymousId, setMiniCart, setShouldUpdateDeliveryOptions } = useCartContext();
  const router = useRouter();

  const nrbaContextHeader = useNRBASession();

  const [updateItemQuantityMutation, { loading }] = useUpdateItemQuantityMutation(nrbaContextHeader);

  const handleModifyQuantity = (lineItemId: string, amount: number) => async () => {
    dispatch({
      type: ProductLineItemActionType.SET_HAS_GENERIC_ERROR,
      hasGenericError: false,
    });
    dispatch({
      type: ProductLineItemActionType.SET_IS_SKU_CAP_REACHED,
      isSkuCapReached: false,
    });

    const storeKey = storeKeyMapper(contextLocale);
    const locale = getCtLocale(contextLocale);
    const quantity = lineItemQuantity + amount;

    const input = {
      cartId,
      anonymousId,
      storeKey,
      locale,
      lineItemId,
      quantity,
    };

    await updateItemQuantityMutation({
      variables: {
        input,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        if (e.graphQLErrors && e.graphQLErrors.length > 0) {
          const error = e.graphQLErrors[0];
          if (error.errorType.toLowerCase() === BasketError.SKU_CAP_ERROR.toLowerCase()) {
            dispatch({
              type: ProductLineItemActionType.SET_IS_SKU_CAP_REACHED,
              isSkuCapReached: true,
            });
          } else {
            dispatch({
              type: ProductLineItemActionType.SET_HAS_GENERIC_ERROR,
              hasGenericError: true,
            });
          }
        }
      },
      onCompleted: ({ updateItemQuantity }) => {
        setMiniCart(updateItemQuantity as Cart);
        setShouldUpdateDeliveryOptions(true);

        dispatch({
          type: ProductLineItemActionType.SET_LINE_ITEM_QUANTITY,
          value: amount,
          add: true,
        });

        const itemPrevQty = lineItemQuantity;
        const itemNewQty = quantity;

        if (itemPrevQty < itemNewQty) {
          sendAddToCartFromQuantityButtonsEvent(input.lineItemId, updateItemQuantity as Cart);
        } else if (itemNewQty === 0) {
          setLineItemStatus((prev) =>
            prev.map((item) => (item.lineItem.id === lineItemId ? { ...item, isDeleted: true } : item)),
          );

          sendRemoveFromCartFromQuantityButtonsEvent(miniCart, lineItemId, updateItemQuantity as Cart);
        } else {
          sendRemoveFromCartFromQuantityButtonsEvent(miniCart, lineItemId, updateItemQuantity as Cart);
        }

        if (isStar2) {
          router.reload();
        }
      },
    });
  };

  useLoadingCursor(loading);

  useEffect(() => {
    dispatch({
      type: ProductLineItemActionType.SET_IS_SKU_CAP_REACHED,
      isSkuCapReached: skuCap ? lineItemQuantity >= skuCap : false,
    });
  }, [skuCap, lineItemQuantity, dispatch]);

  return (
    <>
      <S.QuantityButton
        type="button"
        aria-label={`${t('cart.line.item.decrease.quantity')}, ${name}`}
        onClick={handleModifyQuantity(id, -1)}
        disabled={loading}
        data-testid="decreaseQuantity"
      >
        <MinusCircled
          height="16px"
          width="16px"
          viewBox="0 0.5 19 19"
          preserveAspectRatio="xMaxYMax slice"
          aria-hidden
        />
      </S.QuantityButton>
      {lineItemQuantity}
      <S.QuantityButton
        type="button"
        aria-label={`${t('cart.line.item.increase.quantity')}, ${name}`}
        onClick={handleModifyQuantity(id, +1)}
        disabled={loading || disableIncrease}
        data-testid="increaseQuantity"
      >
        <PlusCircled
          height="16px"
          width="16px"
          viewBox="0 0.5 19 19"
          preserveAspectRatio="xMaxYMax slice"
          aria-hidden
        />
      </S.QuantityButton>
    </>
  );
}
