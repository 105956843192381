import type { ScrollToTop as ScrollToTopSchema } from '@amplience/content-types/typings/c-scroll-to-top';
import { ArrowUpSmall } from '../../../icons';
import { getLocalizedValue } from '../../../utils/transformers';
import { ScrollToTopButton, Text } from './ScrollToTop.styles';

export type ScrollToTopProps = ScrollToTopSchema & {
  isDesktop?: boolean;
};
export function ScrollToTop({ scrollToTopMessage, isDesktop }: ScrollToTopProps) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <ScrollToTopButton $isDesktop={isDesktop} data-testid="scrollToTopButton" type="button" onClick={scrollToTop}>
      <ArrowUpSmall />
      {scrollToTopMessage ? <Text>{getLocalizedValue(scrollToTopMessage)}</Text> : null}
    </ScrollToTopButton>
  );
}
