export async function fetchGeoIPInfo({ locale }: { locale: string }) {
  try {
    const response = await fetch('/api/geoip-info', {
      method: 'POST',
      body: JSON.stringify({ locale }),
    });
    const { hasLocation, promptRedirect, redirectInfo } = await response.json();
    return { hasLocation, promptRedirect, redirectInfo };
  } catch {
    return { hasLocation: false, promptRedirect: false, redirectInfo: null };
  }
}
