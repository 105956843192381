import { forwardRef } from 'react';
import { Trigger } from '@radix-ui/react-accordion';
import type { AccordionTriggerProps } from '@radix-ui/react-accordion';

export const AccordionTrigger = forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, ...props }, forwardedRef) => (
    <Trigger {...props} ref={forwardedRef}>
      {children}
    </Trigger>
  ),
);

export default AccordionTrigger;
