import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type AppointmentsQueryVariables = Types.Exact<{
  input: Types.AppointmentsInput;
}>;

export type AppointmentsQuery = {
  __typename?: 'Query';
  appointments: {
    __typename?: 'Appointments';
    slots: Array<{ __typename?: 'TimeSlot'; hours: number; minutes: number } | null>;
  };
};

export const AppointmentsDocument = gql`
  query appointments($input: AppointmentsInput!) {
    appointments(input: $input) {
      slots {
        hours
        minutes
      }
    }
  }
`;

/**
 * __useAppointmentsQuery__
 *
 * To run a query within a React component, call `useAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
}
export function useAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentsQuery, AppointmentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentsQuery, AppointmentsQueryVariables>(AppointmentsDocument, options);
}
export type AppointmentsQueryHookResult = ReturnType<typeof useAppointmentsQuery>;
export type AppointmentsLazyQueryHookResult = ReturnType<typeof useAppointmentsLazyQuery>;
export type AppointmentsQueryResult = Apollo.QueryResult<AppointmentsQuery, AppointmentsQueryVariables>;
