import type { CentPrecisionPriceRanges, Maybe, Price } from '../../../codegen/types';
import { useFeatureFlagsContext, useLocaleContext } from '../../../context';
import { Locale, formatAmountWithCurrency } from '../../../utils';
import { PriceWrapper } from './ProductItemPrice.styles';

export type PriceWithRange = {
  price: Price;
  priceRanges?: Maybe<CentPrecisionPriceRanges>;
  priceRangeFeatureFlag?: boolean;
  localeProp?: string;
};
export function ProductItemPrice({ price, priceRangeFeatureFlag, priceRanges, localeProp }: PriceWithRange) {
  const { isBtsProductPriceRangeEnabled: contextFlag } = useFeatureFlagsContext();
  const isBtsProductPriceRangeEnabled = priceRangeFeatureFlag || contextFlag;
  const { wasPrice, thenPrice, actualPrice } = price;
  const { locale: contextLocale } = useLocaleContext();
  const locale = localeProp || contextLocale;
  const isLocaleAvailable = [Locale.EN_GB, Locale.EN_IE, Locale.STOREFRONT_OUTLET_LOCALE].includes(locale as string);
  const isNotNorthAmericanLocale = ![Locale.EN_US, Locale.EN_CA, Locale.FR_CA].includes(locale as string);

  // Without split pricing
  const isWasPrice = Boolean(wasPrice?.centAmount && wasPrice?.centAmount !== actualPrice?.centAmount);
  const isThenPrice = Boolean(
    thenPrice?.centAmount && isLocaleAvailable && thenPrice?.centAmount !== actualPrice?.centAmount,
  );
  const wasPriceValue = formatAmountWithCurrency(wasPrice?.centAmount, wasPrice?.currencyCode);
  const thenPriceValue = formatAmountWithCurrency(thenPrice?.centAmount, thenPrice?.currencyCode);
  const actualPriceValue = formatAmountWithCurrency(actualPrice?.centAmount ?? 0, actualPrice?.currencyCode);

  // With split pricing, for PLP/search-page/search-flyout
  let isNotMarkdown = Boolean(
    actualPrice?.maxPrice &&
      wasPrice?.maxPrice &&
      actualPrice?.minPrice &&
      wasPrice?.minPrice &&
      actualPrice?.maxPrice === wasPrice?.maxPrice &&
      actualPrice?.minPrice === wasPrice?.minPrice,
  );
  let actualPriceHasPriceRange = actualPrice && actualPrice.minPrice !== actualPrice.maxPrice;
  let isMinThenPriceDifferentMinWasPrice = Boolean(
    thenPrice?.minPrice && wasPrice?.minPrice && thenPrice.minPrice !== wasPrice.minPrice,
  );
  let isMinWasPrice = Boolean(wasPrice?.minPrice && !isNotMarkdown);
  let isMinThenPrice = Boolean(
    isLocaleAvailable && thenPrice?.minPrice && actualPrice?.minPrice && thenPrice.minPrice > actualPrice.minPrice,
  );
  let minWasPriceValue = wasPrice?.minPrice && formatAmountWithCurrency(wasPrice?.minPrice, wasPrice?.currencyCode);
  let minThenPriceValue = thenPrice?.minPrice && formatAmountWithCurrency(thenPrice?.minPrice, thenPrice?.currencyCode);
  let priceRangeMinActualPriceValue = formatAmountWithCurrency(actualPrice?.minPrice, actualPrice?.currencyCode);
  let priceRangeMaxActualPriceValue = formatAmountWithCurrency(actualPrice?.maxPrice, actualPrice?.currencyCode);

  // for carousels only
  if (priceRanges) {
    isNotMarkdown = Boolean(
      priceRanges.now.maxPrice &&
        priceRanges.was?.maxPrice &&
        priceRanges.now.minPrice &&
        priceRanges.was?.minPrice &&
        priceRanges.now.maxPrice === priceRanges.was?.maxPrice &&
        priceRanges.now.minPrice === priceRanges.was?.minPrice,
    );
    actualPriceHasPriceRange = priceRanges.now.minPrice !== priceRanges.now.maxPrice;
    isMinThenPriceDifferentMinWasPrice =
      // eslint-disable-next-line promise/prefer-await-to-then
      Boolean(priceRanges.then && priceRanges.was && priceRanges.then?.minPrice !== priceRanges.was?.minPrice);
    isMinWasPrice = Boolean(priceRanges.was?.minPrice && !isNotMarkdown);
    isMinThenPrice =
      // eslint-disable-next-line promise/prefer-await-to-then
      Boolean(isLocaleAvailable && priceRanges.then?.minPrice && priceRanges.then?.minPrice > priceRanges.now.minPrice);

    minWasPriceValue = formatAmountWithCurrency(priceRanges.was?.minPrice, priceRanges.currencyCode);
    // eslint-disable-next-line promise/prefer-await-to-then
    minThenPriceValue = formatAmountWithCurrency(priceRanges.then?.minPrice, priceRanges.currencyCode);
    priceRangeMinActualPriceValue = formatAmountWithCurrency(priceRanges.now.minPrice, priceRanges.currencyCode);
    priceRangeMaxActualPriceValue = formatAmountWithCurrency(priceRanges.now.maxPrice, priceRanges.currencyCode);
  }

  return (
    <PriceWrapper $isBtsProductPriceRangeEnabled={isBtsProductPriceRangeEnabled}>
      {isBtsProductPriceRangeEnabled && isNotNorthAmericanLocale ? (
        <>
          {isMinWasPrice && (
            <span className="was-price" aria-label={`The original price was ${minWasPriceValue}`}>
              {minWasPriceValue}
            </span>
          )}
          {isMinThenPrice && isMinThenPriceDifferentMinWasPrice && (
            <span className="then-price" aria-label={`then reduced to ${minThenPriceValue}`}>
              {minThenPriceValue}
            </span>
          )}
          {actualPriceHasPriceRange && (
            <span
              className="price-range"
              data-lowest-price={priceRangeMinActualPriceValue}
              data-highest-price={priceRangeMaxActualPriceValue}
              aria-label={
                minWasPriceValue || minThenPriceValue
                  ? `and now it's between ${priceRangeMinActualPriceValue} - ${priceRangeMaxActualPriceValue}`
                  : `${priceRangeMinActualPriceValue} - ${priceRangeMaxActualPriceValue}`
              }
            >{`${priceRangeMinActualPriceValue} - ${priceRangeMaxActualPriceValue}`}</span>
          )}
          {!actualPriceHasPriceRange && actualPrice?.centAmount && (
            <span
              className="now-price"
              aria-label={minWasPriceValue || minThenPriceValue ? `and now it's ${actualPriceValue}` : actualPriceValue}
            >
              {actualPriceValue}
            </span>
          )}
        </>
      ) : (
        // TODO: part to be removed when removing the feature flag
        <>
          {isWasPrice && (
            <span className="was-price" aria-label={`The original price was ${wasPriceValue}`}>
              {wasPriceValue}
            </span>
          )}
          {isThenPrice && (
            <span className="then-price" aria-label={`then reduced to ${thenPriceValue}`}>
              {thenPriceValue}
            </span>
          )}
          {!!actualPrice?.centAmount && (
            <span
              className="now-price"
              aria-label={isWasPrice || isThenPrice ? `and now it's ${actualPriceValue}` : actualPriceValue}
            >
              {actualPriceValue}
            </span>
          )}
        </>
      )}
    </PriceWrapper>
  );
}
