import type { ReactElement } from 'react';
import Link from 'next/link';
import type { CallToAction as CTASchema } from '@amplience/content-types/typings/p-partials';
import { useLocaleContext } from '../../context/localeContext';
import { getLocalizedValue } from '../../utils/transformers';
import { useGetLinkAttributes } from '../link';

export interface CTAPartialProps extends CTASchema {
  tag?: 'a' | 'button';
  className?: string;
  testId?: string;
}

export function CTAPartial({ className, link, tag, text, testId }: CTAPartialProps): ReactElement {
  const { locale } = useLocaleContext();
  const { openInNewTab } = useGetLinkAttributes(link);

  const openLink = () => {
    if (openInNewTab)
      window.open(
        ((link?.localizedExternalLink || link?.externalLink) as string) ??
          `/${(link?.localizedInternalLink || link?.internalLink) as string}`,
        '_blank',
      );
    else
      window.location.href =
        ((link?.localizedExternalLink || link?.externalLink) as string) ??
        `/${(link?.localizedInternalLink || link?.internalLink) as string}`;
  };

  const localizedText = getLocalizedValue(text);
  switch (tag) {
    case 'button':
      return (
        <button type="button" className={className} onClick={openLink} data-testid={testId}>
          {localizedText}
        </button>
      );
    case 'a':
    default:
      if (link?.localizedExternalLink || link?.externalLink)
        return (
          <a
            className={className}
            href={(link?.localizedExternalLink || link?.externalLink) as string}
            target={openInNewTab ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {localizedText}
          </a>
        );
      return (
        <Link href={`/${link?.localizedInternalLink || link?.internalLink}`} passHref locale={locale}>
          <a aria-label={localizedText} className={className}>
            {localizedText}
          </a>
        </Link>
      );
  }
}
