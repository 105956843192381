/* eslint-disable security/detect-unsafe-regex */
import * as Yup from 'yup';
import { Store } from '@ct/types';
import { FIRST_NAME_REGEXP, LAST_NAME_REGEXP, PHONE_FIELD_REGEXP } from './register';

export const statesUS = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'AA',
  'AE',
  'AP',
  'UM',
  'VI',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

export const NUMBER_FIELD_REGEXP = /^[0-9]*$/i;
export const US_POSTCODE_REGEXP = /^[0-9]{5}(-[0-9]{4})?$/;
export const CA_POSTCODE_REGEXP = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const TEXT_FIELD_REGEXP = /^(?=.{1,255}$)[0-9a-z\u00C0-\u024F\u1E00-\u1EFF '’,?.()-]+(?:['_.\s][-z]+)*$/i;
const UK_POSTCODE_REGEXP = /[a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?\s?[0-9][a-zA-Z]{2}/;

const postalCodeRule = {
  [Store.UK]: Yup.string()
    .matches(UK_POSTCODE_REGEXP, 'checkout.validation.postcode')
    .max(8, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROI]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .max(8, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.UK_OUTLET]: Yup.string()
    .matches(UK_POSTCODE_REGEXP, 'checkout.validation.postcode')
    .max(8, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.US]: Yup.string()
    .matches(US_POSTCODE_REGEXP, 'checkout.validation.zipcode')
    .max(10, 'checkout.validation.zipcode')
    .required('checkout.validation.zipcode'),
  [Store.CA]: Yup.string()
    .matches(CA_POSTCODE_REGEXP, 'checkout.validation.postcode.ca')
    .max(7, 'checkout.validation.postcode.ca')
    .required('checkout.validation.postcode.ca'),
  [Store.DE]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.FR]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.NL]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.IT]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ES]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_CH]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_BE]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_PL]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_AT]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_SM]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_LU]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_DK]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
  [Store.ROE_SCAND]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'checkout.validation.postcode')
    .required('checkout.validation.postcode'),
};

const billingPostalCodeRule = {
  [Store.UK]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROI]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.UK_OUTLET]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.US]: Yup.string()
    .matches(US_POSTCODE_REGEXP, 'checkout.validation.zipcode')
    .max(10, 'checkout.validation.zipcode')
    .required('checkout.validation.zipcode'),
  [Store.CA]: Yup.string()
    .matches(CA_POSTCODE_REGEXP, 'checkout.validation.postcode.ca')
    .max(7, 'checkout.validation.postcode.ca')
    .required('checkout.validation.postcode.ca'),
  [Store.DE]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.FR]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.NL]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.IT]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ES]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_CH]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_BE]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_PL]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_AT]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_SM]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_LU]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_DK]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
  [Store.ROE_SCAND]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'billing.validation.postcode')
    .required('billing.validation.postcode'),
};

export const stateRule = {
  [Store.US]: statesUS,
  [Store.CA]: provinces,
  [Store.ROI]: [],
  [Store.UK]: [],
  [Store.UK_OUTLET]: [],
  [Store.DE]: [],
  [Store.ES]: [],
  [Store.FR]: [],
  [Store.NL]: [],
  [Store.IT]: [],
  [Store.ROE]: [],
  [Store.ROE_CH]: [],
  [Store.ROE_BE]: [],
  [Store.ROE_PL]: [],
  [Store.ROE_AT]: [],
  [Store.ROE_SM]: [],
  [Store.ROE_LU]: [],
  [Store.ROE_DK]: [],
  [Store.ROE_SCAND]: [],
};

export const updateCartAddressSchema = (storeKey: Store, billing?: boolean) => {
  const textFieldRule = Yup.string().notRequired();
  return Yup.object().shape({
    line1: textFieldRule
      .min(1, 'checkout.validation.address.line1')
      .max(255, 'checkout.validation.address.line1')
      .required('checkout.validation.address.line1'),
    line2: textFieldRule.max(255, 'checkout.validation.address.line2').notRequired(),
    company: textFieldRule.max(256, 'checkout.validation.company.name').notRequired(),
    city: textFieldRule
      .min(1, 'checkout.validation.town.or.city')
      .max(40, 'checkout.validation.town.or.city')
      .required('checkout.validation.town.or.city'),
    postalCode: billing ? billingPostalCodeRule[storeKey] : postalCodeRule[storeKey],
    state:
      stateRule[storeKey].length > 0
        ? textFieldRule.matches(TEXT_FIELD_REGEXP).oneOf(stateRule[storeKey]).required()
        : textFieldRule.matches(TEXT_FIELD_REGEXP).notRequired(),
    country: textFieldRule.matches(TEXT_FIELD_REGEXP).required(),
    firstName: textFieldRule
      .required('checkout.invalid.first.name')
      .matches(FIRST_NAME_REGEXP, 'checkout.validation.first.name'),
    lastName: textFieldRule
      .required('checkout.invalid.last.name')
      .matches(LAST_NAME_REGEXP, 'checkout.validation.last.name'),
    phone: textFieldRule
      .matches(PHONE_FIELD_REGEXP, 'checkout.validation.phone.number')
      .max(16, 'checkout.validation.phone.number')
      .required('checkout.validation.phone.number')
      .nullable(),
  });
};

export const updateCartPostalAddressSchema = (storeKey: Store) => {
  const textFieldRule = Yup.string().notRequired();

  return Yup.object().shape({
    postalCode: postalCodeRule[storeKey],
    country: textFieldRule.matches(TEXT_FIELD_REGEXP).required(),
  });
};
