import styled, { css } from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { DatepickerVariant } from './DatepickerVariant';

const HEIGHT_MOBILE = '44px';
const HEIGHT_DESKTOP = '50px';

const initialStyle = (variant: DatepickerVariant | undefined) => css`
  max-width: ${() => (variant === DatepickerVariant.SMALL ? '262px' : '328px')};
  @media ${media.greaterThan('lg')} {
    max-width: 340px;
  }
`;

const pdpStyle = css`
  max-width: initial;
`;

export const Root = styled.div`
  width: 100%;
`;
export const InputContainer = styled.div<{ $variant?: DatepickerVariant; $isFullWidth: boolean }>`
  height: ${HEIGHT_MOBILE};
  position: relative;
  width: 100%;
  ${({ $isFullWidth, $variant }) => ($isFullWidth ? pdpStyle : initialStyle($variant))}

  @media ${media.greaterThan('lg')} {
    height: ${HEIGHT_DESKTOP};
    margin-bottom: ${spacing.XXXS};
  }
`;
export const IconWrapper = styled.div<{ $wrapperType: string }>`
  align-items: center;
  display: flex;
  height: 100%;
  left: ${({ $wrapperType }) => $wrapperType === 'left' && spacing.XS};
  pointer-events: none;
  position: absolute;
  right: ${({ $wrapperType }) => $wrapperType === 'right' && 0};
  top: 0;
  width: 30px;
`;
export const Input = styled.input<{ $isInputError?: boolean }>`
  ${TypographyStyles.Body.Medium.Regular}
  appearance: none;
  border: 1px solid ${({ $isInputError }) => ($isInputError ? colours.ERROR_PRIMARY : colours.GREY)};
  border-radius: 0;
  cursor: pointer;
  height: 100%;
  padding: 0 ${HEIGHT_MOBILE} 0 ${spacing.L};
  width: 100%;

  &:focus {
    border-color: ${colours.DARK_GREY};
    outline: none;
  }

  &::placeholder {
    color: ${colours.GREY};
  }

  @media ${media.greaterThan('lg')} {
    padding-right: ${HEIGHT_DESKTOP};
  }
`;
export const HeaderHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${spacing.XS} calc(${spacing.XS} + 4px);
`;
export const Header = styled.h4`
  ${TypographyStyles.Headings.H4SemiBold}
  color: ${colours.DARK_BLUE};
  margin: 0;
  text-transform: uppercase;
`;
export const ButtonsHolder = styled.div`
  display: flex;
  gap: ${spacing.XS};
`;
export const NavButton = styled.button`
  background: none;
  border: 0;
  padding: 0;

  &[disabled] {
    opacity: 0.3;
  }
`;
export const Calendar = styled.div<{ $variant?: DatepickerVariant }>`
  border-color: ${colours.BLACK};
  border-radius: 0;
  max-width: ${({ $variant }) => ($variant === DatepickerVariant.SMALL ? '262px' : '328px')};
  padding: ${spacing.XXS} ${spacing.XXXS} ${spacing.S};
  position: absolute;
  right: 0;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    max-width: 340px;
    padding-bottom: ${spacing.S};
    padding-inline: 0;
  }

  .react-datepicker {
    &__header {
      background: none;
      border: 0;
    }

    &__month-container {
      float: none;
      padding: 0 calc(${spacing.XXXXS} - 1px);
    }

    &__month,
    &__day,
    &__time-name {
      ${TypographyStyles.Body.Small.Regular}
      margin: ${({ $variant }) =>
        $variant === DatepickerVariant.SMALL
          ? `${spacing.XXXXXS} ${spacing.XXXXS}`
          : `${spacing.XXXXXS} calc(${spacing.XXXS} + 0.5px)`};

      @media ${media.greaterThan('lg')} {
        margin: ${spacing.XXXXS} ${spacing.XXXS};
      }
    }

    &__day {
      height: 27px;
      width: 27px;

      @media ${media.lessThan('lg')} {
        height: 24px;
        width: 24px;
      }

      &:hover,
      &:focus {
        border-radius: 50%;
      }

      &-name {
        ${TypographyStyles.Label.Small.Medium}
        margin: ${({ $variant }) =>
          $variant === DatepickerVariant.SMALL
            ? `${spacing.XXXXXS} ${spacing.XXXXXS}`
            : `${spacing.XXXXXS} calc(${spacing.XXXS} - 1px)`};
        text-transform: uppercase;

        @media ${media.greaterThan('lg')} {
          margin: ${spacing.XXXXS} ${spacing.XXXS};
        }
      }

      &--keyboard-selected.datepicker-highlight-preselected-date {
        background: ${colours.DARK_BLUE};
        border-radius: 50%;
        color: ${colours.WHITE};
      }

      &--keyboard-selected {
        background: transparent;
        border-radius: 50%;
        color: ${colours.BLACK};
      }

      &--selected {
        background: ${colours.DARK_BLUE};
        border-radius: 50%;
        color: ${colours.WHITE};
      }

      &--disabled {
        color: ${colours.MID_GREY};
        text-decoration: line-through;
      }

      &--outside-month {
        background: ${colours.WHITE};
        color: transparent;
        pointer-events: none;
      }
    }
  }
`;
