import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type AddCouponType = {
  coupon: string;
  coupon_value: number;
};
export function sendAddCouponEvent(couponData: AddCouponType, cartId: string) {
  sendDataLayerEvent(
    EventNamesType.add_coupon,
    {
      custom_data: {
        ...couponData,
      },
      cart: {
        ...couponData,
      },
    },
    true,
    { cartId },
  );
}
