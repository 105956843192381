import type { ReactElement } from 'react';
import styled from 'styled-components';
import type { Title as TitleContentSchema } from '@amplience/content-types/typings/p-partials';
import { getLocalizedValue } from '../../utils/transformers';
import { useColor } from '../color';
import { useTextTransform } from '../textTransform';

export interface TitlePartialProps extends TitleContentSchema {
  className?: string;
  id?: string;
  tag?: keyof JSX.IntrinsicElements;
}

const S = {
  Heading: styled.h2``,
  Title: styled.span<TitlePartialProps>`
    ${({ color }) => useColor(color)}
    ${({ textTransform }) => useTextTransform(textTransform)}
  `,
};

export function TitlePartial({
  className,
  color,
  id,
  textTransform,
  tag,
  text,
}: TitlePartialProps): ReactElement | null {
  return text ? (
    <S.Heading as={tag} className={className} id={id}>
      <S.Title color={color} textTransform={textTransform}>
        {getLocalizedValue(text)}
      </S.Title>
    </S.Heading>
  ) : null;
}
