import type { ChangeEvent, MutableRefObject, SyntheticEvent } from 'react';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { usePopper } from 'react-popper';
import styled from 'styled-components';
import { storeKeyMapper } from '@shared/utils/src/ct';
import { fromCents } from '@shared/utils/src/price/money';
import type { Cart } from '../../../codegen/types';
import { useCartContext } from '../../../context/cartContext';
import { useCheckoutConfirmationContext } from '../../../context/checkoutConfirmationContext';
import { useLocaleContext } from '../../../context/localeContext';
import { useUpdateCartPostalAddressMutation } from '../../../graphqlDocument/cart/updateCartPostalAddress.generated';
import { useNRBASession } from '../../../hooks/useNRBASession';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { ArrowDown, ArrowUp, QuestionMarkTooltip } from '../../../icons';
import { TypographyStyles, colours, media, spacing, zPriceTooltip, zPriceTooltipArrow } from '../../../stylings';
import { isAddressDummy } from '../../../utils/address';
import { getCurrencySymbol } from '../../../utils/currencyHelper';
import { Locale, getCtLocale } from '../../../utils/localeHelper';

const S = {
  TaxesContainer: styled.div<{ $isAllowedLocale: boolean }>`
    display: ${({ $isAllowedLocale }) => ($isAllowedLocale ? 'block' : 'none')};
  `,
  TaxAccordion: styled.div`
    all: inherit;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  `,

  TaxLabel: styled.div`
    align-items: center;
    display: flex;
    gap: 6px;
  `,
  EstimateButton: styled.button`
    ${TypographyStyles.Body.Small.SemiBold}
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${colours.BLACK};
    cursor: pointer;
    display: flex;
    gap: ${spacing.XXXXS};
    padding: 0;
    user-select: none;
  `,
  TotalTaxPrice: styled.span<{ $showTotalTaxPrice: boolean }>`
    display: ${({ $showTotalTaxPrice }) => ($showTotalTaxPrice ? 'block' : 'none')};
  `,
  TooltipButtonContainer: styled.button`
    all: initial;
    cursor: pointer;
  `,
  TaxTooltipBubble: styled.div`
    ${TypographyStyles.Body.Tiny.Regular}
    background: ${colours.WHITE};
    filter: drop-shadow(0 0 1px ${colours.BLACK});
    max-width: 300px;
    padding: 20px;
    width: auto;
    z-index: ${zPriceTooltip};

    &[data-popper-placement^='top'] > .arrow {
      bottom: 25px;
    }

    &[data-popper-placement^='bottom'] > .arrow {
      top: 25px;
    }

    @media ${media.greaterThan('lg')} {
      width: 300px;
    }
  `,
  TaxTooltipArrow: styled.div`
    margin-left: -15px;
    position: absolute;
    z-index: ${zPriceTooltipArrow};

    &:before,
    &:after {
      border: 20px solid transparent;
      bottom: 0;
      content: '';
      position: absolute;
    }

    &:before {
      border-bottom: 30px solid ${colours.WHITE};
      border-top: 0;
      bottom: 20px;
      left: -4px;
    }

    &:after {
      border-bottom: 0;
      border-top: 30px solid ${colours.WHITE};
      left: -4px;
      top: 15px;
    }
  `,

  AccordionContainer: styled.div<{ $isAccordionOpen: boolean }>`
    align-items: center;
    display: ${({ $isAccordionOpen }) => ($isAccordionOpen ? 'flex' : 'none')};
    flex-direction: column;
    margin-top: ${spacing.XXXS};
  `,
  InputContainer: styled.form`
    display: flex;
    width: 100%;
  `,
  TaxInput: styled.input<{ $isInputError: boolean }>`
    ${TypographyStyles.Body.Medium.Regular}
    border: ${({ $isInputError }) => ($isInputError ? 'none' : `1px solid ${colours.GREY}`)};
    margin-right: ${spacing.XXXS};
    outline: ${({ $isInputError }) => ($isInputError ? `1px solid ${colours.RED}` : 'none')};
    padding: 10px ${spacing.XXS};
    width: 100%;

    @media ${media.greaterThan('lg')} {
      max-width: 240px;
    }

    &::placeholder {
      color: ${colours.GREY};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  `,
  TaxEstimateButton: styled.button`
    ${TypographyStyles.Button.SemiBold}
    background-color: ${colours.DARK_BLUE};
    border: none;
    color: ${colours.WHITE};
    padding: 14px;
    text-transform: uppercase;

    &:disabled {
      background-color: ${colours.LIGHT_GREY_2};
      border: 1px solid ${colours.GREY};
      color: ${colours.GREY};
      cursor: not-allowed;
    }
  `,
  InputErrorMessage: styled.span`
    ${TypographyStyles.Body.Tiny.Medium}
    color: ${colours.ERROR_PRIMARY};
    margin-top: ${spacing.XXXS};
  `,
};

type State = {
  isTaxTooltipOpen: boolean;
  isTaxAccordionOpen: boolean;
  postalCode: string | undefined;
  totalTaxPrice: number | undefined;
  zipCodeErrorMessage: string | undefined;
};

type Action =
  | { type: 'TOGGLE_TAX_ACCORDION' }
  | { type: 'TOGGLE_TOOLTIP_BUBBLE'; payload: boolean }
  | { type: 'POSTAL_CODE'; payload: string }
  | { type: 'TOTAL_TAX_PRICE'; payload: number | undefined }
  | { type: 'ZIP_CODE_ERROR_MESSAGE'; payload: string };

const taxEstimationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_TAX_ACCORDION':
      return { ...state, isTaxAccordionOpen: !state.isTaxAccordionOpen };
    case 'TOGGLE_TOOLTIP_BUBBLE':
      return { ...state, isTaxTooltipOpen: action.payload };
    case 'POSTAL_CODE':
      return { ...state, postalCode: action.payload };
    case 'ZIP_CODE_ERROR_MESSAGE':
      return { ...state, zipCodeErrorMessage: action.payload };
    case 'TOTAL_TAX_PRICE':
      return { ...state, totalTaxPrice: action.payload };
    default:
      return state;
  }
};

export function TaxEstimation({ taxes }: { taxes: number | undefined }) {
  const { cartId, anonymousId, currency, miniCart, setMiniCart } = useCartContext();
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common']);
  const { orderData, isConfirmationPage } = useCheckoutConfirmationContext();
  const { deliveryAddress } = { ...miniCart };

  const router = useRouter();
  const currentLocale = locale ?? Locale.DEFAULT_LOCALE;
  const isLocaleUS = locale === Locale.EN_US;
  const isLocaleCA = locale === Locale.EN_CA || locale === Locale.FR_CA;
  const isCheckoutPage = router.asPath.includes('/checkout');
  const allowedLocale = [Locale.EN_CA, Locale.FR_CA, Locale.EN_US].includes(locale as string);
  const storeKey = storeKeyMapper(currentLocale);
  const tooltipContainerRef = useRef<HTMLDivElement>(null);
  const tooltipButtonRef = useRef<HTMLButtonElement>(null);

  const nrbaContextHeader = useNRBASession();

  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const [updateCartPostalAddressMutation, { loading: isPostalLoading }] =
    useUpdateCartPostalAddressMutation(nrbaContextHeader);

  const [{ isTaxAccordionOpen, isTaxTooltipOpen, postalCode, totalTaxPrice, zipCodeErrorMessage }, dispatch] =
    useReducer(taxEstimationReducer, {
      isTaxAccordionOpen: false,
      isTaxTooltipOpen: false,
      postalCode:
        !router.asPath.includes('/checkout') && !isAddressDummy(deliveryAddress?.key)
          ? deliveryAddress?.postalCode?.toUpperCase() ?? ''
          : '',
      totalTaxPrice: taxes,
      zipCodeErrorMessage: '',
    });

  const { styles, attributes } = usePopper(tooltipButtonRef.current, tooltipContainerRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'bottom-start'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [-50, 23],
        },
      },
    ],
  });

  useOutsideClick(tooltipContainerRef as unknown as MutableRefObject<HTMLDivElement>, () =>
    dispatch({ type: 'TOGGLE_TOOLTIP_BUBBLE', payload: false }),
  );

  const openTaxAccordion = () => dispatch({ type: 'TOGGLE_TAX_ACCORDION' });
  const openTaxTooltip = () => dispatch({ type: 'TOGGLE_TOOLTIP_BUBBLE', payload: true });
  const changeZipCode = (event: ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: 'POSTAL_CODE', payload: event.target.value });

  const canadaPostalCodeRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][- ]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
  const usPostalCodeRegex = /(\d{5})/g;
  const submitPostalCode = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (
      !postalCode ||
      (isLocaleCA && !canadaPostalCodeRegex.test(postalCode as string)) ||
      (isLocaleUS && !usPostalCodeRegex.test(postalCode as string))
    ) {
      dispatch({ type: 'ZIP_CODE_ERROR_MESSAGE', payload: t('cart.input.validation.error') });
    } else {
      const input = {
        cartId,
        anonymousId,
        storeKey,
        locale: getCtLocale(currentLocale),
        country: storeKey.toUpperCase(),
        postalCode,
      };
      try {
        const data = await updateCartPostalAddressMutation({ variables: { input } });

        if (data) {
          if (zipCodeErrorMessage) dispatch({ type: 'ZIP_CODE_ERROR_MESSAGE', payload: '' });
          dispatch({ type: 'TOGGLE_TAX_ACCORDION' });

          if (data.data?.updateCartPostalAddress) {
            const { updateCartPostalAddress: updatedCartData } = data.data;

            setMiniCart({
              ...(miniCart as Cart),
              total: updatedCartData.total,
              taxes: updatedCartData.taxes,
            });
          }
        }
      } catch (e) {
        dispatch({ type: 'ZIP_CODE_ERROR_MESSAGE', payload: t('cart.input.error') });
        // eslint-disable-next-line no-console
        console.log('error', e);
      }
    }
  };

  useEffect(() => {
    dispatch({ type: 'TOTAL_TAX_PRICE', payload: taxes });
  }, [taxes]);

  const getCurrency = isConfirmationPage ? getCurrencySymbol(orderData?.getOrderedCart?.currency) : currency;
  const displayedTax = `${getCurrency}${fromCents(totalTaxPrice)}`;

  return (
    <S.TaxesContainer $isAllowedLocale={allowedLocale}>
      <S.TaxAccordion>
        <S.TaxLabel>
          <span>{t('cart.taxes')}</span>
          <S.TooltipButtonContainer
            type="button"
            ref={tooltipButtonRef}
            aria-label={t('cart.tax.tooltip.aria.label')}
            onClick={openTaxTooltip}
          >
            <QuestionMarkTooltip aria-hidden />
          </S.TooltipButtonContainer>
          {isTaxTooltipOpen && (
            <S.TaxTooltipBubble ref={tooltipContainerRef} style={styles.popper} {...attributes.popper} role="tooltip">
              <S.TaxTooltipArrow ref={setArrowRef} style={styles.arrow} className="arrow" />
              <span>{isCheckoutPage ? t('cart.tooltip.actual.tax') : t('cart.tooltip.estimated.tax')}</span>
            </S.TaxTooltipBubble>
          )}
        </S.TaxLabel>
        <S.EstimateButton onClick={openTaxAccordion} aria-expanded={isTaxAccordionOpen}>
          {!isCheckoutPage && <span>{t('cart.estimate')}</span>}
          <S.TotalTaxPrice $showTotalTaxPrice={(totalTaxPrice as number) >= 0} data-testid="taxEstimate">
            {!isCheckoutPage ? `(${displayedTax})` : displayedTax}
          </S.TotalTaxPrice>
          {!isCheckoutPage && (isTaxAccordionOpen ? <ArrowUp /> : <ArrowDown />)}
        </S.EstimateButton>
      </S.TaxAccordion>
      {!isCheckoutPage && (
        <S.AccordionContainer $isAccordionOpen={isTaxAccordionOpen}>
          <S.InputContainer onSubmit={submitPostalCode} method="post">
            <S.TaxInput
              aria-label={t('cart.input.placeholder')}
              type={isLocaleUS ? 'number' : 'text'}
              minLength={isLocaleUS ? 5 : 1}
              maxLength={isLocaleUS ? 5 : 7}
              min={1}
              placeholder={t('cart.input.placeholder')}
              onChange={changeZipCode}
              onInput={(e) => {
                if (isLocaleUS) {
                  const inputItem = e.target as HTMLInputElement;
                  inputItem.value = inputItem.value.slice(0, 5);
                }
              }}
              $isInputError={!!zipCodeErrorMessage}
              value={postalCode}
            />
            <S.TaxEstimateButton type="submit" disabled={isPostalLoading}>
              {t('cart.estimate')}
            </S.TaxEstimateButton>
          </S.InputContainer>
          <S.InputErrorMessage>{zipCodeErrorMessage}</S.InputErrorMessage>
        </S.AccordionContainer>
      )}
    </S.TaxesContainer>
  );
}
