import type { Cart } from '../../../../codegen/types';
import { getAmount, localeToCurrencyCode } from '../../../currencyHelper';
import { createLineItem } from '../../utils/createLineItem';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

export const sendViewCartEvent = (miniCart: Cart | Record<string, never>, localeCtx: string) => {
  const { currency, total, lineItems } = miniCart;
  const miniCartExists = Object.keys(miniCart).length >= 1;

  sendDataLayerEvent(
    EventNamesType.view_cart,
    {
      ecommerce: {
        currency: miniCartExists ? currency : localeToCurrencyCode[localeCtx],
        value: +getAmount(total),
        items: miniCartExists
          ? lineItems?.map((item, index) => ({
              ...createLineItem({ ...item, index }),
              item_in_stock: item.isOutOfStock ? 'no' : 'yes',
            }))
          : [],
      },
    },
    true,
    { cartId: miniCart.id },
  );
};
