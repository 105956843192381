import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { getCountryFromLocale } from '@shared/utils/src';
import { CartStorageKeys } from '../utils/cartUtils';
import { getItem } from '../utils/storage';

export const CsaContext = createContext<CsaContextType>({
  isCSA: false,
  isCustomerSession: false,
  setIsCustomerSession: () => {},
  customerEmail: '',
  setCustomerEmail: () => {},
  emailToDelete: '',
  setEmailToDelete: () => {},
  basketId: '',
  setBasketId: () => {},
});

export const useCsaContext = () => useContext(CsaContext);

type CsaContextType = {
  isCSA: boolean;
  isCustomerSession: boolean;
  setIsCustomerSession: Dispatch<SetStateAction<boolean>>;
  customerEmail: string | null;
  setCustomerEmail: Dispatch<SetStateAction<string | null>>;
  emailToDelete: string | null;
  setEmailToDelete: Dispatch<SetStateAction<string | null>>;
  basketId: string | null;
  setBasketId: Dispatch<SetStateAction<string | null>>;
};

type CsaContextProviderProps = {
  isCSA: boolean;
  children: ReactNode;
};

export function CsaContextProvider({ isCSA, children }: CsaContextProviderProps) {
  const [isCustomerSession, setIsCustomerSession] = useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<string | null>(null);
  const [emailToDelete, setEmailToDelete] = useState<string | null>(null);
  const [basketId, setBasketId] = useState<string | null>(null);
  const router = useRouter();
  const anonymousId = getItem(`${CartStorageKeys.CART_ANON_ID}_${getCountryFromLocale(router.locale ?? '')}`);
  const cartIdInStorage = getItem(`${CartStorageKeys.CART_ID}_${getCountryFromLocale(router.locale ?? '')}`);

  const handleFetchCustomerData = useCallback(async () => {
    try {
      const response = await fetch('/api/customerCsaData');
      const { email, isCustomerCsaSession } = await response.json();

      setCustomerEmail(email);
      setIsCustomerSession(isCustomerCsaSession);
      setBasketId(cartIdInStorage);
    } catch (error) {
      setCustomerEmail(null);
      setIsCustomerSession(false);
      setBasketId(null);
    }
  }, [cartIdInStorage]);

  useEffect(() => {
    if (isCSA) {
      if (anonymousId) {
        setCustomerEmail(null);
        setIsCustomerSession(true);
        setBasketId(cartIdInStorage);
      } else {
        (async () => {
          handleFetchCustomerData();
        })();
      }
    }
  }, [anonymousId, isCSA, handleFetchCustomerData, cartIdInStorage]);

  const value = useMemo(
    () => ({
      isCSA,
      isCustomerSession,
      setIsCustomerSession,
      customerEmail,
      setCustomerEmail,
      emailToDelete,
      setEmailToDelete,
      basketId,
      setBasketId,
    }),
    [isCSA, isCustomerSession, customerEmail, emailToDelete, basketId],
  );

  return <CsaContext.Provider value={value}>{children}</CsaContext.Provider>;
}
