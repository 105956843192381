import type { ReactNode } from 'react';
import type { SiteSwitchResponse } from 'codegen/types';
import type { MockedResponse } from '@apollo/client/testing';
import { MockedProvider } from '@apollo/client/testing';
import { CartContextProvider, StaticContextProvider, useLocaleContext } from '../context';
import { appointmentMocks } from './appointmentMockData';
import {
  createCartMock,
  deliveryOptionsMock,
  getCartMock,
  marketingPreferencesCAMock,
  marketingPreferencesMock,
  paymentMethodsMock,
  siteSwitchDataMock,
  siteSwitchMock,
} from './mockData';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mocks: MockedResponse<Record<string, any>>[] = [
  paymentMethodsMock,
  createCartMock,
  getCartMock,
  siteSwitchMock,
  deliveryOptionsMock,
  ...appointmentMocks,
];

export function MockedApolloProvider({ children }: { children: ReactNode }) {
  const { locale } = useLocaleContext();
  return (
    <MockedProvider mocks={mocks} addTypename={false}>
      <StaticContextProvider
        host=""
        paymentMethods={paymentMethodsMock.result.data.paymentData.paymentMethods}
        marketingPreferences={
          locale === 'fr-ca' || locale === 'en-ca' ? marketingPreferencesCAMock : marketingPreferencesMock
        }
        storeData={null}
        switchSitesData={siteSwitchDataMock as unknown as SiteSwitchResponse[] | null}
      >
        <CartContextProvider>{children}</CartContextProvider>
      </StaticContextProvider>
    </MockedProvider>
  );
}
