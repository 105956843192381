import { useCallback, useMemo } from 'react';
import type { RangeConnectorParams, RangeWidgetDescription } from 'instantsearch.js/es/connectors/range/connectRange';
import connectRange from 'instantsearch.js/es/connectors/range/connectRange';
import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import type { UseRefinementListProps } from 'react-instantsearch';
import { useConnector, useRefinementList } from 'react-instantsearch';

// When using the useRefinementList in the component.
// If the component get destroyed or unmounted the refinement list will reset
// The solution is just create custom hook that will be Filter container

interface RatingRefinement extends RefinementListItem {
  label: string;
  value: string;
  count: number;
}

function useRangeSlider(props?: RangeConnectorParams) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useConnector<RangeConnectorParams, RangeWidgetDescription>(connectRange as any, props);
}

export const useAlgoliaFilter = (_locale: string) => {
  const refinementConfig: Partial<UseRefinementListProps> = {
    operator: 'or',
    limit: 6,
    showMore: true,
    showMoreLimit: 100,
  };

  const sizeRefinements = useRefinementList({
    attribute: 'size',
    operator: 'or',
    limit: 100,
  });

  const priceRange = useRangeSlider({
    attribute: 'price.centAmount',
  });

  const fasteningRefinements = useRefinementList({
    attribute: `fastening.${_locale}`,
    ...refinementConfig,
  });

  const genderRefinements = useRefinementList({
    attribute: `gender.${_locale}`,
    ...refinementConfig,
  });
  const productTypeRefinements = useRefinementList({
    attribute: `productType.${_locale}`,
    ...refinementConfig,
  });

  const brandRefinements = useRefinementList({
    attribute: `brand.${_locale}`,
    ...refinementConfig,
  });
  const technologiesRefinements = useRefinementList({
    attribute: `technologies.${_locale}`,
    ...refinementConfig,
  });

  const materialsRefinements = useRefinementList({
    attribute: `upperMaterial.${_locale}`,
    ...refinementConfig,
  });
  const ratingRefinements = useRefinementList({
    attribute: `review.rating.${_locale}`,
    transformItems: useCallback(
      (items: RatingRefinement[]) =>
        items.sort((a, b) => Number(b.value) - Number(a.value)).map((item) => ({ ...item })),
      [],
    ),
    ...refinementConfig,
  });

  const widthRefinements = useRefinementList({
    attribute: `fit.${_locale}`,
    ...refinementConfig,
  });
  const styleRefinements = useRefinementList({
    attribute: `productSubType.${_locale}`,
    ...refinementConfig,
  });

  const colorRefinements = useRefinementList({
    attribute: `color.${_locale}`,
    ...refinementConfig,
  });

  const heelHeightRefinements = useRefinementList({
    attribute: `heelHeight.groups.${_locale}`,
    operator: 'and',
    ...refinementConfig,
  });

  const isFiltersEmpty = useMemo(() => {
    const itemLengths =
      sizeRefinements.items.length +
      colorRefinements.items.length +
      styleRefinements.items.length +
      fasteningRefinements.items.length +
      materialsRefinements.items.length +
      ratingRefinements.items.length +
      widthRefinements.items.length +
      technologiesRefinements.items.length +
      productTypeRefinements.items.length +
      heelHeightRefinements.items.length +
      genderRefinements.items.length +
      brandRefinements.items.length;

    return itemLengths === 0;
  }, [
    sizeRefinements.items.length,
    colorRefinements.items.length,
    styleRefinements.items.length,
    fasteningRefinements.items.length,
    materialsRefinements.items.length,
    ratingRefinements.items.length,
    widthRefinements.items.length,
    technologiesRefinements.items.length,
    productTypeRefinements.items.length,
    heelHeightRefinements.items.length,
    genderRefinements.items.length,
    brandRefinements.items.length,
  ]);
  return {
    sizeRefinements,
    priceRange,
    colorRefinements,
    styleRefinements,
    fasteningRefinements,
    materialsRefinements,
    ratingRefinements,
    widthRefinements,
    technologiesRefinements,
    productTypeRefinements,
    heelHeightRefinements,
    genderRefinements,
    brandRefinements,
    isFiltersEmpty,
  };
};

export default useAlgoliaFilter;

export type UseAlgoliaFilterReturn = ReturnType<typeof useAlgoliaFilter>;
