import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import type { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordSchema } from '@shared/validation/src/storefront/resetPassword';
import { BlueButton } from '../../baseComponents/button';
import { FieldContainer } from '../../baseComponents/containers/FieldContainer';
import { Form } from '../../baseComponents/containers/Form';
import { ErrorMessage } from '../../baseComponents/errors';
import type { FormValues } from '../../baseComponents/inputs/Input';
import { PasswordField } from '../../baseComponents/inputs/Input';
import { useResetPasswordMutation } from '../../graphqlDocument/auth/resetPassword.generated';
import { useIsBadPasswords } from '../../hooks/useBadPassword';
import type { BffError } from '../../services/errors';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { PasswordErrorMessage } from '../forgotPassword/PasswordErrorMessage';
import PasswordStrengthBar from '../registration/PasswordStrengthBar';

const S = {
  Root: styled.div`
    max-width: 328px;
    width: 100%;
    @media ${media.greaterThan('lg')} {
      max-width: 408px;
    }
  `,
  Form: styled(Form)`
    gap: ${spacing.XS};
    margin: 0;

    @media ${media.greaterThan('lg')} {
      margin: 0;
    }
  `,
  Headline: styled.h4`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    @media ${media.greaterThan('lg')} {
      ${TypographyStyles.Headings.H4SemiBold}
    }
  `,
  SubText: styled.p`
    ${TypographyStyles.Body.Small.Regular}
    margin: 0;
    padding: 0;
  `,
  StyledFieldContainer: styled(FieldContainer)`
    margin: 0;
    padding: 0;
  `,
  RepeatPasswordContainer: styled.div`
    border: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  `,
  PasswordHint: styled.p`
    color: ${colours.BLACK};
    display: flex;
    margin: 0;
    width: 298px;
    ${TypographyStyles.Body.Tiny.Regular}
  `,
};

interface LoginFormProps {
  code: string;
}

export function ResetPasswordForm({ code }: LoginFormProps) {
  const { t } = useTranslation('lib-global-common');
  const router = useRouter();
  const [resetPassword, { data, error }] = useResetPasswordMutation();
  const [isCodeExpired, setIsCodeExpired] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { errors: formError, isSubmitting },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema),
  });

  const [newPassword, repeatPassword] = useWatch({
    control,
    name: ['newPassword', 'repeatPassword'],
  });

  const { isBadPassword, checkPassword } = useIsBadPasswords();

  const onPasswordBlur = () => {
    checkPassword(newPassword);
  };

  const onSubmit = async () => {
    const isPasswordBad = await checkPassword(getValues('newPassword'));

    if (newPassword !== repeatPassword || isPasswordBad) return;

    try {
      await resetPassword({
        variables: {
          input: {
            confirmationCode: code,
            password: getValues('newPassword'),
            username: (router.query.email || '').toString(),
          },
        },
      });
    } catch (e) {
      console.error(e);
      const requestError = e as ApolloError;
      const { errorType } = requestError.graphQLErrors[0] as unknown as BffError;
      setIsCodeExpired(errorType === 'ExpiredCodeException');
    }
  };

  if (data) {
    router.push('/reset-password/success');
  }

  useEffect(() => {
    if (isCodeExpired) {
      router.push('/reset-password/expired-link');
    }
  }, [isCodeExpired, router]);

  useEffect(() => {
    if (isBadPassword) {
      setError('newPassword', { message: t('confirm.reset.validation.bad.password') });
    }
  }, [isBadPassword, setError, t]);

  return (
    <S.Root>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Headline>{t('confirm.reset.create.new.password.title')}</S.Headline>
        <S.SubText>{t('confirm.reset.create.new.password.message')}</S.SubText>

        <FieldContainer>
          <PasswordField
            aria-label={t('confirm.reset.new.password')}
            placeholder={`${t('confirm.reset.new.password')}*`}
            aria-describedby="new-password-invalid"
            aria-invalid={!!formError.newPassword || !!formError.repeatPassword}
            isInputError={!!formError.newPassword || !!formError.repeatPassword}
            register={register('newPassword', {
              onBlur: onPasswordBlur,
            })}
            autoComplete="new-password"
            isEmpty={!newPassword}
          />
          <ErrorMessage id="new-password-invalid" {...formError?.newPassword}>
            {t(formError?.newPassword?.message as string)}
          </ErrorMessage>
        </FieldContainer>

        <S.RepeatPasswordContainer>
          <FieldContainer>
            <PasswordField
              aria-label={t('confirm.reset.repeat.password')}
              placeholder={`${t('confirm.reset.repeat.password')}*`}
              aria-describedby="repeat-password-invalid"
              aria-invalid={!!formError.repeatPassword}
              isInputError={!!formError.repeatPassword}
              register={register('repeatPassword', {
                onBlur: onPasswordBlur,
              })}
              autoComplete="new-password"
              isEmpty={!repeatPassword}
            />
            {!!formError.repeatPassword && (
              <ErrorMessage id="repeat-password-invalid">{t(formError?.repeatPassword.message as string)}</ErrorMessage>
            )}
          </FieldContainer>

          <PasswordStrengthBar
            realTimePassword={newPassword}
            isBadPassword={isBadPassword}
            passwordLevelText={[
              t('confirm.reset.weak.password'),
              t('confirm.reset.okay.password'),
              t('confirm.reset.strong.password'),
              t('confirm.reset.very.strong.password'),
            ]}
          />
          <S.PasswordHint>
            {'>'} {t('register.password.minimum')}
          </S.PasswordHint>
          {error && <PasswordErrorMessage error={error} id="reset-password-error" />}
        </S.RepeatPasswordContainer>

        <BlueButton
          data-testid="confirmNewPassword"
          aria-label={t('confirm.reset.my.password')}
          disabled={isSubmitting || isBadPassword || !!formError.repeatPassword}
          type="submit"
        >
          {t('confirm.reset.my.password')}
        </BlueButton>
      </S.Form>
    </S.Root>
  );
}
