import type { Cart } from '../../../../codegen/types';
import type { IndexedLineItem } from '../../../../types/product';
import { getAmount } from '../../../currencyHelper';
import { createLineItem } from '../../utils/createLineItem';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

export const sendRemoveFromCartFromQuantityButtonsEvent = (
  prevCart: Cart | Record<string, never>,
  removedProductId: string,
  userCart: Cart,
) => {
  // as item is remove, use prev cart to find it
  const removedCartItem = prevCart.lineItems?.find((item) => item.id === removedProductId);

  if (!removedCartItem || !userCart) return;

  // update removed product quantity to 1, QuantityButtons can remove only single product
  const removedProduct: IndexedLineItem = {
    ...removedCartItem,
    index: 0,
    quantity: 1,
  };

  const { price } = removedCartItem;
  const { value } = price;

  const cartItems = {
    items: userCart.lineItems?.map((item) => createLineItem(item)),
  };

  sendDataLayerEvent(
    EventNamesType.remove_from_cart,
    {
      ecommerce: {
        currency: value.currencyCode,
        value: +getAmount(value.centAmount),
        items: [createLineItem(removedProduct)],
      },
      cart: {
        currency: userCart.currency,
        value: +getAmount(userCart.total),
        cartItems,
      },
    },
    true,
    { cartId: userCart.id },
  );
};
