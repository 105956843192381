export type StoreAddressType = {
  mallName?: string;
  streetName: string;
  streetNumber?: string;
  postalCode: string;
  town: string;
  country?: string;
  phone?: string;
  region?: string;
  province?: string;
};

export type OpeningHoursType = {
  day: string;
  openingTime: string;
  closingTime: string;
};

export type StoreFeature = {
  name: string;
  isActive: boolean;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export type Department = {
  [key: string]: string;
  url: string;
};

export type StoreType = {
  displayName: string;
  address: StoreAddressType;
  features?: StoreFeature[];
  storeType: string;
  openingHours: OpeningHoursType[];
  appointeddStoreId?: string;
  objectID?: string;
  timezone?: string;
  _geoloc?: LatLng;
  departments?: Department[];
};

export enum StoreDetailType {
  STOCKIST = 'STOCKIST',
}

export enum ExcludedStores {
  CLARKS_HERITAGE_SHOP_STREET = 'Clarks Heritage Shop Street',
}
