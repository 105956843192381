export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: any;
};

export type AddCsaTokenInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId: Scalars['String'];
  csaToken: Scalars['String'];
  locale: Scalars['String'];
  storeKey: Scalars['String'];
};

export type AddDeliveryMethodInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  countryIsoCode2: Scalars['String'];
  deliveryInfo?: InputMaybe<DeliveryInfoInput>;
  deliveryKey: Scalars['String'];
  locale: Scalars['String'];
  postcode: Scalars['String'];
  town?: InputMaybe<Scalars['String']>;
};

export type AddToCartInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  giftCardMessage?: InputMaybe<Scalars['String']>;
  giftCardRecipient?: InputMaybe<Scalars['String']>;
  giftCardRecipientEmail?: InputMaybe<Scalars['String']>;
  giftCardSender?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  quantity: Scalars['Int'];
  sku: Scalars['String'];
  storeKey: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryAlias?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDefaultBilling?: Maybe<Scalars['Boolean']>;
  isDefaultShipping?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressesResponse = {
  __typename?: 'AddressesResponse';
  addresses: Array<Maybe<Address>>;
  defaultBillingAddressId?: Maybe<Scalars['String']>;
  defaultShippingAddressId?: Maybe<Scalars['String']>;
};

export type AdyenAmount = {
  __typename?: 'AdyenAmount';
  currency: Scalars['String'];
  value: Scalars['Int'];
};

export type AgilOneTokenResponse = {
  __typename?: 'AgilOneTokenResponse';
  accessKey: Scalars['String'];
  tenantId: Scalars['Int'];
};

export type AncestorCategory = {
  __typename?: 'AncestorCategory';
  key: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type ApplyDiscountInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId: Scalars['String'];
  discountCode: Scalars['String'];
  locale: Scalars['String'];
  storeKey: Scalars['String'];
};

export type AppointmentBooking = {
  __typename?: 'AppointmentBooking';
  duration?: Maybe<Scalars['Int']>;
  end: Scalars['String'];
  id: Scalars['ID'];
  spaces?: Maybe<Scalars['Int']>;
  start: Scalars['String'];
  store: Scalars['ID'];
};

export type AppointmentBookingDetails = {
  __typename?: 'AppointmentBookingDetails';
  duration?: Maybe<Scalars['Int']>;
  end: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  spaces?: Maybe<Scalars['Int']>;
  start: Scalars['String'];
  store: Scalars['ID'];
};

export type AppointmentBookingKey = {
  __typename?: 'AppointmentBookingKey';
  id: Scalars['ID'];
  start: Scalars['String'];
};

export type AppointmentBookingKeyInput = {
  id: Scalars['ID'];
  start: Scalars['String'];
};

export type AppointmentBookings = {
  __typename?: 'AppointmentBookings';
  exclusiveStartKey?: Maybe<AppointmentBookingKey>;
  items: Array<Maybe<AppointmentBooking>>;
};

export type AppointmentReservation = {
  __typename?: 'AppointmentReservation';
  duration?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  start?: Maybe<Scalars['String']>;
  store: Scalars['ID'];
};

export type Appointments = {
  __typename?: 'Appointments';
  slots: Array<Maybe<TimeSlot>>;
};

export type AppointmentsInput = {
  date: Scalars['String'];
  spaces?: InputMaybe<Scalars['Int']>;
  store: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type AuthResponse = {
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Badge = {
  __typename?: 'Badge';
  altText?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type BaseProduct = {
  badges?: Maybe<Array<Maybe<ProductBadge>>>;
  badgesV2?: Maybe<ProductBadgeV2>;
  brand?: Maybe<Scalars['String']>;
  categoriesPath?: Maybe<Array<PathCategory>>;
  commercetoolsType: CommercetoolsProductType;
  description?: Maybe<Scalars['String']>;
  finalSale?: Maybe<FinalSaleAttributes>;
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  key: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primaryCategory?: Maybe<PrimaryCategory>;
  references?: Maybe<Array<ProductReference>>;
  shippingAndReturns?: Maybe<ShippingAndReturns>;
  slug?: Maybe<Scalars['String']>;
  slugAllLocales?: Maybe<Array<ProductLocalizedString>>;
  subType: Scalars['String'];
  type: Scalars['String'];
};

export type BookAppointment = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobile: Scalars['String'];
  notes: Scalars['String'];
  reservation: Scalars['ID'];
  spaces?: InputMaybe<Scalars['Int']>;
  store: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type BookingCancellation = {
  __typename?: 'BookingCancellation';
  booking: Scalars['ID'];
  store: Scalars['ID'];
};

export type BookingInput = {
  id: Scalars['ID'];
  store?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type BookingsInput = {
  exclusiveStartKey?: InputMaybe<AppointmentBookingKeyInput>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type CancelBooking = {
  booking: Scalars['ID'];
  email?: InputMaybe<Scalars['ID']>;
  source?: InputMaybe<Scalars['String']>;
  store: Scalars['ID'];
};

export type CancelReservation = {
  reservation: Scalars['ID'];
  store: Scalars['ID'];
};

export type CardDetailsInput = {
  first6: Scalars['String'];
  last4: Scalars['String'];
};

export type CarouselProduct = {
  __typename?: 'CarouselProduct';
  badges?: Maybe<Array<Maybe<ProductBadge>>>;
  badgesV2?: Maybe<ProductBadgeV2>;
  brand: Scalars['String'];
  categoryName: Scalars['String'];
  colors?: Maybe<Array<Maybe<StyleProduct>>>;
  follow?: Maybe<Scalars['Boolean']>;
  imageUrls: Array<Scalars['String']>;
  index?: Maybe<Scalars['Boolean']>;
  priceRanges?: Maybe<CentPrecisionPriceRanges>;
  prices: Array<Price>;
  primaryCategory?: Maybe<PrimaryCategory>;
  productId: Scalars['String'];
  productKey: Scalars['String'];
  productName: Scalars['String'];
  rangeGroupDescription?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  slug: Scalars['String'];
};

export type CarouselProductsInput = {
  locale: Scalars['String'];
  productKeys: Array<Scalars['ID']>;
};

export type CarouselProductsResponse = {
  __typename?: 'CarouselProductsResponse';
  products: Array<CarouselProduct>;
};

export type Cart = {
  __typename?: 'Cart';
  active?: Maybe<Scalars['Boolean']>;
  anonymousId?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<CartAddress>;
  createdAt: Scalars['String'];
  currency: Scalars['String'];
  customerEmail?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  deliveries?: Maybe<Array<Delivery>>;
  deliveryAddress?: Maybe<CartAddress>;
  deliveryInstructions?: Maybe<DeliveryInstructions>;
  deliveryMethod?: Maybe<DeliveryMethod>;
  deliveryTotal: Scalars['Float'];
  discountCodes?: Maybe<Array<Maybe<DiscountCode>>>;
  discountTotal: Scalars['Float'];
  errors: Array<CartError>;
  guid: Scalars['String'];
  hasFreeDelivery: Scalars['Boolean'];
  /** @deprecated to be removed in next release */
  hasGiftCard: Scalars['Boolean'];
  /** @deprecated to be removed in next release */
  hasPhysicalGiftCard: Scalars['Boolean'];
  id: Scalars['String'];
  idMeDiscount?: Maybe<Scalars['Int']>;
  isDigitalCart: Scalars['Boolean'];
  lastModifiedAt: Scalars['String'];
  lineItems: Array<LineItem>;
  payments?: Maybe<Array<Maybe<Payment>>>;
  shippingType?: Maybe<Scalars['String']>;
  subtotal: Scalars['Float'];
  taxes?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  version: Scalars['Int'];
};

export type CartAddress = {
  __typename?: 'CartAddress';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  countryAlias?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type CartError = DiscountCodeError | LineItemError;

export enum CartErrorType {
  DISCOUNT_CODE_ERROR = 'discount_code_error',
  LINE_ITEM_ERROR = 'line_item_error',
}

export type CartVariant = {
  __typename?: 'CartVariant';
  id?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
};

export type Category = {
  __typename?: 'Category';
  ancestors?: Maybe<Array<AncestorCategory>>;
  badges?: Maybe<Array<Maybe<Badge>>>;
  description?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  follow?: Maybe<Scalars['Boolean']>;
  h1Text?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  key: Scalars['String'];
  linkedCategories?: Maybe<Array<LinkedCategory>>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  siblingCategories?: Maybe<Array<CategorySibling>>;
  slug?: Maybe<Scalars['String']>;
  slugAllLocales?: Maybe<Array<CategoryLocalizedString>>;
};

export type CategoryInput = {
  key: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
};

export type CategoryLocalizedString = {
  __typename?: 'CategoryLocalizedString';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type CategorySibling = {
  __typename?: 'CategorySibling';
  key: Scalars['String'];
  slug?: Maybe<Array<CategoryLocalizedString>>;
};

export type CentPrecisionMoney = {
  __typename?: 'CentPrecisionMoney';
  centAmount: Scalars['Int'];
  currencyCode: Scalars['String'];
  fractionDigits: Scalars['Int'];
  minPrice?: Scalars['Int'];
  maxPrice?: Scalars['Int'];
  type: Scalars['String'];
};

export type CentPrecisionPriceRanges = {
  __typename?: 'CentPrecisionPriceRanges';
  currencyCode: Scalars['String'];
  fractionDigits: Scalars['Int'];
  now: PriceRange;
  then?: Maybe<PriceRange>;
  type: Scalars['String'];
  was?: Maybe<PriceRange>;
};

export enum Channel {
  FULLPRICE = 'fullPrice',
  OUTLET = 'outlet',
}

export type ClearGuestCheckoutDataInput = {
  anonymousId: Scalars['String'];
  cartId: Scalars['String'];
  locale: Scalars['String'];
};

export type ClearGuestDataInput = {
  anonymousId: Scalars['String'];
  cartId: Scalars['String'];
  locale: Scalars['String'];
};

export type ClearProductCacheInput = {
  cacheTag: Scalars['String'];
};

export type ClearRegisteredUserDataInput = {
  cartId: Scalars['String'];
  customerId: Scalars['String'];
  locale: Scalars['String'];
};

export enum CommercetoolsProductType {
  ACCESSORY = 'accessory',
  EGIFTCARD = 'egiftCard',
  FOOTGAUGE = 'footGauge',
  GIFTCARD = 'giftCard',
  SHOE = 'shoe',
  SHOECARE = 'shoeCare',
}

export type ConfirmEmailChangeInput = {
  code: Scalars['String'];
  currentEmail: Scalars['String'];
  password: Scalars['String'];
};

export type ConfirmEmailChangeResponse = {
  __typename?: 'ConfirmEmailChangeResponse';
  errorNewEmailAlreadyInUse: Scalars['Boolean'];
  errorNoEmailChangeRequestFound: Scalars['Boolean'];
  userTokens?: Maybe<CustomerTokens>;
};

export type ConfirmUserResponse = AuthResponse & {
  __typename?: 'ConfirmUserResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CreateAdyenSessionInput = {
  locale: Scalars['String'];
};

export type CreateCartInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  locale: Scalars['String'];
  storeKey: Scalars['String'];
};

export type CreateFreeOrderInput = {
  cartId: Scalars['String'];
};

export type CreateGuestUserInput = {
  anonymousId: Scalars['String'];
  cartId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateGuestUserResponse = {
  __typename?: 'CreateGuestUserResponse';
  customerId: Scalars['String'];
  token: Scalars['String'];
};

export type CreatePaymentInput = {
  cartId: Scalars['String'];
  centAmount: Scalars['Int'];
  currencyCode: Scalars['String'];
  locale: Scalars['String'];
};

export type CreatePaymentResponse = {
  __typename?: 'CreatePaymentResponse';
  id: Scalars['String'];
  paymentMethods: Array<PaymentMethods>;
  session: Session;
};

export type Customer = {
  __typename?: 'Customer';
  addresses?: Maybe<Array<Address>>;
  areCustomerCardDetailsSaved?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Scalars['String']>;
  countryAlias?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  defaultBillingAddressId?: Maybe<Scalars['String']>;
  defaultShippingAddressId?: Maybe<Scalars['String']>;
  defaultStoreId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName: Scalars['String'];
  newEmail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registeredFrom?: Maybe<Scalars['String']>;
  savedStoresIds?: Maybe<Array<Scalars['String']>>;
  shoeSize?: Maybe<Scalars['String']>;
  shoeWidth?: Maybe<Scalars['String']>;
  signupRegion?: Maybe<Scalars['String']>;
};

export type CustomerDetails = {
  __typename?: 'CustomerDetails';
  ctCustomerId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CustomerPreference = {
  key: Scalars['String'];
  optIn: Scalars['Boolean'];
};

export type CustomerTokens = {
  __typename?: 'CustomerTokens';
  accessToken: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type DeleteCustomerInput = {
  email?: InputMaybe<Scalars['String']>;
};

export type DeleteCustomerResponse = {
  __typename?: 'DeleteCustomerResponse';
  customerEmail: Scalars['String'];
  customerId: Scalars['String'];
};

export type Delivery = {
  __typename?: 'Delivery';
  deliveryDate: Scalars['String'];
  deliveryName?: Maybe<Scalars['String']>;
  deliveryStoreId?: Maybe<Scalars['String']>;
  fulfillmentNodeType?: Maybe<Scalars['String']>;
  lineItems: Array<LineItemIdentifier>;
  serviceLevelIdentifier?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
};

export type DeliveryAddress = {
  __typename?: 'DeliveryAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type DeliveryInfoInput = {
  deliveryStoreId?: InputMaybe<Scalars['String']>;
  serviceLevelIdentifier?: InputMaybe<Scalars['String']>;
  shippingOfferToken?: InputMaybe<Scalars['String']>;
  storeName?: InputMaybe<Scalars['String']>;
};

export type DeliveryInstructions = {
  __typename?: 'DeliveryInstructions';
  message?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DeliveryMethod = {
  __typename?: 'DeliveryMethod';
  deliveryTime?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  shippingType?: Maybe<Scalars['String']>;
};

export type DeliveryMethodInfo = {
  __typename?: 'DeliveryMethodInfo';
  description: Scalars['String'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type DeliveryMethodsResponse = {
  __typename?: 'DeliveryMethodsResponse';
  currency: Scalars['String'];
  /** @deprecated Use deliveryPriority instead */
  deliveryOrder?: Maybe<Scalars['Int']>;
  deliveryPriority?: Maybe<Scalars['Int']>;
  deliveryTime?: Maybe<Scalars['String']>;
  deliveryWindow?: Maybe<DeliveryWindow>;
  key: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  providerRateIdentifier?: Maybe<Scalars['String']>;
  serviceClass?: Maybe<Scalars['String']>;
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  currency: Scalars['String'];
  /** @deprecated Use deliveryPriority instead */
  deliveryOrder?: Maybe<Scalars['Int']>;
  deliveryPriority: Scalars['Int'];
  deliveryTime: Scalars['String'];
  description: Scalars['String'];
  isFreeEligible?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  shippingType?: Maybe<Scalars['String']>;
};

export enum DeliveryOptionsScope {
  BASKET = 'basket',
  PDP = 'pdp',
}

export type DeliveryWindow = {
  __typename?: 'DeliveryWindow';
  endsAt: Scalars['String'];
  startsAt: Scalars['String'];
};

export enum DeviceType {
  GAUGE = 'gauge',
  TAPE = 'tape',
}

export type DiscountCode = {
  __typename?: 'DiscountCode';
  discountCode: Scalars['String'];
  discountId: Scalars['String'];
  discountName?: Maybe<Scalars['String']>;
  discountValue?: Maybe<Scalars['Int']>;
};

export type DiscountCodeError = {
  __typename?: 'DiscountCodeError';
  discountCode: Scalars['String'];
  errorCode: DiscountCodeErrorCode;
  type?: Maybe<CartErrorType>;
  validFrom?: Maybe<Scalars['AWSDateTime']>;
  validUntil?: Maybe<Scalars['AWSDateTime']>;
};

export enum DiscountCodeErrorCode {
  APPLICATION_STOPPED_BY_PREVIOUS_DISCOUNT = 'application_stopped_by_previous_discount',
  MAX_APPLICATION_REACHED = 'max_application_reached',
  NOT_ACTIVE = 'not_active',
  NOT_VALID = 'not_valid',
}

export type DiscountCodeInfo = {
  __typename?: 'DiscountCodeInfo';
  discountCode: Scalars['String'];
  discountId: Scalars['String'];
  discountName?: Maybe<Scalars['String']>;
  discountValue?: Maybe<Scalars['Float']>;
};

export type DiscountDescription = {
  __typename?: 'DiscountDescription';
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  type?: Maybe<DiscountType>;
};

export enum DiscountType {
  CART = 'CART',
  PRODUCT = 'PRODUCT',
}

export type FinalSaleAttributes = {
  __typename?: 'FinalSaleAttributes';
  backgroundColor?: Maybe<Scalars['String']>;
  emailText?: Maybe<Scalars['String']>;
  isFinalSale?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export enum FinalSaleValues {
  NO = 'NO',
  YES = 'YES',
}

export type FindOrderInput = {
  locale: Scalars['String'];
  orderNumber: Scalars['String'];
  postCode: Scalars['String'];
};

export type ForgotPasswordInput = {
  channel: Scalars['String'];
  locale: Scalars['String'];
  username: Scalars['String'];
};

export type ForgotPasswordResponse = AuthResponse & {
  __typename?: 'ForgotPasswordResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type FreezeCartInput = {
  cartGUID: Scalars['String'];
  locale: Scalars['String'];
};

export type GetAddressesInput = {
  storeKey: Scalars['String'];
};

export type GetCMSPageInput = {
  urlSlug: Scalars['String'];
};

export type GetCMSPageResponse = {
  __typename?: 'GetCMSPageResponse';
  cacheTTL: Scalars['Int'];
  hrefLangs?: Maybe<Array<Maybe<HrefLang>>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  pageId?: Maybe<Scalars['String']>;
};

export type GetCartInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  recalculate?: InputMaybe<Scalars['Boolean']>;
  storeKey: Scalars['String'];
};

export type GetCartStatusInput = {
  cartId: Scalars['String'];
};

export type GetCartStatusResponse = {
  __typename?: 'GetCartStatusResponse';
  isPaid: Scalars['Boolean'];
};

export type GetCollectionDeliveryMethodsInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  countryIsoCode2: Scalars['String'];
  locale: Scalars['String'];
  locationId?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  town?: InputMaybe<Scalars['String']>;
};

export type GetCustomerMarketingPreferencesInput = {
  email: Scalars['String'];
  locale: Scalars['String'];
};

export type GetCustomerServiceUrlInput = {
  locale: Scalars['String'];
};

export type GetDeliveryMethodsInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  countryIsoCode2: Scalars['String'];
  locale: Scalars['String'];
  postcode: Scalars['String'];
  town?: InputMaybe<Scalars['String']>;
};

export type GetDeliveryOptionsForProductsInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  destinationCountry?: InputMaybe<Scalars['String']>;
  inclusive?: InputMaybe<Scalars['Boolean']>;
  locale: Scalars['String'];
  scope?: InputMaybe<DeliveryOptionsScope>;
  skus: Array<Scalars['String']>;
  storeKey: Scalars['String'];
};

export type GetDeliveryOptionsForProductsResponse = {
  __typename?: 'GetDeliveryOptionsForProductsResponse';
  deliveryOptions: Array<Maybe<DeliveryOption>>;
};

export type GetFeatureFlagStatusResponse = {
  __typename?: 'GetFeatureFlagStatusResponse';
  enabled: Scalars['Boolean'];
};

export type GetFeatureFlagsResponse = {
  __typename?: 'GetFeatureFlagsResponse';
  authRecaptcha: GetFeatureFlagStatusResponse;
  btsAppointments: GetFeatureFlagStatusResponse;
  btsProductPriceRange: GetFeatureFlagStatusResponse;
  klarnaPdp: GetFeatureFlagStatusResponse;
  orderRiskInquiry: GetFeatureFlagStatusResponse;
  productStockCheck: GetFeatureFlagStatusResponse;
  productTrueFit: GetFeatureFlagStatusResponse;
};

export type GetInStorePickupInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId: Scalars['String'];
  latitude: Scalars['Float'];
  limit?: InputMaybe<Scalars['Int']>;
  locale: Scalars['String'];
  longitude: Scalars['Float'];
  radius?: InputMaybe<Scalars['Int']>;
  storeKey: Scalars['String'];
};

export type GetInStorePickupResponse = {
  __typename?: 'GetInStorePickupResponse';
  options: Array<InStorePickupOption>;
};

export type GetOrderInput = {
  locale: Scalars['String'];
  orderNumber: Scalars['String'];
};

export type GetPudoDeliveryMethodsInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  countryIsoCode2: Scalars['String'];
  locale: Scalars['String'];
  locationId?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  town?: InputMaybe<Scalars['String']>;
};

export type GetStoreInput = {
  storeKey: Scalars['String'];
};

export type GiftCardBalanceInput = {
  cardNumber: Scalars['String'];
  locale: Scalars['String'];
};

export type GiftCardBalanceResponse = {
  __typename?: 'GiftCardBalanceResponse';
  currency: Scalars['String'];
  value: Scalars['Int'];
};

export type GiftCardProduct = BaseProduct & {
  __typename?: 'GiftCardProduct';
  badges?: Maybe<Array<Maybe<ProductBadge>>>;
  badgesV2?: Maybe<ProductBadgeV2>;
  brand?: Maybe<Scalars['String']>;
  categoriesPath?: Maybe<Array<PathCategory>>;
  commercetoolsType: CommercetoolsProductType;
  description?: Maybe<Scalars['String']>;
  finalSale?: Maybe<FinalSaleAttributes>;
  giftCardVariants: Array<GiftCardVariant>;
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  key: Scalars['String'];
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primaryCategory?: Maybe<PrimaryCategory>;
  references?: Maybe<Array<ProductReference>>;
  shippingAndReturns?: Maybe<ShippingAndReturns>;
  slug?: Maybe<Scalars['String']>;
  slugAllLocales?: Maybe<Array<ProductLocalizedString>>;
  subType: Scalars['String'];
  type: Scalars['String'];
};

export type GiftCardVariant = {
  __typename?: 'GiftCardVariant';
  badges?: Maybe<Array<Maybe<ProductBadge>>>;
  badgesV2?: Maybe<ProductBadgeV2>;
  imageUrls: Array<Scalars['String']>;
  occasion?: Maybe<Scalars['String']>;
  price: Array<Price>;
  priceDenomination?: Maybe<Scalars['String']>;
  sku: Scalars['ID'];
};

export type HrefLang = {
  __typename?: 'HrefLang';
  locale: Scalars['String'];
  slug: Scalars['String'];
};

export type InStorePickupInput = {
  latitude: Scalars['Float'];
  locale: Scalars['String'];
  longitude: Scalars['Float'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  searchRadius?: InputMaybe<Scalars['Int']>;
  sku: Scalars['String'];
};

export type InStorePickupOption = {
  __typename?: 'InStorePickupOption';
  deliveryTime?: Maybe<Scalars['String']>;
  hasStock: Scalars['Boolean'];
  shippingOfferToken: Scalars['String'];
  storeId: Scalars['String'];
};

export type InStorePickupResponse = {
  __typename?: 'InStorePickupResponse';
  isNextPage: Scalars['Boolean'];
  stores?: Maybe<Array<Option>>;
};

export type InputTimeSlot = {
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
};

export type ItemAtp = {
  __typename?: 'ItemAtp';
  atp: Scalars['Int'];
  lineItem: LineItem;
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LineItem = {
  __typename?: 'LineItem';
  availableQuantity?: Maybe<Scalars['Int']>;
  backInStockNotificationsEnabled: Scalars['Boolean'];
  badges: Array<LineItemBadge>;
  brand?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  discounts: Array<Scalars['String']>;
  discountsDescriptions: Array<DiscountDescription>;
  finalSale?: Maybe<FinalSaleAttributes>;
  fit?: Maybe<Scalars['String']>;
  fitLabel?: Maybe<Scalars['String']>;
  giftCardMessage?: Maybe<Scalars['String']>;
  giftCardOccasion?: Maybe<Scalars['String']>;
  giftCardRecipient?: Maybe<Scalars['String']>;
  giftCardRecipientEmail?: Maybe<Scalars['String']>;
  giftCardSender?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  imageURLs: Array<Scalars['String']>;
  isLastItem?: Maybe<Scalars['Boolean']>;
  isOutOfStock?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price: LineItemPrice;
  primaryCategory?: Maybe<PrimaryCategoryObject>;
  productId: Scalars['String'];
  productKey?: Maybe<Scalars['String']>;
  productSlug?: Maybe<Scalars['String']>;
  productTypeKey?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  size?: Maybe<Scalars['String']>;
  taxRate?: Maybe<TaxRate>;
  taxedPrice?: Maybe<TaxedPrice>;
  totalPrice: PriceInfo;
  variant: CartVariant;
};

export type LineItemBadge = {
  __typename?: 'LineItemBadge';
  altText?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LineItemError = {
  __typename?: 'LineItemError';
  lineItemId: Scalars['String'];
  type?: Maybe<CartErrorType>;
};

export type LineItemIdentifier = {
  __typename?: 'LineItemIdentifier';
  id: Scalars['String'];
  quantity: Scalars['Int'];
};

export type LineItemPrice = {
  __typename?: 'LineItemPrice';
  /** @deprecated Use discountedPrice instead */
  discountedPercentage?: Maybe<Scalars['Float']>;
  discountedPrice?: Maybe<PriceInfo>;
  thenPrice?: Maybe<PriceInfo>;
  thenPriceValidFrom?: Maybe<Scalars['String']>;
  thenPriceValidUntil?: Maybe<Scalars['String']>;
  totalDiscountedPrice?: Maybe<PriceInfo>;
  value: PriceInfo;
  wasPrice?: Maybe<PriceInfo>;
  wasPriceValidFrom?: Maybe<Scalars['String']>;
  wasPriceValidUntil?: Maybe<Scalars['String']>;
};

export type LinkedCategory = {
  __typename?: 'LinkedCategory';
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export enum MarketingChannel {
  FULLPRICE = 'fullPrice',
  OUTLET = 'outlet',
}

export type MarketingPreference = {
  key: Scalars['String'];
  optIn: Scalars['Boolean'];
};

export type MarketingPreferenceResponse = {
  __typename?: 'MarketingPreferenceResponse';
  key: Scalars['String'];
  optIn: Scalars['Boolean'];
};

export type MarketingPreferences = {
  __typename?: 'MarketingPreferences';
  key: Scalars['String'];
  label: Scalars['String'];
};

export type MarketingPreferencesResponse = {
  __typename?: 'MarketingPreferencesResponse';
  languages: Array<Scalars['String']>;
  marketingPreferenceDefaultEnabled: Scalars['Boolean'];
  marketingPreferences: Array<MarketingPreferences>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCsaToken: Cart;
  addDeliveryMethod: Cart;
  addSavedStore: Scalars['Boolean'];
  addToCart: Cart;
  applyDiscount: Cart;
  bookAppointment: AppointmentBookingDetails;
  cancelBooking: BookingCancellation;
  cancelEmailChangeRequest: Scalars['Boolean'];
  cancelReservation: ReservationCancellation;
  clearGuestCheckoutData: Cart;
  clearGuestData: Cart;
  clearProductCache: ProductCacheResult;
  clearRegisteredUserData: Cart;
  confirmEmailChange: ConfirmEmailChangeResponse;
  confirmUser: ConfirmUserResponse;
  createAdyenSession: Session;
  createCart: Cart;
  createFreeOrder: Cart;
  createGuestUser: CreateGuestUserResponse;
  createPayment: CreatePaymentResponse;
  deleteCustomer: DeleteCustomerResponse;
  exchangeCodeToTokens: UserTokens;
  forgotPassword: ForgotPasswordResponse;
  freezeCart: Cart;
  generateSocialProviderLink: Scalars['String'];
  notifyBackInStock: NotifyBackInStock;
  optDown: Scalars['Boolean'];
  optOut: Scalars['Boolean'];
  placeOrder: PlaceOrderResult;
  removeCustomerAddress?: Maybe<Array<Maybe<Address>>>;
  removeDiscount: Cart;
  removeItem: Cart;
  removeSavedStore: Scalars['Boolean'];
  resendConfirmationCode: ResendConfirmationCodeResponse;
  resendEmailChangeRequestEmail: Scalars['Boolean'];
  reserveAppointment: AppointmentReservation;
  resetPassword: ResetPasswordResponse;
  saveCustomerAddress?: Maybe<Address>;
  saveGuestEmail: Cart;
  setDefaultBillingAddress: AddressesResponse;
  setDefaultSavedStore: Scalars['Boolean'];
  setDefaultShippingAddress: AddressesResponse;
  signIn: SignInResponse;
  signInAsCustomer: SignInAsCustomerResponse;
  signUp: SignUpResponse;
  updateCartBillingAddress?: Maybe<Cart>;
  updateCartPostalAddress?: Maybe<Cart>;
  updateCartShippingAddress?: Maybe<Cart>;
  updateCustomer: UpdateCustomerResponse;
  updateCustomerAfterSignUp: UpdateCustomerAfterSignUpResponse;
  updateCustomerMarketingPreferences: Scalars['Boolean'];
  updateDeliveryInstructions: Cart;
  updateItemQuantity: Cart;
  validateAndUpdateCartItemsStock: ValidateAndUpdateCartItemsStockResponse;
};

export type MutationAddCsaTokenArgs = {
  input: AddCsaTokenInput;
};

export type MutationAddDeliveryMethodArgs = {
  input: AddDeliveryMethodInput;
};

export type MutationAddSavedStoreArgs = {
  storeId: Scalars['String'];
};

export type MutationAddToCartArgs = {
  input: AddToCartInput;
};

export type MutationApplyDiscountArgs = {
  input: ApplyDiscountInput;
};

export type MutationBookAppointmentArgs = {
  input: BookAppointment;
};

export type MutationCancelBookingArgs = {
  input: CancelBooking;
};

export type MutationCancelReservationArgs = {
  input: CancelReservation;
};

export type MutationClearGuestCheckoutDataArgs = {
  input: ClearGuestCheckoutDataInput;
};

export type MutationClearGuestDataArgs = {
  input: ClearGuestDataInput;
};

export type MutationClearProductCacheArgs = {
  input: ClearProductCacheInput;
};

export type MutationClearRegisteredUserDataArgs = {
  input: ClearRegisteredUserDataInput;
};

export type MutationConfirmEmailChangeArgs = {
  input: ConfirmEmailChangeInput;
};

export type MutationConfirmUserArgs = {
  confirmationCode: Scalars['String'];
  username: Scalars['String'];
};

export type MutationCreateAdyenSessionArgs = {
  input: CreateAdyenSessionInput;
};

export type MutationCreateCartArgs = {
  input: CreateCartInput;
};

export type MutationCreateFreeOrderArgs = {
  input: CreateFreeOrderInput;
};

export type MutationCreateGuestUserArgs = {
  input: CreateGuestUserInput;
};

export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};

export type MutationDeleteCustomerArgs = {
  input?: InputMaybe<DeleteCustomerInput>;
};

export type MutationExchangeCodeToTokensArgs = {
  channel: Channel;
  code: Scalars['String'];
  csa?: InputMaybe<Scalars['Boolean']>;
};

export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type MutationFreezeCartArgs = {
  input: FreezeCartInput;
};

export type MutationGenerateSocialProviderLinkArgs = {
  channel: Channel;
  provider: SocialProvider;
  state?: InputMaybe<Array<Pair>>;
};

export type MutationNotifyBackInStockArgs = {
  input: NotifyBackInStockInput;
};

export type MutationOptDownArgs = {
  input: OptDownMarketingPreferencesInput;
};

export type MutationOptOutArgs = {
  input: OptOutMarketingPreferencesInput;
};

export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};

export type MutationRemoveCustomerAddressArgs = {
  input: RemoveCustomerAddressInput;
};

export type MutationRemoveDiscountArgs = {
  input: RemoveDiscountInput;
};

export type MutationRemoveItemArgs = {
  input: RemoveItemInput;
};

export type MutationRemoveSavedStoreArgs = {
  storeId: Scalars['String'];
};

export type MutationResendConfirmationCodeArgs = {
  input: ResendConfirmationCodeInput;
};

export type MutationReserveAppointmentArgs = {
  input: ReserveAppointment;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSaveCustomerAddressArgs = {
  input: UpdateCustomerAddressInput;
};

export type MutationSaveGuestEmailArgs = {
  input: SaveGuestEmailInput;
};

export type MutationSetDefaultBillingAddressArgs = {
  addressKey: Scalars['String'];
  storeKey?: InputMaybe<Scalars['String']>;
};

export type MutationSetDefaultSavedStoreArgs = {
  storeId: Scalars['String'];
};

export type MutationSetDefaultShippingAddressArgs = {
  addressKey: Scalars['String'];
  storeKey?: InputMaybe<Scalars['String']>;
};

export type MutationSignInArgs = {
  input: SignInInput;
};

export type MutationSignInAsCustomerArgs = {
  input: SignInAsCustomerInput;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationUpdateCartBillingAddressArgs = {
  input: UpdateCartAddressInput;
};

export type MutationUpdateCartPostalAddressArgs = {
  input: UpdateCartPostalAddressInput;
};

export type MutationUpdateCartShippingAddressArgs = {
  input: UpdateCartAddressInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateCustomerAfterSignUpArgs = {
  email: Scalars['String'];
  input: PostSignUpData;
};

export type MutationUpdateCustomerMarketingPreferencesArgs = {
  input: UpdateCustomerMarketingPreferencesInput;
};

export type MutationUpdateDeliveryInstructionsArgs = {
  input: UpdateDeliveryInstructionsInput;
};

export type MutationUpdateItemQuantityArgs = {
  input: UpdateItemQuantityInput;
};

export type MutationValidateAndUpdateCartItemsStockArgs = {
  input: ValidateAndUpdateCartItemsStockInput;
};

export type NotifyBackInStock = {
  __typename?: 'NotifyBackInStock';
  email: Scalars['String'];
  sku: Scalars['ID'];
  status: NotifyBackInStockStatus;
};

export type NotifyBackInStockInput = {
  email: Scalars['String'];
  locale: Scalars['String'];
  sku: Scalars['ID'];
};

export enum NotifyBackInStockStatus {
  CREATED = 'CREATED',
  PROCESSED = 'PROCESSED',
}

export type OptDownMarketingPreferencesInput = {
  channel: MarketingChannel;
  emailAddress: Scalars['String'];
  locale: Scalars['String'];
};

export type OptOutMarketingPreferencesInput = {
  channel: MarketingChannel;
  emailAddress: Scalars['String'];
  locale: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  hasStock: Scalars['Boolean'];
  storeId: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  currency?: Maybe<Scalars['String']>;
  orderNumber: Scalars['String'];
  orderPlacedDate: Scalars['String'];
  returnLink?: Maybe<Scalars['String']>;
  shipments?: Maybe<Array<Maybe<Shipment>>>;
  summary?: Maybe<OrderSummary>;
};

export type OrderHistoryInput = {
  count: Scalars['Int'];
  locale: Scalars['String'];
  offset: Scalars['Int'];
};

export type OrderSummary = {
  __typename?: 'OrderSummary';
  deliveryAddress?: Maybe<DeliveryAddress>;
  deliveryMethod?: Maybe<DeliveryMethodInfo>;
  deliveryTotal: Scalars['Float'];
  discountTotal: Scalars['Float'];
  discounts?: Maybe<Array<Maybe<DiscountCodeInfo>>>;
  subtotal: Scalars['Float'];
  taxes?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
};

export type OrdersHistoryResponse = {
  __typename?: 'OrdersHistoryResponse';
  orders?: Maybe<Array<Maybe<Order>>>;
  pagination_info: PaginationInfo;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  count: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type Pair = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type PathCategory = {
  __typename?: 'PathCategory';
  key: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<PriceInfo>;
  paymentMethod?: Maybe<Scalars['String']>;
};

export type PaymentDetailsResponse = {
  __typename?: 'PaymentDetailsResponse';
  amount?: Maybe<AdyenAmount>;
  cartId: Scalars['String'];
  pspReference: Scalars['String'];
  resultCode: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  key: Scalars['String'];
  label: Scalars['String'];
};

export type PaymentMethodConfiguration = {
  __typename?: 'PaymentMethodConfiguration';
  gatewayMerchantId?: Maybe<Scalars['String']>;
  merchantId?: Maybe<Scalars['String']>;
};

export type PaymentMethods = {
  __typename?: 'PaymentMethods';
  brands?: Maybe<Array<Scalars['String']>>;
  configuration?: Maybe<PaymentMethodConfiguration>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type PaymentMethodsResponse = {
  __typename?: 'PaymentMethodsResponse';
  paymentMethods: Array<PaymentMethod>;
};

export type PlaceOrderInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cardDetails?: InputMaybe<CardDetailsInput>;
  cartId: Scalars['String'];
  sessionId: Scalars['String'];
  storeKey: Scalars['String'];
};

export type PlaceOrderResult = {
  __typename?: 'PlaceOrderResult';
  accepted: Scalars['Boolean'];
  cartId: Scalars['String'];
};

export type PostSignUpData = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  channel: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  signupRegion: Scalars['String'];
};

export type Price = {
  __typename?: 'Price';
  actualPrice: CentPrecisionMoney;
  fromDate?: Maybe<Scalars['String']>;
  lastOfferedAtDate?: Maybe<Scalars['String']>;
  thenPrice?: Maybe<CentPrecisionMoney>;
  toDate?: Maybe<Scalars['String']>;
  wasPrice?: Maybe<CentPrecisionMoney>;
};

export type PriceInfo = {
  __typename?: 'PriceInfo';
  centAmount: Scalars['Float'];
  currencyCode: Scalars['String'];
  fractionDigits: Scalars['Float'];
  type: Scalars['String'];
};

export type PriceRange = {
  __typename?: 'PriceRange';
  maxPrice: Scalars['Int'];
  minPrice: Scalars['Int'];
};

export type PrimaryCategory = {
  __typename?: 'PrimaryCategory';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PrimaryCategoryObject = {
  __typename?: 'PrimaryCategoryObject';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Product = BaseProduct & {
  __typename?: 'Product';
  badges?: Maybe<Array<Maybe<ProductBadge>>>;
  badgesV2?: Maybe<ProductBadgeV2>;
  brand?: Maybe<Scalars['String']>;
  categoriesPath?: Maybe<Array<PathCategory>>;
  color?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<StyleProduct>>;
  commercetoolsType: CommercetoolsProductType;
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['String']>>;
  finalSale?: Maybe<FinalSaleAttributes>;
  follow?: Maybe<Scalars['Boolean']>;
  gender?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  imageUrls: Array<Scalars['String']>;
  index?: Maybe<Scalars['Boolean']>;
  isPrivate: Scalars['Boolean'];
  key: Scalars['String'];
  materials?: Maybe<Array<ProductMaterial>>;
  mediumColor?: Maybe<Scalars['String']>;
  merchandiseCategory?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  priceRanges?: Maybe<CentPrecisionPriceRanges>;
  primaryCategory?: Maybe<PrimaryCategory>;
  rangeGroupDescription?: Maybe<Scalars['String']>;
  references?: Maybe<Array<ProductReference>>;
  shippingAndReturns?: Maybe<ShippingAndReturns>;
  slug?: Maybe<Scalars['String']>;
  slugAllLocales?: Maybe<Array<ProductLocalizedString>>;
  styleName?: Maybe<Scalars['String']>;
  subType: Scalars['String'];
  technologies?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
  variants: Array<Variant>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type ProductBadge = {
  __typename?: 'ProductBadge';
  altText?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductBadgeImage = {
  __typename?: 'ProductBadgeImage';
  alt?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductBadgeTextual = {
  __typename?: 'ProductBadgeTextual';
  backgroundColor?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductBadgeV2 = {
  __typename?: 'ProductBadgeV2';
  cacheTTL?: Maybe<Scalars['Int']>;
  image?: Maybe<ProductBadgeImage>;
  textual?: Maybe<ProductBadgeTextual>;
};

export type ProductCacheResult = {
  __typename?: 'ProductCacheResult';
  cacheTag?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ProductInput = {
  key: Scalars['String'];
  locale: Scalars['String'];
};

export type ProductLocalizedString = {
  __typename?: 'ProductLocalizedString';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type ProductMaterial = {
  __typename?: 'ProductMaterial';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ProductReference = {
  __typename?: 'ProductReference';
  targetProduct?: Maybe<TargetProduct>;
  type: ProductReferenceType;
};

export enum ProductReferenceType {
  EGIFTCARD = 'eGiftCard',
  GIFTCARD = 'giftCard',
  LARGERSIZES = 'largerSizes',
  SMALLERSIZES = 'smallerSizes',
}

export type ProductStockCheckInput = {
  latitude: Scalars['Float'];
  locale: Scalars['String'];
  longitude: Scalars['Float'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  searchRadius?: InputMaybe<Scalars['Int']>;
  sku: Scalars['String'];
};

export type ProductStockCheckResponse = {
  __typename?: 'ProductStockCheckResponse';
  isNextPage: Scalars['Boolean'];
  stores?: Maybe<Array<ProductStoreStock>>;
};

export type ProductStoreStock = {
  __typename?: 'ProductStoreStock';
  hasStock: Scalars['Boolean'];
  storeId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  agilOneToken: AgilOneTokenResponse;
  appointments: Appointments;
  booking: AppointmentBookingDetails;
  bookings: AppointmentBookings;
  buildinfo: Scalars['String'];
  carouselProducts: CarouselProductsResponse;
  category?: Maybe<Category>;
  findOrder?: Maybe<Order>;
  getBillingAddresses: Array<Maybe<Address>>;
  getCMSPage?: Maybe<GetCMSPageResponse>;
  getCart?: Maybe<Cart>;
  getCartStatus: GetCartStatusResponse;
  getCollectionDeliveryMethods: Array<DeliveryMethodsResponse>;
  getCustomer: Customer;
  getCustomerMarketingPreferences?: Maybe<Array<MarketingPreferenceResponse>>;
  getCustomerServiceUrl: Scalars['String'];
  getDeliveryMethods: Array<Maybe<DeliveryMethodsResponse>>;
  getDeliveryOptionsForProducts: GetDeliveryOptionsForProductsResponse;
  getEmailFromToken: Scalars['String'];
  getFeatureFlagStatus: GetFeatureFlagStatusResponse;
  getFeatureFlags: GetFeatureFlagsResponse;
  getFits: Array<KeyValuePair>;
  getInStorePickup: GetInStorePickupResponse;
  getOrder: Cart;
  getOrderedCart: Cart;
  getOrdersHistory: OrdersHistoryResponse;
  getPudoDeliveryMethods: Array<Maybe<DeliveryMethodsResponse>>;
  getShippingAddresses: Array<Maybe<Address>>;
  getSizes: Array<KeyValuePair>;
  getStore?: Maybe<Store>;
  /** @deprecated use productStockCheck instead */
  inStorePickup: InStorePickupResponse;
  marketingPreferences: MarketingPreferencesResponse;
  paymentData: PaymentMethodsResponse;
  paymentDetails: PaymentDetailsResponse;
  product?: Maybe<SearchResult>;
  productStockCheck: ProductStockCheckResponse;
  redirectInfo?: Maybe<RedirectInformation>;
  searchCustomers: Array<Maybe<CustomerDetails>>;
  siteSwitch: Array<SiteSwitchResponse>;
  sizeCalculator: SizeWithCategory;
  validateCart?: Maybe<ValidateCartResponse>;
  validateCartItemsStock: ValidateCartItemsStockResponse;
};

export type QueryAgilOneTokenArgs = {
  locale: Scalars['String'];
  refresh?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAppointmentsArgs = {
  input: AppointmentsInput;
};

export type QueryBookingArgs = {
  input: BookingInput;
};

export type QueryBookingsArgs = {
  input: BookingsInput;
};

export type QueryCarouselProductsArgs = {
  input: CarouselProductsInput;
};

export type QueryCategoryArgs = {
  input: CategoryInput;
};

export type QueryFindOrderArgs = {
  input: FindOrderInput;
};

export type QueryGetBillingAddressesArgs = {
  input: GetAddressesInput;
};

export type QueryGetCMSPageArgs = {
  input: GetCMSPageInput;
};

export type QueryGetCartArgs = {
  input: GetCartInput;
};

export type QueryGetCartStatusArgs = {
  input: GetCartStatusInput;
};

export type QueryGetCollectionDeliveryMethodsArgs = {
  input: GetCollectionDeliveryMethodsInput;
};

export type QueryGetCustomerMarketingPreferencesArgs = {
  input: GetCustomerMarketingPreferencesInput;
};

export type QueryGetCustomerServiceUrlArgs = {
  input: GetCustomerServiceUrlInput;
};

export type QueryGetDeliveryMethodsArgs = {
  input: GetDeliveryMethodsInput;
};

export type QueryGetDeliveryOptionsForProductsArgs = {
  input: GetDeliveryOptionsForProductsInput;
};

export type QueryGetEmailFromTokenArgs = {
  token: Scalars['String'];
};

export type QueryGetFeatureFlagStatusArgs = {
  featureFlag: Scalars['String'];
};

export type QueryGetFeatureFlagsArgs = {
  locale: Scalars['String'];
};

export type QueryGetFitsArgs = {
  locale: Scalars['String'];
};

export type QueryGetInStorePickupArgs = {
  input: GetInStorePickupInput;
};

export type QueryGetOrderArgs = {
  input: GetOrderInput;
};

export type QueryGetOrderedCartArgs = {
  input: GetOrderInput;
};

export type QueryGetOrdersHistoryArgs = {
  input: OrderHistoryInput;
};

export type QueryGetPudoDeliveryMethodsArgs = {
  input: GetPudoDeliveryMethodsInput;
};

export type QueryGetShippingAddressesArgs = {
  input: GetAddressesInput;
};

export type QueryGetSizesArgs = {
  locale: Scalars['String'];
};

export type QueryGetStoreArgs = {
  input: GetStoreInput;
};

export type QueryInStorePickupArgs = {
  input: InStorePickupInput;
};

export type QueryMarketingPreferencesArgs = {
  locale: Scalars['String'];
};

export type QueryPaymentDataArgs = {
  locale: Scalars['String'];
};

export type QueryPaymentDetailsArgs = {
  redirectResult: Scalars['String'];
};

export type QueryProductArgs = {
  input: ProductInput;
};

export type QueryProductStockCheckArgs = {
  input: ProductStockCheckInput;
};

export type QueryRedirectInfoArgs = {
  country: Scalars['String'];
  currentLocale?: InputMaybe<Scalars['String']>;
  currentSite?: InputMaybe<Scalars['String']>;
};

export type QuerySearchCustomersArgs = {
  input: SearchCustomersInput;
};

export type QuerySizeCalculatorArgs = {
  input: SizeCalculatorInput;
};

export type QueryValidateCartArgs = {
  input: ValidateCartInput;
};

export type QueryValidateCartItemsStockArgs = {
  input: ValidateCartItemsStockInput;
};

export type RedirectInformation = {
  __typename?: 'RedirectInformation';
  hint?: Maybe<Scalars['String']>;
  type: RedirectType;
  url: Scalars['String'];
};

export enum RedirectType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export type RemoveCustomerAddressInput = {
  addressId: Scalars['String'];
  storeKey?: InputMaybe<Scalars['String']>;
};

export type RemoveDiscountInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId: Scalars['String'];
  discountId: Scalars['String'];
  locale: Scalars['String'];
  storeKey: Scalars['String'];
};

export type RemoveItemInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId: Scalars['String'];
  lineItemId: Scalars['String'];
  locale: Scalars['String'];
  storeKey: Scalars['String'];
};

export type ResendConfirmationCodeInput = {
  username: Scalars['String'];
};

export type ResendConfirmationCodeResponse = AuthResponse & {
  __typename?: 'ResendConfirmationCodeResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ReservationCancellation = {
  __typename?: 'ReservationCancellation';
  reservation: Scalars['ID'];
  store: Scalars['ID'];
};

export type ReserveAppointment = {
  date: Scalars['String'];
  slot: InputTimeSlot;
  spaces?: InputMaybe<Scalars['Int']>;
  store: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  confirmationCode: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type ResetPasswordResponse = AuthResponse & {
  __typename?: 'ResetPasswordResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SaveGuestEmailInput = {
  anonymousId: Scalars['String'];
  cartId: Scalars['String'];
  email: Scalars['String'];
  locale: Scalars['String'];
};

export type SearchCustomersInput = {
  email: Scalars['String'];
};

export type SearchResult = GiftCardProduct | Product;

export type Session = {
  __typename?: 'Session';
  amount?: Maybe<AdyenAmount>;
  channel?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  merchantAccount?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  returnUrl: Scalars['String'];
  sessionData: Scalars['String'];
  shopperLocale?: Maybe<Scalars['String']>;
};

export type Shipment = {
  __typename?: 'Shipment';
  deliveryExpectedDate?: Maybe<Scalars['String']>;
  productImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  statusText: Scalars['String'];
  trackings?: Maybe<Array<Maybe<Tracking>>>;
};

export type ShippingAndReturns = {
  __typename?: 'ShippingAndReturns';
  image?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type SignInAsCustomerInput = {
  ctCustomerId: Scalars['String'];
};

export type SignInAsCustomerResponse = {
  __typename?: 'SignInAsCustomerResponse';
  isGuest: Scalars['Boolean'];
  token: Scalars['String'];
};

export type SignInInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  basketHasBeenMerged?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  userAttributes: UserAttributes;
  userPoolAttributes: UserPoolAttributes;
  userTokens: UserTokens;
};

export type SignUpInput = {
  channel: Channel;
  email: Scalars['String'];
  existingOrderId?: InputMaybe<Scalars['String']>;
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  locale: Scalars['String'];
  password: Scalars['String'];
  preferences: Array<CustomerPreference>;
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  userSub?: Maybe<Scalars['String']>;
};

export type SiteSwitchResponse = {
  __typename?: 'SiteSwitchResponse';
  countryName: Array<StoreLocalizedString>;
  currency: Scalars['String'];
  currencySymbol: Scalars['String'];
  key: Scalars['String'];
  languages: Array<Scalars['String']>;
};

export type SizeCalculatorInput = {
  device: DeviceType;
  length: Scalars['Float'];
  locale: Scalars['String'];
  width: Scalars['Float'];
};

export type SizeWithCategory = {
  __typename?: 'SizeWithCategory';
  ageGroup: Scalars['String'];
  fit: Scalars['String'];
  globalFit: Scalars['String'];
  globalSize: Scalars['String'];
  size: Scalars['String'];
};

export enum SocialProvider {
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
  SIGNINWITHAPPLE = 'SignInWithApple',
}

export type Stock = {
  __typename?: 'Stock';
  availableQuantity: Scalars['Int'];
  status: StockStatus;
};

export enum StockStatus {
  INSTOCK = 'inStock',
  OUTOFSTOCK = 'outOfStock',
}

export type Store = {
  __typename?: 'Store';
  backInStockNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  basketValueCap?: Maybe<Scalars['Float']>;
  basketValueCapAtPeak?: Maybe<Scalars['Float']>;
  basketValueCapAtPeakFrom?: Maybe<Scalars['String']>;
  basketValueCapAtPeakUntil?: Maybe<Scalars['String']>;
  billingCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  collectionShippingEnabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  key: Scalars['String'];
  productQuantityCap?: Maybe<Scalars['Int']>;
  pudoEnabled?: Maybe<Scalars['Boolean']>;
  shippingCountries?: Maybe<Array<Maybe<Scalars['String']>>>;
  skuCap?: Maybe<Scalars['Int']>;
};

export type StoreLocalizedString = {
  __typename?: 'StoreLocalizedString';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type StyleProduct = {
  __typename?: 'StyleProduct';
  color?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  key: Scalars['ID'];
};

export type TargetProduct = {
  __typename?: 'TargetProduct';
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type TaxRate = {
  __typename?: 'TaxRate';
  amount: Scalars['Float'];
};

export type TaxedPrice = {
  __typename?: 'TaxedPrice';
  totalGross: PriceInfo;
  totalNet: PriceInfo;
  totalTax?: Maybe<PriceInfo>;
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
};

export type Tracking = {
  __typename?: 'Tracking';
  carrier?: Maybe<Scalars['String']>;
  carrierService?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<TrackingItem>>>;
  shippedAt?: Maybe<Scalars['String']>;
  trackingCode?: Maybe<Scalars['String']>;
  trackingUrl?: Maybe<Scalars['String']>;
};

export type TrackingItem = {
  __typename?: 'TrackingItem';
  color?: Maybe<Scalars['String']>;
  finalSale?: Maybe<FinalSaleAttributes>;
  giftCardMessage?: Maybe<Scalars['String']>;
  giftCardRecipient?: Maybe<Scalars['String']>;
  giftCardRecipientEmail?: Maybe<Scalars['String']>;
  giftCardSender?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price: Scalars['String'];
  quantity: Scalars['Int'];
  size?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type UpdateCartAddressInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  countryAlias?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  storeKey: Scalars['String'];
};

export type UpdateCartPostalAddressInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  locale: Scalars['String'];
  postalCode: Scalars['String'];
  storeKey: Scalars['String'];
};

export type UpdateCustomerAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  countryAlias?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  storeKey: Scalars['String'];
};

export type UpdateCustomerAfterSignUpResponse = {
  __typename?: 'UpdateCustomerAfterSignUpResponse';
  basketHasBeenMerged?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  result: Scalars['Boolean'];
};

export type UpdateCustomerInput = {
  countryAlias?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shoeSize?: InputMaybe<Scalars['String']>;
  shoeWidth?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerMarketingPreferencesInput = {
  channel: MarketingChannel;
  email: Scalars['String'];
  locale: Scalars['String'];
  preferences: Array<MarketingPreference>;
};

export type UpdateCustomerResponse = {
  __typename?: 'UpdateCustomerResponse';
  customer: Customer;
  errorNewEmailAlreadyInUse: Scalars['Boolean'];
  errorOnRessetingPassword: Scalars['Boolean'];
};

export type UpdateDeliveryInstructionsInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId: Scalars['String'];
  locale: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  storeKey: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateItemQuantityInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId: Scalars['String'];
  lineItemId: Scalars['String'];
  locale: Scalars['String'];
  quantity: Scalars['Int'];
  storeKey: Scalars['String'];
};

export type UserAttributes = {
  __typename?: 'UserAttributes';
  ct_customer_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_verified: Scalars['String'];
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  migrated?: Maybe<Scalars['Boolean']>;
  sub: Scalars['String'];
};

export type UserPoolAttributes = {
  __typename?: 'UserPoolAttributes';
  clientId: Scalars['String'];
  userPoolId: Scalars['String'];
};

export type UserTokens = {
  __typename?: 'UserTokens';
  accessToken: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type ValidateAndUpdateCartItemsStockInput = {
  anonymousId?: InputMaybe<Scalars['String']>;
  cartId?: InputMaybe<Scalars['String']>;
  locale: Scalars['String'];
};

export type ValidateAndUpdateCartItemsStockResponse = {
  __typename?: 'ValidateAndUpdateCartItemsStockResponse';
  cart: Cart;
  outOfStockLineItemsIds: Array<Scalars['ID']>;
  quantityAdjustedLineItemsIds: Array<Scalars['ID']>;
};

export type ValidateCartInput = {
  cartId: Scalars['String'];
  storeKey: Scalars['String'];
};

export type ValidateCartItemsStockInput = {
  cartId: Scalars['String'];
  locale: Scalars['String'];
};

export type ValidateCartItemsStockResponse = {
  __typename?: 'ValidateCartItemsStockResponse';
  itemAtps: Array<ItemAtp>;
  success: Scalars['Boolean'];
};

export type ValidateCartResponse = {
  __typename?: 'ValidateCartResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type Variant = {
  __typename?: 'Variant';
  backInStockNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  badges?: Maybe<Array<Maybe<ProductBadge>>>;
  badgesV2?: Maybe<ProductBadgeV2>;
  fit?: Maybe<Scalars['String']>;
  fitLabel?: Maybe<Scalars['String']>;
  globalFit?: Maybe<Scalars['String']>;
  globalSize?: Maybe<Scalars['String']>;
  price: Array<Price>;
  size?: Maybe<Scalars['String']>;
  sku: Scalars['ID'];
  stock: Stock;
};
