import type { ReactNode } from 'react';
import { Root } from '@radix-ui/react-dialog';
import type { DialogProps } from '@radix-ui/react-dialog';

type DialogRootProps = DialogProps & {
  children: ReactNode;
};

export function DialogRoot({ children, ...rest }: DialogRootProps) {
  return <Root {...rest}>{children}</Root>;
}
