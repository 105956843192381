import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { useTranslation } from 'next-i18next';
import { colorNameToHex } from '../../../stylings';
import { Color, ColorLabel, Metallic, Multi } from './ColorFilter.styles';
import FilterAccordion from './FilterAccordion';
import type { FilterCheckboxProps } from './FilterCheckbox';
import { FilterCheckbox } from './FilterCheckbox';

function renderColor(color: string) {
  switch (color) {
    case 'multi':
      return <Multi />;
    case 'métallique':
    case 'metallic':
      return <Metallic />;
    default:
      return <Color $color={colorNameToHex[color]} />;
  }
}

function renderColorLabel({ label, value }: RefinementListItem) {
  return (
    <>
      {renderColor(value.toLowerCase())}
      <ColorLabel>{label}</ColorLabel>
    </>
  );
}

export function ColorFilter(props: FilterCheckboxProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { items } = props;

  if (!items.length) return null;
  const isOpen = !!items.find((item) => item.isRefined);

  return (
    <FilterAccordion
      isOpen={isOpen}
      triggerLabel={t('filter.menu.filter.colour')}
      content={<FilterCheckbox {...props} labelComponent={renderColorLabel} />}
    />
  );
}

export default ColorFilter;
