import type { Maybe, Price } from '../../../../codegen/types';
import { getAmount } from '../../../currencyHelper';
import { setItem } from '../../../storage';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type ProductDataType = {
  itemListName?: string;
  itemListId?: string;
  primaryCategoryKey?: Maybe<string>;
  primaryCategoryName?: Maybe<string>;
  key: string;
  name: string;
  brand: string;
  color: string;
};

export function sendSelectItemEvent(productData: ProductDataType, prices: Price[]) {
  const [{ actualPrice, wasPrice, thenPrice }] = prices;
  const priceAmount = +getAmount(actualPrice?.centAmount);
  const wasPriceAmount = +getAmount(wasPrice?.centAmount);
  const thenPriceAmount = +getAmount(thenPrice?.centAmount);
  const discountAmount = wasPriceAmount && priceAmount ? wasPriceAmount - priceAmount : 0;
  setItem(
    'listingPage',
    { itemListId: productData.itemListId, itemListName: productData.itemListName },
    true,
    'sessionStorage',
  );
  sendDataLayerEvent(EventNamesType.select_item, {
    ecommerce: {
      item_list_id: productData.itemListId,
      item_list_name: productData.itemListName,
      items: [
        {
          index: 0,
          quantity: 1,
          item_id: productData.key,
          item_name: [productData.name, productData.color].filter(Boolean).join(' '),
          discount: +discountAmount.toFixed(2),
          item_brand: productData.brand,
          item_category: productData.primaryCategoryName,
          item_category_id: productData.primaryCategoryKey,
          item_list_id: productData.itemListId,
          item_list_name: productData.itemListName,
          item_variant: productData.color,
          price: priceAmount,
          was_price: thenPriceAmount,
          then_price: +discountAmount.toFixed(2),
        },
      ],
    },
  });
}
