import { CreateCartDocument } from '../graphqlDocument/cart/createCart.generated';
import { GetCartDocument } from '../graphqlDocument/cart/getCart.generated';
import { GetDeliveryOptionsForProductsDocument } from '../graphqlDocument/deliveryMethods/getDeliveryOptionsForProducts.generated';
import { GetPaymentMethodsDocument } from '../graphqlDocument/payments/paymentMethods.generated';
import { GetSwitchSitesDocument } from '../graphqlDocument/siteSwitcher/getSwitchSites.generated';

export const paymentMethodsMock = {
  request: { query: GetPaymentMethodsDocument },
  result: {
    data: {
      paymentData: {
        paymentMethods: [
          {
            label: 'Amex',
            key: 'amex',
          },
          {
            label: 'Gift card',
            key: 'giftCard',
          },
          {
            label: 'Google pay',
            key: 'gPay',
          },
          {
            label: 'Klarna',
            key: 'klarna',
          },
          {
            label: 'Master card',
            key: 'masterCard',
          },
          {
            label: 'PayPal',
            key: 'payPal',
          },
          {
            label: 'Visa',
            key: 'visa',
          },
          {
            label: 'Apple Pay',
            key: 'applePay',
          },
        ],
      },
    },
  },
};

const cartResult = {
  key: '8000015769',
  id: '91fa2c1c-d0d4-4db1-809a-b15e74b2a41b',
  version: 1,
  anonymousId: '2592fe7c-ce63-4da3-bc7f-e2dc54b5def0',
  customerId: null,
  lineItems: [
    {
      id: '693c7201-0441-4e12-ab4a-bb69f7457e06',
      totalPrice: {
        centAmount: 0,
        currencyCode: 'GBP',
        fractionDigits: 2,
      },
      taxedPrice: null,
      taxRate: null,
      variant: {
        id: 1,
        sku: '261350087120',
      },
      productId: '60f65cdd-878e-446d-ad4d-5d7daa37670a',
      quantity: 2,
      productKey: '26135008',
      name: 'Nova Elite Blue',
      imageURLs: [
        'https://cdn.media.amplience.net/i/clarks/26169496_W_1',
        'https://cdn.media.amplience.net/i/clarks/26169496_W_2',
        'https://cdn.media.amplience.net/i/clarks/26169496_W_3',
        'https://cdn.media.amplience.net/i/clarks/26169496_W_4',
        'https://cdn.media.amplience.net/i/clarks/26169496_W_5',
        'https://cdn.media.amplience.net/i/clarks/26169496_W_6',
        'https://cdn.media.amplience.net/i/clarks/26169496_W_7',
      ],
      size: '12',
      colour: 'Brown MC',
      fit: 'G',
      price: {
        value: {
          centAmount: 5495,
          currencyCode: 'GBP',
          fractionDigits: 2,
        },
        wasPrice: {
          centAmount: 7777,
          currencyCode: 'GBP',
          fractionDigits: 2,
        },
        thenPrice: {
          centAmount: 6006,
          currencyCode: 'GBP',
          fractionDigits: 2,
        },
        wasPriceValidFrom: null,
        wasPriceValidUntil: null,
        thenPriceValidFrom: null,
        thenPriceValidUntil: null,
      },
    },
    {
      id: 'c4a5c4c0-7352-4e1d-acc5-30f2d87a4902',
      totalPrice: {
        centAmount: 0,
        currencyCode: 'GBP',
        fractionDigits: 2,
      },
      taxedPrice: null,
      taxRate: null,
      variant: {
        id: 2,
        sku: '261087750002',
      },
      productId: 'b6dd40cd-5494-4622-87d4-31f6ac0003f5',
      quantity: 2,
      productKey: '261087750000',
      name: 'Suede Protector black',
      imageURLs: [
        'https://cdn.media.amplience.net/i/clarks/26165137_W_6',
        'https://cdn.media.amplience.net/s/clarks/26135083_set',
        'https://cdn.media.amplience.net/s/clarks/26158153_set',
      ],
      size: '39',
      colour: 'none',
      fit: null,
      price: {
        value: {
          centAmount: 1200,
          currencyCode: 'GBP',
          fractionDigits: 2,
        },
        wasPrice: null,
        thenPrice: null,
        wasPriceValidFrom: null,
        wasPriceValidUntil: null,
        thenPriceValidFrom: null,
        thenPriceValidUntil: null,
      },
    },
    {
      id: '3556a3d0-6613-48fc-a08a-81644280cd39',
      totalPrice: {
        centAmount: 0,
        currencyCode: 'GBP',
        fractionDigits: 2,
      },
      taxedPrice: null,
      taxRate: null,
      variant: {
        id: 3,
        sku: '261353806040',
      },
      productId: '019abcb3-598e-4913-bed0-ed9793b0c910',
      quantity: 2,
      productKey: '26135380',
      name: 'Nova Elite Blue GB',
      imageURLs: [
        'https://cdn.media.amplience.net/i/clarks/26166136_W_5',
        'https://cdn.media.amplience.net/i/clarks/26166133_W_1',
        'https://81a2857f142b28218f03-265c7f9ad9bff1e0e0bd4067ee68abed.ssl.cf3.rackcdn.com/image3-emoWU2Pg.jpeg',
      ],
      size: '4',
      colour: 'Blue',
      fit: 'Medium',
      price: {
        value: {
          centAmount: 8200,
          currencyCode: 'GBP',
          fractionDigits: 2,
        },
        wasPrice: {
          centAmount: 9800,
          currencyCode: 'GBP',
          fractionDigits: 2,
        },
        thenPrice: {
          centAmount: 8700,
          currencyCode: 'GBP',
          fractionDigits: 2,
        },
        wasPriceValidFrom: null,
        wasPriceValidUntil: null,
        thenPriceValidFrom: null,
        thenPriceValidUntil: null,
      },
    },
  ],
};

export const createCartMock = {
  request: {
    query: CreateCartDocument,
    variables: {
      input: {
        currency: '',
        locale: 'en',
        storeKey: '',
      },
    },
  },
  result: {
    data: {
      createCart: cartResult,
    },
  },
};

export const getCartMock = {
  request: {
    query: GetCartDocument,
    variables: {
      input: {
        anonymousId: '2592fe7c-ce63-4da3-bc7f-e2dc54b5def0',
        cartId: '91fa2c1c-d0d4-4db1-809a-b15e74b2a41b',
        storeKey: '',
        locale: 'en',
      },
    },
  },
  result: {
    data: {
      getCart: cartResult,
    },
  },
};

export const siteSwitchMock = {
  request: { query: GetSwitchSitesDocument },
  result: {
    data: {
      siteSwitch: [
        {
          key: 'us',
          languages: ['en-US'],
          currency: 'USD',
          currencySymbol: '$',
          countryName: [
            {
              locale: 'en',
              value: 'USA',
            },
            {
              locale: 'fr-CA',
              value: 'États-Unis',
            },
          ],
        },
        {
          key: 'uk',
          languages: ['en-GB'],
          currency: 'GBP',
          currencySymbol: '£',
          countryName: [
            {
              locale: 'en',
              value: 'United Kingdom',
            },
            {
              locale: 'fr-CA',
              value: 'Royaume-Uni',
            },
          ],
        },
        {
          key: 'roi',
          languages: ['en-IE'],
          currency: 'EUR',
          currencySymbol: '€',
          countryName: [
            {
              locale: 'en',
              value: 'Ireland',
            },
            {
              locale: 'fr-CA',
              value: 'Irlande',
            },
          ],
        },
        {
          key: 'ca',
          languages: ['en-CA', 'fr-CA'],
          currency: 'CAD',
          currencySymbol: '$',
          countryName: [
            {
              locale: 'en',
              value: 'Canada',
            },
            {
              locale: 'fr-CA',
              value: 'Canada',
            },
          ],
        },
      ],
    },
  },
};

export const deliveryOptionsMock = {
  request: {
    query: GetDeliveryOptionsForProductsDocument,
    variables: {
      locale: 'en-GB',
      skus: [
        '267713806050',
        '260713856051',
        '260713856052',
        '260713856053',
        '260713856054',
        '260713856055',
        '260713856056',
      ],
    },
  },
  result: {
    data: {
      getDeliveryOptionsForProducts: {
        deliveryOptions: [
          {
            currency: 'GBP',
            deliveryPriority: 11,
            deliveryTime: '3 - 5 business days delivery',
            description: 'Standard delivery',
            key: 'STANDARD_GBP',
            name: 'Standard delivery',
            price: 600,
            shippingType: 'home',
          },
          {
            currency: 'GBP',
            deliveryPriority: 15,
            deliveryTime: 'Pick in a location in the next day',
            description: 'Nexday Pick in Location',
            key: 'NEXTDAY_PUDO_GBP',
            name: 'Nexday Pick in Location',
            price: 600,
            shippingType: 'pudo',
          },
          {
            currency: 'GBP',
            deliveryPriority: 7,
            deliveryTime: '2 - 5 business days delivery',
            description: 'Express delivery',
            key: 'EXPRESS_GBP',
            name: 'Express delivery',
            price: 600,
            shippingType: 'home',
          },
          {
            currency: 'GBP',
            deliveryPriority: 3,
            deliveryTime: 'Next bussiness day',
            description: 'Nextday delivery',
            key: 'NEXTDAY_GBP',
            name: 'Nextday delivery',
            price: 600,
            shippingType: 'home',
          },
          {
            currency: 'GBP',
            deliveryPriority: 16,
            deliveryTime: 'Pick in a location in 3 to 5 days',
            description: 'Standard 3 to 5 days Pick in Location',
            key: 'STANDARD_PUDO_GBP',
            name: 'Standard 3 to 5 days Pick in Location',
            price: 600,
            shippingType: 'pudo',
          },
          {
            currency: 'GBP',
            deliveryPriority: 17,
            deliveryTime: '1 - 5 business days',
            deliveryType: 'collection',
            description: 'Pick In Store',
            key: 'COLLECT_FROM_STORE_GBP',
            name: 'Pick In Store',
            price: 0,
            shippingType: 'in_store_pick_up',
          },
        ],
      },
    },
  },
};

export const marketingPreferencesMock = {
  languages: [],
  marketingPreferenceDefaultEnabled: false,
  marketingPreferences: [
    {
      key: 'kids',
      label: 'Kids',
    },
    {
      key: 'originals',
      label: 'Originals',
    },
    {
      key: 'womens',
      label: 'Womens',
    },
  ],
  languages: ['en-gb'],
};

export const marketingPreferencesCAMock = {
  marketingPreferenceDefaultEnabled: false,
  marketingPreferences: [
    {
      key: 'kids',
      label: 'Kids',
    },
    {
      key: 'originals',
      label: 'Originals',
    },
    {
      key: 'womens',
      label: 'Womens',
    },
  ],
  languages: ['en-ca', 'fr-ca'],
};

export const generateTimeSlots = (startHour: number, endHour: number, intervalMinutes: number) =>
  Array.from({ length: (endHour - startHour) * (60 / intervalMinutes) }, (_, i) => ({
    hours: Math.floor((i * intervalMinutes) / 60) + startHour,
    minutes: (i * intervalMinutes) % 60,
  }));

export const siteSwitchDataMock = [
  {
    __typename: 'SiteSwitchResponse',
    key: 'uk',
    languages: ['en-GB'],
    currency: 'GBP',
    currencySymbol: '£',
    countryName: [
      {
        __typename: 'StoreLocalizedString',
        locale: 'en',
        value: 'United Kingdom',
      },
      {
        __typename: 'StoreLocalizedString',
        locale: 'fr-CA',
        value: 'Royaume-Uni',
      },
    ],
  },
  {
    __typename: 'SiteSwitchResponse',
    key: 'ca',
    languages: ['en-CA', 'fr-CA'],
    currency: 'CAD',
    currencySymbol: '$',
    countryName: [
      {
        __typename: 'StoreLocalizedString',
        locale: 'en',
        value: 'Canada',
      },
      {
        __typename: 'StoreLocalizedString',
        locale: 'fr-CA',
        value: 'Canada',
      },
    ],
  },
  {
    __typename: 'SiteSwitchResponse',
    key: 'us',
    languages: ['en-US'],
    currency: 'USD',
    currencySymbol: '$',
    countryName: [
      {
        __typename: 'StoreLocalizedString',
        locale: 'en',
        value: 'USA',
      },
      {
        __typename: 'StoreLocalizedString',
        locale: 'fr-CA',
        value: 'États-Unis',
      },
    ],
  },
  {
    __typename: 'SiteSwitchResponse',
    key: 'roi',
    languages: ['en-IE'],
    currency: 'EUR',
    currencySymbol: '€',
    countryName: [
      {
        __typename: 'StoreLocalizedString',
        locale: 'en',
        value: 'Ireland',
      },
      {
        __typename: 'StoreLocalizedString',
        locale: 'fr-CA',
        value: 'Irlande',
      },
    ],
  },
];
