import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { LineItem } from '../../../codegen/types';
import { useCartContext } from '../../../context/cartContext';
import { useLocaleContext } from '../../../context/localeContext';
import { useOverlayContext } from '../../../context/overlayContext';
import { CancelCircle, Delete } from '../../../icons';
import { TypographyStyles, colours, hoverUpDeleteIcon, spacing } from '../../../stylings';
import { Locale } from '../../../utils';
import { ProductLineItemType } from './ProductLineItemImages';
import type { LineItemStatus } from './ProductLineItemList';

const S = {
  Container: styled.div`
    align-items: center;
    border-bottom: 1px solid ${colours.LIGHT_GREY_2};
    border-top: 1px solid ${colours.LIGHT_GREY_2};
    display: flex;
    gap: 10px;
    max-width: 740px;
    padding: ${spacing.XXXS} 0;
  `,
  TextContainer: styled.div<{ $type: ProductLineItemType }>`
    align-items: ${({ $type }) => ($type === ProductLineItemType.MULTI_IMAGE ? 'center' : 'flex-start')};
    display: flex;
    flex-direction: ${({ $type }) => ($type === ProductLineItemType.MULTI_IMAGE ? 'row' : 'column')};
    flex-grow: 1;
    gap: ${({ $type }) => ($type === ProductLineItemType.MULTI_IMAGE ? spacing.XS : '0px')};
  `,
  Name: styled.a`
    ${TypographyStyles.Label.Large.Medium}
    color: ${colours.BLACK};
    display: block;
    text-decoration: none;
    text-transform: uppercase;
  `,
  Message: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
    display: block;
  `,
  ClearButton: styled.button`
    background: none;
    border: none;

    ${hoverUpDeleteIcon(true)}

    &.cancel-icon {
      svg {
        border-radius: 50%;
      }
    }

    & svg path {
      fill: ${colours.BLACK};
    }
  `,
};

type DeletedProductLineItemProps = {
  lineItem: LineItem;
  type: ProductLineItemType.MULTI_IMAGE | ProductLineItemType.SINGLE_IMAGE;
  lineItemStatus: LineItemStatus[];
  setLineItemStatus: Dispatch<SetStateAction<LineItemStatus[]>>;
};

export function DeletedProductLineItem({
  lineItem,
  type,
  setLineItemStatus,
  lineItemStatus,
}: DeletedProductLineItemProps) {
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common']);
  const { id, name, productKey, productSlug } = { ...lineItem };
  const { setHasLastItemDeleted } = useCartContext();
  const { setIsMiniCartOpen } = useOverlayContext();

  const productUrlSlug = productSlug ? `/${productSlug}` : '';
  const pdpLink = `${productUrlSlug}/${productKey}-p`;

  const handleDeleteMessage = () => {
    const newLineItemStatus = lineItemStatus.filter((item) => item.lineItem.id !== id);
    const hasMoreDeletedItems = newLineItemStatus.find((item) => item.isDeleted);
    setLineItemStatus(newLineItemStatus);

    if (!hasMoreDeletedItems) setHasLastItemDeleted(false);
  };

  const handlePDPLinkClick = () => {
    setIsMiniCartOpen(false);
  };

  useEffect(() => {
    document.body.style.cursor = 'auto';
    setHasLastItemDeleted(true);
  });

  return (
    <S.Container data-testid="deletedLineItem">
      <S.ClearButton type="button" onClick={handleDeleteMessage} aria-label={t('cart.line.item.clear.message')}>
        <Delete height="20" width="20" viewBox="0 0 11 13.5" />
      </S.ClearButton>
      <S.TextContainer $type={type}>
        <Link href={pdpLink} locale={locale === Locale.STOREFRONT_OUTLET_LOCALE ? false : locale} passHref>
          <S.Name onClick={handlePDPLinkClick}>{name}</S.Name>
        </Link>
        <S.Message role="status">{t('cart.line.item.removed', { item: name })}</S.Message>
      </S.TextContainer>
      <S.ClearButton
        className="cancel-icon"
        type="button"
        onClick={handleDeleteMessage}
        aria-label={t('cart.line.item.clear.message')}
      >
        <CancelCircle />
      </S.ClearButton>
    </S.Container>
  );
}
