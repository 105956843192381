import styled from 'styled-components';
import { LoadingIcon } from '../../icons';
import { colours } from '../../stylings';

const S = {
  LoaderHolder: styled.div<{ $isDark: boolean }>`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    & > svg {
      fill: ${({ $isDark }) => ($isDark ? colours.DARK_BLUE : colours.WHITE)};
      height: 32px;
      width: 48px;
    }
  `,
};

export function Loader({ isDark = false }) {
  return (
    <S.LoaderHolder $isDark={isDark} data-testid="threeDotsLoader">
      <LoadingIcon />
    </S.LoaderHolder>
  );
}
