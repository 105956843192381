import { useEffect } from 'react';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { AlternateLink } from '@amplience/content-types/typings/p-partials';
import { isOutletLocale } from '@shared/utils/dist';
import type {
  AncestorCategory,
  Cart,
  GetCMSPageResponse,
  HrefLang,
  Maybe,
  PathCategory,
  Variant,
} from '../../codegen/types';
import { useCartContext, useCheckoutConfirmationContext } from '../../context';
import { useHistory } from '../../context/historyContext';
import { useRecaptchaContext } from '../../context/recaptchaContext';
import { useUserContext } from '../../context/userContext';
import { useDidMount } from '../../hooks/useDidMount';
import useGetAgileOneToken from '../../hooks/useGetAgileOneToken';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { media } from '../../stylings/media';
import { createURLParameter } from '../../utils/createURLParameter';
import { sendPageLoadedEvent } from '../../utils/gtm/events/page_loaded/sendPageLoadedEvent';
import { Locale, getCtLocale, localeToUpperCase } from '../../utils/localeHelper';
import { addBreadcrumbListJson, addProductJson } from '../../utils/structuredData';
import { getLocalizedValue, removeTrailingSlash } from '../../utils/transformers';

const config = getConfig()?.publicRuntimeConfig || process.env;
interface SlugLocale {
  locale: string;
  value: string;
}
interface SiblingCategory {
  key: string;
  slug: SlugLocale[];
}
interface MetaProps {
  isProduct?: boolean;
  isCategory?: boolean;
  isContent?: boolean;
  isDynamicContent?: boolean;
  isSearch?: boolean;
  isPrivate?: boolean;
  host?: string;
  productKey?: string;
  giftCardKey?: string;
  categoryKey?: string;
  metaTitle?: string;
  metaDescription?: string;
  nbHits?: string;
  pageSize?: string;
  slugAllLocales?: SlugLocale[];
  siblingCategories?: SiblingCategory[];
  urlManagerContent?: string;
  searchTerm?: string;
  imageUrls?: string[];
  name?: string;
  variants?: Variant[];
  categoriesPath?: PathCategory[];
  description?: string;
  index?: boolean | null;
  follow?: boolean | null;
  ancestors?: AncestorCategory[];
  pageType?: string;
  cmsPageData?: {
    hrefLangs?: { locale?: string }[];
  };
}

type SearchIndexingTagParams = {
  isPrivateCategory: boolean;
  index: boolean | null;
  follow: boolean | null;
};

enum Content {
  UNAVAILABLE_AFTER = 'unavailable_after',
}

const pageURL = (pageArg: number) => (!isNaN(pageArg) && pageArg > 1 ? pageArg.toString() : '');

function renderAlternateLink(alternateLinks: AlternateLink) {
  const uniqueAlternateLinks: { [key: string]: string } = {};
  alternateLinks.forEach(({ href, hrefLang }) => {
    if (href && hrefLang) {
      uniqueAlternateLinks[hrefLang] = href ?? '';
    }
  });

  return (
    <>
      {Object.entries(uniqueAlternateLinks).map(([key, value]) => (
        <link key={key} rel="alternate" hrefLang={key} href={value} />
      ))}
    </>
  );
}

function setSearchIndexingTag({ isPrivateCategory, index, follow }: SearchIndexingTagParams) {
  const key = `${isPrivateCategory}_${index}_${follow}`;

  // lookup table to map all the 18 combinations of isPrivateCategory_index_follow
  // including the valid null values when index or follow are left blank in Akeneo
  const searchIndexingTags: { [key: string]: string[] } = {
    'index, follow': ['true_true_true', 'false_true_true', 'false_null_null', 'false_null_true', 'false_true_null'],
    'index, nofollow': ['true_true_false', 'true_true_null', 'false_true_false', 'false_null_false'],
    'noindex, follow': ['true_false_true', 'true_null_true', 'false_false_true', 'false_false_null'],
    'noindex, nofollow': [
      'true_false_false',
      'true_null_false',
      'true_false_null',
      'true_null_null',
      'false_false_false',
    ],
  };
  // from the 4 possible tags in the lookup table, return the one that includes the key
  return Object.keys(searchIndexingTags).find((tag) => searchIndexingTags[tag].includes(key));
}

export function Meta({
  isProduct,
  isCategory,
  isContent,
  isDynamicContent,
  isSearch,
  isPrivate,
  host,
  productKey,
  giftCardKey,
  categoryKey,
  metaTitle,
  metaDescription,
  slugAllLocales,
  siblingCategories,
  urlManagerContent,
  searchTerm,
  imageUrls,
  nbHits,
  pageSize,
  categoriesPath,
  name,
  variants,
  description: productDescription,
  index,
  follow,
  ancestors,
  pageType,
  cmsPageData,
}: MetaProps) {
  const { t } = useTranslation(['lib-global-common']);

  const { locale, query, asPath } = useRouter();
  const { enableRecaptchaScript } = useRecaptchaContext();
  const { isUserLoggedIn, customerData, customerEmailHash } = useUserContext();
  const { miniCart } = useCartContext();
  const { orderData } = useCheckoutConfirmationContext();
  const { page, query: queryURL, pageSize: urlPageSize } = query;
  const { urlSlug, content } = JSON.parse(urlManagerContent ?? '{}');
  const seoMeta = content?.[0]?.seoMeta;
  const localizedSeo = seoMeta?.localizedSeo;

  const isMounted = useDidMount();
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { history } = useHistory();

  const description = isSearch ? t('metasearch.description', { searchTerm }) : metaDescription;
  let searchIndexingTag;

  if (!isProduct && !isCategory) {
    searchIndexingTag = isPrivate ? 'noindex, nofollow' : 'index, follow';
  } else if ((isCategory || isProduct) && index !== undefined && follow !== undefined) {
    searchIndexingTag = setSearchIndexingTag({ isPrivateCategory: !!isPrivate, index, follow });
  }
  const robotsContent = isContent
    ? `${seoMeta?.robots?.tag}${Content.UNAVAILABLE_AFTER === seoMeta?.robots?.tag ? `: ${seoMeta?.robots?.date}` : ''}`
    : searchIndexingTag;
  const isOutlet: boolean = isOutletLocale(locale);

  let slugLocales: SlugLocale[] = [];
  let currentSlugLocale: SlugLocale | undefined;

  const categorySlugLocales = siblingCategories?.flatMap((category) =>
    category?.slug.map((slug) => ({ key: category.key, locale: slug.locale, slug: slug.value })),
  );

  const pageNumber = parseInt(page as string, 10) || 1;
  const pageSizeFromURL = parseInt(urlPageSize as string, 10);

  if ((productKey || giftCardKey) && slugAllLocales) {
    slugLocales =
      slugAllLocales?.length === 1
        ? [{ ...slugAllLocales[0], locale: localeToUpperCase(locale as string) }]
        : slugAllLocales;

    currentSlugLocale = isOutlet
      ? slugLocales?.find((slugAllLocale) => slugAllLocale?.locale === Locale.CT_OUTLET_LOCALE)
      : slugLocales?.find((slugAllLocale) => slugAllLocale?.locale.toLowerCase() === locale?.toLowerCase());
  }

  if (categoryKey && slugAllLocales) {
    currentSlugLocale = isOutlet
      ? slugAllLocales?.find((slugAllLocale) => slugAllLocale?.locale === Locale.CT_OUTLET_LOCALE)
      : slugAllLocales?.find(
          (slugAllLocale) => slugAllLocale?.locale.toLowerCase() === getCtLocale(locale as string).toLowerCase(),
        );
  }
  const maxPage = Math.ceil(Number(nbHits) / Number(pageSizeFromURL || pageSize));
  const hasNextPage = nbHits && pageNumber < maxPage;
  const hasPrevPage = pageNumber > 1 && pageNumber <= maxPage;
  const canonicalProductUrl = `https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}/${encodeURIComponent(
    currentSlugLocale?.value || '',
  )}/${productKey || giftCardKey}-p`;

  const pageTitle: string = metaTitle || getLocalizedValue(localizedSeo?.pageTitleLocal) || seoMeta?.pageTitle;
  useEffect(() => {
    // The following code is a temporary workaround to prevent sending two `page_loaded` events when a search term contains space characters
    // After entering the search term, we use router.push in the submitQuery function (@storefront/lib-global/globalComponents/header/search/Autocomplete.tsx)
    // Following this, URLs are synchronized with InstantSearch inside stateMapping (@storefront/lib-global/context/searchContext.tsx)
    // This causes the page to re-render twice because the space character first changes to a `+` character and then to `%20`
    const lastPath = history?.slice(-2, -1)[0];
    const currentPath = history.slice(-1)[0];
    if (isSearch && lastPath?.replace(/\+/g, '%20') === currentPath) return;

    // pageCategory - refers to the page_category
    // pageCategoryId - refers to the page_category_id; variables are separated by dashes and written in snake_case
    // For pages other than CMS, PLP, and PDP, we should use pageType as page_category and page_category_id
    // Example: page_category: my-account, page_category_id: my_account
    let pageCategory: string | undefined = pageType;
    let pageCategoryId: string | undefined = pageType?.replaceAll('-', '_');

    if (isContent) {
      // For CMS pages, we should use the first part of the slug for page_category and a combination of the first part of the slug and pageType for page_category_id
      // Example: page_category: contact-us, page_category_id: contact_us-cms
      const slug = urlSlug.startsWith('/') ? urlSlug.slice(1) : urlSlug;
      const isHomepage = slug === '';
      pageCategory = isHomepage ? 'homepage' : slug;
      pageCategoryId = isHomepage ? 'homepage' : `${slug.replaceAll('-', '_')}-${pageType?.replaceAll('-', '_')}`;
    }
    if (isCategory) {
      // For PLPs, we should use the slug value for page_category and the category key for page_category_id
      // Example: page_category: mens/mens-casual-shoes, page_category_id: m_casual_uk-c
      pageCategory = currentSlugLocale?.value;
      pageCategoryId = `${categoryKey?.replaceAll('-', '_')}-c`;
    }
    if (isProduct) {
      // For PDPs, we should use the most detailed slug for the category path and the most detailed slug key for page_category_id
      // Example: page_category: kids/kids-sandals, page_category_id: k_sandals_uk-p
      if (!categoriesPath) return;
      pageCategory = `${categoriesPath[categoriesPath.length - 1]?.slug}`;
      pageCategoryId = `${categoriesPath[categoriesPath.length - 1]?.key}-p`;
    }

    // check if history object last item is filled with asPath
    if (asPath === history.slice(-1)[0]) {
      sendPageLoadedEvent(
        history,
        isOutlet,
        isDesktop,
        locale ?? '',
        pageTitle ?? '',
        pageType,
        isUserLoggedIn,
        customerData,
        customerEmailHash,
        miniCart as Cart,
        orderData,
        pageCategory,
        pageCategoryId,
      );
    }
    // should be triggered only once when the path changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, history, isOutlet, locale, pageTitle, pageType]);

  useGetAgileOneToken(isMounted, locale);

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title key="page-title">
        {metaTitle || getLocalizedValue(localizedSeo?.pageTitleLocal) || seoMeta?.pageTitle}
      </title>
      <meta
        name="description"
        content={description || getLocalizedValue(localizedSeo?.pageDescriptionLocal) || seoMeta?.pageDescription || ''}
        key="page-description"
      />
      <meta name="robots" content={isSearch ? 'noindex, follow' : robotsContent} key="robots" />
      {enableRecaptchaScript && (
        <script src={`https://www.google.com/recaptcha/api.js?render=${config.RECAPTCHA_SITE_KEY}`} defer />
      )}
      <link rel="preconnect" href="https://clarks.a.bigcontent.io" />
      <link rel="preconnect" href="https://cdn.media.amplience.net" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="preconnect" href="https://cdn-ukwest.onetrust.com" />
      {isContent && (
        <link
          rel="canonical"
          href={removeTrailingSlash(
            `https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}${urlSlug ?? ''}`,
          )}
        />
      )}
      {!isOutlet &&
        isContent &&
        (cmsPageData?.hrefLangs as GetCMSPageResponse['hrefLangs'])?.map((hrefLang: Maybe<HrefLang>, k: number) => (
          <link
            rel="alternate"
            hrefLang={hrefLang?.locale.toLocaleLowerCase() ?? ''}
            href={`https://${host}${hrefLang?.slug}`}
            key={`${slugAllLocales}-${k}`}
          />
        ))}

      {isDynamicContent && (
        <link
          rel="canonical"
          href={removeTrailingSlash(
            `https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}${asPath.split('?')[0] ?? ''}`,
          )}
        />
      )}
      {renderAlternateLink(seoMeta?.alternateLinks ?? [])}
      {isProduct && (
        <>
          {/* eslint-disable react/no-danger */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={addProductJson(name, productDescription, imageUrls, variants, canonicalProductUrl)}
            defer
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={addBreadcrumbListJson(categoriesPath, locale)}
            defer
          />
          {/* eslint-enable react/no-danger */}

          <meta property="og:title" content={metaTitle} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:image" content={imageUrls?.[0]} />
          <meta property="og:image:secure_url" content={imageUrls?.[0]} />
        </>
      )}
      {isProduct && currentSlugLocale && <link rel="canonical" href={removeTrailingSlash(canonicalProductUrl)} />}
      {!isOutlet &&
        isProduct &&
        // Temporarily disable alternate links for gift cards
        // This should be resolved in another ticket
        // Use this comment as a reference: https://clarks.atlassian.net/browse/CICD-7707?focusedCommentId=382085
        productKey &&
        slugLocales
          ?.filter((slugLocale) => slugLocale.locale !== Locale.CT_OUTLET_LOCALE)
          ?.map((slugLocale, k) => (
            <link
              rel="alternate"
              hrefLang={slugLocale.locale?.toLocaleLowerCase()}
              href={`https://${host}/${slugLocale.locale?.toLocaleLowerCase()}/${encodeURIComponent(
                slugLocale.value,
              )}/${productKey}-p`}
              key={`${slugAllLocales}-${k}`}
            />
          ))}
      {/* eslint-disable react/no-danger */}
      {isCategory && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={addBreadcrumbListJson(
            ancestors,
            locale,
            name,
            categoryKey,
            currentSlugLocale?.value,
          )}
          defer
        />
      )}
      {/* eslint-enable react/no-danger */}
      {isCategory && currentSlugLocale && (
        <>
          <link
            rel="canonical"
            href={removeTrailingSlash(
              `https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}/${
                currentSlugLocale?.value
              }/${categoryKey}-c${createURLParameter({
                page: pageURL(pageNumber),
                pageSize: pageURL(pageSizeFromURL),
              })}`,
            )}
          />
          {hasPrevPage && (
            <link
              rel="prev"
              href={`https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}/${
                currentSlugLocale?.value
              }/${categoryKey}-c${createURLParameter({
                page: pageURL(pageNumber - 1),
                pageSize: pageURL(pageSizeFromURL),
              })}`}
            />
          )}
          {hasNextPage && (
            <link
              rel="next"
              href={`https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}/${
                currentSlugLocale?.value
              }/${categoryKey}-c${createURLParameter({
                page: pageURL(pageNumber + 1),
                pageSize: pageURL(pageSizeFromURL),
              })}`}
            />
          )}
        </>
      )}
      {isSearch && (
        <>
          <link
            rel="canonical"
            href={removeTrailingSlash(
              `https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}/search${createURLParameter({
                query: queryURL,
                page: pageURL(pageNumber),
                pageSize: pageURL(pageSizeFromURL),
              })}`,
            )}
          />
          {hasPrevPage && (
            <link
              rel="prev"
              href={`https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}/search${createURLParameter({
                query: queryURL,
                page: pageURL(pageNumber - 1),
                pageSize: pageURL(pageSizeFromURL),
              })}`}
            />
          )}
          {hasNextPage && (
            <link
              rel="next"
              href={`https://${host}${isOutlet ? '' : `/${locale?.toLocaleLowerCase()}`}/search${createURLParameter({
                query: queryURL,
                page: pageURL(pageNumber + 1),
                pageSize: pageURL(pageSizeFromURL),
              })}`}
            />
          )}
        </>
      )}
      {!isOutlet &&
        isCategory &&
        categorySlugLocales
          ?.filter(
            (categorySlugLocale) =>
              categorySlugLocale.locale !== Locale.CT_OUTLET_LOCALE &&
              categorySlugLocale.locale !== Locale.DEFAULT_LOCALE,
          )
          ?.map((categorySlugLocale, k) => (
            <link
              rel="alternate"
              hrefLang={categorySlugLocale.locale?.toLocaleLowerCase()}
              href={`https://${host}/${categorySlugLocale.locale?.toLowerCase()}/${categorySlugLocale.slug}/${
                categorySlugLocale.key
              }-c`}
              key={`${categorySlugLocale.key}-${k}`}
            />
          ))}
    </Head>
  );
}

export default Meta;
