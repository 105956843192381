import { storeKeyMapper } from '@shared/utils/src/ct';
import { GetStaticContextDataDocument } from '../../graphqlDocument/staticContextData/staticContextData.generated';
import type {
  GetStaticContextDataQuery,
  GetStaticContextDataQueryVariables,
} from '../../graphqlDocument/staticContextData/staticContextData.generated';
import { ONE_MINUTE, queryCacheEvictor } from '../../utils/checkCache';
import { withNewRelic } from '../newrelic';
import type { ApolloClientInstance } from './apolloClient';

const getStaticContextDataBase = async (apolloClient: ApolloClientInstance, locale: string, fullLocale?: string) => {
  try {
    const staticContextData = await apolloClient.query<GetStaticContextDataQuery, GetStaticContextDataQueryVariables>({
      query: GetStaticContextDataDocument,
      variables: {
        locale,
        input: {
          storeKey: storeKeyMapper(fullLocale),
        },
      },
    });
    const {
      data: {
        paymentData: { paymentMethods },
        getStore,
        marketingPreferences,
        siteSwitch,
      },
    } = staticContextData;

    queryCacheEvictor({ isField: false, storeObject: getStore }, ONE_MINUTE * 10, apolloClient);
    queryCacheEvictor(
      {
        isField: true,
        id: 'ROOT_QUERY',
        fieldName: 'siteSwitch',
      },
      ONE_MINUTE * 10,
      apolloClient,
    );
    queryCacheEvictor(
      {
        isField: true,
        id: 'ROOT_QUERY',
        fieldName: 'paymentData',
        args: { locale },
      },
      ONE_MINUTE * 10,
      apolloClient,
    );
    queryCacheEvictor(
      {
        isField: true,
        id: 'ROOT_QUERY',
        fieldName: 'marketingPreferences',
        args: { locale },
      },
      ONE_MINUTE * 10,
      apolloClient,
    );

    return {
      paymentMethods,
      storeData: getStore,
      marketingPreferences,
      siteSwitch,
    };
  } catch (e) {
    return {
      error: e,
      paymentMethods: null,
      storeData: null,
      marketingPreferences: null,
      siteSwitch: null,
    };
  }
};

export const getStaticContextData = withNewRelic('getStaticContextData', getStaticContextDataBase);
