import { useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import { useCartContext } from '../../../context/cartContext';
import { useStar2 } from '../../../hooks/useStar2';
import { SecurityLogo } from '../../../icons';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

const S = {
  SummaryBar: styled.div<{ $isStar2?: boolean }>`
    ${({ $isStar2 }) =>
      $isStar2
        ? css`
            flex-wrap: wrap;
          `
        : null}
    align-items: center;
    display: flex;
    gap: ${spacing.XXXS};

    @media ${media.greaterThan('lg')} {
      gap: ${spacing.XS};
    }
  `,
  Header: styled.h1`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    text-transform: uppercase;
  `,
  Subheading: styled.div`
    align-items: center;
    display: flex;
    gap: ${spacing.XXXXS};
  `,
  ItemCount: styled.span`
    ${TypographyStyles.Body.Small.Regular}
    color: ${colours.GREY};
    white-space: nowrap;
  `,
  Divider: styled.span`
    ${TypographyStyles.Body.Small.Regular}
    color: ${colours.GREY};
  `,
  Total: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
  SecurityLogo: styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    @media ${media.greaterThan('lg')} {
      flex-grow: 0;

      .mini-cart-summary-bar,
      .checkout-summary-bar & {
        flex-grow: 1;
      }
    }
  `,
};

type SummaryBarProps = {
  currency: string;
  total: string;
  className?: string;
  testId?: string;
  isGlobalECheckout?: boolean;
};

export function SummaryBar({ currency, total, className, testId, isGlobalECheckout }: SummaryBarProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { itemsInCart, isCartEmpty } = useCartContext();
  const { isStar2 } = useStar2();

  return (
    <S.SummaryBar className={className} data-testid={testId} $isStar2={!!isStar2}>
      <S.Header>{isCartEmpty ? t('cart.summary.bar.header.empty') : t('cart.summary.bar.header')}</S.Header>
      {!isCartEmpty && (
        <S.Subheading>
          <S.ItemCount data-testid="cartItemCount">{t('cart.summary.items', { count: itemsInCart })}</S.ItemCount>
          {!isGlobalECheckout && <S.Divider> | </S.Divider>}
          <S.Total data-testid="cartTotal">
            {currency}
            {total}
          </S.Total>
        </S.Subheading>
      )}
      <S.SecurityLogo>
        <SecurityLogo />
      </S.SecurityLogo>
    </S.SummaryBar>
  );
}
