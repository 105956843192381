import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { TypographyStyles } from '../../stylings';
import { UserOpt } from '../../types/newsletter';

const S = {
  LabelHeading: styled.p`
    margin: 0;
    padding: 0;
    ${TypographyStyles.Body.Small.Medium}
  `,
  Description: styled.p`
    margin: 0;
    padding: 0;
    ${TypographyStyles.Body.Tiny.Regular}
  `,
  LabelWrapper: styled.span``,
};

export function OptLabel({ opt }: { opt: UserOpt }) {
  const { t } = useTranslation(['lib-global-common']);
  const isOptDown = opt === UserOpt.OPT_DOWN;
  const headingMessage = isOptDown ? t('newsletter.opt.down.option.header') : t('newsletter.opt.out.option.header');
  const message = isOptDown ? t('newsletter.opt.down.option.description') : t('newsletter.opt.out.option.description');
  return (
    <S.LabelWrapper>
      <S.LabelHeading>{headingMessage}</S.LabelHeading>
      <S.Description>{message}</S.Description>
    </S.LabelWrapper>
  );
}
