import { useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import { A11y, Navigation } from 'swiper/modules';
import type { RelatedProductsCarousel as RelatedProductsCarouselProps } from '@amplience/content-types/typings/c-related-products-carousel';
import { useLocaleContext } from '../../../context/localeContext';
import { useAlgoliaRelatedItems } from '../../../hooks/useAlgoliaRelatedItems';
import { useIntersectOnce } from '../../../hooks/useIntersectOnce';
import { useMediaMatch } from '../../../hooks/useMediaMatch';
import { useTruncated } from '../../../hooks/useTruncated';
import { ArrowTailLeft, ArrowTailRight } from '../../../icons';
import { getAlgoliaProductIndex } from '../../../services/algolia/searchClient';
import { TypographyStylesType, colours, media } from '../../../stylings';
import { sendViewItemListFromRelatedCarouselEvent } from '../../../utils/gtm/events/view_item_list/sendViewItemListFromRelatedCarouselEvent';
import { getLocalizedValue, transformTitleTextToID } from '../../../utils/transformers';
import AlgoliaCarouselItem from '../algoliaCarouselItem/AlgoliaCarouselItem';
import {
  CarouselContainer,
  CarouselTitle,
  Container,
  IconButton,
  IconContainer,
  SwiperContainer,
  SwiperSlideContainer,
  TitleContainer,
  TopContainer,
} from './RelatedProductsCarousel.styles';

export interface RelatedProductsProps extends RelatedProductsCarouselProps {
  singleProductKey?: string;
  objectIDsArray?: string[];
  isFromCart?: boolean;
  className?: string;
}

export function RelatedProductsCarousel({
  title,
  layout,
  multiImageButtonText,
  maxRecommendations,
  index,
  singleProductKey: productKey,
  sampleProductKey,
  objectIDsArray = [],
  isFromCart = false,
  className,
}: RelatedProductsProps) {
  const { locale } = useLocaleContext();
  const indexName = getAlgoliaProductIndex(locale);
  const { isReady } = useRouter();

  const sampleVisualizationProductKey = sampleProductKey && sampleProductKey[0]?.split(' - ')[0];

  const { elementRef, isIntersecting: isInView } = useIntersectOnce({
    rootMargin: '100%',
  });

  const { relatedItems, isLoading } = useAlgoliaRelatedItems({
    query: isFromCart
      ? objectIDsArray.map((objectId) => ({
          indexName,
          objectID: objectId,
        }))
      : [
          {
            indexName,
            objectID: sampleVisualizationProductKey ?? productKey ?? '',
          },
        ],
    isInView,
  });

  const loadingSkeleton = isLoading && (Array.from({ length: 5 }, () => ({})) as typeof relatedItems);

  const recommendationItems = useMemo(
    () => loadingSkeleton || relatedItems?.slice(0, maxRecommendations) || relatedItems,
    [maxRecommendations, relatedItems, loadingSkeleton],
  );

  const isMultiImage = layout === 'Multi Image';
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const isSingleImageCount = isDesktop ? recommendationItems.length > 3 : recommendationItems.length > 2;
  const isMultiImageCount = isDesktop ? relatedItems.length > 2 : relatedItems.length > 1;
  const { t } = useTranslation(['lib-global-common']);
  const textElRef = useRef<HTMLElement>(null);
  const { isTruncated, lineHeight } = useTruncated(textElRef);

  useEffect(() => {
    if (!isLoading && recommendationItems.length > 0) {
      sendViewItemListFromRelatedCarouselEvent({
        algoliaCarouselProducts: recommendationItems,
        locale,
        itemList: {
          itemListId: transformTitleTextToID(title?.text),
          itemListName: getLocalizedValue(title?.text),
        },
      });
    }
  }, [recommendationItems, isReady, locale, title?.text, isLoading]);

  return (
    <CarouselContainer className={className} ref={elementRef}>
      {(isLoading || (!isLoading && recommendationItems.length > 0)) && (
        <Container className={className} key={isDesktop ? 'desktop-related-carousel' : 'mobile-related-carousel'}>
          <TopContainer>
            <TitleContainer>
              <CarouselTitle
                {...title}
                defaultTextSize={TypographyStylesType.HEADINGS_H3}
                defaultTextColor={colours.BLACK}
                ref={textElRef}
                $lineHeight={lineHeight}
                className={`${isTruncated ? 'truncated' : ''}`}
              />
              {!isLoading && ((!isMultiImage && isSingleImageCount) || (isMultiImage && isMultiImageCount)) && (
                <span className="products-count">{`(${recommendationItems.length})`}</span>
              )}
            </TitleContainer>
            {((!isMultiImage && isSingleImageCount) || (isMultiImage && isMultiImageCount)) && (
              <IconContainer className="product-icons">
                <IconButton
                  type="button"
                  aria-label={t('media.carousel.aria.prev.button')}
                  className={`nav-prev media-grid-prev-${index}`}
                >
                  <ArrowTailRight className="arrow-icon" viewBox="5 4 6 14" />
                </IconButton>
                <IconButton
                  type="button"
                  aria-label={t('media.carousel.aria.next.button')}
                  className={` media-grid-next-${index}`}
                >
                  <ArrowTailLeft className="arrow-icon" viewBox="10 4 6 14" />
                </IconButton>
              </IconContainer>
            )}
          </TopContainer>
          <SwiperContainer
            modules={[Navigation, A11y]}
            navigation={{
              prevEl: `.media-grid-prev-${index}`,
              nextEl: `.media-grid-next-${index}`,
              enabled: !isLoading,
            }}
            a11y={{ slideRole: 'link' }}
            shortSwipes={!isDesktop}
            slidesPerView="auto"
            watchSlidesProgress
            spaceBetween={isDesktop ? 32 : 20}
          >
            {recommendationItems.length !== 0 &&
              recommendationItems
                .filter((item) => {
                  if (isLoading) return true;
                  return isMultiImage ? item.images && item.images.length > 3 : item;
                })
                .map((product, i) => (
                  <SwiperSlideContainer key={`${product.objectID}-${i}`} $isMultiImage={isMultiImage}>
                    <AlgoliaCarouselItem
                      isLoading={isLoading}
                      algoliaIndexName={indexName}
                      position={i + 1}
                      item={product}
                      isMultiImage={isMultiImage}
                      multiImageButtonText={multiImageButtonText}
                      carouselTitle={title?.text?.toString()}
                      isPDP
                    />
                  </SwiperSlideContainer>
                ))}
          </SwiperContainer>
        </Container>
      )}
    </CarouselContainer>
  );
}
