import { css } from 'styled-components';
import type { TextTransform } from '@amplience/content-types/typings/p-partials';
import { TextTransformMapper } from '../../utils/amplienceMappers';

export const useTextTransform = (transform: TextTransform | undefined) =>
  transform
    ? css`
        text-transform: ${TextTransformMapper[transform]};
      `
    : null;
