import type {
  TypedMoney,
  HighPrecisionMoney,
  HighPrecisionMoneyDraft,
  CentPrecisionMoney,
} from '@commercetools/platform-sdk';
import type { LocaleType, LanguageType } from '@shared/localisation';
import { isLocaleOrLanguage } from '@shared/localisation';
import { getCorrectedLocale } from '../i18n';
import { getCurrencyFractionDigits, highPrecisionFractionDigits } from './common';

interface FormatPriceOptions {
  locale?: string;
  currencyDisplay?: string;
  withSign?: boolean;
}

export type CtMoney = Omit<TypedMoney, 'type'> & { type: string };

function getPart(parts: Intl.NumberFormatPart[], partType: string) {
  const [value] = parts.filter(({ type }) => type === partType);
  return value;
}

function intlFormat(locale: LocaleType | LanguageType, currency: string, amount: number, useGrouping: boolean) {
  const correctedLocale = getCorrectedLocale(locale);
  return new Intl.NumberFormat(correctedLocale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
    currencyDisplay: 'code',
    useGrouping,
  }).formatToParts(amount);
}

export function formatPrice(
  money: CtMoney,
  { locale = 'en', currencyDisplay = '', withSign = false }: FormatPriceOptions,
): string {
  if (!isLocaleOrLanguage(locale)) {
    throw new Error(`${locale} is not a configured locale`);
  }

  const parts = intlFormat(locale, money.currencyCode, money.centAmount / 100, locale.startsWith('en'));

  const integer = parts
    .flatMap((part) => (part.type === 'integer' || part.type === 'group' ? [part.value] : []))
    .join('');

  const decimal = getPart(parts, 'decimal').value;
  const fraction = getPart(parts, 'fraction').value;

  let formattedValue = money.centAmount % 100 === 0 ? integer : `${integer}${decimal}${fraction}`;

  if (currencyDisplay !== '') {
    formattedValue = `${formattedValue} ${currencyDisplay}`;
  }

  if (withSign) {
    formattedValue = `${money.centAmount >= 0 ? '+' : '-'} ${formattedValue}`;
  }

  return formattedValue;
}

type PrecisionType = 'highPrecision' | 'centPrecision';
type CtPrecisionMoney = CtMoney & { preciseAmount?: number };

function stringToCtMoney({
  stringPrice,
  currencyCode,
  customPrecisionFractionDigits,
  precisionType = 'centPrecision',
}: {
  stringPrice: string;
  currencyCode: string;
  customPrecisionFractionDigits?: number;
  precisionType?: PrecisionType;
}): CtPrecisionMoney {
  const currencyFractionDigits = getCurrencyFractionDigits(currencyCode);

  const precisionFractionDigits = customPrecisionFractionDigits || currencyFractionDigits;
  const fractionMultiplier = 10 ** precisionFractionDigits;
  const [intPart, cents] = stringPrice.split('.');

  let centAmount: number;
  let preciseAmount: number;

  if (!cents) {
    centAmount = +stringPrice * 10 ** currencyFractionDigits;
    preciseAmount = +stringPrice * fractionMultiplier;
  } else {
    centAmount = Math.round(
      +intPart.concat('.', cents.padEnd(currencyFractionDigits + 1, '0').substr(0, currencyFractionDigits + 1)) *
        10 ** currencyFractionDigits +
        Number.EPSILON,
    );
    preciseAmount = +intPart.concat(cents.padEnd(precisionFractionDigits, '0').substr(0, precisionFractionDigits));
  }

  return {
    type: precisionType,
    currencyCode,
    centAmount,
    fractionDigits: precisionFractionDigits,
    preciseAmount: customPrecisionFractionDigits && preciseAmount,
  };
}

export function stringToCentPrecisionMoney(stringPrice: string, currencyCode: string): CentPrecisionMoney {
  return stringToCtMoney({
    stringPrice,
    currencyCode,
    precisionType: 'centPrecision',
  }) as CentPrecisionMoney;
}

export function numberToCentPrecisionMoney(price: number, currencyCode: string): CentPrecisionMoney {
  return stringToCentPrecisionMoney(price.toString(), currencyCode);
}

export function stringToHighPrecisionMoney(stringPrice: string, currencyCode: string): HighPrecisionMoneyDraft {
  return stringToCtMoney({
    stringPrice,
    currencyCode,
    precisionType: 'highPrecision',
    customPrecisionFractionDigits: highPrecisionFractionDigits,
  }) as HighPrecisionMoneyDraft;
}

export function highPrecisionMoneyToNumber(price: HighPrecisionMoney): number {
  return price.preciseAmount
    ? price.preciseAmount / 10 ** price.fractionDigits
    : price.centAmount / 10 ** getCurrencyFractionDigits(price.currencyCode);
}

export function toHighPrecisionNumber(numberWithFraction: number): number {
  return toPrecisionNumber(numberWithFraction, highPrecisionFractionDigits);
}

export function toCentPrecisionNumber(numberWithFraction: number, currencyCode: string): number {
  return toPrecisionNumber(numberWithFraction, getCurrencyFractionDigits(currencyCode));
}

export function toPrecisionNumber(numberWithFraction: number, fractionDigits: number): number {
  return Math.round(numberWithFraction * 10 ** fractionDigits + Number.EPSILON);
}

/*
 * Rounds up a number using fixed-point notation (default is 2).
 * Example1: 123.456 is rounded to 123.46
 * Example2: 123.454 is rounded to 123.45
 */
export function truncateDecimals(unrounded: number, fractionDigits = 2): number {
  return Number.parseFloat(unrounded.toFixed(fractionDigits));
}

interface CalculatePriceForPercentageParams {
  locale: LocaleType | LanguageType;
  percentage: number;
  price: CtMoney;
}

export function roundTo(num: number, fractionDigits: number): number {
  return +`${Math.round(Number(`${num}e+${fractionDigits}`))}e-${fractionDigits}`;
}

/**
 * Calculates price with decimals for passed percentage with applying rounding based on price fractionDigits
 */
export function calculatePriceWithDecimalsForPercentage({
  locale,
  percentage,
  price,
}: CalculatePriceForPercentageParams): string {
  const money = {
    ...price,
    centAmount: roundTo((price.centAmount * percentage) / 100, price.fractionDigits),
    type: 'centPrecision',
  };

  return formatPrice(money, { locale, currencyDisplay: price.currencyCode });
}

export function getPriceValue(price: TypedMoney): number {
  const amount = price.type === 'highPrecision' ? price.preciseAmount : price.centAmount;
  return amount / 10 ** price.fractionDigits;
}

export function getPriceDifference<T extends Omit<TypedMoney, 'type'>>(sourceOperand: T, destOperand: T): T {
  return {
    ...destOperand,
    centAmount: destOperand.centAmount - sourceOperand.centAmount,
  };
}

export function fromCents(centAmount: number | undefined | null): string {
  return centAmount ? (centAmount / 100).toFixed(2) : '0.00';
}
