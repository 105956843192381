import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../../stylings';
import { NeedHelpContent } from './NeedHelpContent';

const S = {
  HelpWrapper: styled.div`
    background-color: ${colours.WHITE};
    height: fit-content;
    margin: ${spacing.XS} 0;
    min-height: 125px;
    min-width: 44px;
    width: fit-content;
  `,
  NeedHelpLabel: styled.span`
    ${TypographyStyles.Headings.H5}
    display: block;
  `,
  NeedHelpContent: styled.div`
    ${TypographyStyles.Body.Small.Regular}
  `,
};

export function NeedHelp({ orderNumber }: { orderNumber?: string }) {
  const { t } = useTranslation(['lib-global-common']);

  return (
    <S.HelpWrapper data-testid="needHelpWrapper">
      <S.NeedHelpLabel>{t('cart.need.help')}</S.NeedHelpLabel>
      <S.NeedHelpContent>
        <NeedHelpContent orderNumber={orderNumber} />
      </S.NeedHelpContent>
    </S.HelpWrapper>
  );
}
