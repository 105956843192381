import type { ParsedUrlQuery } from 'querystring';
import { Locale, createFacetAttributeNames, isAllowedAlgoliaAttribute } from '../../utils';
import { getAlgoliaProductIndex } from './searchClient';

type GetSearchResultsProps = {
  searchTerm?: ParsedUrlQuery;
  category?: string;
  locale?: string;
};
export const getSearchResults = async ({ searchTerm, locale, category }: GetSearchResultsProps) => {
  const indexName = searchTerm?.sortBy || getAlgoliaProductIndex(locale ?? Locale.DEFAULT_LOCALE);

  const searchTermKeys = Array.from(
    new Set(
      Object.keys(searchTerm || {})
        .map((s) => s.split('[')[0])
        .filter(isAllowedAlgoliaAttribute),
    ),
  );

  let facetFilters: string[][] = [];

  // Caters anything expect for the ranges

  searchTermKeys.forEach((searchTermKey) => {
    const querySearchTermKeys = Object.entries(searchTerm || {})
      .filter(([querySearchTermKey]) => {
        const newQuerySearchTermKey = querySearchTermKey.split('[')[0];
        return newQuerySearchTermKey === searchTermKey;
      })
      .map(([_, value]) => value);
    const newFacetFilterArray = querySearchTermKeys.map(
      (querySearchTermKey) => `${searchTermKey}:${querySearchTermKey}`,
    );

    facetFilters = [...facetFilters, newFacetFilterArray];
  });

  const newSearchTerm: {
    [key: string]: unknown;
  } = {
    query: searchTerm?.query || '',
    clickAnalytics: true,
    facets: createFacetAttributeNames(locale),
    maxValuesPerFacet: 100,
  };

  if (searchTerm?.pageSize) {
    newSearchTerm.hitsPerPage = searchTerm?.pageSize;
  }

  if (category) {
    newSearchTerm.filters = [`categories.key: ${category}`];
  }

  if (searchTermKeys) {
    newSearchTerm.facetFilters = JSON.stringify(facetFilters);
  }
  const numericFilters: string[] = [];

  if (searchTerm?.minPrice) {
    numericFilters.push(`price.centAmount >= ${searchTerm.minPrice}`);
  }

  if (searchTerm?.maxPrice) {
    numericFilters.push(`price.centAmount <= ${searchTerm.maxPrice}`);
  }
  newSearchTerm.numericFilters = numericFilters;

  const searchParams = new URLSearchParams(newSearchTerm as Record<string, string>);

  const requestBody = {
    params: searchParams.toString(),
  };

  const searchURL = `https://${process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID}-dsn.algolia.net/1/indexes/${indexName}/query`;

  const data = await getAlgoliaSearchResult({ searchURL, body: JSON.stringify(requestBody) });
  const newFacetFilterValues = await Promise.all(
    searchTermKeys.map(async (searchTermKey) => {
      const facetSearchTerm: {
        [key: string]: unknown;
      } = {
        maxValuesPerFacet: 100,
        facets: searchTermKey,
        hitsPerPage: 0,
        query: searchTerm?.query || '',
        numericFilters,
        facetFilters: JSON.stringify(facetFilters.filter((facetFilter) => !facetFilter[0].includes(searchTermKey))),
      };

      const facetParams = new URLSearchParams(facetSearchTerm as Record<string, string>);
      const facetRequestBody = {
        params: facetParams.toString(),
      };
      return getAlgoliaSearchResult({ searchURL, body: JSON.stringify(facetRequestBody) });
    }),
  );
  const newFacets = newFacetFilterValues.map((newFacetFilterValue) => newFacetFilterValue.facets);

  let productHits = data.hits;

  // Get the correct products when pages in URL is greater than 1
  if (Number(searchTerm?.page) > 1 && data?.hitsPerPage) {
    const hitsSearchTerm: {
      [key: string]: unknown;
    } = {
      hitsPerPage: (searchTerm?.pageSize || data.hitsPerPage) * Number(searchTerm?.page),
      query: searchTerm?.query || '',
    };

    if (category) {
      hitsSearchTerm.filters = [`categories.key: ${category}`];
    }

    const hitsParams = new URLSearchParams(hitsSearchTerm as Record<string, string>);
    const hitsRequestBody = {
      params: hitsParams.toString(),
    };

    const allProductHits = await getAlgoliaSearchResult({ searchURL, body: JSON.stringify(hitsRequestBody) });
    if (allProductHits?.hits) {
      productHits = allProductHits.hits;
    }
  }

  return {
    ...data,
    // Seems like Algolia is filling up the gap when there are more products than nbHits. The only way to fix it is to slice the hits.
    hits: productHits.length > data.nbHits ? productHits.slice(0, data.nbHits) : productHits,
    facets: {
      ...data.facets,
      ...Object.assign({}, ...newFacets),
    },
  };
};

export async function getAlgoliaSearchResult({ searchURL, body }: { searchURL: string; body: string }) {
  try {
    const response = await fetch(searchURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Algolia-API-Key': process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? '',
        'X-Algolia-Application-Id': process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ?? '',
      },
      body,
    });

    const data = await response.json();
    return data;
  } catch (e) {
    return {};
  }
}
