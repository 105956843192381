import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import { A11y, Navigation } from 'swiper/modules';
import type { ProductCarousel as ProductCarouselProps } from '@amplience/content-types/typings/c-product-carousel';
import type { CarouselProduct } from '../../../codegen/types';
import { useLocaleContext } from '../../../context/localeContext';
import { useDidMount } from '../../../hooks/useDidMount';
import { useGetCarouselProducts } from '../../../hooks/useGetCarouselProducts';
import { useIntersectOnce } from '../../../hooks/useIntersectOnce';
import { useMediaMatch } from '../../../hooks/useMediaMatch';
import { useTruncated } from '../../../hooks/useTruncated';
import { ArrowTailLeft, ArrowTailRight } from '../../../icons';
import { TypographyStylesType, colours, media } from '../../../stylings';
import { toSnakeCase } from '../../../utils/convertToSnakeCase';
import { sendSelectItemEvent } from '../../../utils/gtm/events/select_item/sendSelectItemEvent';
import { sendViewItemListFromCarouselEvent } from '../../../utils/gtm/events/view_item_list/sendViewItemListFromCarouselEvent';
import { productCarouselMultiImageSettings } from '../../../utils/swiperCarouselSettings';
import { getLocalizedValue, transformTitleTextToID } from '../../../utils/transformers';
import { ProductItem } from '../productCarouselItem/ProductCarouselItem';
import {
  CarouselContainer,
  CarouselTitle,
  Container,
  IconButton,
  IconContainer,
  SwiperContainer,
  SwiperSlideContainer,
  TitleContainer,
  TopContainer,
} from './ProductCarousel.styles';

export interface ProductCarouselMediaProps extends ProductCarouselProps {
  carouselProducts: CarouselProduct[];
}

export function ProductCarousel({
  carouselProducts: carouselProductsAmplienceData = [],
  title,
  layout,
  multiImageButtonText,
  index,
  productKey,
}: ProductCarouselMediaProps) {
  const { locale } = useLocaleContext();
  const textElRef = useRef<HTMLElement>(null);
  const isMounted = useDidMount();

  const { elementRef, isIntersecting: isInView } = useIntersectOnce({
    rootMargin: '100%',
  });

  const { carouselProducts: carouselProductsData, isLoading } = useGetCarouselProducts({
    productKey,
    context: { locale },
    skip: carouselProductsAmplienceData.length > 0 || !isInView,
  });

  const loadingSkeleton = isLoading && Array.from({ length: 5 }, () => ({} as CarouselProduct));

  const carouselProducts = useMemo(
    () =>
      loadingSkeleton ||
      (carouselProductsAmplienceData.length > 0 ? carouselProductsAmplienceData : carouselProductsData),
    [carouselProductsAmplienceData, carouselProductsData, loadingSkeleton],
  );

  const isMultiImage = layout === 'Multi Image';
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const isSingleImageCount = isDesktop ? carouselProducts.length > 3 : carouselProducts.length > 2;
  const isMultiImageCount = isDesktop ? carouselProducts.length > 2 : carouselProducts.length > 1;
  const { t } = useTranslation(['lib-global-common']);
  const { isTruncated, lineHeight } = useTruncated(textElRef);
  const multiImageSpace = isMultiImage ? 32 : 20;

  const itemListName = title?.text?.toString() ?? '';
  const itemListId = toSnakeCase(itemListName);

  const handleOnClick = (product: CarouselProduct) => {
    sendSelectItemEvent(
      {
        itemListName,
        itemListId,
        primaryCategoryKey: product.primaryCategory?.key ?? undefined,
        primaryCategoryName: product.primaryCategory?.name ?? undefined,
        key: product.productKey,
        name: product.productName ?? '',
        brand: product.brand,
        color: product.colors?.[0]?.color ?? '',
      },
      product.prices,
    );
  };

  useEffect(() => {
    if (carouselProducts?.length > 0 && isMounted && !isLoading) {
      sendViewItemListFromCarouselEvent({
        carouselProducts,
        itemList: {
          itemListId: transformTitleTextToID(title?.text),
          itemListName: getLocalizedValue(title?.text),
        },
      });
    }
  }, [carouselProducts, title?.text, isMounted, isLoading]);

  return (
    <CarouselContainer ref={elementRef}>
      {(isLoading || (!isLoading && carouselProducts.length > 1)) && (
        <Container key={isDesktop ? 'desktop-product-carousel' : 'mobile-product-carousel'}>
          <TopContainer>
            <TitleContainer>
              <CarouselTitle
                tag="h3"
                {...title}
                defaultTextSize={TypographyStylesType.HEADINGS_H3}
                defaultTextColor={colours.BLACK}
                ref={textElRef}
                $lineHeight={lineHeight}
                className={`${isTruncated ? 'truncated' : ''}`}
              />
              {!isLoading && !!isMultiImage && isMultiImageCount && (
                <span className="products-count">{`(${carouselProducts.length})`}</span>
              )}
              {!isLoading && !isMultiImage && isSingleImageCount && (
                <span className="products-count">{`(${carouselProducts.length})`}</span>
              )}
            </TitleContainer>
            {((!isMultiImage && isSingleImageCount) || (isMultiImage && isMultiImageCount)) && (
              <IconContainer className="product-icons">
                <IconButton
                  type="button"
                  aria-label={t('media.carousel.aria.prev.button')}
                  className={`nav-prev media-grid-prev-${index}`}
                >
                  <ArrowTailRight className="arrow-icon" viewBox="5 4 6 14" />
                </IconButton>
                <IconButton
                  type="button"
                  aria-label={t('media.carousel.aria.next.button')}
                  className={` media-grid-next-${index}`}
                >
                  <ArrowTailLeft className="arrow-icon" viewBox="10 4 6 14" />
                </IconButton>
              </IconContainer>
            )}
          </TopContainer>
          <SwiperContainer
            modules={[Navigation, A11y]}
            navigation={{
              prevEl: `.media-grid-prev-${index}`,
              nextEl: `.media-grid-next-${index}`,
              disabledClass: 'swiper-button-disabled',
              lockClass: 'swiper-button-lock',
              enabled: !isLoading,
            }}
            a11y={{ slideRole: 'link' }}
            shortSwipes={!isDesktop}
            slidesPerView={isDesktop && !isMultiImage ? 3.2 : 'auto'}
            watchSlidesProgress
            spaceBetween={isDesktop && !isMultiImage ? 16 : multiImageSpace}
            breakpoints={isMultiImage ? productCarouselMultiImageSettings : undefined}
          >
            {carouselProducts &&
              carouselProducts.map((product, i) => (
                <SwiperSlideContainer key={`${product.productId}-${i}`} $isMultiImage={isMultiImage}>
                  <ProductItem
                    isLoading={isLoading}
                    isMultiImage={isMultiImage}
                    multiImageButtonText={multiImageButtonText}
                    handleOnClick={() => handleOnClick(product)}
                    {...product}
                  />
                </SwiperSlideContainer>
              ))}
          </SwiperContainer>
        </Container>
      )}
    </CarouselContainer>
  );
}
