import styled from 'styled-components';
import { TypographyStyles, colours, media, spacing, zStickyHeader } from '../../../stylings';

export const TopCategories = styled.nav`
  display: none;
  flex-shrink: 1;
  margin: 0;

  & > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media ${media.greaterThan('lg')} {
      column-gap: ${spacing.S};
      row-gap: ${spacing.XXXS};
    }

    & > li {
      white-space: nowrap;
    }
  }

  @media ${media.greaterThan('lg')} {
    display: block;
    width: 682px;
  }
`;

export const TopCategory = styled.li<{ $isDisabled?: boolean }>`
  align-items: center;
  display: flex;
  opacity: ${({ $isDisabled }) => $isDisabled && 0.5};
  pointer-events: ${({ $isDisabled }) => $isDisabled && 'none'};

  & > a {
    ${TypographyStyles.Label.Large.Medium}
    color: ${colours.BLACK};
    margin: 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    @media ${media.greaterThan('lg')} {
      font-size: 16px;
    }

    &:before {
      background-color: ${colours.BLACK};
      bottom: -4px;
      content: '';
      height: 4px;
      opacity: 0;
      position: absolute;
      transition: opacity 0.3s ease-in-out;
      width: 100%;

      @media screen and (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }
  }

  &.active {
    > a:before {
      opacity: 1;
    }
  }

  &:hover,
  &:focus {
    > a:before {
      opacity: 1;
    }
  }

  &.inactive {
    &:hover,
    &:focus {
      > a:before {
        opacity: 0;
      }
    }
  }
`;

export const MenuContainer = styled.div<{ $isVisible?: boolean }>`
  background: ${colours.WHITE};
  border-top: ${spacing.XXXXXS} solid rgba(0, 0, 0, 0.1);
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  float: left;
  left: 0;
  max-height: calc(100vh - 140px);
  min-height: 393px;
  overflow: auto;
  padding: 100px 0 140px;
  position: absolute;
  top: 80px;
  width: 100%;
  z-index: ${zStickyHeader};
`;
