import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type CreateAccountOnCheckoutType = {
  subscription_preferences: string;
};

export function sendCreateAccountOnCheckoutEvent(subscriptionPreferences: CreateAccountOnCheckoutType) {
  sendDataLayerEvent(
    EventNamesType.create_account_oncheckout,
    {
      custom_data: {
        ...subscriptionPreferences,
      },
    },
    false,
  );
}
