import type { StoreAddressType } from '../types/store';
import { getCountryName } from './localeHelper';

const storeLocatorUrl: Record<string, string> = {
  en: 'store-locator',
  de: 'filialfinder',
  nl: 'winkel-zoeken',
  fr: 'localisateur-magasin',
  es: 'localizador-tiendas',
  it: 'localizzatore-negozi',
};

export function getStoreLocatorBaseUrl(locale?: string) {
  const language = locale?.split('-')[0] || 'en';

  return storeLocatorUrl[language] || storeLocatorUrl.en;
}

export function getStoreLocatorLink(address: StoreAddressType, id: string, addLeadingSlash: boolean, locale?: string) {
  const { country, town } = address;

  const countryValue = getCountryName(country || '').replace(/\s/g, '');
  const townValue = town?.replace(/\s/g, '').toLocaleUpperCase();
  const baseUrl = getStoreLocatorBaseUrl(locale);

  return `${addLeadingSlash ? '/' : ''}${baseUrl}/${countryValue}/${townValue}/${id}`;
}
