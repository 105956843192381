import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

export function useTooltip() {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(buttonRef.current, containerRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-start', 'bottom-start', 'left-start', 'right-start'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [-30, 25],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: { left: 5, right: 15 },
        },
      },
    ],
  });

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const tooltipEl = containerRef.current;
      const buttonEl = buttonRef.current;
      const element = event.target as HTMLElement;

      if (!buttonEl?.contains(element) && !tooltipEl?.contains(element)) {
        setIsOpen(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);

    return () => window.removeEventListener('click', handleOutsideClick);
  }, []);

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return { isOpen, handleClick, buttonRef, containerRef, setArrowRef, styles, attributes };
}
