import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { VerifyEmailFormSchema } from '@shared/validation/src/storefront';
import { BlueButton } from '../../baseComponents/button';
import { FieldContainer } from '../../baseComponents/containers/FieldContainer';
import { Form } from '../../baseComponents/containers/Form';
import { SectionContainer } from '../../baseComponents/containers/SectionContainer';
import { ErrorMessage } from '../../baseComponents/errors';
import { InputField, PasswordField } from '../../baseComponents/inputs/Input';
import type { ConfirmEmailChangeInput, ConfirmEmailChangeResponse } from '../../codegen/types';
import type { BffError } from '../../services/errors';
import { TypographyStyles, colours, spacing } from '../../stylings';

const S = {
  SubmitButton: styled(BlueButton)`
    margin: 0;
  `,
  Header: styled.h4`
    ${TypographyStyles.Headings.H4SemiBold}
    color: ${colours.BLACK};
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  `,
  Description: styled.span`
    ${TypographyStyles.Body.Small.Regular}
    color: ${colours.BLACK};
    margin: 0;
    padding: 0;
  `,
  Form: styled(Form)`
    gap: ${spacing.XS};
  `,
};

type VerifyEmailFormValues = {
  currentEmail: string;
  password: string;
  code: string;
};

type VerifyEmailFormProps = {
  onSubmit: (input: ConfirmEmailChangeInput) => void;
  error: BffError | null;
  loading: boolean;
  data: ConfirmEmailChangeResponse | null;
};
export function VerifyEmailForm({ onSubmit, error, loading, data }: VerifyEmailFormProps) {
  const { t } = useTranslation('lib-global-common');
  const { query } = useRouter();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formError },
  } = useForm<VerifyEmailFormValues>({
    mode: 'all',
    resolver: yupResolver(VerifyEmailFormSchema),
    defaultValues: {
      code: (query.code as string) ?? '',
    },
  });

  const [emailValue, passwordValue] = useWatch({
    control,
    name: ['currentEmail', 'password'],
  });

  return (
    <SectionContainer>
      <S.Form data-testid="loginForm" onSubmit={handleSubmit(onSubmit)} noValidate>
        <S.Header>{t('validate.new.email.header')}</S.Header>
        <S.Description>{t('validate.new.email.description')}</S.Description>

        <FieldContainer>
          <InputField
            aria-label={t('validate.new.email.current.email')}
            placeholder={`${t('validate.new.email.current.email')}*`}
            type="email"
            aria-describedby="email-invalid"
            aria-invalid={!!formError.currentEmail}
            isInputError={!!formError.currentEmail}
            register={register('currentEmail')}
            isEmpty={!emailValue}
            testId="validateNewEmail"
            autoComplete="email"
            required
          />
          <ErrorMessage id="email-invalid" data-testid="validateNewEmailError" {...formError?.currentEmail}>
            {t(formError?.currentEmail?.message as string)}
          </ErrorMessage>
        </FieldContainer>

        <FieldContainer>
          <PasswordField
            aria-label={t('validate.new.email.current.password')}
            placeholder={t('validate.new.email.current.password')}
            aria-describedby="password-invalid"
            aria-invalid={!!formError.password}
            isInputError={!!formError.password}
            register={register('password')}
            isEmpty={!passwordValue}
            testId="validateNewEmailPassword"
            required
          />
          <ErrorMessage id="password-invalid" data-testid="validateNewEmailPasswordError" {...formError?.password}>
            {t(formError?.password?.message as string)}
          </ErrorMessage>
          {error && (
            <ErrorMessage id="validate-new-email-error" data-testid="validateNewEmailPasswordError">
              {t('login.password.error')}
            </ErrorMessage>
          )}
          {data?.errorNewEmailAlreadyInUse && (
            <ErrorMessage id="validate-new-email-already-used-error" data-testid="validateNewEmailAlreadyUsed">
              {t('validate.new.email.already.exist')}
            </ErrorMessage>
          )}

          {data?.errorNoEmailChangeRequestFound && (
            <ErrorMessage id="validate-new-email-no-request-found-error" data-testid="validateNewEmailNoRequestFound">
              {t('validate.new.email.no.request.found')}
            </ErrorMessage>
          )}
        </FieldContainer>
        <S.SubmitButton type="submit" disabled={loading} data-testid="validateNewEmailConfirm">
          {t('validate.new.email.confirm')}
        </S.SubmitButton>
      </S.Form>
    </SectionContainer>
  );
}
export default VerifyEmailForm;
