export const TextTransformMapper = {
  lowercase: 'lowercase',
  UPPERCASE: 'uppercase',
  Capitalize: 'capitalize',
};

export const TextAlignMapper = {
  left: 'left',
  center: 'center',
  right: 'right',
};
