import type { ParsedUrlQuery } from 'querystring';
import type { InstantSearchServerState } from 'react-instantsearch';
import {
  getIndexedStoresList,
  productFacetAllowedList,
  productSearchQueryAllowedList,
  storeKeyMapper,
} from '@shared/utils';
import type { Store } from '@shared/utils/dist';
import { getAlgoliaProductIndex } from '../services';

export const isAllowedAlgoliaAttribute = (attribute: string): boolean => {
  const allowedList = productSearchQueryAllowedList(getIndexedStoresList());
  return allowedList.includes(attribute);
};

export const createFacetAttributeNames = (locale = '') => [
  ...productFacetAllowedList([storeKeyMapper(locale) as Store]),
];

export const createInitialRefinementList = (query: ParsedUrlQuery | null, locale?: string) => {
  const initialRefinementList: { [key: string]: string[] } = {};
  createFacetAttributeNames(locale).forEach((attribute) => {
    initialRefinementList[attribute] = [];
  });

  if (!query) {
    return initialRefinementList;
  }

  // For only the configuration attributes that has locale
  const refinementEntries = Object.entries(query)
    .filter(([queryKey]) => queryKey !== 'query')
    .map((refinementQuery) => refinementQuery);

  const refinementKeys = new Set(refinementEntries.map(([key]) => key.split('[')[0]));

  if (refinementEntries.length === 0) {
    return initialRefinementList;
  }

  refinementKeys.forEach((refinementKey) => {
    const refinementValue = refinementEntries
      .filter(([key]) => key.split('[')[0] === refinementKey)
      .filter(([_, value]) => value)
      .map(([_, value]) => value as string);
    initialRefinementList[refinementKey] = refinementValue;
  });

  return initialRefinementList;
};

export const createAlgoliaServerState = ({
  locale,
  products,
  query,
}: {
  locale?: string;
  products: { [key: string]: unknown };
  query: ParsedUrlQuery;
}) => {
  const algoliaIndex = getAlgoliaProductIndex(locale || 'en');
  const facets = createFacetAttributeNames(locale);
  const numericRefinements = {
    'price.centAmount': {},
  };
  const price: {
    [key: string]: number[];
  } = {};

  if (query.minPrice) {
    price['>='] = [Number(query.minPrice)];
  }

  if (query.maxPrice) {
    price['<='] = [Number(query.maxPrice)];
  }
  numericRefinements['price.centAmount'] = price;
  const slugValue = (query.slug as string[]) || [];

  return {
    initialResults: {
      [algoliaIndex]: {
        state: {
          page: query.page,
          query: query.query,
          facets: createFacetAttributeNames(locale),
          facetsRefinements: createInitialRefinementList(query, locale),
          numericRefinements,
          index: algoliaIndex,
          disjunctiveFacets: facets,
          configure: {
            hitsPerPage: Number(query.pageSize),
            filters: [`categories.key: ${slugValue[slugValue.length - 1]?.split('-c')[0]?.toString()}`],
          },
        },
        results: [{ ...products }],
      },
    },
  } as unknown as InstantSearchServerState;
};

const heelHeightGroups: { [key: string]: { [key: string]: string } } = {
  flat: {
    'en-us': '0-1"',
    default: '0-2cm',
  },
  low: {
    'en-us': '1-1 \u00BE"',
    default: '2-4cm',
  },
  medium: {
    'en-us': '2-2 \u00BE"',
    default: '4-7cm',
  },
  high: {
    'en-us': '3-3 \u00BE"',
    default: '7-13cm',
  },
};

export const getHeelHeightGroupValue = (locale: string, label: string) =>
  heelHeightGroups[label][locale] || heelHeightGroups[label].default;
