import type { BaseHit } from 'instantsearch.js';
import type { MultiImageButtonText } from '@amplience/content-types/typings/c-trending-carousel';
import { useLocaleContext } from '../../../context/localeContext';
import { createProductHit } from '../../../utils/transformers';
import type { ProductCarouselItemProps } from '../productCarouselItem/ProductCarouselItem';
import AlgoliaCarouselMultiImage from './AlgoliaCarouselMultiImage';
import AlgoliaCarouselSingleImage from './AlgoliaCarouselSingleImage';

export type CarouselItemProps = {
  isLoading?: boolean;
  algoliaIndexName: string;
  position: number;
  isMultiImage?: boolean;
  multiImageButtonText?: MultiImageButtonText;
  item: BaseHit;
  itemGender: string[];
  carouselTitle?: string;
  isPDP?: boolean;
  handleOnClick?: () => void;
};

export type CarouselLayoutItemProps = ProductCarouselItemProps & {
  algoliaIndexName: string;
  position: number;
  itemGender: string[];
  mainCategory?: string;
  productColor?: string;
  carouselTitle?: string;
  isPDP?: boolean;
};

export function AlgoliaCarouselItem({
  isLoading,
  algoliaIndexName,
  position,
  item,
  isMultiImage,
  multiImageButtonText,
  carouselTitle,
  isPDP,
}: Omit<CarouselItemProps, 'itemGender'>) {
  const { locale } = useLocaleContext();
  const {
    price,
    wasPrice,
    thenPrice,
    name,
    image,
    slug,
    colors,
    color,
    gender,
    objectID,
    rangeGroupDescription,
    category,
    primaryCategory,
    ...rest
  } = createProductHit({
    item,
    locale,
  });
  const priceObject = [{ actualPrice: price, wasPrice, thenPrice }];
  if (isMultiImage) {
    return (
      <AlgoliaCarouselMultiImage
        isLoading={isLoading}
        algoliaIndexName={algoliaIndexName}
        position={position}
        primaryCategory={primaryCategory}
        productKey={objectID}
        categoryName={rangeGroupDescription}
        imageUrls={image}
        prices={priceObject}
        productName={name}
        slug={slug}
        colors={colors}
        itemGender={gender}
        multiImageButtonText={multiImageButtonText}
        sku={objectID}
        mainCategory={category}
        productColor={color}
        carouselTitle={carouselTitle}
        isPDP={isPDP}
        {...rest}
      />
    );
  }

  return (
    <AlgoliaCarouselSingleImage
      isLoading={isLoading}
      algoliaIndexName={algoliaIndexName}
      position={position}
      primaryCategory={primaryCategory}
      productKey={objectID}
      categoryName={rangeGroupDescription}
      imageUrls={image}
      prices={priceObject}
      productName={name}
      slug={slug}
      itemGender={gender}
      sku={objectID}
      mainCategory={category}
      productColor={color}
      carouselTitle={carouselTitle}
      isPDP={isPDP}
      {...rest}
    />
  );
}

export default AlgoliaCarouselItem;
