import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';
import {
  AddressesFragmentDoc,
  CartDetailsFragmentDoc,
  DeliveriesFragmentDoc,
  DeliveryInstructionsFragmentDoc,
  PaymentsFragmentDoc,
} from '../fragment.generated';

const defaultOptions = {} as const;
export type CreateCartMutationVariables = Types.Exact<{
  input: Types.CreateCartInput;
}>;

export type CreateCartMutation = {
  __typename?: 'Mutation';
  createCart: {
    __typename?: 'Cart';
    id: string;
    guid: string;
    version: number;
    anonymousId?: string | null;
    customerEmail?: string | null;
    customerId?: string | null;
    subtotal: number;
    deliveryTotal: number;
    discountTotal: number;
    taxes?: number | null;
    idMeDiscount?: number | null;
    isDigitalCart: boolean;
    total: number;
    currency: string;
    hasFreeDelivery: boolean;
    shippingType?: string | null;
    active?: boolean | null;
    errors: Array<
      | {
          __typename?: 'DiscountCodeError';
          validUntil?: any | null;
          validFrom?: any | null;
          errorCode: Types.DiscountCodeErrorCode;
          discountCode: string;
        }
      | { __typename?: 'LineItemError'; lineItemId: string }
    >;
    discountCodes?: Array<{
      __typename?: 'DiscountCode';
      discountCode: string;
      discountId: string;
      discountName?: string | null;
      discountValue?: number | null;
    } | null> | null;
    lineItems: Array<{
      __typename?: 'LineItem';
      id: string;
      brand?: string | null;
      productId: string;
      quantity: number;
      productKey?: string | null;
      productSlug?: string | null;
      name: string;
      imageURLs: Array<string>;
      size?: string | null;
      colour?: string | null;
      fit?: string | null;
      fitLabel?: string | null;
      discounts: Array<string>;
      isLastItem?: boolean | null;
      isOutOfStock?: boolean | null;
      productTypeKey?: string | null;
      giftCardRecipient?: string | null;
      giftCardMessage?: string | null;
      giftCardSender?: string | null;
      giftCardRecipientEmail?: string | null;
      giftCardOccasion?: string | null;
      availableQuantity?: number | null;
      backInStockNotificationsEnabled: boolean;
      variant: { __typename?: 'CartVariant'; id?: number | null; sku?: string | null };
      finalSale?: {
        __typename?: 'FinalSaleAttributes';
        backgroundColor?: string | null;
        emailText?: string | null;
        isFinalSale?: boolean | null;
        text?: string | null;
        textColor?: string | null;
      } | null;
      discountsDescriptions: Array<{
        __typename?: 'DiscountDescription';
        type?: Types.DiscountType | null;
        description?: string | null;
        shortDescription?: string | null;
      }>;
      badges: Array<{ __typename?: 'LineItemBadge'; code: string; type?: string | null }>;
      primaryCategory?: { __typename?: 'PrimaryCategoryObject'; key?: string | null; name?: string | null } | null;
      price: {
        __typename?: 'LineItemPrice';
        discountedPercentage?: number | null;
        wasPriceValidFrom?: string | null;
        wasPriceValidUntil?: string | null;
        thenPriceValidFrom?: string | null;
        thenPriceValidUntil?: string | null;
        value: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
        wasPrice?: {
          __typename?: 'PriceInfo';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
        } | null;
        thenPrice?: {
          __typename?: 'PriceInfo';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
        } | null;
        discountedPrice?: {
          __typename?: 'PriceInfo';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
        } | null;
        totalDiscountedPrice?: {
          __typename?: 'PriceInfo';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
        } | null;
      };
      totalPrice: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
      taxedPrice?: {
        __typename?: 'TaxedPrice';
        totalGross: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
        totalNet: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
        totalTax?: {
          __typename?: 'PriceInfo';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
        } | null;
      } | null;
      taxRate?: { __typename?: 'TaxRate'; amount: number } | null;
    }>;
    billingAddress?: {
      __typename?: 'CartAddress';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      company?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      postalCode?: string | null;
      country: string;
      state?: string | null;
      key?: string | null;
      id?: string | null;
      countryAlias?: string | null;
    } | null;
    deliveryAddress?: {
      __typename?: 'CartAddress';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      company?: string | null;
      line1?: string | null;
      line2?: string | null;
      city?: string | null;
      postalCode?: string | null;
      country: string;
      state?: string | null;
      key?: string | null;
      id?: string | null;
      countryAlias?: string | null;
    } | null;
    deliveryMethod?: {
      __typename?: 'DeliveryMethod';
      description: string;
      key: string;
      name: string;
      shippingType?: string | null;
      price: number;
      deliveryTime?: string | null;
    } | null;
    deliveries?: Array<{
      __typename?: 'Delivery';
      deliveryDate: string;
      storeName?: string | null;
      deliveryStoreId?: string | null;
      deliveryName?: string | null;
      fulfillmentNodeType?: string | null;
      serviceLevelIdentifier?: string | null;
      lineItems: Array<{ __typename?: 'LineItemIdentifier'; id: string }>;
    }> | null;
  };
};

export const CreateCartDocument = gql`
  mutation createCart($input: CreateCartInput!) {
    createCart(input: $input) {
      ...CartDetails
    }
  }
  ${CartDetailsFragmentDoc}
`;
export type CreateCartMutationFn = Apollo.MutationFunction<CreateCartMutation, CreateCartMutationVariables>;

/**
 * __useCreateCartMutation__
 *
 * To run a mutation, you first call `useCreateCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCartMutation, { data, loading, error }] = useCreateCartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCartMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCartMutation, CreateCartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCartMutation, CreateCartMutationVariables>(CreateCartDocument, options);
}
export type CreateCartMutationHookResult = ReturnType<typeof useCreateCartMutation>;
export type CreateCartMutationResult = Apollo.MutationResult<CreateCartMutation>;
export type CreateCartMutationOptions = Apollo.BaseMutationOptions<CreateCartMutation, CreateCartMutationVariables>;
