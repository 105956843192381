import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useRouter } from 'next/router';
import type { SiteSwitchResponse } from '../codegen/types';
import { useLocaleContext } from '../context/localeContext';
import { useStaticContext } from '../context/staticContext';
import { Cookies } from '../utils/cookies';
import { getLanguageFromLocale, getLocale } from '../utils/localeHelper';

function sortCountries(countryA: SiteSwitchResponse, countryB: SiteSwitchResponse) {
  return getLocale(countryA.countryName)?.value.localeCompare(getLocale(countryB.countryName)?.value ?? '') ?? 1;
}

export const useSiteSwitcher = () => {
  const router = useRouter();

  const { locale } = useLocaleContext();
  const { switchSitesData } = useStaticContext();

  const [selectedCountry, setSelectedCountry] = useState<SiteSwitchResponse | null | undefined>(null);
  const [dialogSelectedCountry, setDialogSelectedCountry] = useState<SiteSwitchResponse | null | undefined>(null);
  const [dialogSelectedLanguage, setDialogSelectedLanguage] = useState<string | undefined>();

  const siteSwitcherSortedData = useMemo(() => switchSitesData?.slice().sort(sortCountries) ?? null, [switchSitesData]);

  const onChangeCountry = useCallback(
    (ev: ChangeEvent<HTMLSelectElement>) => {
      const country = siteSwitcherSortedData?.find(({ key }) => ev.target.value === key);
      setDialogSelectedCountry(country);
    },
    [siteSwitcherSortedData],
  );

  const updateDefaultLocaleCookie = useCallback(() => {
    Cookies.set('defaultLocale', dialogSelectedLanguage, { path: '/', secure: true, sameSite: true });
  }, [dialogSelectedLanguage]);

  const updatePreferredSiteCookie = useCallback(() => {
    Cookies.set('preferredSite', dialogSelectedLanguage?.toLowerCase(), { path: '/', secure: true, sameSite: true });
  }, [dialogSelectedLanguage]);

  const onChangeLanguage = useCallback((ev: ChangeEvent<HTMLSelectElement>) => {
    setDialogSelectedLanguage(ev.target.value);
  }, []);

  const onUpdatePreferences = useCallback(() => {
    updatePreferredSiteCookie();
    router.push('/', '/', { locale: dialogSelectedLanguage?.toLowerCase() });
  }, [dialogSelectedLanguage, router, updatePreferredSiteCookie]);

  useEffect(() => {
    const country = siteSwitcherSortedData?.find(
      (siteCountry) => !!siteCountry.languages.find((lang) => lang.toLowerCase() === locale),
    );
    setSelectedCountry(country);
    setDialogSelectedCountry(country);
  }, [siteSwitcherSortedData, locale]);

  useEffect(() => {
    const defaultSelectLanguage =
      dialogSelectedCountry?.languages.find((lang) => lang.toLowerCase() === locale) ??
      dialogSelectedCountry?.languages[0];

    setDialogSelectedLanguage(defaultSelectLanguage);
  }, [dialogSelectedCountry, locale]);

  useEffect(() => {
    // Update the page language when switching between locales
    const setLanguageFromLocale = () => {
      document.documentElement.lang = getLanguageFromLocale(locale);
    };

    router.events.on('routeChangeComplete', setLanguageFromLocale);

    return () => {
      router.events.off('routeChangeComplete', setLanguageFromLocale);
    };
  }, [locale, router.events]);

  return {
    selectedCountry,
    dialogSelectedCountry,
    dialogSelectedLanguage,
    onChangeCountry,
    onChangeLanguage,
    onUpdatePreferences,
    siteSwitcherSortedData,
    locale,
    updateDefaultLocaleCookie,
    updatePreferredSiteCookie,
  };
};
