import styled from 'styled-components';
import { media } from '../../../stylings';

export const ImageBadgeContainer = styled.div<{ $pdpBadge?: boolean }>`
  aspect-ratio: 1 / 1;
  height: auto;
  position: absolute;
  right: 0px;
  top: 8px;
  width: 36px;
  z-index: 3;

  @media ${media.greaterThan('lg')} {
    top: 16px;
    width: 60px;
  }

  ${({ $pdpBadge }) =>
    $pdpBadge &&
    `
      top: 20px;
      width: 60px;

      @media ${media.greaterThan('lg')} {
        height: auto;
        top: 40px;
        width: 70px;
      }
    `}
`;
