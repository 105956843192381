import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { LineItem } from '../../../codegen/types';
import { useCartContext } from '../../../context/cartContext';
import { useValidateAndUpdateCartItemsStockMutation } from '../../../graphqlDocument/cart/validateAndUpdateCartItemsStock.generated';
import { useNRBASession } from '../../../hooks/useNRBASession';
import { getCtLocale } from '../../../utils/localeHelper';
import { getItem, removeItem } from '../../../utils/storage';
import { DeletedProductLineItem } from './DeletedProductLineItem';
import { ProductLineItem } from './ProductLineItem';
import type { ProductLineItemType } from './ProductLineItemImages';
import { ProductsList, ProductsListItem } from './ProductLineItemList.style';

export type LineItemStatus = {
  index: number;
  lineItem: LineItem;
  isDeleted: boolean;
};

const buildLineItemStatus = (lineItems: LineItem[]) => {
  const statuses: LineItemStatus[] = lineItems?.map((lineItem, index) => ({
    index,
    lineItem,
    isDeleted: false,
  }));

  return statuses;
};

export type ProductLineItemListProps = {
  isStar2?: boolean;
  lineItems: LineItem[];
  type: ProductLineItemType.MULTI_IMAGE | ProductLineItemType.SINGLE_IMAGE;
  handlePDPLinkFromCheckout?: (pdpLink: string) => void;
};

export enum ValidateStockStatusType {
  QUANTITY_ADJUSTED = 'quantityAdjusted',
  OUT_OF_STOCK = 'outOfStock',
}

export function ProductLineItemList({
  lineItems,
  type,
  isStar2 = false,
  handlePDPLinkFromCheckout,
}: ProductLineItemListProps) {
  const { locale } = useRouter();
  const { cartId, refetchCart, cartInputVariables, anonymousId } = useCartContext();

  const nrbaContextHeader = useNRBASession();

  const [lineItemStatus, setLineItemStatus] = useState<LineItemStatus[]>(buildLineItemStatus(lineItems));
  const [validateAndUpdateCartItemsStockMutation, { data }] =
    useValidateAndUpdateCartItemsStockMutation(nrbaContextHeader);

  const { validateAndUpdateCartItemsStock } = { ...data };
  const { outOfStockLineItemsIds, quantityAdjustedLineItemsIds } = { ...validateAndUpdateCartItemsStock };

  useEffect(() => {
    setLineItemStatus((prev) =>
      prev?.map((item, index) =>
        lineItems[index]?.id === item.lineItem.id ? { ...item, index, lineItem: lineItems[index] } : item,
      ),
    );
  }, [lineItems]);

  // For each item in the previous state, we find the corresponding item in the new lineItems array by matching their ids.
  // Then we compare their totalDiscountedPrice and update the item if it has changed.
  useEffect(() => {
    setLineItemStatus((prev) =>
      prev.map((item) => {
        const newItem = lineItems.find((li) => li.id === item.lineItem.id);
        if (newItem && newItem.price.totalDiscountedPrice !== item.lineItem.price.totalDiscountedPrice) {
          return { ...item, lineItem: newItem };
        }
        return item;
      }),
    );
  }, [lineItems]);

  useEffect(() => {
    async function validateAndUpdateCartItems() {
      await validateAndUpdateCartItemsStockMutation({
        variables: {
          input: {
            cartId,
            locale: getCtLocale(locale),
            anonymousId,
          },
        },
        onCompleted: () => refetchCart(cartInputVariables),
      });
    }

    const shouldValidate = getItem('VALIDATE_UPDATE_CART', true, 'localStorage');

    if (shouldValidate) {
      validateAndUpdateCartItems();
      removeItem('VALIDATE_UPDATE_CART', 'localStorage');
    }
  }, [cartId, locale, refetchCart, validateAndUpdateCartItemsStockMutation, cartInputVariables, anonymousId]);

  return (
    <ProductsList>
      {lineItemStatus?.map((cartItem) => {
        let validateAndUpdateStockStatus: ValidateStockStatusType | undefined;
        if (quantityAdjustedLineItemsIds?.includes(cartItem.lineItem.id))
          validateAndUpdateStockStatus = ValidateStockStatusType.QUANTITY_ADJUSTED;
        if (outOfStockLineItemsIds?.includes(cartItem.lineItem.id))
          validateAndUpdateStockStatus = ValidateStockStatusType.OUT_OF_STOCK;
        return (
          <ProductsListItem key={cartItem.lineItem.id}>
            {cartItem.isDeleted ? (
              <DeletedProductLineItem
                lineItem={cartItem.lineItem}
                type={type}
                lineItemStatus={lineItemStatus}
                setLineItemStatus={setLineItemStatus}
              />
            ) : (
              <ProductLineItem
                lineItem={cartItem.lineItem}
                type={type}
                setLineItemStatus={setLineItemStatus}
                validateAndUpdateStockStatus={validateAndUpdateStockStatus}
                isStar2={isStar2}
                handlePDPLinkFromCheckout={handlePDPLinkFromCheckout}
              />
            )}
          </ProductsListItem>
        );
      })}
    </ProductsList>
  );
}
