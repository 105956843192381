import { Fragment } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import type { Order, Shipment, Tracking } from '../../codegen/types';
import { useLocaleContext } from '../../context/localeContext';
import { TypographyStyles, colours, fontWeights, media, spacing } from '../../stylings';
import { deliveryDetailsDate } from '../../utils/dateUtils';
import { getCountryName } from '../../utils/localeHelper';

const mediumBold = css`
  .name {
    ${fontWeights.Medium500}
  }
`;

const S = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    left: -${spacing.S};
    margin-top: ${spacing.XXXS};
    padding: ${spacing.XS} ${spacing.S};
    position: relative;
    width: calc(100% + 48px);
    @media ${media.greaterThan('lg')} {
      border-top: solid 1px ${colours.BLACK};
      left: 0;
      padding: 0;
      padding-top: 31px;
      width: initial;
    }
  `,
  Title: styled.h4`
    ${TypographyStyles.Headings.H4SemiBold}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  `,
  DeliveryPartLabel: styled.p`
    ${TypographyStyles.Body.Small.SemiBold}
    margin: 0;
    padding: 0;
  `,
  DeliveryDetails: styled.div`
    ${TypographyStyles.Body.Small.SemiBold}
    display: flex;
    flex-direction: column;
    p {
      ${TypographyStyles.Body.Small.Regular}
    }
    span {
      ${TypographyStyles.Body.Small.Medium}
    }
  `,
  Products: styled.p`
    ${mediumBold}
    margin: 0;
    padding: 0;
  `,
  ShippingAddress: styled.p`
    ${TypographyStyles.Body.Small.Regular}
    ${mediumBold}
    margin: 0;
    padding: 0;
    span {
      ${TypographyStyles.Body.Small.Medium}
    }
  `,
  DeliveryMethod: styled.p`
    ${mediumBold}
    margin: 0;
    padding: 0;
  `,
  DeliveryDate: styled.p`
    ${mediumBold}
    margin: 0;
    padding: 0;
  `,
  NotificationMethod: styled.p`
    ${TypographyStyles.Body.Small.Regular}
    ${mediumBold}
    margin: 0;
    padding: 0;
    span {
      ${TypographyStyles.Body.Small.Medium}
    }
  `,
};

type DeliveryPartDetailProps = {
  products: string;
  deliveryMethod: string;
  deliveryDate: string;
};

function DeliveryPartDetail({ products, deliveryMethod, deliveryDate }: DeliveryPartDetailProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();
  return (
    <S.DeliveryDetails>
      <S.Products>
        <span className="name">{t('order.details.delivery.details.products')}</span>: {products}
      </S.Products>
      <S.DeliveryMethod>
        <span className="name">{t('order.details.delivery.details.shipping.method')}</span>: {deliveryMethod}
      </S.DeliveryMethod>
      <S.DeliveryDate>
        <span className="name">{t('order.details.delivery.details.delivery.date')}</span>:{' '}
        {t('order.details.delivery.details.expected.delivery', { date: deliveryDetailsDate(deliveryDate, locale, t) })}
      </S.DeliveryDate>
    </S.DeliveryDetails>
  );
}

export function DeliveryDetails({ summary, shipments }: { summary: Order['summary']; shipments: Shipment[] }) {
  const { t } = useTranslation(['lib-global-common']);

  if (!summary) return null;

  const shipmentsLength = shipments?.length ?? 0;
  const productNames =
    shipments
      ?.map((shipment) => shipment.trackings)?.[0]
      ?.map((tracking) => tracking?.items)?.[0]
      ?.map((item) => item?.name)
      .join(', ') ?? '';
  const singleDeliveryDate = shipments?.[0]?.deliveryExpectedDate ?? '';
  const { deliveryAddress, deliveryMethod } = summary;
  const shippingAddress = [
    deliveryAddress?.line1,
    deliveryAddress?.line2,
    deliveryAddress?.city,
    deliveryAddress?.state,
    getCountryName(deliveryAddress?.country as string),
    deliveryAddress?.postalCode,
  ]
    .filter((text) => !!text)
    .join(', ');

  return (
    <S.Container>
      <S.Title>{t('order.details.delivery.details.title')}</S.Title>
      <S.ShippingAddress>
        <span className="name">{t('order.details.delivery.details.shipping.address')}</span>: <br />
        {deliveryMethod?.key.toLowerCase().includes('email') ? deliveryMethod.name : shippingAddress}
      </S.ShippingAddress>
      {shipmentsLength ? (
        shipments?.map((delivery, idx) => {
          const products = (delivery?.trackings as Tracking[])?.[0]?.items?.map((item) => item?.name).join(', ') ?? '';
          const trackingDeliveryMethod = delivery?.trackings?.[0]?.carrierService ?? '';
          return (
            <Fragment key={idx}>
              {!!shipmentsLength && (
                <S.DeliveryPartLabel>
                  <Trans
                    t={t}
                    i18nKey="order.details.delivery.details.delivery.part.of"
                    values={{
                      part: idx + 1,
                      shipmentsLength,
                    }}
                  />
                </S.DeliveryPartLabel>
              )}
              <DeliveryPartDetail
                products={products}
                deliveryMethod={trackingDeliveryMethod}
                deliveryDate={delivery?.deliveryExpectedDate as string}
              />
            </Fragment>
          );
        })
      ) : (
        <DeliveryPartDetail
          products={productNames}
          deliveryMethod={deliveryMethod?.name ?? ''}
          deliveryDate={singleDeliveryDate}
        />
      )}
      <S.NotificationMethod>
        <span className="name">{t('order.details.delivery.details.notification.method')}</span>:{' '}
        {deliveryAddress?.phone}
      </S.NotificationMethod>
    </S.Container>
  );
}
