import styled, { css } from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

export const PriceWrapper = styled.div<{ $isBtsProductPriceRangeEnabled: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.XXXS};
  margin-bottom: 2px;
  row-gap: 0;

  & .was-price,
  & .then-price {
    ${TypographyStyles.Body.Medium.RegularStrikethrough}
  }

  & .was-price {
    color: ${colours.GREY};
  }

  & .now-price {
    ${TypographyStyles.Body.Medium.SemiBold}
  }

  ${(props) =>
    props.$isBtsProductPriceRangeEnabled &&
    css`
      & .was-price,
      & .then-price {
        font-size: 12px;

        @media ${media.greaterThan('md')} {
          font-size: 16px;
        }
        @media ${media.greaterThan('lg')} {
          font-size: 14px;
        }
      }
      & .now-price,
      & .price-range {
        ${TypographyStyles.Body.Medium.SemiBold}
        font-size: 14px;

        @media ${media.greaterThan('md')} {
          font-size: 18px;
        }

        @media ${media.greaterThan('lg')} {
          font-size: 16px;
        }
      }
    `}

  @media ${media.greaterThan('lg')} {
    margin-bottom: 3px;
  }
`;
