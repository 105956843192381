import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetSwitchSitesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSwitchSitesQuery = {
  __typename?: 'Query';
  siteSwitch: Array<{
    __typename?: 'SiteSwitchResponse';
    key: string;
    languages: Array<string>;
    currency: string;
    currencySymbol: string;
    countryName: Array<{ __typename?: 'StoreLocalizedString'; locale: string; value: string }>;
  }>;
};

export const GetSwitchSitesDocument = gql`
  query getSwitchSites {
    siteSwitch {
      key
      languages
      currency
      currencySymbol
      countryName {
        locale
        value
      }
    }
  }
`;

/**
 * __useGetSwitchSitesQuery__
 *
 * To run a query within a React component, call `useGetSwitchSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwitchSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwitchSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSwitchSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSwitchSitesQuery, GetSwitchSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSwitchSitesQuery, GetSwitchSitesQueryVariables>(GetSwitchSitesDocument, options);
}
export function useGetSwitchSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSwitchSitesQuery, GetSwitchSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSwitchSitesQuery, GetSwitchSitesQueryVariables>(GetSwitchSitesDocument, options);
}
export type GetSwitchSitesQueryHookResult = ReturnType<typeof useGetSwitchSitesQuery>;
export type GetSwitchSitesLazyQueryHookResult = ReturnType<typeof useGetSwitchSitesLazyQuery>;
export type GetSwitchSitesQueryResult = Apollo.QueryResult<GetSwitchSitesQuery, GetSwitchSitesQueryVariables>;
