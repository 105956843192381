import type { AllHTMLAttributes, ElementType } from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { colours, skeletonPulse } from '../../stylings';

const S = {
  Skeleton: styled.div<{
    $aspectRatio?: string;
    $width?: number;
    $height?: number;
    $forceMount?: boolean;
  }>`
    &[data-loading='true'] {
      ${({ $aspectRatio, $height, $width, $forceMount }) =>
        !$forceMount &&
        css`
          animation: ${skeletonPulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          aspect-ratio: ${$aspectRatio};
          background: ${colours.LIGHT_GREY};
          border-radius: 5px;
          height: ${$height && `${$height}px`};
          width: ${$width ? `${$width}px` : '100%'};

          @media (prefers-reduced-motion) {
            animation: none;
          }
        `}

      pointer-events: none;
      user-select: none;
    }
  `,
};

type SkeletonProps = Partial<{
  tag: ElementType;
  count: number;
  forceMount: boolean;
  isLoading: boolean;
  aspectRatio: string;
  skeletonWidth: number;
  skeletonHeight: number;
}>;

export const Skeleton = forwardRef<HTMLElement, SkeletonProps & AllHTMLAttributes<HTMLElement>>(
  (
    {
      tag = 'div',
      count = 1,
      forceMount,
      isLoading,
      aspectRatio,
      skeletonWidth: width,
      skeletonHeight: height,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <>
      {Array.from({ length: count }, (_, i) => (
        <S.Skeleton
          key={i}
          ref={ref}
          {...(!isLoading && { ...props })}
          {...(isLoading && { 'aria-hidden': 'true', 'data-loading': isLoading })}
          as={isLoading ? 'div' : tag}
          $forceMount={forceMount}
          $aspectRatio={aspectRatio}
          $width={width}
          $height={height}
          className={className}
        >
          {(!isLoading || forceMount) && children}
        </S.Skeleton>
      ))}
    </>
  ),
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
