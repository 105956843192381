import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { BlueButton } from '../../baseComponents/button';
import { DialogContent, DialogOverlay, DialogPortal, DialogRoot } from '../../baseComponents/dialog';
import type { RedirectInformation } from '../../codegen/types';
import { RedirectType } from '../../codegen/types';
import { useLocaleContext } from '../../context';
import { TypographyStyles, media, spacing } from '../../stylings';
import { Cookies } from '../../utils/cookies';
import { handleCountryTranslations } from '../../utils/countryTranslations';
import { STAR2LocalesWithMultipleLanguages, localeToUpperCase } from '../../utils/localeHelper';
import { CountryFlag } from '../countryFlag/CountryFlag';

const S = {
  Content: styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: ${spacing.M};
    max-height: 85vh;
    max-width: 85vw;
    padding: ${spacing.M};
    width: 430px;
  `,
  Title: styled.h3`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  `,
  CopyText: styled.span`
    ${TypographyStyles.Body.Small.Regular}
    margin: 0;
    padding: 0;
  `,
  DialogBodyWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.S};
  `,
  LabelText: styled.span`
    ${TypographyStyles.Label.Large.Medium}
    text-transform: uppercase;
  `,
  Visit: styled(BlueButton)`
    margin-bottom: 0;
    width: 100%;
  `,
  Header: styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    @media ${media.greaterThan('lg')} {
      gap: ${spacing.XS};
    }
  `,
  FlagIcon: styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      height: 50px;
      width: 50px;

      @media ${media.greaterThan('lg')} {
        height: 60px;
        width: 60px;
      }
    }

    &:empty {
      display: none;
    }
  `,
};

export function RedirectPopUp({ redirectInfo }: { redirectInfo: RedirectInformation | null }) {
  const router = useRouter();
  const { t } = useTranslation(['lib-global-common']);
  const { locale: currLocale } = useLocaleContext();

  const [showPopUp, setShowPopUp] = useState(false);
  const [visit, setVisit] = useState(false);
  const [userLanguage, setUserLanguage] = useState('');

  useEffect(() => {
    const browserLanguage = navigator.language;
    const language = browserLanguage.includes('-') ? browserLanguage.split('-')[0] : browserLanguage;
    setUserLanguage(language);
  }, []);

  useEffect(() => {
    setShowPopUp(!!redirectInfo);
  }, [redirectInfo]);

  if (!redirectInfo) return null;

  const { type: redirectType, url, hint } = redirectInfo;

  const currentCountryCode = currLocale.split('-')[1];

  if (currentCountryCode.toLowerCase() === hint?.toLowerCase() || '') return null;

  const currentCountry = handleCountryTranslations(t, currentCountryCode);
  const hintCountry = handleCountryTranslations(t, hint as string);

  const togglePopUp = () => {
    setShowPopUp((prev) => !prev);
  };

  const updateDefaultLocaleCookie = (locale: string) => {
    Cookies.set('defaultLocale', localeToUpperCase(locale), {
      path: '/',
      secure: true,
      sameSite: true,
    });
  };
  const handleExitModal = () => {
    if (visit) return;
    updateDefaultLocaleCookie(router.locale as string);
  };

  const onVisit = () => {
    const supposedLocaleForRedirect = userLanguage.concat('-', hint as string).toLocaleLowerCase();

    const redirectLocale = STAR2LocalesWithMultipleLanguages.includes(supposedLocaleForRedirect)
      ? supposedLocaleForRedirect
      : url;

    if (redirectType === RedirectType.INTERNAL) {
      router.replace(
        {
          pathname: router.asPath,
        },
        undefined,
        {
          locale: redirectLocale,
        },
      );

      updateDefaultLocaleCookie(localeToUpperCase(redirectLocale));
      setVisit(true);
      togglePopUp();
    } else {
      window.location.replace(redirectLocale);
    }
  };

  return (
    <DialogRoot onOpenChange={togglePopUp} open={showPopUp}>
      <DialogPortal>
        <DialogOverlay />
        <S.Content
          data-testid="redirectPopup"
          onEscapeKeyDown={handleExitModal}
          onCloseAutoFocus={handleExitModal}
          onInteractOutside={handleExitModal}
        >
          <S.Header>
            <S.FlagIcon>
              <CountryFlag country={(hint || '').toLowerCase()} viewBox="0 -10 200 210" />
            </S.FlagIcon>
            <S.Title>{t('redirect.popup.title', { country: hintCountry })}</S.Title>
          </S.Header>
          <S.CopyText>
            {t('redirect.popup.copy.text', {
              currentCountry,
              country: hintCountry,
            })}
          </S.CopyText>
          <S.Visit onClick={onVisit}>{t('redirect.popup.visit', { country: hintCountry })}</S.Visit>
        </S.Content>
      </DialogPortal>
    </DialogRoot>
  );
}

export default RedirectPopUp;
