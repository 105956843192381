import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { fromCents } from '@shared/utils/src/price/money';
import { useCartContext } from '../../../context/cartContext';
import { useCheckoutConfirmationContext } from '../../../context/checkoutConfirmationContext';
import { useLocaleContext } from '../../../context/localeContext';
import { usePaymentContext } from '../../../context/paymentContext';
import { useCancelGiftCardOrder } from '../../../hooks/useCancelGiftCardOrder';
import { Delete } from '../../../icons';
import { getCurrencySymbol } from '../../../utils/currencyHelper';
import { Locale } from '../../../utils/localeHelper';
import { CartDelivery } from './CartDelivery';
import { VerifyWithIDme } from './IDme';
import { Amount, Container, Label, Total, TrashButton, VatLabel } from './TaxAndTotals.styles';
import { TaxEstimation } from './TaxEstimation';

function Details({
  currency,
  amount,
  label,
  className,
  vatLabel,
  locale,
  isTotal = false,
  isGiftCard = false,
  testId,
  isDiscount = false,
}: {
  currency: string;
  amount: string;
  label: string;
  className?: string;
  vatLabel?: string;
  locale?: string;
  isTotal?: boolean;
  testId?: string;
  isGiftCard?: boolean;
  isDiscount?: boolean;
}) {
  const router = useRouter();
  const { t } = useTranslation(['app-orders-common']);

  const allowedLocale = [Locale.CT_OUTLET_LOCALE, Locale.EN_GB, Locale.EN_IE].includes(locale as string);
  const isDeliveryPage = router.asPath === '/checkout/delivery';
  const isPaymentPage = router.asPath === '/checkout/payment';
  const isConfirmationPage = router.asPath === '/checkout/confirmation';
  const isVatLabel = allowedLocale && (isDeliveryPage || isPaymentPage || isConfirmationPage);
  const { paymentContainer } = usePaymentContext();

  const goToGiftCard = () => paymentContainer?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });

  return (
    <Label className={className}>
      {isGiftCard ? (
        <TrashButton type="button" onClick={goToGiftCard} aria-label={t('checkout.giftcard.scroll')}>
          <Delete />
        </TrashButton>
      ) : null}
      <span>
        {isTotal ? <Total>{label}</Total> : label} {isVatLabel && <VatLabel>{vatLabel}</VatLabel>}
      </span>
      <Amount $isTotal={isTotal} data-testid={testId} $isDiscount={isDiscount}>
        {currency}
        {amount}
      </Amount>
    </Label>
  );
}

export function TaxAndTotals() {
  const router = useRouter();

  const { miniCart, currency } = useCartContext();
  const { subtotal, total, discountTotal, taxes, lineItems } = { ...miniCart };
  const { t } = useTranslation(['lib-global-common', 'app-orders-common']);
  const { locale } = useLocaleContext();
  const { isConfirmationPage, orderData } = useCheckoutConfirmationContext();
  const { cancelGiftCardOrder } = useCancelGiftCardOrder();
  const { getOrderedCart: orderedCart } = { ...orderData };
  const {
    giftCardOrder,
    isGiftCardEnough,
    isGiftCardRemoved,
    setIsGiftCardEnough,
    setIsAddAnotherGiftCard,
    setIsGiftCardRemoved,
    setGiftCardTotal,
    setGiftCardValue,
    setGiftCardStatus,
    setIsErrorGiftCardInput,
    setOnErrorGiftCard,
    setCanPayWithGiftCards,
    setGiftCardOrder,
  } = usePaymentContext();

  const remainingAmountToPay = Number(fromCents(giftCardOrder?.remainingAmount?.value));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const orderTotalFromGiftCard = Number(fromCents((giftCardOrder as any)?.amount?.value));
  const totalToPay = orderTotalFromGiftCard - remainingAmountToPay;
  const isCheckout = router.asPath.includes('/checkout');
  const isPaymentPage = router.asPath.includes('/checkout/payment');

  const lineItemSubtotal = [Locale.EN_CA, Locale.FR_CA, Locale.EN_US].includes(locale)
    ? lineItems
        ?.map((lineItem) => lineItem.price.value.centAmount * lineItem.quantity)
        .reduce((acc, cur) => acc + cur, 0)
    : subtotal;
  const orderedCartLineItemSubtotal = [Locale.EN_CA, Locale.FR_CA, Locale.EN_US].includes(locale)
    ? orderData?.getOrderedCart.lineItems
        ?.map((lineItem) => lineItem.price.value.centAmount * lineItem.quantity)
        .reduce((acc, cur) => acc + cur, 0)
    : orderData?.getOrderedCart.subtotal;

  const giftCardPayment = orderedCart?.payments?.filter((payment) => payment?.paymentMethod === 'givex');
  const giftCardTotal = giftCardPayment?.reduce(
    (giftCardTotalTemp, curr) => (curr?.amount?.centAmount ?? 0) + giftCardTotalTemp,
    0,
  );

  function renderGiftCardTotal() {
    if (isGiftCardEnough && !isGiftCardRemoved) {
      return fromCents(total);
    }
    if (isGiftCardRemoved) {
      return '0.00';
    }
    return totalToPay.toFixed(2);
  }

  function renderTotalToPay() {
    if (isGiftCardEnough && !isGiftCardRemoved) {
      return '0.00';
    }
    if (isGiftCardRemoved) {
      return fromCents(total);
    }
    return fromCents(giftCardOrder?.remainingAmount?.value);
  }

  useEffect(() => {
    const isTotalTheSame = Number(fromCents(total)) === orderTotalFromGiftCard;

    if (isCheckout && giftCardOrder && !isTotalTheSame) {
      // IF in checkout flow and user updates order in such a way that it updates order total,
      // we reset all gift card states and cancel any previous gift card order.
      // This is done also in paymentContext but resetting will only happen on createPaymentSession (payment section).
      // This is to make sure tax and totals UI is updated even when user is not in payment section.
      cancelGiftCardOrder();

      setIsGiftCardEnough(false);
      setIsAddAnotherGiftCard(false);
      setIsGiftCardRemoved(false);
      setGiftCardTotal(0);
      setGiftCardValue(0);
      setGiftCardStatus(false);
      setIsErrorGiftCardInput(false);
      setOnErrorGiftCard(false);
      setCanPayWithGiftCards(false);
      setGiftCardOrder(null);
    }
  }, [
    setCanPayWithGiftCards,
    setGiftCardOrder,
    setGiftCardStatus,
    setGiftCardTotal,
    setGiftCardValue,
    setIsAddAnotherGiftCard,
    setIsErrorGiftCardInput,
    setIsGiftCardEnough,
    setIsGiftCardRemoved,
    setOnErrorGiftCard,
    total,
    isCheckout,
    cancelGiftCardOrder,
    orderTotalFromGiftCard,
    giftCardOrder,
  ]);

  return (
    <Container data-testid="taxTotalsContainer">
      <VerifyWithIDme />
      <Details
        label={t('cart.subtotal')}
        currency={isConfirmationPage ? getCurrencySymbol(orderedCart?.currency) : currency}
        amount={isConfirmationPage ? fromCents(orderedCartLineItemSubtotal) : fromCents(lineItemSubtotal)}
        testId="subtotalAmount"
      />
      <CartDelivery />
      <Details
        className={orderData?.getOrderedCart.discountTotal === 0 || discountTotal === 0 ? 'hide-label' : ''}
        label={t('cart.total.discounts')}
        currency={isConfirmationPage ? getCurrencySymbol(orderedCart?.currency) : currency}
        amount={isConfirmationPage ? fromCents(orderedCart?.discountTotal) : fromCents(discountTotal)}
        testId="discountAmount"
        isDiscount
      />
      <TaxEstimation taxes={isConfirmationPage ? (orderedCart?.taxes as number) : (taxes as number)} />
      <Details
        className="total-amount"
        label={
          isConfirmationPage || isPaymentPage
            ? t('checkout.confirmation.summary.total', { ns: 'app-orders-common' })
            : t('cart.total')
        }
        vatLabel={t('checkout.vat.label')}
        currency={isConfirmationPage ? getCurrencySymbol(orderedCart?.currency) : currency}
        amount={isConfirmationPage ? fromCents(orderedCart?.total as number) : fromCents(total)}
        locale={locale}
        isTotal
        testId="totalAmount"
      />
      {isConfirmationPage && giftCardPayment?.length ? (
        <Details
          label={t('checkout.total.paid.via.giftcard', { ns: 'app-orders-common' })}
          currency={getCurrencySymbol(giftCardPayment?.[0]?.amount?.currencyCode)}
          amount={fromCents(giftCardTotal)}
          locale={locale}
        />
      ) : null}
      {(giftCardOrder?.remainingAmount || isGiftCardEnough || isGiftCardRemoved) && (
        <>
          <Details
            label={t('checkout.giftcard.total', { ns: 'app-orders-common' })}
            currency={`-${currency}`}
            amount={renderGiftCardTotal()}
            locale={locale}
            className="gift-card-total"
            isGiftCard
            testId="giftCardsTotal"
          />
          <Details
            label={t('checkout.tax.total.to.pay', { ns: 'app-orders-common' })}
            currency={currency}
            amount={renderTotalToPay()}
            locale={locale}
            testId="remainingToPay"
          />
        </>
      )}
    </Container>
  );
}
