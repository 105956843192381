import { type ChangeEvent } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import type {
  BackgroundColor,
  NewsletterSignUp as NewsletterSignUpSchema,
} from '@amplience/content-types/typings/c-footer-newsletter-sign-up';
import type { RichTextEditor } from '@amplience/content-types/typings/p-partials';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewsletterSchema } from '@shared/validation/src/storefront/newsletter';
import { BlueButton } from '../../baseComponents/button';
import { ErrorMessage } from '../../baseComponents/errors';
import { Fieldset } from '../../baseComponents/inputs/Fieldset';
import { useStar2 } from '../../hooks';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { useMountRecaptchaScript } from '../../hooks/useMountRecaptchaScript';
import { useNewsletter } from '../../hooks/useNewsletter';
import { useResizeScrollSize } from '../../hooks/useResizeScrollSize';
import { CheckCircle, Tick } from '../../icons';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import { RichTextPartial } from '../../partials/richText/RichTextPartial';
import { TypographyStyles, TypographyStylesType, colours, maxWidthPartial, media, spacing } from '../../stylings';
import type { MarketingCategory } from '../../types/marketingPreferences';
import { Locale, languageMapper } from '../../utils/localeHelper';
import { getLocalizedValue } from '../../utils/transformers';

const multipleLanguagesStyles = css`
  display: grid;
  gap: ${spacing.XS} ${spacing.M};
  grid-template-columns: repeat(2, max-content);
  margin: 0;
  padding: 0;

  > li {
    margin: 0;
  }

  @media ${media.greaterThan('lg')} {
    display: flex;
    gap: ${spacing.XS};
    grid-template-columns: unset;
  }
`;

const singleLanguageStyles = css`
  display: grid;
  gap: 0 ${spacing.M};
  grid-template-columns: repeat(2, max-content);
  margin: 0;
  padding: 0;
`;

const S = {
  FullWidthContainer: styled.div<{ $bgColor?: BackgroundColor }>`
    background-color: ${({ $bgColor }) => ($bgColor ? $bgColor?.mobile : colours.LIGHT_BLUE)};

    @media ${media.greaterThan('lg')} {
      background-color: ${({ $bgColor }) => ($bgColor ? $bgColor?.desktop : colours.LIGHT_BLUE)};
    }
  `,
  NewsletterSignUpContainer: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    ${maxWidthPartial({ maxWidth: 1440, withPadding: false })}
    padding: ${spacing.L} ${spacing.S};

    @media ${media.greaterThan('lg')} {
      flex-direction: row;
      gap: 54px;
      justify-content: space-between;
      padding: 46px ${spacing.S} 48px ${spacing.S};
    }

    @media ${media.greaterThan('xl')} {
      padding: 46px 70px 48px;
    }
  `,
  FormContainer: styled.form`
    width: 100%;

    @media ${media.greaterThan('lg')} {
      display: flex;
      gap: 54px;
    }
  `,
  SelectionListContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    width: 100%;
  `,
  InputContainer: styled.div`
    width: 100%;

    > *:not(:last-child) {
      margin-bottom: ${spacing.XS};
    }

    @media ${media.greaterThan('lg')} {
      > *:not(:last-child) {
        margin-bottom: ${spacing.XXXS};
      }
    }
  `,
  Details: styled.div`
    margin-bottom: ${spacing.XS};

    > *:not(:last-child) {
      margin-bottom: ${spacing.XS};
    }

    @media ${media.greaterThan('lg')} {
      margin-bottom: 0;
      width: 479px;
    }
  `,
  Heading: styled(FlexibleTextPartial)`
    margin: 0;
    word-break: break-word;
  `,
  SubHeading: styled(FlexibleTextPartial)`
    margin: 0;
    word-break: break-word;
  `,
  Error: styled(ErrorMessage)`
    margin-top: ${spacing.XXXXS};
  `,
  Input: styled.input`
    border: solid 1px ${colours.GREY};
    border-radius: 0;
    min-height: 48px;
    outline: none;
    padding: 0 ${spacing.XS};
    width: 100%;
    ${TypographyStyles.Body.Medium.Regular}

    &:focus {
      border: 2px solid ${colours.BLACK};
    }

    &::placeholder {
      color: ${colours.GREY};
    }

    @media ${media.greaterThan('lg')} {
      ${TypographyStyles.Body.Tiny.Regular}
    }

    @media ${media.greaterThan('xl')} {
      ${TypographyStyles.Body.Medium.Regular}
    }
  `,
  InputAndButton: styled.div<{ $scrollWidth: number }>`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};

    @media ${media.greaterThan('xl')} {
      flex-direction: ${({ $scrollWidth }) => ($scrollWidth <= 430 ? 'column' : 'row')};
      gap: 5px;
      margin-bottom: ${spacing.XXXS};

      > * {
        width: 100%;
      }
    }
  `,
  SelectionList: styled(Fieldset)<{ $hasMultipleLanguages: boolean }>`
    ${({ $hasMultipleLanguages }) => ($hasMultipleLanguages ? multipleLanguagesStyles : singleLanguageStyles)}
    border: none;
  `,
  Selection: styled.div`
    align-items: center;
    display: flex;
    margin: 0 0 ${spacing.XS} 0;
    position: relative;
    width: max-content;

    > label {
      ${TypographyStyles.Body.Tiny.Regular}
      cursor: pointer;
      overflow-wrap: anywhere;
      padding: 0 0 0 10px;
    }

    > input[type='checkbox'] {
      appearance: none;
      background-color: ${colours.WHITE};
      border: solid 1px ${colours.GREY};
      border-radius: 0;
      box-sizing: border-box;
      cursor: pointer;
      flex-shrink: 0;
      height: ${spacing.S};
      margin: 0;
      width: ${spacing.S};

      &:focus {
        outline-color: ${colours.OUTLINE_BORDER};
      }
    }

    > input[type='checkbox']:checked {
      background-color: ${colours.DARK_BLUE};
    }

    > svg {
      border-radius: 0px;
      display: inline-block;
      height: 15.1px;
      left: 6px;
      pointer-events: none;
      position: absolute;
      width: 15.1px;

      & path {
        fill: ${colours.WHITE};
      }
    }
  `,
  Button: styled(BlueButton)<{ $scrollWidth: number }>`
    ${TypographyStyles.Button.SemiBold}
    background-color: ${colours.DARK_BLUE};
    border: none;
    border-radius: 0;
    color: ${colours.WHITE};
    cursor: pointer;
    height: 100%;
    margin: 0;
    min-height: 48px;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-transform: uppercase;
    width: 100%;

    @media ${media.greaterThan('xl')} {
      max-width: ${({ $scrollWidth }) => ($scrollWidth <= 430 ? 'none' : '237px')};
    }
  `,
  TermsAndPolicy: styled(RichTextPartial)`
    > p {
      margin: 0;
    }

    a {
      color: ${colours.BLACK};
    }
  `,
  SuccessMessageContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${media.greaterThan('lg')} {
      flex-direction: column-reverse;
    }
  `,
  SuccessMessage: styled.p`
    ${TypographyStyles.Body.Small.Regular}

    @media ${media.greaterThan('lg')} {
      margin: 0;
    }
  `,
  SuccessContentContainer: styled.div`
    display: flex;
    gap: 10px;
  `,
  SuccessIcon: styled.div`
    align-items: center;
    display: flex;
    height: 22px;
  `,
  LanguageSelectionContainer: styled.div`
    ${TypographyStyles.Body.Tiny.Regular}
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};

    @media ${media.greaterThan('lg')} {
      gap: ${spacing.XXXS};
    }

    > p {
      margin: 0;
      padding: 0;
    }
  `,
  LanguageSelectionList: styled(Fieldset)`
    border: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${spacing.XS};
    margin: 0 0 ${spacing.XS};
    padding: 0;
  `,
  LanguageSelection: styled.div`
    align-items: center;
    display: flex;

    > label {
      ${TypographyStyles.Body.Tiny.Regular}
      cursor: pointer;
      overflow-wrap: anywhere;
      padding: 0 0 0 10px;
    }

    > input[type='radio'] {
      cursor: pointer;
      flex-shrink: 0;
      height: ${spacing.S};
      margin: 0;
      width: ${spacing.S};

      &:focus {
        outline-color: ${colours.OUTLINE_BORDER};
        outline-width: 2px;
      }
    }

    > input[type='radio']:checked {
      accent-color: ${colours.DARK_BLUE};
    }
  `,
};

function SuccessMessage({ richText }: { richText?: string }) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { t } = useTranslation('lib-global-common');

  return (
    <S.SuccessMessageContainer role="status">
      <S.SuccessContentContainer>
        <S.SuccessIcon data-testid="newsletterSuccessIcon">
          <CheckCircle />
        </S.SuccessIcon>
        {isDesktop ? (
          <S.TermsAndPolicy text={getLocalizedValue(richText)} fontSize={TypographyStyles.Body.Tiny.Regular} />
        ) : null}
      </S.SuccessContentContainer>
      <S.SuccessMessage data-testid="newsletterSuccessMessage">{t('footer.newsletter.success.title')}</S.SuccessMessage>
      {!isDesktop ? (
        <S.TermsAndPolicy text={getLocalizedValue(richText)} fontSize={TypographyStyles.Body.Tiny.Regular} />
      ) : null}
    </S.SuccessMessageContainer>
  );
}

function LanguageSelection({
  languages,
  onChange,
  languageSelectionError,
}: {
  languages: string[];
  onChange: ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => void;
  languageSelectionError: boolean;
}) {
  const { t } = useTranslation('lib-global-common');

  return (
    <S.LanguageSelectionContainer>
      <p>{t('footer.newsletter.language.selection')}</p>
      <S.LanguageSelectionList label={t('footer.newsletter.radio.language.selection')}>
        {languages.map((language) => {
          const lang = languageMapper[language.substring(0, language.indexOf('-'))];

          return (
            <S.LanguageSelection key={`newsletter-language-${lang}`}>
              <input
                type="radio"
                aria-describedby={languageSelectionError ? 'newsletter-language-error' : undefined}
                id={`newsletter-language-${lang}`}
                value={language}
                name="language"
                onChange={onChange}
                data-testid="newsletterLanguageSelection"
              />
              <label htmlFor={`newsletter-language-${lang}`}>{t(`footer.newsletter.language.${lang}`)}</label>
            </S.LanguageSelection>
          );
        })}
      </S.LanguageSelectionList>
    </S.LanguageSelectionContainer>
  );
}

export type TermsAndPolicyType = {
  richText: RichTextEditor;
};

export type NewsletterSignUpProps = {
  termsAndPolicy?: TermsAndPolicyType;
} & NewsletterSignUpSchema;

export const getTranslation = (value: string) => {
  if (value === 'kids') return 'footer.newsletter.kids';
  if (value === 'womens') return 'footer.newsletter.womens';
  if (value === 'mens') return 'footer.newsletter.mens';
  if (value === 'outlet') return 'footer.newsletter.outlet';
  if (value === 'originals') return 'footer.newsletter.originals';
  return '';
};

export function NewsletterSignUp({
  newsHeading: heading,
  bgColor,
  newsSubHeading: subHeading,
  termsAndPolicy,
  showSelections,
}: NewsletterSignUpProps) {
  const { t } = useTranslation('lib-global-common');

  const {
    checkboxError,
    newsletterData,
    onChange,
    onSubmit,
    recaptchaError,
    marketingPreferences,
    marketingChoices,
    locale,
    languageSelectionError,
    loading,
  } = useNewsletter();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(NewsletterSchema),
    defaultValues: {
      email: '',
    },
  });
  const hasMultipleLanguages = (marketingPreferences && marketingPreferences.languages.length > 1) || false;
  const { elementRef, scrollWidth } = useResizeScrollSize({});
  const { componentRef } = useMountRecaptchaScript();
  const { isStar2 } = useStar2();
  return (
    <S.FullWidthContainer ref={componentRef} $bgColor={bgColor} data-testid="newsletterSection">
      <S.NewsletterSignUpContainer>
        <S.Details>
          {heading?.text && (
            <S.Heading
              {...heading}
              text={getLocalizedValue(heading?.text)}
              defaultTextSize={TypographyStylesType.HEADINGS_H3}
              defaultTextColor={colours.BLACK}
            />
          )}
          {subHeading?.text && (
            <S.SubHeading
              {...subHeading}
              text={getLocalizedValue(subHeading?.text)}
              defaultTextSize={TypographyStylesType.BODY_TINY_REGULAR}
              defaultTextColor={colours.BLACK}
            />
          )}
        </S.Details>
        {!newsletterData ? (
          <S.FormContainer onSubmit={handleSubmit(onSubmit)} noValidate>
            <div>
              {!!showSelections && (
                <S.SelectionListContainer>
                  <S.SelectionList
                    $hasMultipleLanguages={hasMultipleLanguages}
                    label={t('footer.newsletter.radio.category.selection')}
                  >
                    {marketingPreferences &&
                      marketingPreferences.marketingPreferences &&
                      Object.entries(marketingPreferences?.marketingPreferences as MarketingCategory).map(
                        ([key, item]) => (
                          <S.Selection key={`${item.key}-${key}`}>
                            <input
                              checked={marketingChoices[+key]?.optIn}
                              aria-invalid={checkboxError}
                              aria-describedby={checkboxError ? 'newsletter-checkbox-error' : undefined}
                              aria-required="true"
                              type="checkbox"
                              id={`newsletter-${item.key}`}
                              value={item.key}
                              onChange={onChange}
                              name={key}
                              data-testid="newsletterCheckbox"
                            />
                            <Tick viewBox="-6 0 40 25" />
                            <label htmlFor={`newsletter-${item.key}`}>{t(getTranslation(item.key))}</label>
                          </S.Selection>
                        ),
                      )}
                  </S.SelectionList>
                  {hasMultipleLanguages ? (
                    <LanguageSelection
                      languages={marketingPreferences?.languages || []}
                      onChange={onChange}
                      languageSelectionError={languageSelectionError}
                    />
                  ) : null}
                </S.SelectionListContainer>
              )}
            </div>
            <S.InputContainer ref={elementRef}>
              <S.InputAndButton $scrollWidth={scrollWidth}>
                <div data-cs-mask>
                  <S.Input
                    type="email"
                    maxLength={60}
                    aria-label={t('footer.newsletter.placeholder')}
                    aria-invalid={errors.email ? 'true' : 'false'}
                    aria-describedby={errors.email?.message ? 'newsletter-email-error' : undefined}
                    aria-required="true"
                    placeholder={t('footer.newsletter.placeholder')}
                    {...register('email')}
                    autoComplete="email"
                    required
                    data-testid="newsletterInput"
                  />
                  {errors.email?.message ? (
                    <S.Error id="newsletter-email-error">{t(errors.email.message)}</S.Error>
                  ) : null}
                  {checkboxError && (
                    <S.Error id="newsletter-checkbox-error">{t('footer.newsletter.validation.checkbox')}</S.Error>
                  )}
                  {languageSelectionError && (
                    <S.Error id="newsletter-language-error">{t('footer.newsletter.validation.language')}</S.Error>
                  )}
                  {recaptchaError === 'BadDataError' && (
                    <S.Error id="recaptcha-error">{t('recaptcha.error.message')}</S.Error>
                  )}
                </div>
                <S.Button
                  $scrollWidth={scrollWidth}
                  disabled={loading}
                  type="submit"
                  data-testid="newsletterSubmitButton"
                >
                  {t('footer.newsletter.button')}
                </S.Button>
              </S.InputAndButton>
              <S.TermsAndPolicy
                text={getLocalizedValue(termsAndPolicy?.richText)}
                fontSize={TypographyStyles.Body.Tiny.Regular}
              />
            </S.InputContainer>
          </S.FormContainer>
        ) : (
          <SuccessMessage richText={termsAndPolicy?.richText} />
        )}
      </S.NewsletterSignUpContainer>
    </S.FullWidthContainer>
  );
}
