import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

interface HistoryValidation {
  history: string[];
  lastPath: string;
}

const HistoryContext = createContext<HistoryValidation>({} as HistoryValidation);

export function HistoryProvider({ children }: PropsWithChildren) {
  const router = useRouter();

  const [isSwitching, setIsSwitching] = useState(false);
  const [history, setHistory] = useState<string[]>(() => {
    const storedHistory = typeof window !== 'undefined' ? window.sessionStorage.getItem('history') : undefined;
    return storedHistory ? JSON.parse(storedHistory) : [];
  });
  const [lastPath, setLastPath] = useState<string>(() => {
    const storedLastPath = typeof window !== 'undefined' ? window.sessionStorage.getItem('lastPath') : undefined;
    return storedLastPath || '';
  });

  const handleRouteChange = () => {
    setIsSwitching(true);
  };

  // sessionStorage prevents 'history' and 'lastPath' from being cleared when switching between 'app-main' and 'app-orders'
  // 'history' and 'lastPath' are removed only after a hard reload or when the user closes the tab
  useEffect(() => {
    const sessionFlag = sessionStorage.getItem('appLoaded');

    if (!sessionFlag) {
      sessionStorage.removeItem('history');
      sessionStorage.removeItem('lastPath');
      sessionStorage.setItem('appLoaded', 'true');
    }

    const lastHistoryPath = history.slice(-1)[0];
    if (lastHistoryPath !== router.asPath) {
      const updatedHistory = [...history, router.asPath];

      setLastPath(lastHistoryPath);
      setHistory(updatedHistory);

      window.sessionStorage.setItem('lastPath', router.asPath);
      window.sessionStorage.setItem('history', JSON.stringify(updatedHistory));
    }

    const handleBeforeUnload = () => {
      if (!isSwitching) {
        sessionStorage.removeItem('history');
        sessionStorage.removeItem('lastPath');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, history, isSwitching]);

  useEffect(() => {
    setIsSwitching(false);
  }, [router.asPath]);

  const value = useMemo(
    () => ({
      history,
      lastPath,
    }),
    [history, lastPath],
  );

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
}

export function useHistory(): HistoryValidation {
  return useContext(HistoryContext);
}
