import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type FindOrderQueryVariables = Types.Exact<{
  input: Types.FindOrderInput;
}>;

export type FindOrderQuery = {
  __typename?: 'Query';
  findOrder?: {
    __typename?: 'Order';
    orderNumber: string;
    orderPlacedDate: string;
    currency?: string | null;
    returnLink?: string | null;
    shipments?: Array<{
      __typename?: 'Shipment';
      status: string;
      statusText: string;
      deliveryExpectedDate?: string | null;
      productImages?: Array<string | null> | null;
      trackings?: Array<{
        __typename?: 'Tracking';
        carrier?: string | null;
        carrierService?: string | null;
        shippedAt?: string | null;
        trackingCode?: string | null;
        trackingUrl?: string | null;
        items?: Array<{
          __typename?: 'TrackingItem';
          name?: string | null;
          price: string;
          size?: string | null;
          width?: string | null;
          imageUrl?: string | null;
          color?: string | null;
          quantity: number;
          status?: string | null;
          giftCardRecipient?: string | null;
          giftCardMessage?: string | null;
          giftCardSender?: string | null;
          giftCardRecipientEmail?: string | null;
          finalSale?: {
            __typename?: 'FinalSaleAttributes';
            backgroundColor?: string | null;
            emailText?: string | null;
            isFinalSale?: boolean | null;
            text?: string | null;
            textColor?: string | null;
          } | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    summary?: {
      __typename?: 'OrderSummary';
      subtotal: number;
      deliveryTotal: number;
      discountTotal: number;
      total: number;
      taxes?: number | null;
      discounts?: Array<{
        __typename?: 'DiscountCodeInfo';
        discountCode: string;
        discountId: string;
        discountName?: string | null;
        discountValue?: number | null;
      } | null> | null;
      deliveryMethod?: { __typename?: 'DeliveryMethodInfo'; key: string; name: string; description: string } | null;
      deliveryAddress?: {
        __typename?: 'DeliveryAddress';
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        line1?: string | null;
        line2?: string | null;
        city?: string | null;
        postalCode?: string | null;
        country?: string | null;
        state?: string | null;
      } | null;
    } | null;
  } | null;
};

export const FindOrderDocument = gql`
  query findOrder($input: FindOrderInput!) {
    findOrder(input: $input) {
      orderNumber
      orderPlacedDate
      currency
      returnLink
      shipments {
        status
        statusText
        deliveryExpectedDate
        productImages
        trackings {
          carrier
          carrierService
          shippedAt
          trackingCode
          trackingUrl
          items {
            name
            price
            size
            width
            imageUrl
            color
            quantity
            status
            giftCardRecipient
            giftCardMessage
            giftCardSender
            giftCardRecipientEmail
            finalSale {
              backgroundColor
              emailText
              isFinalSale
              text
              textColor
            }
          }
        }
      }
      summary {
        subtotal
        deliveryTotal
        discountTotal
        total
        taxes
        discounts {
          discountCode
          discountId
          discountName
          discountValue
        }
        deliveryMethod {
          key
          name
          description
        }
        deliveryAddress {
          firstName
          lastName
          phone
          line1
          line2
          city
          postalCode
          country
          state
        }
      }
    }
  }
`;

/**
 * __useFindOrderQuery__
 *
 * To run a query within a React component, call `useFindOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindOrderQuery(baseOptions: Apollo.QueryHookOptions<FindOrderQuery, FindOrderQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindOrderQuery, FindOrderQueryVariables>(FindOrderDocument, options);
}
export function useFindOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindOrderQuery, FindOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindOrderQuery, FindOrderQueryVariables>(FindOrderDocument, options);
}
export type FindOrderQueryHookResult = ReturnType<typeof useFindOrderQuery>;
export type FindOrderLazyQueryHookResult = ReturnType<typeof useFindOrderLazyQuery>;
export type FindOrderQueryResult = Apollo.QueryResult<FindOrderQuery, FindOrderQueryVariables>;
