import type { HandlebarTemplateProps } from '../../globalComponents';
import { siteMapper } from '../../utils';
import type { CmsSlotsDeliveryKeys } from '../../utils/amplience';
import { amplienceClient } from '../../utils/amplience';
import { getHandlebarTemplateComponents } from './getHandlebarTemplateComponents';

interface SlotComponent {
  _meta: {
    schema: string;
  };
  slotContent?: unknown;
  categories?: string[];
}

interface GetCmsSlotsDataProps {
  locale: string;
  categoryKey?: string;
  deliveryKeys: CmsSlotsDeliveryKeys[];
}

export const getCmsSlotsData = async ({ locale, categoryKey, deliveryKeys }: GetCmsSlotsDataProps) => {
  if (!categoryKey) return {};

  const isRoeLocale = !Object.keys(siteMapper).includes(locale);
  const deliveryKeyLocale = isRoeLocale ? 'roe' : locale;

  const { responses } = await amplienceClient.fetchContentItems({
    parameters: {
      depth: 'all',
      format: 'inlined',
      locale,
    },
    requests: deliveryKeys.map((key) => ({ key: `${key}-${deliveryKeyLocale}` })),
  });

  const cmsSlots = await Promise.all(
    responses.map(async (component) => {
      // For some reason type that lives inside component is different in our Amplience package than the one provided in documentation and by actual response.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { error, content } = component as any;
      const deliveryKeyBase = error?.data.deliveryKey ?? content?._meta.deliveryKey;
      const deliveryKey = deliveryKeyBase.replace(`-${deliveryKeyLocale}`, '');

      if (error) {
        return [deliveryKey, null];
      }

      const handlebarComponent = content.slotContent.components.filter(
        (slotComponent: SlotComponent) =>
          slotComponent._meta.schema.split('/').pop()?.startsWith('b-') &&
          slotComponent.categories?.join()?.includes(categoryKey) &&
          !slotComponent.slotContent,
      )[0];

      const handlebarComponentTemplate = (await getHandlebarTemplateComponents([handlebarComponent])) as
        | HandlebarTemplateProps[]
        | null;

      return [deliveryKey, handlebarComponentTemplate?.[0] ?? null];
    }),
  );

  return Object.fromEntries(cmsSlots);
};
