/* eslint-disable */
import { logger } from '../../utils';
import { cache } from '../redis-cache';

type HierarchyDataProps = {
  id: string;
  locale: string;
  key?: string;
  staging?: boolean;
};

export const getHierarchyData = async ({ id, locale, key, staging }: HierarchyDataProps): Promise<any[]> => {
  const baseUrl = staging
    ? `https://${process.env.AMPLIENCE_HUB_STAGING}.staging.bigcontent.io/content/filter`
    : `https://${process.env.AMPLIENCE_HUB_NAME}.cdn.content.amplience.net/content/filter`;

  // limit = 2 to only get two pages (max 24 responses)
  let limit = 2;
  let cursor = '';
  const responses: any[] = [];

  while (limit > 0) {
    limit--;
    const requestBody = {
      filterBy: [
        {
          path: '/_meta/hierarchy/parentId',
          value: id,
        },
      ],
      parameters: {
        depth: 'all',
        locale,
      },
      sortBy: {
        ...{ key },
      },
      ...(!!cursor && { page: { cursor } }),
    };

    try {
      const HIERARCHY_DATA_CACHE_KEY = `HIERARCHY_DATA_${id}_${locale}_${key}__${staging}_${''}_${cursor}`;
      const cachedHierarchyData = await cache().get(HIERARCHY_DATA_CACHE_KEY);

      // If cache is available then return catch as long as it is not staging
      if (cachedHierarchyData && !staging) {
        responses.push(...(cachedHierarchyData.responses ?? []));
        if (!cachedHierarchyData.page?.nextCursor) break;
        cursor = cachedHierarchyData.page.nextCursor;
      } else {
        const response = await fetch(baseUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(`Fetch failed with status ${response.status}, ${response.statusText}`);
        }

        const data = await response.json();
        responses.push(...(data?.responses ?? []));

        await cache().set(HIERARCHY_DATA_CACHE_KEY, data, { ttl: 60 * 10 });

        if (!data?.page?.nextCursor) break;
        cursor = data.page.nextCursor;
      }
    } catch (error) {
      logger.debug({ error }, 'Error in getHierarchyData');
      break;
    }
  }

  return responses;
};

export const getNavigationHierarchyDataTopLevel = async (headerNavigation: any, locale: string, staging?: boolean) =>
  headerNavigation
    ? await getHierarchyData({ id: headerNavigation._meta.deliveryId, locale, key: 'default', staging })
    : [];

export const getNavigationHierarchyDataSubLevel = async (
  headerNavigationLevel1: any,
  locale: string,
  staging?: boolean,
) => {
  const hierarchyData =
    (await getHierarchyData({ id: headerNavigationLevel1._meta.deliveryId as string, locale, staging })) ?? [];

  const megamenuLayoutData = headerNavigationLevel1 ? hierarchyData : [];

  return megamenuLayoutData.length
    ? await Promise.all(
        megamenuLayoutData.map(async (megamenuData: any) => {
          const linkedContent = await getLinkedContentWithChildData(megamenuData.linkedContent, locale, staging);
          const layoutContent = getLayoutContentWithChildData(megamenuData.content.layoutContent, linkedContent);

          return { ...megamenuData.content, ...{ layoutContent } };
        }),
      )
    : [];
};

export const getLinkedContentWithChildData = async (linkedContent: any, locale: string, staging?: boolean) => {
  return linkedContent
    ? await Promise.all(
        linkedContent.map(async (linkedData: any) => {
          const hasChildNodes = !!linkedData._meta?.hierarchy;
          if (hasChildNodes) {
            const linkedContentChildData = await getHierarchyData({
              id: linkedData._meta.deliveryId as string,
              locale,
              key: 'default',
              staging,
            });

            return { ...linkedData, ...{ linkedContentChildData } };
          }

          return linkedData;
        }),
      )
    : [];
};

export const getLayoutContentWithChildData = (layoutContent: any, linkedContent: any) => {
  return layoutContent?.map((layoutContentData: any) => {
    const { navigationList: navigationListData, featuredCards: featuredCardsComponent } = layoutContentData;
    let linkedContentChildData;

    if (navigationListData) {
      const navigationList = navigationListData.map((navigationData: any) => {
        const { featuredLinks: featuredLinksComponent, category: categoryComponent } = navigationData;

        if (featuredLinksComponent) {
          linkedContentChildData = linkedContent.find(
            (linkedContentData: any) => linkedContentData._meta.deliveryId === featuredLinksComponent.id,
          );
          const featuredLinks = { ...navigationData.featuredLinks, ...{ linkedContentChildData } };
          return { ...navigationData, ...{ featuredLinks } };
        }

        if (categoryComponent) {
          let categoryLevelFeaturedCards;
          const linkedContentCategoryChildData = linkedContent.find(
            (linkedContentData: any) => linkedContentData._meta.deliveryId === categoryComponent.id,
          );

          if (linkedContentCategoryChildData?.categoryLevelFeaturedCards) {
            categoryLevelFeaturedCards = linkedContentCategoryChildData?.categoryLevelFeaturedCards.map(
              (categoryLevelFeaturedCard: any) => {
                return linkedContent.find(
                  (linkedContentData: any) => linkedContentData._meta.deliveryId === categoryLevelFeaturedCard.id,
                );
              },
            );
          }
          linkedContentChildData = { ...linkedContentCategoryChildData, ...{ categoryLevelFeaturedCards } };

          const category = { ...navigationData.category, ...{ linkedContentChildData } };
          return { ...navigationData, ...{ category } };
        }
        return navigationData;
      });

      return { ...layoutContentData, ...{ navigationList } };
    }

    if (featuredCardsComponent) {
      linkedContentChildData = linkedContent.find(
        (linkedContentData: any) => linkedContentData._meta.deliveryId === featuredCardsComponent.id,
      );
      const featuredCards = { ...layoutContentData.featuredCards, ...{ linkedContentChildData } };
      return { ...layoutContentData, ...{ featuredCards } };
    }

    return layoutContentData;
  });
};

export const getNavigationHierarchyData = async (headerNavigation: any, locale: string, staging?: boolean) => {
  if (!headerNavigation) return [];

  const navigationHierarchyDataTopLevel =
    (await getNavigationHierarchyDataTopLevel(headerNavigation, locale, staging)) ?? [];

  return await Promise.all(
    navigationHierarchyDataTopLevel.map(async (topLevelData: any) => {
      const navigationHierarchyDataSubLevel = await getNavigationHierarchyDataSubLevel(
        topLevelData.content,
        locale,
        staging,
      );

      return { ...topLevelData, ...{ navigationHierarchyDataSubLevel } };
    }),
  );
};
