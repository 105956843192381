import algoliasearch from 'algoliasearch/lite';
import { createInMemoryCache } from '@algolia/cache-in-memory';
import {
  LocaleStar2,
  STAR1Locales,
  STAR2LocalesScand,
  STAR2LocalesWithMultipleLanguages,
} from '../../utils/localeHelper';

const ENV = process.env.ALGOLIA_INDEX_PREFIX ?? process.env.NEXT_PUBLIC_ENV_NAME;

const AlgoliaIndexes: {
  [key: string]: string;
} = {
  'en-product': `${ENV}_product_uk`,
  'en-gb-product': `${ENV}_product_uk`,
  'en-us-product': `${ENV}_product_us`,
  'en-ie-product': `${ENV}_product_roi`,
  'en-ca-product': `${ENV}_product_ca`,
  'fr-ca-product': `${ENV}_product_ca`,
  'en-vg-product': `${ENV}_product_outlet_uk`,
  'de-de-product': `${ENV}_product_de`,
  'en-de-product': `${ENV}_product_de`,
  'fr-fr-product': `${ENV}_product_fr`,
  'en-fr-product': `${ENV}_product_fr`,
  'it-it-product': `${ENV}_product_it`,
  'en-it-product': `${ENV}_product_it`,
  'es-es-product': `${ENV}_product_es`,
  'en-es-product': `${ENV}_product_es`,
  'nl-nl-product': `${ENV}_product_nl`,
  'en-nl-product': `${ENV}_product_nl`,
  'en-at-product': `${ENV}_product_roe_at`,
  'de-at-product': `${ENV}_product_roe_at`,
  'en-be-product': `${ENV}_product_roe_be`,
  'nl-be-product': `${ENV}_product_roe_be`,
  'fr-be-product': `${ENV}_product_roe_be`,
  'en-ch-product': `${ENV}_product_roe_ch`,
  'de-ch-product': `${ENV}_product_roe_ch`,
  'fr-ch-product': `${ENV}_product_roe_ch`,
  'it-ch-product': `${ENV}_product_roe_ch`,
  'en-dk-product': `${ENV}_product_roe_dk`,
  'en-pl-product': `${ENV}_product_roe_pl`,
  'en-sm-product': `${ENV}_product_roe_sm`,
  'it-sm-product': `${ENV}_product_roe_sm`,
  'en-lu-product': `${ENV}_product_roe_lu`,
  'fr-lu-product': `${ENV}_product_roe_lu`,
  'en-fi-product': `${ENV}_product_roe_scand`,
  'en-no-product': `${ENV}_product_roe_scand`,
  'en-se-product': `${ENV}_product_roe_scand`,
  'roe-product': `${ENV}_product_roe`,
  'en-category': `${ENV}_category_uk`,
  'en-gb-category': `${ENV}_category_uk`,
  'en-us-category': `${ENV}_category_us`,
  'en-ie-category': `${ENV}_category_roi`,
  'en-ca-category': `${ENV}_category_ca`,
  'fr-ca-category': `${ENV}_category_ca`,
  'en-vg-category': `${ENV}_category_outlet_uk`,
  'de-de-category': `${ENV}_category_de`,
  'en-de-category': `${ENV}_category_de`,
  'fr-fr-category': `${ENV}_category_fr`,
  'en-fr-category': `${ENV}_category_fr`,
  'it-it-category': `${ENV}_category_it`,
  'en-it-category': `${ENV}_category_it`,
  'es-es-category': `${ENV}_category_es`,
  'en-es-category': `${ENV}_category_es`,
  'nl-nl-category': `${ENV}_category_nl`,
  'en-nl-category': `${ENV}_category_nl`,
  'en-at-category': `${ENV}_category_roe_at`,
  'de-at-category': `${ENV}_category_roe_at`,
  'en-be-category': `${ENV}_category_roe_be`,
  'nl-be-category': `${ENV}_category_roe_be`,
  'fr-be-category': `${ENV}_category_roe_be`,
  'en-ch-category': `${ENV}_category_roe_ch`,
  'de-ch-category': `${ENV}_category_roe_ch`,
  'fr-ch-category': `${ENV}_category_roe_ch`,
  'it-ch-category': `${ENV}_category_roe_ch`,
  'en-dk-category': `${ENV}_category_roe_dk`,
  'en-pl-category': `${ENV}_category_roe_pl`,
  'en-sm-category': `${ENV}_category_roe_sm`,
  'it-sm-category': `${ENV}_category_roe_sm`,
  'en-lu-category': `${ENV}_category_roe_lu`,
  'fr-lu-category': `${ENV}_category_roe_lu`,
  'en-fi-category': `${ENV}_category_roe_scand`,
  'en-no-category': `${ENV}_category_roe_scand`,
  'en-se-category': `${ENV}_category_roe_scand`,
  'roe-category': `${ENV}_category_roe`,
  store: `${ENV}_store`,
};

export const APPLICATION_ID = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ?? '';
export const SEARCH_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? '';

export const searchClient = algoliasearch(APPLICATION_ID, SEARCH_API_KEY, {
  responsesCache: createInMemoryCache(),
  requestsCache: createInMemoryCache({ serializable: false }),
});

export const getAlgoliaProductIndex = (locale: string) =>
  AlgoliaIndexes[
    !STAR1Locales.includes(locale.toLowerCase()) &&
    !STAR2LocalesWithMultipleLanguages.includes(locale.toLowerCase()) &&
    !STAR2LocalesScand.includes(locale.toLowerCase())
      ? 'roe-product'
      : `${locale}-product`
  ];
export const getAlgoliaCategoryIndex = (locale: string) =>
  AlgoliaIndexes[
    !STAR1Locales.includes(locale.toLowerCase()) &&
    !STAR2LocalesWithMultipleLanguages.includes(locale.toLowerCase()) &&
    !STAR2LocalesScand.includes(locale.toLowerCase())
      ? 'roe-category'
      : `${locale}-category`
  ];

export const getQuerySuggestionsIndex = (locale: string) => {
  const querySuggestionsIndex = `${getAlgoliaProductIndex(locale)}_query_suggestions`;

  if (
    locale.includes('ca') ||
    (STAR2LocalesWithMultipleLanguages.includes(locale) && locale !== LocaleStar2.EN_DK && locale !== LocaleStar2.EN_PL)
  ) {
    return `${querySuggestionsIndex}_${locale.substring(0, locale.indexOf('-'))}`;
  }
  return querySuggestionsIndex;
};

export const getAlgoliaStoreIndex = () => searchClient.initIndex(AlgoliaIndexes.store);
