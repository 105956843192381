import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Close } from '../../icons';
import {
  TypographyStyles,
  colours,
  hoverUp,
  media,
  spacing,
  zSidebarMobile,
  zSidebarMobileContent,
  zStickyHeader,
} from '../../stylings';

const S = {
  Container: styled.div<{ $isOpen: boolean }>`
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    height: 100%;
    justify-content: flex-end;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: ${zSidebarMobile};
  `,
  Backdrop: styled.div<{ $isOpen: boolean }>`
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: ${({ $isOpen }) => ($isOpen ? 'flex' : 'none')};
    height: 100%;
    right: 0;
    top: 0;
    width: 100%;
  `,
  Content: styled.div<{ $isOpen: boolean }>`
    background-color: ${colours.WHITE};
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 310px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 ${spacing.S};
    position: fixed;
    right: 0;
    top: 0;
    transform: ${({ $isOpen }) => ($isOpen ? 'translateX(0%)' : 'translateX(100%)')};
    transition: transform 0.5s ease;
    width: 90%;
    z-index: ${zSidebarMobileContent};

    @media ${media.greaterThan('lg')} {
      max-width: 404px;
      padding: 0 ${spacing.M};
    }
  `,
  Header: styled.div`
    align-items: center;
    background-color: ${colours.WHITE};
    display: flex;
    justify-content: space-between;
    padding-bottom: 27px;
    padding-top: ${spacing.L};
    position: sticky;
    top: 0;
    z-index: ${zStickyHeader};
  `,
  Heading: styled.div`
    ${TypographyStyles.Headings.H4SemiBold}
    text-transform: uppercase;
  `,
  CloseButton: styled.button`
    align-items: flex-start;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: left;
    padding: 0;
    width: 20px;

    ${hoverUp()}

    svg {
      color: ${colours.WHITE};
      transform: scale(0.5);
    }
  `,
};

type SideBarMobileProps = {
  isOpen: boolean;
  label: string;
  onClose: () => void;
  children: ReactNode;
};

export function SideBar({ isOpen, label, onClose, children }: SideBarMobileProps) {
  const { t } = useTranslation(['lib-global-common']);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'initial';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isOpen]);

  return (
    <S.Container $isOpen={isOpen} aria-hidden={!isOpen} role="dialog" aria-modal={isOpen}>
      <S.Backdrop $isOpen={isOpen} onClick={onClose} />
      <S.Content $isOpen={isOpen}>
        <S.Header>
          <S.Heading data-testid="sideBarLabel">{label}</S.Heading>
          <S.CloseButton
            aria-label={t('sidebar.mobile.close')}
            id="sidebar-mobile-close"
            type="button"
            onClick={onClose}
          >
            <Close aria-labelledby="sidebar-mobile-close" />
          </S.CloseButton>
        </S.Header>
        {children}
      </S.Content>
    </S.Container>
  );
}
