import { css } from 'styled-components';
import type { TextAlign } from '@amplience/content-types/typings/p-partials';
import { TextAlignMapper } from '../../utils/amplienceMappers';

export const useTextAlign = (textAlign: TextAlign | undefined) =>
  textAlign
    ? css`
        text-align: ${TextAlignMapper[textAlign]};
      `
    : null;
