import type { CartLayoutSlot } from '@amplience/content-types/typings/s-cart-layout';
import type { OrderLayoutSlot } from '@amplience/content-types/typings/s-order-layout';
import type { PDPLayoutSlot } from '@amplience/content-types/typings/s-pdp-layout';
import type { PLPLayoutSlot } from '@amplience/content-types/typings/s-plp-layout';
import type { SRPLayoutSlot } from '@amplience/content-types/typings/s-srp-layout';
import { LocaleStar2, STAR1Locales, countryNameMapperROE, getCountryKey, siteMapper } from '../../utils/localeHelper';
import { sendFilterApiRequest } from './amplienceFilterApi';

type SlotLayout = PDPLayoutSlot | PLPLayoutSlot | SRPLayoutSlot | CartLayoutSlot | OrderLayoutSlot;

export const getSlotLayout = async (locale: string, slotLayout: string): Promise<SlotLayout | unknown> => {
  const parameterLocale = Object.prototype.hasOwnProperty.call(
    countryNameMapperROE,
    getCountryKey(locale).toUpperCase(),
  )
    ? locale.split('-')[0]
    : locale;

  const requestBody = {
    filterBy: [
      {
        path: '/_meta/schema',
        value: slotLayout,
      },
      {
        path: '/isDefault',
        value:
          siteMapper[
            Object.prototype.hasOwnProperty.call(countryNameMapperROE, getCountryKey(locale).toUpperCase())
              ? LocaleStar2.ROE
              : locale.toLowerCase()
          ],
      },
    ],
    parameters: {
      depth: 'all',
      format: 'inlined',
      locale: parameterLocale,
    },
  };

  const requestBodyEurope = {
    filterBy: [
      {
        path: '/_meta/schema',
        value: slotLayout,
      },
      {
        path: '/isDefault',
        value: 'Europe',
      },
    ],
    parameters: {
      depth: 'all',
      format: 'inlined',
      locale: parameterLocale,
    },
  };

  try {
    const response = await sendFilterApiRequest({ requestBody });
    let slotLayoutContent: SlotLayout = response[0]?.content ?? null;

    let responseEurope = [];

    if (!STAR1Locales.includes(locale) && response.length === 0) {
      responseEurope = await sendFilterApiRequest({ requestBody: requestBodyEurope });
      slotLayoutContent = responseEurope[0]?.content ?? null;
    }

    return slotLayoutContent;
  } catch (err) {
    console.error('Error fetching Slot Layout from Amplience', err);
    return null;
  }
};
