export type ProductLineItemState = {
  lineItemQuantity: number;
  isSkuCapReached: boolean;
  adjustedQuantity: number;
  hasValidated: boolean;
  hasGenericError: boolean;
};

export enum ProductLineItemActionType {
  SET_LINE_ITEM_QUANTITY = 'setLineItemQuantity',
  SET_IS_SKU_CAP_REACHED = 'setIsSkuCapReached',
  SET_ADJUSTED_QUANTITY = 'setAdjustedQuantity',
  SET_HAS_VALIDATED = 'setHasValidated',
  SET_HAS_GENERIC_ERROR = 'setHasGenericError',
}

export type ProductLineItemAction =
  | {
      type: ProductLineItemActionType.SET_LINE_ITEM_QUANTITY;
      value: number;
      add?: boolean;
    }
  | {
      type: ProductLineItemActionType.SET_IS_SKU_CAP_REACHED;
      isSkuCapReached: boolean;
    }
  | {
      type: ProductLineItemActionType.SET_ADJUSTED_QUANTITY;
      adjustedQuantity: number;
    }
  | {
      type: ProductLineItemActionType.SET_HAS_VALIDATED;
      hasValidated: boolean;
    }
  | {
      type: ProductLineItemActionType.SET_HAS_GENERIC_ERROR;
      hasGenericError: boolean;
    };

export const productLineItemReducer = (
  state: ProductLineItemState,
  action: ProductLineItemAction,
): ProductLineItemState => {
  switch (action.type) {
    case ProductLineItemActionType.SET_LINE_ITEM_QUANTITY:
      return {
        ...state,
        lineItemQuantity: action.add ? state.lineItemQuantity + action.value : action.value,
      };
    case ProductLineItemActionType.SET_IS_SKU_CAP_REACHED:
      return {
        ...state,
        isSkuCapReached: action.isSkuCapReached,
      };
    case ProductLineItemActionType.SET_ADJUSTED_QUANTITY:
      return {
        ...state,
        adjustedQuantity: action.adjustedQuantity,
      };
    case ProductLineItemActionType.SET_HAS_VALIDATED:
      return {
        ...state,
        hasValidated: action.hasValidated,
      };
    case ProductLineItemActionType.SET_HAS_GENERIC_ERROR:
      return {
        ...state,
        hasGenericError: action.hasGenericError,
      };
    default:
      return state;
  }
};
