/* eslint-disable consistent-return */

/* eslint-disable no-restricted-syntax */

/* eslint-disable promise/always-return */

/* eslint-disable promise/prefer-await-to-then */

/* eslint-disable promise/catch-or-return */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from '@apollo/client';
import type { NextLink, Operation } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { isLocale } from '@shared/localisation';
import { getBasketPageLink } from '../../utils/cartUtils';
import { isServer } from '../../utils/ssr';
import { getItem, removeItem, setItem } from '../../utils/storage';

type ErrorHandler = (forward: NextLink, operation: Operation) => any;

const errorStates: Record<string, ErrorHandler> = {
  TokenExpired: handleTokenExpiration,
  // CommerceTools extension error
  HttpError: retryRequest,
};

export const errorLink = onError(({ graphQLErrors, forward, operation }) => {
  const handler = getFirstHandler(graphQLErrors);
  if (handler) {
    return handler(forward, operation);
  }
});

function handleTokenExpiration(forward: NextLink, operation: Operation) {
  // TODO: Add refresh token logic for SSR
  if (isServer()) return;
  return new Observable((observer) => {
    fetch('/api/refresh-session', { method: 'POST' }).then(async (response) => {
      if (response.status >= 400) {
        const locale = isLocale(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : '';
        const clearDataInput = getItem('CLEAR_DATA_INPUT', false, 'sessionStorage');
        // eslint-disable-next-line promise/no-nesting
        await fetch('/api/clear-user-data', { method: 'POST', body: clearDataInput })
          .then(() => {
            removeItem('CLEAR_DATA_INPUT', 'sessionStorage');
          })
          .finally(() => {
            if (
              location.pathname.includes('/checkout') ||
              location.pathname.includes(`/${getBasketPageLink(locale)}`)
            ) {
              setItem('SESSION_TIMEOUT_NOTIFICATION', true, true, 'sessionStorage');
              setItem('LOGGED_OUT', true, true, 'sessionStorage');
              location.assign(`${origin}/${locale}/${getBasketPageLink(locale)}`);
            }
            if (location.pathname.includes('/my-account')) {
              location.assign(`${origin}/${locale}/login`);
            } else {
              location.assign(location.href);
            }
          });
        return;
      }

      const subscriber = {
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      };
      // Retry operation
      forward(operation).subscribe(subscriber);
    });
  });
}

function retryRequest(forward: NextLink, operation: Operation) {
  return new Observable((observer) => {
    const subscriber = {
      next: observer.next.bind(observer),
      error: observer.error.bind(observer),
      complete: observer.complete.bind(observer),
    };
    forward(operation).subscribe(subscriber);
  });
}

function getFirstHandler(graphQLErrors?: any) {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const handler = errorStates[err.errorType];
      if (handler) {
        return handler;
      }
    }
  }
  return null;
}
