/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans, useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useLocaleContext } from '../../context/localeContext';
import { colours, fontWeights } from '../../stylings';
import { createAnchorTagHref } from '../../utils/transformers';

const S = {
  Text: styled.span`
    color: ${colours.NAVY_BLUE};
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0;
    padding: 0;
    a {
      background-color: inherit;
      border: none;
      color: ${colours.NAVY_BLUE};
      font-family: inherit;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 22px;
      margin: 0;
      padding: 0;
      text-decoration: underline;
      ${fontWeights.SemiBold600}
    }
  `,
};

export function MigratedUserError() {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();
  const reset = t('login.migration.error.reset');
  return (
    <S.Text data-testid="migratedUserError" role="status">
      <Trans
        i18nKey="login.migration.error.message"
        values={{
          reset,
        }}
        components={[
          <a
            href={createAnchorTagHref({
              locale,
              path: 'reset-password',
            })}
            data-testid="resetPasswordLink"
            key="reset-password"
            aria-label={reset}
          />,
        ]}
        t={t}
      />
    </S.Text>
  );
}
