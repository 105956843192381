import type { RefObject } from 'react';
import { type HTMLAttributes, type ReactNode, forwardRef } from 'react';
import styled from 'styled-components';

type FieldsetProps = HTMLAttributes<HTMLFieldSetElement> & {
  children: ReactNode;
  label?: string;
  disabled?: boolean;
};

const S = {
  Fieldset: styled.fieldset`
    border: none;
    padding: 0;
  `,
};

export const Fieldset = forwardRef(({ children, label, disabled = false, ...props }: FieldsetProps, ref) => (
  <S.Fieldset {...props} disabled={disabled} ref={ref as RefObject<HTMLFieldSetElement>}>
    {!!label && <legend className="visually-hidden">{label}</legend>}
    {children}
  </S.Fieldset>
));
