/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import type GooglePay from '@adyen/adyen-web/dist/types/components/GooglePay/GooglePay';
import type UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import type { Order } from '@adyen/adyen-web/dist/types/types';
import { useApolloClient } from '@apollo/client';
import { getOnOrderPlacedCaptureConfig } from '@shared/utils/src/adyen';
import { countryCodeMapper, currencyMapper, storeKeyMapper } from '@shared/utils/src/ct';
import type { CardDetailsInput, CartAddress } from '../codegen/types';
import { useCartContext, useFeatureFlagsContext, useLocaleContext, useUserContext } from '../context';
import type { PaymentContextType } from '../context';
import type { GetCartQuery } from '../graphqlDocument/cart/getCart.generated';
import { GetCartDocument } from '../graphqlDocument/cart/getCart.generated';
import type { GetOrderedCartQuery } from '../graphqlDocument/cart/getOrderedCart.generated';
import { GetOrderedCartDocument } from '../graphqlDocument/cart/getOrderedCart.generated';
import { usePlaceOrderMutation } from '../graphqlDocument/order/placeOrder.generated';
import { validateCartItemsStock } from '../services';
import { colours } from '../stylings';
import type { PaymentResponseType } from '../types';
import { paymentMethods } from '../types';
import {
  IS_GC_PAYMENT_METHOD,
  STAR1Locales,
  STAR2LocalesStandalone,
  createAnchorTagHref,
  getAmount,
  getCtLocale,
  getItem,
  localeToUnderscoreUppercase,
  localeToUpperCase,
  paymentRefusalReason,
  setItem,
} from '../utils';
import { isHomeDeliveryMethod } from '../utils/deliveryUtils';
import { sendAddPaymentInfoEvent } from '../utils/gtm/events/add_payment_info/sendAddPaymentInfoEvent';
import { newRelicAvailabilityChecker } from '../utils/newrelic';
import { useDidMount } from './useDidMount';
import { useNRBASession } from './useNRBASession';

const clientKey = process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY ?? '';

const ADYEN_IFRAME_ERROR = 'secured field iframes';

export const setPaymentMethodInStorage = (paymentMethod: string | null, paymentType: string | null) => {
  if (
    paymentMethod === paymentMethods.scheme ||
    paymentMethod === paymentMethods.giftcard ||
    paymentMethod === paymentMethods.groupklarna
  ) {
    setItem('CUSTOMER_PAYMENT_METHOD', paymentMethod, true, 'localStorage');
  } else {
    setItem('CUSTOMER_PAYMENT_METHOD', paymentType, true, 'localStorage');
  }
};

const createTranslation = (locale: string) =>
  // eslint-disable-next-line global-require, import/no-dynamic-require
  require(`@shared/localisation/src/translations/b2c/${locale}/app-orders-common.json`);

const formatAddress = (address?: CartAddress | null) =>
  address
    ? {
        country: address?.country,
        city: address?.city,
        houseNumberOrName: address?.line2,
        postalCode: address?.postalCode,
        street: address?.line1,
        stateOrProvince: address?.state,
      }
    : null;

export function useAdyenConfig(paymentContext: PaymentContextType) {
  const router = useRouter();
  const { locale } = useLocaleContext();
  const { basketTotalCent, cartId, anonymousId, miniCart } = useCartContext();
  const { isDigitalCart } = miniCart;
  const { customerData } = useUserContext();
  const { isProductTrueFitEnabled } = useFeatureFlagsContext();
  const nrbaContextHeader = useNRBASession();
  const userId = customerData?.id ?? '';
  const {
    paymentData,
    setAdyenComponent,
    setIsPaymentBtnDisabled,
    customerPaymentMethod,
    setPaymentError,
    setAvailableAdyenComponents,
    setValidityStatus,
    setCheckedStatus,
    setAdyenCheckout,
    setGiftCardOrder,
    setCanPayWithGiftCards,
    setCustomerPaymentMethod,
    isCSA,
    setOnErrorGiftCard,
    setIsErrorGiftCardInput,
    setGiftCardStatus,
    setIsGiftCardEnough,
    isGiftCardEnough,
    isGiftCardRemoved,
    setPaymentInProgress,
    kountSessionId,
    setIsIframeError,
  } = paymentContext;
  const apolloClient = useApolloClient();
  const isMounted = useDidMount();
  const [placeOrder, { data: placeOrderData, error: placeOrderError, loading: placeOrderLoading }] =
    usePlaceOrderMutation(nrbaContextHeader);

  const translations = {
    [locale]: createTranslation(
      !STAR1Locales.includes(locale) && !STAR2LocalesStandalone.includes(locale) ? locale.split('-')[0] : locale,
    ),
  };

  const getCartAddresses = useCallback(async () => {
    const { data: cart } = await apolloClient.query<GetCartQuery>({
      query: GetCartDocument,
      variables: {
        input: {
          anonymousId,
          cartId,
          locale: getCtLocale(locale),
          storeKey: storeKeyMapper(locale),
        },
      },
      ...nrbaContextHeader,
    });

    return {
      cartBillingAddress: formatAddress(cart.getCart?.billingAddress),
      // set delivery address only in case of home delivery
      cartDeliveryAddress: isHomeDeliveryMethod(cart.getCart?.deliveryMethod)
        ? formatAddress(cart.getCart?.deliveryAddress)
        : null,
    };
  }, [anonymousId, apolloClient, cartId, locale, nrbaContextHeader]);

  const resolveData = useCallback(
    async (data: any) => {
      const { cartBillingAddress, cartDeliveryAddress } = await getCartAddresses();
      const result = { ...data };

      if (cartBillingAddress) {
        result.billingAddress = cartBillingAddress;
      }
      /* When the cart only contains digital products such as an e-gift card that does not require physical shipping, the
       * customer won’t be able to input a delivery address at checkout. The delivery address is then set as the same
       * value as the billing address when the cart is only digital, else it is set as the delivery address input by the
       * customer at checkout. */
      if (isDigitalCart && cartBillingAddress) {
        result.deliveryAddress = cartBillingAddress;
      } else if (cartDeliveryAddress) {
        result.deliveryAddress = cartDeliveryAddress;
      }

      return result;
    },
    [getCartAddresses, isDigitalCart],
  );

  const { isOrderRiskInquiryEnabled } = useFeatureFlagsContext();

  const configurableCardDetailsRef = useRef({ first6: '', last4: '' });

  // Kount RIS fraud check
  const placeOrderMutation = useCallback(
    async (cardDetails: CardDetailsInput | undefined = undefined) =>
      placeOrder({
        variables: {
          input: {
            anonymousId,
            cartId,
            sessionId: kountSessionId,
            storeKey: storeKeyMapper(locale),
            ...(isOrderRiskInquiryEnabled && { cardDetails }),
          },
        },
      }),
    [anonymousId, cartId, locale, placeOrder, kountSessionId, isOrderRiskInquiryEnabled],
  );

  const onBeforeSubmit = useCallback(
    async (data: any, actions: any) => {
      setIsPaymentBtnDisabled(true);
      validateCartItemsStock({
        locale,
        apolloClient,
        input: {
          cartId,
          locale: getCtLocale(locale),
        },
        nrbaContextHeader,
        onSuccess: async () => {
          actions.resolve(await resolveData(data));
        },
      });
    },
    [apolloClient, cartId, locale, setIsPaymentBtnDisabled, nrbaContextHeader, resolveData],
  );

  const goToCheckoutErrorPage = () => {
    window.location.href = createAnchorTagHref({ locale, path: '/checkout/payment/error' });
  };

  useEffect(() => {
    if (!isMounted || !paymentData || isCSA) return;
    const createCheckout = async () => {
      const AdyenCheckout = (await import('@adyen/adyen-web')).default;
      const checkout = await AdyenCheckout({
        locale,
        clientKey,
        environment: clientKey.split('_')[0],
        analytics: {
          enabled: true,
        },
        session: {
          id: paymentData.session.id,
          sessionData: paymentData.session.sessionData,
        },
        onPaymentCompleted: async (result: PaymentResponseType, component: UIElement) => {
          newRelicAvailabilityChecker(() => {
            window.newrelic.addPageAction('checkout_payment_completed', {
              cart_id: cartId,
              payment_result: result.resultCode !== undefined ? result.resultCode : '',
              payment_session_id: paymentData.session.id,
            });
          });

          setPaymentError(paymentRefusalReason(result.resultCode));

          if (result?.resultCode === 'Authorised' || result?.resultCode === 'Received') {
            const { data } = await apolloClient.query<GetOrderedCartQuery>({
              query: GetOrderedCartDocument,
              variables: {
                input: {
                  orderNumber: cartId,
                  locale: getCtLocale(locale),
                },
              },
              ...nrbaContextHeader,
            });
            const { id, lineItems } = data.getOrderedCart;

            // TODO: Move truefit logic to checkout/confirmation page.
            if (isProductTrueFitEnabled) {
              (window as any).tfcapi('track', 'checkout', {
                userId,
                orderId: id,
                locale: localeToUnderscoreUppercase(locale),
                products: lineItems.map((item) => ({
                  productId: item.productKey,
                  colorId: item.colour,
                  quantity: item.quantity,
                  price: +getAmount(item.price.value.centAmount),
                  currency: item.price.value.currencyCode,
                  size: `${item.size}${item.fit ? ` ${item.fit}` : ''}`,
                  sku: item.variant.sku,
                })),
              });
            }

            router.push(
              {
                pathname: createAnchorTagHref({ locale, path: '/checkout/confirmation' }),
              },
              undefined,
              {
                shallow: true,
              },
            );
            setItem('PAYMENT_RESULT_CODE', result.resultCode, true, 'localStorage');
          } else {
            setPaymentInProgress(false);
            setIsPaymentBtnDisabled(false);
          }
        },
        onError: (error: any) => {
          newRelicAvailabilityChecker(() => {
            window.newrelic.addPageAction('checkout_payment_error', {
              cart_id: cartId,
              payment_error_name: error?.name ?? '',
              payment_error_message: error?.message ?? '',
              payment_session_id: paymentData.session.id,
            });
          });
          const isFromGiftCard = !!getItem(IS_GC_PAYMENT_METHOD, true, 'sessionStorage');

          /* When user is on payment page, after a couple of seconds 'iframe failed to load' error occur.
           * It is internal adyen thing, which does not break payment in any way.
           * The only thing that helps with that is to remount adyen component in AdyenWebComponent.tsx
           * This internal error seems to be fixed after bumping @adyen/adyen-web from 5.43.0 to 5.63.0 */
          if (error.message.toLowerCase().includes(ADYEN_IFRAME_ERROR)) {
            setIsIframeError(true);
          } else if (error.name !== 'CANCEL' && customerPaymentMethod !== paymentMethods.paypal && !isFromGiftCard) {
            goToCheckoutErrorPage();
          }

          // For some reason, when `Add another gift card` is clicked
          // and an error occurs (wrong PIN, no balance, etc) it goes here (generic Adyen onError)
          // instead of GiftCard onError and customerPaymentMethod gets overwritten as well.
          // As workaround, added a session storage variable to indicate that user clicked on
          // `Add another gift card` so that when an error occurs, it does not get redirected to
          // /checkout/payment/error
          if (isFromGiftCard && !error.message.toLowerCase().includes(ADYEN_IFRAME_ERROR)) {
            setOnErrorGiftCard(true);
            if (!isGiftCardEnough || !isGiftCardRemoved) setIsErrorGiftCardInput(true);
          }
        },
        onChange: (state: any) => {
          if (state.data.paymentMethod.type === paymentMethods.scheme) {
            setValidityStatus(state.errors);
          }
          setCheckedStatus(state.valid);
          setCustomerPaymentMethod(state.data.paymentMethod.type);

          if (state.data.paymentMethod.type !== paymentMethods.giftcard) {
            setIsPaymentBtnDisabled(!state.isValid);
          } else if (state.data.paymentMethod.type === paymentMethods.giftcard) {
            const giftCardInputs = document.getElementsByClassName('adyen-checkout__input--error');
            setIsErrorGiftCardInput(!!giftCardInputs.length);
            setGiftCardStatus(state.isValid);
          }
          if (state.isValid) setPaymentError(false);
        },
        amount: {
          currency: currencyMapper[locale],
          value: basketTotalCent,
        },
        countryCode: countryCodeMapper(locale),
        allowPaymentMethods: paymentData.paymentMethods.map(({ type }) => type),
        translations,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            positionHolderNameOnTop: true,
            showBrandIcon: true,
            showPayButton: false,
            brands: paymentData.paymentMethods.find(({ type }) => type === paymentMethods.scheme)?.brands as string[],
            styles: {
              placeholder: {
                padding: '0',
                color: colours.BLACK,
                fontFamily: 'Montserrat, sans-serif',
              },
            },
            onBinValue: (result: any) => {
              if (result.binValue?.trim() !== '') {
                configurableCardDetailsRef.current.first6 = result.binValue;
              }
            },
            onFieldValid: (result: any) => {
              if (result.fieldType === 'encryptedCardNumber' && result.endDigits?.trim() !== '') {
                configurableCardDetailsRef.current.last4 = result.endDigits;
              }
            },
            beforeSubmit: async (data, element, actions) => {
              setIsPaymentBtnDisabled(true);
              setPaymentInProgress(true);
              const result = await placeOrderMutation(configurableCardDetailsRef.current);
              if (result.data?.placeOrder.accepted) {
                actions.resolve(await resolveData(data));
              } else {
                actions.reject();
                setPaymentError(true);
                setIsPaymentBtnDisabled(false);
                setPaymentInProgress(false);
              }
            },
          },
          paypal: {
            intent: getOnOrderPlacedCaptureConfig(storeKeyMapper(locale))?.has('paypal') ? 'capture' : 'authorize',
            blockPayPalPayLaterButton: true,
            blockPayPalCreditButton: true,
            showPayButton: true,
            style: {
              color: 'blue',
              label: 'checkout',
            },
            blockPayPalVenmoButton: true,
            locale: localeToUpperCase(locale),
            async beforeSubmit(data, element, actions) {
              setIsPaymentBtnDisabled(true);
              setPaymentInProgress(true);
              const result = await placeOrderMutation();
              if (result.data?.placeOrder.accepted) {
                sendAddPaymentInfoEvent(miniCart, 'paypal', cartId);
                setPaymentMethodInStorage('paypal', 'PayPal');
                onBeforeSubmit(data, actions);
              } else {
                actions.reject();
                setPaymentError(true);
                setIsPaymentBtnDisabled(false);
                setPaymentInProgress(false);
              }
            },
          },
          googlepay: {
            showPayButton: true,
            buttonColor: 'default',
            buttonType: 'checkout',
            buttonSizeMode: 'fill',
            async beforeSubmit(data, component, actions) {
              setIsPaymentBtnDisabled(true);
              setPaymentInProgress(true);
              const result = await placeOrderMutation();
              if (result.data?.placeOrder.accepted) {
                sendAddPaymentInfoEvent(miniCart, 'googlepay', cartId);
                setPaymentMethodInStorage('googlepay', 'GooglePay');
                actions.resolve(await resolveData(data));
              } else {
                actions.reject();
                setPaymentError(true);
                setIsPaymentBtnDisabled(false);
                setPaymentInProgress(false);
              }
            },
          },
          applepay: {
            showPayButton: true,
            buttonType: 'check-out',
            buttonColor: 'black',
            async beforeSubmit(data, element, actions) {
              setIsPaymentBtnDisabled(true);
              setPaymentInProgress(true);
              const result = await placeOrderMutation();
              if (result.data?.placeOrder.accepted) {
                sendAddPaymentInfoEvent(miniCart, 'apple pay', cartId);
                setPaymentMethodInStorage('applepay', 'ApplePay');
                onBeforeSubmit(data, actions);
              } else {
                actions.reject();
                setPaymentError(true);
                setIsPaymentBtnDisabled(false);
                setPaymentInProgress(false);
              }
            },
          },
          klarna: {
            showPayButton: false,
            useKlarnaWidget: false,
            beforeSubmit: async (data, element, actions) => {
              setIsPaymentBtnDisabled(true);
              setPaymentInProgress(true);
              const result = await placeOrderMutation();
              if (result.data?.placeOrder.accepted) {
                actions.resolve(await resolveData(data));
              } else {
                actions.reject();
                setPaymentError(true);
                setIsPaymentBtnDisabled(false);
                setPaymentInProgress(false);
              }
            },
          },
          klarna_account: {
            showPayButton: false,
            useKlarnaWidget: false,
            beforeSubmit: async (data, element, actions) => {
              setIsPaymentBtnDisabled(true);
              setPaymentInProgress(true);
              const result = await placeOrderMutation();
              if (result.data?.placeOrder.accepted) {
                actions.resolve(await resolveData(data));
              } else {
                actions.reject();
                setPaymentError(true);
                setIsPaymentBtnDisabled(false);
                setPaymentInProgress(false);
              }
            },
          },
          giftcard: {
            // Called after a first gift card is added AND after submitting payment
            beforeSubmit: async (data: any, element: UIElement<any>, actions: any) => {
              setIsPaymentBtnDisabled(true);
              setPaymentInProgress(true);
              const result = await placeOrderMutation();
              if (result.data?.placeOrder.accepted) {
                const { cartBillingAddress, cartDeliveryAddress } = await getCartAddresses();
                actions.resolve({
                  ...data,
                  ...(cartBillingAddress && { billingAddress: cartBillingAddress }),
                  ...(cartDeliveryAddress && { deliveryAddress: cartDeliveryAddress }),
                });
              } else {
                actions.reject();
                setPaymentError(true);
                setIsPaymentBtnDisabled(false);
                setPaymentInProgress(false);
              }
            },
            // Called when the gift card balance is less than the transaction amount.
            // https://docs.adyen.com/payment-methods/gift-cards/web-component/?tab=config-sessions_2#required-configuration-1
            onOrderCreated(orderStatus: Order) {
              setPaymentInProgress(false);
              setGiftCardOrder(orderStatus);
            },
            // Called when the gift card balance is enough to pay the full payment amount.
            // https://docs.adyen.com/payment-methods/gift-cards/web-component/?tab=config-sessions_2#required-configuration-1
            onRequiringConfirmation() {
              setCanPayWithGiftCards(true);
              setIsGiftCardEnough(true);
            },
            onError: (error: any, component: UIElement) => {
              console.error('GiftCard', error.name, error.message, error.stack, component);

              if (!error.message.toLowerCase().includes('secured field iframes')) {
                setOnErrorGiftCard(true);
                if (!isGiftCardEnough || !isGiftCardRemoved) setIsErrorGiftCardInput(true);
              }
            },
          },
        },
      });

      const components = await Promise.all(
        paymentData.paymentMethods.map(async ({ type }) => {
          const component = checkout.create(type);
          let isAvailable = true;
          // Google Pay, Apple Pay and Gift Cards components are not available in all countries and under all circumstances
          if ((component as GooglePay).isAvailable) {
            try {
              isAvailable = await (component as GooglePay).isAvailable();
            } catch {
              isAvailable = false;
            }
          }
          component.remove();
          return { [type]: !!isAvailable };
        }),
      );
      const availableComponents = components
        .filter((obj): obj is { [method: string]: boolean } => !!obj)
        .reduce((acc, obj) => ({ ...acc, ...obj }), {});

      setAdyenCheckout(checkout);
      setAvailableAdyenComponents(availableComponents);
    };

    createCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketTotalCent, isMounted, locale, paymentData, setAdyenComponent, setIsPaymentBtnDisabled, isCSA]);
}
