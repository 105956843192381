import { memo } from 'react';
import styled from 'styled-components';
import { ShoppingBag, ShoppingBagCheckout } from '../../../icons';
import { colours } from '../../../stylings';

const S = {
  ShoppingBagContainer: styled.div`
    align-items: center;
    align-self: flex-end;
    display: flex;
    position: relative;
    transition: transform 0.3s ease;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    & svg #shopping_bag_checkout_svg__a path {
      fill: ${colours.WHITE};
    }

    & svg {
      height: 22px;
      width: 22px;
    }

    &::before {
      color: ${colours.WHITE};
      content: attr(data-item-count);
      font-size: 8px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -25%);
    }

    &:hover,
    &:focus {
      transform: translateY(-2px);

      .hover-bg {
        background-color: ${colours.LIGHT_BLUE};
      }
    }
  `,
  CartHoverBackground: styled.div`
    height: 13px;
    left: 25%;
    position: absolute;
    top: 25%;
    width: 12px;
    z-index: -1;
  `,
};

type CartIconProps = {
  itemsInCart: number;
  className?: string;
};

export function CartIcon({ itemsInCart, className }: CartIconProps) {
  return (
    <S.ShoppingBagContainer className={className} data-item-count={itemsInCart} data-testid="cartIcon">
      <S.CartHoverBackground className="hover-bg" />
      {itemsInCart > 0 ? <ShoppingBagCheckout /> : <ShoppingBag />}
    </S.ShoppingBagContainer>
  );
}

export default memo(CartIcon);
