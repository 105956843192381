import type { HTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import type { ApolloError } from '@apollo/client';
import { ErrorMessage } from '../../baseComponents/errors';
import type { BffError } from '../../services/errors';

enum PasswordErrorType {
  LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException',
}

type PasswordErrorMessageProps = HTMLAttributes<HTMLParagraphElement> & {
  error: ApolloError | null;
};

export function PasswordErrorMessage({ error, ...props }: PasswordErrorMessageProps) {
  const { t } = useTranslation(['lib-global-common']);

  return (
    <ErrorMessage {...props}>
      {(error?.graphQLErrors?.[0] as unknown as BffError)?.errorType === PasswordErrorType.LIMIT_EXCEEDED_EXCEPTION
        ? t('forgot.password.limit.exceeded')
        : t('forgot.password.invalid.email')}
    </ErrorMessage>
  );
}
