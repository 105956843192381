import { Locale } from './localeHelper';
import { getItem, removeItem, setItem } from './storage';

export enum AppointmentStorageKeys {
  APPOINTMENT_BOOKING_PAGE_PATH = 'appointment-booking-page-url',
  APPOINTMENT_RESERVATION_DATA = 'appointment-reservation-data',
  APPOINTMENT_BOOKING_RESULT = 'appointment-booking-result',
}

export enum BookingResult {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const getAppointmentBookingPagePath = () => getItem(AppointmentStorageKeys.APPOINTMENT_BOOKING_PAGE_PATH);
export const setAppointmentBookingPagePath = (path: string) =>
  setItem(AppointmentStorageKeys.APPOINTMENT_BOOKING_PAGE_PATH, path);

export const getAppointmentReservationData = () => getItem(AppointmentStorageKeys.APPOINTMENT_RESERVATION_DATA);
export const setAppointmentReservationData = (id: string) =>
  setItem(AppointmentStorageKeys.APPOINTMENT_RESERVATION_DATA, id);

export const removeAppointmentReservationData = () => removeItem(AppointmentStorageKeys.APPOINTMENT_RESERVATION_DATA);

export const getAppointmentBookingResult = () => getItem(AppointmentStorageKeys.APPOINTMENT_BOOKING_RESULT);
export const setAppointmentBookingResult = (result: string) =>
  setItem(AppointmentStorageKeys.APPOINTMENT_BOOKING_RESULT, result);
export const removeAppointmentBookingResult = () => removeItem(AppointmentStorageKeys.APPOINTMENT_BOOKING_RESULT);

export const localesWithAppointments = [
  Locale.EN_GB,
  Locale.EN_IE,
  Locale.CT_OUTLET_LOCALE,
  Locale.STOREFRONT_OUTLET_LOCALE,
];

export function supportsAppointments(locale: string) {
  return localesWithAppointments.includes(locale);
}
