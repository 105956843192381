import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { Order } from '../../codegen/types';
import { TypographyStyles, colours, fontWeights, media, spacing } from '../../stylings';
import { convertISODateToDate } from '../../utils/dateUtils';
import { Locale } from '../../utils/localeHelper';
import { CreateReturnButton } from './CreateReturnButton';

const S = {
  Container: styled.div`
    background-color: ${colours.LIGHT_BLUE};
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXS};
    justify-content: space-between;
    left: -24px;
    padding: ${spacing.XS} ${spacing.S};
    position: relative;
    width: calc(100% + 48px);
    @media ${media.greaterThan('lg')} {
      flex-direction: row;
      gap: unset;
      left: 0;
      width: initial;
    }
  `,
  Details: styled.div`
    flex: 3;
    .orderNumber {
      font-size: 16px;
      line-height: 28px;
      margin: 0;
      padding: 0;
      .value {
        ${fontWeights.SemiBold600}
        font-size: 16px;
        line-height: 28px;
      }
    }
    .placeDate {
      ${TypographyStyles.Body.Tiny.Regular}
      margin: 0;
      padding: 0;
    }
  `,
  CreateAReturn: styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    button {
      ${TypographyStyles.CallToAction.Large.SemiBold}
      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      gap: ${spacing.XXXXS};
      justify-content: flex-start;
      padding: 0;
      text-decoration: underline;
      @media ${media.greaterThan('lg')} {
        justify-content: flex-end;
      }
    }
    @media ${media.greaterThan('lg')} {
      justify-content: flex-end;
    }
  `,
};

type DetailsAndReturnProps = {
  orderNumber: Order['orderNumber'];
  orderPlaceDate: Order['orderPlacedDate'];
  canBeReturned: boolean;
  returnLink: string;
};

export function DetailsAndReturn({ orderNumber, orderPlaceDate, canBeReturned, returnLink }: DetailsAndReturnProps) {
  const router = useRouter();
  const { t } = useTranslation(['lib-global-common']);

  return (
    <S.Container>
      <S.Details>
        <p className="orderNumber">
          {t('order.details.order.number')}: <span className="value">{orderNumber}</span>
        </p>
        <p className="placeDate">
          ({t('order.details.ordered')} {convertISODateToDate(orderPlaceDate, router.locale ?? Locale.DEFAULT_LOCALE)})
        </p>
      </S.Details>
      {canBeReturned && (
        <S.CreateAReturn>
          <CreateReturnButton orderNumber={orderNumber} returnLink={returnLink} />
        </S.CreateAReturn>
      )}
    </S.Container>
  );
}
