import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

export function sendAccordionClickEvent(accordionName: string) {
  sendDataLayerEvent(EventNamesType.accordion_click, {
    custom_data: {
      link_text: accordionName,
    },
  });
}
