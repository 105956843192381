import { css, keyframes } from 'styled-components';

const widthAnimation = keyframes`
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
`;

export const anchorTagHoverAnimation = ({ backgroundColor }: { backgroundColor: string }) => css`
  a {
    position: relative;

    &:before {
      background-color: ${backgroundColor};
      bottom: -2px;
      content: '';
      height: 1px;
      position: absolute;
      width: 0;
    }

    &:focus:before,
    &:hover:before {
      animation: ${widthAnimation} 0.5s forwards;
      @media screen and (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }
  }
`;
