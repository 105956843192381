import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useSortBy } from 'react-instantsearch';
import styled from 'styled-components';
import { Content, Item, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { Fieldset } from '../../../baseComponents/inputs/Fieldset';
import { Radio } from '../../../baseComponents/inputs/Radio';
import { useLocaleContext } from '../../../context/localeContext';
import { ArrowDown } from '../../../icons';
import { TypographyStyles, accordionAnimation, colours, media, spacing, zProductBadge } from '../../../stylings';
import { createSortByItems } from '../../../utils/algoliaSortByUtils';

const S = {
  Container: styled(Root)`
    display: none;

    @media ${media.greaterThan('lg')} {
      display: flex;
    }
  `,
  Trigger: styled(Trigger)`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 1px solid ${colours.GREY};
    color: ${colours.BLACK};
    display: none;
    margin-left: auto;
    min-height: 48px;
    padding: 9px ${spacing.XS};
    width: 220px;
    ${TypographyStyles.Body.Medium.SemiBold}

    .accordion-arrow-down {
      flex-shrink: 0;
      transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);
    }

    &[data-state='open'] {
      > .accordion-arrow-down {
        transform: rotate(180deg);
      }
      color: ${colours.MIDNIGHT_BLUE};
      width: 220px;
    }
    &:focus,
    &:hover {
      background-color: ${colours.LIGHT_BLUE};
    }

    @media (prefers-reduced-motion) {
      .accordion-arrow-down {
        transition: none;
      }
    }

    @media ${media.greaterThan('lg')} {
      display: flex;
    }
  `,
  Label: styled.label`
    align-items: center;
    color: ${colours.BLACK};
    cursor: pointer;
    display: flex;
    width: 100%;
    ${TypographyStyles.Body.Medium.SemiBold}
  `,
  RadioGroup: styled(Fieldset)<{ $isDropDownOpen: boolean }>`
    background-color: ${colours.WHITE};
    border-top: none;
    display: none;
    margin: 0;
    padding: 0;

    .checked {
      ${TypographyStyles.Body.Tiny.SemiBold}
    }

    > div {
      &:focus {
        background-color: ${colours.LIGHT_BLUE};
        outline-color: ${colours.OUTLINE_BORDER};
        outline-width: 2px;
      }

      &:hover {
        outline: none;
      }
    }

    @media ${media.greaterThan('lg')} {
      display: block;
    }
  `,
  RadioButton: styled(Radio)<{ $isDropDownOpen: boolean }>`
    ${TypographyStyles.Body.Tiny.Medium}
    border: none;
    gap: ${spacing.XXXXS};
    height: 100%;
    min-height: 38px;
    padding: ${spacing.XXXS};
    width: 100%;
    &:hover,
    &:focus-within {
      background-color: ${colours.LIGHT_BLUE};
      outline-color: ${colours.OUTLINE_BORDER};
      outline-width: 2px;
    }
    ${({ $isDropDownOpen }) => accordionAnimation($isDropDownOpen)}
  `,
  ButtonWrapper: styled.div`
    align-items: center;
    display: flex;
    height: 100%;
  `,
  Button: styled.button`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 1px solid ${colours.GREY};
    color: ${colours.BLACK};
    display: flex;
    height: 44px;
    padding: 9px ${spacing.XS};
    width: 160px;
  `,
  Header: styled.span`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.L};
    width: 268px;
    h4 {
      ${TypographyStyles.Headings.H4}
      font-weight: 600;
      height: 18px;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      width: 232px;
    }
  `,
  CloseButton: styled.button`
    background-color: inherit;
    border: none;
    svg {
      height: 10px;
      width: 10px;
    }
  `,
  DropdownContent: styled(Content)<{ $isDropDownOpen: boolean }>`
    border: solid 1px ${colours.BLACK};
    ${({ $isDropDownOpen }) => accordionAnimation($isDropDownOpen)}
    overflow: hidden;
    width: 220px;

    z-index: ${zProductBadge};
  `,
};

type UseAlgoliaFilterReturn = ReturnType<typeof useSortBy>;

export function SortBy({ refine, currentRefinement, options }: UseAlgoliaFilterReturn) {
  const { t } = useTranslation(['lib-global-common']);

  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const [isDefault, setIsDefault] = useState(true);

  const handleOpenDropDown = () => {
    setDropDownOpen((prev) => !prev);
  };

  const handleOnChangeValue = (value: string) => () => {
    if (value === currentRefinement) return;
    if (isDefault) {
      setIsDefault(false);
    }
    handleOpenDropDown();
    refine(value);
  };

  const handleOnCloseAutoFocus = (e: Event) => {
    // Avoid focusing the trigger in Safari upon dropdown closing
    e.preventDefault();
  };

  const getLabel = (currentSort: string) => options.find(({ value }) => value === currentSort)?.label || '';

  const triggerLabel = t(isDefault ? 'sort.by.menu.sort.by' : getLabel(currentRefinement)).trim();

  return (
    <S.Container open={isDropDownOpen} onOpenChange={handleOpenDropDown} modal={false}>
      <S.Trigger data-testid="sortByButton">
        <S.Label className="DropdownMenuLabel">{triggerLabel}</S.Label>
        <ArrowDown className="accordion-arrow-down" />
      </S.Trigger>

      <Portal>
        <S.DropdownContent
          data-testid="sortByDropdown"
          onCloseAutoFocus={handleOnCloseAutoFocus}
          $isDropDownOpen={isDropDownOpen}
        >
          <S.RadioGroup id="sort-by" label={triggerLabel} $isDropDownOpen={isDropDownOpen}>
            {options.map(({ value, label }) => (
              <Item key={value} onSelect={handleOnChangeValue(value)}>
                <S.RadioButton
                  $isDropDownOpen={isDropDownOpen}
                  label={t(label)}
                  value={value}
                  name="sort-by"
                  onChange={handleOnChangeValue(value)}
                  checked={currentRefinement === value}
                  className={currentRefinement === value ? 'checked' : ''}
                />
              </Item>
            ))}
          </S.RadioGroup>
        </S.DropdownContent>
      </Portal>
    </S.Container>
  );
}

export function SortByMenu() {
  const { locale } = useLocaleContext();
  const items = createSortByItems(locale);
  const sortBy = useSortBy({
    items,
  });

  return <SortBy {...sortBy} />;
}
export default SortByMenu;
