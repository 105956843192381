import styled from 'styled-components';
import type { PaymentMethods as PaymentMethodsSchema } from '@amplience/content-types/typings/c-payment-methods';
import { TypographyStyleMapping, spacing } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';
import { PaymentMethodsContainer, PaymentOptions } from './PaymentMethods';
import type { PaymentMethodsPlacement } from './PaymentMethods.utils';

const S = {
  Label: styled.span<{ $textSize?: string }>`
    ${({ $textSize }) => ($textSize ? TypographyStyleMapping[$textSize] : null)}
    display: block;
    margin-bottom: ${spacing.XXS};
  `,
};

export type PaymentMethodsProps = PaymentMethodsSchema;

export function AmpliencePaymentMethods({ placement, label, textSize }: PaymentMethodsProps) {
  return (
    <PaymentMethodsContainer placement={placement as PaymentMethodsPlacement}>
      {label && <S.Label $textSize={textSize}>{getLocalizedValue(label)}</S.Label>}
      <PaymentOptions placement={placement as PaymentMethodsPlacement} />
    </PaymentMethodsContainer>
  );
}
