import * as yup from 'yup';

export const CustomerSessionLoginSchema = yup
  .object()
  .shape({
    email: yup.string().email().nullable(),
    basketId: yup.string().nullable(),
  })
  .test({
    test(value) {
      const { email, basketId } = value;
      if (!email && !basketId) {
        return this.createError({
          message: 'At least one input is required',
          path: '',
        });
      }
      return true;
    },
  });
