import type { PriceTooltip as PriceTooltipSchema } from '@amplience/content-types/typings/c-price-tooltip';
import { useLocaleContext } from '../../context/localeContext';
import { ImagePartial } from '../../partials/image';
import { TypographyStyles } from '../../stylings';
import { Locale } from '../../utils/localeHelper';
import { useTooltip } from './Tooltip';
import {
  PriceTooltipArrow,
  PriceTooltipBodyText,
  PriceTooltipBubble,
  PriceTooltipContainer,
  PriceTooltipHeaderText,
  PriceTooltipIcon,
} from './Tooltip.styles';

export function PriceTooltip({ tooltipHeading, tooltipBody, tooltipIcon, shouldAttach }: PriceTooltipSchema) {
  const { locale } = useLocaleContext();
  const { isOpen, handleClick, buttonRef, containerRef, setArrowRef, styles, attributes } = useTooltip();

  if (![Locale.EN_GB, Locale.EN_IE, Locale.STOREFRONT_OUTLET_LOCALE].includes(locale as string) || !shouldAttach) {
    return null;
  }

  return (
    <PriceTooltipContainer role="tooltip">
      <PriceTooltipIcon
        onClick={handleClick}
        ref={buttonRef}
        aria-label="Price tooltip"
        type="button"
        data-testid="priceTooltipButton"
      >
        <ImagePartial
          altText={tooltipIcon?.image?.altText}
          src={tooltipIcon?.image?.image?.name ?? ''}
          width={14}
          height={14}
          assetType={tooltipIcon?.image?.assetType}
          defaultHost={tooltipIcon?.image?.image?.defaultHost}
          useOriginalSize={tooltipIcon?.image?.useOriginalSize}
        />
      </PriceTooltipIcon>
      {isOpen && (
        <PriceTooltipBubble
          ref={containerRef}
          style={styles.popper}
          {...attributes.popper}
          data-testid="priceTooltipModal"
        >
          <PriceTooltipArrow ref={setArrowRef} style={styles.arrow} className="arrow" />
          <PriceTooltipHeaderText>{tooltipHeading}</PriceTooltipHeaderText>
          <PriceTooltipBodyText text={tooltipBody?.richText ?? ''} fontSize={TypographyStyles.Body.Tiny.Regular} />
        </PriceTooltipBubble>
      )}
    </PriceTooltipContainer>
  );
}

export default PriceTooltip;
