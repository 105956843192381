import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { initInsights } from '../services';
import { initAfterCookieBanner } from '../utils';

export type AfterCookieBannerContextType = {
  promptRedirectAfterCookie: boolean;
  setPromptRedirectAfterCookie: Dispatch<SetStateAction<boolean>>;
};

const AfterCookieBannerContext = createContext<AfterCookieBannerContextType>({
  promptRedirectAfterCookie: false,
  setPromptRedirectAfterCookie: () => {},
});

export const useAfterCookieBannerContext = () => useContext(AfterCookieBannerContext);

export function AfterCookieBannerContextProvider({ children }: { children: ReactNode }) {
  const [promptRedirectAfterCookie, setPromptRedirectAfterCookie] = useState(false);

  useEffect(() => {
    initAfterCookieBanner(setPromptRedirectAfterCookie);
  }, []);

  useEffect(() => {
    initInsights();
  }, [promptRedirectAfterCookie]);

  const value = useMemo(
    () => ({
      setPromptRedirectAfterCookie,
      promptRedirectAfterCookie,
    }),
    [promptRedirectAfterCookie, setPromptRedirectAfterCookie],
  );

  return <AfterCookieBannerContext.Provider value={value}>{children}</AfterCookieBannerContext.Provider>;
}
