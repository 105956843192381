import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import type { OrderLayoutSlot } from '@amplience/content-types/typings/s-order-layout';
import type { GetOrderedCartQuery } from '../graphqlDocument/cart/getOrderedCart.generated';
import { useGetOrderedCartQuery } from '../graphqlDocument/cart/getOrderedCart.generated';
import { useNRBASession } from '../hooks';
import { getCtLocale } from '../utils/localeHelper';
import { useCartContext } from './cartContext';
import { useLocaleContext } from './localeContext';

type CheckoutConfirmationContextType = {
  orderLayout?: OrderLayoutSlot;
  isConfirmationPage?: boolean;
  orderDataLoading?: boolean;
  orderData?: GetOrderedCartQuery;
};

const CheckoutConfirmationContext = createContext<CheckoutConfirmationContextType>({
  orderLayout: undefined,
  isConfirmationPage: false,
  orderDataLoading: false,
  orderData: undefined,
});

export const useCheckoutConfirmationContext = () => useContext(CheckoutConfirmationContext);

// CheckoutConfirmationContext should contain variables needed for Checkout Confirmation section only.
export function CheckoutConfirmationContextProvider({
  children,
  orderLayout,
}: {
  children: ReactNode;
  orderLayout?: OrderLayoutSlot;
}) {
  const router = useRouter();
  const isConfirmationPage = router.asPath === '/checkout/confirmation';

  const { locale } = useLocaleContext();
  const { cartId } = useCartContext();

  const nrbaContextHeader = useNRBASession();

  const { loading: orderDataLoading, data: orderData } = useGetOrderedCartQuery({
    ...nrbaContextHeader,
    variables: {
      input: {
        orderNumber: cartId,
        locale: getCtLocale(locale),
      },
    },
    skip: !cartId || !isConfirmationPage,
  });

  const value = useMemo(
    () => ({ orderLayout, isConfirmationPage, orderDataLoading, orderData }),
    [orderLayout, isConfirmationPage, orderDataLoading, orderData],
  );

  return <CheckoutConfirmationContext.Provider value={value}>{children}</CheckoutConfirmationContext.Provider>;
}
