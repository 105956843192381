import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { NeedHelpSectionCheckout as NeedHelpSectionCheckoutSchema } from '@amplience/content-types/typings/c-need-help-section-checkout';

export type NeedHelpContextType = {
  needHelpData: NeedHelpSectionCheckoutSchema | null;
};

const NeedHelpContext = createContext<NeedHelpContextType>({
  needHelpData: {
    _meta: {
      schema: '',
    },
  },
});

export const useNeedHelpContext = () => useContext(NeedHelpContext);

export function NeedHelpContextProvider({
  children,
  needHelpData,
}: {
  children: ReactNode;
  needHelpData: NeedHelpSectionCheckoutSchema | null;
}) {
  const value = useMemo(() => ({ needHelpData }), [needHelpData]);

  return <NeedHelpContext.Provider value={value}>{children}</NeedHelpContext.Provider>;
}
