import styled, { css } from 'styled-components';
import { colours, media, spacing } from '../../stylings';

export const ColumnWrapper = styled.div`
  margin: 0 auto;
  max-width: 1440px;
`;

export const ColumnContent = styled.div`
  border-bottom: 1px solid ${colours.BLACK};
  display: grid;
  grid-auto-flow: row;
  justify-content: stretch;
  margin: 0 ${spacing.S};

  @media ${media.greaterThan('lg')} {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    grid-gap: ${spacing.M};
    justify-content: center;
    margin: 44px ${spacing.S} 0 ${spacing.S};
  }

  @media ${media.greaterThan('xl')} {
    margin: 44px 70px 0;
  }
`;
export const Content = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${colours.BLACK};
  }

  > *:not(:last-child) {
    border-bottom: 1px solid ${colours.BLACK};
  }

  @media ${media.greaterThan('lg')} {
    max-width: 300px;

    &:not(:last-child) {
      border-bottom: unset;
    }

    > *:first-child {
      padding-top: 0;
    }

    > *:not(:first-child) {
      padding: ${spacing.M} 0;
    }
  }
`;
export const BottomContent = styled.div<{ $numOfItems?: number }>`
  display: grid;
  grid-template-areas:
    'keyLinks'
    'smallLinks'
    'siteSwitcher';
  margin: 0 ${spacing.S};

  @media ${media.greaterThan('lg')} {
    column-gap: 115px;
    ${({ $numOfItems }) =>
      $numOfItems !== 3
        ? css`
            grid-template-columns: 1fr;
          `
        : css`
            grid-template-areas:
              'keyLinks keyLinks'
              'smallLinks siteSwitcher';
          `}
  }

  @media ${media.greaterThan('xl')} {
    margin: 0 70px;
  }
`;
