import styled from 'styled-components';
import type {
  FooterAccordion as FooterAccordionSchema,
  Link,
  LocalizedValue,
} from '@amplience/content-types/typings/c-footer-accordion';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { anchorTagHoverAnimation } from '../../stylings/anchorTag';
import { getLocalizedValue } from '../../utils/transformers';
import { MobileFooterAccordion } from './MobileFooterAccordion';

const S = {
  FooterAccordionWrapper: styled.div`
    @media ${media.greaterThan('lg')} {
      padding: ${spacing.M} 0;
    }
  `,
  FooterAccordionHeaderText: styled.span`
    ${TypographyStyles.Body.Medium.SemiBold}
  `,
  FooterAccordionLinks: styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    padding: 0;
  `,
  FooterAccordionCommonLinkItem: styled.li`
    ${TypographyStyles.Body.Tiny.Regular}

    /* stylelint-disable selector-type-no-unknown */
        ${() =>
      anchorTagHoverAnimation({
        backgroundColor: colours.BLACK,
      })}
      
    a {
      color: ${colours.BLACK};
      text-decoration: none;
    }
  `,
  AccordionLinkLabelIcon: styled.span`
    padding-right: ${spacing.XS};
  `,
  DesktopContainer: styled.div`
    display: none;

    @media ${media.greaterThan('lg')} {
      display: unset;
    }
  `,
  MobileContainer: styled.div`
    @media ${media.greaterThan('lg')} {
      display: none;
    }
  `,
};

export type FooterAccordionProps = FooterAccordionSchema;

export function AccordionLinkLabel({ link, label }: { link?: Link; label: LocalizedValue }) {
  const localizedLabel = getLocalizedValue(label);
  return (
    <LinkPartial {...link} ariaLabel={localizedLabel}>
      {localizedLabel}
    </LinkPartial>
  );
}

export function FooterAccordion({ footerColumnTitle, footerAccordionLinks }: FooterAccordionProps) {
  const headerText = getLocalizedValue(footerColumnTitle);

  return (
    <S.FooterAccordionWrapper>
      <S.DesktopContainer>
        <S.FooterAccordionHeaderText>{headerText}</S.FooterAccordionHeaderText>
        <S.FooterAccordionLinks>
          {footerAccordionLinks?.map(({ link, label }, index) => (
            <S.FooterAccordionCommonLinkItem key={`${label}-${index}`}>
              <AccordionLinkLabel link={link} label={label} />
            </S.FooterAccordionCommonLinkItem>
          ))}
        </S.FooterAccordionLinks>
      </S.DesktopContainer>
      <S.MobileContainer>
        <MobileFooterAccordion commonLinks={footerAccordionLinks} title={headerText} />
      </S.MobileContainer>
    </S.FooterAccordionWrapper>
  );
}
