import styled, { css } from 'styled-components';
import { TypographyStyles, spacing } from '../../../stylings';

export const ProductDetailRow = styled.tr`
  display: flex;
  flex-wrap: wrap;
  padding-right: ${spacing.XXXS};
  width: 100%;
`;

export const ProductDetailLabel = styled.th<{ $isFRCA?: boolean; $isGiftCard: boolean }>`
  ${TypographyStyles.Body.Tiny.Regular}
  margin-right: ${spacing.XXS};
  ${({ $isFRCA }) =>
    css`
      flex-basis: ${$isFRCA ? '60px' : '55px'};
      min-width: ${$isFRCA ? '60px' : '55px'};
    `}
  padding: 0;
`;

export const ProductDetailValue = styled.td<{ $isMessage: boolean }>`
  ${TypographyStyles.Body.Tiny.SemiBold}
  display: flex;
  flex-grow: 1;
  gap: 10px;
  padding: 0;
  position: relative;
`;

export const ProductDetailValueText = styled.span<{ $isMessage: boolean; $isEmailAddress: boolean }>`
  ${({ $isMessage }) =>
    $isMessage &&
    css`
      &::before {
        content: '"';
      }

      &::after {
        content: '"';
        display: inline-block;
      }
    `}
  ${({ $isEmailAddress }) =>
    $isEmailAddress &&
    css`
      word-break: break-word;
    `}
`;
