/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { GiftCardType } from '../../../context/cartContext';
import { ImagePartial } from '../../../partials/image';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

const S = {
  ImageWrapper: styled.div<{ $multiImage?: boolean; $type?: string }>`
    background-color: ${colours.PALE_GREY};
    height: 76px;
    position: relative;
    width: 76px;

    @media ${media.greaterThan('lg')} {
      height: 94px;
      width: 94px;
    }
  `,
  Quantity: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
    left: ${spacing.XXXXS};
    position: absolute;
    z-index: 1;
  `,
  GiftCardMessage: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
  `,
};

type MultiGroupDeliveryItemProps = {
  imageURLs: Array<string>;
  quantity: number;
  productTypeKey: string;
};

export function MultiGroupDeliveryItem({ imageURLs, quantity, productTypeKey }: MultiGroupDeliveryItemProps) {
  const { t } = useTranslation(['app-orders-common']);

  return (
    <S.ImageWrapper>
      <S.Quantity>{t('checkout.split.quantity', { count: quantity })}</S.Quantity>
      <ImagePartial src={imageURLs?.length ? imageURLs[0] : ''} layout="fill" objectFit="cover" />
    </S.ImageWrapper>
  );
}
