import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type SizeCalculatorQueryVariables = Types.Exact<{
  input: Types.SizeCalculatorInput;
}>;

export type SizeCalculatorQuery = {
  __typename?: 'Query';
  sizeCalculator: {
    __typename?: 'SizeWithCategory';
    size: string;
    ageGroup: string;
    fit: string;
    globalFit: string;
    globalSize: string;
  };
};

export const SizeCalculatorDocument = gql`
  query sizeCalculator($input: SizeCalculatorInput!) {
    sizeCalculator(input: $input) {
      size
      ageGroup
      fit
      globalFit
      globalSize
    }
  }
`;

/**
 * __useSizeCalculatorQuery__
 *
 * To run a query within a React component, call `useSizeCalculatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSizeCalculatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSizeCalculatorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSizeCalculatorQuery(
  baseOptions: Apollo.QueryHookOptions<SizeCalculatorQuery, SizeCalculatorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SizeCalculatorQuery, SizeCalculatorQueryVariables>(SizeCalculatorDocument, options);
}
export function useSizeCalculatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SizeCalculatorQuery, SizeCalculatorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SizeCalculatorQuery, SizeCalculatorQueryVariables>(SizeCalculatorDocument, options);
}
export type SizeCalculatorQueryHookResult = ReturnType<typeof useSizeCalculatorQuery>;
export type SizeCalculatorLazyQueryHookResult = ReturnType<typeof useSizeCalculatorLazyQuery>;
export type SizeCalculatorQueryResult = Apollo.QueryResult<SizeCalculatorQuery, SizeCalculatorQueryVariables>;
