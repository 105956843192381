import nodeFetch, { RequestInfo, RequestInit } from 'node-fetch';
import http, { AgentOptions as HttpAgentOptions } from 'http';
import https, { AgentOptions as HttpsAgentOptions } from 'https';

const agentOptions: HttpAgentOptions | HttpsAgentOptions = {
  keepAlive: true,
  keepAliveMsecs: 3500,
  scheduling: 'fifo',
};

const httpAgent = new http.Agent(agentOptions);
const httpsAgent = new https.Agent(agentOptions);

export const fetch = (url: RequestInfo, options: RequestInit = {}) =>
  nodeFetch(url, {
    agent: (parsedURL) => {
      if (parsedURL.protocol === 'http:') {
        return httpAgent;
      }
      return httpsAgent;
    },
    ...options,
  });
