import { format } from 'date-fns';
import * as dateLocale from 'date-fns/locale';
import type { TFunction } from 'next-i18next';
import type { TimeSlot } from '../codegen/types';
import { Locale, LocaleStar2, localeToUpperCase } from './localeHelper';

const timeFormat = 'h:mmaaa';

function getDateFormat(locale: string) {
  if (locale === Locale.EN_US) {
    return 'eee, MMM d, y';
  }
  if (locale === Locale.FR_CA) {
    return 'eeee, d MMM, y';
  }
  return 'eee, d MMM, y'; // English: UK, ROI, Canada
}

export const { frCA, enUS, enCA, enGB, enIE } = dateLocale;
const localeArray = {
  [Locale.FR_CA]: frCA,
  [Locale.EN_CA]: enCA,
  [Locale.EN_GB]: enGB,
  [Locale.EN_US]: enUS,
  [Locale.EN_IE]: enIE,
};

export const WEEK_DAYS = new Map([
  [1, 'monday'],
  [2, 'tuesday'],
  [3, 'wednesday'],
  [4, 'thursday'],
  [5, 'friday'],
  [6, 'saturday'],
  [0, 'sunday'],
]);

export function getDateLocale(locale: string) {
  return { locale: localeArray[locale as keyof typeof localeArray] };
}

export function getTomorrow() {
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  tomorrowDate.setHours(0, 0, 0, 0);
  return tomorrowDate;
}

export function formatDate(date: Date, locale: string) {
  return format(date, getDateFormat(locale), getDateLocale(locale));
}
export function formatTime(date: Date, locale: string) {
  // ACs of REP-7801 state that time should be in am/pm format for all locales
  try {
    return format(date, timeFormat, getDateLocale(locale));
  } catch (e) {
    console.error(`Invalid date: ${date}, ${e}`);
    return null;
  }
}

/**
 * Removes timezone part from ISO date string (e.g. -05:00, +01:00 or Z)
 * We need to display time in the timezone of the store, not local time.
 * The easiest way to achieve this is to remove timezone information and format the date as if it was local.
 */
function stripTimezoneFromISO(isoDate: string) {
  return isoDate?.replace(/(T.*)(Z|[+-](\d{2}:?\d{2}$)|([+-]\d{2}$))/, '$1');
}

export function formatISODate(isoDate: string, locale: string) {
  if (!isoDate) return null;
  return formatDate(new Date(stripTimezoneFromISO(isoDate)), locale);
}

export function formatISOTime(isoDate: string, locale: string) {
  if (!isoDate) return null;
  return formatTime(new Date(stripTimezoneFromISO(isoDate)), locale);
}

export function formatTimeslot(timeslot: TimeSlot, locale: string) {
  try {
    const date = new Date();
    date.setHours(timeslot.hours);
    date.setMinutes(timeslot.minutes);
    return format(date, 'HH:mm', getDateLocale(locale));
  } catch (e) {
    console.error(`Unable to convert timeslot: ${timeslot}, ${e}`);
    return null;
  }
}

export function isStillLive(startDate: string, endDate: string) {
  if (!startDate && !endDate) return false;

  let start: number;
  let end: number;
  const today = new Date().getTime();

  if (startDate && !endDate) {
    start = new Date(startDate).getTime();
    return today >= start;
  }

  if (!startDate && endDate) {
    end = new Date(endDate).getTime();
    return today <= end;
  }

  start = new Date(startDate).getTime();
  end = new Date(endDate).getTime();
  return today >= start && today <= end;
}

export const convertISODateToDate = (date: string, locale: string, withDay?: boolean, fullDay?: boolean) => {
  try {
    if (!date) return '';
    const isENCA = locale === 'en-ca';
    const isENUS = locale === 'en-us';
    const newDate = new Date(date);

    const localizedDate = new Intl.DateTimeFormat(localeToUpperCase(locale), {
      dateStyle: withDay ? 'full' : 'long',
    }).format(newDate);

    if (!withDay) {
      const dateArray = localizedDate.split(' ');
      return !isENCA
        ? `${dateArray[0].replace(',', '')} ${dateArray[1]} ${dateArray[2]}`
        : `${dateArray[1].replace(',', '')} ${dateArray[0]} ${dateArray[2]}`;
    }

    if (!fullDay) {
      const dateArray = localizedDate.split(' ');
      const isThursday = dateArray[0] === 'Thursday,';
      return !isENCA
        ? `${dateArray[0].substring(0, isThursday ? 4 : 3)} ${dateArray[1].replace(',', '')} ${dateArray[2]} ${
            dateArray[3]
          }`
        : `${dateArray[0].substring(0, isThursday ? 4 : 3)} ${dateArray[2].replace(',', '')}
       ${dateArray[1].replace(',', '')} ${dateArray[3]}`;
    }

    const dateArray = localizedDate.split(' ');
    return !isENCA
      ? `${dateArray[0]} ${dateArray[1].replace(',', '')} ${dateArray[2].replace(',', '')}${isENUS ? ',' : ''} ${
          dateArray[3]
        }`
      : `${dateArray[0]} ${dateArray[2].replace(',', '')} ${dateArray[1].replace(',', '')} ${dateArray[3]}`;
  } catch (error) {
    return '';
  }
};

const DAYS_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
const GERMAN_LANGUAGE_LOCALES = ['de-de', 'de-ch', 'de-at'];

export const deliveryDetailsDate = (date: string, locale: string, t: TFunction) => {
  if (!date) return '';

  const dateObj = new Date(date);
  const dayOfWeek = t(`week.day.${DAYS_OF_WEEK[dateObj.getDay()]}`);
  const dayOfMonth = dateObj.getDate();
  const month = t(`month.${MONTHS[dateObj.getMonth()]}`);

  if (GERMAN_LANGUAGE_LOCALES.includes(locale)) return `${dayOfWeek}, ${dayOfMonth}. ${month}`;
  if (locale === LocaleStar2.ES_ES) return `${dayOfWeek} ${dayOfMonth} de ${month}`;

  return `${dayOfWeek} ${dayOfMonth}${locale.includes('en') ? getOrdinalSuffix(dayOfMonth) : ''} ${month}`;
};

function getOrdinalSuffix(day: number) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}
