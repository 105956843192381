import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { BlueButton } from '../../baseComponents/button';
import { CheckCircleFilled } from '../../icons';
import { TypographyStyles, colours, media } from '../../stylings';
import { UserOpt } from '../../types/newsletter';

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 328px;
    @media ${media.greaterThan('lg')} {
      gap: 32px;
      max-width: initial;
      width: 628px;
    }
  `,
  CheckWrapper: styled.div`
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    @media ${media.greaterThan('lg')} {
      height: 80px;
    }

    svg {
      transform: scale(2);
      @media ${media.greaterThan('lg')} {
        transform: scale(3.29);
      }
    }
  `,
  MessageWrapper: styled.div`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 6px solid ${colours.SUCCESS_SECONDARY};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 24px;

    @media ${media.greaterThan('lg')} {
      border: 4px solid ${colours.SUCCESS_SECONDARY};
      padding: 40px 24px;
    }
  `,
  HeadingMessage: styled.h3`
    ${TypographyStyles.Headings.H3}
    color: ${colours.SUCCESS_PRIMARY};
  `,
  Message: styled.span`
    ${TypographyStyles.Body.Small.Regular}
    color: ${colours.BLACK};
  `,
  ContinueShopping: styled(BlueButton)`
    height: 48px;
    margin: 0;
    padding: 14px;
    @media ${media.greaterThan('lg')} {
      height: 50px;
    }
  `,
};

type OptSuccessMessageProps = {
  email: string;
  opt: UserOpt;
};

export function OptSuccessMessage({ email, opt }: OptSuccessMessageProps) {
  const { t } = useTranslation(['lib-global-common']);
  const router = useRouter();

  const isOptDown = opt === UserOpt.OPT_DOWN;
  const headingMessage = isOptDown ? t('newsletter.opt.down.thanks') : t('newsletter.opt.out.thanks');
  const message = isOptDown
    ? t('newsletter.opt.down.message', {
        email,
      })
    : t('newsletter.opt.out.message', {
        email,
      });

  const handleOnClickContinue = () => {
    router.push('/');
  };

  return (
    <S.Container>
      <S.MessageWrapper>
        <S.CheckWrapper>
          <CheckCircleFilled data-testid="unsubscribeSuccessCircle" />
        </S.CheckWrapper>
        <S.HeadingMessage data-testid="unsubscribeSuccessHeading">{headingMessage}</S.HeadingMessage>
        <S.Message data-testid="unsubscribeSuccessMessage">{message}</S.Message>
      </S.MessageWrapper>
      <S.ContinueShopping type="button" onClick={handleOnClickContinue} data-testid="continueShopping">
        {t('newsletter.continue.shopping')}
      </S.ContinueShopping>
    </S.Container>
  );
}

export default OptSuccessMessage;
