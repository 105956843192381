import { useEffect, useState } from 'react';

export function useInputAnimation(isEmpty?: boolean) {
  const [className, setClassName] = useState('');

  const handleFocus = () => {
    if (isEmpty) setClassName('going-up');
  };

  const handleBlur = () => {
    setClassName('');
  };

  useEffect(() => {
    if (!isEmpty) {
      setClassName('going-up');
    }
  }, [isEmpty]);

  return { handleFocus, handleBlur, className };
}
