import styled from 'styled-components';
import type { SmallFooterLinks as SmallFooterLinksSchema } from '@amplience/content-types/typings/c-small-footer-links';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { anchorTagHoverAnimation } from '../../stylings/anchorTag';
import { getLocalizedValue } from '../../utils/transformers';

export type SmallFooterLinksProps = SmallFooterLinksSchema;

const S = {
  Container: styled.div`
    border-bottom: 1px solid ${colours.BLACK};
    grid-area: smallLinks;
    padding: ${spacing.M} 0;

    @media ${media.greaterThan('lg')} {
      border-bottom: none;
    }
  `,
  Wrapper: styled.div`
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing.M};
    justify-content: center;

    @media ${media.greaterThan('lg')} {
      flex-wrap: nowrap;
      gap: 115px;
      justify-content: space-between;
    }
  `,
  SmallFooterLinks: styled.ul`
    margin: 0;
    padding: 0;
    text-align: center;

    @media ${media.greaterThan('lg')} {
      text-align: left;
    }
  `,
  LinkItem: styled.li`
    display: inline;

    &:not(:last-child) {
      margin: 0 ${spacing.XXS} 0 0;
    }

    /* stylelint-disable selector-type-no-unknown */
    ${() =>
      anchorTagHoverAnimation({
        backgroundColor: colours.BLACK,
      })}

    a {
      ${TypographyStyles.Body.Tiny.Regular}
      color: ${colours.BLACK};
      text-decoration: none;
    }
  `,
  CopyrightText: styled.p`
    ${TypographyStyles.Body.Tiny.Regular}
    align-self:center;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    @media ${media.greaterThan('lg')} {
      align-self: unset;
    }
  `,
};

export function SmallFooterLinks({ smallFooterLinks, copyrightText }: SmallFooterLinksProps) {
  return (
    <S.Container>
      <S.Wrapper data-testid="smallFooterLinksSection">
        <S.SmallFooterLinks>
          {smallFooterLinks?.map((smallFooterLink, index) => (
            <S.LinkItem key={`${smallFooterLink.label}-${index}`}>
              <LinkPartial {...smallFooterLink.link}>{getLocalizedValue(smallFooterLink.label)}</LinkPartial>
            </S.LinkItem>
          ))}
        </S.SmallFooterLinks>
        <S.CopyrightText>{getLocalizedValue(copyrightText)}</S.CopyrightText>
      </S.Wrapper>
    </S.Container>
  );
}
