import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type FilterDataType = {
  filter_category: string;
  filter_value: string;
};

export function sendRemoveCategoryFilterEvent(filterData: FilterDataType) {
  sendDataLayerEvent(EventNamesType.remove_category_filter, {
    custom_data: {
      ...filterData,
    },
  });
}
