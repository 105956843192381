import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type NotifyMeEventData = {
  custom_data: {
    customer_number: string | null | undefined;
    item_name: string;
    item_id: string;
    size: string;
    width: string;
  };
};

export const sendNotifyMeEvent = (data?: NotifyMeEventData) => {
  sendDataLayerEvent(EventNamesType.notify_me, data);
};
