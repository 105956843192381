import styled from 'styled-components';
import { TypographyStyles, colours, fontWeights, media, spacing } from '../../stylings';

export const BaseButton = styled.button`
  height: 50px;
`;

export const BlueVariant = styled.button`
  background-color: ${colours.DARK_BLUE};
  border: none;
  color: ${colours.WHITE};
  height: 44px;
  margin-bottom: ${spacing.XS};
  text-transform: uppercase;
  ${TypographyStyles.Button.SemiBold}
  &:hover,&:focus {
    background-color: ${colours.NAVY_BLUE};
  }
  &:focus-visible {
    outline-color: ${colours.DARK_ORANGE};
  }
  &:disabled {
    background-color: ${colours.LIGHT_GREY_2};
    border: 1px solid ${colours.MID_GREY};
    color: ${colours.GREY};
    cursor: not-allowed;

    & svg path {
      fill: ${colours.GREY};
    }
  }
`;

export const WhiteVariant = styled.button`
  background-color: ${colours.WHITE};
  border: solid 1px ${colours.DARK_BLUE};
  color: ${colours.DARK_BLUE};
  height: 44px;
  text-transform: uppercase;
  ${TypographyStyles.Button.SemiBold}

  &:hover,&:focus {
    background-color: ${colours.LIGHT_BLUE};
  }

  &:disabled {
    background-color: ${colours.LIGHT_GREY_2};
    border: 1px solid ${colours.MID_GREY};
    color: ${colours.GREY};
    cursor: not-allowed;

    & svg path {
      fill: ${colours.GREY};
    }
  }
`;

export const Back = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: max-content;
  line-height: 14px;
  margin-bottom: 30px;
  padding: 0;
  text-decoration: underline;
  ${fontWeights.SemiBold600}

  @media ${media.greaterThan('lg')} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const LightBlueVariant = styled.button`
  background-color: ${colours.LIGHT_BLUE};
  border: 1px solid ${colours.DARK_BLUE};
  color: ${colours.DARK_BLUE};
  height: 50px;
  padding: 0 14px;
  text-transform: uppercase;
  ${TypographyStyles.Button.SemiBold}
`;
