import { useCallback, useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'next-i18next';
import type { UseFormSetError } from 'react-hook-form';
import type { PaymentAmount } from '@adyen/adyen-web/dist/types/types';
import { useLocaleContext } from '../context/localeContext';
import { useCreateAdyenSessionMutation } from '../graphqlDocument/payments/createAdyenSession.generated';
import type { CreateAdyenSessionMutation } from '../graphqlDocument/payments/createAdyenSession.generated';
import { getCtLocale } from '../utils/localeHelper';
import { createAnchorTagHref } from '../utils/transformers';

const clientKey = process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY ?? '';

export function useGetGiftCardBalance(
  giftCardNumber: number,
  setCardNumber: Dispatch<SetStateAction<number | null>>,
  setError: UseFormSetError<{ giftCardNumber: number }>,
) {
  const [createAdyenSession] = useCreateAdyenSessionMutation();
  const [adyenSession, setAdyenSession] = useState<CreateAdyenSessionMutation['createAdyenSession']>();
  const { t } = useTranslation(['lib-products-common']);
  const [giftCardBalance, setGiftCardBalance] = useState<PaymentAmount | undefined>();
  const [loading, setLoading] = useState(false);
  const { locale } = useLocaleContext();

  const submitGiftCard = useCallback(async () => {
    if (!adyenSession) return;
    setLoading(true);
    const AdyenCheckout = (await import('@adyen/adyen-web')).default;
    const checkout = await AdyenCheckout({
      locale,
      clientKey,
      session: {
        id: adyenSession.id,
        sessionData: adyenSession.sessionData,
      },
      environment: clientKey.split('_')[0],
    });
    try {
      const { balance } = await checkout.session.checkBalance({
        paymentMethod: {
          brand: 'givex',
          type: 'giftcard',
          number: giftCardNumber,
        },
      });
      setCardNumber(giftCardNumber);
      setGiftCardBalance(balance);
    } catch {
      setGiftCardBalance(undefined);
      setError('giftCardNumber', {
        type: 'custom',
        message: t('giftcard.error'),
      });
    } finally {
      setLoading(false);
    }
  }, [adyenSession, giftCardNumber, locale, setCardNumber, setError, t]);

  useEffect(() => {
    async function getAdyenSession() {
      const { data } = await createAdyenSession({ variables: { input: { locale: getCtLocale(locale) } } });
      if (data?.createAdyenSession) {
        setAdyenSession(data.createAdyenSession);
      } else {
        window.location.href = createAnchorTagHref({ locale, path: '/500' });
      }
    }
    getAdyenSession();
  }, [createAdyenSession, locale]);

  return { submitGiftCard, balance: giftCardBalance, loading };
}
