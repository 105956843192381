import { storeKeyMapper } from '@shared/utils/src/ct';
import { GetStoreDocument } from '../../graphqlDocument/stores/store.generated';
import type { GetStoreQuery, GetStoreQueryVariables } from '../../graphqlDocument/stores/store.generated';
import { ONE_MINUTE, queryCacheEvictor } from '../../utils/checkCache';
import type { ApolloClientInstance } from './apolloClient';

export const getStoreData = async (apolloClient: ApolloClientInstance, locale: string) => {
  try {
    const response = await apolloClient.query<GetStoreQuery, GetStoreQueryVariables>({
      query: GetStoreDocument,
      variables: {
        input: {
          storeKey: storeKeyMapper(locale),
        },
      },
    });

    const result = response.data.getStore;

    queryCacheEvictor({ isField: false, storeObject: result }, ONE_MINUTE * 10, apolloClient);

    return { data: result };
  } catch (e) {
    return {
      error: e,
      data: undefined,
    };
  }
};
