import { useEffect } from 'react';

export function useLoadingCursor(isLoading: boolean) {
  useEffect(() => {
    document.body.style.cursor = isLoading ? 'wait' : 'auto';
    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [isLoading]);
}
