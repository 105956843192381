/**
 * xs through xx has `rem` breakpoints.
 * Don't forget to add the units!
 */
export const breakpoints = {
  /**
   * 20rem => 320px
   */
  xs: 20,
  /**
   * 30rem => 480px
   */
  sm: 30,
  /**
   * Tablet min-width,
   * 48rem => 768px
   */
  md: 48,
  /**
   * Desktop min-width,
   * 64rem => 1024px
   */
  lg: 64,
  /**
   * 78.75rem => 1260px
   */
  xl: 78.75,
  /**
   * 90rem => 1440px
   */
  xx: 90,
  /**
   * Mobile max-width.
   */
  mobile: 767,
  /**
   * Tablet min-width.
   */
  tablet: 768,
  /**
   * Desktop min-width.
   */
  desktop: 1024,
} as const;

export type Breakpoint = keyof typeof breakpoints;
