import styled from 'styled-components';
import { colours, media, spacing } from '../../../../stylings';

export const RectangleIcon = styled.li`
  border: 1px solid ${colours.BLACK};
  display: flex;
  height: 24px;
  margin: 0 ${spacing.XXXXS} 0 0;
  position: relative;
  width: 34px;

  > * {
    height: 100%;
    width: 100%;
  }

  @media ${media.greaterThan('lg')} {
    height: 38px;
    margin-right: ${spacing.XXXS};
    width: 54px;
  }

  &:hover,
  &:focus {
    background-color: ${colours.LIGHT_BLUE};
  }
`;
