import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { VirtualRefinementList, VirtualSortBy } from '../globalComponents/algolia/virtualComponents';
import { createFacetAttributeNames } from '../utils/algoliaFilterUtils';
import { createSortByItems } from '../utils/algoliaSortByUtils';
import { useLocaleContext } from './localeContext';

// Type is InstantSearchApi<UiState> but InstantSearchApi is not exposed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InstantSearchContext = createContext<any>({});

export const useInstantSearchContext = () => useContext(InstantSearchContext);

type InstantSearchProviderProps = {
  children: ReactNode;
};

export function InstantSearchProvider({ children }: InstantSearchProviderProps) {
  const instantSearch = useInstantSearch();
  const { locale } = useLocaleContext();

  const items = createSortByItems(locale);
  return (
    <InstantSearchContext.Provider value={instantSearch}>
      {createFacetAttributeNames(locale).map((attribute) => (
        <VirtualRefinementList attribute={attribute} key={attribute} />
      ))}
      <VirtualSortBy items={items} />
      {children}
    </InstantSearchContext.Provider>
  );
}
