import type { GlobalEAnalyticsType, ProductInfoType } from '../types/globaleAnalytics';
import { Cookies } from './cookies';
import { sendAddPaymentInfoEventGlobalE } from './gtm/events/add_payment_info/sendAddPaymentInfoEventGlobalE';
import { sendAddShippingInfoEventGlobalE } from './gtm/events/add_shipping_info/sendAddShippingInfoEventGlobalE';
import { sendPurchaseEventGlobalE } from './gtm/events/purchase/sendPurchaseEventGlobalE';
import type { LineItemGlobalE } from './gtm/types/lineItem';
import { STAR1Locales } from './localeHelper';

type GlobalECookieParamType = {
  locale?: string;
  guid?: string;
};

export const addGlobalECookie = ({ locale = '', guid }: GlobalECookieParamType) => {
  const [, country] = locale.split('-');
  if (locale && country && guid) {
    const countryCap = country.toUpperCase();
    const params = {
      country: countryCap,
      locale,
      token: guid,
    };
    const existingCookie = Cookies.get('globale-params');
    if (
      !existingCookie ||
      existingCookie.country !== countryCap ||
      existingCookie.locale !== locale ||
      existingCookie.token !== guid
    ) {
      Cookies.set('globale-params', params, {
        path: '/',
        secure: true,
      });
    }
  }
};

// To be used as a server-side check only - for client-side see the hook useStar2
// Returns true if the locale is not a Star1 locale
export const isStar2Check = ({ locale }: { locale?: string }) => locale && !STAR1Locales.includes(locale);

export const sendGlobalEAnalytics = ({ data }: { data?: GlobalEAnalyticsType }, cartId: string) => {
  if (!data?.details) return;

  const {
    ShippingMethodType,
    ShippingMethodName,
    PaymentMethods,
    CustomerDetails,
    OrderID,
    MerchantOrderID,
    OrderPrices,
    CustomerCurrencyCode,
    Discounts,
    ProductInformation,
  } = data.details;

  const couponCode = Discounts?.map(({ CouponCode }) => CouponCode)
    .filter((code, index, self) => !!code && self.indexOf(code) === index)
    .join(', ');

  const couponValue = Discounts?.map(
    ({ DiscountTypeName, DiscountPrices }) =>
      (DiscountTypeName === 'Cart discount' && DiscountPrices?.CustomerTransaction?.CustomerPrice) || 0,
  ).reduce((sum, number) => sum + number, 0);

  const lineItems: LineItemGlobalE[] =
    ProductInformation?.map(
      ({
        SecondaryProductCode,
        Quantity,
        ProductName,
        Brand,
        Categories,
        SKU,
        ProductGroupCode,
        ProductPrices,
      }: ProductInfoType) => {
        const customerListPrice = ProductPrices?.CustomerTransaction?.CustomerListPrice ?? 0;
        const customerSalePrice = ProductPrices?.CustomerTransaction?.CustomerSalePrice ?? 0;
        const quantity = Quantity ?? 0;

        return {
          colour: SecondaryProductCode,
          quantity: +quantity,
          name: ProductName ?? '',
          brand: Brand,
          primaryCategory: {
            name: Categories?.Name,
            key: Categories?.Code,
          },
          variant: { sku: SKU },
          productKey: ProductGroupCode,
          value: +customerSalePrice * +quantity,
          discount: +customerListPrice - +customerSalePrice,
          price: +customerSalePrice,
          wasPrice: +customerListPrice,
          thenPrice: 0,
        };
      },
    ) ?? [];

  const miniCart = {
    currency: CustomerCurrencyCode ?? '',
    total: OrderPrices?.CustomerTransaction?.CustomerTotalPrice ?? 0,
    couponCode,
  };

  const paymentMethod = PaymentMethods?.[0]?.PaymentMethodTypeName;

  if (paymentMethod) {
    sendAddPaymentInfoEventGlobalE(miniCart, paymentMethod, cartId, lineItems);
  }

  if (ShippingMethodType) {
    sendAddShippingInfoEventGlobalE(miniCart, cartId, ShippingMethodType, lineItems);
  }

  const purchaseParams = {
    deliveryTypeData: ShippingMethodName,
    deliveryAddress: {
      city: CustomerDetails?.ShippingAddress?.ShippingCity,
      country: CustomerDetails?.ShippingAddress?.ShippingCountryName ?? '',
    },
    deliveryMethodData: ShippingMethodType,
    transactionId: MerchantOrderID ?? '',
    globalEId: OrderID ?? '',
    taxes: OrderPrices?.CustomerTransaction?.CustomerDutiesAndTaxes ?? 0,
    deliveryTotal: OrderPrices?.CustomerTransaction?.CustomerShippingPrice ?? 0,
    couponValue,
    paymentMethod,
    lineItems,
    cartId,
  };

  sendPurchaseEventGlobalE({ ...purchaseParams, ...miniCart });
};
