import type { CentPrecisionMoneyDraft, HighPrecisionMoney, HighPrecisionMoneyDraft } from '@commercetools/platform-sdk';
import { getCurrencyFractionDigits, highPrecisionFractionDigits } from './common';
import { toPrecisionNumber } from './money';

const highPrecisionFractionMultiplier = 10 ** highPrecisionFractionDigits;

export function calculateGrossPrice(
  preciseAmount: number,
  taxRate: number,
  currencyCode: string,
): CentPrecisionMoneyDraft {
  const currencyFractionDigits = getCurrencyFractionDigits(currencyCode);
  const preciseAmountWithFraction = preciseAmount / highPrecisionFractionMultiplier;

  const centAmount = calculateGrossAmount(preciseAmountWithFraction, taxRate, currencyFractionDigits);

  return {
    type: 'centPrecision',
    currencyCode,
    centAmount,
  };
}

export function calculateHighPrecisionGrossPrice(
  preciseAmount: number,
  taxRate: number,
  currencyCode: string,
): HighPrecisionMoneyDraft {
  const { centAmount } = calculateGrossPrice(preciseAmount, taxRate, currencyCode);
  const preciseAmountWithFraction = preciseAmount / highPrecisionFractionMultiplier;

  return {
    type: 'highPrecision',
    currencyCode,
    centAmount,
    preciseAmount: calculateGrossAmount(preciseAmountWithFraction, taxRate, highPrecisionFractionDigits),
    fractionDigits: highPrecisionFractionDigits,
  };
}

export function calculateHighPrecisionNetPrice(
  { preciseAmount, currencyCode, fractionDigits }: HighPrecisionMoney,
  taxRate: number,
): HighPrecisionMoney {
  const currencyFractionDigits = getCurrencyFractionDigits(currencyCode);
  const preciseAmountWithFraction = preciseAmount / 10 ** fractionDigits;

  return {
    type: 'highPrecision',
    currencyCode,
    centAmount: calculateNetAmount(preciseAmountWithFraction, taxRate, currencyFractionDigits),
    preciseAmount: calculateNetAmount(preciseAmountWithFraction, taxRate, fractionDigits),
    fractionDigits,
  };
}

export function calculateGrossAmount(
  netAmountWithFraction: number,
  taxRate: number,
  fractionDigits = highPrecisionFractionDigits,
) {
  return toPrecisionNumber(netAmountWithFraction * taxRate + netAmountWithFraction, fractionDigits);
}

export function calculateNetAmount(
  grossAmountWithFaction: number,
  taxRate: number,
  fractionDigits = highPrecisionFractionDigits,
) {
  return toPrecisionNumber(grossAmountWithFaction / (taxRate + 1), fractionDigits);
}
