import type { LineItemPrice } from '../../../codegen/types';
import { getAmount, getCurrencySymbol } from '../../../utils/currencyHelper';
import { PriceWrapper } from './ProductLineItemPrices.styles';

export default function LineItemPrices({ price, quantity }: { price: LineItemPrice; quantity: number }) {
  const { value, totalDiscountedPrice } = { ...price };

  return (
    <PriceWrapper $discountedPrice={!!totalDiscountedPrice}>
      {value?.centAmount > 0 && (
        <span className="now-price">
          {getCurrencySymbol(value?.currencyCode)}
          {getAmount((value?.centAmount ?? 0) * quantity)}
        </span>
      )}
      {totalDiscountedPrice && totalDiscountedPrice?.centAmount >= 0 && (
        <span className="discounted-price">
          {getCurrencySymbol(totalDiscountedPrice?.currencyCode)}
          {getAmount(totalDiscountedPrice?.centAmount)}
        </span>
      )}
    </PriceWrapper>
  );
}
