import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { Title } from '@radix-ui/react-dialog';
import type { DialogTitleProps as RadixTitle } from '@radix-ui/react-dialog';

type DialogTitleProps = RadixTitle & {
  children: ReactNode;
  className?: string;
};

export const DialogTitle = forwardRef<HTMLHeadingElement, DialogTitleProps>(({ children, className, ...rest }, ref) => (
  <Title className={className} ref={ref} {...rest}>
    {children}
  </Title>
));
