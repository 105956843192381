import styled from 'styled-components';
import { Loader } from '../../Loader/Loader';

const S = {
  Wrapper: styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 50vh;
  `,
};

export function CartLoader() {
  return (
    <S.Wrapper role="status">
      <Loader isDark />
    </S.Wrapper>
  );
}
