import styled, { css } from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

const commonStyles = css`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  gap: ${spacing.XXXS};
  justify-content: center;
  padding: ${spacing.XXS} 0;
  width: 100%;

  svg {
    display: inline-block;
    height: 5.73px;
    width: 9.75px;
  }
`;

export const ScrollToTopButton = styled.button<{ $isDesktop?: boolean }>`
  ${commonStyles}

  @media ${media.greaterThan('lg')} {
    display: none;
  }

  ${(props) =>
    props.$isDesktop &&
    css`
      display: none;
      @media ${media.greaterThan('lg')} {
        ${commonStyles}
        margin-top: 30px;
      }
    `}
`;
export const Text = styled.span`
  ${TypographyStyles.Body.Small.Regular}
  color: ${colours.BLACK};
`;
