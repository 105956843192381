import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type ValidateAndUpdateCartItemsStockMutationVariables = Types.Exact<{
  input: Types.ValidateAndUpdateCartItemsStockInput;
}>;

export type ValidateAndUpdateCartItemsStockMutation = {
  __typename?: 'Mutation';
  validateAndUpdateCartItemsStock: {
    __typename?: 'ValidateAndUpdateCartItemsStockResponse';
    outOfStockLineItemsIds: Array<string>;
    quantityAdjustedLineItemsIds: Array<string>;
    cart: { __typename?: 'Cart'; id: string };
  };
};

export const ValidateAndUpdateCartItemsStockDocument = gql`
  mutation validateAndUpdateCartItemsStock($input: ValidateAndUpdateCartItemsStockInput!) {
    validateAndUpdateCartItemsStock(input: $input) {
      cart {
        id
      }
      outOfStockLineItemsIds
      quantityAdjustedLineItemsIds
    }
  }
`;
export type ValidateAndUpdateCartItemsStockMutationFn = Apollo.MutationFunction<
  ValidateAndUpdateCartItemsStockMutation,
  ValidateAndUpdateCartItemsStockMutationVariables
>;

/**
 * __useValidateAndUpdateCartItemsStockMutation__
 *
 * To run a mutation, you first call `useValidateAndUpdateCartItemsStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAndUpdateCartItemsStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAndUpdateCartItemsStockMutation, { data, loading, error }] = useValidateAndUpdateCartItemsStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateAndUpdateCartItemsStockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateAndUpdateCartItemsStockMutation,
    ValidateAndUpdateCartItemsStockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateAndUpdateCartItemsStockMutation, ValidateAndUpdateCartItemsStockMutationVariables>(
    ValidateAndUpdateCartItemsStockDocument,
    options,
  );
}
export type ValidateAndUpdateCartItemsStockMutationHookResult = ReturnType<
  typeof useValidateAndUpdateCartItemsStockMutation
>;
export type ValidateAndUpdateCartItemsStockMutationResult =
  Apollo.MutationResult<ValidateAndUpdateCartItemsStockMutation>;
export type ValidateAndUpdateCartItemsStockMutationOptions = Apollo.BaseMutationOptions<
  ValidateAndUpdateCartItemsStockMutation,
  ValidateAndUpdateCartItemsStockMutationVariables
>;
