import { useEffect, useState } from 'react';
import { useResizeScrollSize } from './useResizeScrollSize';

export function useScreenChanges(dimension: 'width' | 'height', ratio: number) {
  const { elementRef, scrollHeight, scrollWidth } = useResizeScrollSize({});
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [isRatioExceeded, setIsRatioExceeded] = useState(false);

  useEffect(() => {
    let previousVisualViewport = window.visualViewport;

    const handleResize = () => {
      const newVisualViewport = window.visualViewport;

      // Detect keyboard visibility
      const windowHeight = newVisualViewport?.height || window.innerHeight;
      const documentHeight = document.documentElement.clientHeight;
      setIsKeyboardVisible(windowHeight < documentHeight);

      // Calculate and set isRatioExceeded
      const scrollRatio = dimension === 'width' ? scrollWidth / window.innerWidth : scrollHeight / window.innerHeight;
      setIsRatioExceeded(scrollRatio > ratio);

      previousVisualViewport = newVisualViewport;
    };
    window.addEventListener('resize', handleResize, { passive: true });

    // Initial call to handleResize
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dimension, ratio, scrollHeight, scrollWidth]);

  return { elementRef, isKeyboardVisible, isRatioExceeded };
}
