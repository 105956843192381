import { css } from 'styled-components';
import { media } from './media';

type MaxWidthPartialType = Partial<{
  maxWidth: number;

  /** To add padding or not */
  withPadding: boolean;

  /** Used when some content still bleeds but you want them to stay within the max width at >1260px */
  withPaddingXL: boolean;

  /** Used for carousels that needs to bleed to the right */
  shouldBleedRight: boolean;
}>;

export const maxWidthPartial = ({
  maxWidth = 1440,
  withPadding = true,
  withPaddingXL = false,
  shouldBleedRight = false,
}: MaxWidthPartialType = {}) => css`
  margin: 0 auto;
  max-width: ${maxWidth}px;
  padding: ${withPadding && '0 24px'};
  width: 100%;

  @media ${media.greaterThan('xl')} {
    padding: ${withPadding && withPaddingXL && '0 70px'};
  }

  @media ${media.greaterThan('xx')} {
    padding: ${withPadding && '0 70px'};
  }

  ${shouldBleedRight &&
  css`
    padding-right: 0;

    @media ${media.between('lg', 'xx')} {
      padding-right: 0;
    }
  `}
`;
