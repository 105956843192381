import { logger } from '../../utils';
import { isAmpliencePreviewEnabled } from '../../utils/isAmpliencePreviewEnabled';
import { cache } from '../redis-cache';

export type FilterAPIRequestBody = {
  filterBy: {
    path: string;
    value: string | number | boolean;
  }[];
  sortBy?: {
    key: string;
    order?: string;
  };
  parameters?: {
    depth?: string;
    format?: string;
    locale?: string;
  };
  page?: {
    size: number;
    cursor?: string;
  };
};

interface FilterApiRequestParam {
  requestBody: FilterAPIRequestBody;
  hubName?: string;
}

export const sendFilterApiRequest = async ({ requestBody, hubName }: FilterApiRequestParam) => {
  try {
    const fetchURL =
      createPreviewUrl() ||
      `https://${hubName || process.env.AMPLIENCE_HUB_NAME}.cdn.content.amplience.net/content/filter`;

    const cacheKey = `filterApi:${JSON.stringify(requestBody)}${hubName}${fetchURL}`;

    const cacheTTL = 10 * 60;
    const cachedResponse = await cache().get(cacheKey);
    if (cachedResponse) {
      return cachedResponse;
    }

    const response = await fetch(fetchURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    await cache().set(cacheKey, data.responses, { ttl: cacheTTL });

    return data.responses;
  } catch (error) {
    logger.error('Error in sendFilterApiRequest:', error);
  }

  return [];
};

function createPreviewUrl() {
  if (isAmpliencePreviewEnabled()) {
    return `https://${process.env.AMPLIENCE_HUB_STAGING}.staging.bigcontent.io/content/filter`;
  }
  return undefined;
}
