import type { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { TypographyStyles, colours } from '../../stylings';

const S = {
  ErrorMessage: styled.p`
    ${TypographyStyles.Body.Tiny.Regular}
    color: ${colours.RED};
    letter-spacing: 0;
    margin: 0;
  `,
};

interface ErrorMessageProps extends HTMLAttributes<HTMLParagraphElement> {
  errorMessage?: boolean;
}

export function ErrorMessage({ errorMessage, ...props }: ErrorMessageProps) {
  if (errorMessage) return null;

  return <S.ErrorMessage role="alert" {...props} />;
}
