import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import type { LocalizedValue, Media as MediaSchema } from '@amplience/content-types/typings/p-partials';
import { useLoadMedia } from '../../hooks/useLoadMedia';
import { colours } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';
import { ImagePartial } from '../image/ImagePartial';
import { LinkPartial } from '../link/LinkPartial';

export type MediaPartialProps = MediaSchema & {
  height: number;
  width: number;
  videoQuality?: string;
  isImageLayoutFill?: boolean;
  mediaFit?: 'fill' | 'contain' | 'cover';
  altText?: string | LocalizedValue;
  internalLink?: string;
  externalLink?: string;
  localizedInternalLink?: string;
  localizedExternalLink?: string;
  autoPlay?: boolean;
  className?: string;
  pauseOnScroll?: boolean;
  loop?: boolean;
  vttSupport?: string;
  isMediaFocusable?: boolean;
  observerOptions?: IntersectionObserverInit;
  ariaLabel?: string;
  lazyLoading?: boolean;
  isCarousel?: boolean;
};

const S = {
  MediaLinkContainer: styled(LinkPartial)`
    display: block;
    height: 100%;
    left: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
    &:focus {
      outline: 2px solid ${colours.OUTLINE_BORDER};
    }
  `,
};

const FIRST_VIDEO_FRAGMENT_TIME = 0.001;

export function MediaPartial({
  _meta,
  height,
  width,
  videoQuality,
  hasControls = true,
  isImageLayoutFill,
  mediaFit,
  altText,
  internalLink,
  externalLink,
  localizedInternalLink,
  localizedExternalLink,
  autoPlay = true,
  className,
  pauseOnScroll,
  loop = true,
  vttSupport,
  observerOptions,
  isMediaFocusable = true,
  lazyLoading = true,
  isCarousel,
  ...props
}: MediaPartialProps): ReactElement | null {
  const isImage = props.media?._meta.schema === 'http://bigcontent.io/cms/schema/v1/core#/definitions/image-link';
  const isVideo = props.media?._meta.schema === 'http://bigcontent.io/cms/schema/v1/core#/definitions/video-link';
  const [isUserPause, setIsUserPause] = useState(false);
  const [isVideoPause, setIsVideoPause] = useState(false);

  const mp4Url = `https://${props.media?.defaultHost}/v/${props.media?.endpoint}/${props.media?.name}/mp4_${
    videoQuality || '720'
  }p`;
  const webmUrl = `https://${props.media?.defaultHost}/v/${props.media?.endpoint}/${props.media?.name}/webm_${
    videoQuality || '720'
  }p`;

  const { isVisible, mediaRef } = useLoadMedia(mp4Url, webmUrl, observerOptions);

  const handlePause = () => {
    setIsVideoPause(true);
  };

  const handlePlay = () => {
    setIsVideoPause(false);
    setIsUserPause(false);
  };

  useEffect(() => {
    const mediaElements: NodeListOf<HTMLVideoElement> = document.querySelectorAll(`.${className}`);

    if (isVideo && pauseOnScroll) {
      if (!isVisible) {
        mediaElements?.forEach((mediaItem) => mediaItem.pause());
      } else if (isVisible) {
        mediaElements?.forEach((mediaItem) => {
          if (hasControls && !isUserPause && isCarousel) mediaItem.play();
          if (
            (mediaItem.currentTime > FIRST_VIDEO_FRAGMENT_TIME || autoPlay) &&
            (!isUserPause || autoPlay) &&
            !mediaItem.ended
          )
            mediaItem.play();
        });
      }
    }
  }, [autoPlay, className, isVideo, isVisible, pauseOnScroll, isUserPause, hasControls, isCarousel]);

  useEffect(() => {
    if (isVisible && isVideoPause) {
      setIsUserPause(true);
    }
  }, [isVideoPause, isVisible]);

  if (!props.media?._meta.schema) {
    return null;
  }

  if ((internalLink || externalLink || localizedInternalLink || localizedExternalLink) && isImage) {
    return isImageLayoutFill ? (
      <S.MediaLinkContainer
        internalLink={internalLink}
        externalLink={externalLink}
        localizedInternalLink={localizedInternalLink}
        localizedExternalLink={localizedExternalLink}
        ariaLabel={getLocalizedValue(altText)}
        tabIndex={isMediaFocusable ? 0 : -1}
      >
        <ImagePartial
          layout="fill"
          src={props.media?.name}
          objectFit={mediaFit}
          altText={getLocalizedValue(altText)}
          aria-hidden
        />
      </S.MediaLinkContainer>
    ) : (
      <S.MediaLinkContainer
        internalLink={internalLink}
        externalLink={externalLink}
        localizedInternalLink={localizedInternalLink}
        localizedExternalLink={localizedExternalLink}
        ariaLabel={getLocalizedValue(altText)}
        tabIndex={isMediaFocusable ? 0 : -1}
      >
        <ImagePartial
          layout="intrinsic"
          src={props.media?.name}
          height={height}
          width={width}
          objectFit={mediaFit}
          altText={getLocalizedValue(altText)}
          aria-hidden
        />
      </S.MediaLinkContainer>
    );
  }

  if (isImage) {
    return isImageLayoutFill ? (
      <ImagePartial
        lazyLoading={lazyLoading}
        layout="fill"
        src={props.media?.name}
        objectFit={mediaFit}
        altText={getLocalizedValue(altText)}
      />
    ) : (
      <ImagePartial
        layout="intrinsic"
        src={props.media?.name}
        height={height}
        width={width}
        objectFit={mediaFit}
        altText={getLocalizedValue(altText)}
      />
    );
  }

  if (isVideo) {
    const img = `https://${props.media?.name}/v/${props.media?.endpoint}/${props.media?.name}`;
    const { hasCaptions } = props;
    return (
      <video
        aria-label={getLocalizedValue(altText)}
        className={className}
        height={height}
        width={width}
        controls={hasControls}
        autoPlay={autoPlay}
        muted
        loop={loop}
        ref={mediaRef}
        crossOrigin="anonymous"
        onPlay={pauseOnScroll ? handlePlay : undefined}
        onPause={pauseOnScroll ? handlePause : undefined}
        playsInline
        tabIndex={isMediaFocusable ? 0 : -1}
        preload="none"
      >
        {vttSupport && <track src={vttSupport} default={!!hasCaptions} kind="captions" />}
        <Image src={img} alt={getLocalizedValue(altText)} height={height} width={width} unoptimized />
      </video>
    );
  }

  return null;
}
