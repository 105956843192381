import { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { FreeShippingDelivery as FreeShippingAndReturnSchema } from '@amplience/content-types/typings/c-shipping-section';
import type { DeliveryOption } from '../../../codegen/types';
import { useMasterLayoutContext } from '../../../context/masterLayoutContext';
import { useStar2 } from '../../../hooks/useStar2';
import { ArrowDown } from '../../../icons';
import { TypographyStyles, accordionAnimation, accordionArrowAnimation, colours, spacing } from '../../../stylings';
import { renderDeliveryOptionsTranslation } from '../../../utils';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { Loader } from '../../Loader/Loader';
import { ShippingSection } from '../../shippingSection';
import { RenderPrice } from '../taxAndTotals/CartDelivery';

const S = {
  Container: styled.div`
    margin-top: ${spacing.S};
  `,
  DeliveryAccordion: styled.button`
    align-self: center;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0;
    user-select: none;
    width: 100%;
  `,
  IconWrapper: styled.span<{ $isOpenDelivery: boolean }>`
    align-self: baseline; /* stylelint-disable-line order/properties-alphabetical-order */
    margin-top: 5px;

    ${({ $isOpenDelivery }) => accordionArrowAnimation($isOpenDelivery)}

    &:focus {
      outline-color: ${colours.OUTLINE_BORDER};
      outline-width: 2px;
    }
  `,
  AccordionContainer: styled.div<{ $isOpenDelivery: boolean }>`
    ${({ $isOpenDelivery }) => accordionAnimation($isOpenDelivery)}
    overflow: hidden;
    @media (prefers-reduced-motion) {
      display: ${({ $isOpenDelivery }) => ($isOpenDelivery ? 'unset' : 'none')};
    }
  `,
  DeliveryLabel: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
    color: ${colours.BLACK};
    margin-right: ${spacing.XXXS};
  `,
  DeliveryOptions: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: ${spacing.XS} 0;
    padding: 0;
  `,
  DeliveryItem: styled.li`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
  DeliveryName: styled.span`
    ${TypographyStyles.Body.Small.Regular}
  `,
  Days: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
    display: block;
    margin: 0;
  `,
  ShippingSection: styled(ShippingSection)`
    margin-bottom: 0;
  `,
  NoDeliveryOptions: styled.span`
    display: block;
    margin: ${spacing.XXXS} 0;
  `,
};

function DeliveryOptionsList({ deliveryOptions }: { deliveryOptions: DeliveryOption[] | undefined }) {
  const { t } = useTranslation(['lib-global-common']);
  const { isStar2 } = useStar2();
  const noDeliveryOptions = () => <S.NoDeliveryOptions>{t('cart.delivery.and.returns.error')}</S.NoDeliveryOptions>;

  if (!deliveryOptions) return <>{noDeliveryOptions()}</>;

  return deliveryOptions?.length > 0 ? (
    <S.DeliveryOptions>
      {deliveryOptions?.map(({ currency, deliveryTime, price, name, key: deliveryKey }) => (
        <S.DeliveryItem key={deliveryKey}>
          <RenderPrice price={price} currency={currency} />{' '}
          <S.DeliveryName>{isStar2 ? renderDeliveryOptionsTranslation(deliveryKey, t, name) : name}</S.DeliveryName>{' '}
          <S.Days>
            {isStar2
              ? t('cart.delivery.and.returns.deliveryTime', { deliveryTime })
              : capitalizeFirstLetter(deliveryTime)}
          </S.Days>
        </S.DeliveryItem>
      ))}
    </S.DeliveryOptions>
  ) : (
    <>{noDeliveryOptions()}</>
  );
}

type DeliveryOptionsProps = {
  deliveryOptions: DeliveryOption[];
  isDeliveryOptionsLoading: boolean;
};

export function DeliveryOptions({ deliveryOptions, isDeliveryOptionsLoading }: DeliveryOptionsProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { masterLayoutData } = useMasterLayoutContext();
  const { freeShippingAndReturn = null } = { ...masterLayoutData };

  const router = useRouter();
  const isPaymentPage = router.asPath.includes('/checkout/payment');
  const isConfirmationPage = router.asPath.includes('/checkout/confirmation');

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleDeliveryAccordion = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  if (isPaymentPage || isConfirmationPage) return null;

  return (
    <S.Container>
      <S.DeliveryAccordion type="button" onClick={toggleDeliveryAccordion}>
        <S.DeliveryLabel>{t('cart.delivery.and.returns')}</S.DeliveryLabel>
        <S.IconWrapper
          aria-label={t('cart.delivery.and.returns.toggle')}
          role="button"
          $isOpenDelivery={isAccordionOpen}
        >
          <ArrowDown className="accordion-arrow-down" width={25} aria-hidden viewBox="0 0 20 20" />
        </S.IconWrapper>
      </S.DeliveryAccordion>
      <S.AccordionContainer $isOpenDelivery={isAccordionOpen}>
        <>
          {isDeliveryOptionsLoading ? <Loader isDark /> : <DeliveryOptionsList deliveryOptions={deliveryOptions} />}
          {freeShippingAndReturn && (
            <S.ShippingSection {...(freeShippingAndReturn as unknown as FreeShippingAndReturnSchema)} />
          )}
        </>
      </S.AccordionContainer>
    </S.Container>
  );
}
