import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { RedirectInformation } from '../codegen/types';
import { fetchGeoIPInfo } from '../services/apiRoute/geoipInfo';
import { Cookies } from '../utils/cookies';
import { Locale } from '../utils/localeHelper';

export function useGeoIp() {
  const router = useRouter();
  const [hasLocationInfo, setHasLocationInfo] = useState(false);
  const [loadingGeoIpInfo, setLoadingGeoIpInfo] = useState(true);
  const [showRedirectPopUp, setShowRedirectPopUp] = useState(false);
  const [redirectInfo, setRedirectInfo] = useState<RedirectInformation | null>(null);

  useEffect(() => {
    const defaultGeoIpInfo = () => {
      setHasLocationInfo(false);
      setLoadingGeoIpInfo(true);
      setShowRedirectPopUp(false);
    };
    router.events.on('routeChangeStart', defaultGeoIpInfo);

    return () => {
      router.events.off('routeChangeStart', defaultGeoIpInfo);
    };
  }, [router.events]);

  useEffect(() => {
    const effectiveLocale = router.locale ?? Locale.DEFAULT_LOCALE;
    if (loadingGeoIpInfo) {
      if (
        effectiveLocale === Locale.STOREFRONT_OUTLET_LOCALE ||
        Cookies.get('defaultLocale')?.toLowerCase() === effectiveLocale.toLowerCase()
      ) {
        setHasLocationInfo(true);
        setShowRedirectPopUp(false);
        setLoadingGeoIpInfo(false);
        return;
      }
      (async () => {
        try {
          const {
            hasLocation,
            promptRedirect,
            redirectInfo: promptRedirectInfo,
          } = await fetchGeoIPInfo({
            locale: effectiveLocale,
          });
          setHasLocationInfo(hasLocation);
          setShowRedirectPopUp(promptRedirect);
          setRedirectInfo(promptRedirectInfo);
        } catch {
          setHasLocationInfo(false);
          setShowRedirectPopUp(false);
        } finally {
          setLoadingGeoIpInfo(false);
        }
      })();
    }
  }, [loadingGeoIpInfo, redirectInfo, router.locale]);

  return { hasLocationInfo, loadingGeoIpInfo, showRedirectPopUp, redirectInfo };
}
