import type { ReactNode } from 'react';
import { Portal } from '@radix-ui/react-dialog';
import type { DialogPortalProps as RadixPortal } from '@radix-ui/react-dialog';

type DialogPortalProps = RadixPortal & {
  children: ReactNode;
};

export function DialogPortal({ children, ...rest }: DialogPortalProps) {
  return <Portal {...rest}>{children}</Portal>;
}
