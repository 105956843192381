import getConfig from 'next/config';
// Note this is a workaround to see if we can get newrelic logging working.
// eslint-disable-next-line import/no-duplicates
import pino from 'pino';
// eslint-disable-next-line import/no-duplicates
import { stdSerializers, stdTimeFunctions } from 'pino';

const INFO_LEVEL_ENVS = ['prod', 'uat'];
const ENV_NAME = getConfig() ? getConfig().serverRuntimeConfig.NEXT_PUBLIC_ENV_NAME : process.env.NEXT_PUBLIC_ENV_NAME;

export const logger: pino.Logger = pino({
  formatters: {
    level: (label) => ({ label }),
  },
  level: INFO_LEVEL_ENVS.includes(ENV_NAME) ? 'info' : 'debug',
  serializers: {
    error: stdSerializers.err,
  },
  timestamp: stdTimeFunctions.isoTime,
});
