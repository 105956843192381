import { getAmount } from '../../../currencyHelper';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';
import type EventDataList from './EventDataListType';

export const sendViewItemListFromCarouselEvent = (eventData: EventDataList) => {
  const { carouselProducts, itemList } = { ...eventData };

  sendDataLayerEvent(EventNamesType.view_item_list, {
    ecommerce: {
      item_list_id: itemList?.itemListId,
      item_list_name: itemList?.itemListName,
      items:
        carouselProducts?.map((carouselProduct, index) => {
          const { primaryCategory, prices, productName, productKey, colors, brand } = carouselProduct;
          const price = +getAmount(prices?.[0]?.actualPrice?.centAmount);
          const wasPrice = +getAmount(prices?.[0]?.wasPrice?.centAmount);
          const thenPrice = +getAmount(prices?.[0]?.thenPrice?.centAmount);
          const discount = wasPrice && price ? wasPrice - price : 0;
          const color = colors?.[0]?.color;

          return {
            index,
            quantity: 1,
            item_name: [productName, color].filter(Boolean).join(' '),
            item_id: productKey,
            item_brand: brand,
            item_category: primaryCategory?.name ?? undefined,
            item_category_id: primaryCategory?.key ?? undefined,
            item_list_id: itemList?.itemListId,
            item_list_name: itemList?.itemListName,
            item_variant: colors ? colors[0]?.color : undefined,
            discount: +discount.toFixed(2),
            price,
            was_price: wasPrice,
            then_price: thenPrice,
          };
        }) ?? [],
    },
  });
};
