import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { Order, Shipment } from '../../codegen/types';
import { ArrowRight } from '../../icons';
import { TypographyStyles, colours, fontWeights, media, spacing } from '../../stylings';
import { getDeliveryStatus } from '../../utils/orders';
import { NeedHelpContent } from '../cart/needHelp/NeedHelpContent';
import { DeliveryDetails } from './DeliveryDetails';
import { DetailsAndReturn } from './DetailsAndReturn';
import { OrderSummary } from './orderSummary/OrderSummary';
import { Shipments } from './shipments/Shipments';

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXS};
    width: 100%;
    @media ${media.greaterThan('lg')} {
      gap: ${spacing.M};
    }
  `,
  BackAndTitle: styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    width: 100%;
  `,
  BackButton: styled.button`
    ${fontWeights.SemiBold600}
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${colours.BLACK};
    display: flex;
    font-size: 12px;
    gap: ${spacing.XXXXS};
    justify-content: center;
    line-height: 14px;
    padding: 0;
    text-decoration: underline;
    @media ${media.greaterThan('lg')} {
      font-size: 16px;
      line-height: 20px;
    }
  `,
  Title: styled.h3`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    margin-bottom: ${spacing.XXXS};
    padding: 0;
    text-transform: uppercase;
  `,
  NeedHelpContainer: styled.div`
    padding: ${spacing.XS} 0;
    @media ${media.greaterThan('lg')} {
      padding: 0;
    }
  `,
  NeedHelpTitle: styled.h5`
    ${TypographyStyles.Headings.H5}
    margin: 0;
    padding: 0;
  `,
};

type OrderDetailsProps = {
  backDetails: {
    text: string;
    handleBack: () => void;
  };
  order: Order;
};

export function OrderDetails({ order, backDetails }: OrderDetailsProps) {
  const { t } = useTranslation(['lib-global-common']);
  if (!order || Object.entries(order).length === 0) return null;

  const { text, handleBack } = backDetails;
  const { orderNumber, orderPlacedDate, shipments, summary, currency, returnLink } = order;
  const { canBeReturned } = getDeliveryStatus(shipments?.[0]?.status ?? '', returnLink ?? '');

  return (
    <S.Container>
      <S.BackAndTitle>
        <S.BackButton type="button" onClick={handleBack}>
          <ArrowRight />
          {t(text)}
        </S.BackButton>
        <S.Title>{t('order.details.title')}</S.Title>
      </S.BackAndTitle>

      <DetailsAndReturn
        canBeReturned={canBeReturned}
        orderNumber={orderNumber}
        orderPlaceDate={orderPlacedDate}
        returnLink={returnLink as string}
      />
      <Shipments currency={currency} shipments={shipments} />
      <DeliveryDetails summary={summary} shipments={shipments as Shipment[]} />
      <OrderSummary currency={currency} summary={summary} />

      <S.NeedHelpContainer>
        <S.NeedHelpTitle>{t('order.details.need.help.title')}</S.NeedHelpTitle>
        <NeedHelpContent orderNumber={orderNumber} />
      </S.NeedHelpContainer>
    </S.Container>
  );
}
