import { strict as assert } from 'assert';
import { Store, storeToLocales } from '../ct';

export * from './productSearchQueryAllowedList';

export function localiseProperty(property: string, store: Store): string[] {
  assert(storeToLocales.get(store), `There is no store to locales mapping for store: ${store}`);

  return storeToLocales.get(store)!.map((locale) => `${property}.${locale}`);
}
