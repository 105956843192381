import { Fragment } from 'react';
import styled from 'styled-components';
import type { NotificationBar as NotificationBarSchema } from '@amplience/content-types/typings/c-notification-bar';
import { useColor } from '../../partials/color';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import { LinkPartial, hasHref } from '../../partials/link';
import { TypographyStylesType, colours, media, spacing } from '../../stylings';

const S = {
  NotificationBar: styled.ul`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding-inline-start: 0px;

    @media ${media.greaterThan('lg')} {
      display: flex;
      padding-left: 0;
    }
  `,
  Component: styled.li<{ backgroundColor?: string }>`
    align-items: center;
    background-color: ${({ backgroundColor }) => backgroundColor || colours.BLACK};
    display: flex;
    height: 30px;
    justify-content: center;
    width: 100%;
    /* padding: ${spacing.XXXXS} 0; */

    &:not(:only-child):not(:last-child) {
      margin: 0 ${spacing.XXXXS} 0 0;
    }

    & > * {
      padding: ${spacing.XXS};
    }

    @media ${media.greaterThan('lg')} {
      height: 40px;
    }
  `,
  LinkPartial: styled(LinkPartial)<{ $textColor: string; $defaultTextColor: string }>`
    ${({ $textColor, $defaultTextColor }) => useColor($textColor, $defaultTextColor)}
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 5px;
    text-decoration-line: underline;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 1.5px;

    & > p {
      margin: ${spacing.XXXXS} 0;
    }
  `,
};

export type NotificationBarProps = NotificationBarSchema;

export function NotificationBar({ notificationBarComponents, backgroundColor, _meta }: NotificationBarProps) {
  const isDisplayed = notificationBarComponents?.find(({ notificationLink }) =>
    notificationLink?.find(({ text }) => text.text),
  );

  return isDisplayed ? (
    <S.NotificationBar id="clarks-notification-bar">
      {notificationBarComponents &&
        notificationBarComponents.map(({ notificationLink }, idx) => (
          <S.Component backgroundColor={backgroundColor} key={`${_meta?.deliveryId}-${idx}`}>
            {notificationLink.map(
              ({ text, link }, i) =>
                text && (
                  <Fragment key={`${_meta?.deliveryId}-${idx}-${i}`}>
                    {hasHref(link) ? (
                      <S.LinkPartial {...link} $textColor={text.textColor} $defaultTextColor={colours.WHITE}>
                        <FlexibleTextPartial
                          {...text}
                          defaultTextSize={TypographyStylesType.CTA_TINY_SEMIBOLD}
                          defaultTextColor={colours.WHITE}
                        />
                      </S.LinkPartial>
                    ) : (
                      <FlexibleTextPartial
                        {...text}
                        defaultTextSize={TypographyStylesType.CTA_TINY_SEMIBOLD}
                        defaultTextColor={colours.WHITE}
                      />
                    )}
                  </Fragment>
                ),
            )}
          </S.Component>
        ))}
    </S.NotificationBar>
  ) : null;
}
