import nProgress from 'nprogress';

const progressBarStart = () => nProgress.start();
const progressBarDone = () => nProgress.done();
const showProgressBar = () => {
  progressBarStart();
  progressBarDone();
};

export { progressBarStart, progressBarDone, showProgressBar };
