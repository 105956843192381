export const userLogout = async () => {
  try {
    const response = await fetch('/api/logout', {
      method: 'POST',
    });
    const { isLoggedOut } = await response.json();
    return isLoggedOut;
  } catch (err) {
    console.error(err);
    return { error: err };
  }
};
