import styled from 'styled-components';
import type {
  BottomSpacing as BottomSpacingSchema,
  BottomSpacing3 as BottomSpacingStyledProps,
} from '@amplience/content-types/typings/c-bottom-spacing';
import { maxWidthPartial, media, spacing } from '../../stylings';

type SpacerProps = BottomSpacingSchema;

export type SpacerKeys = keyof typeof spacing;

const S = {
  Container: styled.div<{ $bottomSpacing?: BottomSpacingStyledProps; $bgColor?: string }>`
    background-color: ${({ $bgColor }) => $bgColor};
    padding-bottom: ${({ $bottomSpacing }) =>
      $bottomSpacing?.mobile && spacing[$bottomSpacing.mobile?.split('/')[0] as SpacerKeys]};

    @media ${media.greaterThan('lg')} {
      padding-bottom: ${({ $bottomSpacing }) =>
        $bottomSpacing?.desktop && spacing[$bottomSpacing.desktop?.split('/')[0] as SpacerKeys]};
    }

    @media ${media.greaterThan('xx')} {
      ${maxWidthPartial({ maxWidth: 1300, withPadding: false })}
    }
  `,
};

export function Spacer({ bottomSpacing, bgColor }: SpacerProps) {
  return <S.Container $bottomSpacing={bottomSpacing} $bgColor={bgColor} aria-hidden />;
}

export default Spacer;
