import { forwardRef } from 'react';
import styled from 'styled-components';
import { Overlay } from '@radix-ui/react-dialog';
import type { DialogOverlayProps } from '@radix-ui/react-dialog';
import { zNeedHelpModalOverlay } from '../../stylings';

const S = {
  Overlay: styled(Overlay)`
    background-color: rgba(0, 0, 0, 0.4);
    inset: 0;
    position: fixed;
    z-index: ${zNeedHelpModalOverlay};
  `,
};

export const DialogOverlay = forwardRef<HTMLDivElement, DialogOverlayProps>((props, ref) => (
  <S.Overlay ref={ref} {...props} />
));
