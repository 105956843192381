import * as Yup from 'yup';
import { Store } from '@ct/types';
import { FIRST_NAME_REGEXP, LAST_NAME_REGEXP, PHONE_FIELD_REGEXP, TEXT_FIELD_REGEXP } from './register';
import { CA_POSTCODE_REGEXP, US_POSTCODE_REGEXP } from './address';

const billingPostalCodeRule = {
  [Store.UK]: Yup.string()
    .matches(TEXT_FIELD_REGEXP, 'my.account.address.validation.post.code')
    .required('my.account.address.validation.post.code'),
  [Store.US]: Yup.string()
    .matches(US_POSTCODE_REGEXP, 'my.account.address.validation.post.code')
    .max(10, 'my.account.address.validation.post.code')
    .required('my.account.address.validation.post.code'),
  [Store.CA]: Yup.string()
    .matches(CA_POSTCODE_REGEXP, 'my.account.address.validation.post.code')
    .max(7, 'my.account.address.validation.post.code')
    .required('my.account.address.validation.post.code'),
};
const textFieldRule = Yup.string().notRequired();

export const SaveCustomerAddressSchema = () =>
  Yup.object({
    firstName: Yup.string()
      .required('register.invalid.first.name')
      .matches(FIRST_NAME_REGEXP, 'register.validation.first.name'),
    lastName: Yup.string()
      .required('register.invalid.last.name')
      .matches(LAST_NAME_REGEXP, 'register.validation.last.name'),
    phone: Yup.string()
      .max(16, 'register.validation.phone')
      .required('register.validation.empty.phone')
      .matches(PHONE_FIELD_REGEXP, 'register.validation.phone')
      .nullable(),
    postalCode: Yup.string()
      .when('country', {
        is: 'US',
        then: billingPostalCodeRule[Store.US],
        otherwise: billingPostalCodeRule[Store.UK],
      })
      .when('country', {
        is: 'CA',
        then: billingPostalCodeRule[Store.CA],
        otherwise: billingPostalCodeRule[Store.UK],
      }),
    country: Yup.string()
      .matches(TEXT_FIELD_REGEXP, 'my.account.address.validation.country')
      .required('my.account.address.validation.country'),
    line1: Yup.string()
      .min(1, 'checkout.validation.address.line1')
      .max(255, 'checkout.validation.address.line1')
      .required('checkout.validation.address.line1'),
    city: Yup.string()
      .min(1, 'my.account.address.validation.city')
      .max(40, 'my.account.address.validation.city')
      .required('my.account.address.validation.city'),
    state: Yup.string()
      .when('country', {
        is: 'US',
        then: Yup.string().required('my.account.address.validation.state'),
        otherwise: textFieldRule,
      })
      .when('country', {
        is: 'CA',
        then: Yup.string().required('my.account.address.validation.province'),
        otherwise: textFieldRule,
      }),
  });
