/* eslint-disable security/detect-unsafe-regex */
import * as yup from 'yup';
import { EMAIL_REGEX } from './register';

export const CognitoCustomerMigrationSchema = yup.object({
  firstName: yup.string().nullable().notRequired(),
  lastName: yup.string().nullable().notRequired(),
  email: yup.string().required().matches(EMAIL_REGEX).max(128),
  locale: yup.string().required(),
  registeredFrom: yup.string().required(),
});
