import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { EMAIL_REGEX } from '@shared/validation/src/b2c/register';
import { BlueButton } from '../../baseComponents/button';
import { ErrorMessage } from '../../baseComponents/errors';
import { useLocaleContext } from '../../context/localeContext';
import { useUserContext } from '../../context/userContext';
import { useNotifyBackInStockMutation } from '../../graphqlDocument/notifyMe/notifyMe.generated';
import { useLoadingCursor } from '../../hooks/useLoadingCursor';
import { CheckCircle } from '../../icons';
import { TypographyStyles, colours, fonts, media, spacing } from '../../stylings';
import { sendNotifyMeEvent } from '../../utils/gtm/events/notify_me/sendNotifyMeEvent';
import { getCtLocale } from '../../utils/localeHelper';

const S = {
  Heading: styled.div`
    ${TypographyStyles.Headings.H3}
    margin-bottom: ${spacing.M};
    text-transform: uppercase;
  `,
  Wrapper: styled.form`
    margin-top: ${spacing.XS};
    @media ${media.greaterThan('lg')} {
      margin-top: ${spacing.M};
    }
  `,
  Label: styled.span`
    ${TypographyStyles.CallToAction.Tiny.Regular}
  `,
  EmailInput: styled.input<{ hasError: boolean }>`
    border: solid 1px;
    border-color: ${({ hasError }) => (hasError ? colours.ERROR_PRIMARY : colours.GREY)};
    border-radius: 0;
    min-height: 50px;
    outline: none;
    padding: 0 ${spacing.XS};
    width: 100%;
    ${TypographyStyles.Body.Medium.Regular}
  `,
  SubmitBtn: styled(BlueButton)`
    ${TypographyStyles.Button.SemiBold}
    background-color: ${colours.DARK_BLUE};
    border: 0;
    color: ${colours.WHITE};
    height: 50px;
    justify-content: center;
    margin: 0;
    text-transform: uppercase;
    white-space: nowrap;
  `,
  SuccessMessage: styled.div`
    align-items: flex-start;
    background-color: ${colours.LIGHT_GREY_2};
    display: flex;
    flex-direction: row;
    padding: 20px;
    width: 100%;

    @media ${media.greaterThan('lg')} {
      align-items: center;
    }
  `,
  SuccessText: styled.div`
    display: flex;
    flex-direction: column;
    height: 52px;
    justify-content: space-evenly;
    margin-left: 15px;
  `,
  SuccessIcon: styled(CheckCircle)`
    min-width: 16px;
  `,
  Text: styled.span`
    ${fonts.Montserrat}
    font-size: 12px;
    line-height: 18px;
  `,
  Title: styled.span`
    ${fonts.Montserrat}
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  `,
  InputContainer: styled.div`
    align-items: center;
    display: flex;
    gap: ${spacing.XXXS};
  `,
};

type NotifyMePopUpProps = {
  productSize: string;
  productName: string;
  productWidth: string;
  productSku: string;
  isHorizontal?: boolean;
};

type FormValues = {
  email: string;
};

export function NotifyMeForm({ productName, productSize, productWidth, productSku }: NotifyMePopUpProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();
  const { customerData, isUserLoggedIn } = useUserContext();
  const [notifyBackInStockMutation, { data, loading }] = useNotifyBackInStockMutation();
  const [apiError, setApiError] = useState(false);

  const userEmail = customerData?.email;

  const {
    handleSubmit,
    register,
    formState: { errors: validationErrors, isSubmitting, isSubmitSuccessful, touchedFields },
    setValue,
  } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ email }) => {
    try {
      setApiError(false);
      // we use nextjs locale, required for localized links in email
      await notifyBackInStockMutation({
        variables: {
          input: {
            email,
            locale,
            sku: productSku,
          },
        },
        onCompleted: () => {
          const customerNumber = isUserLoggedIn ? customerData?.customerNumber : 'not_logged';

          sendNotifyMeEvent({
            custom_data: {
              customer_number: customerNumber,
              item_name: productName,
              item_id: productSku,
              size: productSize,
              width: productWidth,
            },
          });
        },
      });
    } catch (e) {
      console.error(e);
      setApiError(true);
    }
  };

  const handleInputOnChange = () => {
    if (apiError) {
      setApiError(false);
    }
  };

  useEffect(() => {
    // pre-fill email for logged in users once it is fetched from the API
    if (!touchedFields.email && userEmail) {
      setValue('email', userEmail);
    }
  }, [setValue, touchedFields.email, userEmail]);

  useLoadingCursor(loading);

  return (
    <S.Wrapper noValidate onSubmit={handleSubmit(onSubmit)}>
      {(!isSubmitSuccessful || !data) && (
        <div>
          <S.InputContainer data-cs-mask>
            <S.EmailInput
              aria-label={t('notifyme.email.enter')}
              placeholder={t('notifyme.email.enter')}
              type="email"
              autoComplete="email"
              maxLength={60}
              hasError={!!validationErrors?.email?.message || apiError}
              {...register('email', {
                required: t('notifyme.email.valid'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('notifyme.email.valid'),
                },
                onChange: handleInputOnChange,
              })}
            />
            <S.SubmitBtn disabled={isSubmitting || loading} type="submit">
              {t('notifyme.cta')}
            </S.SubmitBtn>
          </S.InputContainer>
          {validationErrors?.email?.message && (
            <ErrorMessage id="notifyme-email-error">{validationErrors.email.message}</ErrorMessage>
          )}
          {apiError && <ErrorMessage id="notifyme-email-error">{t('notifyme.email.error')}</ErrorMessage>}
        </div>
      )}
      {isSubmitSuccessful && data && (
        <S.SuccessMessage>
          <S.SuccessIcon />
          <S.SuccessText role="status">
            <S.Title>{t('notifyme.success.thanks')}</S.Title>
            <S.Text>{t('notifyme.success.notify')}</S.Text>
          </S.SuccessText>
        </S.SuccessMessage>
      )}
    </S.Wrapper>
  );
}
