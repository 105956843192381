import Script from 'next/script';
import { useFeatureFlagsContext } from '../../context';
import { Locale } from '../../utils';

const trueFitEnv = process.env.NEXT_PUBLIC_TRUEFIT_ENV === 'prod' ? 'prod' : 'staging';

const addTrueFitScript = (key?: string) => `
  (function(r,e,o){
  var w=window,d=document,t,s,x;
  w.tfcapi=t=w.tfcapi||function(){t.q=t.q||[]; t.q.push(arguments);};
  t('init', {storeKey: r, environment: e, ...o});
  x=d.getElementsByTagName('script')[0];
  s=d.createElement('script');
  s.type='text/javascript';s.async=true;
  s.src='https://'+r+'-cdn'+(e==='dev'||e==='staging'?'.'+e:'')+ '.truefitcorp.com/fitrec/global/js/tf-integration.js?storeKey='+r;
  x.parentNode.insertBefore(s,x);
  // Don't change anything above this line
  })('${key}', '${trueFitEnv}', { autoCalculate: false });
`;

export type TrueFitScriptProps = {
  id: string;
  locale?: string;
};

export function TrueFitScript({ id, locale }: TrueFitScriptProps) {
  const { isProductTrueFitEnabled } = useFeatureFlagsContext();

  if (!isProductTrueFitEnabled || !locale) return null;

  const truefitKey = [Locale.EN_GB, Locale.EN_IE].includes(locale)
    ? process.env.NEXT_PUBLIC_TRUEFIT_TLA_GB_IRE_KEY
    : process.env.NEXT_PUBLIC_TRUEFIT_TLA_US_KEY;

  return <Script id={`${id}-${truefitKey}`}>{addTrueFitScript(truefitKey)}</Script>;
}
