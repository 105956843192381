import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useCartContext } from '../../../context/cartContext';
import { DeliveryCar } from '../../../icons';
import { TypographyStyles, colours, spacing } from '../../../stylings';

const S = {
  PromotionsMessage: styled.div`
    ${TypographyStyles.Body.Tiny.SemiBold}
    align-items: center;
    background-color: ${colours.LIGHT_GREY_2};
    display: flex;
    height: 36px;
    justify-content: center;
    max-width: 857px;
    width: 100%;

    .delivery-car {
      margin-right: ${spacing.XXXS};
    }
  `,
};

type PromotionsMessageProps = {
  className?: string;
  isFreeEligible?: boolean;
};

export function PromotionsMessage({ className, isFreeEligible = false }: PromotionsMessageProps) {
  const { miniCart, isCartEmpty } = useCartContext();
  const { hasFreeDelivery = false } = { ...miniCart };
  const { t } = useTranslation('lib-global-common');

  // TODO: temporarily removed promotions banner - https://clarks.atlassian.net/browse/CICD-3831
  return null;

  if (isCartEmpty || (!hasFreeDelivery && !isFreeEligible)) return null;

  return (
    <S.PromotionsMessage className={className} role="status">
      <DeliveryCar className="delivery-car" />
      <span>{t('cart.free.delivery')}</span>
    </S.PromotionsMessage>
  );
}
