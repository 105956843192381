import type { ReactNode } from 'react';
import type {
  RefinementListItem,
  RefinementListRenderState,
} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { useTranslation } from 'next-i18next';
import { Checkbox } from '../../../baseComponents/inputs/Checkbox';
import { useLocaleContext } from '../../../context/localeContext';
import { Minus, Plus } from '../../../icons';
import { sendApplyCategoryFilterEvent } from '../../../utils/gtm/events/apply_category_filter/sendApplyCategoryFilterEvent';
import { sendRemoveCategoryFilterEvent } from '../../../utils/gtm/events/remove_category_filter/sendRemoveCategoryFilterEvent';
import { CheckboxWrapper, Container, ItemCount, MoreLessButton } from './FilterCheckbox.styles';

export type FilterCheckboxProps = Omit<
  RefinementListRenderState,
  'createURL' | 'sendEvent' | 'searchForItems' | 'hasExhaustiveItems' | 'isFromSearch' | 'canRefine'
> & {
  labelComponent?: (item: RefinementListItem) => ReactNode;
  accordionLabel: string;
};

export function FilterCheckbox({
  items,
  refine,
  labelComponent,
  canToggleShowMore,
  isShowingMore,
  toggleShowMore,
  accordionLabel,
}: FilterCheckboxProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();

  const lang = locale.split('-').shift()?.toLowerCase();

  const handleOnChange = (item: RefinementListItem, label: string) => () => {
    const { value, isRefined } = item;
    refine(value);
    if (!isRefined) {
      sendApplyCategoryFilterEvent({
        filter_category: `${label}`,
        filter_value: `${value}`,
      });
    } else {
      sendRemoveCategoryFilterEvent({
        filter_category: `${label}`,
        filter_value: `${value}`,
      });
    }
  };
  return (
    <Container label={accordionLabel as string}>
      {items.map((item) => (
        <CheckboxWrapper key={item.value}>
          <Checkbox
            role="checkbox"
            aria-checked={item.isRefined}
            label={labelComponent ? labelComponent(item) : item.label}
            onChange={handleOnChange(item, accordionLabel)}
            checked={item.isRefined}
            testId="filterCheckbox"
            testLabel={item.label}
          />
          <ItemCount>{`(${item.count})`}</ItemCount>
        </CheckboxWrapper>
      ))}
      {canToggleShowMore && (
        <MoreLessButton type="button" onClick={toggleShowMore} data-testid="moreLessCheckboxFiltersButton">
          {isShowingMore ? (
            <>
              <Minus data-testid="lessCheckboxFilters" /> {t('filter.menu.filter.colour.less')}
            </>
          ) : (
            <>
              <Plus data-testid="moreCheckboxFilters" /> {t('filter.menu.filter.colour.more')}
            </>
          )}
        </MoreLessButton>
      )}
    </Container>
  );
}
