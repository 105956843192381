import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type ProductImageDataType = {
  component_type: string;
  item_name: string;
  item_id: string;
  link_url: string;
  index: number;
};

export function sendViewProductImageEvent(productImageData: ProductImageDataType) {
  sendDataLayerEvent(EventNamesType.view_product_image, {
    custom_data: {
      ...productImageData,
    },
  });
}
