import { useTranslation } from 'next-i18next';
import { useForm, useWatch } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { FindOrderSchema } from '@shared/validation/src/storefront';
import { BlueButton, WhiteButton } from '../../../baseComponents/button';
import { FieldContainer } from '../../../baseComponents/containers/FieldContainer';
import { ErrorMessage } from '../../../baseComponents/errors';
import { InputField } from '../../../baseComponents/inputs/Input';
import { OrSeparator } from '../../../baseComponents/separator/OrSeparator';
import { useFindOrder } from '../../../hooks/useFindOrder';
import { TrackDelivery } from '../../../icons';
import { media, spacing } from '../../../stylings';
import type { FindOrderFormProps } from '../../../types/order';

const mobileStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`;

const desktopStyles = css`
  display: grid;
  gap: ${spacing.XS};
  grid-template-columns: repeat(2, minmax(0, 388.5px));
`;

const S = {
  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    width: 100%;
    @media ${media.greaterThan('lg')} {
      gap: 28px;
      max-width: 777px;
    }
  `,
  UpperBlock: styled.div`
    ${mobileStyles}
    @media ${media.greaterThan('lg')} {
      ${desktopStyles}
      grid-template-areas: 'orderNumber deliveryPostcode' 'findOrderBlock .' 'gqlError gqlError';
    }
  `,
  LowerBlock: styled.div`
    ${mobileStyles}
    @media ${media.greaterThan('lg')} {
      ${desktopStyles}
      grid-template-areas: 'alreadyRegistered .' 'login .';
    }
  `,
  OrderNumber: styled(FieldContainer)`
    grid-area: orderNumber;
  `,
  DeliveryPostcode: styled(FieldContainer)`
    grid-area: deliveryPostcode;
  `,
  FindOrder: styled(BlueButton)`
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 0;
    width: 100%;
  `,
  GqlError: styled.div`
    grid-area: gqlError;
  `,
  Divider: styled(OrSeparator)`
    grid-area: or;
    margin: 15px 0;
    @media ${media.greaterThan('lg')} {
      margin: 0;
    }
  `,
  AlreadyRegistered: styled.p`
    grid-area: alreadyRegistered;
    margin: 0;
    padding: 0;
  `,
  LoginBlock: styled.div`
    ${mobileStyles}
    grid-area: login;
  `,
  Login: styled(WhiteButton)``,
};

export function FindOrderForm() {
  const { t } = useTranslation(['lib-global-common']);
  const { gqlError, loading, onSubmit, toLogin } = useFindOrder();
  const {
    register,
    handleSubmit,
    formState: { errors: formError },
    control,
  } = useForm<FindOrderFormProps>({
    mode: 'onChange',
    resolver: yupResolver(FindOrderSchema),
  });

  const [orderNumber, deliveryPostcode] = useWatch({
    control,
    name: ['orderNumber', 'deliveryPostcode'],
  });

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <S.UpperBlock>
        <S.OrderNumber>
          <InputField
            aria-label={t('find.order.order.number')}
            type="text"
            placeholder={t('find.order.order.number')}
            aria-describedby="order-number-invalid"
            aria-invalid={!!formError.orderNumber}
            register={register('orderNumber')}
            isEmpty={!orderNumber}
            isInputError={!!formError.orderNumber}
          />
          <ErrorMessage id="order-number-invalid" {...formError.orderNumber}>
            {t(formError?.orderNumber?.message as string)}
          </ErrorMessage>
        </S.OrderNumber>
        <S.DeliveryPostcode>
          <InputField
            aria-label={t('find.order.delivery.postcode')}
            type="text"
            placeholder={t('find.order.delivery.postcode')}
            aria-describedby="delivery-postcode-invalid"
            aria-invalid={!!formError.deliveryPostcode}
            register={register('deliveryPostcode')}
            isEmpty={!deliveryPostcode}
            isInputError={!!formError.deliveryPostcode}
          />
          <ErrorMessage id="delivery-postcode-invalid" {...formError.deliveryPostcode}>
            {t(formError?.deliveryPostcode?.message as string)}
          </ErrorMessage>
        </S.DeliveryPostcode>
        <S.FindOrder disabled={loading} type="submit">
          <TrackDelivery aria-hidden /> {t('find.order.title')}
        </S.FindOrder>
        {gqlError && (
          <S.GqlError>
            <ErrorMessage id="order-invalid" errorMessage={!gqlError}>
              {t(gqlError)}
            </ErrorMessage>
          </S.GqlError>
        )}
      </S.UpperBlock>

      <S.Divider />

      <S.LowerBlock>
        <S.AlreadyRegistered>{t('find.order.already.registered')}</S.AlreadyRegistered>
        <S.LoginBlock>
          <WhiteButton type="button" onClick={toLogin}>
            {t('find.order.login')}
          </WhiteButton>
        </S.LoginBlock>
      </S.LowerBlock>
    </S.Form>
  );
}
