import type { ReactElement } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import type {
  CallToAction,
  CategoryFeaturedCard as CategoryFeaturedCardSchema,
  DesktopMedia,
  MobileMedia,
} from '@amplience/content-types/typings/c-category-featured-card';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { LinkPartial, hasHref } from '../../partials/link';
import type { MediaPartialProps } from '../../partials/media';
import { MediaPartial } from '../../partials/media';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { sendNavigationEvent } from '../../utils/gtm/events/navigation/sendNavigationEvent';
import { getLocalizedValue, transformTextToId } from '../../utils/transformers';

export type CategoryFeaturedCardProps = CategoryFeaturedCardSchema & { extendWidth?: boolean };
const S = {
  DesktopCard: styled.article<{ $extendWidth: boolean }>`
    display: none;
    @media ${media.greaterThan('lg')} {
      display: initial;
      gap: ${spacing.XS};
      max-width: ${({ $extendWidth }) => ($extendWidth ? '255px' : '190px')};
    }
  `,
  MobileCard: styled.article`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXXS};
    max-width: 262px;
    width: 100%;
    @media ${media.greaterThan('lg')} {
      display: none;
    }
  `,
  CardWrapper: styled.figure`
    gap: ${spacing.XXXS};
    margin: 0;
    span {
      @media ${media.greaterThan('lg')} {
        gap: ${spacing.XXS};
        outline: 1.13px solid ${colours.LIGHTER_GREY};
      }
    }
  `,

  Title: styled.span`
    ${TypographyStyles.Label.Large.Medium}
    display: block;
    font-size: 16px;
    margin: 0 0 ${spacing.XXXS} 0;
    text-align: left;
    white-space: normal;
    width: 280px;
    @media ${media.greaterThan('lg')} {
      border-radius: 0;
      font-size: 14px;
      margin: 0 0 ${spacing.XXXXS} 0;
      min-height: 22px;
      text-align: left;
      width: inherit;
    }
  `,
  Link: styled.div`
    font-size: 14px;
    a {
      color: inherit;
      display: flex;
      font-weight: 300;
      text-decoration: none;
    }
  `,
  TexContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXXS};
    justify-content: center;
    margin: 0;
  `,
  CTA: styled.span`
    ${TypographyStyles.CallToAction.Tiny.SemiBold}
    font-size: 14px;
    text-align: left;
    text-decoration-line: underline;
    text-transform: capitalize;
    white-space: normal;
    @media ${media.greaterThan('lg')} {
      font-size: 12px;
    }
  `,
  LinkPartial: styled(LinkPartial)`
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin: 0px;
    padding: 0px;
  `,
};

interface CartTitleProps {
  title?: string;
}

interface CTAProps {
  cta?: CallToAction;
}

type MobileCardProps = CartTitleProps &
  CTAProps & {
    mobile?: MobileMedia;
    handleClick: () => void;
  };

type DesktopCardProps = CartTitleProps &
  CTAProps & {
    desktop?: DesktopMedia;
    handleClick: () => void;
    extendWidth?: boolean;
  };

function CartTitle({ title }: CartTitleProps) {
  return <S.Title>{title}</S.Title>;
}

function CTA({ cta }: CTAProps) {
  if (cta) {
    const localizedText = getLocalizedValue(cta.text);
    const ctaId = transformTextToId(localizedText);
    return (
      <S.Link>
        {hasHref(cta.link) ? (
          <LinkPartial id={ctaId} ariaLabel={localizedText} {...cta.link}>
            <S.CTA>{localizedText}</S.CTA>
          </LinkPartial>
        ) : (
          <span>{localizedText}</span>
        )}
      </S.Link>
    );
  }
  return null;
}
function CardMedia({
  media: amplienceMedia,
  extendWidth,
}: {
  media?: DesktopMedia | MobileMedia;
  extendWidth?: boolean;
}) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));

  const dimensions = useMemo(() => {
    if (isDesktop) {
      return {
        ...amplienceMedia?.media,
        altText: amplienceMedia?.altText,
        videoQuality: amplienceMedia?.videoQuality,
        width: extendWidth ? 255 : 190,
        height: extendWidth ? 142 : 285,
      };
    }
    return {
      ...amplienceMedia?.media,
      altText: amplienceMedia?.altText,
      videoQuality: amplienceMedia?.videoQuality,
      width: 262,
      height: 148,
    };
  }, [amplienceMedia?.altText, amplienceMedia?.media, amplienceMedia?.videoQuality, extendWidth, isDesktop]);
  return (
    <S.CardWrapper>
      <MediaPartial {...(dimensions as MediaPartialProps)} />
    </S.CardWrapper>
  );
}

function MobileCard({ mobile, cta, title, handleClick }: MobileCardProps) {
  const localizedText = cta ? getLocalizedValue(cta.text) : '';

  return (
    <S.MobileCard onClick={handleClick}>
      <CardMedia media={mobile} />
      <S.TexContainer>
        <S.Link>
          {cta && hasHref(cta.link) ? (
            <S.LinkPartial ariaLabel={localizedText} {...cta.link}>
              <CartTitle title={title} />
              <S.CTA>{localizedText}</S.CTA>
            </S.LinkPartial>
          ) : (
            <CartTitle title={title} />
          )}
        </S.Link>
      </S.TexContainer>
    </S.MobileCard>
  );
}

function DesktopCard({ desktop, title, cta, handleClick, extendWidth = false }: DesktopCardProps) {
  return (
    <S.DesktopCard onClick={handleClick} $extendWidth={extendWidth}>
      <CardMedia media={desktop} extendWidth={extendWidth} />
      <S.TexContainer>
        <CartTitle title={title} />
        <CTA cta={cta} />
      </S.TexContainer>
    </S.DesktopCard>
  );
}
export function CategoryFeaturedCard({
  desktopMediaSlot,
  mobileMediaSlot,
  title,
  link: cta,
  extendWidth,
}: CategoryFeaturedCardProps): ReactElement {
  const localizedText = getLocalizedValue(title);
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const handleClick = () => {
    sendNavigationEvent({
      component_header: localizedText,
      component_type: 'main nav - image link',
      link_text: getLocalizedValue(cta?.text),
    });
  };
  return isDesktop ? (
    <DesktopCard
      handleClick={handleClick}
      desktop={desktopMediaSlot}
      title={localizedText}
      cta={cta}
      extendWidth={extendWidth}
    />
  ) : (
    <MobileCard handleClick={handleClick} mobile={mobileMediaSlot} title={localizedText} cta={cta} />
  );
}

export default CategoryFeaturedCard;
