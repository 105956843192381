import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import type { ReactNode } from 'react';
import { useRouter } from 'next/router';
import type { MarketingPreferencesResponse, PaymentMethod, SiteSwitchResponse, Store } from '../codegen/types';
import type { MarketingCategory } from '../types/marketingPreferences';
import { countryNameMapperROE, getCountryKey } from '../utils/localeHelper';

type MarketingPreferences = {
  marketingPreferenceDefaultEnabled: boolean | null;
  marketingPreferences: MarketingCategory;
  languages: string[];
};

type StaticContextType = {
  host: string | '';
  previousURL: string | '';
  paymentMethods: PaymentMethod[] | null;
  marketingPreferences: MarketingPreferences | null;
  storeData: Store | null;
  switchSitesData: SiteSwitchResponse[] | null;
};

const StaticContext = createContext<StaticContextType>({
  host: '',
  previousURL: '',
  paymentMethods: null,
  marketingPreferences: null,
  storeData: null,
  switchSitesData: null,
});

export const useStaticContext = () => useContext(StaticContext);

type StaticContextProviderProps = {
  children: ReactNode;
  host: string | '';
  paymentMethods: PaymentMethod[] | null;
  marketingPreferences: MarketingPreferencesResponse | null;
  storeData: Store | null;
  switchSitesData: SiteSwitchResponse[] | null;
};

export function StaticContextProvider({
  children,
  host,
  paymentMethods,
  marketingPreferences,
  storeData,
  switchSitesData,
}: StaticContextProviderProps) {
  const { asPath, locale } = useRouter() ?? {};
  const ref = useRef('');

  const transformedMarketingPreferences = marketingPreferences?.marketingPreferences.reduce(
    (acc, item, idx) => ({
      ...acc,
      [idx]: {
        key: item.key,
        label: item.label,
        state: !!marketingPreferences.marketingPreferenceDefaultEnabled,
      },
    }),
    {},
  ) as MarketingCategory;

  const marketingPreferencesData = useMemo(
    () => ({
      marketingPreferenceDefaultEnabled: !!marketingPreferences?.marketingPreferenceDefaultEnabled,
      marketingPreferences: transformedMarketingPreferences,
      languages: marketingPreferences?.languages || [],
    }),
    [
      marketingPreferences?.languages,
      marketingPreferences?.marketingPreferenceDefaultEnabled,
      transformedMarketingPreferences,
    ],
  );

  useEffect(() => {
    ref.current = asPath ?? '';
  }, [asPath]);

  const previousURL = `https://${host}/${locale}${ref.current}`;

  const switchSitesCountriesROE = Object.keys(countryNameMapperROE).map(
    (key) =>
      ({
        countryName: [
          {
            locale: 'en',
            value: countryNameMapperROE[key],
          },
        ],
        currency: 'EUR',
        currencySymbol: '€',
        key: `roe-${key.toLowerCase()}`,
        languages: [`en-${key.toUpperCase()}`],
      } as SiteSwitchResponse),
  );

  const switchSitesEnLanguageData =
    switchSitesData?.map((obj) => {
      if (!obj.languages.some((lang) => lang.startsWith('en'))) {
        return { ...obj, languages: [...obj.languages, `en-${getCountryKey(obj.key).toUpperCase()}`] };
      }
      return obj;
    }) || [];

  const switchSitesLanguagesData = switchSitesEnLanguageData.map((obj) => ({
    ...obj,
    languages: obj.languages.map((lang) => {
      if (!lang.includes('-')) {
        return `${lang}-${getCountryKey(obj.key).toUpperCase()}`;
      }
      return lang;
    }),
  }));

  const combinedSwitchSites = [...switchSitesLanguagesData, ...switchSitesCountriesROE];

  const switchSitesFullData = combinedSwitchSites
    .reduce((acc: SiteSwitchResponse[], current) => {
      const isDuplicate = acc.some((item) => item.key === current.key);
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    }, [])
    .filter((data) => data.key !== 'roe' && data.key !== 'roe-scand');

  const staticContext = useMemo(
    () => ({
      host,
      previousURL,
      paymentMethods,
      marketingPreferences: marketingPreferencesData,
      storeData,
      switchSitesData: switchSitesFullData,
    }),
    [host, previousURL, paymentMethods, marketingPreferencesData, storeData, switchSitesFullData],
  );

  return <StaticContext.Provider value={staticContext}>{children}</StaticContext.Provider>;
}
