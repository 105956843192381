import type { AutocompleteSource } from '@algolia/autocomplete-js';

export const DEBOUNCE_SEARCH_SOURCE_DELAY = 300; // ms

export function debouncePromise<T>(fn: (...args: T[]) => Promise<T>, time: number): (...args: T[]) => Promise<T> {
  let timerId: NodeJS.Timeout | undefined;

  return (...args: T[]): Promise<T> => {
    if (timerId) {
      clearTimeout(timerId);
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => {
        resolve(fn(...args));
      }, time);
    });
  };
}

/**
 * Type any is use as a workaround for items type.
 * It should be fixed in the future as a part of types refactor for Autocomplete/Algolia components
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debouncedSources = debouncePromise<Array<boolean | AutocompleteSource<any> | undefined>>(
  (items) => Promise.resolve(items),
  DEBOUNCE_SEARCH_SOURCE_DELAY,
);
