/* eslint-disable @typescript-eslint/no-explicit-any */
import dynamic from 'next/dynamic';
import BottomSpacingSchema from '@amplience/content-types/src/content-schemas/c-bottom-spacing.json';
import CategoryFeaturedCardSchema from '@amplience/content-types/src/content-schemas/c-category-featured-card.json';
import EmptyBasketSchema from '@amplience/content-types/src/content-schemas/c-empty-basket.json';
import FooterAccordionSchema from '@amplience/content-types/src/content-schemas/c-footer-accordion.json';
import FooterIconsBlockSchema from '@amplience/content-types/src/content-schemas/c-footer-icons-block.json';
import FooterKeyLinksSchema from '@amplience/content-types/src/content-schemas/c-footer-key-links.json';
import MainLinksColumnSchema from '@amplience/content-types/src/content-schemas/c-footer-main-links-column.json';
import NewsletterSignUpSchema from '@amplience/content-types/src/content-schemas/c-footer-newsletter-sign-up.json';
import FooterSchema from '@amplience/content-types/src/content-schemas/c-footer.json';
import HeaderSchema from '@amplience/content-types/src/content-schemas/c-header.json';
import MarketingMessageSchema from '@amplience/content-types/src/content-schemas/c-marketing-message.json';
import MediaGridCarouselSchema from '@amplience/content-types/src/content-schemas/c-media-grid-carousel.json';
import NavigationBarSchema from '@amplience/content-types/src/content-schemas/c-navigation-bar.json';
import NeedHelpSectionCheckoutSchema from '@amplience/content-types/src/content-schemas/c-need-help-section-checkout.json';
import NotificationBarSchema from '@amplience/content-types/src/content-schemas/c-notification-bar.json';
import PaymentMethodsSchema from '@amplience/content-types/src/content-schemas/c-payment-methods.json';
import PriceTooltipSchema from '@amplience/content-types/src/content-schemas/c-price-tooltip.json';
import ProductCarouselSchema from '@amplience/content-types/src/content-schemas/c-product-carousel.json';
import PromotionalLinksNavSchema from '@amplience/content-types/src/content-schemas/c-promotional-links-nav.json';
import RelatedProductsCarouselSchema from '@amplience/content-types/src/content-schemas/c-related-products-carousel.json';
import ScrollToTopSchema from '@amplience/content-types/src/content-schemas/c-scroll-to-top.json';
import ShippingSectionSchema from '@amplience/content-types/src/content-schemas/c-shipping-section.json';
import SimpleAccordionsSchema from '@amplience/content-types/src/content-schemas/c-simple-accordions.json';
import SiteSwitcherSchema from '@amplience/content-types/src/content-schemas/c-site-switcher.json';
import SizeCalculatorSchema from '@amplience/content-types/src/content-schemas/c-size-calculator.json';
import SmallFooterLinksSchema from '@amplience/content-types/src/content-schemas/c-small-footer-links.json';
import SubNavSchema from '@amplience/content-types/src/content-schemas/c-sub-nav.json';
import TrendingCarouselSchema from '@amplience/content-types/src/content-schemas/c-trending-carousel.json';
import HeaderNavigationSchema from '@amplience/content-types/src/content-schemas/h-header-navigation.json';
import MegamenuLayoutSchema from '@amplience/content-types/src/content-schemas/h-megamenu-layout.json';
import { HandlebarTemplate } from './handlebarTemplate/HandlebarTemplate';

/* eslint-disable promise/prefer-await-to-then */
const HeaderComponent = dynamic(() => import('./header/Header').then((mod) => mod.HeaderComponent));

const MarketingMessage = dynamic(() => import('./header/MarketingMessage').then((mod) => mod.MarketingMessage));

const NavigationBar = dynamic(() => import('./header/NavigationBar').then((mod) => mod.NavigationBar));

const NotificationBar = dynamic(() => import('./header/NotificationBar').then((mod) => mod.NotificationBar));

const PromotionalLinksNav = dynamic(() =>
  import('./header/PromotionalLinksNav').then((mod) => mod.PromotionalLinksNav),
);

const SubNav = dynamic(() => import('./header/SubNav').then((mod) => mod.SubNav));

const ScrollToTop = dynamic(() => import('./footer/scrollToTop/ScrollToTop').then((mod) => mod.ScrollToTop));

const SmallFooterLinks = dynamic(() => import('./footer/SmallFooterLinks').then((mod) => mod.SmallFooterLinks));

const FooterIconsBlock = dynamic(() =>
  import('./footer/footerIconsBlock/FooterIconsBlock').then((mod) => mod.FooterIconsBlock),
);

const NewsletterSignUp = dynamic(() => import('./footer/NewsletterSignUp').then((mod) => mod.NewsletterSignUp));

const FooterAccordion = dynamic(() => import('./footer/FooterAccordion').then((mod) => mod.FooterAccordion));

const CategoryFeaturedCard = dynamic(() =>
  import('./navigation/CategoryFeaturedCard').then((mod) => mod.CategoryFeaturedCard),
);
const MainLinksColumn = dynamic(() => import('./footer/MainLinksColumn').then((mod) => mod.MainLinksColumn));
const SiteSwitcher = dynamic(() => import('./footer/SiteSwitcher').then((mod) => mod.SiteSwitcher));
const FooterComponent = dynamic(() => import('./footer/Footer').then((mod) => mod.Footer));

const Megamenu = dynamic(() => import('./navigation/MegaMenu').then((mod) => mod.Megamenu));

const HeaderNavigation = dynamic(() =>
  import('./navigation/headerNavigation/HeaderNavigation').then((mod) => mod.HeaderNavigation),
);

const AmpliencePaymentMethods = dynamic(() =>
  import('./paymentMethods/AmpliencePaymentMethods').then((mod) => mod.AmpliencePaymentMethods),
);

const MediaCarousel = dynamic(() => import('./carousel/MediaCarousel').then((mod) => mod.MediaCarousel));

const EmptyBasket = dynamic(() => import('./cart/emptyBasket/EmptyBasket').then((mod) => mod.EmptyBasket));

const PriceTooltip = dynamic(() => import('./tooltip/PriceTooltip').then((mod) => mod.PriceTooltip));

const ProductCarousel = dynamic(() =>
  import('./carousel/productCarousel/ProductCarousel').then((mod) => mod.ProductCarousel),
);

const FooterKeyLinks = dynamic(() => import('./footer/FooterKeyLinks').then((mod) => mod.FooterKeyLinks));

const TrendingCarousel = dynamic(() => import('./carousel/TrendingCarousel').then((mod) => mod.TrendingCarousel));

const SizeCalculator = dynamic(() => import('./sizeCalculator/SizeCalculator').then((mod) => mod.SizeCalculator));

const ShippingSection = dynamic(() => import('./shippingSection/ShippingSection').then((mod) => mod.ShippingSection));

const RelatedProductsCarousel = dynamic(() =>
  import('./carousel/relatedProductsCarousel/RelatedProductsCarousel').then((mod) => mod.RelatedProductsCarousel),
);

const SimpleAccordions = dynamic(() =>
  import('./simpleAccordions/SimpleAccordions').then((mod) => mod.SimpleAccordions),
);

const Spacer = dynamic(() => import('./spacer/Spacer').then((mod) => mod.Spacer));

const NeedHelpSectionCheckout = dynamic(() =>
  import('./cart/needHelp/NeedHelpSectionCheckout').then((mod) => mod.NeedHelpSectionCheckout),
);

/* eslint-enable promise/prefer-await-to-then */

export function createGlobalAmplienceComponent<T extends unknown[]>(array: T): any {
  const components: any = {
    'handlebar-component': HandlebarTemplate,
  };
  array?.forEach((comp) => {
    const componentProps = comp as any;

    if (!componentProps?._meta) {
      return;
    }

    if (componentProps._meta.schema === HeaderSchema.$id) {
      components[HeaderSchema.$id] = HeaderComponent;
    } else if (componentProps._meta.schema === MarketingMessageSchema.$id) {
      components[MarketingMessageSchema.$id] = MarketingMessage;
    } else if (componentProps._meta.schema === NavigationBarSchema.$id) {
      components[NavigationBarSchema.$id] = NavigationBar;
    } else if (componentProps._meta.schema === NotificationBarSchema.$id) {
      components[NotificationBarSchema.$id] = NotificationBar;
    } else if (componentProps._meta.schema === PromotionalLinksNavSchema.$id) {
      components[PromotionalLinksNavSchema.$id] = PromotionalLinksNav;
    } else if (componentProps._meta.schema === SubNavSchema.$id) {
      components[SubNavSchema.$id] = SubNav;
    } else if (componentProps._meta.schema === ScrollToTopSchema.$id) {
      components[ScrollToTopSchema.$id] = ScrollToTop;
    } else if (componentProps._meta.schema === SmallFooterLinksSchema.$id) {
      components[SmallFooterLinksSchema.$id] = SmallFooterLinks;
    } else if (componentProps._meta.schema === FooterIconsBlockSchema.$id) {
      components[FooterIconsBlockSchema.$id] = FooterIconsBlock;
    } else if (componentProps._meta.schema === NewsletterSignUpSchema.$id) {
      components[NewsletterSignUpSchema.$id] = NewsletterSignUp;
    } else if (componentProps._meta.schema === FooterAccordionSchema.$id) {
      components[FooterAccordionSchema.$id] = FooterAccordion;
    } else if (componentProps._meta.schema === CategoryFeaturedCardSchema.$id) {
      components[CategoryFeaturedCardSchema.$id] = CategoryFeaturedCard;
    } else if (componentProps._meta.schema === MainLinksColumnSchema.$id) {
      components[MainLinksColumnSchema.$id] = MainLinksColumn;
    } else if (componentProps._meta.schema === SiteSwitcherSchema.$id) {
      components[SiteSwitcherSchema.$id] = SiteSwitcher;
    } else if (componentProps._meta.schema === FooterSchema.$id) {
      components[FooterSchema.$id] = FooterComponent;
    } else if (componentProps._meta.schema === MegamenuLayoutSchema.$id) {
      components[MegamenuLayoutSchema.$id] = Megamenu;
    } else if (componentProps._meta.schema === HeaderNavigationSchema.$id) {
      components[HeaderNavigationSchema.$id] = HeaderNavigation;
    } else if (componentProps._meta.schema === PaymentMethodsSchema.$id) {
      components[PaymentMethodsSchema.$id] = AmpliencePaymentMethods;
    } else if (componentProps._meta.schema === MediaGridCarouselSchema.$id) {
      components[MediaGridCarouselSchema.$id] = MediaCarousel;
    } else if (componentProps._meta.schema === EmptyBasketSchema.$id) {
      components[EmptyBasketSchema.$id] = EmptyBasket;
    } else if (componentProps._meta.schema === PriceTooltipSchema.$id) {
      components[PriceTooltipSchema.$id] = PriceTooltip;
    } else if (componentProps._meta.schema === ProductCarouselSchema.$id) {
      components[ProductCarouselSchema.$id] = ProductCarousel;
    } else if (componentProps._meta.schema === FooterKeyLinksSchema.$id) {
      components[FooterKeyLinksSchema.$id] = FooterKeyLinks;
    } else if (componentProps._meta.schema === TrendingCarouselSchema.$id) {
      components[TrendingCarouselSchema.$id] = TrendingCarousel;
    } else if (componentProps._meta.schema === SizeCalculatorSchema.$id) {
      components[SizeCalculatorSchema.$id] = SizeCalculator;
    } else if (componentProps._meta.schema === ShippingSectionSchema.$id) {
      components[ShippingSectionSchema.$id] = ShippingSection;
    } else if (componentProps._meta.schema === RelatedProductsCarouselSchema.$id) {
      components[RelatedProductsCarouselSchema.$id] = RelatedProductsCarousel;
    } else if (componentProps._meta.schema === SimpleAccordionsSchema.$id) {
      components[SimpleAccordionsSchema.$id] = SimpleAccordions;
    } else if (componentProps._meta.schema === BottomSpacingSchema.$id) {
      components[BottomSpacingSchema.$id] = Spacer;
    } else if (componentProps._meta.schema === NeedHelpSectionCheckoutSchema.$id) {
      components[NeedHelpSectionCheckoutSchema.$id] = NeedHelpSectionCheckout;
    }
  });

  return components;
}
