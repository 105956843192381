import { useEffect, useState } from 'react';
import { media } from '../stylings';
import { useDisableBodyScroll } from './useDisableBodyScroll';
import { useMediaMatch } from './useMediaMatch';

export const useMobileOverlay = (seconds?: number) => {
  const [isOverLayOpen, setOverLayOpen] = useState(false);
  const isDesktop = useMediaMatch(media.greaterThan('lg'));

  useEffect(() => {
    if (isDesktop) {
      setOverLayOpen(false);
    }
  }, [isDesktop]);

  useDisableBodyScroll(isOverLayOpen);

  const handleOpenOverlay = () => {
    setOverLayOpen((prev) => !prev);
    if (seconds) {
      setTimeout(() => setOverLayOpen(false), seconds);
    }
  };

  return { isOverLayOpen, handleOpenOverlay };
};
