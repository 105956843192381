import type { SwiperOptions } from 'swiper/types';

export const mediaGridCarouselSettings: Record<string, SwiperOptions> = {
  320: {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
  400: {
    slidesPerView: 'auto',
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 32,
  },
  1440: {
    slidesPerView: 3.2,
    spaceBetween: 32,
  },
};

export const productCarouselMultiImageSettings: Record<string, SwiperOptions> = {
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 2,
    spaceBetween: 32,
  },
};
