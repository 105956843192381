import { ConfigService } from '../types';

export function merchantAccountMapper(locale: string, config: ConfigService) {
  const localeToMerchantAccountMap: {
    [key: string]: string;
  } = {
    'en-gb': config.getRequired('UK_MERCHANT_NAME'),
    'en-ie': config.getRequired('ROI_MERCHANT_NAME'),
    'en-us': config.getRequired('US_MERCHANT_NAME'),
    'en-ca': config.getRequired('CANADA_MERCHANT_NAME'),
    'fr-ca': config.getRequired('CANADA_MERCHANT_NAME'),
    'en-vg': config.getRequired('UK_OUTLET_MERCHANT_NAME'),
    'en-gb-outlet': config.getRequired('UK_OUTLET_MERCHANT_NAME'),
  };

  const lowerLocale = locale.toLowerCase();
  return localeToMerchantAccountMap[lowerLocale];
}

export enum AdyenPaymentMethods {
  CARD = 'scheme',
  PAY_PAL = 'paypal',
  GOOGLE_PAY = 'googlepay',
  APPLE_PAY = 'applepay',
  KLARNA = 'klarna',
  KLARNA_ACCOUNT = 'klarna_account',
  GIFT_CARD = 'giftcard',
}

const onOrderPlacedCaptureConfig: Record<string, Set<string> | undefined> = {
  uk: new Set([AdyenPaymentMethods.PAY_PAL]),
  'outlet-uk': new Set([AdyenPaymentMethods.PAY_PAL]),
  roi: new Set([AdyenPaymentMethods.PAY_PAL]),
};

export function getOnOrderPlacedCaptureConfig(store: string) {
  return onOrderPlacedCaptureConfig[store.toLowerCase()];
}

export function loadFromAdyenIntegrationEnvVar() {
  try {
    const adyenMerchantConfig = JSON.parse(process.env.adyenMerchantConfig as string);
    const adyenEcomMerchants = JSON.parse(process.env.adyenEcomMerchants as string);
    const adyenMotoMerchants = JSON.parse(process.env.adyenMotoMerchants as string);
    const commercetools = JSON.parse(process.env.commercetools as string);

    const config = JSON.parse(process.env.ADYEN_INTEGRATION_CONFIG as string);
    config.commercetools = { [commercetools.ctProjectKey]: commercetools };
    config.adyen = [...adyenEcomMerchants, ...adyenMotoMerchants].reduce(
      (acc: Record<string, typeof adyenMerchantConfig>, merchant: string) => {
        acc[merchant] = adyenMerchantConfig;
        return acc;
      },
      {},
    );
    return config;
  } catch (e) {
    throw new Error('Adyen integration configuration is not provided in the JSON format');
  }
}

export const AdyenCtIalConstants = {
  CTP_ADYEN_INTEGRATION: 'ctp-adyen-integration',
  CTP_PAYMENT_INTERACTION_CUSTOM_TYPE_KEY: 'ctp-adyen-integration-interaction-payment-type',
  CTP_PAYMENT_CUSTOM_TYPE_KEY: 'ctp-adyen-integration-web-components-payment-type',
  CTP_INTERACTION_TYPE_CANCEL_PAYMENT: 'cancelPayment',
  CTP_INTERACTION_TYPE_GET_PAYMENT_METHODS: 'getPaymentMethods',
  CTP_CUSTOM_FIELD_GET_PAYMENT_METHODS_RESPONSE: 'getPaymentMethodsResponse',
  CTP_INTERACTION_TYPE_MANUAL_CAPTURE: 'manualCapture',
  CTP_INTERACTION_TYPE_REFUND: 'refund',

  PAYMENT_METHOD_TYPE_KLARNA_METHODS: ['klarna', 'klarna_paynow', 'klarna_account'],

  PAYMENT_METHOD_TYPE_AFFIRM_METHODS: ['affirm'],
  PAYMENT_METHODS_WITH_REQUIRED_LINE_ITEMS: [
    'klarna',
    'affirm',
    'afterpay',
    'ratepay',
    'facilypay',
    'clearpay',
    'grabpay',
    'paybright',
    'pix',
    'zip',
  ],
  CTP_CARBON_OFFSET_COSTS_RESPONSE: 'getCarbonOffsetCostsResponse',
  CTP_INTERACTION_TYPE_GET_CARBON_OFFSET_COSTS: 'getCarbonOffsetCosts',
  CTP_CUSTOM_FIELD_AMOUNT_UPDATES_RESPONSE: 'amountUpdatesResponse',
  CTP_INTERACTION_TYPE_AMOUNT_UPDATES: 'amountUpdates',
  CTP_INTERACTION_TYPE_CREATE_SESSION: 'createSession',
  CTP_INTERACTION_TYPE_CREATE_SESSION_RESPONSE: 'createSessionResponse',
  CTP_INTERACTION_TYPE_DISABLE_STORED_PAYMENT: 'disableStoredPayment',
  CTP_DISABLE_STORED_PAYMENT_RESPONSE: 'disableStoredPaymentResponse',
  ADYEN_LEGACY_API_VERSION: {
    MANUAL_CAPTURE: 'v64',
    CANCEL: 'v64',
    REFUND: 'v64',
    DISABLED_STORED_PAYMENT: 'v68',
  },
};
