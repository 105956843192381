import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { TrendingCarousel as TrendingCarouselProps } from '@amplience/content-types/typings/c-trending-carousel';
import { useLocaleContext } from '../../context/localeContext';
import { useAlgoliaTrendingItems } from '../../hooks/useAlgoliaTrendingItems';
import { useIntersectOnce } from '../../hooks/useIntersectOnce';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { useTruncated } from '../../hooks/useTruncated';
import { ArrowTailLeft, ArrowTailRight } from '../../icons';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import { getAlgoliaProductIndex } from '../../services/algolia/searchClient';
import {
  TypographyStylesType,
  colours,
  maxWidthPartial,
  media,
  slideToRightArrowsAnimation,
  spacing,
} from '../../stylings';
import AlgoliaCarouselItem from './algoliaCarouselItem/AlgoliaCarouselItem';

export type RecommendationItemHit = {
  objectID: string;
  _score?: number | undefined;
  images: string[];
};

const S = {
  CarouselContainer: styled.div`
    @media ${media.greaterThan('xx')} {
      display: flex;
      justify-content: center;
    }
  `,
  Container: styled.div`
    ${maxWidthPartial({ withPaddingXL: true, shouldBleedRight: true })}
  `,
  TitleContainer: styled.div`
    align-items: center;
    display: flex;

    .products-count {
      font-size: 14px;
      margin-left: ${spacing.XXXS};

      @media ${media.greaterThan('lg')} {
        font-size: 16px;
        margin-left: 10px;
      }
    }
  `,
  CarouselTitle: styled(FlexibleTextPartial)`
    margin: 0;
  `,
  SwiperContainer: styled(Swiper)`
    display: flex;
    flex-direction: row;
  `,
  SwiperSlideContainer: styled(SwiperSlide)<{ $isMultiImage: boolean }>`
    width: ${({ $isMultiImage }) => ($isMultiImage ? '328px' : '272px')};
    &:nth-of-type(1) {
      margin-left: 0;
    }

    video {
      width: 100%;
    }
    @media ${media.greaterThan('lg')} {
      width: ${({ $isMultiImage }) => ($isMultiImage ? '623px' : '424px')};
    }
  `,
  TopContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    margin-top: 0;

    @media ${media.greaterThan('lg')} {
      margin-bottom: ${spacing.S};
    }
  `,
  IconContainer: styled.div`
    align-items: center;
    display: flex;
    margin-left: 19px;
    margin-right: ${spacing.S};

    &.product-icons {
      display: none;

      @media ${media.greaterThan('lg')} {
        display: flex;
      }
    }

    @media ${media.greaterThan('xl')} {
      margin-right: 70px;
    }

    @media ${media.greaterThan('xx')} {
      margin-right: 0;
    }
  `,
  IconButton: styled.button`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 2px solid ${colours.BLACK};
    border-radius: 50%;
    display: flex;
    height: 25px;
    justify-content: center;
    min-width: 25px;
    padding: 0;
    ${slideToRightArrowsAnimation}

    &.swiper-button-disabled {
      border: 2px solid ${colours.MEDIUM_GREY};
      cursor: not-allowed;

      .arrow-icon {
        path {
          fill: ${colours.MEDIUM_GREY};
        }
      }
    }

    &.swiper-button-lock {
      display: none;
    }

    svg {
      height: 10px;
      width: 10px;
    }

    &.nav-prev {
      margin-right: ${spacing.S};
    }
  `,
};

export function TrendingCarousel({
  title,
  layout,
  multiImageButtonText,
  maxRecommendations,
  index,
}: TrendingCarouselProps) {
  const { locale } = useLocaleContext();
  const indexName = getAlgoliaProductIndex(locale);
  const router = useRouter();

  const { elementRef, isIntersecting: isInView } = useIntersectOnce({
    rootMargin: '100%',
  });

  const query = useMemo(() => [{ indexName }], [indexName]);

  const { trendingItems: trending, isLoading } = useAlgoliaTrendingItems({
    query,
    isInView,
  });

  const trendingItems = isLoading ? (Array.from({ length: 5 }, () => ({})) as typeof trending) : trending;

  const [recommendationItems, setRecommendationItems] = useState<typeof trendingItems>([]);

  useEffect(() => {
    if (trendingItems.length !== 0) {
      setRecommendationItems(trendingItems.slice(0, maxRecommendations) || trendingItems);
    }
  }, [maxRecommendations, trendingItems]);

  const isMultiImage = layout === 'Multi Image';
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const isSingleImageCount = isDesktop ? recommendationItems.length > 3 : recommendationItems.length > 2;
  const isMultiImageCount = isDesktop ? trendingItems.length > 2 : trendingItems.length > 1;
  const { t } = useTranslation(['lib-global-common']);
  const textElRef = useRef<HTMLElement>(null);
  const { isTruncated, lineHeight } = useTruncated(textElRef);

  const isPDP = router.query.slug?.[router.query.slug.length - 1].toLocaleLowerCase().endsWith('-p');

  return (
    <S.CarouselContainer ref={elementRef}>
      {(isLoading || (!isLoading && recommendationItems.length > 0)) && (
        <S.Container key={isDesktop ? 'desktop-trending-carosuel' : 'mobile-trending-carousel'}>
          <S.TopContainer>
            <S.TitleContainer>
              <S.CarouselTitle
                {...title}
                defaultTextSize={TypographyStylesType.HEADINGS_H3}
                defaultTextColor={colours.BLACK}
                ref={textElRef}
                $lineHeight={lineHeight}
                className={`${isTruncated ? 'truncated' : ''}`}
              />
              {!isLoading && ((!isMultiImage && isSingleImageCount) || (isMultiImage && isMultiImageCount)) && (
                <span className="products-count">{`(${recommendationItems.length})`}</span>
              )}
            </S.TitleContainer>
            {((!isMultiImage && isSingleImageCount) || (isMultiImage && isMultiImageCount)) && (
              <S.IconContainer className="product-icons">
                <S.IconButton
                  type="button"
                  aria-label={t('media.carousel.aria.prev.button')}
                  className={`nav-prev media-grid-prev-${index}`}
                >
                  <ArrowTailRight className="arrow-icon" viewBox="5 4 6 14" />
                </S.IconButton>
                <S.IconButton
                  type="button"
                  aria-label={t('media.carousel.aria.next.button')}
                  className={` media-grid-next-${index}`}
                >
                  <ArrowTailLeft className="arrow-icon" viewBox="10 4 6 14" />
                </S.IconButton>
              </S.IconContainer>
            )}
          </S.TopContainer>
          <S.SwiperContainer
            modules={[Navigation, A11y]}
            navigation={{
              prevEl: `.media-grid-prev-${index}`,
              nextEl: `.media-grid-next-${index}`,
              enabled: !isLoading,
            }}
            a11y={{ slideRole: 'link' }}
            shortSwipes={!isDesktop}
            slidesPerView="auto"
            watchSlidesProgress
            spaceBetween={isDesktop ? 32 : 20}
          >
            {recommendationItems.length !== 0 &&
              recommendationItems
                .filter((item) => {
                  if (isLoading) return true;
                  return isMultiImage ? item.images && item.images.length > 3 : item;
                })
                .map((product, i) => (
                  <S.SwiperSlideContainer key={`${product.objectID}-${i}`} $isMultiImage={isMultiImage}>
                    <AlgoliaCarouselItem
                      isLoading={isLoading}
                      algoliaIndexName={indexName}
                      position={i + 1}
                      item={product}
                      isMultiImage={isMultiImage}
                      multiImageButtonText={multiImageButtonText}
                      carouselTitle={title?.text?.toString()}
                      isPDP={isPDP}
                    />
                  </S.SwiperSlideContainer>
                ))}
          </S.SwiperContainer>
        </S.Container>
      )}
    </S.CarouselContainer>
  );
}
