import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Return } from '../../icons';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, colours, spacing } from '../../stylings';
import { sendDataLayerEvent } from '../../utils/gtm/utils/dataLayerInteractions';

const S = {
  ReturnLinkWrapper: styled.div`
    ${TypographyStyles.CallToAction.Large.SemiBold}
    align-items: center;
    background: none;
    border: none;
    display: flex;
    gap: ${spacing.XXXXXS};
    height: 20px;
    justify-content: flex-start;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
    a {
      color: ${colours.BLACK};
      display: flex;
      gap: ${spacing.XXXXS};
    }
  `,
  ReturnsIcon: styled(Return)`
    svg {
      transform: scale(0.9);
    }
  `,
};

export function CreateReturnButton({ orderNumber, returnLink }: { orderNumber: string; returnLink?: string }) {
  const { t } = useTranslation(['app-orders-common']);

  const handleClick = () => {
    sendDataLayerEvent('create_return', {
      custom_data: {
        transaction_id: orderNumber,
      },
    });
  };

  return (
    <S.ReturnLinkWrapper>
      <LinkPartial externalLink={returnLink} onClick={handleClick}>
        <S.ReturnsIcon aria-hidden />
        {t('my.orders.create.return')}
      </LinkPartial>
    </S.ReturnLinkWrapper>
  );
}
