import styled from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

export const ProductItemContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  &:focus {
    outline: 2px solid ${colours.OUTLINE_BORDER};
  }
`;

export const Price = styled.div`
  align-items: center;
  column-gap: ${spacing.XXXS};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${spacing.XXXXS} 0 6px;
  min-height: 30px;
  padding: 0px;
  @media ${media.greaterThan('lg')} {
    margin: 0;
  }
`;

export const ProductLink = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${spacing.XXXS};
  @media ${media.greaterThan('lg')} {
    gap: ${spacing.XXS};
  }
`;

export const ProductName = styled.h2`
  -webkit-box-orient: vertical;
  display: block;
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  height: fit-content;
  -webkit-line-clamp: 2;
  margin: 0;
  min-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  width: inherit;
  ${TypographyStyles.Headings.H4SemiBold}
  @media ${media.greaterThan('lg')} {
    min-height: 48px;
  }
`;

export const ProductCategory = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 36px;
  width: inherit;
  @media ${media.greaterThan('lg')} {
    min-height: 44px;
  }
  & p {
    ${TypographyStyles.Body.Tiny.Regular}
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    height: fit-content;
    -webkit-line-clamp: 1;
    line-height: 18px;
    margin: 0;
    min-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    width: inherit;
    @media ${media.greaterThan('lg')} {
      line-height: 22px;
      min-height: 22px;
    }
  }
`;

export const ImageAndBadgeContainer = styled.div`
  position: relative;
`;
