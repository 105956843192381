import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { Delivery, LineItem } from '../../../codegen/types';
import { GiftCardType } from '../../../context/cartContext';
import { TypographyStyles, media, spacing } from '../../../stylings';
import { MultiGroupDeliveryItem } from './MultiGroupDeliveryItem';

const S = {
  Container: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: ${spacing.XXXS};
    width: 100%;

    @media ${media.greaterThan('lg')} {
      padding-top: ${spacing.S};
    }
  `,
  GroupWrapper: styled.div`
    display: flex;
    flex-direction: row;
    gap: ${spacing.XS};
    overflow-x: auto;
    padding-bottom: ${spacing.XXS};
    width: 100%;

    @media ${media.greaterThan('lg')} {
      flex-direction: column;
      gap: ${spacing.XS};
    }
  `,
  Group: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXXS};
    margin: ${spacing.XXXXS} 0 0 0;
  `,
  Items: styled.div`
    column-gap: ${spacing.XXXS};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media ${media.greaterThan('lg')} {
      flex-wrap: wrap;
      row-gap: ${spacing.XXXS};
    }
  `,
  ItemsDescription: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
  `,
  Title: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
};

type MultiGroupDeliveryListTypes = {
  lineItems: LineItem[];
  deliveries: Delivery[] | undefined | null;
  isDigitalCart: boolean;
};

export const FulfillmentNodeType = {
  STORE: 'store',
  WAREHOUSE: 'warehouse',
};

export function MultiGroupDeliveryList({ lineItems, deliveries, isDigitalCart }: MultiGroupDeliveryListTypes) {
  const { t } = useTranslation(['app-orders-common']);

  const deliveriesData = useMemo(
    () =>
      deliveries?.map((delivery) => {
        const itemIds = delivery.lineItems.map((li) => li.id);
        const relevantItems = lineItems.filter((li) => itemIds.includes(li.id));
        return {
          ...delivery,
          items: relevantItems,
        };
      }),
    [deliveries, lineItems],
  );

  const nonDigitalShipment = deliveriesData?.filter(
    (deliveryItem) => !deliveryItem.serviceLevelIdentifier?.includes('EMAIL'),
  );

  const numProducts = useMemo(
    () => nonDigitalShipment?.reduce((total, curr) => curr.items.length + total, 0),
    [nonDigitalShipment],
  );

  if (!deliveries || !deliveriesData) return null;

  const numShipments = nonDigitalShipment?.length;

  const digitalShipment = deliveriesData?.filter((deliveryItem) =>
    deliveryItem.serviceLevelIdentifier?.includes('EMAIL'),
  );

  const digitalItemsInCart = lineItems?.filter((lineItem) => lineItem.productTypeKey === GiftCardType.EGIFT_CARD);

  return (
    <S.Container>
      {numProducts && !isDigitalCart ? (
        <S.Title>{t('checkout.split.shipping', { count: numProducts, parts: numShipments })}</S.Title>
      ) : null}
      {!!digitalShipment.length && digitalShipment.length > 0 ? (
        <S.Title>{t('checkout.split.giftcard.item', { count: digitalItemsInCart?.length })}</S.Title>
      ) : null}
      <S.GroupWrapper>
        {deliveriesData.map(({ items, deliveryDate, fulfillmentNodeType, serviceLevelIdentifier }, index) => (
          <S.Group key={`${deliveryDate}-${index}`}>
            <S.Items>
              {items.map(({ imageURLs, quantity, id, productTypeKey }) => (
                <MultiGroupDeliveryItem
                  imageURLs={imageURLs}
                  quantity={quantity}
                  key={id}
                  productTypeKey={productTypeKey as string}
                />
              ))}
            </S.Items>
            <S.ItemsDescription>
              {serviceLevelIdentifier?.includes('EMAIL')
                ? t('checkout.split.digital.message')
                : t('checkout.split.item', {
                    curr: index + 1,
                    total: numShipments,
                    count: items.length,
                    location:
                      fulfillmentNodeType === FulfillmentNodeType.STORE
                        ? t('checkout.split.store')
                        : t('checkout.split.warehouse'),
                  })}
            </S.ItemsDescription>
          </S.Group>
        ))}
      </S.GroupWrapper>
    </S.Container>
  );
}
