import type { Cart } from '../../../../codegen/types';
import type { IndexedLineItem } from '../../../../types/product';
import { getAmount } from '../../../currencyHelper';
import { createLineItem } from '../../utils/createLineItem';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

export function sendAddToCartFromQuantityButtonsEvent(addedProductId: string, cart: Cart) {
  if (addedProductId && cart) {
    const { lineItems } = cart;
    const addedCartProduct = lineItems.find((product) => product?.id === addedProductId);
    const value = addedCartProduct?.price.value;

    if (!addedCartProduct) return;

    // update added product quantity to 1, QuantityButtons can add only single product
    const addedProduct: IndexedLineItem = {
      ...addedCartProduct,
      index: 0,
      quantity: 1,
    };

    const cartItems = {
      items: lineItems?.map((item) => createLineItem(item)),
    };

    sendDataLayerEvent(
      EventNamesType.add_to_cart,
      {
        ecommerce: {
          currency: value?.currencyCode,
          value: +getAmount(value?.centAmount),
          items: [createLineItem(addedProduct)],
        },
        cart: {
          currency: cart.currency,
          value: +getAmount(cart.total),
          cartItems,
        },
      },
      true,
      { cartId: cart.id },
    );
  }
}
