import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetDeliveryOptionsForProductsQueryVariables = Types.Exact<{
  input: Types.GetDeliveryOptionsForProductsInput;
}>;

export type GetDeliveryOptionsForProductsQuery = {
  __typename?: 'Query';
  getDeliveryOptionsForProducts: {
    __typename?: 'GetDeliveryOptionsForProductsResponse';
    deliveryOptions: Array<{
      __typename?: 'DeliveryOption';
      currency: string;
      deliveryPriority: number;
      deliveryTime: string;
      description: string;
      isFreeEligible?: boolean | null;
      key: string;
      name: string;
      price: number;
      shippingType?: string | null;
    } | null>;
  };
};

export const GetDeliveryOptionsForProductsDocument = gql`
  query getDeliveryOptionsForProducts($input: GetDeliveryOptionsForProductsInput!) {
    getDeliveryOptionsForProducts(input: $input) {
      deliveryOptions {
        currency
        deliveryPriority
        deliveryTime
        description
        isFreeEligible
        key
        name
        price
        shippingType
      }
    }
  }
`;

/**
 * __useGetDeliveryOptionsForProductsQuery__
 *
 * To run a query within a React component, call `useGetDeliveryOptionsForProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryOptionsForProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryOptionsForProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDeliveryOptionsForProductsQuery(
  baseOptions: Apollo.QueryHookOptions<GetDeliveryOptionsForProductsQuery, GetDeliveryOptionsForProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeliveryOptionsForProductsQuery, GetDeliveryOptionsForProductsQueryVariables>(
    GetDeliveryOptionsForProductsDocument,
    options,
  );
}
export function useGetDeliveryOptionsForProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDeliveryOptionsForProductsQuery,
    GetDeliveryOptionsForProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeliveryOptionsForProductsQuery, GetDeliveryOptionsForProductsQueryVariables>(
    GetDeliveryOptionsForProductsDocument,
    options,
  );
}
export type GetDeliveryOptionsForProductsQueryHookResult = ReturnType<typeof useGetDeliveryOptionsForProductsQuery>;
export type GetDeliveryOptionsForProductsLazyQueryHookResult = ReturnType<
  typeof useGetDeliveryOptionsForProductsLazyQuery
>;
export type GetDeliveryOptionsForProductsQueryResult = Apollo.QueryResult<
  GetDeliveryOptionsForProductsQuery,
  GetDeliveryOptionsForProductsQueryVariables
>;
