import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../stylings';

const S = {
  CTAButton: styled.a`
    background-color: ${colours.WHITE};
    border: solid 1px ${colours.DARK_BLUE};
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    color: ${colours.DARK_BLUE};
    display: block;
    overflow: hidden;
    padding: ${spacing.XXXXS};
    position: absolute;
    text-decoration: none;
    ${TypographyStyles.Button.SemiBold}

    &:focus {
      clip: auto;
      clip-path: unset;
    }
  `,
};

export function SkipMainContentCTA() {
  const { t } = useTranslation(['lib-global-common']);
  return <S.CTAButton href="#main-content">{t('header.skip.to.main.content')}</S.CTAButton>;
}

export default SkipMainContentCTA;
