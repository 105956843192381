import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { HandlebarTemplateProps } from '../globalComponents';
import type { CmsSlotsDeliveryKeys } from '../utils/amplience';

type CmsSlotsList = Record<string, HandlebarTemplateProps | null>;

interface CmsSlotsContextProps {
  cmsSlotsData: CmsSlotsList;
}

export const CmsSlotsContext = createContext<CmsSlotsList>({});

export function CmsSlotsProvider({ children, cmsSlotsData }: PropsWithChildren<CmsSlotsContextProps>) {
  const [contextData, setContextData] = useState<CmsSlotsList>(cmsSlotsData);

  useEffect(() => {
    setContextData(cmsSlotsData);
  }, [cmsSlotsData]);

  return <CmsSlotsContext.Provider value={contextData}>{children}</CmsSlotsContext.Provider>;
}

export const useCmsSlotsContext = () => {
  const components = useContext(CmsSlotsContext);

  const getSlotComponent = (deliveryKey: CmsSlotsDeliveryKeys) => components?.[deliveryKey];

  return {
    getSlotComponent,
  };
};
