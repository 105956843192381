import type { KeyboardEvent, MouseEvent } from 'react';
import type { Maybe, ProductBadgeTextual } from '../../../codegen/types';
import { TextualBadgeContainer } from './TextualBadge.styles';

type TextualBadgeProps = {
  badge?: Maybe<ProductBadgeTextual>;
  pdpBadge?: boolean;
  isVideo?: boolean;
};

export function TextualBadge({ badge, pdpBadge, isVideo }: TextualBadgeProps) {
  if (!badge || isVideo) return null;

  const handleBadgeClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!badge?.url) return;

    e.preventDefault();
    window.open(badge.url, '_blank', 'noopener noreferrer');
  };

  const handleBadgeKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter' || !badge?.url) return;

    window.open(badge.url, '_blank', 'noopener noreferrer');
  };

  return (
    <TextualBadgeContainer
      data-testid={badge?.code}
      $backgroundColor={badge?.backgroundColor}
      $textColor={badge?.textColor}
      $pdpBadge={pdpBadge}
      aria-label={badge?.text || ''}
      role="link"
      tabIndex={0}
      onKeyDown={handleBadgeKeyDown}
      onClick={handleBadgeClick}
    >
      {badge?.text}
    </TextualBadgeContainer>
  );
}
