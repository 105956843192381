import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useLocaleContext } from '../../../../context';
import { useCartContext } from '../../../../context/cartContext';
import { useOverlayContext } from '../../../../context/overlayContext';
import { useStaticContext } from '../../../../context/staticContext';
import { useGetDeliveryOptionsForProductsHook, useStar2 } from '../../../../hooks';
import { useExitAnimation } from '../../../../hooks/useExitAnimation';
import { CheckCircle, Close } from '../../../../icons';
import { addGlobalECookie } from '../../../../utils/globalEUtils';
import { sendViewCartEvent } from '../../../../utils/gtm/events/view_cart/sendViewCartEvent';
import { getItem, removeItem } from '../../../../utils/storage';
import { PaymentMethodsContainer, PaymentOptions } from '../../../paymentMethods/PaymentMethods';
import { PaymentMethodsPlacement } from '../../../paymentMethods/PaymentMethods.utils';
import { CartLoader } from '../../cartLoader';
import { CheckoutButton } from '../../checkoutButton';
import { ContinueShoppingButton } from '../../continueShoppingButton';
import { DeliveryOptions } from '../../deliveryOptions';
import { EmptyBasket } from '../../emptyBasket';
import type { EmptyBasketProps } from '../../emptyBasket';
import { NeedHelp } from '../../needHelp/NeedHelp';
import { NeedHelpModal } from '../../needHelp/NeedHelpModal';
import { ProductLineItemList, ProductLineItemType } from '../../productLineItem';
import { PromoBox } from '../../promoBox';
import { PromotionsMessage } from '../../promotionsMessage';
import { TaxAndTotals } from '../../taxAndTotals';
import { ATBConfirmationMessage } from '../ATBConfirmationMessage';
import {
  BasketMergedConfirmationMessage,
  BasketMergedConfirmationMessageContainer,
  ButtonContainer,
  ButtonWrapper,
  CloseButton,
  ErrorMessage,
  MenuContainer,
  MenuContent,
  MenuOverlay,
  NeedHelpContainer,
  PaymentMethodLabel,
  SectionWrapper,
  SliderWrapper,
  SummaryBarContainer,
  SummaryBarContent,
  Wrapper,
} from './MiniCartSlider.styles';
import type { BelowLineItemsProps, CheckoutOrContinueShoppingProps, MiniCartSliderProps } from './MiniCartSliderProps';

const DynamicCheckoutCtaMenu = dynamic(
  // eslint-disable-next-line promise/prefer-await-to-then
  () => import('../../checkoutCtaMenu/CheckoutCtaMenu').then((mod) => mod.CheckoutCtaMenu),
  {
    ssr: false,
  },
);

function CheckoutOrContinueShopping({
  isCartEmpty,
  currency,
  totalBasket,
  handleCloseMiniCart,
}: CheckoutOrContinueShoppingProps) {
  const { storeData } = useStaticContext();
  const { t } = useTranslation(['lib-global-common']);
  const { isMaxItemsReached, isBasketValueExceeded, isLineItemsEmpty, isSkuCapExceeded } = useCartContext();

  if (isCartEmpty) return null;

  const { productQuantityCap, basketValueCap } = { ...storeData };

  return (
    <ButtonWrapper>
      <ButtonContainer>
        {isMaxItemsReached && (
          <ErrorMessage data-testid="cartItemsCapError" role="alert">
            {t('cart.max.items.reached', { count: productQuantityCap ?? 0 })}
          </ErrorMessage>
        )}
        {isBasketValueExceeded && (
          <ErrorMessage data-testid="cartValueCapError" role="alert">
            {t('cart.max.basket.value', { currency, count: basketValueCap ?? 0 }).replace('. ', '.\n')}
          </ErrorMessage>
        )}
        <CheckoutButton
          total={totalBasket}
          currency={currency}
          isDisabled={isMaxItemsReached || isBasketValueExceeded || isLineItemsEmpty || isSkuCapExceeded}
        />
        <ContinueShoppingButton onClick={handleCloseMiniCart} />
      </ButtonContainer>
    </ButtonWrapper>
  );
}

export function BelowLineItems({
  isCartEmpty,
  placement,
  noBottomMargin = false,
  deliveryOptions,
  isDeliveryOptionsLoading,
  isGlobalE = false,
}: BelowLineItemsProps) {
  const { t } = useTranslation(['lib-global-common']);
  const displayDeliveryOptions = !!deliveryOptions.length;
  if (isCartEmpty) return null;

  return (
    <SectionWrapper $noBottomMargin={noBottomMargin}>
      <PromoBox isStar2={isGlobalE} />
      {!isGlobalE && <TaxAndTotals />}
      <PaymentMethodsContainer placement={placement}>
        <PaymentMethodLabel>{t('cart.payments.accepted')}</PaymentMethodLabel>
        <PaymentOptions placement={placement} />
      </PaymentMethodsContainer>
      {displayDeliveryOptions ? (
        <DeliveryOptions deliveryOptions={deliveryOptions} isDeliveryOptionsLoading={isDeliveryOptionsLoading} />
      ) : null}
    </SectionWrapper>
  );
}

export function MiniCartSlider({ emptyBasketComponent, scrollTop, sliderOffset, isDesktop }: MiniCartSliderProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { isStar2 } = useStar2();
  const {
    miniCart,
    isCartLoading,
    setHasLastItemDeleted,
    checkoutSecurely,
    isCartEmpty,
    basketTotal: totalBasket,
    currency,
    guid,
  } = useCartContext();
  const { setIsMiniCartOpen, isMiniCartOpen } = useOverlayContext();

  const { locale: localeCtx } = useLocaleContext();

  const { deliveryOptions, isDeliveryOptionsLoading, isFreeDeliveryEligible } = useGetDeliveryOptionsForProductsHook();

  const isBasketMerged = getItem('BASKET_MERGED', true, 'sessionStorage');

  const handleCloseMiniCart = () => {
    setIsMiniCartOpen(false);
    setHasLastItemDeleted(false);
    removeItem('BASKET_MERGED', 'sessionStorage');
  };

  const { handleExitAnimation, isClosing } = useExitAnimation({ callbackFunction: handleCloseMiniCart });

  useEffect(() => {
    if (!isCartLoading) {
      sendViewCartEvent(miniCart, localeCtx);
    }
  }, [isCartLoading, miniCart, localeCtx]);

  useEffect(() => {
    if (isStar2 && guid && localeCtx) {
      addGlobalECookie({ locale: localeCtx, guid });
    }
  }, [isStar2, guid, localeCtx]);

  return (
    <>
      {isMiniCartOpen && checkoutSecurely && <DynamicCheckoutCtaMenu sliderOffset={sliderOffset} />}
      <MenuContainer className="menu-container" $sliderOffset={sliderOffset}>
        <MenuOverlay data-testid="minicartOverlay" onClick={handleExitAnimation} />
        <SliderWrapper
          $scrollTop={scrollTop}
          $navBarHeightDesktop={80}
          $navBarHeightMobile={49}
          $mobileHeaderHeight={129}
          $sliderOffset={sliderOffset}
          focusOptions={{ preventScroll: true }}
          className={isClosing ? 'is-closing' : ''}
        >
          {isDesktop && <NeedHelpModal isCartEmpty={isCartEmpty} />}
          <MenuContent>
            {isCartLoading ? (
              <CartLoader />
            ) : (
              <>
                <Wrapper>
                  <SummaryBarContainer>
                    <SummaryBarContent
                      currency={currency}
                      total={totalBasket}
                      className="mini-cart-summary-bar"
                      testId="miniCartSummary"
                    />
                    <CloseButton aria-label={t('cart.close')} id="close" type="button" onClick={handleExitAnimation}>
                      <Close aria-labelledby="close" />
                    </CloseButton>
                  </SummaryBarContainer>
                  <PromotionsMessage isFreeEligible={isFreeDeliveryEligible && !isStar2} />
                  <ATBConfirmationMessage />
                  {isBasketMerged ? (
                    <BasketMergedConfirmationMessageContainer role="status">
                      <CheckCircle />
                      <BasketMergedConfirmationMessage data-testid="cartMergedSuccessMessage">
                        {t('cart.merged.contents')}
                      </BasketMergedConfirmationMessage>
                    </BasketMergedConfirmationMessageContainer>
                  ) : null}
                  {isCartEmpty ? (
                    <EmptyBasket {...(emptyBasketComponent as EmptyBasketProps)} className="mini-cart-empty-basket" />
                  ) : (
                    <ProductLineItemList lineItems={miniCart.lineItems} type={ProductLineItemType.SINGLE_IMAGE} />
                  )}
                </Wrapper>
                <BelowLineItems
                  isCartEmpty={isCartEmpty}
                  placement={PaymentMethodsPlacement.MINI_CART}
                  deliveryOptions={deliveryOptions}
                  isDeliveryOptionsLoading={isDeliveryOptionsLoading}
                  noBottomMargin
                />
                {!isDesktop && (
                  <NeedHelpContainer>
                    <NeedHelp />
                  </NeedHelpContainer>
                )}
              </>
            )}
          </MenuContent>
          <CheckoutOrContinueShopping
            isCartEmpty={isCartEmpty}
            currency={currency}
            totalBasket={totalBasket}
            handleCloseMiniCart={handleExitAnimation}
          />
        </SliderWrapper>
      </MenuContainer>
    </>
  );
}
