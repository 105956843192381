import { Locale } from './localeHelper';

const BvLocaleMap = {
  [Locale.EN_GB]: 'en_GB',
  [Locale.EN_IE]: 'en_GB', // shares the same reviews with en_GB
  [Locale.EN_US]: 'en_US',
  [Locale.EN_CA]: 'en_CA',
  [Locale.FR_CA]: 'fr_CA',
} as const;

export function getBvEnvironment(env?: string) {
  return env === 'prod' ? 'production' : 'staging';
}

export function getBvLocale(locale?: string) {
  if (!locale) return null;

  return BvLocaleMap[locale];
}
