import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import type { RelatedProductsQuery } from '@algolia/recommend';
import type { useRelatedProducts } from '@algolia/recommend-react';
import { getRelatedProducts } from '../services/algolia/getRelatedProducts';

type RelatedProducts = Array<
  ReturnType<typeof useRelatedProducts>['recommendations'][number] & {
    images?: string[];
  }
>;

export function useAlgoliaRelatedItems({ query, isInView }: { isInView: boolean; query: RelatedProductsQuery[] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [relatedItems, setRelatedItems] = useState<RelatedProducts>([]);
  const called = useRef(false);
  const router = useRouter();
  const queryStr = JSON.stringify(query);

  useEffect(() => {
    const defaultCalled = () => {
      called.current = false;
    };
    router.events.on('routeChangeStart', defaultCalled);

    router.events.on('routeChangeComplete', defaultCalled);

    return () => {
      router.events.off('routeChangeStart', defaultCalled);
      router.events.off('routeChangeComplete', defaultCalled);
    };
  }, [router.events]);

  useEffect(() => {
    if (isInView || !called.current) {
      (async () => {
        try {
          setIsLoading(true);
          const relatedProducts = await getRelatedProducts({ query: JSON.parse(queryStr) });
          setRelatedItems(relatedProducts.results.flatMap((result) => result.hits));
        } catch (e) {
          console.error(e);
        } finally {
          called.current = true;
          setIsLoading(false);
        }
      })();
    }
  }, [isInView, queryStr]);

  return { relatedItems, isLoading };
}
