import { forwardRef } from 'react';
import { Item } from '@radix-ui/react-accordion';
import type { AccordionItemProps } from '@radix-ui/react-accordion';

export const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(({ children, ...props }, forwardedRef) => (
  <Item {...props} ref={forwardedRef}>
    {children}
  </Item>
));

export default AccordionItem;
