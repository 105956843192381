import { ShippingMethod } from '@commercetools/platform-sdk';

export const isPudo = (name: string) => name.toLowerCase().includes('pudo');

export const isCFS = (name: string) => name.toLowerCase().includes('collect_from_store');

export const isInStorePickUp = (name: string) => name.toLowerCase().includes('in_store_pick_up');

export const isEmail = (name: string) => name.toLowerCase().includes('email');

export function isHome(name: string) {
  const lowerName = name.toLowerCase();
  return [isPudo, isCFS, isInStorePickUp, isEmail].every((fn) => !fn(lowerName));
}

export const filteredOutNonWarehouseShippingMethods =
  /^(EXPRESS_|NEXTDAY_|FEDEX_NEXTDAY_|NEXTDAY_|FEDEX_2DAY_AIR_|2DAY_AIR_)(.*)/;

export function filterOutNonWarehouseShippingMethods(shippingMethods: ShippingMethod[]) {
  return shippingMethods.filter(
    (shippingMethod) => !filteredOutNonWarehouseShippingMethods.test(shippingMethod.key as string),
  );
}

export const filteredOutPhysicalGiftCardShippingMethods: Record<string, RegExp> = {
  uk: /^(NEXTDAY_)(.*)/,
  roi: /^(NEXTDAY_|EXPRESS_)(.*)/,
};

export function filterOutPhysicalGiftCardShippingMethods(shippingMethods: ShippingMethod[], storeKey: string) {
  const storeRegex = filteredOutPhysicalGiftCardShippingMethods[storeKey];
  if (!storeRegex) return shippingMethods;
  return shippingMethods.filter((shippingMethod) => !storeRegex.test(shippingMethod.key as string));
}
