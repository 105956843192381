import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { MainLinkColumn as MainLinkColumnSchema } from '@amplience/content-types/typings/c-footer-main-links-column';
import { Clarks } from '../../icons';
import { ImagePartial } from '../../partials/image';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { anchorTagHoverAnimation } from '../../stylings/anchorTag';
import { getLocalizedValue } from '../../utils/transformers';

const S = {
  Container: styled.div`
    padding: ${spacing.L} 0 ${spacing.M} 0;

    svg {
      display: inline-block;
      height: 32.32px;
      width: 120px;
    }
  `,

  Links: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 30px 0 0;
    padding: 0;

    @media ${media.greaterThan('lg')} {
      gap: ${spacing.S};
      margin-top: ${spacing.L};
    }
  `,

  Logo: styled.div`
    display: flex;
    width: 95px;

    img {
      height: 100%;
      width: 100%;
    }

    & a {
      display: flex;
      height: 100%;
      width: 100%;
    }

    @media ${media.greaterThan('lg')} {
      width: 120px;
    }
  `,

  Link: styled.li`
    /* stylelint-disable selector-type-no-unknown */
    ${() =>
      anchorTagHoverAnimation({
        backgroundColor: colours.BLACK,
      })}

    a {
      ${TypographyStyles.Headings.H5}
      color: ${colours.BLACK};
      text-decoration: none;
    }
  `,
};

export type MainLinksColumnProps = MainLinkColumnSchema;

export function MainLinksColumn({ mainLinksColumn, footerLogo }: MainLinksColumnProps) {
  const { t } = useTranslation('lib-global-common');

  return (
    <S.Container>
      <S.Logo>
        <Link passHref href="/">
          <a id="footer-clarks-logo" aria-label={t('header.link.home')}>
            {footerLogo?.image ? (
              <ImagePartial
                altText={footerLogo?.altText}
                src={footerLogo?.image?.name ?? ''}
                assetType={footerLogo?.assetType}
                useOriginalSize
                defaultHost={footerLogo?.image?.defaultHost}
              />
            ) : (
              <Clarks />
            )}
          </a>
        </Link>
      </S.Logo>
      <S.Links>
        {mainLinksColumn &&
          mainLinksColumn?.map((link, index) => (
            <S.Link key={`${link.text}-${index}`}>
              <LinkPartial {...link.link}>{getLocalizedValue(link.text)}</LinkPartial>
            </S.Link>
          ))}
      </S.Links>
    </S.Container>
  );
}
