import Script from 'next/script';
import { useAfterCookieBannerContext } from '../../context/afterCookieBannerContext';
import { useLocaleContext } from '../../context/localeContext';
import { getBvEnvironment, getBvLocale } from '../../utils/bvHelper';
import { checkConsent } from '../../utils/checkConsent';

export function BazaarvoiceScript() {
  const { locale } = useLocaleContext();
  const bvLocale = getBvLocale(locale);

  const { promptRedirectAfterCookie } = useAfterCookieBannerContext();

  const handleOnLoad = () => {
    const hasConsent = checkConsent();

    if (window.BV?.cookieConsent?.setConsent) {
      window.BV.cookieConsent.setConsent({
        bv_metrics: hasConsent,
        BVBRANDID: hasConsent,
        BVBRANDSID: hasConsent,
      });
    }
  };

  if (!bvLocale || !promptRedirectAfterCookie) return null;

  return (
    <Script
      async
      src={`https://apps.bazaarvoice.com/deployments/clarks-eu/main_site_v2/${getBvEnvironment(
        process.env.NEXT_PUBLIC_ENV_NAME,
      )}/${bvLocale}/bv.js`}
      onLoad={handleOnLoad}
    />
  );
}

export default BazaarvoiceScript;
