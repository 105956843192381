import type { Dispatch, MutableRefObject, ReactNode, SetStateAction } from 'react';
import { createContext, createRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import type RedirectElement from '@adyen/adyen-web/dist/types/components/Redirect';
import type Core from '@adyen/adyen-web/dist/types/core';
import type { Order } from '@adyen/adyen-web/dist/types/types';
import type { ApolloError } from '@apollo/client';
import { currencyMapper } from '@shared/utils/src/ct';
import type { CreatePaymentMutation } from '../graphqlDocument/payments/createPayment.generated';
import { useCreatePaymentMutation } from '../graphqlDocument/payments/createPayment.generated';
import { useAdyenConfig } from '../hooks/useAdyenConfig';
import { useNRBASession } from '../hooks/useNRBASession';
import type { CancelOrderResponse } from '../types';
import { type CheckedStatus, type ValidityStatus, paymentMethods } from '../types';
import { getCtLocale } from '../utils/localeHelper';
import { useCartContext } from './cartContext';
import { useLocaleContext } from './localeContext';

export type PaymentContextType = {
  createPaymentSession: () => void;
  isCreatePaymentLoading: boolean;
  createPaymentError: ApolloError | null | undefined;
  paymentData: CreatePaymentMutation['createPayment'] | null | undefined;
  adyenComponent: RedirectElement | null;
  setAdyenComponent: Dispatch<SetStateAction<RedirectElement | null>>;
  isPaymentBtnDisabled: boolean;
  setIsPaymentBtnDisabled: Dispatch<SetStateAction<boolean>>;
  customerPaymentMethod: string | null;
  setCustomerPaymentMethod: Dispatch<SetStateAction<string | null>>;
  customerPaymentType: string | null;
  setCustomerPaymentType: Dispatch<SetStateAction<string | null>>;
  setPaymentData: Dispatch<CreatePaymentMutation['createPayment'] | null>;
  paymentError: boolean;
  setPaymentError: Dispatch<SetStateAction<boolean>>;
  availableAdyenComponents: AvailableAdyenComponent | null;
  setAvailableAdyenComponents: Dispatch<SetStateAction<AvailableAdyenComponent | null>>;
  validityStatus: ValidityStatus;
  setValidityStatus: Dispatch<SetStateAction<ValidityStatus>>;
  checkedStatus: CheckedStatus | null;
  setCheckedStatus: Dispatch<SetStateAction<CheckedStatus | null>>;
  adyenCheckout: Core | null;
  setAdyenCheckout: Dispatch<SetStateAction<Core | null>>;
  giftCardOrder: Order | null;
  setGiftCardOrder: Dispatch<SetStateAction<Order | null>>;
  canPayWithGiftCards: boolean;
  setCanPayWithGiftCards: Dispatch<SetStateAction<boolean>>;
  setKlarnaSelectedPayment: Dispatch<SetStateAction<string>>;
  klarnaSelectedPayment: string;
  paymentContainer: MutableRefObject<null | HTMLDivElement>;
  onErrorGiftCard: boolean;
  setOnErrorGiftCard: Dispatch<SetStateAction<boolean>>;
  isCSA: boolean;
  isErrorGiftCardInput: boolean;
  setIsErrorGiftCardInput: Dispatch<SetStateAction<boolean>>;
  giftCardStatus: boolean;
  setGiftCardStatus: Dispatch<SetStateAction<boolean>>;
  isGiftCardRemoved: boolean;
  setIsGiftCardRemoved: Dispatch<SetStateAction<boolean>>;
  giftCardTotal: number;
  setGiftCardTotal: Dispatch<SetStateAction<number>>;
  giftCardValue: number;
  setGiftCardValue: Dispatch<SetStateAction<number>>;
  isGiftCardEnough: boolean;
  setIsGiftCardEnough: Dispatch<SetStateAction<boolean>>;
  isAddAnotherGiftCard: boolean;
  setIsAddAnotherGiftCard: Dispatch<SetStateAction<boolean>>;
  remainingGiftCardBalance: number;
  setRemainingGiftCardBalance: Dispatch<SetStateAction<number>>;
  paymentInProgress: boolean;
  setPaymentInProgress: Dispatch<SetStateAction<boolean>>;
  setKountSessionId: Dispatch<SetStateAction<string>>;
  kountSessionId: string;
  isIframeError: boolean;
  setIsIframeError: Dispatch<SetStateAction<boolean>>;
};

const PaymentContext = createContext<PaymentContextType>({
  createPaymentSession: () => {},
  isCreatePaymentLoading: true,
  createPaymentError: null,
  paymentData: null,
  adyenComponent: null,
  setAdyenComponent: () => {},
  isPaymentBtnDisabled: false,
  setIsPaymentBtnDisabled: () => {},
  customerPaymentMethod: null,
  setCustomerPaymentMethod: () => {},
  customerPaymentType: null,
  setCustomerPaymentType: () => {},
  setPaymentData: () => {},
  paymentError: false,
  setPaymentError: () => {},
  availableAdyenComponents: null,
  setAvailableAdyenComponents: () => {},
  validityStatus: {
    encryptedCardNumber: { isValid: true },
    encryptedExpiryDate: { isValid: true },
    encryptedSecurityCode: { isValid: true },
    holderName: null,
  },
  setValidityStatus: () => {},
  checkedStatus: null,
  setCheckedStatus: () => {},
  adyenCheckout: null,
  setAdyenCheckout: () => {},
  giftCardOrder: null,
  setGiftCardOrder: () => {},
  canPayWithGiftCards: false,
  setCanPayWithGiftCards: () => {},
  setKlarnaSelectedPayment: () => {},
  klarnaSelectedPayment: '',
  paymentContainer: createRef(),
  isCSA: false,
  onErrorGiftCard: false,
  setOnErrorGiftCard: () => {},
  isErrorGiftCardInput: false,
  setIsErrorGiftCardInput: () => {},
  giftCardStatus: false,
  setGiftCardStatus: () => {},
  isGiftCardRemoved: false,
  setIsGiftCardRemoved: () => {},
  giftCardTotal: 0,
  setGiftCardTotal: () => {},
  giftCardValue: 0,
  setGiftCardValue: () => {},
  isGiftCardEnough: false,
  setIsGiftCardEnough: () => {},
  isAddAnotherGiftCard: false,
  setIsAddAnotherGiftCard: () => {},
  remainingGiftCardBalance: 0,
  setRemainingGiftCardBalance: () => {},
  paymentInProgress: false,
  setPaymentInProgress: () => {},
  setKountSessionId: () => {},
  kountSessionId: '',
  isIframeError: false,
  setIsIframeError: () => {},
});

type AvailableAdyenComponent = {
  [method: string]: boolean;
};

export const usePaymentContext = () => useContext(PaymentContext);

export function PaymentContextProvider({ isCSA, children }: { isCSA: boolean; children: ReactNode }) {
  const router = useRouter();
  const { locale } = useLocaleContext();
  const { cartId, basketTotalCent, miniCart } = useCartContext();

  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState<string | null>('scheme');
  const [customerPaymentType, setCustomerPaymentType] = useState<string | null>('Credit Card');
  const [isPaymentBtnDisabled, setIsPaymentBtnDisabled] = useState<boolean>(true);
  const [adyenComponent, setAdyenComponent] = useState<PaymentContextType['adyenComponent']>(null);
  const [paymentData, setPaymentData] = useState<CreatePaymentMutation['createPayment'] | null>(null);
  const [availableAdyenComponents, setAvailableAdyenComponents] = useState<AvailableAdyenComponent | null>(null);
  const [validityStatus, setValidityStatus] = useState<ValidityStatus>({
    encryptedCardNumber: { isValid: true },
    encryptedExpiryDate: { isValid: true },
    encryptedSecurityCode: { isValid: true },
    holderName: null,
  });
  const [checkedStatus, setCheckedStatus] = useState<CheckedStatus | null>(null);
  const nrbaContextHeader = useNRBASession();
  const [createPayment, { loading: isCreatePaymentLoading, error: createPaymentError }] =
    useCreatePaymentMutation(nrbaContextHeader);
  const [paymentError, setPaymentError] = useState(false);
  const [isIframeError, setIsIframeError] = useState(false);
  const [adyenCheckout, setAdyenCheckout] = useState<Core | null>(null);
  const [klarnaSelectedPayment, setKlarnaSelectedPayment] = useState('');
  const paymentContainer = useRef(null);

  // Gift card states
  const [giftCardOrder, setGiftCardOrder] = useState<Order | null>(null);
  const [canPayWithGiftCards, setCanPayWithGiftCards] = useState<boolean>(false);
  const [onErrorGiftCard, setOnErrorGiftCard] = useState<boolean>(false);
  const [isErrorGiftCardInput, setIsErrorGiftCardInput] = useState<boolean>(false);
  const [giftCardStatus, setGiftCardStatus] = useState(false);
  const [isGiftCardRemoved, setIsGiftCardRemoved] = useState(false);
  const [giftCardTotal, setGiftCardTotal] = useState(0);
  const [giftCardValue, setGiftCardValue] = useState(0);
  const [isGiftCardEnough, setIsGiftCardEnough] = useState(false);
  const [isAddAnotherGiftCard, setIsAddAnotherGiftCard] = useState(false);
  const [remainingGiftCardBalance, setRemainingGiftCardBalance] = useState(0);
  const [kountSessionId, setKountSessionId] = useState('');

  const createPaymentSession = useCallback(async () => {
    await createPayment({
      variables: {
        input: {
          cartId,
          locale: getCtLocale(locale),
          centAmount: basketTotalCent,
          currencyCode: currencyMapper[locale],
        },
      },
      onCompleted: async ({ createPayment: createPaymentData }) => {
        setPaymentData(createPaymentData);

        if (giftCardOrder) {
          try {
            const cancelResult = (await adyenCheckout?.session.cancelOrder({
              order: giftCardOrder,
            })) as CancelOrderResponse;

            // eslint-disable-next-line no-console
            console.info('Cancelled previous checkout session', cancelResult);

            if (cancelResult?.resultCode === 'Received') {
              setGiftCardOrder(null);
            }
          } catch (e) {
            console.error('There was a problem cancelling your previous gift card payment', e);
          }
        }

        // Reset all gift card states
        setIsGiftCardEnough(false);
        setIsAddAnotherGiftCard(false);
        setIsGiftCardRemoved(false);
        setGiftCardTotal(0);
        setGiftCardValue(0);
        setGiftCardStatus(false);
        setIsErrorGiftCardInput(false);
        setOnErrorGiftCard(false);
        setCanPayWithGiftCards(false);
        setGiftCardOrder(null);
      },
      onError: (e) => {
        // TODO: update error handling
        console.error('There was an error creating payment session', e);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketTotalCent, cartId, createPayment, locale]);

  useEffect(() => {
    if (miniCart?.deliveryMethod?.key && router.asPath === '/checkout/payment') {
      createPaymentSession();
    }
  }, [createPaymentSession, miniCart?.deliveryMethod?.key, router.asPath]);

  useEffect(() => {
    if (customerPaymentMethod === paymentMethods.giftcard) {
      setIsPaymentBtnDisabled(!canPayWithGiftCards);
    }
  }, [canPayWithGiftCards, customerPaymentMethod]);

  const value = useMemo(
    () => ({
      createPaymentSession,
      isCreatePaymentLoading,
      createPaymentError,
      paymentData,
      adyenComponent,
      setAdyenComponent,
      setIsPaymentBtnDisabled,
      isPaymentBtnDisabled,
      customerPaymentMethod,
      setCustomerPaymentMethod,
      customerPaymentType,
      setCustomerPaymentType,
      setPaymentData,
      paymentError,
      setPaymentError,
      availableAdyenComponents,
      setAvailableAdyenComponents,
      validityStatus,
      setValidityStatus,
      checkedStatus,
      setCheckedStatus,
      setAdyenCheckout,
      adyenCheckout,
      giftCardOrder,
      setGiftCardOrder,
      canPayWithGiftCards,
      setCanPayWithGiftCards,
      setKlarnaSelectedPayment,
      klarnaSelectedPayment,
      paymentContainer,
      isCSA,
      onErrorGiftCard,
      setOnErrorGiftCard,
      isErrorGiftCardInput,
      setIsErrorGiftCardInput,
      giftCardStatus,
      setGiftCardStatus,
      isGiftCardRemoved,
      setIsGiftCardRemoved,
      giftCardTotal,
      setGiftCardTotal,
      giftCardValue,
      setGiftCardValue,
      isGiftCardEnough,
      setIsGiftCardEnough,
      isAddAnotherGiftCard,
      setIsAddAnotherGiftCard,
      remainingGiftCardBalance,
      setRemainingGiftCardBalance,
      paymentInProgress,
      setPaymentInProgress,
      setKountSessionId,
      kountSessionId,
      isIframeError,
      setIsIframeError,
    }),
    [
      createPaymentSession,
      isCreatePaymentLoading,
      createPaymentError,
      paymentData,
      adyenComponent,
      isPaymentBtnDisabled,
      customerPaymentMethod,
      customerPaymentType,
      paymentError,
      availableAdyenComponents,
      validityStatus,
      checkedStatus,
      adyenCheckout,
      giftCardOrder,
      canPayWithGiftCards,
      klarnaSelectedPayment,
      isCSA,
      onErrorGiftCard,
      isErrorGiftCardInput,
      giftCardStatus,
      isGiftCardRemoved,
      giftCardTotal,
      giftCardValue,
      isGiftCardEnough,
      isAddAnotherGiftCard,
      remainingGiftCardBalance,
      paymentInProgress,
      kountSessionId,
      isIframeError,
    ],
  );

  useAdyenConfig(value);

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
}
