import { useTranslation } from 'next-i18next';
import { Loader } from '../Loader/Loader';
import { DynamicMessage } from './DynamicMessage';

export function LoadingMessage() {
  const { t } = useTranslation(['lib-global-common']);

  return (
    <DynamicMessage
      testId="welcomeMessage"
      heading={t('register.loading.confirmation')}
      subText={t('register.loading.warning.info')}
    >
      <Loader isDark />
    </DynamicMessage>
  );
}
export default LoadingMessage;
