import type { ReactNode } from 'react';
import styled from 'styled-components';
import { media, spacing } from '../../stylings';

const S = {
  Container: styled.div`
    height: 100%;
    margin: 0 auto;
    max-width: 1440px;
  `,
  SectionContainer: styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 60px ${spacing.S};
    @media ${media.greaterThan('lg')} {
      margin: 80px 0;
    }
  `,
};

export function SectionContainer({ children }: { children: ReactNode }) {
  return (
    <S.Container>
      <S.SectionContainer>{children}</S.SectionContainer>
    </S.Container>
  );
}

export default SectionContainer;
