import * as yup from 'yup';
import { FIRST_NAME_REGEXP, LAST_NAME_REGEXP } from '../b2c';

export const PHONE_FIELD_REGEXP = /^\+?\d+$/;

export const appointmentDetailsMaxLength = {
  firstName: 256,
  lastName: 256,
  email: 114,
  phoneNumber: 20,
  notes: 1000,
};

export const AppointmentDetailsSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('appointment.validation.first.name.required')
    .matches(FIRST_NAME_REGEXP, 'appointment.validation.first.name'),
  lastName: yup
    .string()
    .required('appointment.validation.last.name.required')
    .matches(LAST_NAME_REGEXP, 'appointment.validation.last.name'),
  email: yup
    .string()
    .trim()
    .required('appointment.validation.email.address.required')
    .email('appointment.validation.email.address')
    .max(appointmentDetailsMaxLength.email, 'appointment.validation.email.address'),
  phoneNumber: yup
    .string()
    .trim()
    .required('appointment.validation.phone.number.required')
    .matches(PHONE_FIELD_REGEXP, 'appointment.validation.phone.number')
    .min(6, 'appointment.validation.phone.number')
    .max(appointmentDetailsMaxLength.phoneNumber, 'appointment.validation.phone.number'),
  notes: yup.string().trim().max(appointmentDetailsMaxLength.notes),
  stayInTouch: yup.boolean(),
});
