import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { colours } from '../../stylings';

const S = {
  ErrorMessage: styled.pre`
    color: ${colours.RED};
  `,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ErrorFallback({ error }: any) {
  const { t } = useTranslation(['lib-global-common']);

  return (
    <div role="alert">
      <p>{t('error.fallback.text')}</p>
      <S.ErrorMessage>{error.message}</S.ErrorMessage>
    </div>
  );
}
