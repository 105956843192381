import type { InputHTMLAttributes, RefObject, SyntheticEvent, TextareaHTMLAttributes } from 'react';
import { forwardRef, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { Control, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import type { Country, Value as E164Number } from 'react-phone-number-input';
import { usePopper } from 'react-popper';
import { useOutsideClick } from 'rooks';
import { useInputAnimation } from '../../hooks/useInputAnimation';
import { CancelCircle, CheckCircle, InfoSmall, Visible, VisibleOff } from '../../icons';
import {
  Container,
  FieldContainer,
  FieldInput,
  FieldLabel,
  IconWrapper,
  PhoneNumberInputContainer,
  ShowPasswordButton,
  TextArea,
  TextAreaCharacterCount,
  TooltipArrow,
  TooltipBodyText,
  TooltipBubble,
  TooltipIcon,
} from './Input.styles';

export type FieldInputProps = {
  $isInputError?: boolean;
  $disabled?: boolean;
  $colour?: string;
  $isEmpty?: boolean;
  $isGiftCardBalance?: boolean;
};

export interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  newPassword: string;
  rememberMe: boolean;
  repeatPassword: string;
}

type InputFieldProps<Attr = InputHTMLAttributes<HTMLInputElement>> = Partial<Attr> & {
  isInputError?: boolean;
  isEmpty?: boolean;
  register?: UseFormRegisterReturn;
  testId?: string;
  colour?: string;
  label?: string;
  characterLimit?: number;
  message?: string;
  isGiftCardBalance?: boolean;
  control?: Control<FieldValues, unknown>;
  selectedCountry?: string | null;
};

export const renderInputIcon = (error: boolean, isEmpty: boolean, isGiftCardBalance?: boolean) => {
  if (error) {
    return (
      <IconWrapper $isGiftCardBalance={isGiftCardBalance}>
        <CancelCircle aria-hidden />
      </IconWrapper>
    );
  }
  return (
    <IconWrapper $isGiftCardBalance={isGiftCardBalance} className={isEmpty ? 'grayed-out' : ''}>
      <CheckCircle aria-hidden />
    </IconWrapper>
  );
};

type PasswordFieldProps = InputFieldProps & {
  disableShowPassword?: boolean;
};

export const PasswordField = forwardRef(
  (
    {
      testId,
      isInputError,
      isEmpty,
      register,
      disabled,
      placeholder,
      disableShowPassword = false,
      ...props
    }: PasswordFieldProps,
    ref,
  ) => {
    const { t } = useTranslation('lib-global-common');

    const { className, handleBlur, handleFocus } = useInputAnimation(isEmpty);
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => setShowPassword((prev) => !prev);

    const handleOnBlur = (e: SyntheticEvent) => {
      register?.onBlur(e);
      handleBlur();
    };
    return (
      <FieldContainer className={isInputError ? 'error' : ''}>
        <FieldLabel $isEmpty={isEmpty} className={`${className} ${isInputError ? 'error-label' : ''}`}>
          {placeholder}
        </FieldLabel>
        <FieldInput
          ref={ref as RefObject<HTMLInputElement>}
          {...props}
          {...register}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder?.replace('*', '')}
          data-testid={testId}
          disabled={disabled}
          $isEmpty={isEmpty}
          $isInputError={isInputError}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
        />
        <IconWrapper className={isEmpty ? 'grayed-out' : ''}>
          <ShowPasswordButton
            id="show-password"
            type="button"
            aria-label={showPassword ? t('login.hide.password') : t('login.show.password')}
            onClick={togglePassword}
            data-testid="btnShowPassword"
            disabled={disableShowPassword}
          >
            {showPassword ? <VisibleOff aria-hidden /> : <Visible aria-hidden />}
          </ShowPasswordButton>
        </IconWrapper>
      </FieldContainer>
    );
  },
);

export const EmailField = forwardRef(
  (
    { testId, isInputError, isEmpty, register, placeholder, disabled, colour, label, ...props }: InputFieldProps,
    ref,
  ) => {
    const { className, handleBlur, handleFocus } = useInputAnimation(isEmpty);

    return (
      <FieldContainer className={isInputError ? 'error' : ''}>
        <FieldLabel
          $disabled={disabled}
          $colour={colour}
          $isEmpty={isEmpty}
          className={`${className} ${isInputError ? 'error-label' : ''}`}
        >
          {label}
        </FieldLabel>
        <FieldInput
          ref={ref as RefObject<HTMLInputElement>}
          {...props}
          {...register}
          placeholder={placeholder?.replace('*', '')}
          data-testid={testId}
          disabled={disabled}
          $isEmpty={isEmpty}
          $isInputError={isInputError}
          $disabled={disabled}
          $colour={colour}
          autoComplete="email"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {!disabled ? renderInputIcon(Boolean(isInputError), !!isEmpty) : null}
      </FieldContainer>
    );
  },
);

export const InputField = forwardRef(
  ({ testId, isInputError, isEmpty, register, placeholder, colour, disabled, ...props }: InputFieldProps, ref) => {
    const { className, handleBlur, handleFocus } = useInputAnimation(isEmpty);

    return (
      <FieldContainer className={isInputError ? 'error' : ''}>
        <FieldLabel
          $colour={colour}
          $isEmpty={isEmpty}
          className={`${className} ${isInputError ? 'error-label' : ''}`}
          $disabled={disabled}
        >
          {placeholder}
        </FieldLabel>
        <FieldInput
          ref={ref as RefObject<HTMLInputElement>}
          {...props}
          {...register}
          placeholder={placeholder?.replace('*', '')}
          data-testid={testId}
          $isInputError={isInputError}
          $isEmpty={isEmpty}
          $colour={colour}
          $disabled={disabled}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {renderInputIcon(Boolean(isInputError), !!isEmpty)}
      </FieldContainer>
    );
  },
);

export const TextAreaField = forwardRef(
  (
    {
      testId,
      isInputError,
      register,
      placeholder,
      ...props
    }: Omit<InputFieldProps<TextareaHTMLAttributes<HTMLTextAreaElement>>, 'label'>,
    ref,
  ) => (
    <FieldContainer className={isInputError ? 'error' : ''}>
      <TextArea
        autoComplete="off"
        {...props}
        ref={ref as RefObject<HTMLTextAreaElement>}
        placeholder={placeholder?.replace('*', '')}
        data-testid={testId}
        {...register}
      />
      <FieldLabel className={isInputError ? 'error-label' : ''}>{placeholder}</FieldLabel>
    </FieldContainer>
  ),
);

export function TextAreaWithCharacterCount({
  testId,
  isInputError,
  placeholder,
  characterLimit,
  register,
  message,
  isEmpty,
  ...props
}: Omit<InputFieldProps<TextareaHTMLAttributes<HTMLTextAreaElement>>, 'label'>) {
  const characterCount = characterLimit && message ? characterLimit - message.length : characterLimit;
  const { className, handleBlur, handleFocus } = useInputAnimation(isEmpty);

  return (
    <FieldContainer className={isInputError ? 'error' : ''}>
      <FieldLabel $isEmpty={isEmpty} className={className}>
        {placeholder}
      </FieldLabel>
      <TextArea
        autoComplete="off"
        {...props}
        placeholder={placeholder?.replace('*', '')}
        data-testid={testId}
        rows={4}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...register}
        maxLength={characterLimit}
      />
      <TextAreaCharacterCount>{characterCount}</TextAreaCharacterCount>
    </FieldContainer>
  );
}

type InputWithToolTipProps = InputFieldProps & {
  message: string;
};
export const InputWithToolTip = forwardRef(
  (
    { testId, isInputError, isEmpty, register, disabled, placeholder, message, ...props }: InputWithToolTipProps,
    ref,
  ) => {
    const { className, handleBlur, handleFocus } = useInputAnimation(isEmpty);

    return (
      <FieldContainer className={isInputError ? 'error' : ''}>
        <FieldLabel
          $disabled={disabled}
          $isEmpty={isEmpty}
          className={`${className} ${isInputError ? 'error-label' : ''}`}
        >
          {placeholder}
        </FieldLabel>
        <FieldInput
          ref={ref as RefObject<HTMLInputElement>}
          {...props}
          {...register}
          type="text"
          placeholder={placeholder?.replace('*', '')}
          autoComplete="off"
          data-testid={testId}
          disabled={disabled}
          $isEmpty={isEmpty}
          $isInputError={isInputError}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <ToolTip message={message} />
      </FieldContainer>
    );
  },
);

function ToolTip({ message }: { message?: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const giftCardTooltipRef = useRef<HTMLButtonElement>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(giftCardTooltipRef.current, containerRef.current, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 25],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: { left: 5, right: 15 },
        },
      },
    ],
  });

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  useOutsideClick(containerRef, () => setIsOpen(false));

  return (
    <Container role="tooltip">
      <TooltipIcon onClick={handleClick} ref={giftCardTooltipRef} aria-label={message} type="button">
        <InfoSmall />
      </TooltipIcon>
      {isOpen && (
        <TooltipBubble ref={containerRef} style={styles.popper} {...attributes.popper}>
          <TooltipArrow ref={setArrowRef} style={styles.arrow} className="arrow" />
          <TooltipBodyText>{message}</TooltipBodyText>
        </TooltipBubble>
      )}
    </Container>
  );
}

export type PhoneNumberInputProps = FieldInputProps & InputFieldProps;

export function PhoneNumberInput({
  testId,
  isInputError,
  isEmpty,
  placeholder,
  colour,
  disabled,
  control,
  value,
  selectedCountry,
  ...props
}: InputFieldProps) {
  const { className, handleBlur, handleFocus } = useInputAnimation(isEmpty);

  return (
    <FieldContainer className={isInputError ? 'error' : ''}>
      <FieldLabel
        $colour={colour}
        $isEmpty={isEmpty}
        className={`${className} ${isInputError ? 'error-label' : ''}`}
        $disabled={disabled}
      >
        {placeholder}
      </FieldLabel>
      <PhoneNumberInputContainer
        {...props}
        name="phone"
        control={control}
        placeholder={placeholder?.replace('*', '')}
        data-testid={testId}
        $isInputError={isInputError}
        $isEmpty={isEmpty}
        $colour={colour}
        $disabled={disabled}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        defaultValue={value as E164Number}
        country={selectedCountry as Country}
        international
      />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {renderInputIcon(Boolean(isInputError), !!isEmpty)}
    </FieldContainer>
  );
}
