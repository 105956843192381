import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useLocaleContext, useStaticContext } from '../../../context';
import { useMediaMatch } from '../../../hooks';
import { ImagePartial } from '../../../partials';
import { sendClickAfterSearchEvent } from '../../../services';
import { media } from '../../../stylings';
import { getItem, getLocalizedValue, toSnakeCase } from '../../../utils';
import { sendSelectItemEvent } from '../../../utils/gtm/events/select_item/sendSelectItemEvent';
import { sendViewProductImageEvent } from '../../../utils/gtm/events/view_product_image/sendViewProductImageEvent';
import { ImageBadge, TextualBadge } from '../../badges';
import { Skeleton } from '../../skeleton';
import { ProductItemPrice } from '../productItemPrice/ProductItemPrice';
import type { CarouselLayoutItemProps } from './AlgoliaCarouselItem';
import {
  CarouselImagePartial,
  ImageContainer,
  PriceContainer,
  ProductColour,
  ProductColourSelector,
  ProductColourSelectorSection,
  ProductDetails,
  ProductItemContainer,
  ProductItemInfo,
  ProductItemMainInfo,
  ProductLink,
  ProductMoreColors,
  ProductMultiImageContainer,
  ProductName,
  ProductShopNowLink,
  ProductThumbnail,
  ProductThumbnailSelector,
  SelectedThumbnailBorder,
} from './AlgoliaCarouselItem.styles';

const MAX_IMAGES = 4;

function AlgoliaCarouselMultiImage({
  isLoading,
  algoliaIndexName,
  position,
  primaryCategory,
  brand,
  categoryName,
  imageUrls,
  productName,
  prices,
  slug,
  multiImageButtonText,
  colors,
  productKey,
  productColor,
  carouselTitle,
  isPDP,
  badgesV2,
}: Omit<CarouselLayoutItemProps, 'productId'>) {
  const { asPath } = useRouter();
  const { host } = useStaticContext();
  const { locale } = useLocaleContext();
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { t } = useTranslation(['lib-global-common']);
  const [focusedProductImage, setFocusedProductImage] = useState<string>(imageUrls[0]);
  const [selectedColorLink, setSelectedColorLink] = useState<number | undefined>();

  const componentType = !isPDP
    ? `${asPath === '/' ? 'Home' : `${asPath.toUpperCase()}`.replace(/\//g, '')} Page`
    : 'PDP';

  const images = imageUrls.slice(0, MAX_IMAGES);
  const selectProductImage = (image: string) => () => {
    setFocusedProductImage(image);
  };

  const onImageClick = (typeName: string, linkUrl: string, index: number) => () => {
    setSelectedColorLink(index);
    sendViewProductImageEvent({
      component_type: `${componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };

  const handleOnClick = (typeName: string, linkUrl: string, index: number) => () => {
    sendClickAfterSearchEvent(
      'Algolia Carousel: Product Clicked',
      algoliaIndexName,
      getItem('queryID') as string,
      productKey,
      position,
    );
    sendSelectItemEvent(
      {
        itemListName: carouselTitle || undefined,
        itemListId: toSnakeCase(carouselTitle as string) || undefined,
        primaryCategoryKey: primaryCategory?.key ?? undefined,
        primaryCategoryName: primaryCategory?.name ?? undefined,
        key: productKey,
        name: productName ?? '',
        brand,
        color: productColor ?? '',
      },
      prices,
    );
    onImageClick(typeName, linkUrl, index)();
  };

  return (
    <ProductItemContainer>
      <ProductMultiImageContainer>
        <Link href={`/${slug}/${productKey}-p`} passHref>
          <ProductLink
            tag="a"
            isLoading={isLoading}
            forceMount
            aria-label={t('go.to.pdp.page', { productName })}
            onClick={handleOnClick('Product Card', `/${slug}/${productKey}-p`, 0)}
          >
            {isLoading && <Skeleton isLoading={isLoading} aspectRatio="1/1" skeletonWidth={isDesktop ? 634 : 328} />}
            <TextualBadge badge={badgesV2?.textual} />
            <ImageBadge badge={badgesV2?.image} />

            {!isLoading &&
              !!images.length &&
              images.map((image) => (
                <ImageContainer key={image} $isVisible={image === focusedProductImage}>
                  <CarouselImagePartial
                    alt={image}
                    src={image}
                    width={isDesktop ? 634 : 328}
                    height={isDesktop ? 634 : 328}
                    aria-hidden
                    onClick={onImageClick('Product Card', `/${slug}/${productKey}-p`, 0)}
                  />
                </ImageContainer>
              ))}
          </ProductLink>
        </Link>
        <ProductThumbnailSelector>
          {isLoading &&
            Array.from({ length: MAX_IMAGES }, (_, i) => (
              <ProductThumbnail key={i} style={{ border: 'none' }}>
                <Skeleton isLoading={isLoading} aspectRatio="1/1" />
              </ProductThumbnail>
            ))}
          {!isLoading &&
            !!images.length &&
            images.map((thumbnail, i) => (
              <ProductThumbnail key={`${thumbnail}_${i}`} onClick={selectProductImage(thumbnail)}>
                <ImagePartial src={thumbnail} layout="fill" objectFit="cover" />
                <SelectedThumbnailBorder $isSelected={`${focusedProductImage}_${i}` === `${thumbnail}_${i}`} />
              </ProductThumbnail>
            ))}
        </ProductThumbnailSelector>
      </ProductMultiImageContainer>
      <ProductItemInfo>
        <Link href={`/${slug}/${productKey}-p`} passHref>
          <ProductItemMainInfo tag="a" forceMount isLoading={isLoading}>
            <ProductName
              tag="h4"
              skeletonWidth={200}
              skeletonHeight={32}
              isLoading={isLoading}
              title={productName}
              $isMultiImageName
              className="truncated"
            >
              {productName}
            </ProductName>
            <ProductDetails $isMultiImageCategory>
              <Skeleton skeletonWidth={180} tag="p" isLoading={isLoading} title={productColor}>
                {productColor}
              </Skeleton>
              <Skeleton skeletonWidth={180} tag="p" isLoading={isLoading} title={categoryName}>
                {categoryName}
              </Skeleton>
            </ProductDetails>
            <PriceContainer>
              {prices?.map((price, i) => (
                <ProductItemPrice price={price} key={i} />
              ))}
            </PriceContainer>
          </ProductItemMainInfo>
        </Link>
        <ProductColourSelectorSection>
          <ProductColourSelector>
            {!colors && isLoading && (
              <Skeleton count={MAX_IMAGES} isLoading={isLoading} aspectRatio="1/1" skeletonWidth={40} />
            )}
            {!!colors &&
              colors?.slice(0, 3).map((color, i) => (
                <Link key={`${i}_${color}`} href={`/${slug}/${color?.key}-p`} passHref>
                  <ProductColour
                    $isSelectedColorLink={selectedColorLink === i}
                    onClick={onImageClick('Product Thumbnail', `/${slug}/${color?.key}-p`, i)}
                    aria-label={`${t('go.to.pdp.page', { productName })} - ${color?.color}`}
                  >
                    <ImagePartial src={color?.image || ''} layout="fill" objectFit="contain" />
                    <SelectedThumbnailBorder $isSelected={selectedColorLink === i} $isProductColour />
                  </ProductColour>
                </Link>
              ))}
            {colors && colors?.length > 3 && (
              <Link href={`/${slug}/${productKey}-p`} passHref>
                <ProductMoreColors>+{colors.length - 3} more</ProductMoreColors>
              </Link>
            )}
          </ProductColourSelector>
          <Link href={`/${slug}/${productKey}-p`} passHref>
            <ProductShopNowLink
              tag="a"
              isLoading={isLoading}
              aria-label={`${getLocalizedValue(multiImageButtonText)}, ${t('go.to.pdp.page', { productName })}`}
            >
              {getLocalizedValue(multiImageButtonText)}
            </ProductShopNowLink>
          </Link>
        </ProductColourSelectorSection>
      </ProductItemInfo>
    </ProductItemContainer>
  );
}

export default AlgoliaCarouselMultiImage;
