const base = 0;
const above = 1;
const below = -1;

export const zPriceTooltipArrow = below + base;

export const zBase = base;

export const zSwiper = above + base;
export const zMobileHeroBanner = above + base;
export const zCheckoutAdyenBase = above + base;
export const zSliderTrack = above + base;
export const zPopupHotspot = above + base;
export const zDeliveryMethodsLoader = above + base;
export const zInputFieldCheckMark = above + base;

export const zProductBadge = above + zPopupHotspot;
export const zSwiperNavigation = above + zSwiper;
export const zMobileHeroBannerContent = above + zMobileHeroBanner;
export const zCheckoutAdyenLabel = above + zCheckoutAdyenBase;
export const zCheckoutAdyenTooltip = above + zCheckoutAdyenBase;
export const zSliderRange = above + zSliderTrack;
export const zSliderLeftThumb = above + zSliderRange;
export const zSliderRightThumb = above + zSliderLeftThumb;
export const zPopupHotspotBubble = above + zPopupHotspot;
export const zCheckoutAddressSliderSaveButton = above + zInputFieldCheckMark;

export const zOverlay = above + zSwiperNavigation;
export const zCheckoutFooter = above + zCheckoutAdyenLabel;
export const zCheckoutPopupActive = above + zPopupHotspotBubble;
export const z3dAdyen = above + zCheckoutAdyenTooltip;

export const zNavBar = above + zOverlay;
export const zPriceTooltip = above + zOverlay;
export const zCheckoutAdyenTooltipBubble = above + zCheckoutFooter;
export const zNeedHelpButton = above + zCheckoutFooter;

export const zMobileAddToBag = above + zPriceTooltip;
export const zMobileCheckoutSecurely = above + zPriceTooltip;
export const zCheckoutAddressSlider = above + zCheckoutAdyenTooltipBubble;

export const zMiniCart = above + zMobileAddToBag;
export const zAutocompleteOverlay = above + zMobileAddToBag;
export const zCheckoutAddressSliderOverlay = above + zCheckoutAddressSlider;
export const zIDmeTooltip = above + zMiniCart;

export const zNeedHelpModalOverlay = above + zMiniCart;
export const zCheckoutCtaMenu = above + zMiniCart;
export const zAutocomplete = above + zAutocompleteOverlay;
export const zStickyHeader = above + zMiniCart;
export const zCheckoutAddressSliderContent = above + zCheckoutAddressSliderOverlay;

export const zNeedHelpModalContent = above + zNeedHelpModalOverlay;
export const zSizeGuide = above + zCheckoutCtaMenu;
export const zFilterMenu = above + zAutocomplete;
export const zMobileHamburgerMenu = above + zStickyHeader;
export const zCheckoutAddressSliderContentHeader = above + zCheckoutAddressSliderContent;

export const zMobileHamburgerMenuContent = above + zMobileHamburgerMenu;

export const zSubMenuTopContainer = above + zMobileHamburgerMenuContent;

export const zSidebarMobile = above + zStickyHeader;
export const zSidebarMobileContent = above + zSidebarMobile;

export const zNoLocationPopUp = above + zNeedHelpModalContent;

export const zSliderLeftOverRightThumb = zSliderLeftThumb + zSliderRange;

export const zButtonOverLay = above + base;
export const zCategoryNavCardLinks = above + base;

export const zImageLoader = above + base;
