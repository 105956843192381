import { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { SimpleAccordions as SimpleAccordionsSchema } from '@amplience/content-types/typings/c-simple-accordions';
import { AccordionRoot } from '../../baseComponents/accordion';
import { useDidMount } from '../../hooks/useDidMount';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import { TypographyStylesType, colours, media, spacing } from '../../stylings';
import { getLocalizedValue, transformTextToId } from '../../utils/transformers';
import SimpleAccordion from './SimpleAccordion';

export type SimpleAccordionsProps = SimpleAccordionsSchema;

const S = {
  Accordions: styled(AccordionRoot)`
    > div:first-child > button {
      border-top: 1px solid ${colours.BLACK};
    }
  `,

  Container: styled.div`
    margin: 0 auto;
    padding: 0 ${spacing.S};

    @media ${media.greaterThan('lg')} {
      max-width: 777px;
      width: 100%;
    }
  `,

  Title: styled(FlexibleTextPartial)`
    margin: 0 0 ${spacing.M} 0;
    overflow-wrap: anywhere;
  `,
};

export function SimpleAccordions({ heading, accordions, textTransform }: SimpleAccordionsProps) {
  const [value, setValue] = useState<string[]>([]);
  const isMounted = useDidMount();

  const handleOnChange = (newValue: string[]) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (isMounted) {
      setValue([window.location.hash]);
    }
  }, [isMounted]);

  useEffect(() => {
    const handleHashChange = () => {
      setValue((prev) => [...prev, window.location.hash]);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <S.Container>
      {heading && (
        <S.Title
          textSize={TypographyStylesType.HEADINGS_H3}
          textColor={colours.BLACK}
          textTransform={textTransform ?? 'UPPERCASE'}
          text={heading}
        />
      )}
      <S.Accordions type="multiple" value={value} onValueChange={handleOnChange}>
        {accordions?.map((accordion, idx) => (
          <SimpleAccordion
            key={idx}
            {...accordion}
            value={`${
              transformTextToId(accordion?.accordionAnchorId) ||
              `${transformTextToId(getLocalizedValue(accordion?.accordionHeading))}-${idx}`
            }`}
          />
        ))}
      </S.Accordions>
    </S.Container>
  );
}

export default SimpleAccordions;
