const currencyCodeToSymbol: {
  [key: string]: string;
} = {
  GBP: '£',
  CAD: '$',
  EUR: '€',
  USD: '$',
  CHF: '₣',
  DKK: 'Kr.',
  PLN: 'zł',
};

export const localeToCurrencyCode: {
  [key: string]: string;
} = {
  en: 'USD',
  'en-vg': 'GBP',
  'en-GB-OUTLET': 'GBP',
  'en-us': 'USD',
  'en-ie': 'EUR',
  'en-ca': 'CAD',
  'fr-ca': 'CAD',
  'en-gb': 'GBP',
  'de-de': 'EUR',
  'en-de': 'EUR',
  'fr-fr': 'EUR',
  'en-fr': 'EUR',
  'it-it': 'EUR',
  'en-it': 'EUR',
  'es-es': 'EUR',
  'en-es': 'EUR',
  'nl-nl': 'EUR',
  'en-nl': 'EUR',
  'de-at': 'EUR',
  'en-at': 'EUR',
  'de-ch': 'CHF',
  'fr-ch': 'CHF',
  'it-ch': 'CHF',
  'en-ch': 'CHF',
  'it-sm': 'EUR',
  'en-sm': 'EUR',
  'en-pl': 'PLN',
  'nl-be': 'EUR',
  'fr-be': 'EUR',
  'en-be': 'EUR',
  'en-dk': 'DKK',
  'fr-lu': 'EUR',
  'en-lu': 'EUR',
  'en-ba': 'EUR',
  'en-bg': 'EUR',
  'en-hr': 'EUR',
  'en-cy': 'EUR',
  'en-cz': 'EUR',
  'en-ee': 'EUR',
  'en-fi': 'EUR',
  'en-ge': 'EUR',
  'en-gi': 'EUR',
  'en-gr': 'EUR',
  'en-hu': 'EUR',
  'en-is': 'EUR',
  'en-lv': 'EUR',
  'en-li': 'EUR',
  'en-lt': 'EUR',
  'en-mt': 'EUR',
  'en-mc': 'EUR',
  'en-me': 'EUR',
  'en-mk': 'EUR',
  'en-no': 'EUR',
  'en-pt': 'EUR',
  'en-md': 'EUR',
  'en-ro': 'EUR',
  'en-rs': 'EUR',
  'en-sk': 'EUR',
  'en-si': 'EUR',
  'en-se': 'EUR',
  'en-tr': 'EUR',
};

/**
 * Returns the corresponding currency symbol string given the currency code.
 *
 * @param currencyCode `'GBP'` | `'CAD'` | `'EUR'` | `'USD'` | `'CHF'` | `'DKK'` | `'PLN'`
 * @returns `'£'` | `'$'` | `'€'` | `'₣'` | `'Kr.'` | `'zł'`
 */
export const getCurrencySymbol = (currencyCode: string | undefined) =>
  currencyCode ? currencyCodeToSymbol[currencyCode] : '';

/**
 * Returns whole value from cent amount.
 */
export const getAmount = (centAmount: number | undefined) => (centAmount ? (centAmount / 100).toFixed(2) : 0);

/**
 * Returns the formatted price with the currency symbol in front given the cent amount and the locale.
 */
export const formatAmountWithCurrency = (centAmount: number | undefined, currencyCode: string | undefined) =>
  `${getCurrencySymbol(currencyCode)}${getAmount(centAmount)}`;
