import { getItem } from '../utils';

export function useNRBASession() {
  const nrbaSessionObject = getItem('NRBA_SESSION', true, 'localStorage') as Record<string, string | number>;
  const nrbaSessionId = nrbaSessionObject?.value as string;

  return nrbaSessionId
    ? {
        context: {
          headers: {
            'x-nrba-session': nrbaSessionId,
          },
        },
      }
    : undefined;
}
