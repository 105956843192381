import { forwardRef } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../stylings';
import { labelStyle } from './Input.styles';

type StyleProps = {
  $isEmpty?: boolean;
  $shouldDisplayPlaceholder?: boolean;
};

const S = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
  `,
  Textarea: styled.textarea<StyleProps>`
    ${TypographyStyles.Body.Medium.Regular}
    border: 1px solid ${({ $isEmpty }) => ($isEmpty ? colours.GREY : colours.BLACK)};
    border-radius: 0;
    padding: ${spacing.XXXS} ${spacing.XXS};
    resize: vertical;
    width: 100%;

    & ~ label {
      color: ${({ $isEmpty }) => ($isEmpty ? colours.GREY : colours.BLACK)};
    }

    &:focus {
      border-color: transparent;
      outline: solid ${colours.BLACK} 1px;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      ${({ $shouldDisplayPlaceholder }) =>
        $shouldDisplayPlaceholder
          ? null
          : css`
              background-color: ${colours.WHITE};
              left: 10px;
              padding: 0 5px;
              transform: translateY(-50%) scale(0.8);
            `}
    }

    &::placeholder {
      color: ${({ $shouldDisplayPlaceholder }) => ($shouldDisplayPlaceholder ? colours.GREY : 'transparent')};
    }
  `,
  Label: styled.label<{ $shouldDisplayPlaceholder?: boolean }>`
    ${labelStyle}

    ${({ $shouldDisplayPlaceholder }) =>
      $shouldDisplayPlaceholder
        ? css`
            ${TypographyStyles.Body.Tiny.Regular}
            background-color: ${colours.WHITE};
            color: ${colours.GREY};
            display: block;
            left: ${spacing.XXS};
            padding: 0 ${spacing.XXXXS};
            position: absolute;
            top: -${spacing.S};
          `
        : null}

    textarea:required ~ &::after {
      content: '*';
    }
  `,
};

type TextareaProps = Omit<JSX.IntrinsicElements['textarea'], 'ref'> & {
  isEmpty?: boolean;
  register?: UseFormRegisterReturn;
  testId?: string;
  className?: string;
  shouldDisplayPlaceholder?: boolean;
  placeholder: string;
  label: string;
  name: string;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { testId, isEmpty, register, name, placeholder, className, label, shouldDisplayPlaceholder = false, ...rest },
    ref,
  ) => (
    <S.Wrapper className={className}>
      <S.Textarea
        ref={ref}
        placeholder={placeholder}
        name={name}
        data-testid={testId}
        $isEmpty={isEmpty}
        $shouldDisplayPlaceholder={shouldDisplayPlaceholder}
        {...rest}
        {...register}
      />
      <S.Label htmlFor={name} $shouldDisplayPlaceholder={shouldDisplayPlaceholder}>
        {label}
      </S.Label>
    </S.Wrapper>
  ),
);
