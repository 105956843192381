import { useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { MultiImageButtonText } from '@amplience/content-types/typings/c-product-carousel';
import type { CarouselProduct } from '../../../codegen/types';
import { useLocaleContext } from '../../../context/localeContext';
import { useStaticContext } from '../../../context/staticContext';
import { useMediaMatch } from '../../../hooks/useMediaMatch';
import { useTruncated } from '../../../hooks/useTruncated';
import { ImagePartial } from '../../../partials/image';
import { media } from '../../../stylings';
import { sendViewProductImageEvent } from '../../../utils/gtm/events/view_product_image/sendViewProductImageEvent';
import { getLocalizedValue } from '../../../utils/transformers';
import { ImageBadge, TextualBadge } from '../../badges';
import { Skeleton } from '../../skeleton/Skeleton';
import { ProductItemPrice } from '../productItemPrice/ProductItemPrice';
import {
  ImageAndBadgeContainer,
  ImageContainer,
  PriceContainer,
  ProductColour,
  ProductColourSelector,
  ProductColourSelectorSection,
  ProductDetails,
  ProductItemContainer,
  ProductItemInfo,
  ProductItemMainInfo,
  ProductLink,
  ProductMoreColors,
  ProductMultiImageContainerStyled,
  ProductName,
  ProductShopNowLink,
  ProductSingleItemContainer,
  ProductThumbnail,
  ProductThumbnailContainer,
  ProductThumbnailSelector,
  SelectedThumbnailBorder,
} from './ProductCarouselItem.styles';

export interface ProductCarouselItemProps extends CarouselProduct {
  isLoading?: boolean;
  productColor?: string;
  isMultiImage?: boolean;
  multiImageButtonText?: MultiImageButtonText;
  handleOnClick?: () => void;
}

function ProductSingleImage({
  isLoading,
  badgesV2,
  imageUrls,
  productColor,
  productName,
  prices,
  slug,
  productKey,
  handleOnClick,
  priceRanges,
  rangeGroupDescription,
}: Omit<ProductCarouselItemProps, 'isMultiImage' | 'productId' | 'multiImageButtonText' | 'sku'>) {
  const { asPath, query } = useRouter();
  const { host } = useStaticContext();
  const { locale } = useLocaleContext();
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { t } = useTranslation(['lib-global-common']);
  const singleProductNameRef = useRef<HTMLHeadingElement>(null);
  const { isTruncated, lineHeight } = useTruncated(singleProductNameRef);

  const isPDP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-p');
  const isPLP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-c');

  const componentType = !isPDP
    ? `${asPath === '/' ? 'Home' : `${asPath.toUpperCase()}`.replace(/\//g, '')} Page`
    : 'PDP';

  const onImageClick = (typeName: string, linkUrl: string, index: number) => () => {
    sendViewProductImageEvent({
      component_type: `${isPLP ? 'PLP' : componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };

  return (
    <ProductSingleItemContainer>
      <Link href={`/${slug}/${productKey}-p`} passHref>
        <ProductLink
          tag="a"
          isLoading={isLoading}
          forceMount
          onClick={handleOnClick}
          aria-label={t('go.to.pdp.page', { productName })}
        >
          {isLoading && <Skeleton aspectRatio="1/1" isLoading={isLoading} />}
          {!isLoading && imageUrls && (
            <ImageAndBadgeContainer>
              <TextualBadge badge={badgesV2?.textual} />
              <ImageBadge badge={badgesV2?.image} />

              <ImagePartial
                src={imageUrls?.[0]}
                hoveredSrc={imageUrls?.[1]}
                width={isDesktop ? 424 : 272}
                height={isDesktop ? 424 : 280}
                onClick={onImageClick('Product Card', `/${slug}/${productKey}-p`, 0)}
                hasLoadingState
              />
            </ImageAndBadgeContainer>
          )}
          <ProductName
            tag="span"
            isLoading={isLoading}
            skeletonWidth={150}
            title={productName}
            ref={singleProductNameRef}
            $lineHeight={lineHeight}
            className={`${isTruncated ? 'truncated' : ''}`}
          >
            {productName}
          </ProductName>
          <ProductDetails>
            <Skeleton tag="p" skeletonWidth={100} isLoading={isLoading} title={productColor}>
              {productColor}
            </Skeleton>
            <Skeleton tag="p" skeletonWidth={100} isLoading={isLoading} title={rangeGroupDescription || ''}>
              {rangeGroupDescription}
            </Skeleton>
          </ProductDetails>
          {prices?.map((price, i) => (
            <ProductItemPrice price={price} key={i} priceRanges={priceRanges} />
          ))}
        </ProductLink>
      </Link>
    </ProductSingleItemContainer>
  );
}

interface ProductMultiImageContainerProps
  extends Pick<ProductCarouselItemProps, 'slug' | 'productKey' | 'imageUrls' | 'productName' | 'badgesV2'> {
  isLoading?: boolean;
  minThumbnails?: number;
}

const MAX_IMAGES = 4;

export function ProductMultiImageContainer({
  isLoading,
  slug,
  productKey,
  imageUrls = [],
  productName,
  minThumbnails = 0,
  badgesV2,
}: ProductMultiImageContainerProps) {
  const { asPath, query } = useRouter();
  const { host } = useStaticContext();
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common']);
  const [focusedProductImage, setFocusedProductImage] = useState<string>(imageUrls?.[0] ?? '');
  const images = imageUrls.slice(0, MAX_IMAGES);

  const isPDP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-p');
  const isPLP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-c');

  const componentType = !isPDP
    ? `${asPath === '/' ? 'Home' : `${asPath.toUpperCase()}`.replace(/\//g, '')} Page`
    : 'PDP';

  const onImageClick = (typeName: string, linkUrl: string, index: number) => () => {
    sendViewProductImageEvent({
      component_type: `${isPLP ? 'PLP' : componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };

  const selectProductImage = (image: string, typeName: string, linkUrl: string, index: number) => () => {
    setFocusedProductImage(image);
    sendViewProductImageEvent({
      component_type: `${isPLP ? 'PLP' : componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };
  return (
    <ProductMultiImageContainerStyled>
      <Link href={`/${slug}/${productKey}-p`} passHref>
        <ProductLink tag="a" isLoading={isLoading} aria-label={t('go.to.pdp.page', { productName })}>
          <TextualBadge badge={badgesV2?.textual} />
          <ImageBadge badge={badgesV2?.image} />

          {images.map((image) => (
            <ImageContainer key={image} $isVisible={image === focusedProductImage}>
              <ImagePartial
                alt={image}
                src={image}
                width={1000}
                height={1000}
                aria-hidden
                onClick={onImageClick('Product Card', `/${slug}/${productKey}-p`, 0)}
              />
            </ImageContainer>
          ))}
        </ProductLink>
      </Link>
      <ProductThumbnailSelector>
        {!images.length && isLoading && <Skeleton count={MAX_IMAGES} isLoading={isLoading} aspectRatio="1/1" />}

        {!!images.length &&
          imageUrls?.length >= minThumbnails &&
          images.map((thumbnail, i) => (
            <ProductThumbnailContainer key={`${thumbnail}_${i}`}>
              <ProductThumbnail
                type="button"
                onClick={selectProductImage(thumbnail, 'Product Thumbnail', `/${slug}/${productKey}-p`, i)}
                aria-current={thumbnail === focusedProductImage}
                aria-label={t('product.carousel.thumbnail', {
                  thumbnailCount: `${i + 1} / ${Math.min(MAX_IMAGES, imageUrls.length)}`,
                })}
              >
                <ImagePartial src={thumbnail} layout="fill" objectFit="cover" aria-hidden />
              </ProductThumbnail>
              <SelectedThumbnailBorder
                $isSelected={`${focusedProductImage}_${i}` === `${thumbnail}_${i}`}
                aria-hidden
              />
            </ProductThumbnailContainer>
          ))}
      </ProductThumbnailSelector>
    </ProductMultiImageContainerStyled>
  );
}

function ProductMultiImage({
  isLoading,
  badgesV2,
  imageUrls,
  productColor,
  productName,
  prices,
  slug,
  multiImageButtonText,
  colors,
  productKey,
  handleOnClick,
  priceRanges,
  rangeGroupDescription,
}: Omit<ProductCarouselItemProps, 'isMultiImage' | 'productId' | 'sku'>) {
  const { t } = useTranslation(['lib-global-common']);
  const [selectedColorLink, setSelectedColorLink] = useState<number | undefined>();

  return (
    <ProductItemContainer forceMount isLoading={isLoading} onClick={handleOnClick}>
      <ProductMultiImageContainer
        isLoading={isLoading}
        slug={slug}
        productKey={productKey}
        imageUrls={imageUrls}
        productName={productName}
        badgesV2={badgesV2}
      />
      <ProductItemInfo>
        <Link href={`/${slug}/${productKey}-p`} passHref>
          <ProductItemMainInfo tag="a" isLoading={isLoading} forceMount>
            <ProductName
              tag="span"
              skeletonWidth={200}
              skeletonHeight={32}
              isLoading={isLoading}
              title={productName}
              $isMultiImageName
              className="truncated"
            >
              {productName}
            </ProductName>
            <ProductDetails $isMultiImageCategory>
              <Skeleton skeletonWidth={180} tag="p" isLoading={isLoading} title={productColor}>
                {productColor}
              </Skeleton>
              <Skeleton skeletonWidth={180} tag="p" isLoading={isLoading} title={rangeGroupDescription || ''}>
                {rangeGroupDescription}
              </Skeleton>
            </ProductDetails>
            <PriceContainer>
              {prices?.map((price, i) => (
                <ProductItemPrice price={price} key={i} priceRanges={priceRanges} />
              ))}
            </PriceContainer>
          </ProductItemMainInfo>
        </Link>
        <ProductColourSelectorSection>
          <ProductColourSelector>
            {!colors && isLoading && (
              <Skeleton count={MAX_IMAGES} isLoading={isLoading} aspectRatio="1/1" skeletonWidth={40} />
            )}

            {colors &&
              colors?.slice(0, 3).map((color, i) => (
                <Link key={`${i}_${color}`} href={`/${slug}/${color?.key}-p`} passHref>
                  <ProductColour
                    $isSelectedColorLink={selectedColorLink === i}
                    onClick={() => setSelectedColorLink(i)}
                    aria-label={`${t('go.to.pdp.page', { productName })} - ${color?.color}`}
                  >
                    <ImagePartial src={color?.image || ''} layout="fill" objectFit="contain" />
                    <SelectedThumbnailBorder $isSelected={selectedColorLink === i} $isProductColour />
                  </ProductColour>
                </Link>
              ))}

            {colors && colors?.length > 3 && (
              <Link href={`/${slug}/${productKey}-p`} passHref>
                <ProductMoreColors>+{colors.length - 3} more</ProductMoreColors>
              </Link>
            )}
          </ProductColourSelector>
          <Link href={`/${slug}/${productKey}-p`} passHref>
            <ProductShopNowLink
              tag="a"
              isLoading={isLoading}
              aria-label={`${getLocalizedValue(multiImageButtonText)}, ${t('go.to.pdp.page', { productName })}`}
            >
              {getLocalizedValue(multiImageButtonText)}
            </ProductShopNowLink>
          </Link>
        </ProductColourSelectorSection>
      </ProductItemInfo>
    </ProductItemContainer>
  );
}

export function ProductItem({
  brand,
  primaryCategory,
  categoryName,
  imageUrls,
  productName,
  prices,
  slug,
  isMultiImage,
  multiImageButtonText,
  colors,
  productKey,
  handleOnClick,
  rangeGroupDescription,
  ...rest
}: ProductCarouselItemProps) {
  const productColor = colors?.find((color) => color?.key === productKey)?.color;
  if (isMultiImage) {
    return (
      <ProductMultiImage
        brand={brand}
        primaryCategory={primaryCategory}
        productKey={productKey}
        categoryName={categoryName}
        imageUrls={imageUrls}
        prices={prices}
        productColor={productColor as string}
        productName={productName}
        slug={slug}
        multiImageButtonText={multiImageButtonText}
        colors={colors}
        handleOnClick={handleOnClick}
        rangeGroupDescription={rangeGroupDescription}
        {...rest}
      />
    );
  }
  return (
    <ProductSingleImage
      brand={brand}
      primaryCategory={primaryCategory}
      productKey={productKey}
      categoryName={categoryName}
      imageUrls={imageUrls}
      prices={prices}
      productColor={productColor as string}
      productName={productName}
      slug={slug}
      handleOnClick={handleOnClick}
      rangeGroupDescription={rangeGroupDescription}
      {...rest}
    />
  );
}

export default ProductItem;
