import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { ApolloError } from '@apollo/client';
import type { Customer } from '../codegen/types';
import { useGetCustomerLazyQuery } from '../graphqlDocument/customer/getCustomer.generated';
import { setInsightsUserToken } from '../services/algolia/insights';
import { AttributeName } from '../types/newrelic';
import { sendLoginSuccessEvent } from '../utils/gtm/events/login_success/sendLoginSuccessEvent';
import { sendUserDataLoadedEvent } from '../utils/gtm/events/user_data_loaded/sendUserDataLoadedEvent';
import { hashEmail, setNewRelicAttributes } from '../utils/newrelic';
import { getItem, removeItem } from '../utils/storage';

export type UserContextType = {
  isUserLoggedIn: boolean;
  customerData?: Customer;
  customerEmailHash: string | null;
  customerError?: ApolloError;
  customerLoading?: boolean;
  refetch: () => void;
  isUserLoggedInLoading: boolean;
  handleFetchIsUserLogin: () => void;
  isGuest: boolean;
  isUserRememberMe: boolean;
};

const UserContext = createContext<UserContextType>({
  isUserLoggedIn: false,
  isUserLoggedInLoading: true,
  customerEmailHash: null,
  handleFetchIsUserLogin: () => {},
  refetch: () => {},
  isGuest: false,
  isUserRememberMe: false,
});

export const useUserContext = () => useContext(UserContext);

let status = '';

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isUserLoggedInLoading, setIsUserLoggedInLoading] = useState(true);
  const [isGuest, setIsGuest] = useState(false);
  const [isUserRememberMe, setIsUserRememberMe] = useState(false);
  const [customerEmailHash, setCustomerEmailHash] = useState<null | string>(null);
  const [isCustomerEmailHashLoading, setCustomerEmailHashLoading] = useState(true);

  const [getCustomer, { loading: isGetCustomerLoading, data: customerData, error: customerError, refetch }] =
    useGetCustomerLazyQuery();

  const getEmailHash = useCallback(async () => {
    if (!customerData?.getCustomer?.email) return;

    try {
      const hashedUserEmail = await hashEmail(customerData?.getCustomer?.email);
      setCustomerEmailHash(hashedUserEmail);
    } catch (e) {
      console.error(e);
    } finally {
      setCustomerEmailHashLoading(false);
    }
  }, [customerData?.getCustomer?.email]);

  const handleFetchIsUserLogin = useCallback(async () => {
    try {
      const response = await fetch('/api/isUserLoggedIn');
      const {
        isUserLoggedIn: isLoggedIn,
        isGuest: isGuestTemp,
        isUserRememberMe: isUserRememberMeTemp,
      } = await response.json();

      setIsUserLoggedIn(isLoggedIn);
      setIsGuest(isGuestTemp);
      setIsUserRememberMe(isUserRememberMeTemp);

      if (isLoggedIn) {
        setCustomerEmailHashLoading(true);

        await getCustomer();
        await getEmailHash();
      } else {
        setCustomerEmailHashLoading(false);
      }
    } catch {
      // If fetch fails and we have no information whether user is logged in
      // or not, then do getCustomer as fallback.
      await getCustomer();
      await getEmailHash();
    } finally {
      setIsUserLoggedInLoading(false);
    }
  }, [getCustomer, getEmailHash]);

  useEffect(() => {
    (async () => {
      handleFetchIsUserLogin();
    })();
  }, [handleFetchIsUserLogin]);

  useEffect(() => {
    if (isUserLoggedIn && customerData) {
      setInsightsUserToken(customerData.getCustomer.id);
    }
  }, [customerData, isUserLoggedIn]);

  useEffect(() => {
    if (!isGetCustomerLoading && !isUserLoggedInLoading && !isCustomerEmailHashLoading) {
      const user =
        customerData?.getCustomer?.customerNumber || isUserLoggedIn
          ? {
              status: 'logged_in',
              customer_number: customerData?.getCustomer?.customerNumber,
              customer_email: customerData?.getCustomer?.email,
            }
          : { status: 'not_logged_in' };

      if (user.status === status) return;

      if (user.status === 'logged_in' && getItem('loginSuccess', true, 'sessionStorage')) {
        sendLoginSuccessEvent({
          user_logged_in: true,
          user_id: customerData?.getCustomer?.customerNumber,
          user_email_hash: customerEmailHash,
        });
        removeItem('loginSuccess', 'sessionStorage');
      }

      status = user.status;
      sendUserDataLoadedEvent(user);
    }
  }, [
    customerData?.getCustomer?.customerNumber,
    customerData?.getCustomer?.email,
    customerEmailHash,
    isCustomerEmailHashLoading,
    isGetCustomerLoading,
    isUserLoggedIn,
    isUserLoggedInLoading,
  ]);

  useEffect(() => {
    setNewRelicAttributes({
      [AttributeName.USER_LOGGED_IN]: !!isUserLoggedIn || !!customerData?.getCustomer.id || null,
      [AttributeName.USER_ID]: customerData?.getCustomer?.customerNumber || null,
      [AttributeName.USER_EMAIL_HASH]: customerEmailHash,
    });
  }, [
    customerData?.getCustomer.id,
    customerData?.getCustomer?.customerNumber,
    customerEmailHash,
    isCustomerEmailHashLoading,
    isUserLoggedIn,
    isUserLoggedInLoading,
  ]);

  const value = useMemo(
    () => ({
      isUserLoggedIn: isUserLoggedIn || !!customerData?.getCustomer.id,
      customerData: customerData?.getCustomer,
      customerEmailHash,
      refetch,
      customerLoading: isGetCustomerLoading || isUserLoggedInLoading,
      customerError,
      isUserLoggedInLoading,
      handleFetchIsUserLogin,
      isGuest,
      isUserRememberMe,
    }),
    [
      isUserLoggedIn,
      customerData?.getCustomer,
      customerEmailHash,
      refetch,
      isGetCustomerLoading,
      customerError,
      isUserLoggedInLoading,
      handleFetchIsUserLogin,
      isGuest,
      isUserRememberMe,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
