import type { NextApiRequest, NextApiResponse } from 'next';
import cookie from 'cookie';
import type { CookieSerializeOptions } from 'cookie';
import type { ServerResponse } from 'http';

export const AUTH_PREFIX = 'auth';

interface CookieOptions {
  path?: string;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none' | boolean;
  httpOnly?: boolean;
  maxAge?: number;
}

const defaultCookieOptions: CookieOptions = {
  path: '/',
  secure: true,
  sameSite: 'lax',
  httpOnly: true,
  maxAge: 60 * 60 * 24 * 365,
};

export function mapAuthCookies<T extends Record<string, string>>(data: T[], options: CookieOptions = {}) {
  return data
    .map((el) =>
      Object.entries(el)
        .filter(([name]) => name !== '__typename')
        .map(([name, value]) =>
          cookie.serialize(`${AUTH_PREFIX}_${name}`, value, { ...defaultCookieOptions, ...options }),
        ),
    )
    .flat();
}

export function removeCookies(cookies: NextApiRequest['cookies'], res: NextApiResponse) {
  const deletedCookies = Object.entries(cookies)
    .filter(([name]) => name.startsWith(AUTH_PREFIX))
    .map(([name, value]) => cookie.serialize(name, value || '', { ...defaultCookieOptions, maxAge: -1 }));

  res.setHeader('Set-Cookie', deletedCookies);
}

export function removeSpecificCookies(cookies: NextApiRequest['cookies'], res: NextApiResponse, cookieArray: string[]) {
  if (cookieArray.length === 0) {
    return;
  }
  const deletedCookies = Object.entries(cookies)
    .filter(([name]) => cookieArray.includes(name))
    .map(([name, value]) => cookie.serialize(name, value || '', { ...defaultCookieOptions, maxAge: -1 }));

  res.setHeader('Set-Cookie', deletedCookies);
}

export function setCookie(res: ServerResponse, name: string, value: unknown, options: CookieSerializeOptions = {}) {
  const stringValue = typeof value === 'object' ? `j:${JSON.stringify(value)}` : String(value);
  res.setHeader('Set-Cookie', cookie.serialize(name, stringValue, { ...defaultCookieOptions, ...options }));
}

export function setAuthSessionTimeout(res: ServerResponse) {
  setCookie(res, 'auth_sessionTimeout', 'true', { maxAge: 60 * 30 });
}

export function getCookie(c: string | undefined) {
  if (!c) return {};
  return cookie.parse(c);
}

// CSA session
export const CALLBACK_PATH = '/api/auth/callback';
export const START_CSA_SESSION_PATH = '/start-csa-session';
export const TERMINATE_CSA_SESSION_PATH = '/terminate-csa-session';
export const START_CSA_SESSION_TOKEN = 'startCsaSessionToken';
