import { useEffect, useState } from 'react';
import Image from 'next/image';
import type { ImageLoaderProps, ImageProps } from 'next/image';
import styled from 'styled-components';
import type { AssetType, LocalizedValue, UseOriginalSize } from '@amplience/content-types/typings/p-partials';
import { HourGlassIcon } from '../../icons';
import { colours, zImageLoader } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';
import { BROKEN_IMAGE_SRC, getAmplienceImageUrls, getSingleAmplienceImageUrl } from './ImagePartialUtils';

export interface ImagePartialProps extends ImageProps {
  defaultHost?: string;
  assetType?: AssetType;
  endpoint?: string;
  altText?: string | LocalizedValue;
  lazyLoading?: boolean;
  className?: string;
  // Enforce height and width to be a number instead of string.
  height?: number;
  width?: number;
  useOriginalSize?: UseOriginalSize;
  hasLoadingState?: boolean;
  hoveredSrc?: string;
}

export interface DynamicMediaProps {
  /**
   * Quality of the image in percentage. Lossy. Lower value will result in less bytes, but with more artefacts.
   */
  quality?: number | string;
  /**
   * Width of the final image in pixels.
   */
  width?: number;
  /**
   * Height of the final image in pixels.
   */
  height?: number;
  /**
   * Fallback in case the main image cannot be found by name.
   *
   * The value is also a name of image stored on the same hub.
   */
  notFoundImage?: string;
}

const LoadingPlaceholder = styled.div`
  align-items: center;
  aspect-ratio: 1/1;
  background-color: ${colours.LIGHT_GREY};
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${zImageLoader};
`;

export function ImagePartial({
  altText = '',
  defaultHost,
  endpoint,
  lazyLoading = true,
  src: srcProp,
  assetType,
  height,
  width: widthProp,
  objectFit = 'cover',
  quality = 'default',
  useOriginalSize,
  hasLoadingState,
  hoveredSrc,
  ...props
}: ImagePartialProps) {
  const [imageSrc, setImageSrc] = useState(encodeURI((srcProp as string) || BROKEN_IMAGE_SRC));
  const [isImageBroken, setIsImageBroken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredImageBroken, setIsHoveredImageBroken] = useState(false);

  useEffect(() => {
    setImageSrc(encodeURI((srcProp as string) || BROKEN_IMAGE_SRC));
    setIsLoading(true);
  }, [srcProp]);

  const loadingStrategy = lazyLoading ? 'lazy' : 'eager';
  const priority = !lazyLoading;

  const handleImageError = (isHoveredImage: boolean) => {
    setIsLoading(false);

    if (isHoveredImage) {
      setIsHoveredImageBroken(true); // Set the hovered image to broken when error occurs
      if (hoveredSrc) {
        setImageSrc(BROKEN_IMAGE_SRC); // Display the BROKEN_IMAGE_SRC for the hovered image
      }
    } else {
      setImageSrc(BROKEN_IMAGE_SRC); // Set the main image to broken when error occurs
      setIsImageBroken(true);
      setIsHoveredImageBroken(false);
    }
  };

  const amplienceImageLoader = ({ src, width }: ImageLoaderProps) => {
    const dynamicMediaProps = { width, height, quality };

    return getAmplienceImageUrls({
      defaultHost,
      dynamicMediaProps,
      endpoint,
      name: src,
      assetType: isImageBroken ? 'dynamic' : assetType,
    });
  };

  if (useOriginalSize) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={getSingleAmplienceImageUrl(defaultHost, 'clarks', srcProp.toString(), assetType)}
        alt={getLocalizedValue(altText)}
      />
    );
  }

  const changeImageOnHover = isHovered && hoveredSrc && !isHoveredImageBroken ? hoveredSrc : imageSrc;

  const setOnMouseLeave = () => {
    setIsHovered(false);
    setIsImageBroken(false);
    setImageSrc(encodeURI((srcProp as string) || ''));
    setIsHoveredImageBroken(false);
  };

  return (
    <>
      {hasLoadingState && isLoading && (
        <LoadingPlaceholder>
          <HourGlassIcon />
        </LoadingPlaceholder>
      )}
      <Image
        {...props}
        height={height}
        width={widthProp}
        src={changeImageOnHover}
        {...(hoveredSrc && {
          onMouseLeave: setOnMouseLeave,
          onMouseEnter: () => {
            setIsHovered(true);
          },
        })}
        alt={getLocalizedValue(altText)}
        loader={amplienceImageLoader}
        loading={loadingStrategy}
        objectFit={isImageBroken ? 'cover' : objectFit}
        priority={priority}
        onError={() => handleImageError(!!hoveredSrc && isHovered)}
        onLoad={() => setIsLoading(false)}
      />
    </>
  );
}
