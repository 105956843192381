import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type ResendConfirmationCodeMutationVariables = Types.Exact<{
  input: Types.ResendConfirmationCodeInput;
}>;

export type ResendConfirmationCodeMutation = {
  __typename?: 'Mutation';
  resendConfirmationCode: { __typename?: 'ResendConfirmationCodeResponse'; message?: string | null; success: boolean };
};

export const ResendConfirmationCodeDocument = gql`
  mutation resendConfirmationCode($input: ResendConfirmationCodeInput!) {
    resendConfirmationCode(input: $input) {
      message
      success
    }
  }
`;
export type ResendConfirmationCodeMutationFn = Apollo.MutationFunction<
  ResendConfirmationCodeMutation,
  ResendConfirmationCodeMutationVariables
>;

/**
 * __useResendConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useResendConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationCodeMutation, { data, loading, error }] = useResendConfirmationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendConfirmationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendConfirmationCodeMutation, ResendConfirmationCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendConfirmationCodeMutation, ResendConfirmationCodeMutationVariables>(
    ResendConfirmationCodeDocument,
    options,
  );
}
export type ResendConfirmationCodeMutationHookResult = ReturnType<typeof useResendConfirmationCodeMutation>;
export type ResendConfirmationCodeMutationResult = Apollo.MutationResult<ResendConfirmationCodeMutation>;
export type ResendConfirmationCodeMutationOptions = Apollo.BaseMutationOptions<
  ResendConfirmationCodeMutation,
  ResendConfirmationCodeMutationVariables
>;
