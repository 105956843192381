import styled from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.M} 0;
`;

export const BlockText = styled.span<{ $isCircle?: boolean }>`
  ${({ $isCircle }) => ($isCircle ? TypographyStyles.Body.Small.SemiBold : TypographyStyles.Body.Small.Regular)}
  margin-bottom: ${({ $isCircle }) => ($isCircle ? spacing.S : spacing.XXS)};

  @media ${media.greaterThan('lg')} {
    ${({ $isCircle }) => ($isCircle ? TypographyStyles.Body.Medium.SemiBold : TypographyStyles.Body.Tiny.Regular)}
  }
`;

export const BlockIconList = styled.ul<{ $isCircle?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ $isCircle }) => ($isCircle ? `0 0 0 ${spacing.XXS}` : '0')};
  padding: 0;
  row-gap: ${({ $isCircle }) => ($isCircle ? spacing.M : spacing.XXXXS)};
  width: 100%;

  @media ${media.greaterThan('lg')} {
    row-gap: ${({ $isCircle }) => ($isCircle ? spacing.M : spacing.XXXS)};
  }
`;

export const IconContainer = styled.li<{ hasLink: boolean }>`
  border: ${({ hasLink }) => (!hasLink ? `1px solid ${colours.BLACK}` : '0')};
  border-radius: 2px;
  overflow: hidden;
  position: relative;
`;
