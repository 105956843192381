import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type BookAppointmentMutationVariables = Types.Exact<{
  input: Types.BookAppointment;
}>;

export type BookAppointmentMutation = {
  __typename?: 'Mutation';
  bookAppointment: { __typename?: 'AppointmentBookingDetails'; id: string; start: string; end: string };
};

export const BookAppointmentDocument = gql`
  mutation bookAppointment($input: BookAppointment!) {
    bookAppointment(input: $input) {
      id
      start
      end
    }
  }
`;
export type BookAppointmentMutationFn = Apollo.MutationFunction<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;

/**
 * __useBookAppointmentMutation__
 *
 * To run a mutation, you first call `useBookAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookAppointmentMutation, { data, loading, error }] = useBookAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<BookAppointmentMutation, BookAppointmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BookAppointmentMutation, BookAppointmentMutationVariables>(
    BookAppointmentDocument,
    options,
  );
}
export type BookAppointmentMutationHookResult = ReturnType<typeof useBookAppointmentMutation>;
export type BookAppointmentMutationResult = Apollo.MutationResult<BookAppointmentMutation>;
export type BookAppointmentMutationOptions = Apollo.BaseMutationOptions<
  BookAppointmentMutation,
  BookAppointmentMutationVariables
>;
