// TODO: Might remove this component when the real designs are given
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import * as Tabs from '@radix-ui/react-tabs';
import { BackButton } from '../../baseComponents/button';
import { SectionContainer } from '../../baseComponents/containers/SectionContainer';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { transformTextToId } from '../../utils/transformers';

const S = {
  TabsRoot: styled(Tabs.Root)`
    max-width: 328px;
    width: 100%;
    @media ${media.greaterThan('lg')} {
      max-width: 408px;
    }
  `,
  TabList: styled(Tabs.List)`
    border-bottom: solid ${colours.BLACK} 1px;
  `,
  TabTrigger: styled(Tabs.Trigger)`
    background-color: inherit;
    border: none;
    color: ${colours.BLACK};
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    min-height: 40px;
    padding: 0 0 ${spacing.XXXS};
    text-transform: uppercase;
    width: 50%;
    ${TypographyStyles.Headings.H4}
    &[data-state='active'] {
      border-bottom: solid ${colours.BLACK} 4px;
      color: ${colours.MIDNIGHT_BLUE};
      cursor: default;
      font-weight: 600;
      opacity: 1;
    }
    a {
      color: inherit;
      text-align: center;
      text-decoration: none;
    }
  `,
  TabContent: styled(Tabs.Content)`
    margin: ${spacing.XS} 0 0;
    width: 100%;
  `,
  Back: styled(BackButton)`
    color: ${colours.BLACK};
  `,
};

export function TabNavigation({
  joinUsTab,
  loginTab,
  slug,
}: {
  joinUsTab: ReactNode;
  loginTab: ReactNode;
  slug?: string;
}) {
  const { t } = useTranslation(['lib-global-common']);
  const joinUs = t('register.join.us');
  const login = t('register.login');
  const joinUsId = transformTextToId(joinUs);
  const loginId = transformTextToId(login);
  const router = useRouter();
  const defaultTab = useMemo(
    () => (router.asPath.includes('/login') ? loginId : joinUsId),
    [router.asPath, loginId, joinUsId],
  );

  const onRouteChanged = (pathname: string) => {
    router.push(
      {
        pathname,
        query: router.pathname ? router.query : null,
      },
      undefined,
      {
        locale: router.locale,
        shallow: true,
      },
    );
  };

  const onChangeActiveTab = (value: string) => {
    const joinUsPath = slug || '/register';

    onRouteChanged(value === loginId ? '/login' : joinUsPath);
  };

  return (
    <SectionContainer>
      <S.TabsRoot defaultValue={defaultTab} onValueChange={onChangeActiveTab}>
        <S.Back />
        <S.TabList>
          <S.TabTrigger value={loginId} data-testid="loginTab">
            {login}
          </S.TabTrigger>
          <S.TabTrigger value={joinUsId} data-testid="joinUsTab">
            {joinUs}
          </S.TabTrigger>
        </S.TabList>
        <S.TabContent value={joinUsId}>{joinUsTab}</S.TabContent>
        <S.TabContent value={loginId}>{loginTab}</S.TabContent>
      </S.TabsRoot>
    </SectionContainer>
  );
}

export default TabNavigation;
