import styled from 'styled-components';
import {
  TypographyStyles,
  colours,
  hoverUp,
  media,
  slideToLeftTraysAnimation,
  spacing,
  zMiniCart,
} from '../../../../stylings';
import { FocusTrap } from '../../../focusTrap';
import { SummaryBar } from '../../summaryBar';
import type { SliderWrapperProps } from './MiniCartSliderProps';

export const MenuContainer = styled.div<{ $sliderOffset: number }>`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  position: absolute;
  top: ${({ $sliderOffset }) => $sliderOffset}px;
  width: 100%;
  z-index: ${zMiniCart};

  .sticky ~ & {
    position: fixed;

    @media ${media.greaterThan('lg')} {
      top: 80px;
    }
  }

  .sticky.hide-navbar ~ & {
    top: 0;
  }
`;

export const MenuOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: fixed;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    position: absolute;
  }
`;

export const MenuContent = styled.div`
  background-color: ${colours.WHITE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 310px;
  overflow: auto;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    max-width: 400px;
    width: 400px;
  }
`;
export const Wrapper = styled.div`
  padding: ${spacing.S} ${spacing.S};

  @media ${media.greaterThan('lg')} {
    padding: ${spacing.L} ${spacing.M};
  }
`;

export const SummaryBarContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.XS};
`;

export const CloseButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  ${hoverUp()}

  svg {
    transform: scale(0.5);
  }
`;

export const SectionWrapper = styled.div<{ $noBottomMargin?: boolean }>`
  background-color: ${colours.LIGHT_GREY_2};
  margin-bottom: ${({ $noBottomMargin }) => ($noBottomMargin ? 0 : spacing.S)};
  padding: ${spacing.S};

  @media ${media.greaterThan('lg')} {
    padding: ${spacing.M};
  }
`;

export const PaymentMethodLabel = styled.span`
  display: block;
  margin-bottom: ${spacing.XXXS};
  ${TypographyStyles.Body.Small.SemiBold}
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  background: ${colours.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 310px;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    max-width: 400px;
  }
`;

export const ButtonContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${colours.MID_GREY};
  display: flex;
  flex-direction: column;
  gap: ${spacing.XXXS};
  padding: ${spacing.XS} ${spacing.S};
  width: 100%;

  @media ${media.greaterThan('lg')} {
    padding: ${spacing.XS} ${spacing.M};
  }
`;

export const SummaryBarContent = styled(SummaryBar)`
  flex-grow: 1;
`;

export const ErrorMessage = styled.span`
  ${TypographyStyles.Body.Tiny.SemiBold}
  color: ${colours.ERROR_PRIMARY};
  text-align: center;
  white-space: pre-line;
`;

export const SliderWrapper = styled(FocusTrap)<SliderWrapperProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $sliderOffset }) => $sliderOffset}px;
  ${slideToLeftTraysAnimation}

  .sticky ~ .menu-container > &,
      .notification-bar ~ .sticky ~ .menu-container > & {
    margin-bottom: ${({ $navBarHeightMobile = 0 }) => $navBarHeightMobile}px;

    @media ${media.greaterThan('lg')} {
      margin-bottom: ${({ $navBarHeightDesktop = 0 }) => $navBarHeightDesktop}px;
    }
  }

  .sticky.hide-navbar ~ .menu-container > &,
  .notification-bar ~ .sticky.hide-navbar ~ .menu-container > & {
    margin-bottom: 0;
  }

  @media ${media.greaterThan('lg')} {
    margin-bottom: ${({ $scrollTop, $sliderOffset }) => ($scrollTop ? $sliderOffset - $scrollTop : $sliderOffset)}px;
    max-width: 400px;
    width: 400px;
  }
`;

export const BasketMergedConfirmationMessageContainer = styled.div`
  align-items: center;
  background-color: ${colours.SUCCESS_SECONDARY};
  display: flex;
  height: 64px;
  justify-content: center;
  margin-top: ${spacing.XS};

  svg {
    stroke: ${colours.BLACK};
  }

  @media ${media.greaterThan('lg')} {
    height: 72px;
  }
`;

export const BasketMergedConfirmationMessage = styled.span`
  margin-left: ${spacing.XXXS};
  width: 202px;
  ${TypographyStyles.Body.Small.SemiBold}

  @media ${media.greaterThan('lg')} {
    width: 223px;
  }
`;

export const NeedHelpContainer = styled.div`
  padding: 0 ${spacing.S};
`;
