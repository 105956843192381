import styled from 'styled-components';
import { RichTextPartial } from '../../partials/richText';
import { TypographyStyles, colours, media, zPriceTooltip, zPriceTooltipArrow } from '../../stylings';

export const PriceTooltipContainer = styled.div`
  display: inline-block;
  height: 17px;
  position: relative;
  width: 17px;
`;
export const PriceTooltipIcon = styled.button`
  background: none;
  border: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const PriceTooltipArrow = styled.div`
  margin-left: -20px;
  position: absolute;
  z-index: ${zPriceTooltipArrow};

  &:before,
  &:after {
    border: 20px solid transparent;
    bottom: 0;
    content: '';
    position: absolute;
  }

  &:before {
    border-bottom: 30px solid ${colours.WHITE};
    border-top: 0;
    bottom: 0;
  }

  &:after {
    border-bottom: 0;
    border-top: 30px solid ${colours.WHITE};
    top: 0;
  }
`;

export const PriceTooltipBubble = styled.div`
  background: ${colours.WHITE};
  filter: drop-shadow(0 0 1px ${colours.BLACK});
  padding: 20px;
  top: 100%;
  width: 230px;
  z-index: ${zPriceTooltip};

  &[data-popper-placement^='top'] > .arrow {
    bottom: 10px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: 10px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: 30px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: 10px;
  }

  @media ${media.greaterThan('lg')} {
    width: 300px;
  }
`;
export const PriceTooltipHeaderText = styled.span`
  ${TypographyStyles.Body.Tiny.SemiBold}
`;

export const PriceTooltipBodyText = styled(RichTextPartial)`
  & p {
    margin: 0;
  }

  & a {
    color: ${colours.BLACK};
  }
`;
