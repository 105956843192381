import type { ReactNode } from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from '../../../baseComponents/accordion';
import { ArrowDown } from '../../../icons';
import {
  TypographyStyles,
  accordionArrowAnimation,
  colours,
  maxHeightAccordionClose,
  maxHeightAccordionOpen,
  media,
  spacing,
} from '../../../stylings';

const S = {
  FilterList: styled.ol`
    display: grid;
    gap: ${spacing.XXXS};
    grid-template-columns: repeat(4, 1fr);
    padding: 0;
    width: 262px;

    @media ${media.greaterThan('lg')} {
      gap: 10px;
      width: 302px;
    }
  `,
  FilterItem: styled.button<{ $isActive?: boolean }>`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 1px solid ${colours.GREY};
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: center;
    padding: ${spacing.XXS} 10px;
    width: 59.5px;
    ${TypographyStyles.Body.Small.Regular}

    @media ${media.greaterThan('lg')} {
      height: 36px;
      padding: ${spacing.XXXXS} 10px;
      width: 69.5px;
    }

    ${({ $isActive }) =>
      $isActive &&
      css`
        background: ${colours.LIGHT_BLUE};
        border: 1px solid ${colours.NAVY_BLUE};
        font-weight: 600;
      `}
  `,
  Divider: styled.hr`
    margin: 0;
    padding: 0;
    width: 262px;

    @media ${media.greaterThan('lg')} {
      width: 302px;
    }
  `,
  Item: styled(AccordionItem)`
    width: 262px;

    @media ${media.greaterThan('lg')} {
      width: 302px;
    }
  `,
  Header: styled(AccordionHeader)`
    margin: 0;
    padding: 0;
    @media ${media.greaterThan('lg')} {
      width: 302px;
    }
  `,
  Trigger: styled(AccordionTrigger)<{ $isOpen: boolean }>`
    align-items: center;
    background-color: ${colours.WHITE};
    border: none;
    color: ${colours.BLACK};
    display: flex;
    height: 52px;
    justify-content: space-between;
    margin: 0;
    padding: 14px 0;
    width: 100%;
    ${TypographyStyles.Body.Small.SemiBold}
    ${({ $isOpen }) => accordionArrowAnimation($isOpen)}

    @media ${media.greaterThan('lg')} {
      height: 68px;
      padding: 20px 0;
    }
  `,
  Content: styled(AccordionContent)`
    margin-top: ${spacing.S};
    @media ${media.greaterThan('lg')} {
      margin-top: 30px;
    }
  `,
  SizeHeading: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
    height: 22px;
    width: 302px;
    @media ${media.greaterThan('lg')} {
      width: 262px;
    }
  `,
  AccordionContent: styled(AccordionContent)`
    overflow: hidden;

    &[data-state='open'] {
      animation: ${maxHeightAccordionOpen} 0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }

    &[data-state='closed'] {
      animation: ${maxHeightAccordionClose} 0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }

    @media (prefers-reduced-motion) {
      &[data-state='open'] {
        animation: none;
      }

      &[data-state='closed'] {
        animation: none;
      }
    }
  `,
};

export type FilterAccordionProps = {
  triggerLabel: ReactNode | string;
  content: ReactNode | string;
  isOpen?: boolean;
  testId?: string;
};

export function FilterAccordion({ triggerLabel, content, isOpen = false, testId }: FilterAccordionProps) {
  const [openAccordion, setOpenAccordion] = useState(isOpen);

  const handleOnToggleAccordion = () => {
    setOpenAccordion((prev) => !prev);
  };
  return (
    <AccordionRoot
      type="single"
      collapsible
      value={openAccordion ? 'accordion-1' : ''}
      onValueChange={handleOnToggleAccordion}
    >
      <S.Item value="accordion-1">
        <S.Header asChild>
          <S.Trigger data-testid={testId} $isOpen={openAccordion}>
            <>
              {triggerLabel}
              <ArrowDown className="accordion-arrow-down" />
            </>
          </S.Trigger>
        </S.Header>
        <S.AccordionContent>{content}</S.AccordionContent>
      </S.Item>
      <S.Divider />
    </AccordionRoot>
  );
}

export default FilterAccordion;
