import styled from 'styled-components';
import { Fieldset } from '../../../baseComponents/inputs/Fieldset';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

export const Container = styled(Fieldset)`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 9px;
  margin: 0;
  margin-bottom: ${spacing.S};
  padding: ${spacing.XXXXXS};
  @media ${media.greaterThan('lg')} {
    gap: 13px;
    margin-bottom: ${spacing.M};
  }
`;

export const CheckboxWrapper = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  width: 262px;
  label {
    align-items: start;
    gap: ${spacing.XS};
    ${TypographyStyles.Body.Small.Regular}
  }
  @media ${media.greaterThan('lg')} {
    width: 302px;
  }
`;

export const ItemCount = styled.p`
  ${TypographyStyles.Body.Small.Medium}
  margin-block: 0;
  padding-right: 5px;
`;

export const MoreLessButton = styled.button`
  ${TypographyStyles.CallToAction.Tiny.SemiBold}
  align-items: center;
  background: none;
  border: 0;
  color: ${colours.BLACK};
  display: flex;
  padding: 0;
  text-align: left;
  text-decoration: underline;

  svg {
    display: inline-block;
    overflow: visible;
    transform: scale(0.5);
  }
`;
