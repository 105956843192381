import type { LocaleType, LanguageType } from '@shared/localisation';

export enum HourFormat {
  HOUR_12 = 'hour12',
  HOUR_24 = 'hour24',
}

export function getCorrectedLocale(locale: LocaleType | LanguageType): string {
  // Safari (14.1.2 atm) doesn't support ar-AE but does support ar-SA
  // return locale === 'ar-AE' ? 'ar-SA' : locale;
  return locale;
}

export function formatNumber(
  locale: LocaleType,
  number: number,
  options: Intl.NumberFormatOptions = { maximumSignificantDigits: 4 },
): string {
  const correctedLocale = getCorrectedLocale(locale);
  const numberFormat = new Intl.NumberFormat(correctedLocale, options);
  return numberFormat.format(number);
}

export function translateNumbers(value: string | number, locale = 'ar'): string {
  if (!value && value !== 0) return '';
  if (locale.includes('ar')) {
    return value.toString().replace(/\d/g, (digit) => '٠١٢٣٤٥٦٧٨٩'[Number(digit)]);
  }

  return value.toString();
}

export function containsArabic(s: string): boolean {
  return /[\u0600-\u06FF]/.test(s);
}

// datetime should be a ISO 8601 string
export function getFormattedTime(datetime: string, locale?: string, hourFormatType?: HourFormat): string {
  let time = datetime.split('T')[1].slice(0, 5);
  if (hourFormatType === HourFormat.HOUR_12) {
    const [hour, minute] = time.split(':');
    const hourInt = +hour;

    const hour12 = hourInt === 12 || hourInt === 0 ? '12' : hourInt % 12;
    const period = hourInt >= 12 ? 'PM' : 'AM';

    time = `${hour12}:${minute} ${period}`;
  }
  return locale && locale.includes('ar') ? translateNumbers(time) : time;
}

export function getFormattedDate(datetime: string, locale?: string): string {
  const date = datetime.split('T')[0];
  return locale && locale.includes('ar') ? translateNumbers(date) : date;
}

export function getSessionTime({
  startDateTime,
  endDateTime,
  status = 'confirmed',
  locale,
  hourFormatType = HourFormat.HOUR_24,
}: {
  startDateTime?: string | null;
  endDateTime?: string | null;
  status?: string;
  locale: string;
  hourFormatType?: HourFormat;
}): string {
  if (status === 'open' || !startDateTime || !endDateTime) {
    return '';
  }

  const startTime = getFormattedTime(startDateTime, locale, hourFormatType);
  const endTime = getFormattedTime(endDateTime, locale, hourFormatType);

  return `${startTime} - ${endTime}`;
}

export function getCountryFromLocale(locale: string) {
  return locale?.split('-')[1]?.toUpperCase();
}

export const outletLocales = ['en-vg', 'en-gb-outlet'];

// The string union type from CustomerMarketingPreferences['country'] cannot be checked at runtime, we have to create an array with the available countries in order to ensure a correct payload
const schemaCountries = [
  'uk',
  'eu',
  'nl',
  'es',
  'us',
  'fr',
  'de',
  'ie',
  'ca',
  'gb',
  'it',
  'at',
  'be',
  'ba',
  'bg',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fi',
  'ge',
  'gi',
  'gr',
  'hu',
  'il',
  'lv',
  'li',
  'lt',
  'lu',
  'mt',
  'mc',
  'me',
  'mk',
  'no',
  'pl',
  'pt',
  'md',
  'ro',
  'sm',
  'rs',
  'sk',
  'si',
  'se',
  'ch',
  'tr',
  'is',
  'Unknown',
];

export function getCustomerMarketingPreferencesCountry(nextJslocale: string) {
  let country = nextJslocale?.split('-')[1].toLowerCase() || 'Unknown';
  if (country.toString() === 'uk' || country === 'vg') country = 'gb';

  return schemaCountries.includes(country) ? country : 'Unknown';
}

export function getCustomerMarketingPreferencesLanguage(locale: string): string {
  return outletLocales.includes(locale?.toLowerCase()) ? 'en-gb-outlet' : locale;
}
