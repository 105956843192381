import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useCartContext } from '../../../context/cartContext';
import { useCheckoutConfirmationContext } from '../../../context/checkoutConfirmationContext';
import { TypographyStyles, media, spacing } from '../../../stylings';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { getAmount, getCurrencySymbol } from '../../../utils/currencyHelper';

export enum DeliveryMethodError {
  INSUFFICIENT_STOCK_ERROR = 'InsufficientStockError',
}

const S = {
  Container: styled.div``,
  DeliveryLineItem: styled.div`
    align-self: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    user-select: none;
  `,
  DeliveryDetails: styled.div`
    line-height: normal;
    margin-left: auto;
    text-align: right;

    @media ${media.greaterThan('lg')} {
      margin-left: 20px;
    }
  `,
  DeliveryText: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
  DeliveryDays: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
  `,
  DeliveryLabel: styled.span`
    margin-right: ${spacing.XXXS};
  `,
  DeliveryOptions: styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXS};
    margin-bottom: 0;
    margin-top: ${spacing.XS};
    padding: 0;
  `,
  Days: styled.p`
    ${TypographyStyles.Body.Tiny.Regular}
    margin: 0;
  `,
  Price: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
};

export function RenderPrice({
  price,
  currency,
  displayPriceAsIs = false,
}: {
  price: number;
  currency: string | undefined;
  displayPriceAsIs?: boolean;
}) {
  const { t } = useTranslation(['lib-global-common']);
  const priceDisplay = displayPriceAsIs ? price?.toFixed(2) : getAmount(price);

  return (
    <S.Price data-testid="cartDeliveryPrice">
      {price === 0 ? `${t('cart.free')}` : `${getCurrencySymbol(currency)}${priceDisplay}`}
    </S.Price>
  );
}

export function CartDelivery() {
  const { t } = useTranslation(['lib-global-common']);
  const { miniCart } = useCartContext();
  const {
    deliveryMethod: cartDeliveryMethod,
    hasFreeDelivery: hasCartFreeDelivery,
    currency: cartCurrency,
  } = { ...miniCart };
  const { orderData } = useCheckoutConfirmationContext();
  const {
    deliveryMethod: orderedCartDeliveryMethod,
    hasFreeDelivery: hasOrderFreeDelivery = false,
    currency: orderedCartCurrency,
  } = {
    ...orderData?.getOrderedCart,
  };
  const { asPath } = useRouter();

  const isConfirmationPage = asPath.includes('/checkout/confirmation');
  const deliveryMethod = isConfirmationPage ? orderedCartDeliveryMethod : cartDeliveryMethod;
  const currency = isConfirmationPage ? orderedCartCurrency : cartCurrency;

  const renderDeliveryOptionDisplay = () =>
    deliveryMethod ? (
      <S.DeliveryDetails>
        <RenderPrice
          price={hasCartFreeDelivery || hasOrderFreeDelivery ? 0 : deliveryMethod?.price}
          currency={currency}
        />{' '}
        <S.DeliveryText data-testid="cartDeliveryText">{deliveryMethod?.name}</S.DeliveryText>{' '}
        {deliveryMethod?.deliveryTime && (
          <S.DeliveryDays data-testid="cartDeliveryDays">
            {capitalizeFirstLetter(deliveryMethod.deliveryTime)}
          </S.DeliveryDays>
        )}
      </S.DeliveryDetails>
    ) : null;

  if (!deliveryMethod) return null;

  return (
    <S.Container>
      <S.DeliveryLineItem data-testid="cartDeliveryItem">
        <S.DeliveryLabel data-testid="cartDeliveryLabel">{t('cart.delivery')}</S.DeliveryLabel>
        {renderDeliveryOptionDisplay()}
      </S.DeliveryLineItem>
    </S.Container>
  );
}
