import { useIntersectionObserverRef } from 'rooks';
import { useRecaptchaContext } from '../context/recaptchaContext';

export function useMountRecaptchaScript() {
  const { handleEnableRecaptchaScript } = useRecaptchaContext();

  const callback = (entries: { isIntersecting: boolean | ((prevState: boolean) => boolean) }[]) => {
    if (entries?.[0] && entries[0].isIntersecting) {
      handleEnableRecaptchaScript();
    }
  };
  const [componentRef] = useIntersectionObserverRef(callback);

  return { componentRef };
}
