export type CustomAttributes = {
  [name: string]: string | number | boolean | null;
};

export enum AttributeName {
  // New Relic
  SITE_BRAND = 'site_brand',
  SITE_LOCALE = 'site_locale',
  SITE_VERSION = 'site_version',
  SITE_ENVIRONMENT = 'site_environment',
  SITE_COUNTRY = 'site_country',
  SITE_CURRENCY = 'site_currency',
  PAGE_TYPE = 'page_type',
  PAGE_TITLE = 'page_title',
  PAGE_PATH = 'page_path',
  USER_ID = 'user_id',
  USER_LOGGED_IN = 'user_logged_in',
  USER_EMAIL_HASH = 'user_email_hash',
  ORDER_ID = 'order_id',
  ORDER_VALUE = 'order_value',
  ORDER_CART_ID = 'order_cart_id',
  PRODUCT_ID = 'product_id',
  PRODUCT_NAME = 'product_name',
  CATEGORY_ID = 'category_id',
  CATEGORY_NAME = 'category_name',
  CATEGORY_RESULT_COUNT = 'category_result_count',
  CATEGORY_PAGE_NUM = 'category_page_num',
  SEARCH_TERM = 'search_term',
  SEARCH_SUCCESS = 'search_success',
  SEARCH_RESULT_COUNT = 'search_result_count',
  SEARCH_PAGE_NUM = 'search_page_num',
  NEXTJS_HYDRATION = 'nextjs_hydration',
  NEXTJS_RENDER = 'nextjs_render',
  NEXTJS_ROUTE_CHANGE_TO_RENDER = 'nextjs_route_change_to_render',
  // GTM
  REFERRER = 'referrer',
  LANGUAGE_DISPLAY = 'language_display',
  SITE_REGION = 'site_region',
}

export enum PageType {
  HOMEPAGE = 'homepage',
  CMS = 'cms',
  PDP = 'pdp',
  PLP = 'plp',
  SEARCH = 'search',
  CART = 'cart',
  CHECKOUT = 'checkout',
  ERROR = 'error',
  LOGIN = 'login',
  REGISTER = 'register',
  MY_ACCOUNT = 'my-account',
  LOCATIONS = 'locations',
  FIND_ORDER = 'find-order',
}
