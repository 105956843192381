import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Warning } from '../../icons';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

const S = {
  Button: styled.button`
    ${TypographyStyles.Button.SemiBold}
    background-color: ${colours.DARK_BLUE};
    border: none;
    color: ${colours.WHITE};
    height: 44px;
    line-height: 22px;
    width: 100%;
  `,
  ShortMessage: styled.span`
    ${TypographyStyles.Body.Small.Regular}
  `,
  Info: styled.div`
    display: flex;
    margin: 2px;
    @media ${media.greaterThan('lg')} {
      margin: 10px;
    }
  `,
  Container: styled.section`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    max-width: 328px;
    @media ${media.greaterThan('lg')} {
      max-width: 408px;
    }
  `,
  IconWrapper: styled.figure`
    align-items: center;
    display: flex;
    margin: 0;
  `,
  HeaderText: styled.h2`
    ${TypographyStyles.Headings.H3}
    margin: 0;
  `,
  StyledWarning: styled(Warning)`
    @media ${media.greaterThan('lg')} {
      transform: scale(2);
    }
  `,
};

export function ConfirmationError() {
  const router = useRouter();
  const { t } = useTranslation(['lib-global-common']);
  const handleOnClick = (pathname: string) => () => {
    router.push(
      {
        pathname,
      },
      undefined,
      {
        locale: router.locale,
      },
    );
  };

  return (
    <S.Container role="status">
      <S.Info>
        <S.IconWrapper>
          <S.StyledWarning />
        </S.IconWrapper>
      </S.Info>
      <S.HeaderText>{t('register.confirmation.not.found.message').toUpperCase()}</S.HeaderText>
      <S.ShortMessage>{t('register.confirmation.not.found.start.again')}</S.ShortMessage>
      <S.Button
        type="button"
        aria-label={t('register.create.account')}
        onClick={handleOnClick('/register')}
        data-testid="createAccountButton"
      >
        {t('register.create.account').toUpperCase()}
      </S.Button>
    </S.Container>
  );
}
export default ConfirmationError;
