import { useEffect } from 'react';

export const useEscapeKeyListener = (callbackFunction: () => Promise<void>): void => {
  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent): Promise<void> => {
      if (event.code === 'Escape') {
        await callbackFunction();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [callbackFunction]);
};

export default useEscapeKeyListener;
