export const isServer = (): boolean => typeof window === 'undefined';

export const isClient = (): boolean => typeof window !== 'undefined';

export const isProduction = (): boolean => process.env.NODE_ENV === 'production';

export const isDevelopment = (): boolean => !isProduction();

export const isLocal = (): boolean => process.env.ORIGIN === 'https://clarks.dev:3001';

export const { STOREFRONT_URL, STOREFRONT_CDN_URL, UK_OUTLET_URL, UK_OUTLET_CDN_URL } = process?.env || {};

export const isCsaEnv = (): boolean =>
  STOREFRONT_URL?.includes('csa') ||
  STOREFRONT_CDN_URL?.includes('csa') ||
  UK_OUTLET_URL?.includes('csa') ||
  UK_OUTLET_CDN_URL?.includes('csa') ||
  false;
