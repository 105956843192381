import { forwardRef } from 'react';
import type { RefObject } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useCartContext } from '../../../context/cartContext';
import { useCheckoutConfirmationContext } from '../../../context/checkoutConfirmationContext';
import { useNeedHelpContext } from '../../../context/needHelpContext';
import { useStar2 } from '../../../hooks/useStar2';
import { CallCenter, WhatsApp } from '../../../icons';
import { LinkPartial } from '../../../partials/link/LinkPartial';
import { TypographyStyles, colours, spacing } from '../../../stylings';
import { NeedHelpSectionCheckout } from './NeedHelpSectionCheckout';

const S = {
  BagID: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
  Contacts: styled.ul`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    padding-left: 0;

    & a,
    & button {
      ${TypographyStyles.CallToAction.Tiny.SemiBold}
      align-items: center;
      background: none;
      border: none;
      color: ${colours.BLACK};
      cursor: pointer;
      display: flex;
      gap: ${spacing.XXXS};
      padding: 0;
      text-align: left;
      text-decoration: underline;
    }

    & li {
      &:hover,
      &:focus {
        svg {
          transform: translateY(-2px);
        }
      }
    }
  `,
  IconContainer: styled.span`
    height: 15px;
    width: 15px;
  `,
  ContactUs: styled(LinkPartial)`
    ${TypographyStyles.CallToAction.Large.SemiBold}
    color: ${colours.BLACK};
    height: 16px;
    text-decoration-line: underline;
  `,
};

// eslint-disable-next-line react/function-component-definition
export const NeedHelpContent = forwardRef(
  ({ className, orderNumber = undefined }: { className?: string; orderNumber?: string }, ref) => {
    const { t } = useTranslation(['lib-global-common']);
    const { miniCart } = useCartContext();
    const { id } = { ...miniCart };
    const { isConfirmationPage, orderData } = useCheckoutConfirmationContext();
    const { needHelpData } = useNeedHelpContext();
    const { id: orderedCartId } = { ...orderData?.getOrderedCart };
    const bagId = orderNumber ?? (isConfirmationPage ? orderedCartId : id);
    const textPrefill = encodeURIComponent(t('cart.need.help.text.prefill', { bagId }));
    const { isStar2 } = useStar2();

    return (
      <div ref={ref as RefObject<HTMLDivElement>} className={className}>
        <p>
          <Trans
            i18nKey="cart.need.help.body"
            t={t}
            values={{
              bagId,
            }}
          >
            Contact us with your bag ID <S.BagID data-testid="cartId">(bagId)</S.BagID> and we can answer any questions:
          </Trans>
        </p>
        {isStar2 ? (
          <NeedHelpSectionCheckout
            emailLink={needHelpData?.emailLink}
            phoneLink={needHelpData?.phoneLink}
            contactUsLink={needHelpData?.contactUsLink}
            showContactUsLink={needHelpData?.showContactUsLink}
          />
        ) : (
          <S.Contacts>
            {t('cart.need.help.whatsapp.number', { defaultValue: false }) ? (
              <li>
                <a
                  href={`https://wa.me/${t('cart.need.help.whatsapp.number')}/?text=${textPrefill}`}
                  target="_blank"
                  rel="noreferrer"
                  data-testid="whatsappLink"
                >
                  <S.IconContainer>
                    <WhatsApp />
                  </S.IconContainer>
                  {t('cart.need.help.whatsapp')}
                </a>
              </li>
            ) : null}
            <li>
              <LinkPartial internalLink="/contact-us">
                <S.IconContainer>
                  <CallCenter />
                </S.IconContainer>
                {t('cart.need.help.contactUs')}
              </LinkPartial>
            </li>
          </S.Contacts>
        )}
      </div>
    );
  },
);
