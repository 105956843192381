import * as yup from 'yup';
import { EMAIL_REGEX } from './register';

const GIFTCARD_REGEX = /^[a-zA-Z0-9\s'\-.]{2,40}$/;

export const GiftCardMessageSchema = (isEgiftCard: boolean) =>
  yup.object({
    to: yup
      .string()
      .notRequired()
      .matches(GIFTCARD_REGEX, { message: 'pdp.giftcard.validation.to', excludeEmptyString: true }),
    from: yup
      .string()
      .notRequired()
      .matches(GIFTCARD_REGEX, { message: 'pdp.giftcard.validation.from', excludeEmptyString: true }),
    message: yup.string().optional(),
    ...(isEgiftCard
      ? {
          email: yup
            .string()
            .required('pdp.giftcard.validation.email')
            .matches(EMAIL_REGEX, 'pdp.giftcard.validation.email')
            .max(128),
        }
      : null),
  });
