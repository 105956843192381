import type { RedirectInformation } from '../../codegen/types';
import type {
  GetRedirectInfoDataQuery,
  GetRedirectInfoDataQueryVariables,
} from '../../graphqlDocument/redirect/redirectInfoData.generated';
import { GetRedirectInfoDataDocument } from '../../graphqlDocument/redirect/redirectInfoData.generated';
import { logger } from '../../utils/logger';
import { withNewRelic } from '../newrelic';
import type { ApolloClientInstance } from './apolloClient';

type RedirectInfoPayload = {
  redirectInfo: RedirectInformation | null | undefined;
  redirectInfoRecord: RedirectInformation | null | undefined;
  errors: unknown;
};

async function getRedirectInfoBase(
  apolloClient: ApolloClientInstance,
  { country, locale, currentSite }: { country: string; locale?: string; currentSite: string },
): Promise<RedirectInfoPayload> {
  logger.info({ country, locale, currentSite }, 'Redirect info inputs');

  try {
    const lowercaseLocale = (locale || '').toLowerCase();
    const isEN = locale === 'en';
    const currentLocale = isEN ? '' : lowercaseLocale;

    const redirectInfoData = await apolloClient.query<GetRedirectInfoDataQuery, GetRedirectInfoDataQueryVariables>({
      query: GetRedirectInfoDataDocument,
      variables: {
        country,
        currentLocale,
        currentSite,
      },
    });
    const {
      data: { redirectInfo, redirectInfoRecord },
    } = redirectInfoData;

    return {
      redirectInfo,
      redirectInfoRecord,
      errors: undefined,
    };
  } catch (e) {
    return {
      redirectInfo: null,
      redirectInfoRecord: null,
      errors: e,
    };
  }
}

export const getRedirectInfo = withNewRelic('getRedirectInfo', getRedirectInfoBase);
