import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Content, Close as DialogClose } from '@radix-ui/react-dialog';
import type { DialogContentProps as RadixContent } from '@radix-ui/react-dialog';
import { Close } from '../../icons';
import {
  colours,
  fadeInAnimation,
  fadeOutAnimation,
  hoverUp,
  media,
  pulseInAnimation,
  spacing,
  zNeedHelpModalContent,
} from '../../stylings';

const S = {
  DialogContent: styled(Content)<{ $isStatusModal: boolean | undefined }>`
    background-color: ${colours.WHITE};
    left: 50%;
    padding: ${spacing.S};
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    width: 342px;
    z-index: ${zNeedHelpModalContent};
    &[data-state='open'] {
      ${({ $isStatusModal }) => ($isStatusModal ? pulseInAnimation : fadeInAnimation)}
    }
    &[data-state='closed'] {
      ${fadeOutAnimation}
    }
    @media ${media.greaterThan('lg')} {
      padding: ${spacing.L};
    }
  `,
  CloseButtonWrapper: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  `,
  CloseButton: styled(DialogClose)`
    background-color: transparent;
    border: 0;
    ${hoverUp()}
  `,
};

type DialogContentProps = RadixContent & {
  children: ReactNode;
  className?: string;
};

export const DialogContent = forwardRef<
  HTMLDivElement,
  DialogContentProps & { isStatusModal?: boolean; isWithCloseButton?: boolean }
>(({ children, className, isStatusModal, isWithCloseButton = true, ...rest }, ref) => {
  const { t } = useTranslation(['lib-global-common']);

  return (
    <S.DialogContent $isStatusModal={isStatusModal} className={className} ref={ref} {...rest}>
      {isWithCloseButton && (
        <S.CloseButtonWrapper>
          <S.CloseButton
            data-testid="closeButton"
            id="close-need-help-modal"
            type="button"
            aria-label={t('cart.need.help.modal.close')}
          >
            <Close height="10" width="10" viewBox="0 0.5 18.85 18.5" aria-labelledby="close-need-help-modal" />
          </S.CloseButton>
        </S.CloseButtonWrapper>
      )}
      {children}
    </S.DialogContent>
  );
});
