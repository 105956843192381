import type { Page404LayoutSlot } from '@amplience/content-types/typings/s-page-404-layout';
import { getSlotLayout } from './amplience/getSlotLayout';

const PAGE_404_LAYOUT_SLOT = 'https://clarks.com/slot/s-page-404-layout.json';

export const get404PageData = async (locale?: string) => {
  const page404Layout = (await getSlotLayout(locale as string, PAGE_404_LAYOUT_SLOT)) as Page404LayoutSlot;

  return page404Layout;
};
