import styled from 'styled-components';
import { DialogContent, DialogOverlay } from '../../baseComponents';
import { LinkPartial } from '../../partials';
import { TypographyStyles, colours, fonts, media, spacing, zMobileHamburgerMenu } from '../../stylings';
import { StateModalResult } from '../../types';

export const DialogOverlayComponent = styled(DialogOverlay)`
  background-color: rgba(0, 0, 0, 0.4);
  inset: 0;
  position: fixed;
  z-index: ${zMobileHamburgerMenu};
`;

export const DialogContentWrapper = styled(DialogContent)<{ $result: StateModalResult }>`
  align-items: center;
  background-color: ${({ $result }) => {
    switch ($result) {
      case StateModalResult.ERROR:
        return colours.ERROR_SECONDARY;
      case StateModalResult.SUCCESS:
        return colours.SUCCESS_SECONDARY;
      case StateModalResult.WARNING:
      default:
        return colours.WARNING_SECONDARY;
    }
  }};
  color: ${colours.BLACK};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 195px;
  min-width: 337px;
  overflow: hidden;
  z-index: ${zMobileHamburgerMenu};
`;

export const TextInfo = styled.span`
  ${fonts.Montserrat}
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  text-align: center;
  width: 100%;
`;

export const IconWrapper = styled.div<{ $result: StateModalResult }>`
  margin: ${spacing.XS};

  svg {
    transform: ${({ $result }) => ($result !== StateModalResult.SUCCESS ? 'scale(1.6)' : 'none')};

    path {
      fill: ${colours.BLACK};
    }
  }
`;

export const LinkPartialCartWrapper = styled.div`
  margin-top: ${spacing.M};
`;

export const LinkPartialCart = styled(LinkPartial)`
  ${TypographyStyles.Headings.H4}
  align-items: center;
  background-color: ${colours.DARK_BLUE};
  color: ${colours.WHITE};
  display: flex;
  height: 32px;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 64px;

  &:focus-visible {
    outline-color: ${colours.WHITE};
  }
  @media ${media.greaterThan('lg')} {
    height: 40px;
    width: 72px;
  }
`;

export const ButtonContainer = styled.div`
  width: 85%;

  & button {
    margin-top: 2rem;
    width: 100%;
  }
`;
