import type { AltTextTextualDescriptionOfTheImage } from '@amplience/content-types/typings/c-footer-icons-block';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

export function sendSocialLinkEvent(linkText: AltTextTextualDescriptionOfTheImage, linkUrl: string) {
  sendDataLayerEvent(EventNamesType.social_link, {
    custom_data: {
      component_header: 'Follow us',
      component_type: 'footer - social link',
      link_text: linkText,
      link_url: linkUrl,
    },
  });
}
