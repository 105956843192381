import { useTranslation } from 'next-i18next';
import { GiftCardType } from '../context';

export function useItemDetailsHelpers() {
  const { t } = useTranslation(['lib-global-common']);

  function isGiftCard(productTypeKey: string | undefined | null) {
    if (!productTypeKey) return false;

    return productTypeKey === GiftCardType.EGIFT_CARD || productTypeKey === GiftCardType.GIFT_CARD;
  }

  const getItemType = (label: string): 'email' | 'quantity' | 'message' | 'default' => {
    if (label === t('cart.line.item.message')) return 'message';
    if (label === t('cart.line.item.quantity')) return 'quantity';
    if (label === t('cart.line.item.send.to')) return 'email';

    return 'default';
  };

  return {
    isGiftCard,
    getItemType,
  };
}
