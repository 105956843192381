import styled, { css } from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../../stylings';

export const Container = styled.div`
  ${TypographyStyles.Body.Small.Regular}
  display: flex;
  flex-direction: column;
  gap: ${spacing.XXS};
`;

export const Label = styled.div`
  display: flex;
  justify-content: space-between;

  &.hide-label {
    display: none;
  }

  &.total-amount {
    border-top: 1px solid ${colours.BLACK};

    & span {
      margin-top: ${spacing.XXS};
    }
  }

  &.gift-card-total {
    align-items: center;
    background-color: ${colours.TINT_BLUE};
    display: flex;
    justify-content: space-between;
    padding: ${spacing.XXXXS};
  }
`;

export const Total = styled.span`
  ${TypographyStyles.Body.Medium.SemiBold}
`;

export const Amount = styled.span<{ $isTotal: boolean; $isDiscount: boolean }>`
  ${({ $isTotal }) => ($isTotal ? TypographyStyles.Body.Medium.SemiBold : TypographyStyles.Summary.SubTotal)}

  ${({ $isDiscount }) =>
    $isDiscount &&
    css`
      color: ${colours.DISCOUNT_RED};

      ::before {
        content: '-';
      }
    `}
`;

export const VatLabel = styled.span`
  ${TypographyStyles.Body.Tiny.Regular}
`;

export const TrashButton = styled.button`
  background-color: transparent;
  border: none;
`;
