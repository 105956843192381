import { useState } from 'react';
import type { ChangeEvent } from 'react';
import type { ApolloError } from '@apollo/client';
import { MarketingChannel } from '../codegen/types';
import { useFeatureFlagsContext } from '../context/featureFlagsContext';
import { useLocaleContext } from '../context/localeContext';
import { useStaticContext } from '../context/staticContext';
import { useUpdateCustomerMarketingPreferencesMutation } from '../graphqlDocument/marketingPreferences/updateCustomerMarketingPreferences.generated';
import type { BffError } from '../services/errors';
import type { MarketingCategory, MarketingChoice } from '../types/marketingPreferences';
import { grecaptcha } from '../utils/grecaptcha';
import { sendSubscribeToNewsletterEvent } from '../utils/gtm/events/subscribe_to_newsletter/sendSubscribeToNewsletterEvent';
import { Locale } from '../utils/localeHelper';
import { useLoadingCursor } from './useLoadingCursor';

export const useNewsletter = () => {
  const { locale } = useLocaleContext();
  const { marketingPreferences } = useStaticContext();
  const [checkboxError, setCheckboxError] = useState(false);
  const { isAuthRecaptchaEnabled } = useFeatureFlagsContext();
  const [recaptchaError, setRecaptchaError] = useState<string | null>();
  const [languageSelectionError, setLanguageSelectionError] = useState(false);
  const defaultCheckboxesState =
    marketingPreferences?.marketingPreferences &&
    Object.entries(marketingPreferences?.marketingPreferences as MarketingCategory).map(([, item]) => ({
      key: item.key,
      optIn: false,
    }));
  const [marketingChoices, setMarketingChoices] = useState<MarketingChoice>({ ...defaultCheckboxesState });
  const [languageChoice, setLanguageChoice] = useState(
    // we use original nextjs locale here, in backend we extract country and language from it
    marketingPreferences && marketingPreferences?.languages.length > 1 ? '' : locale,
  );
  const [updateCustomerMarketingPreferences, { data: newsletterData, error, called: isCalled, loading }] =
    useUpdateCustomerMarketingPreferencesMutation();

  useLoadingCursor(loading);

  const onSubmit = async ({ email }: { email: string }) => {
    const isCheckboxError = marketingChoices && !Object.entries(marketingChoices).some(([, item]) => item.optIn);
    const isLanguageSelectionError =
      marketingPreferences && marketingPreferences.languages.length > 1 && languageChoice === '';
    setCheckboxError(isCheckboxError);
    setLanguageSelectionError(!!isLanguageSelectionError);
    setRecaptchaError('');
    if (isCheckboxError || isLanguageSelectionError) {
      return;
    }
    let recaptchaToken;
    if (isAuthRecaptchaEnabled) {
      recaptchaToken = await grecaptcha();
    }
    const preferences = Object.entries(marketingChoices).map(([, item]) => item);

    try {
      await updateCustomerMarketingPreferences({
        variables: {
          input: {
            email,
            preferences,
            locale:
              languageChoice === Locale.STOREFRONT_OUTLET_LOCALE ? Locale.CT_OUTLET_LOCALE : locale || Locale.EN_GB,
            channel: locale === Locale.STOREFRONT_OUTLET_LOCALE ? MarketingChannel.OUTLET : MarketingChannel.FULLPRICE,
          },
        },
        context: {
          headers: isAuthRecaptchaEnabled ? { 'x-recaptcha-token': recaptchaToken } : {},
        },
        onCompleted: () => {
          sendSubscribeToNewsletterEvent();
        },
      });
    } catch (e: unknown) {
      const gqlError = (e as ApolloError).graphQLErrors[0] as unknown as BffError;
      setRecaptchaError(gqlError.errorType);
    }
  };

  const onChange = ({ target: { name, checked, value } }: ChangeEvent<HTMLInputElement>) => {
    if (name === 'language') {
      setLanguageChoice(value);
    } else {
      setMarketingChoices((prev) => ({
        ...prev,
        [name]: {
          key: (prev as MarketingChoice)[+name].key,
          optIn: checked,
        },
      }));
    }
  };

  return {
    onChange,
    onSubmit,
    newsletterData,
    isCalled,
    checkboxError,
    error,
    recaptchaError,
    marketingPreferences,
    marketingChoices,
    locale,
    languageSelectionError,
    loading,
  };
};
