import { Store } from '../ct';
import { localiseProperty } from './index';

const searchQueryListNotLocalised = ['categories.key', 'size', 'price.centAmount'];

export const searchQueryListToLocalise = [
  'productType',
  'productSubType',
  'categories.name',
  'color',
  'brand',
  'technologies',
  'fastening',
  'styleName',
  'upperMaterial',
  'gender',
  'review.rating',
  'heelHeight.groups',
  'fit',
];

export const productSearchQueryAllowedList = (stores: Store[]): string[] => {
  return [
    ...searchQueryListNotLocalised,
    ...searchQueryListToLocalise.flatMap((property) => [
      ...stores.flatMap((store) => [...localiseProperty(property, store)]),
    ]),
  ];
};

export const productFacetAllowedList = (stores: Store[]): string[] => {
  return [
    'size',
    'price.centAmount',
    ...searchQueryListToLocalise.flatMap((property) => [
      ...stores.flatMap((store) => [...localiseProperty(property, store)]),
    ]),
  ];
};
