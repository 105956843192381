import styled from 'styled-components';
import { WhiteButton } from '../../../baseComponents';
import { TypographyStyles, colours, hoverUp, media, spacing } from '../../../stylings';

export const Container = styled.menu`
  display: none;
  margin: 0;
  padding: 0px;
  width: 302px;
  @media ${media.greaterThan('lg')} {
    display: initial;
  }
`;

export const FilterButton = styled.button`
  align-items: center;
  background-color: ${colours.WHITE};
  border: 1px solid ${colours.GREY};
  color: ${colours.BLACK};
  display: flex;
  height: 48px;
  justify-content: space-between;
  max-width: 160px;
  padding: ${spacing.XXS} ${spacing.XS};
  width: 100%;
  ${TypographyStyles.Body.Medium.SemiBold} @media ${media.greaterThan('lg')} {
    display: none;
  }
`;

export const FilterMenu = styled.menu`
  align-items: center;
  background-color: ${colours.WHITE};
  display: flex;
  flex-direction: column;
  margin: 0;
  min-width: 310px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${spacing.L} ${spacing.S};
`;

export const Header = styled.span`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
  width: 268px;
  h4 {
    ${TypographyStyles.Headings.H4}
    font-weight: 600;
    height: 18px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    width: 232px;
  }
`;

export const CloseButton = styled.button`
  background-color: inherit;
  border: none;

  ${hoverUp()}

  svg {
    height: 10px;
    width: 10px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex: 1 1 160px;
  justify-content: flex-end;
  padding: ${spacing.XXS} 0px 10px;
`;

export const Divider = styled.hr`
  margin: 0;
  padding: 0;
  width: 262px;
`;

export const ApplyButton = styled(WhiteButton)`
  bottom: 0;
  margin-bottom: 5px;
  position: absolute;
  width: 262px;
`;

export const FacetContainer = styled.div`
  margin-bottom: 20px;
`;

export const FacetsContainer = styled.ul`
  margin: 0;
  padding: 0;
`;
