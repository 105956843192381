import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetStaticContextDataQueryVariables = Types.Exact<{
  locale: Types.Scalars['String'];
  input: Types.GetStoreInput;
}>;

export type GetStaticContextDataQuery = {
  __typename?: 'Query';
  paymentData: {
    __typename?: 'PaymentMethodsResponse';
    paymentMethods: Array<{ __typename?: 'PaymentMethod'; label: string; key: string }>;
  };
  marketingPreferences: {
    __typename?: 'MarketingPreferencesResponse';
    marketingPreferenceDefaultEnabled: boolean;
    languages: Array<string>;
    marketingPreferences: Array<{ __typename?: 'MarketingPreferences'; key: string; label: string }>;
  };
  getStore?: {
    __typename?: 'Store';
    collectionShippingEnabled?: boolean | null;
    id: string;
    key: string;
    skuCap?: number | null;
    productQuantityCap?: number | null;
    basketValueCap?: number | null;
    basketValueCapAtPeak?: number | null;
    basketValueCapAtPeakFrom?: string | null;
    basketValueCapAtPeakUntil?: string | null;
    billingCountries?: Array<string | null> | null;
    shippingCountries?: Array<string | null> | null;
    pudoEnabled?: boolean | null;
  } | null;
  siteSwitch: Array<{
    __typename?: 'SiteSwitchResponse';
    key: string;
    languages: Array<string>;
    currency: string;
    currencySymbol: string;
    countryName: Array<{ __typename?: 'StoreLocalizedString'; locale: string; value: string }>;
  }>;
};

export const GetStaticContextDataDocument = gql`
  query GetStaticContextData($locale: String!, $input: GetStoreInput!) {
    paymentData(locale: $locale) {
      paymentMethods {
        label
        key
      }
    }
    marketingPreferences(locale: $locale) {
      marketingPreferences {
        key
        label
      }
      marketingPreferenceDefaultEnabled
      languages
    }
    getStore(input: $input) {
      collectionShippingEnabled
      id
      key
      skuCap
      productQuantityCap
      basketValueCap
      basketValueCapAtPeak
      basketValueCapAtPeakFrom
      basketValueCapAtPeakUntil
      billingCountries
      shippingCountries
      pudoEnabled
    }
    siteSwitch {
      key
      languages
      currency
      currencySymbol
      countryName {
        locale
        value
      }
    }
  }
`;

/**
 * __useGetStaticContextDataQuery__
 *
 * To run a query within a React component, call `useGetStaticContextDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticContextDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticContextDataQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStaticContextDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetStaticContextDataQuery, GetStaticContextDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStaticContextDataQuery, GetStaticContextDataQueryVariables>(
    GetStaticContextDataDocument,
    options,
  );
}
export function useGetStaticContextDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStaticContextDataQuery, GetStaticContextDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStaticContextDataQuery, GetStaticContextDataQueryVariables>(
    GetStaticContextDataDocument,
    options,
  );
}
export type GetStaticContextDataQueryHookResult = ReturnType<typeof useGetStaticContextDataQuery>;
export type GetStaticContextDataLazyQueryHookResult = ReturnType<typeof useGetStaticContextDataLazyQuery>;
export type GetStaticContextDataQueryResult = Apollo.QueryResult<
  GetStaticContextDataQuery,
  GetStaticContextDataQueryVariables
>;
