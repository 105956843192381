import { getAlgoliaProductIndex } from '../services/algolia/searchClient';
import { Locale, getCtLocale } from './localeHelper';

enum SortByOptions {
  PRODUCT_ASC = 'PRODUCT_ASC',
  PRODUCT_DESC = 'PRODUCT_DESC',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC',
  RATINGS = 'RATINGS',
  NEWEST = 'NEWEST',
}

const createSortIndexName = ({ locale, sortBy }: { locale?: string; sortBy?: SortByOptions }) => {
  const ctLocale = getCtLocale(locale);
  const initialIndex = getAlgoliaProductIndex(locale || Locale.DEFAULT_LOCALE);

  switch (sortBy) {
    case SortByOptions.PRICE_ASC: {
      return `${initialIndex}_price_asc`;
    }
    case SortByOptions.PRICE_DESC: {
      return `${initialIndex}_price_desc`;
    }
    case SortByOptions.PRODUCT_ASC: {
      return `${initialIndex}_name.${ctLocale}_asc`;
    }
    case SortByOptions.PRODUCT_DESC: {
      return `${initialIndex}_name.${ctLocale}_desc`;
    }
    case SortByOptions.NEWEST: {
      return `${initialIndex}_availability_desc`;
    }
    case SortByOptions.RATINGS: {
      return `${initialIndex}_review.avgRating_desc`;
    }
    default: {
      return initialIndex;
    }
  }
};

export const createSortByItems = (locale?: string) => [
  {
    value: createSortIndexName({ locale }),
    label: 'sort.by.menu.sort.relevance',
  },
  {
    value: createSortIndexName({ locale, sortBy: SortByOptions.NEWEST }),
    label: 'sort.by.menu.sort.newest',
  },
  {
    value: createSortIndexName({ locale, sortBy: SortByOptions.RATINGS }),
    label: 'sort.by.menu.sort.ratings',
  },
  {
    value: createSortIndexName({ locale, sortBy: SortByOptions.PRODUCT_ASC }),
    label: 'sort.by.menu.sort.name.asc',
  },
  {
    value: createSortIndexName({ locale, sortBy: SortByOptions.PRODUCT_DESC }),
    label: 'sort.by.menu.sort.name.desc',
  },
  {
    value: createSortIndexName({ locale, sortBy: SortByOptions.PRICE_DESC }),
    label: 'sort.by.menu.sort.price.desc',
  },
  {
    value: createSortIndexName({ locale, sortBy: SortByOptions.PRICE_ASC }),
    label: 'sort.by.menu.sort.price.asc',
  },
];
