import type { KeyboardEvent } from 'react';
import { useTranslation } from 'next-i18next';
import { useLocaleContext } from '../../context/localeContext';
import { useStar2 } from '../../hooks';
import type { StoreItemProps } from '../../types';
import { getFormattedAddress } from '../../utils/address';
import { getLocaleDistance, getLocaleDistanceUnits } from '../../utils/measurementHelper';
import { StoreStatusComponent } from '../storeStatus/StoreStatus';
import {
  Distance,
  Features,
  InfoSection,
  Name,
  OpeningHoursList,
  OpeningHoursListItem,
  ScheduleTitle,
  StoreHolder,
  StoreInfo,
  StoreSchedule,
} from './StoreItem.styles';
import type { FormattedScheduleType } from './utils';
import { formatOpeningHours, useFormattedSchedule } from './utils';

export function StoreItem({
  id,
  displayName,
  address,
  distance,
  openingHours,
  setActiveStoreId,
  isActiveStore,
  actionBtn,
  timeSlotsComponent,
  storeTags,
  showOpeningHours = true,
  isColumnView,
  isScheduleGrouped,
  setZoomedStore,
  timezone,
}: StoreItemProps) {
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common', 'lib-products-common']);
  const { isStar2 } = useStar2();

  const formattedSchedule: FormattedScheduleType[] = useFormattedSchedule(openingHours, isScheduleGrouped || false);

  const handleStoreItemClick = (storeId: string) => {
    setActiveStoreId?.((prevActiveStoreId) => (prevActiveStoreId === storeId ? '' : storeId));
    setZoomedStore?.(id);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if ((event.target as HTMLElement).tagName === 'A') return;
    if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
      event.preventDefault();
      handleStoreItemClick(id);
    }
  };

  return (
    <StoreHolder
      onClick={() => handleStoreItemClick(id)}
      $isActiveStore={isActiveStore}
      $isClickable={!!setActiveStoreId}
      tabIndex={setActiveStoreId ? 0 : -1}
      onKeyDown={handleKeyDown}
      data-testid="storeHolder"
    >
      <StoreInfo data-testid="storeInfo">
        <div>
          <Name data-testid="storeTitle">{displayName}</Name>
          <Distance data-testid="storeDistance">
            {getLocaleDistance(distance, locale)} {getLocaleDistanceUnits(locale)}
          </Distance>
        </div>
        <InfoSection $isColumn={isColumnView} data-testid="storeAddress">
          {address && <span>{getFormattedAddress(address, locale)}</span>}
          <Features>{storeTags}</Features>
        </InfoSection>
      </StoreInfo>
      {isActiveStore && (
        <>
          {showOpeningHours && (
            <ScheduleTitle data-testid="storeScheduleTitle">
              {`${t('pdp.store.hours')}`}
              <StoreStatusComponent openingHours={openingHours} timezone={timezone} />:
            </ScheduleTitle>
          )}
          <StoreSchedule data-testid="storeSchedule" $isStar2Locale={!!isStar2}>
            {showOpeningHours && (
              <div>
                <OpeningHoursList data-testid="storeOpenHoursList">
                  {formattedSchedule.map((schedule) => (
                    <OpeningHoursListItem data-testid="storeOpenHoursItem" key={schedule.startDay}>
                      {formatOpeningHours(schedule, t)}
                    </OpeningHoursListItem>
                  ))}
                </OpeningHoursList>
              </div>
            )}
            {actionBtn}
          </StoreSchedule>
        </>
      )}
      {isActiveStore && timeSlotsComponent}
    </StoreHolder>
  );
}
