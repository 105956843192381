import type { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { WhiteButton } from '../../baseComponents/button';
import { OrSeparator } from '../../baseComponents/separator/OrSeparator';
import type { SocialProvider } from '../../codegen/types';
import { useSocialLogin } from '../../hooks/useSocialLogin';
import { spacing } from '../../stylings';

const S = {
  Container: styled.section`
    margin: 0;
  `,
  Button: styled(WhiteButton)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: ${spacing.XS};
    width: 100%;
  `,
  Label: styled.span`
    margin-left: ${spacing.XXS};
  `,
};

type SocialLoginsProps = {
  socials: {
    label: string;
    element: ReactNode;
    provider: SocialProvider;
  }[];
  isFromLogin?: boolean;
};
export function SocialLogins({ socials, isFromLogin = false }: SocialLoginsProps) {
  const { t } = useTranslation('lib-global-common');
  const { generateSocialLoginURL } = useSocialLogin(isFromLogin);
  const handleOnClick = (provider: SocialProvider) => () => generateSocialLoginURL(provider);

  return (
    <S.Container>
      <OrSeparator />
      {socials.map(({ label, element, provider }) => {
        const iconLabel = t(label);
        return (
          <S.Button aria-label={iconLabel} key={iconLabel} type="button" onClick={handleOnClick(provider)}>
            {element}
            <S.Label>{iconLabel}</S.Label>
          </S.Button>
        );
      })}
    </S.Container>
  );
}
