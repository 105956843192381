import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type NavigationType = {
  component_header?: string;
  component_type: string;
  link_text: string;
};

export function sendNavigationEvent(navigationData: NavigationType) {
  sendDataLayerEvent(EventNamesType.navigation, {
    custom_data: { ...navigationData },
  });
}
