import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { EmptyBasketState as EmptyBasketSchema } from '@amplience/content-types/typings/c-empty-basket';
import { WhiteButton } from '../../../baseComponents/button';
import { useLocaleContext } from '../../../context/localeContext';
import { useOverlayContext } from '../../../context/overlayContext';
import { FlexibleTextPartial } from '../../../partials/flexibleText';
import { TypographyStyles, TypographyStylesType, colours, media, spacing } from '../../../stylings';
import { createAnchorTagHref, getLocalizedValue } from '../../../utils/transformers';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    max-width: 857px;

    @media ${media.greaterThan('lg')} {
      gap: ${spacing.M};

      &.mini-cart-empty-basket {
        gap: ${spacing.XS};
      }
    }
  `,
  TextBlock: styled.div`
    background-color: ${colours.LIGHT_GREY_2};
    padding: 20px;
  `,
  Title: styled(FlexibleTextPartial)`
    display: block;
    margin: 0;
    margin-bottom: ${spacing.XXXS};
  `,
  Paragraph: styled(FlexibleTextPartial)`
    display: block;
    margin: 0;
  `,
  Button: styled(WhiteButton)`
    ${TypographyStyles.Button.SemiBold}
    align-items: center;
    background-color: transparent;
    border: 1px solid ${colours.DARK_BLUE};
    color: ${colours.DARK_BLUE};
    display: flex;
    height: 48px;
    justify-content: center;
    max-width: 375px;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
  `,
};

export type EmptyBasketProps = EmptyBasketSchema & {
  className?: string;
};

export function EmptyBasket({ title, copyBlock, buttonText, className }: EmptyBasketProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { isMiniCartOpen, setIsMiniCartOpen } = useOverlayContext();
  const { locale } = useLocaleContext();
  const router = useRouter();

  const onContinueShopping = () => {
    const goToHomepage = window.history.length > 1 && document.referrer.includes('checkout');

    if (isMiniCartOpen) {
      setIsMiniCartOpen(false);
      return;
    }

    if (goToHomepage) {
      router.push({ pathname: createAnchorTagHref({ locale, path: '/' }) });
      return;
    }

    router.back();
  };

  return (
    <S.Wrapper className={className}>
      <S.TextBlock data-testid="emptyCartText" role="status">
        <S.Title {...title} defaultTextSize={TypographyStylesType.BODY_SMALL_SEMIBOLD} defaultTextColor={colours.BLACK}>
          {getLocalizedValue(title?.text) ?? t('cart.empty.title')}
        </S.Title>
        {copyBlock?.text && (
          <S.Paragraph
            {...copyBlock}
            defaultTextSize={TypographyStylesType.BODY_SMALL_REGULAR}
            defaultTextColor={colours.BLACK}
          >
            {getLocalizedValue(copyBlock?.text)}
          </S.Paragraph>
        )}
      </S.TextBlock>
      <S.Button type="button" onClick={onContinueShopping}>
        {getLocalizedValue(buttonText) ?? t('cart.continue.shopping')}
      </S.Button>
    </S.Wrapper>
  );
}
