import aa from 'search-insights';
import { checkConsent } from '../../utils/checkConsent';
import { APPLICATION_ID, SEARCH_API_KEY } from './searchClient';

export const initInsights = () =>
  aa('init', {
    appId: APPLICATION_ID,
    apiKey: SEARCH_API_KEY,
    useCookie: checkConsent(),
    cookieDuration: 24 * 60 * 60 * 1000, // sets the cookie expiration to 24h for guest users
  });

export const setInsightsUserToken = (token: string) => {
  aa('setUserToken', token);
};

export const getUserToken = async () => {
  try {
    const insightsToken = await new Promise((resolve, reject) => {
      aa('getUserToken', {}, (err, userToken) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(userToken);
      });
    });

    return insightsToken;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const sendClickAfterSearchEvent = async (
  eventName: string,
  index: string,
  queryID: string,
  objectID: string,
  positions: number,
) => {
  const hasConsent = checkConsent();
  if (!hasConsent) return;
  const userToken = await getUserToken();
  aa('clickedObjectIDsAfterSearch', {
    userToken: userToken as string,
    eventName,
    index,
    queryID,
    objectIDs: [objectID],
    positions: [positions],
  });
};

export const sendConvertedAfterSearchEvent = async (
  eventName: string,
  index: string,
  queryID: string,
  objectID: string,
) => {
  const hasConsent = checkConsent();
  if (!hasConsent) return;
  const userToken = await getUserToken();
  aa('convertedObjectIDsAfterSearch', {
    userToken: userToken as string,
    eventName,
    index,
    objectIDs: [objectID],
    queryID,
  });
};

export const sendConvertedEvent = async (eventName: string, index: string, objectID: string) => {
  const hasConsent = checkConsent();
  if (!hasConsent) return;
  const userToken = await getUserToken();
  aa('convertedObjectIDs', {
    userToken: userToken as string,
    eventName,
    index,
    objectIDs: [objectID],
  });
};
