import type { MouseEvent } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import { A11y, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { MediaGridCarousel as MediaCarouselSchema } from '@amplience/content-types/typings/c-media-grid-carousel';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { ArrowTailLeft, ArrowTailRight } from '../../icons';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import {
  TypographyStylesType,
  colours,
  maxWidthPartial,
  media,
  slideToRightArrowsAnimation,
  spacing,
} from '../../stylings';
import { sendSelectContentEvent } from '../../utils/gtm/events/select_content/sendSelectContentEvent';
import { getLocalizedValue } from '../../utils/transformers';
import { MediaGridItem } from './MediaGridItem';

export type MediaCarouselProps = MediaCarouselSchema;

const S = {
  CarouselContainer: styled.div<{ $bgColor?: string }>`
    background-color: ${({ $bgColor }) => $bgColor};
    padding: ${spacing.S} 0 ${spacing.S} ${spacing.S};

    @media ${media.greaterThan('xl')} {
      padding-left: 70px;
    }

    @media ${media.greaterThan('xx')} {
      ${maxWidthPartial({ maxWidth: 1300, withPadding: false })}
      padding-left: ${spacing.S};
    }
  `,
  TitleContainer: styled.div`
    align-items: center;
    display: flex;

    .products-count {
      font-size: 14px;
      margin-left: ${spacing.XXXS};

      @media ${media.greaterThan('lg')} {
        font-size: ${spacing.XS};
        margin-left: 10px;
      }
    }
  `,
  CarouselTitle: styled(FlexibleTextPartial)`
    margin: 0;
  `,
  SwiperContainer: styled(Swiper)`
    display: flex;
    flex-direction: row;

    .swiper-wrapper {
      height: auto;
      width: auto;
    }
  `,
  SwiperSlideContainer: styled(SwiperSlide)``,
  TopContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 0;

    @media ${media.greaterThan('lg')} {
      margin-bottom: ${spacing.M};
    }

    .product-icons {
      display: none;

      @media ${media.greaterThan('lg')} {
        display: flex;
      }
    }
  `,
  BottomContainer: styled.div<{ $lineColor?: string }>`
    align-items: center;
    display: flex;
    margin: 23px 27px 0 0;
    min-height: 25px;
    padding-right: ${spacing.S};
    pointer-events: none;
    width: 100%;

    @media ${media.greaterThan('lg')} {
      justify-content: space-between;
      margin: 34px ${spacing.XXXL} 0 0;
    }

    @media ${media.greaterThan('xl')} {
      padding-right: 70px;
    }

    @media ${media.greaterThan('xx')} {
      padding-right: 0;
    }

    &.hide-container {
      display: none;
    }

    .line {
      border: 1px solid ${({ $lineColor }) => $lineColor};
      margin: 0;
      width: 100%;
    }
  `,
  IconContainer: styled.div<{ $showIcons: boolean }>`
    align-items: center;
    display: ${({ $showIcons }) => ($showIcons ? 'flex' : 'none')};
    margin: 0 ${spacing.XS} 0 19px;
  `,
  IconButton: styled.button`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 2px solid ${colours.BLACK};
    border-radius: 50%;
    display: flex;
    height: 25px;
    justify-content: center;
    min-width: 25px;
    padding: 0;
    pointer-events: auto;
    ${slideToRightArrowsAnimation}

    &.media-swiper-button-disabled {
      border: 2px solid ${colours.MEDIUM_GREY};
      cursor: not-allowed;

      .arrow-icon {
        path {
          fill: ${colours.MEDIUM_GREY};
        }
      }
    }

    svg {
      height: 10px;
      width: 10px;
    }

    &.nav-prev {
      margin-right: 23px;
    }
  `,
};

export function MediaCarousel({ mediaGridCarousel, title, index, bgColor, lineColor }: MediaCarouselProps) {
  const { t } = useTranslation(['lib-global-common']);
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const carouselLength = mediaGridCarousel?.length as number;
  const showCarouselArrows = isDesktop ? carouselLength > 3 : carouselLength > 2;

  if (mediaGridCarousel?.length === 0 || !mediaGridCarousel) {
    return null;
  }

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const element = event.currentTarget as HTMLAnchorElement;

    sendSelectContentEvent({
      component_type: 'Media Grid Item',
      component_header: getLocalizedValue(title?.text ?? '').trim(),
      link_url: element.href,
      link_text: element.textContent ?? '',
    });
  };

  return (
    <S.CarouselContainer $bgColor={bgColor}>
      <S.TopContainer>
        <S.TitleContainer>
          <S.CarouselTitle
            {...title}
            defaultTextSize={TypographyStylesType.HEADINGS_H3}
            defaultTextColor={colours.BLACK}
          />
        </S.TitleContainer>
      </S.TopContainer>
      <S.SwiperContainer
        modules={[Navigation, A11y]}
        navigation={{
          prevEl: `.media-grid-prev-${index}`,
          nextEl: `.media-grid-next-${index}`,
          disabledClass: 'media-swiper-button-disabled',
        }}
        a11y={{ slideRole: 'link' }}
        slidesPerView="auto"
        watchSlidesProgress
        spaceBetween={isDesktop ? 32 : 20}
      >
        <MediaGridItem mediaGridCarouselItems={mediaGridCarousel} handleClick={handleClick} />
      </S.SwiperContainer>
      <S.BottomContainer $lineColor={lineColor ?? colours.BLACK}>
        <span className="line" />
        <S.IconContainer $showIcons={showCarouselArrows}>
          <S.IconButton
            type="button"
            aria-label={t('media.carousel.aria.prev.button')}
            className={`nav-prev media-grid-prev-${index}`}
          >
            <ArrowTailRight className="arrow-icon" viewBox="5 4 6 14" />
          </S.IconButton>
          <S.IconButton
            type="button"
            aria-label={t('media.carousel.aria.next.button')}
            className={`media-grid-next-${index}`}
          >
            <ArrowTailLeft className="arrow-icon" viewBox="10 4 6 14" />
          </S.IconButton>
        </S.IconContainer>
      </S.BottomContainer>
    </S.CarouselContainer>
  );
}
