import { useCallback, useState } from 'react';
import { useResizeObserverRef } from 'rooks';

export function useResizeScrollSize(options: ResizeObserverOptions) {
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const callback = useCallback(([entry]: ResizeObserverEntry[]) => {
    if (entry) {
      setScrollHeight(entry.target.scrollHeight);
      setScrollWidth(entry.target.scrollWidth);
    }
  }, []);
  const [elementRef] = useResizeObserverRef(callback, options);
  return { elementRef, scrollHeight, scrollWidth };
}
