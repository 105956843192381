import * as yup from 'yup';
import { PASSWORD_REGEX, PHONE_FIELD_REGEXP, RegisterSchema } from './register';

export const UpdateCustomerSchema = RegisterSchema.shape({
  phone: yup
    .string()
    .required('register.validation.empty.phone')
    .matches(PHONE_FIELD_REGEXP, 'register.validation.phone'),
  password: yup.string().matches(PASSWORD_REGEX, 'register.validation.password'),
});
