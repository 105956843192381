import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSearchBox } from 'react-instantsearch';

export function VirtualSearchBox() {
  const router = useRouter();

  const { query, refine } = useSearchBox();

  // Workaround in place for the following scenario:
  // 1. Go to any page except SRP
  // 2. Search for anything
  // 3. Notice that SRP is displayed initially with correct results,
  //    then Algolia sends another search request with empty query,
  //    resulting in No Search Results UI to show in place of correct results.
  //    At this stage the states look like this:
  //        router.query.query -> contains search param that user searched for
  //        query -> empty
  //    Since query is empty, there are no search results thus the No Search
  //    Results UI. Algolia then attempts to sync these two, which results in router
  //    query to be deleted:
  //        router.query.query -> empty
  //        query -> empty
  // (Note:
  //    In some cases it is reversed, query is non-empty while router.query is empty.
  //    Just the same, after syncing these states they both become empty, leading to
  //    No Search Results UI
  // )
  //
  // Solution:
  // 1. Refine the search result by sending the router query
  //    OR push the query to router.
  //
  // Drawbacks:
  // 1. Algolia sends multiple search requests (empty and non-empty),
  //    until router.query and query are finally both synced.
  useEffect(() => {
    if (router.query.query && !query) {
      refine(router.query.query as string);
    } else if (!router.query.query && query) {
      router.push(
        {
          pathname: router.pathname,
          query: {
            query,
          },
        },
        undefined,
        {
          shallow: true,
        },
      );
    }
  }, [router, query, refine]);

  return null;
}
