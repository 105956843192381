import type { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import { useCartContext } from '../../../context/cartContext';
import { useLocaleContext } from '../../../context/localeContext';
import { useUserContext } from '../../../context/userContext';
import { useStar2 } from '../../../hooks/useStar2';
import { Lock } from '../../../icons';
import { TypographyStyles, colours, media, spacing, zMobileCheckoutSecurely } from '../../../stylings';
import { CartStorageKeys } from '../../../utils/cartUtils';
import { showProgressBar } from '../../../utils/progressBarStatus';
import { removeItem, setItem } from '../../../utils/storage';
import { createAnchorTagHref } from '../../../utils/transformers';

const S = {
  CheckoutButtonWrapper: styled.div`
    align-items: center;
    background-color: ${colours.WHITE};
    border-top: 1px solid ${colours.MID_GREY};
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    padding: ${spacing.XS} ${spacing.S};
    position: fixed;
    width: 100%;
    z-index: ${zMobileCheckoutSecurely};

    @media ${media.greaterThan('lg')} {
      background-color: transparent;
      border-top: 0;
      display: block;
      padding: 0;
      position: static;
    }
  `,
  CheckoutButton: styled.a<{ $isDisabled?: boolean; $noMaxWidth?: boolean }>`
    ${TypographyStyles.Button.SemiBold}
    align-items: center;
    background-color: ${({ $isDisabled }) => ($isDisabled ? colours.LIGHT_GREY_2 : colours.TURQUOISE)};
    border: ${({ $isDisabled }) => ($isDisabled ? `1px solid ${colours.MID_GREY}` : 0)};
    color: ${({ $isDisabled }) => ($isDisabled ? colours.GREY : colours.BLACK)};
    display: flex;
    gap: ${spacing.XXS};
    height: 100%;
    justify-content: center;
    min-height: 48px;
    pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    ${({ $noMaxWidth }) =>
      $noMaxWidth
        ? null
        : css`
            max-width: 328px;
          `}

    &:hover,&:focus {
      opacity: 0.9;
    }
  `,
  CheckoutButtonContainer: styled.div<{ $isDisabled?: boolean; $noMaxWidth?: boolean }>`
    cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
    width: 100%;

    ${({ $noMaxWidth }) =>
      $noMaxWidth
        ? null
        : css`
            max-width: 328px;
          `}

    @media ${media.greaterThan('lg')} {
      margin-top: ${spacing.XS};
    }
  `,
};

type CheckoutButtonProps = {
  currency: string;
  total: string;
  isDisabled?: boolean;
  noMaxWidth?: boolean;
};

export function CheckoutButtonWrapper({ children }: { children: ReactNode }) {
  return <S.CheckoutButtonWrapper>{children}</S.CheckoutButtonWrapper>;
}

export function CheckoutButton({ currency, total, isDisabled, noMaxWidth = false }: CheckoutButtonProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { checkoutSecurely, setCheckoutSecurely } = useCartContext();
  const { isUserLoggedIn } = useUserContext();
  const { locale } = useLocaleContext();
  const { isStar2 } = useStar2();

  const handleCheckoutSecurely = () => {
    if (!isUserLoggedIn) {
      setCheckoutSecurely(!checkoutSecurely);
    }
  };
  const handleLoggedInCheckoutSecurely = () => {
    showProgressBar();
    setItem(CartStorageKeys.CHECKOUT_FLOW, true);
    removeItem('GUEST_CHECKOUT_EMAIL', 'sessionStorage');
    removeItem('BASKET_MERGED', 'sessionStorage');
  };

  return (
    <S.CheckoutButtonContainer $isDisabled={isDisabled} $noMaxWidth={noMaxWidth}>
      <S.CheckoutButton
        $noMaxWidth={noMaxWidth}
        data-testid="miniCartCheckoutButton"
        {...(!isUserLoggedIn
          ? {
              as: 'button',
              type: 'button',
              onClick: handleCheckoutSecurely,
            }
          : {
              as: 'a',
              href: createAnchorTagHref({
                locale,
                path: isStar2 ? '/checkout' : '/checkout/delivery',
              }),
              onClick: handleLoggedInCheckoutSecurely,
            })}
        $isDisabled={isDisabled}
      >
        <Lock />
        <span>
          {t('cart.checkout.cta')}&nbsp;{currency}
          {total}
        </span>
      </S.CheckoutButton>
    </S.CheckoutButtonContainer>
  );
}
