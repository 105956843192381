/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans, useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useResendConfirmationCodeMutation } from '../../graphqlDocument/auth/resendConfirmationCode.generated';
import { colours, fontWeights } from '../../stylings';

const S = {
  Text: styled.span`
    color: ${colours.RED};
    font-size: 13px;
    letter-spacing: 0;
    line-height: 20px;
    margin: 0;
    padding: 0;
  `,
  HereButton: styled.button`
    background-color: inherit;
    border: none;
    color: ${colours.BLACK};
    font-family: inherit;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    ${fontWeights.SemiBold600}
  `,
};

export function UnconfirmedEmailError({ email }: { email: string }) {
  const { t } = useTranslation(['lib-global-common']);
  const [resendConfirmationCode, { loading }] = useResendConfirmationCodeMutation();

  const handleResendConfirmation = async () => {
    try {
      await resendConfirmationCode({
        variables: {
          input: {
            username: email,
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <S.Text data-testid="unconfirmedUserError" role="status">
      <Trans
        i18nKey="register.unconfirmed.email.message"
        values={{
          here: t('register.confirmation.message.here'),
        }}
        components={[
          <S.HereButton
            type="button"
            aria-label={t('register.confirmation.message.here')}
            disabled={loading}
            onClick={handleResendConfirmation}
            key="resend-confirmation"
          />,
        ]}
        t={t}
      />
    </S.Text>
  );
}

export default UnconfirmedEmailError;
