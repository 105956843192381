import type { KeyboardEvent, MouseEvent } from 'react';
import type { Maybe, ProductBadgeImage } from '../../../codegen/types';
import { ImagePartial } from '../../../partials/image';
import { ImageBadgeContainer } from './ImageBadge.styles';

type ImageBadgesProps = {
  badge?: Maybe<ProductBadgeImage>;
  pdpBadge?: boolean;
  isVideo?: boolean;
};

export function ImageBadge({ badge, pdpBadge, isVideo }: ImageBadgesProps) {
  if (!badge || !badge?.src || isVideo) return null;

  const handleBadgeClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!badge?.url) return;

    e.preventDefault();
    window.open(badge.url, '_blank', 'noopener noreferrer');
  };

  const handleBadgeKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter' || !badge?.url) return;

    window.open(badge.url, '_blank', 'noopener noreferrer');
  };

  return (
    <ImageBadgeContainer
      $pdpBadge={pdpBadge}
      data-testid={badge?.code}
      role="link"
      tabIndex={0}
      onKeyDown={handleBadgeKeyDown}
      onClick={handleBadgeClick}
    >
      <ImagePartial src={badge?.src as string} altText={badge?.alt || ''} layout="fill" aria-label={badge?.alt || ''} />
    </ImageBadgeContainer>
  );
}
