import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { KeyboardEvent, ReactElement, MouseEvent as ReactMouseEvent, TouchEvent } from 'react';
import { useRouter } from 'next/router';
import { useEscapeKeyListener } from '../../../hooks/useEscapeKeyListener';
import { LinkPartial } from '../../../partials';
import type { NavigationHierarchyData1 } from '../../../types';
import { transformTextToId } from '../../../utils';
import Megamenu from '../MegaMenu';
import { MenuContainer, TopCategories, TopCategory } from './HeaderNavigation.styles';

export const OPEN_MENU_DELAY = 300;
export const CLOSE_MENU_DELAY = 300;
export interface HeaderNavigationProps {
  hierarchyData: NavigationHierarchyData1[];
}
export function HeaderNavigation({ hierarchyData }: HeaderNavigationProps): ReactElement {
  const showMenuTimeout = useRef<number>();
  const hideMenuTimeout = useRef<number>();
  const router = useRouter();

  const [activeMenu, setActiveMenu] = useState<string | undefined>('');
  const [onTouchDevice, setOnTouchDevice] = useState(false);

  let onTouch = false;

  const showMegaMenu = (selectedMenu?: string) => {
    clearTimeout(hideMenuTimeout.current);
    showMenuTimeout.current = window.setTimeout(() => {
      setActiveMenu(selectedMenu);
    }, OPEN_MENU_DELAY);
  };

  const hideMegamenu = () => {
    clearTimeout(showMenuTimeout.current);
    hideMenuTimeout.current = window.setTimeout(() => {
      setActiveMenu('');
    }, CLOSE_MENU_DELAY);
  };

  const handleMouseEnter = useCallback(
    (selectedMenu?: string) => (event: ReactMouseEvent) => {
      if (onTouch) return;
      clearTimeout(hideMenuTimeout.current);
      if (selectedMenu === activeMenu) return;
      if (!(event.relatedTarget as Element)?.id?.includes('top-category')) {
        showMegaMenu(selectedMenu);
      }
    },
    [activeMenu, onTouch],
  );

  const handleMouseLeave = useCallback(() => {
    if (onTouch) return;
    hideMegamenu();
  }, [onTouch]);

  useEffect(() => {
    router.events.on('routeChangeComplete', hideMegamenu);
    return () => router.events.off('routeChangeComplete', hideMegamenu);
  }, [router.events]);

  useEffect(() => {
    const checkTouchDevice = () => {
      if ('ontouchstart' in window || navigator.maxTouchPoints) {
        setOnTouchDevice(true);
      }
    };
    const closeMenuOnOutsideTouch = (event: MouseEvent) => {
      const megaMenuContainer = document.getElementById('mega-menu-container');

      if ((event.target as Element)?.id?.includes('top-category')) return;
      if (event.target !== megaMenuContainer && !megaMenuContainer?.contains(event.target as Element)) {
        hideMegamenu();
      }
    };

    window.addEventListener('click', closeMenuOnOutsideTouch);
    checkTouchDevice();

    return () => {
      window.removeEventListener('click', closeMenuOnOutsideTouch);
    };
  }, []);

  useEffect(() => {
    if (activeMenu && onTouchDevice) {
      // disable body scroll on active menu on hover for touch device
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [activeMenu, onTouchDevice]);

  const handleKeyDown = (selectedMenu?: string) => (event: KeyboardEvent<HTMLLIElement>) => {
    if (event.code === 'Space') {
      event.preventDefault();

      setActiveMenu(selectedMenu);
    }
  };

  const handleCloseMenu = async (): Promise<void> => {
    setActiveMenu('');
  };

  const handleOnTouchEnd = useCallback(
    (category?: NavigationHierarchyData1) => (event: TouchEvent<HTMLAnchorElement>) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      onTouch = true;
      if (!category) return;

      if (category.navigationHierarchyDataSubLevel.length < 1) {
        hideMegamenu();
        return;
      }

      if (activeMenu === category.content._meta?.deliveryId) {
        hideMegamenu();
        return;
      }

      event.preventDefault();
      showMegaMenu(category.content._meta?.deliveryId as string);
    },
    [activeMenu],
  );

  const handleOnClick = useCallback(
    (category?: NavigationHierarchyData1) => (event: ReactMouseEvent<HTMLAnchorElement>) => {
      if (!category) return;

      if (Object.keys(category?.content.link ?? {}).length === 0) {
        event.preventDefault();
      }
      if (!onTouchDevice) showMegaMenu(category.content._meta?.deliveryId as string);
    },
    [onTouchDevice],
  );

  useEscapeKeyListener(handleCloseMenu);

  const TopCategoryList = useMemo(
    () => (
      <TopCategories data-testid="topCategories" role="navigation" aria-label="Categories">
        <ul role="menu" aria-label="Categories">
          {hierarchyData.map((category: NavigationHierarchyData1) => (
            <TopCategory
              role="menuitem"
              key={transformTextToId(category.content.text)}
              onMouseEnter={handleMouseEnter(category.content._meta?.deliveryId as string)}
              onMouseLeave={handleMouseLeave}
              onKeyDown={handleKeyDown ? handleKeyDown(category.content._meta?.deliveryId as string) : undefined}
              className={activeMenu === category.content._meta?.deliveryId ? 'active' : 'inactive'}
              $isDisabled={category.navigationHierarchyDataSubLevel.length === 0 && !category.content.link}
            >
              <LinkPartial
                {...category.content.link}
                role="menuitem"
                id={`top-category-${transformTextToId(category.content.text)}`}
                aria-haspopup={activeMenu === category.content._meta?.deliveryId}
                aria-expanded={false}
                onClick={handleOnClick(category)}
                onTouchEnd={handleOnTouchEnd(category)}
              >
                {category.content.text}
              </LinkPartial>
              <MenuContainer id="mega-menu-container" $isVisible={activeMenu === category.content._meta?.deliveryId}>
                <Megamenu
                  megamenuData={category.navigationHierarchyDataSubLevel[0]}
                  handleCloseMenu={handleCloseMenu}
                />
              </MenuContainer>
            </TopCategory>
          ))}
        </ul>
      </TopCategories>
    ),
    [activeMenu, handleMouseEnter, handleMouseLeave, handleOnClick, handleOnTouchEnd, hierarchyData],
  );
  return TopCategoryList;
}

export default HeaderNavigation;
