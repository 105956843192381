import * as yup from 'yup';

export const ResetPasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('confirm.reset.empty.password')
    .min(10, 'confirm.reset.validation.password')
    .max(256, 'confirm.reset.validation.password'),
  repeatPassword: yup
    .string()
    .required('confirm.reset.validation.repeat.password')
    .oneOf([yup.ref('newPassword')], 'confirm.reset.validation.repeat.password'),
});
