import type { ServerResponse } from 'http';
import { setAuthSessionTimeout } from '../../auth';

export const cachedQueries = [
  'getSwitchSites',
  'getPaymentMethods',
  'GetStaticContextData',
  'getFits',
  'getSizes',
  'GetRedirectInfoData',
  'getRedirectInfo',
  'getProduct',
  'getVariants',
  'marketingPreferences',
  'getStore',
  'getDeliveryOptionsForProducts',
  'getInStorePickupOptions',
  'getCategory',
  'getCarouselProducts',
  'sizeCalculator',
];

export function updateAuthSessionOnGqlOperation(operationName: string, res: ServerResponse) {
  if (cachedQueries.includes(operationName)) {
    return;
  }
  setAuthSessionTimeout(res);
}
