import type { ChangeEvent, KeyboardEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Range, RangeBoundaries } from 'instantsearch.js/es/connectors/range/connectRange';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { Fieldset } from '../../../baseComponents/inputs/Fieldset';
import { useLocaleContext } from '../../../context/localeContext';
import {
  TypographyStyles,
  colours,
  media,
  spacing,
  zSliderLeftOverRightThumb,
  zSliderLeftThumb,
  zSliderRange,
  zSliderRightThumb,
  zSliderTrack,
} from '../../../stylings';
import { getAmount, getCurrencySymbol, localeToCurrencyCode } from '../../../utils/currencyHelper';
import { sendApplyCategoryFilterEvent } from '../../../utils/gtm/events/apply_category_filter/sendApplyCategoryFilterEvent';
import { sendRemoveCategoryFilterEvent } from '../../../utils/gtm/events/remove_category_filter/sendRemoveCategoryFilterEvent';

interface PriceSliderProps {
  range: Range;
  start: RangeBoundaries;
  onChange: ({ min, max }: { min: number; max: number }) => void;
}

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXS};
    padding: ${spacing.XS} 0 ${spacing.S} 0;
  `,
  PriceSliderContainer: styled(Fieldset)`
    background-color: ${colours.LIGHT_GREY_2};
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXS};
    height: 90px;
    padding: ${spacing.XS};
    width: 100%;
  `,

  Input: styled.input`
    /* stylelint-disable value-no-vendor-prefix */
    appearance: none;
    height: 0;
    margin-top: ${spacing.XXXXS};
    outline: none;
    pointer-events: none;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    width: 230px;

    @media ${media.greaterThan('lg')} {
      width: 260px;
    }

    &.left-thumb {
      z-index: ${zSliderLeftThumb};
    }

    &.right-thumb {
      z-index: ${zSliderRightThumb};
    }

    &.left-over-right-thumb {
      z-index: ${zSliderLeftOverRightThumb};
    }

    &::-webkit-slider-thumb {
      appearance: none;
      background-color: ${colours.BLACK};
      border: none;
      cursor: pointer;
      height: 18px;
      pointer-events: all;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      width: 18px;
    }

    &::-moz-range-thumb {
      appearance: none;
      background-color: ${colours.BLACK};
      border: none;
      cursor: pointer;
      height: 18px;
      pointer-events: all;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      width: 18px;
    }

    /* Chrome, Safari, Opera, and Edge Chromium */
    &:focus::-webkit-slider-thumb {
      outline: 2px solid ${colours.OUTLINE_BORDER};
    }

    /* Firefox  */
    &:focus::-moz-range-thumb {
      outline: 2px solid ${colours.OUTLINE_BORDER};
    }
    /* stylelint-enable value-no-vendor-prefix */
  `,
  Slider: styled.div`
    position: relative;
    width: 230px;

    @media ${media.greaterThan('lg')} {
      width: 260px;
    }
  `,
  SliderTrack: styled.div`
    background-color: ${colours.MID_GREY};
    height: ${spacing.XXXXXS};
    position: absolute;
    width: 100%;
    z-index: ${zSliderTrack};
  `,
  SliderRange: styled.div`
    align-items: center;
    background-color: ${colours.BLACK};
    height: ${spacing.XXXXXS};
    position: absolute;
    z-index: ${zSliderRange};
  `,
  MinMaxContainer: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${spacing.XXXXS};
  `,
  SliderContainer: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 34px;
    justify-content: center;
    padding: 0;
    position: relative;
    width: 100%;
  `,
  RangeContainer: styled.div`
    align-items: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;

    span {
      ${TypographyStyles.Body.Tiny.Regular}
    }
  `,
  ClearButton: styled.button`
    ${TypographyStyles.CallToAction.Tiny.SemiBold}

    background: none;
    border: 0;
    color: ${colours.BLACK};
    padding: 0;
    text-align: right;
    text-decoration: underline;
  `,
  RangeSelectedValues: styled.span`
    ${TypographyStyles.Body.Tiny.SemiBold}
  `,
  MinValue: styled.span`
    ${TypographyStyles.Body.Tiny.Medium}
  `,
  MaxValue: styled.span`
    ${TypographyStyles.Body.Tiny.Medium}
    text-align: right;
  `,
};

export function PriceSlider({ range, start, onChange }: PriceSliderProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();
  const currencySymbol = getCurrencySymbol(localeToCurrencyCode[locale]);

  const { maxThumb, minThumb } = useMemo(
    () => ({
      minThumb: parseInt(getAmount(range?.min).toString(), 10),
      maxThumb: parseInt(getAmount(range?.max).toString(), 10),
    }),
    [range?.max, range?.min],
  );
  const { minPrice, maxPrice } = useMemo(
    () => ({
      minPrice: parseInt(getAmount(start[0] === -Infinity ? range.min : start[0]).toString(), 10),
      maxPrice: parseInt(getAmount(start[1] === Infinity ? range.max : start[1]).toString(), 10),
    }),
    [range.max, range.min, start],
  );

  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);

  const showPriceRangeSelected = start[0] !== -Infinity || start[1] !== Infinity;

  const minValRef = useRef<HTMLInputElement>(null);
  const maxValRef = useRef<HTMLInputElement>(null);
  const sliderRange = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const min = getInitialMinValue(Number(start[0]), Number(range.min));
    const max = getInitialMaxValue(Number(start[1]), Number(range.max));
    setMaxVal(max);
    setMinVal(min);
  }, [start, range]);

  // Convert to percentage
  const getPercent = useCallback(
    (value: number) => Math.round(((value - minThumb) / (maxThumb - minThumb)) * 100),
    [minThumb, maxThumb],
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(maxValRef.current.valueAsNumber);

      if (sliderRange.current) {
        sliderRange.current.style.left = `${minPercent}%`;
        sliderRange.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(minValRef.current.valueAsNumber);
      const maxPercent = getPercent(maxVal);

      if (sliderRange.current) {
        sliderRange.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  const handleMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.min(e.target.valueAsNumber, maxVal - 1);
    setMinVal(value);
  };

  const handleMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(e.target.valueAsNumber, minVal + 1);
    setMaxVal(value);
  };

  const handleThumbRelease = () => {
    onChange({ min: minVal, max: maxVal });

    sendApplyCategoryFilterEvent({
      filter_category: 'Price Range',
      filter_value: `${currencySymbol}${minVal}-${currencySymbol}${maxVal}`,
    });
  };

  const handleKeyRelease = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!(event.code === 'ArrowRight' || event.code === 'ArrowLeft')) return;

    onChange({ min: minVal, max: maxVal });
    sendRemoveCategoryFilterEvent({
      filter_category: 'Price Range',
      filter_value: `${currencySymbol}${minVal}-${currencySymbol}${maxVal}`,
    });
  };

  const onClick = () => {
    setMinVal(minThumb);
    setMaxVal(maxThumb);
    onChange({ min: minThumb, max: maxThumb });

    sendRemoveCategoryFilterEvent({
      filter_category: 'Price Range',
      filter_value: `${currencySymbol}${minVal}-${currencySymbol}${maxVal}`,
    });
  };

  return (
    <S.Container role="region" aria-label={t('filter.menu.filter.price')}>
      {showPriceRangeSelected && (
        <>
          <S.RangeContainer>
            <span>{t('filter.menu.filter.price.range.selected')}</span>
            <S.ClearButton
              role="button"
              aria-label="clearPriceFilterButton"
              type="button"
              onClick={onClick}
              data-testid="clearPriceFilterButton"
            >
              {t('filter.menu.filter.price.range.button')}
            </S.ClearButton>
          </S.RangeContainer>
          <S.RangeSelectedValues data-testid="selectedRangeValues">{`${currencySymbol}${minPrice} - ${currencySymbol}${maxPrice}`}</S.RangeSelectedValues>
        </>
      )}

      <S.PriceSliderContainer
        data-testid="priceSliderContainer"
        aria-label={t('filter.menu.filter.price')}
        label={t('filter.menu.filter.price')}
      >
        <S.MinMaxContainer>
          <S.MinValue data-testid="minPrice">{`${currencySymbol}${minVal}`}</S.MinValue>
          <S.MaxValue data-testid="maxPrice">{`${currencySymbol}${maxVal}`}</S.MaxValue>
        </S.MinMaxContainer>
        <S.SliderContainer>
          <S.Input
            type="range"
            min={minThumb}
            max={maxThumb}
            value={minVal}
            ref={minValRef}
            onChange={handleMinChange}
            onMouseUp={handleThumbRelease}
            onTouchEnd={handleThumbRelease}
            onKeyUp={handleKeyRelease}
            className={minVal > maxThumb - 100 ? 'left-over-right-thumb' : 'left-thumb'}
            data-testid="minPriceInput"
            aria-label={t('filter.price.range.min')}
            aria-valuemin={minThumb}
            aria-valuemax={maxThumb}
            aria-valuenow={minVal}
            aria-orientation="horizontal"
          />
          <S.Input
            type="range"
            min={minThumb}
            max={maxThumb}
            value={maxVal}
            ref={maxValRef}
            onChange={handleMaxChange}
            onMouseUp={handleThumbRelease}
            onTouchEnd={handleThumbRelease}
            onKeyUp={handleKeyRelease}
            className="right-thumb"
            data-testid="maxPriceInput"
            aria-label={t('filter.price.range.max')}
            aria-valuemin={minThumb}
            aria-valuemax={maxThumb}
            aria-valuenow={maxVal}
            aria-orientation="horizontal"
          />
          <S.Slider>
            <S.SliderTrack role="presentation" />
            <S.SliderRange role="presentation" ref={sliderRange} />
          </S.Slider>
        </S.SliderContainer>
      </S.PriceSliderContainer>
    </S.Container>
  );
}

function getInitialMinValue(start: number, range: number) {
  if (start === -Infinity || start <= range) {
    return parseInt(getAmount(range).toString(), 10);
  }
  return parseInt(getAmount(start).toString(), 10);
}
function getInitialMaxValue(start: number, range: number) {
  if (start === Infinity || start >= range) {
    return parseInt(getAmount(range).toString(), 10);
  }
  return parseInt(getAmount(start).toString(), 10);
}

export default PriceSlider;
