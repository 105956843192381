import { useState } from 'react';
import { useRouter } from 'next/router';

export function useExitAnimation({ callbackFunction, delay = 500 }: { callbackFunction: () => void; delay?: number }) {
  const [isClosing, setIsClosing] = useState(false);
  const router = useRouter();
  const isCheckout = router.asPath.includes('checkout');

  const handleIsCheckout = () => {
    if (!isCheckout) {
      setIsClosing(false);
    }
  };

  const handleExitAnimation = () => {
    const hasReduceMotion = window?.matchMedia('(prefers-reduced-motion: reduce)').matches;

    setIsClosing(true);
    if (hasReduceMotion) {
      callbackFunction();
      handleIsCheckout();
    } else {
      setTimeout(() => {
        callbackFunction();
        handleIsCheckout();
      }, delay);
    }
  };

  return { isClosing, handleExitAnimation };
}
