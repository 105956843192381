import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { EmptyStar, FilledStar } from '../../../icons';
import { spacing } from '../../../stylings';
import FilterAccordion from './FilterAccordion';
import type { FilterCheckboxProps } from './FilterCheckbox';
import { FilterCheckbox } from './FilterCheckbox';

const S = {
  StarContainer: styled.div`
    align-items: center;
    display: flex;
    height: 13px;
    justify-content: center;
    margin-right: -${spacing.XXXS};
    position: relative;
    width: 13px;
  `,
};

function StarsRating({ value, maxRating }: Partial<RefinementListItem & { maxRating: number }>) {
  const { t } = useTranslation(['lib-global-common']);
  const ratingScore = Number(value);
  const filledStarArray = Array.from(Array(ratingScore).fill('filled'));
  const emptyStarArray = Array.from(Array(5 - ratingScore).fill('empty'));

  return (
    <>
      {filledStarArray.map((star, i) => (
        <S.StarContainer key={`${star}_${i}`}>
          <FilledStar />
        </S.StarContainer>
      ))}
      {emptyStarArray.map((star, i) => (
        <S.StarContainer key={`${star}_${i}`}>
          <EmptyStar />
        </S.StarContainer>
      ))}
      {maxRating && (
        <span className="visually-hidden">{t('filter.top.rated.stars', { rating: value, maxRating })}</span>
      )}
    </>
  );
}

function RatingLabel(LabelComponent: typeof StarsRating, items: RefinementListItem[]) {
  const maxRating = Math.max(...items.map((item) => Number(item?.value)));

  return function Component({ value }: RefinementListItem) {
    return <LabelComponent value={value} maxRating={maxRating} />;
  };
}

export function RatingsFilter(
  props: FilterCheckboxProps & {
    productCount: number;
  },
) {
  const { t } = useTranslation(['lib-global-common']);
  const { items, productCount } = props;
  if (items.length === 0 || (items.length === 1 && items[0].count === productCount)) return null;

  const isOpen = !!items.find((item) => item.isRefined);

  return (
    <FilterAccordion
      isOpen={isOpen}
      triggerLabel={t('filter.menu.filter.top.rated')}
      content={<FilterCheckbox {...props} labelComponent={RatingLabel(StarsRating, items)} />}
    />
  );
}

export default RatingsFilter;
