import type { LineItem, Maybe } from '../../../codegen/types';
import type { IndexedLineItem } from '../../../types/product';
import { getAmount } from '../../currencyHelper';

const getCartPrices = (price: LineItem['price']) => {
  const { wasPrice, thenPrice, value } = { ...price };
  const { currencyCode, centAmount } = value;
  const priceAmount = +getAmount(value?.centAmount);
  const wasPriceAmount = +getAmount(wasPrice?.centAmount);
  const thenPriceAmount = +getAmount(thenPrice?.centAmount);
  const discountAmount = wasPriceAmount && priceAmount ? wasPriceAmount - priceAmount : 0;
  return {
    priceAmount,
    wasPriceAmount,
    thenPriceAmount,
    discountAmount,
    currencyCode,
    centAmount,
  };
};

export interface LineItemParams {
  quantity: number;
  then_price: number;
  item_id?: Maybe<string>;
  price: number;
  discount: number;
  was_price: number;
  item_sku?: Maybe<string>;
  item_name: string;
  item_brand?: Maybe<string>;
  item_category: string;
  item_variant?: Maybe<string>;
  item_category_id: string;
  index?: number;
}

export const createLineItem = ({
  index,
  price,
  productKey,
  name,
  quantity,
  colour,
  brand,
  primaryCategory,
  variant,
}: IndexedLineItem): LineItemParams => {
  const { discountAmount, priceAmount, wasPriceAmount, thenPriceAmount } = getCartPrices(price);
  return {
    ...(index !== undefined && { index }),
    item_id: productKey,
    item_sku: variant.sku,
    item_name: name,
    discount: +discountAmount.toFixed(2),
    quantity,
    item_brand: brand,
    item_category: primaryCategory?.name || '',
    item_category_id: primaryCategory?.key || '',
    item_variant: colour,
    price: priceAmount,
    was_price: wasPriceAmount,
    then_price: thenPriceAmount,
  };
};
