import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .required('login.validation.empty.email')
    .max(128, 'login.validation.email')
    .email('login.validation.email'),
  password: yup.string().required('login.validation.password').min(10, 'login.validation.min.password'),
});
