import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { userLogout } from '../services/apiRoute/logout';
import { progressBarDone, progressBarStart } from '../utils/progressBarStatus';

export function useLogout() {
  const router = useRouter();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const isFromMyAccountPage = router.asPath.includes('/my-account');

  async function logoutUser() {
    try {
      progressBarStart();
      const result = await userLogout();
      progressBarDone();
      setIsLoggedOut(result);
      return !!result;
    } catch (e) {
      console.error('Logout API', e);
      progressBarDone();
      return e;
    } finally {
      progressBarDone();
    }
  }

  useEffect(() => {
    if (!isLoggedOut) return;

    if (isFromMyAccountPage) {
      router.replace(
        {
          pathname: '/',
        },
        undefined,
        {
          locale: router.locale,
        },
      );
    } else {
      router.reload();
    }
  }, [isFromMyAccountPage, isLoggedOut, router]);

  return { logoutUser };
}
