import type { Dispatch, SetStateAction } from 'react';
import { useLocaleContext } from '../../../context';
import { useItemDetailsHelpers } from '../../../hooks/useItemDetailsHelpers';
import { Locale } from '../../../utils';
import {
  ProductDetailLabel,
  ProductDetailRow,
  ProductDetailValue,
  ProductDetailValueText,
} from './ProductItemDetail.styles';
import type { ProductLineItemAction } from './ProductLineItem.utils';
import type { LineItemStatus } from './ProductLineItemList';
import { WithQuantityButtons } from './WithQuantityButtons';

interface ProductItemDetailProps {
  item: {
    id: string;
    name: string;
    label: string;
    value: string | number;
    productTypeKey: string | null | undefined;
  };
  indexes: { productDetailsBlockIndex: number; productDetailsItemIndex: number };
  quantityButtonProps?: {
    sku: {
      cap: number | undefined | null;
      isCapReached: boolean;
    };
    dispatch: Dispatch<ProductLineItemAction>;
    isStar2: boolean;
    setLineItemStatus: Dispatch<SetStateAction<LineItemStatus[]>>;
  };
}

export function ProductItemDetail({
  item: { label, value, productTypeKey, id, name },
  indexes: { productDetailsBlockIndex, productDetailsItemIndex },
  quantityButtonProps,
}: ProductItemDetailProps) {
  const { locale } = useLocaleContext();
  const { getItemType, isGiftCard } = useItemDetailsHelpers();
  const isFRCA = locale === Locale.FR_CA;
  const itemType = getItemType(label);

  return (
    <ProductDetailRow key={productDetailsItemIndex}>
      <ProductDetailLabel scope="row" $isFRCA={isFRCA} $isGiftCard={isGiftCard(productTypeKey)}>
        {label}
      </ProductDetailLabel>
      <ProductDetailValue
        data-testid={`lineItemValue-${productDetailsBlockIndex}-${productDetailsItemIndex}`}
        $isMessage={itemType === 'message'}
      >
        {itemType === 'quantity' && quantityButtonProps ? (
          <WithQuantityButtons
            name={name}
            id={id}
            disableIncrease={quantityButtonProps.sku.isCapReached ?? false}
            quantity={value as number}
            skuCap={quantityButtonProps.sku.cap}
            dispatch={quantityButtonProps.dispatch}
            setLineItemStatus={quantityButtonProps.setLineItemStatus}
            isStar2={quantityButtonProps.isStar2}
          />
        ) : (
          <ProductDetailValueText $isMessage={itemType === 'message'} $isEmailAddress={itemType === 'email'}>
            {value}
          </ProductDetailValueText>
        )}
      </ProductDetailValue>
    </ProductDetailRow>
  );
}
