import type { AppointmentConfirmationLayout } from '@amplience/content-types/typings/s-appointment-confirmation-layout';
import { LocaleStar2, countryNameMapperROE, getCountryKey, siteMapper } from '../../utils/localeHelper';
import { sendFilterApiRequest } from './amplienceFilterApi';

export const getAppointmentConfirmationLayout = async (
  locale: string,
): Promise<AppointmentConfirmationLayout | unknown> => {
  const parameterLocale = Object.prototype.hasOwnProperty.call(
    countryNameMapperROE,
    getCountryKey(locale).toUpperCase(),
  )
    ? locale.split('-')[0]
    : locale;

  const requestBody = {
    filterBy: [
      {
        path: '/_meta/schema',
        value: 'https://clarks.com/slot/s-appointment-confirmation-layout.json',
      },
      {
        path: '/isDefault',
        value:
          siteMapper[
            Object.prototype.hasOwnProperty.call(countryNameMapperROE, getCountryKey(locale).toUpperCase())
              ? LocaleStar2.ROE
              : locale.toLowerCase()
          ],
      },
    ],
    parameters: {
      depth: 'all',
      format: 'inlined',
      locale: parameterLocale,
    },
  };

  try {
    const response = await sendFilterApiRequest({ requestBody });

    return response[0]?.content ?? null;
  } catch (err) {
    console.error('Error fetching Appointment Confirmation Slot Layout from Amplience', err);
    return null;
  }
};
