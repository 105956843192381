import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type CreateAdyenSessionMutationVariables = Types.Exact<{
  input: Types.CreateAdyenSessionInput;
}>;

export type CreateAdyenSessionMutation = {
  __typename?: 'Mutation';
  createAdyenSession: { __typename?: 'Session'; id: string; sessionData: string };
};

export const CreateAdyenSessionDocument = gql`
  mutation createAdyenSession($input: CreateAdyenSessionInput!) {
    createAdyenSession(input: $input) {
      id
      sessionData
    }
  }
`;
export type CreateAdyenSessionMutationFn = Apollo.MutationFunction<
  CreateAdyenSessionMutation,
  CreateAdyenSessionMutationVariables
>;

/**
 * __useCreateAdyenSessionMutation__
 *
 * To run a mutation, you first call `useCreateAdyenSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdyenSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdyenSessionMutation, { data, loading, error }] = useCreateAdyenSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdyenSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAdyenSessionMutation, CreateAdyenSessionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAdyenSessionMutation, CreateAdyenSessionMutationVariables>(
    CreateAdyenSessionDocument,
    options,
  );
}
export type CreateAdyenSessionMutationHookResult = ReturnType<typeof useCreateAdyenSessionMutation>;
export type CreateAdyenSessionMutationResult = Apollo.MutationResult<CreateAdyenSessionMutation>;
export type CreateAdyenSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateAdyenSessionMutation,
  CreateAdyenSessionMutationVariables
>;
