import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetFeatureFlagsQueryVariables = Types.Exact<{
  locale: Types.Scalars['String'];
}>;

export type GetFeatureFlagsQuery = {
  __typename?: 'Query';
  getFeatureFlags: {
    __typename?: 'GetFeatureFlagsResponse';
    authRecaptcha: { __typename?: 'GetFeatureFlagStatusResponse'; enabled: boolean };
    productTrueFit: { __typename?: 'GetFeatureFlagStatusResponse'; enabled: boolean };
    orderRiskInquiry: { __typename?: 'GetFeatureFlagStatusResponse'; enabled: boolean };
    klarnaPdp: { __typename?: 'GetFeatureFlagStatusResponse'; enabled: boolean };
    btsProductPriceRange: { __typename?: 'GetFeatureFlagStatusResponse'; enabled: boolean };
    btsAppointments: { __typename?: 'GetFeatureFlagStatusResponse'; enabled: boolean };
    productStockCheck: { __typename?: 'GetFeatureFlagStatusResponse'; enabled: boolean };
  };
};

export const GetFeatureFlagsDocument = gql`
  query GetFeatureFlags($locale: String!) {
    getFeatureFlags(locale: $locale) {
      authRecaptcha {
        enabled
      }
      productTrueFit {
        enabled
      }
      orderRiskInquiry {
        enabled
      }
      klarnaPdp {
        enabled
      }
      btsProductPriceRange {
        enabled
      }
      btsAppointments {
        enabled
      }
      productStockCheck {
        enabled
      }
    }
  }
`;

/**
 * __useGetFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetFeatureFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
}
export function useGetFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>(GetFeatureFlagsDocument, options);
}
export type GetFeatureFlagsQueryHookResult = ReturnType<typeof useGetFeatureFlagsQuery>;
export type GetFeatureFlagsLazyQueryHookResult = ReturnType<typeof useGetFeatureFlagsLazyQuery>;
export type GetFeatureFlagsQueryResult = Apollo.QueryResult<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>;
