import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import { useDisableBodyScroll } from '../hooks/useDisableBodyScroll';
import { useMediaMatch } from '../hooks/useMediaMatch';
import { media } from '../stylings';

type OverlayContextType = {
  isMiniCartOpen: boolean;
  setIsMiniCartOpen: Dispatch<SetStateAction<boolean>>;
  isSearchOverlayOpen: boolean;
  setIsSearchOverlayOpen: Dispatch<SetStateAction<boolean>>;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  searchQuery: string;
  isMarketingMessageInView: boolean;
  setIsMarketingMessageInView: Dispatch<SetStateAction<boolean>>;
};

const OverlayContext = createContext<OverlayContextType>({
  isMiniCartOpen: false,
  setIsMiniCartOpen: () => {},
  isSearchOverlayOpen: false,
  setIsSearchOverlayOpen: () => {},
  isMobileMenuOpen: false,
  setIsMobileMenuOpen: () => {},
  searchQuery: '',
  isMarketingMessageInView: false,
  setIsMarketingMessageInView: () => {},
});

export const useOverlayContext = () => useContext(OverlayContext);

type OverlayProviderProps = {
  children: ReactNode;
};

export function OverlayContextProvider({ children }: OverlayProviderProps) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const router = useRouter();

  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMarketingMessageInView, setIsMarketingMessageInView] = useState(false);

  const [searchQuery, setSearchQuery] = useState((router?.query?.query as string) || '');

  useDisableBodyScroll(isMiniCartOpen || isSearchOverlayOpen || isMobileMenuOpen);
  useEffect(() => {
    const searchModalInputElement: HTMLInputElement | null = document.querySelector('.aa-Input');

    if (searchModalInputElement) {
      if (!isSearchOverlayOpen) {
        searchModalInputElement.blur();
        setSearchQuery(searchModalInputElement.value);
      } else {
        searchModalInputElement.focus();
      }
    }
  }, [isMiniCartOpen, isSearchOverlayOpen, isMobileMenuOpen]);

  useEffect(() => {
    if (!isDesktop) {
      // Disabling body scroll on iOS needs to be applied to `html` also:
      // https://stackoverflow.com/questions/3047337/does-overflowhidden-applied-to-body-work-on-iphone-safari
      document.documentElement.style.overflow =
        isMiniCartOpen || isSearchOverlayOpen || isMobileMenuOpen ? 'hidden' : 'initial';
    }
  }, [isDesktop, isMiniCartOpen, isMobileMenuOpen, isSearchOverlayOpen]);

  useEffect(() => {
    if (isDesktop && isMobileMenuOpen) setIsMobileMenuOpen(false);
  }, [isDesktop, isMobileMenuOpen]);

  useEffect(() => {
    if (isSearchOverlayOpen) scrollTo({ top: 0 });
  }, [isSearchOverlayOpen]);

  useEffect(() => {
    if (router?.query?.query && router.query.query !== searchQuery) {
      setSearchQuery(router.query.query as string);
    }
  }, [router.query, searchQuery]);

  const value = useMemo(
    () => ({
      isMiniCartOpen,
      setIsMiniCartOpen,
      isSearchOverlayOpen,
      setIsSearchOverlayOpen,
      isMobileMenuOpen,
      setIsMobileMenuOpen,
      searchQuery,
      setIsMarketingMessageInView,
      isMarketingMessageInView,
    }),
    [isMiniCartOpen, isSearchOverlayOpen, isMobileMenuOpen, searchQuery, isMarketingMessageInView],
  );

  return <OverlayContext.Provider value={value}>{children}</OverlayContext.Provider>;
}
