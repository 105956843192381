import { useState } from 'react';
import Image from 'next/image';
import type { ImageLoaderProps, ImageProps } from 'next/image';
import styled from 'styled-components';
import { BigPlaceholder } from '../../icons';
import { colours } from '../../stylings';

const S = {
  Container: styled.div<{ $width: number; $height: number }>`
    align-items: center;
    border: solid ${colours.ERROR_PRIMARY} 1px;
    display: flex;
    height: ${({ $height }) => `${$height}px`};
    justify-content: center;
    width: ${({ $width }) => `${$width}px`};
  `,
};

export function CloudinaryImage({
  src,
  height,
  width,
  quality,
  ...props
}: ImageProps & { width: number; height: number; quality?: number }) {
  const [isBroken, setIsBroken] = useState(false);

  const cloudinaryLoader = ({ src: srcProp, quality: qualityProp }: ImageLoaderProps) => {
    const params = [`w_${width}`, `h_${height}`, `q_${qualityProp || 'auto'}`, 'f_webp'];
    const textUpload = '/upload/';
    return srcProp.replace(textUpload, `${textUpload}${params.join(',')}/`);
  };

  const onErrorImage = () => setIsBroken(true);

  return isBroken ? (
    <S.Container $height={height} $width={width}>
      <BigPlaceholder />
    </S.Container>
  ) : (
    <Image
      {...props}
      src={src}
      width={width}
      height={height}
      quality={quality}
      onError={onErrorImage}
      loader={cloudinaryLoader}
      placeholder="blur"
      blurDataURL={src as string}
    />
  );
}
