import type { ReactNode } from 'react';
import type { RefinementListRenderState } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import FilterAccordion from './FilterAccordion';
import { FilterCheckbox } from './FilterCheckbox';

export type FilterCheckboxAccordionProps = RefinementListRenderState & {
  accordionLabel: ReactNode | string;
  productCount: number;
};

export function FilterCheckboxAccordion({ accordionLabel, productCount, ...props }: FilterCheckboxAccordionProps) {
  const { items } = props;
  if (items.length === 0 || (items.length === 1 && items[0].count === productCount)) return null;

  const isOpen = !!items.find((item) => item.isRefined);
  return (
    <FilterAccordion
      isOpen={isOpen}
      triggerLabel={accordionLabel}
      content={<FilterCheckbox accordionLabel={accordionLabel as string} {...props} />}
      testId="filterCheckboxAccordion"
    />
  );
}

export default FilterCheckboxAccordion;
