import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import { fromCents } from '@shared/utils';
import { useCartContext } from '../../../context/cartContext';
import { useLocaleContext } from '../../../context/localeContext';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { QuestionMarkTooltip } from '../../../icons';
import { ImagePartial } from '../../../partials/image';
import { LinkPartial } from '../../../partials/link';
import { TypographyStyles, colours, media, spacing, zIDmeTooltip } from '../../../stylings';
import { getCurrencySymbol } from '../../../utils/currencyHelper';
import { Locale } from '../../../utils/localeHelper';

const S = {
  Container: styled.div<{ $spaceBetween?: boolean }>`
    align-items: center;
    display: flex;
    gap: ${spacing.XXXS};
    height: 24px;

    ${({ $spaceBetween }) =>
      $spaceBetween
        ? css`
            justify-content: space-between;
          `
        : null}

    @media ${media.greaterThan('lg')} {
      height: 28px;
    }
  `,
  IDmeButton: styled.button`
    background-color: transparent;
    border: 0;
    height: 24px;
    padding: 0;
    position: relative;
    width: 114px;

    @media ${media.greaterThan('lg')} {
      height: 28px;
      width: 134px;
    }
  `,
  TooltipContainer: styled.div`
    align-items: center;
    display: flex;
  `,
  IDmeTooltipIcon: styled.button`
    background: none;
    border: 0;
    height: 16px;
    margin: 0;
    padding: 0;
    width: 16px;
  `,
  IDmeTooltipBubble: styled.div`
    background: ${colours.WHITE};
    filter: drop-shadow(0 0 1px ${colours.BLACK});
    padding: 20px;
    top: 100%;
    width: 230px;
    z-index: ${zIDmeTooltip};

    &[data-popper-placement^='top'] > .arrow {
      bottom: 10px;
    }

    &[data-popper-placement^='bottom'] > .arrow {
      top: 10px;
    }

    &[data-popper-placement^='left'] > .arrow {
      right: 30px;
    }

    &[data-popper-placement^='right'] > .arrow {
      left: 10px;
    }

    @media ${media.greaterThan('lg')} {
      width: 300px;
    }
  `,
  IDmeTooltipArrow: styled.div`
    margin-left: -20px;
    position: absolute;
    z-index: ${zIDmeTooltip};

    &:before {
      border: 20px solid transparent;
      border-bottom: 30px solid ${colours.WHITE};
      border-top: 0;
      bottom: 0;
      content: '';
      position: absolute;
    }
  `,
  IDmeTooltipContent: styled.div`
    ${TypographyStyles.Body.Tiny.Regular}
  `,
  DiscountAmount: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
  `,
};

function IDmeTooltip() {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(buttonRef.current, containerRef.current, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['bottom-start'],
        },
      },
      {
        name: 'offset',
        options: {
          offset: [-30, 25],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: { left: 5, right: 15 },
        },
      },
    ],
  });

  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  useOutsideClick(containerRef, () => setIsOpen(false));

  return (
    <S.TooltipContainer role="tooltip">
      <S.IDmeTooltipIcon onClick={handleClick} ref={buttonRef} aria-label="Toggle tooltip" type="button">
        <QuestionMarkTooltip />
      </S.IDmeTooltipIcon>
      {isOpen && (
        <S.IDmeTooltipBubble ref={containerRef} style={styles.popper} {...attributes.popper}>
          <S.IDmeTooltipArrow ref={setArrowRef} style={styles.arrow} className="arrow" />
          <S.IDmeTooltipContent>
            <Trans
              i18nKey="idme.tooltip.message"
              ns="lib-global-common"
              components={{
                IDmeLink: <LinkPartial externalLink="http://id.me/">ID.me</LinkPartial>,
              }}
            />
          </S.IDmeTooltipContent>
        </S.IDmeTooltipBubble>
      )}
    </S.TooltipContainer>
  );
}

export function VerifyWithIDme() {
  const { t } = useTranslation('lib-global-common');
  const { locale } = useLocaleContext();
  const { asPath } = useRouter();
  const { miniCart } = useCartContext();
  const { idMeDiscount, currency } = { ...miniCart };

  const isUS = [Locale.EN_US].includes(locale);
  const isCheckout = asPath.includes('/checkout');

  const openIDmeVerification = () => {
    window.open(
      process.env.NEXT_PUBLIC_ENV_NAME === 'prod'
        ? 'https://hosted-pages.id.me/offers/clarks'
        : 'https://hosted-pages.id.me/offers/clarks-test',
      '',
      `scrollbars\x3dyes,menubar\x3dno,status\x3dno,location\x3dno,toolbar\x3dno,width\x3d750,height\x3d780,top\x3d${
        (document.body.scrollHeight - 780) / 4
      },left\x3d${(document.body.scrollWidth - 750) / 2}`,
    );
  };

  if (idMeDiscount)
    return (
      <S.Container $spaceBetween>
        <S.Container>
          <span>{t('idme.discount.label')}</span>
          <IDmeTooltip />
        </S.Container>
        <S.DiscountAmount>
          {getCurrencySymbol(currency)}
          {fromCents(idMeDiscount)}
        </S.DiscountAmount>
      </S.Container>
    );

  if (!isUS || isCheckout) return null;

  return (
    <S.Container>
      <S.IDmeButton type="button" onClick={openIDmeVerification}>
        <ImagePartial src="verify-with-idme" objectFit="contain" layout="fill" altText={t('idme.verify.message')} />
      </S.IDmeButton>
      <IDmeTooltip />
    </S.Container>
  );
}
