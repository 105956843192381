import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import styled, { css } from 'styled-components';
import {
  TypographyStyles,
  colours,
  media,
  spacing,
  zCheckoutAdyenTooltip,
  zCheckoutAdyenTooltipBubble,
  zInputFieldCheckMark,
} from '../../stylings';
import type { FieldInputProps, PhoneNumberInputProps } from './Input';

export const initialBorder = css`
  border: none;
  outline: solid ${colours.BLACK} 1px;
  outline-offset: -1px;
`;

export const errorBorder = css`
  border: none;
  height: fit-content;
  min-height: 42px;
  outline: solid ${colours.RED} 1.5px;

  @media ${media.greaterThan('lg')} {
    min-height: 48px;
  }
`;

export const blackBorder = css`
  border: solid ${colours.BLACK} 1px;
  outline: none;
`;

export const noBorder = css`
  border: none;
`;

export const labelStyle = css`
  left: 13px;
  pointer-events: none;
  position: absolute;
  transform: translateY(0.8rem);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1); /* stylelint-disable-line */
  @media ${media.greaterThan('lg')} {
    transform: translateY(1rem);
  }
  white-space: nowrap;
`;

export const goingUpBaseStyle = css`
  ${TypographyStyles.Body.Tiny.Regular}
  background-color: ${colours.WHITE};
  left: 1px;
  line-height: 10px;
  margin: 0 ${spacing.XXXS};
  padding: 0 ${spacing.XXXXS};
  pointer-events: none;
  white-space: nowrap;
  @media ${media.greaterThan('lg')} {
    line-height: 12px;
  }
`;

export const IconWrapper = styled.div<{ $isGiftCardBalance?: boolean }>`
  margin: 0px ${spacing.XS} 0px ${spacing.XXS};
  position: absolute;
  right: ${({ $isGiftCardBalance }) => ($isGiftCardBalance ? '25px' : 0)};
  top: 50%;
  transform: translateY(-50%);
  z-index: ${zInputFieldCheckMark};
`;

export const IconWrapperButton = styled.button`
  background: none;
  border: none;
  margin: 17px 18px 17px 14px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ShowPasswordButton = styled.button`
  align-items: center;
  background-color: inherit;
  border: none;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export const FieldContainer = styled.div`
  position: relative;

  svg {
    background-color: ${colours.WHITE};
    display: block;
  }
  .error {
    border: solid ${colours.RED} 1px;
    max-height: 50px;
  }

  .error-label {
    color: ${colours.RED};
  }

  .grayed-out {
    svg {
      path {
        fill: ${colours.GREY};
      }
    }
  }
`;

export const FieldInput = styled.input<FieldInputProps>`
  background: ${({ $disabled }) => ($disabled ? colours.LIGHT_GREY_2 : 'none')};
  ${({ $isInputError }) => (!$isInputError ? blackBorder : noBorder)}
  border-color: ${({ $isEmpty, $disabled }) => {
    if ($disabled) return colours.GREY;
    if (!$isEmpty) return colours.BLACK;
    return colours.GREY;
  }};
  border-radius: 0;
  color: ${({ $colour }) => (!$colour ? colours.BLACK : $colour)};
  height: 2em;
  min-height: 44px;
  padding: 0 ${spacing.L} 0 ${spacing.XXS};
  width: 100%;
  ${TypographyStyles.Body.Medium.Regular}

  &::-ms-reveal {
    display: none;
  }

  @media ${media.greaterThan('lg')} {
    min-height: 49px;
  }

  &:focus {
    ${({ $isInputError }) => {
      if ($isInputError) {
        return errorBorder;
      }

      return initialBorder;
    }}
  }

  &::placeholder {
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  &:disabled {
    color: ${colours.GREY};
    opacity: 1;
    -webkit-text-fill-color: ${colours.GREY};
  }
`;

export const FieldLabel = styled.label<{ $isEmpty?: boolean; $disabled?: boolean; $colour?: string }>`
  color: ${({ $isEmpty, $disabled, $colour }) => {
    if ($disabled) {
      return colours.GREY;
    }
    if (!$isEmpty) {
      return colours.BLACK;
    }
    return !$colour ? colours.GREY : $colour;
  }};

  @media ${media.between('lg', 'xl')} {
    ${({ $disabled }) =>
      $disabled &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
  }

  &.going-up {
    ${goingUpBaseStyle}
    transform: translateY(-50%);
  }

  ${labelStyle}

  ${({ $isEmpty }) =>
    !$isEmpty &&
    css`
      ${goingUpBaseStyle}
      transform: translateY(-50%);
      @media ${media.greaterThan('lg')} {
        transform: translateY(-0.3rem);
      }
    `}
`;

export const BorderLabel = styled.span`
  position: absolute;
`;

export const TextArea = styled.textarea<FieldInputProps>`
  ${({ $isInputError }) => (!$isInputError ? blackBorder : noBorder)}
  border-color: ${colours.BLACK};
  border-radius: 0;
  color: ${({ $colour }) => (!$colour ? colours.BLACK : $colour)};
  height: fit-content;
  min-height: 100px;
  padding: ${spacing.XXS} ${spacing.L} 0 ${spacing.XXS};
  resize: none;
  width: 100%;
  ${TypographyStyles.Body.Medium.Regular}

  @media ${media.greaterThan('lg')} {
    min-height: 180px;
  }

  &:focus {
    ${blackBorder}
  }

  &::placeholder {
    color: transparent;
  }
`;

export const TextAreaCharacterCount = styled.span`
  ${TypographyStyles.Body.Medium.Regular}
  bottom: 8px;
  position: absolute;
  right: 12px;
`;

export const Container = styled.div`
  margin: 17px 18px 17px 14px;
  position: absolute;
  right: 0;
  top: -2px;

  @media ${media.greaterThan('lg')} {
    top: 0;
  }
`;

export const TooltipIcon = styled.button<{ $isInputError?: boolean }>`
  background: none;
  border: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;

  & path {
    fill: ${({ $isInputError }) => ($isInputError ? colours.RED : colours.BLACK)};
  }
`;

export const TooltipArrow = styled.div`
  margin-left: -${spacing.L};
  position: absolute;
  z-index: ${zCheckoutAdyenTooltip};

  &:before,
  &:after {
    border: 20px solid transparent;
    bottom: 0;
    content: '';
    position: absolute;
  }

  &:before {
    border-bottom: 30px solid ${colours.WHITE};
    border-top: 0;
    bottom: 0;
  }

  &:after {
    border-bottom: 0;
    border-top: 30px solid ${colours.WHITE};
    top: 0;
  }
`;

export const TooltipBubble = styled.div`
  background: ${colours.WHITE};
  filter: drop-shadow(0 0 1px ${colours.BLACK});
  margin-right: -20px;
  padding: 20px;
  top: 100%;
  width: 230px;
  z-index: ${zCheckoutAdyenTooltipBubble};

  &[data-popper-placement^='top'] > .arrow {
    bottom: 10px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: 10px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: 30px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: 10px;
  }

  @media ${media.greaterThan('lg')} {
    width: 300px;
  }
`;

export const TooltipBodyText = styled.p`
  color: ${colours.BLACK};
  ${TypographyStyles.Body.Tiny.Regular}
`;

export const PhoneNumberInputContainer = styled(PhoneInput)<PhoneNumberInputProps>`
  background: ${({ $disabled }) => ($disabled ? colours.LIGHT_GREY_2 : 'none')};
  ${({ $isInputError }) => (!$isInputError ? blackBorder : noBorder)}
  border-color: ${({ $isEmpty, $disabled }) => {
    if ($disabled) return colours.GREY;
    if (!$isEmpty) return colours.BLACK;
    return colours.GREY;
  }};
  border-radius: 0;
  color: ${({ $colour }) => (!$colour ? colours.BLACK : $colour)};
  height: 2em;
  min-height: 44px;
  padding: 0 ${spacing.L} 0 ${spacing.XXS};
  width: 100%;
  ${TypographyStyles.Body.Medium.Regular}

  &::-ms-reveal {
    display: none;
  }

  @media ${media.greaterThan('lg')} {
    min-height: 50px;
  }

  &:focus {
    ${({ $isInputError }) => {
      if ($isInputError) {
        return errorBorder;
      }

      return initialBorder;
    }}
  }

  &::placeholder {
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  &:disabled {
    color: ${colours.GREY};
    opacity: 1;
    -webkit-text-fill-color: ${colours.GREY};
  }
`;
