import styled from 'styled-components';
import { ImagePartial } from '../../../partials';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';
import { Skeleton } from '../../skeleton';

export const Price = styled.div`
  align-items: center;
  column-gap: ${spacing.XXXS};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 30px;
  margin-top: ${spacing.XXXS};
  padding: 0px;

  @media ${media.greaterThan('lg')} {
    margin-top: 20px;
  }
`;

export const ProductItemContainer = styled.div`
  position: relative;
`;

export const ProductLink = styled(Skeleton)`
  aspect-ratio: 1/1;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
`;

export const ImageContainer = styled.div<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  position: absolute;
  transition: opacity 0.5s ease-in-out;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
`;

export const ProductName = styled(Skeleton)<{ $lineHeight?: string; $isMultiImageName?: boolean }>`
  ${TypographyStyles.Headings.H4SemiBold}
  font-size: 18px;
  height: ${({ $isMultiImageName }) => ($isMultiImageName ? '18px' : '36px')};
  margin: ${spacing.XXS} 0;
  text-transform: uppercase;

  &.truncated {
    -webkit-box-orient: vertical;
    /* stylelint-disable value-no-vendor-prefix */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media ${media.greaterThan('lg')} {
    height: 48px;
    margin: ${spacing.XS} 0;
    width: 100%;
    &.truncated {
      white-space: unset;
    }
  }
`;

export const ProductDetails = styled.div<{ $isMultiImageCategory?: boolean }>`
  display: grid;
  margin: ${spacing.XXS} 0;
  min-height: 36px;

  @media ${media.greaterThan('lg')} {
    min-height: ${({ $isMultiImageCategory }) => ($isMultiImageCategory ? '44px' : '56px')};
  }

  & p {
    ${TypographyStyles.Body.Tiny.Regular}
    margin: 0;
    min-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;

    @media ${media.greaterThan('lg')} {
      min-height: ${({ $isMultiImageCategory }) => ($isMultiImageCategory ? '22px' : '28px')};
      ${({ $isMultiImageCategory }) => !$isMultiImageCategory && TypographyStyles.Body.Small.Regular}
    }
  }

  & p:last-child {
    place-self: end stretch;
  }
`;

export const PriceContainer = styled.div`
  min-height: 28px;
`;

export const ProductItemInfo = styled.div`
  margin-top: 90px;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    display: flex;
    gap: ${spacing.XXS};
    justify-content: center;
    margin-top: 150px;
  }
`;
export const ProductItemMainInfo = styled(Skeleton)`
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  @media ${media.greaterThan('lg')} {
    max-width: 259px;
    width: 50%;
  }
`;

export const ProductMultiImageContainer = styled.div`
  position: relative;
`;
export const ProductThumbnailSelector = styled.div`
  bottom: 0;
  display: flex;
  gap: ${spacing.XXXS};
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 103%;
  width: fit-content;
`;

export const ProductThumbnail = styled.div`
  border: 1px solid ${colours.MID_GREY};
  cursor: pointer;
  height: 70px;
  position: relative;
  width: 70px;

  @media ${media.greaterThan('lg')} {
    height: 130px;
    width: 130px;
  }
`;

export const SelectedThumbnailBorder = styled.div<{ $isSelected: boolean; $isProductColour?: boolean }>`
  background-color: ${colours.TURQUOISE};
  bottom: 0;
  height: ${({ $isProductColour }) => ($isProductColour ? '4px' : '6px')};
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
  pointer-events: none;
  position: absolute;
  top: ${({ $isProductColour }) => ($isProductColour ? '85%' : 'unset')};
  transition: opacity 0.5s ease-in-out;
  width: 100%;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
  @media ${media.greaterThan('lg')} {
    height: ${({ $isProductColour }) => ($isProductColour ? '4px' : '9px')};
    top: ${({ $isProductColour }) => $isProductColour && '90%'};
  }
`;

export const ProductColourSelectorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.M};
  margin-top: ${spacing.XXXS};

  @media ${media.greaterThan('lg')} {
    gap: ${spacing.XS};
    max-width: 261px;
    width: 50%;
  }
`;

export const ProductColourSelector = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacing.XXXS};
  min-height: ${spacing.L};
`;

export const ProductColour = styled.a<{ $isSelectedColorLink: boolean }>`
  border: ${({ $isSelectedColorLink }) => $isSelectedColorLink && `1px solid ${colours.BLACK}`};
  cursor: pointer;
  height: 32px;
  position: relative;
  width: 32px;

  @media ${media.greaterThan('lg')} {
    height: 40px;
    width: 40px;
  }
`;

export const ProductShopNowLink = styled(Skeleton)`
  background-color: ${colours.DARK_BLUE};
  color: ${colours.WHITE};
  cursor: pointer;
  ${TypographyStyles.Button.SemiBold}
  letter-spacing: 0px;
  min-height: 48px;
  padding: 14px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    min-height: 50px;
  }
`;

export const ProductMoreColors = styled.a`
  ${TypographyStyles.Body.Tiny.SemiBold}
  color: ${colours.BLACK};
  cursor: pointer;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: uppercase;
`;

export const CarouselImagePartial = styled(ImagePartial)`
  margin-bottom: ${spacing.XXXXXS};
`;

export const ImageAndBadgeContainer = styled.div`
  position: relative;
`;
