import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type NotifyBackInStockMutationVariables = Types.Exact<{
  input: Types.NotifyBackInStockInput;
}>;

export type NotifyBackInStockMutation = {
  __typename?: 'Mutation';
  notifyBackInStock: {
    __typename?: 'NotifyBackInStock';
    email: string;
    sku: string;
    status: Types.NotifyBackInStockStatus;
  };
};

export const NotifyBackInStockDocument = gql`
  mutation notifyBackInStock($input: NotifyBackInStockInput!) {
    notifyBackInStock(input: $input) {
      email
      sku
      status
    }
  }
`;
export type NotifyBackInStockMutationFn = Apollo.MutationFunction<
  NotifyBackInStockMutation,
  NotifyBackInStockMutationVariables
>;

/**
 * __useNotifyBackInStockMutation__
 *
 * To run a mutation, you first call `useNotifyBackInStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyBackInStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyBackInStockMutation, { data, loading, error }] = useNotifyBackInStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotifyBackInStockMutation(
  baseOptions?: Apollo.MutationHookOptions<NotifyBackInStockMutation, NotifyBackInStockMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<NotifyBackInStockMutation, NotifyBackInStockMutationVariables>(
    NotifyBackInStockDocument,
    options,
  );
}
export type NotifyBackInStockMutationHookResult = ReturnType<typeof useNotifyBackInStockMutation>;
export type NotifyBackInStockMutationResult = Apollo.MutationResult<NotifyBackInStockMutation>;
export type NotifyBackInStockMutationOptions = Apollo.BaseMutationOptions<
  NotifyBackInStockMutation,
  NotifyBackInStockMutationVariables
>;
