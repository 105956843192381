import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { BackButton, BlueButton } from '../../baseComponents/button';
import { SectionContainer } from '../../baseComponents/containers/SectionContainer';
import { CheckCircle } from '../../icons';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

const S = {
  ResetPasswordMessage: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    justify-content: center;
    margin: 0;
    max-width: 328px;
    padding: 0;
    @media ${media.greaterThan('lg')} {
      max-width: 408px;
    }
  `,
  TextContainer: styled.section`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    margin: 0;
  `,

  Title: styled.h4`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    @media ${media.greaterThan('lg')} {
      ${TypographyStyles.Headings.H4SemiBold}
    }
  `,
  Message: styled.p`
    ${TypographyStyles.Body.Small.Regular}
    margin: 0;
    padding: 0;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    @media ${media.greaterThan('lg')} {
      flex-direction: row;
    }
  `,
  Button: styled(BlueButton)`
    width: 100%;
  `,
  Back: styled(BackButton)`
    color: ${colours.BLACK};
    margin-bottom: 0;
  `,
  IconWrapper: styled.figure`
    margin: 0;
    padding: ${spacing.XXXXS} 0 0;
    path {
      fill: ${colours.SUCCESS_PRIMARY};
    }
  `,
};

export function ResetPasswordMessage() {
  const { t } = useTranslation(['lib-global-common']);
  const router = useRouter();
  const handleOnClick = () => {
    router.push(
      {
        pathname: '/',
      },
      undefined,
      {
        locale: router.locale,
      },
    );
  };
  return (
    <SectionContainer>
      <S.ResetPasswordMessage>
        <S.Back />
        <S.TextContainer role="status">
          <S.Info>
            <S.IconWrapper>
              <CheckCircle />
            </S.IconWrapper>
            <S.Title data-testid="forgotPasswordSentHeader">{t('forgot.password.link.sent')}</S.Title>
          </S.Info>

          <S.Message data-testid="forgotPasswordSentMessage">{t('forgot.password.link.message')}</S.Message>
        </S.TextContainer>
        <S.Button onClick={handleOnClick} data-testid="forgotPasswordContinueButton">
          {t('forgot.password.continue.shopping')}
        </S.Button>
      </S.ResetPasswordMessage>
    </SectionContainer>
  );
}

export default ResetPasswordMessage;
