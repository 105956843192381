import type { ReactElement } from 'react';
import { renderToString } from 'react-dom/server';

const getSvgDataUrl = (svgString: string): string =>
  `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;

export const createGoogleMapIcon = (iconComponent: ReactElement) => {
  const svgString = renderToString(iconComponent);
  const url = getSvgDataUrl(svgString);

  return { url };
};

export const getDirectionsUrl = (
  destinationLatLng?: { lat: number; lng: number },
  originLatLng?: { lat: number; lng: number },
): string => {
  const apiUrl = 'https://www.google.com/maps/dir/?api=1';
  const destination = destinationLatLng ? `destination=${destinationLatLng.lat},${destinationLatLng.lng}` : '';
  const origin = originLatLng ? `origin=${originLatLng.lat},${originLatLng.lng}` : '';
  const action = 'dir_action=navigate';
  const travelMode = 'travelmode=driving';

  return `${apiUrl}&${destination}&${origin}&${action}&${travelMode}`;
};

export const countryMapper: { [key: string]: string } = {
  'en-gb': 'UK',
  'en-us': 'US',
  'en-ie': 'Ireland',
  'en-ca': 'Canada',
  'fr-ca': 'Canada',
  'en-vg': 'UK',
};

export const searchRadiusByLocale: { [key: string]: number } = {
  'en-gb': 1000000,
  'en-us': 5000000,
  'en-ie': 500000,
  'en-ca': 6000000,
  'fr-ca': 6000000,
  'en-vg': 1000000,
  // Star2 locales
  'de-de': 1000000,
  'en-de': 1000000,
  'en-es': 1000000,
  'en-fr': 1000000,
  'en-it': 1000000,
  'en-nl': 1000000,
  'es-es': 1000000,
  'fr-fr': 1000000,
  'it-it': 1000000,
  'nl-nl': 1000000,
  'en-at': 1000000,
  'de-at': 1000000,
  'en-be': 1000000,
  'nl-be': 1000000,
  'fr-be': 1000000,
  'en-ch': 1000000,
  'de-ch': 1000000,
  'fr-ch': 1000000,
  'it-ch': 1000000,
  'en-dk': 1000000,
  'en-pl': 1000000,
  'it-sm': 1000000,
  'en-lu': 1000000,
  'fr-lu': 1000000,
  'en-fi': 1000000,
  'en-no': 1000000,
  'en-se': 1000000,
  'en-tr': 1000000,
  'en-si': 1000000,
  'en-sk': 1000000,
  'en-rs': 1000000,
  'en-ro': 1000000,
  'en-md': 1000000,
  'en-pt': 1000000,
  'en-mk': 1000000,
  'en-me': 1000000,
  'en-mc': 1000000,
  'en-mt': 1000000,
  'en-lt': 1000000,
  'en-li': 1000000,
  'en-lv': 1000000,
  'en-is': 1000000,
  'en-hu': 1000000,
  'en-gr': 1000000,
  'en-gi': 1000000,
  'en-ge': 1000000,
  'en-ee': 1000000,
  'en-cz': 1000000,
  'en-cy': 1000000,
  'en-hr': 1000000,
  'en-bg': 1000000,
  'en-ba': 1000000,
  'en-sm': 1000000,
};
