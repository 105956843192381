import { useRouter } from 'next/router';
import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { getHeelHeightGroupValue } from '../../../utils/algoliaFilterUtils';
import { Locale } from '../../../utils/localeHelper';
import { FilterAccordion } from './FilterAccordion';
import { FilterCheckbox } from './FilterCheckbox';
import type { FilterCheckboxProps } from './FilterCheckbox';

const S = {
  FilterLabel: styled.span`
    text-transform: capitalize;
  `,
};

const heelHeightSortWeights: Record<string, number> = {
  flat: 1,
  low: 2,
  medium: 3,
  high: 4,
};

const heelHeightFrTranslations: Record<string, string> = {
  flat: 'plat',
  low: 'bas',
  medium: 'moyen',
  high: 'haut',
};

function HeelHeightLabel({ label }: RefinementListItem) {
  const { locale } = useRouter() || {};

  return (
    <S.FilterLabel>{`${locale === Locale.FR_CA ? heelHeightFrTranslations[label] : label} (${getHeelHeightGroupValue(
      locale || '',
      label,
    )})`}</S.FilterLabel>
  );
}

export function HeelHeightFilter(props: FilterCheckboxProps & { productCount: number }) {
  const { t } = useTranslation(['lib-global-common']);
  const { items, productCount } = props;

  if (items.length === 0 || (items.length === 1 && items[0].count === productCount)) return null;

  const isOpen = !!items.find((item) => item.isRefined);

  const sortedItems = items.sort(
    (itemA, itemB) => heelHeightSortWeights[itemA.label] - heelHeightSortWeights[itemB.label],
  );

  return (
    <FilterAccordion
      isOpen={isOpen}
      triggerLabel={t('filter.menu.filter.heel.height')}
      content={<FilterCheckbox {...props} items={sortedItems} labelComponent={HeelHeightLabel} />}
    />
  );
}
