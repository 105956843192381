import styled from 'styled-components';
import { TypographyStyles, colours, media } from '../../stylings';

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  ${TypographyStyles.Body.Tiny.Regular}

  @media screen and (min-width: 376px) {
    width: 100%;
  }

  ${media.greaterThan('lg')} {
    width: auto;
  }
`;

export const Wrapper = styled.span<{ $isChecked: boolean; $isValidationError?: boolean }>`
  background-color: ${({ $isChecked }) => $isChecked && colours.DARK_BLUE};
  border: solid 1px ${colours.DARK_BLUE};
  border-color: ${({ $isValidationError }) => ($isValidationError ? colours.RED : colours.DARK_BLUE)};
  margin: 0;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  padding: 0;
  position: relative;

  &:focus-within {
    outline: 2px solid ${colours.OUTLINE_BORDER};
  }

  @media ${media.greaterThan('lg')} {
    max-height: 24px;
    max-width: 24px;
    min-height: 24px;
    min-width: 24px;
  }
`;

export const IconWrapper = styled.span`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 1.5px;
  position: absolute;
  top: 0;
  width: 100%;

  svg {
    border-radius: 0px;
    height: 15.1px;
    width: 15.1px;

    path {
      fill: ${colours.WHITE};
    }
  }
`;

export const Input = styled.input`
  accent-color: ${colours.DARK_BLUE};
  appearance: none;
  border: none;
  height: 0;
  min-height: 20px;
  min-width: 20px;
  opacity: 0;
  position: absolute;
  width: 0;

  @media ${media.greaterThan('lg')} {
    min-height: 24px;
    min-width: 24px;
  }
`;
