import getConfig from 'next/config';
import type { AncestorCategory, PathCategory, Variant } from '../codegen/types';
import { getAmount } from './currencyHelper';
import { Locale } from './localeHelper';
import { findMostExpensiveSku } from './price';

const config = getConfig()?.publicRuntimeConfig || process.env;

const homeTranslationMapper: { [key: string]: string } = {
  en: 'Home',
  fr: 'Accueil',
};

export function addProductJson(
  name?: string,
  description?: string,
  imageUrls?: string[],
  variants?: Variant[],
  url?: string,
) {
  const mosExpensiveSku = findMostExpensiveSku({ variants });

  return {
    __html: JSON.stringify(
      {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        '@id': url,
        name: name ?? '',
        description: description ?? '',
        image: imageUrls?.[0] ?? '',
        offers: {
          '@type': 'Offer',
          price: +getAmount(mosExpensiveSku?.actualPrice.centAmount) ?? 0,
          priceCurrency: mosExpensiveSku?.actualPrice.currencyCode ?? '',
          itemCondition: 'https://schema.org/NewCondition',
          availability: variants?.find((item) => item.stock.status === 'inStock')
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
        },
      },
      null,
      2,
    ),
  };
}

export function addBreadcrumbListJson(
  categoriesPath?: PathCategory[] | AncestorCategory[],
  locale?: string,
  name?: string, // to be used with category pages only
  currentCategoryKey?: string,
  currentCategorySlug?: string,
) {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
  };

  const itemListElement = [
    {
      '@type': 'ListItem',
      position: 1,
      name: homeTranslationMapper[locale?.substring(0, locale.indexOf('-')) || 'en'],
      item: `${config.STOREFRONT_CDN_URL}/${locale}`,
    },
  ];

  const categoriesData =
    categoriesPath?.map((category, idx) => ({
      '@type': 'ListItem',
      position: idx + 2,
      name: category?.name || '',
      item: `${config.STOREFRONT_CDN_URL}/${locale}/${category.slug}/${category.key}-c`,
    })) || [];

  // to add the category page itself to the schema
  if (name && currentCategoryKey && currentCategorySlug) {
    categoriesData.push({
      '@type': 'ListItem',
      position: categoriesData.length + 2,
      name,
      item: `${config.STOREFRONT_CDN_URL}/${locale}/${currentCategorySlug}/${currentCategoryKey}-c`,
    });
  }
  itemListElement?.push(...categoriesData);

  return {
    __html: JSON.stringify({ ...structuredData, ...{ itemListElement } }, null, 2),
  };
}

export function addWebsiteJsonLd(locale: string) {
  return {
    __html: JSON.stringify(
      {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: 'Clarks',
        url:
          locale === Locale.STOREFRONT_OUTLET_LOCALE
            ? 'https://www.clarksoutlet.co.uk/'
            : `https://www.clarks.com/${locale}`,
        potentialAction: {
          '@type': 'SearchAction',
          target:
            locale === Locale.STOREFRONT_OUTLET_LOCALE
              ? 'https://www.clarksoutlet.co.uk/search?query={search_term}'
              : `https://www.clarks.com/${locale}/search?query={search_term}`,
          'query-input': 'required name=search_term',
        },
      },
      null,
      4,
    ),
  };
}
