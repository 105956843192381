import type { ImageLoaderProps } from 'next/image';
import { useTranslation } from 'next-i18next';
import type { Maybe, Order, TrackingItem } from '../../../codegen/types';
import { ImagePartial } from '../../../partials';
import { getCurrencySymbol } from '../../../utils';
import { FinalSale } from '../../finalSale/FinalSale';
import { TableRow } from './TableRow';
import {
  Canceled,
  CardDetails,
  CardImageContainer,
  FinalSaleContainer,
  Name,
  Price,
  Table,
  TrackingCard,
} from './Tracking.styles';

const cloudinaryLoader = ({ src: srcProp, quality: qualityProp, width }: ImageLoaderProps) => {
  const params = [`w_${width}`, `h_${width}`, `q_${qualityProp || 'auto'}`, 'f_webp'];
  const textUpload = '/upload/';
  return srcProp.replace(textUpload, `${textUpload}${params.join(',')}/`);
};

export enum ItemStatus {
  CANCELED = 'Cancelled',
}

export function TrackingProductCard({
  product,
  currency,
  isCanceled: shipmentIsCancelled,
}: {
  product?: Maybe<TrackingItem>;
  currency: Order['currency'];
  isCanceled: boolean;
}) {
  const {
    backgroundColor: finalSaleBackgroundColor,
    text: finalSaleText,
    textColor: finalSaleTextColor,
  } = product?.finalSale ?? {};
  const { t } = useTranslation(['lib-global-common']);
  const isCanceled = shipmentIsCancelled ? false : product?.status?.toLowerCase() === ItemStatus.CANCELED.toLowerCase();

  return (
    <TrackingCard $isCanceled={isCanceled}>
      <CardImageContainer>
        <ImagePartial src={product?.imageUrl ?? ''} objectFit="contain" layout="fill" loader={cloudinaryLoader} />
      </CardImageContainer>
      <CardDetails>
        <Name data-testid="productName">{product?.name}</Name>
        <div>
          {!isCanceled && (
            <Price data-testid="productPrice">
              {getCurrencySymbol(currency ?? '')}
              {product?.price}
            </Price>
          )}
          {finalSaleText && (
            <FinalSaleContainer>
              <FinalSale
                text={finalSaleText}
                textColor={finalSaleTextColor}
                backgroundColor={finalSaleBackgroundColor}
              />
            </FinalSaleContainer>
          )}
          <Table>
            <tbody>
              {product?.size && (
                <TableRow property={t('order.details.tracking.card.size')} value={product.size} testId="productSize" />
              )}
              {product?.color && (
                <TableRow
                  property={t('order.details.tracking.card.colour')}
                  value={product.color}
                  testId="productColor"
                />
              )}
              {product?.width && <TableRow property={t('order.details.tracking.card.width')} value={product.width} />}
              {product?.quantity && (
                <TableRow
                  property={t('order.details.tracking.card.quantity')}
                  value={product.quantity}
                  testId="productQuantity"
                />
              )}
              {product?.giftCardRecipientEmail && (
                <TableRow
                  property={t('order.details.tracking.card.email')}
                  value={product.giftCardRecipientEmail}
                  testId="email"
                />
              )}
              {product?.giftCardRecipient && (
                <TableRow
                  property={t('order.details.tracking.card.to')}
                  value={product.giftCardRecipient}
                  testId="giftCardRecipient"
                />
              )}
              {product?.giftCardSender && (
                <TableRow
                  property={t('order.details.tracking.card.from')}
                  value={product.giftCardSender}
                  testId="giftCardSender"
                />
              )}
              {product?.giftCardMessage && (
                <TableRow
                  property={t('order.details.tracking.card.message')}
                  value={product.giftCardMessage}
                  testId="giftCardMessage"
                />
              )}
            </tbody>
          </Table>
        </div>
        {isCanceled && <Canceled>{t('order.details.tracking.card.canceled')}</Canceled>}
      </CardDetails>
    </TrackingCard>
  );
}
