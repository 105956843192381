import { format } from 'date-fns';
import { AppointmentsDocument } from '../graphqlDocument/appointmentBooking/appointments.generated';
import { BookAppointmentDocument } from '../graphqlDocument/appointmentBooking/bookAppointment.generated';
import { CancelReservationDocument } from '../graphqlDocument/appointmentBooking/cancelReservation.generated';
import { getTomorrow } from '../utils';
import { generateTimeSlots } from './mockData';

export const appointmentBookingInputMock = {
  email: 'test@email.com',
  firstName: 'Name',
  lastName: 'Last',
  mobile: '+123',
  notes: 'note',
  reservation: '1231231ff3',
  store: '0010',
};

const bookAppointmentMock = {
  request: {
    query: BookAppointmentDocument,
    variables: {
      input: {
        ...appointmentBookingInputMock,
      },
    },
  },
  result: {
    data: {
      bookAppointment: {
        id: '1231231ff3',
        store: '0010',
        start: '2023-04-10T10:00:00.000Z',
        end: '2023-04-10T10:15:00.000Z',
        firstName: 'Name',
        lastName: 'Last',
        spaces: 6,
      },
    },
  },
};

const cancelReservationMock = {
  request: {
    query: CancelReservationDocument,
    variables: {
      input: {
        reservation: '1231231ff3',
        store: '0010',
      },
    },
  },
  result: {
    data: {
      cancelReservation: {
        reservation: '1231231ff3',
        store: '0010',
      },
    },
  },
};

const getStoreSlotsMock = {
  request: {
    query: AppointmentsDocument,
    variables: {
      input: {
        date: format(getTomorrow(), 'yyyy-MM-dd'),
        store: '0010',
        spaces: 4,
      },
    },
  },
  result: {
    data: {
      appointments: {
        slots: generateTimeSlots(12, 20, 15),
      },
    },
  },
};
export const appointmentMocks = [bookAppointmentMock, cancelReservationMock, getStoreSlotsMock];
