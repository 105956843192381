import styled from 'styled-components';
import { ErrorMessage as ErrorMessageComponent } from '../../../baseComponents/errors';
import { Fieldset } from '../../../baseComponents/inputs/Fieldset';
import { TypographyStyles, colours, fontWeights, media, spacing } from '../../../stylings';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 328px;
  @media ${media.greaterThan('lg')} {
    max-width: 408px;
  }

  a {
    ${TypographyStyles.Body.Tiny.Medium}
    color: ${colours.NOBEL_GREY};
  }
`;
export const TermsAndCondition = styled.p`
  margin: 0;
  padding: 0;
  ${TypographyStyles.Body.Tiny.Regular}

  @media ${media.greaterThan('lg')} {
    line-height: 16px;
  }

  a {
    ${fontWeights.SemiBold600}
    color: ${colours.BLACK};
  }
`;
export const PasswordHint = styled.p`
  color: ${colours.BLACK};
  display: flex;
  margin: 0;
  margin-bottom: ${spacing.XS};
  width: 298px;
  ${TypographyStyles.Body.Tiny.Regular}
`;

export const AllFields = styled(Fieldset)`
  border: none;
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
  margin: 0;
  padding: 0;
`;

export const MarketingChoices = styled(Fieldset)`
  border: none;
  margin: 0;
  margin-bottom: ${spacing.M};
  padding: 0;
`;

export const MasterCheckboxContainer = styled.div`
  margin-bottom: ${spacing.XXXS};
`;

export const EmailChoices = styled.div<{ $itemLength: number }>`
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (min-width: 376px) {
    display: ${({ $itemLength }) => ($itemLength === 5 ? 'grid' : 'flex')};
    flex-direction: row;
    gap: ${({ $itemLength }) => ($itemLength === 5 ? '5px 10px' : '10px')};
    justify-content: flex-start;
    ${({ $itemLength }) => $itemLength === 5 && { gridTemplateColumns: 'repeat(3, 120px)' }}
  }

  @media ${media.greaterThan('lg')} {
    display: grid;
    gap: ${({ $itemLength }) => ($itemLength === 5 ? '10px 20px' : '20px')};
    grid-template-columns: ${({ $itemLength }) =>
      $itemLength === 3 ? `repeat(${$itemLength}, f1r)` : `repeat(4, 1fr)`};
  }
`;

export const SubscribeText = styled.span`
  ${TypographyStyles.Body.Small.Regular}
  margin-bottom: ${spacing.XXXS};
`;
export const Headline = styled.p`
  margin: 0;
  margin-bottom: ${spacing.XS};
  padding: 0;
  ${TypographyStyles.Body.Small.Regular}
`;
export const TransLink = styled.a`
  text-decoration: underline;
`;

export const ErrorMessage = styled(ErrorMessageComponent)`
  padding-bottom: ${spacing.XXXS};
`;
