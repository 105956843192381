import { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { BlueButton } from '../../baseComponents/button';
import { DialogContent, DialogOverlay, DialogPortal, DialogRoot } from '../../baseComponents/dialog';
import { Select } from '../../baseComponents/select/Select';
import { useSiteSwitcher } from '../../hooks/useSiteSwitcher';
import { useStar2 } from '../../hooks/useStar2';
import { TypographyStyles, spacing } from '../../stylings';
import {
  Locale,
  STAR1CountryKeys,
  countryNameMapper,
  countryNameMapperROE,
  getCountryKey,
  getLocale,
} from '../../utils/localeHelper';

const S = {
  CloseContainer: styled.div``,
  Content: styled(DialogContent)`
    display: flex;
    flex-direction: column;
    gap: ${spacing.M};
    max-height: 85vh;
    max-width: 85vw;
    padding: ${spacing.M};
    width: 430px;

    .location,
    .language {
      display: flex;
      flex-direction: column;
      gap: ${spacing.XXXS};
    }
  `,
  Title: styled.h3`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  `,
  CopyText: styled.span`
    ${TypographyStyles.Body.Small.Regular}
    margin: 0;
    padding: 0;
  `,
  DialogBodyWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.S};
  `,
  LabelText: styled.span`
    ${TypographyStyles.Label.Large.Medium}
    text-transform: uppercase;
  `,
  Visit: styled(BlueButton)`
    margin-bottom: 0;
    width: 100%;
  `,
};

export function NoLocationPopUp({ hasLocationInfo }: { hasLocationInfo: boolean }) {
  const router = useRouter();
  const { t } = useTranslation(['lib-global-common']);

  const {
    dialogSelectedCountry,
    dialogSelectedLanguage,
    onChangeCountry,
    onChangeLanguage,
    updateDefaultLocaleCookie,
    selectedCountry,
    siteSwitcherSortedData,
    locale,
    updatePreferredSiteCookie,
  } = useSiteSwitcher();
  const { isStar2 } = useStar2();

  const [showPopUp, setShowPopUp] = useState(!hasLocationInfo);

  const togglePopUp = () => {
    setShowPopUp((prev) => !prev);
  };
  const onVisit = () => {
    updateDefaultLocaleCookie();
    updatePreferredSiteCookie();
    if (dialogSelectedLanguage?.toLowerCase() !== router.locale) {
      router.push(
        {
          pathname: router.asPath,
        },
        undefined,
        {
          locale: dialogSelectedLanguage?.toLowerCase(),
        },
      );
    }
    togglePopUp();
  };

  if ([Locale.STOREFRONT_OUTLET_LOCALE, Locale.CT_OUTLET_LOCALE].includes(locale as string)) return null;

  if (!siteSwitcherSortedData || !selectedCountry || !dialogSelectedCountry || !dialogSelectedLanguage) return null;

  return (
    <DialogRoot onOpenChange={togglePopUp} open={showPopUp}>
      <DialogPortal>
        <DialogOverlay />
        <S.Content
          data-testid="noLocationPopup"
          onEscapeKeyDown={updateDefaultLocaleCookie}
          onCloseAutoFocus={updateDefaultLocaleCookie}
          onInteractOutside={updateDefaultLocaleCookie}
        >
          <S.Title>{t('footer.siteswitcher.no.redirect.title')}</S.Title>
          <S.CopyText>{t('footer.siteswitcher.no.redirect.copytext')}</S.CopyText>
          <S.DialogBodyWrapper>
            <div className="location">
              <S.LabelText id="footer-siteswitcher-shopin">{t('footer.siteswitcher.shopin')}</S.LabelText>
              <Select
                data-testid="selectCountry"
                value={dialogSelectedCountry.key}
                onChange={onChangeCountry}
                aria-labelledby="footer-siteswitcher-shopin"
              >
                {siteSwitcherSortedData.map((country) => (
                  <option key={country.key} value={country.key}>
                    {getLocale(country.countryName, locale)?.value}
                  </option>
                ))}
              </Select>
            </div>
            {dialogSelectedCountry.languages.length > 1 && (
              <div className="language">
                <S.LabelText id="footer-siteswitcher-language">{t('footer.siteswitcher.language')}</S.LabelText>
                <Select
                  data-testid="selectLanguage"
                  value={dialogSelectedLanguage}
                  onChange={onChangeLanguage}
                  aria-labelledby="footer-siteswitcher-language"
                >
                  {dialogSelectedCountry.languages.map((lang) => (
                    <option key={lang} value={lang}>
                      {t(`language.${lang.split('-')[0]}`)}
                    </option>
                  ))}
                </Select>
              </div>
            )}
          </S.DialogBodyWrapper>
          <S.Visit onClick={onVisit}>{`${t('footer.siteswitcher.no.redirect.visit')} ${
            STAR1CountryKeys.includes(dialogSelectedCountry.key)
              ? dialogSelectedCountry.key.toUpperCase()
              : countryNameMapper[getCountryKey(dialogSelectedCountry.key).toUpperCase()] ||
                countryNameMapperROE[getCountryKey(dialogSelectedCountry.key).toUpperCase()]
          }`}</S.Visit>
        </S.Content>
      </DialogPortal>
    </DialogRoot>
  );
}
