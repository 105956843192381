import {
  AustraliaFlag,
  AustriaFlag,
  BelgiumFlag,
  BosniaAndHerzegovinaFlag,
  BulgariaFlag,
  CanadaFlag,
  ChinaFlag,
  CroatiaFlag,
  CyprusFlag,
  CzechRepublicFlag,
  DenmarkFlag,
  EUFlag,
  EstoniaFlag,
  FinlandFlag,
  FranceFlag,
  GeorgiaFlag,
  GermanyFlag,
  GibraltarFlag,
  GreeceFlag,
  HungaryFlag,
  IcelandFlag,
  IndiaFlag,
  IrelandFlag,
  ItalyFlag,
  JapanFlag,
  KoreaFlag,
  LatviaFlag,
  LiechtensteinFlag,
  LithuaniaFlag,
  LuxembourgFlag,
  MalaysiaFlag,
  MaltaFlag,
  MonacoFlag,
  MontenegroFlag,
  NetherlandsFlag,
  NorthMacedoniaFlag,
  NorwayFlag,
  PolandFlag,
  PortugalFlag,
  RepublicOfMoldovaFlag,
  RomaniaFlag,
  SanMarinoFlag,
  SerbiaFlag,
  SingaporeFlag,
  SlovakiaFlag,
  SloveniaFlag,
  SpainFlag,
  SwedenFlag,
  SwitzerlandFlag,
  TurkeyFlag,
  UAEFlag,
  UKFlag,
  USAFlag,
} from '../../icons';

export function CountryFlag({ country, viewBox }: { country: string; viewBox?: string }) {
  switch (country.toLocaleLowerCase()) {
    case 'ca':
    case 'canada':
      return <CanadaFlag viewBox={viewBox} />;
    case 'us':
    case 'américain':
      return <USAFlag viewBox={viewBox} />;
    case 'roi':
    case 'ie':
      return <IrelandFlag viewBox={viewBox} />;
    case 'uk':
    case 'gb':
    case 'britannique':
      return <UKFlag viewBox={viewBox} />;
    case 'eu':
    case 'européen':
      return <EUFlag viewBox={viewBox} />;
    case 'uae':
      return <UAEFlag viewBox={viewBox} />;
    case 'india':
    case 'inde':
      return <IndiaFlag viewBox={viewBox} />;
    case 'japan':
    case 'japon':
      return <JapanFlag viewBox={viewBox} />;
    case 'malaysia':
    case 'malaisie':
      return <MalaysiaFlag viewBox={viewBox} />;
    case 'china':
    case 'chine':
      return <ChinaFlag viewBox={viewBox} />;
    case 'australia':
    case 'australie':
      return <AustraliaFlag viewBox={viewBox} />;
    case 'singapore':
    case 'singapour':
      return <SingaporeFlag viewBox={viewBox} />;
    case 'korea':
    case 'coréen':
      return <KoreaFlag viewBox={viewBox} />;
    case 'at':
      return <AustriaFlag viewBox={viewBox} />;
    case 'be':
      return <BelgiumFlag viewBox={viewBox} />;
    case 'ba':
      return <BosniaAndHerzegovinaFlag viewBox={viewBox} />;
    case 'bg':
      return <BulgariaFlag viewBox={viewBox} />;
    case 'hr':
      return <CroatiaFlag viewBox={viewBox} />;
    case 'cy':
      return <CyprusFlag viewBox={viewBox} />;
    case 'cz':
      return <CzechRepublicFlag viewBox={viewBox} />;
    case 'dk':
      return <DenmarkFlag viewBox={viewBox} />;
    case 'ee':
      return <EstoniaFlag viewBox={viewBox} />;
    case 'fi':
      return <FinlandFlag viewBox={viewBox} />;
    case 'fr':
      return <FranceFlag viewBox={viewBox} />;
    case 'ge':
      return <GeorgiaFlag viewBox={viewBox} />;
    case 'de':
      return <GermanyFlag viewBox={viewBox} />;
    case 'gi':
      return <GibraltarFlag viewBox={viewBox} />;
    case 'gr':
      return <GreeceFlag viewBox={viewBox} />;
    case 'hu':
      return <HungaryFlag viewBox={viewBox} />;
    case 'is':
      return <IcelandFlag viewBox={viewBox} />;
    case 'it':
      return <ItalyFlag viewBox={viewBox} />;
    case 'lv':
      return <LatviaFlag viewBox={viewBox} />;
    case 'li':
      return <LiechtensteinFlag viewBox={viewBox} />;
    case 'lt':
      return <LithuaniaFlag viewBox={viewBox} />;
    case 'lu':
      return <LuxembourgFlag viewBox={viewBox} />;
    case 'mt':
      return <MaltaFlag viewBox={viewBox} />;
    case 'mc':
      return <MonacoFlag viewBox={viewBox} />;
    case 'me':
      return <MontenegroFlag viewBox={viewBox} />;
    case 'nl':
      return <NetherlandsFlag viewBox={viewBox} />;
    case 'mk':
      return <NorthMacedoniaFlag viewBox={viewBox} />;
    case 'no':
      return <NorwayFlag viewBox={viewBox} />;
    case 'pl':
      return <PolandFlag viewBox={viewBox} />;
    case 'pt':
      return <PortugalFlag viewBox={viewBox} />;
    case 'md':
      return <RepublicOfMoldovaFlag viewBox={viewBox} />;
    case 'ro':
      return <RomaniaFlag viewBox={viewBox} />;
    case 'sm':
      return <SanMarinoFlag viewBox={viewBox} />;
    case 'rs':
      return <SerbiaFlag viewBox={viewBox} />;
    case 'sk':
      return <SlovakiaFlag viewBox={viewBox} />;
    case 'si':
      return <SloveniaFlag viewBox={viewBox} />;
    case 'es':
      return <SpainFlag viewBox={viewBox} />;
    case 'se':
      return <SwedenFlag viewBox={viewBox} />;
    case 'ch':
      return <SwitzerlandFlag viewBox={viewBox} />;
    case 'tr':
      return <TurkeyFlag viewBox={viewBox} />;
    default:
      return null;
  }
}

export default CountryFlag;
