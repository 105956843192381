import { Cookies } from './cookies';
import { isStar2Check } from './globalEUtils';
import { Locale, LocaleStar2, getLanguageFromLocale } from './localeHelper';

const OneTrustIdStar1LocaleMap: { [key: string]: string } = {
  [Locale.EN_GB]: 'ec1abcdd-a34c-4c84-b5b5-fcf9a8ae65ed',
  [Locale.EN_US]: '7df076bf-1918-4a0d-acbc-32161e8e4e4c',
  [Locale.EN_CA]: '61ffc7f8-f3c3-402a-88f4-41f0047e4546',
  [Locale.FR_CA]: '92bc0eb8-c337-47a2-bfd1-9824f8dcea01',
  [Locale.EN_IE]: 'a71bece0-bcd0-45dc-9be0-ff3201271a27',
  [Locale.STOREFRONT_OUTLET_LOCALE]: '537b1391-a333-4682-80a8-070b5bd76986',
};

const OneTrustIdStar2LocaleMap: { [key: string]: string } = {
  [LocaleStar2.DE_DE]: '018e0eee-b1c7-7bb5-ba59-bd87a69364ac',
  [LocaleStar2.EN_DE]: '018e65cc-48e3-7086-afb4-4c8498270a17',
  [LocaleStar2.EN_ES]: '018e65c8-3391-72f8-aabe-c42e1c94ce08',
  [LocaleStar2.EN_FR]: '018e65c9-676b-72b1-8bfe-42faa52bdc00',
  [LocaleStar2.EN_IT]: '018e65c6-08e1-75aa-ad6b-7ead0916d765',
  [LocaleStar2.EN_NL]: '018e65c7-204c-7ecc-9c0e-4d1beff4ab81',
  [LocaleStar2.ES_ES]: '018e0ef0-55a1-7f9a-acfb-31dcc401072e',
  [LocaleStar2.FR_FR]: '018e0ef2-3517-7ffe-8023-4cf5d798743f',
  [LocaleStar2.IT_IT]: '018e0eeb-e415-7e78-8fb4-c459a61675f1',
  [LocaleStar2.NL_NL]: '018e0eed-eab7-7125-9110-9b94df1b1d7a',
  [LocaleStar2.EN_AT]: '018e76a6-c3ea-793d-94c3-cf8ee4e078ca',
  [LocaleStar2.EN_BA]: '018e76a4-ae04-7da0-b620-22d99ec99349',
  [LocaleStar2.EN_BE]: '018e76a7-dafb-7f21-993a-cbc47a8c0f88',
  [LocaleStar2.EN_BG]: '018e76a3-fd28-766a-89fc-e489e700f87f',
  [LocaleStar2.EN_CH]: '018e76aa-baec-72ba-9724-21cd7e8f3106',
  [LocaleStar2.EN_CY]: '018e76a2-91ec-76dc-ac45-1773dc721e18',
  [LocaleStar2.EN_CZ]: '018e76a1-eb83-7178-bca6-7c965a25f54a',
  [LocaleStar2.EN_DK]: '018e76a1-1779-7df3-88b9-2ed13c98ee94',
  [LocaleStar2.EN_EE]: '018e76a0-6d1c-7499-bd36-ef9b40e8aaae',
  [LocaleStar2.EN_FI]: '018e769f-648f-7c3c-9dd3-1c4049205ac9',
  [LocaleStar2.EN_GE]: '018e769e-ca78-7d14-a290-ebead9340eac',
  [LocaleStar2.EN_GI]: '018e769e-3252-7a90-8459-1a6cedde284e',
  [LocaleStar2.EN_GR]: '018e769d-abec-798d-b59b-35611eeb4dd2',
  [LocaleStar2.EN_HU]: '018e769c-f745-7e51-97b7-32c63a162e0f',
  [LocaleStar2.EN_IS]: '018e769c-61a4-788f-b88c-0d028f4c3ddf',
  [LocaleStar2.EN_LI]: '018e769a-ba67-7708-8af9-5840a0a91546',
  [LocaleStar2.EN_LT]: '018e769a-1882-707d-8e81-c144369e993d',
  [LocaleStar2.EN_LU]: '018e76a8-beb6-75c3-a718-204f6628b5ca',
  [LocaleStar2.EN_LV]: '018e769b-9795-76c7-b27a-a8136bbf7999',
  [LocaleStar2.EN_MC]: '018e7698-d458-7a2f-9a9e-ead863021fd5',
  [LocaleStar2.EN_MD]: '018e7694-c0aa-71e5-931a-e1a802f621c0',
  [LocaleStar2.EN_ME]: '018e7697-d606-70fd-be16-a5b127900d02',
  [LocaleStar2.EN_MK]: '018e7697-3d38-707a-a932-0d45f06cbe8d',
  [LocaleStar2.EN_MT]: '018e7699-7015-7e03-ac64-9a91e85bb7eb',
  [LocaleStar2.EN_NO]: '018e7696-9025-7b8e-8500-daa56fdbbcd8',
  [LocaleStar2.EN_PL]: '018e7695-751a-7a7e-9c0a-2c556013ea43',
  [LocaleStar2.EN_PT]: '018e75ea-9e62-7d10-8192-594c8c22fa1a',
  [LocaleStar2.EN_RO]: '018e7693-ee26-7014-b0a4-8dd9b6f12544',
  [LocaleStar2.EN_RS]: '018e7692-e1fd-79bf-bafc-8d446f297b85',
  [LocaleStar2.EN_SE]: '018e7690-53ab-7c4e-b661-58de63b0368f',
  [LocaleStar2.EN_SI]: '018e7691-8149-704c-b9dd-f67ab66f20dd',
  [LocaleStar2.EN_SK]: '018e7692-3957-76dc-ae28-8e0ce30a4e41',
  [LocaleStar2.EN_SM]: '018e76a9-7e2d-7f42-9cc4-55dc1c83041c',
  [LocaleStar2.EN_TR]: '018e768f-0a1b-7f7a-aae0-58b366291724',
  [LocaleStar2.EN_HR]: '018e76a3-27da-7201-9eca-40ba418022d7',
  [LocaleStar2.DE_CH]: '018e76ac-e341-7cf9-8cab-7abba769e546',
  [LocaleStar2.FR_BE]: '018e76b0-7e5a-7b9a-bfce-d29102af32d7',
  [LocaleStar2.FR_CH]: '018e76ad-f770-78f0-afe7-dc56ac8c560a',
  [LocaleStar2.IT_SM]: '018e76ae-bdb7-7877-b975-0f5505877b63',
};

const OneTrustIdStar2Default = '018e191a-c416-74dc-a960-b78550b30cd3';

const OneTrustIdStar2MultipleLanguages: { [key: string]: string } = {
  de: '018e75e9-570a-71ae-a525-0fcfd15f9c30',
  it: '018e75f3-f2b3-7795-88d5-9d0751f74a55',
  fr: '018e75f4-b2bc-7db2-8845-23f129aa2c09',
  nl: '018e191a-c416-74dc-a960-b78550b30cd3',
  en: '018e75ea-9e62-7d10-8192-594c8c22fa1a',
};

export const getOneTrustId = (locale: string) => {
  const language = getLanguageFromLocale(locale);

  let oneTrustId;

  if (!isStar2Check({ locale })) {
    oneTrustId = OneTrustIdStar1LocaleMap[locale];
  }

  if (isStar2Check({ locale })) {
    if (Object.keys(OneTrustIdStar2LocaleMap).includes(locale)) {
      oneTrustId = OneTrustIdStar2LocaleMap[locale];
    } else if (Object.keys(OneTrustIdStar2MultipleLanguages).includes(language)) {
      oneTrustId = OneTrustIdStar2MultipleLanguages[language];
    } else {
      oneTrustId = OneTrustIdStar2Default;
    }
  }

  if (!oneTrustId) return null;

  oneTrustId = process.env.NEXT_PUBLIC_ENV_NAME === 'prod' ? oneTrustId : `${oneTrustId}-test`;

  return oneTrustId;
};

export const reloadOneTrustBanner = () => {
  const params = new URLSearchParams(Cookies.get('OptanonConsent') ?? '');
  const groups = params.get('groups')?.split(',') ?? [];
  if (groups.length > 0) return;

  // Reload One Trust Banner
  const otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (window.OneTrust !== undefined) {
    window.OneTrust.Init();

    setTimeout(() => {
      // 'Single Page Application Support' flag in OneTrust must be set to true
      window.OneTrust.LoadBanner();
    }, 1000);
  }
};
