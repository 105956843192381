import { useEffect } from 'react';
import { useInViewRef } from 'rooks';
import styled from 'styled-components';
import type {
  BackgroundColor,
  MarketingMessageLink,
  MarketingMessage as MarketingMessageSchema,
} from '@amplience/content-types/typings/c-marketing-message';
import { useOverlayContext } from '../../context/overlayContext';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { useColor } from '../../partials/color';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import { LinkPartial } from '../../partials/link';
import { TypographyStylesType, colours, media, spacing } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';

export type MarketingMessageProps = MarketingMessageSchema;
const S = {
  MarketingMessage: styled.div`
    margin: 0;
    width: 100%;
  `,
  MessageList: styled.ul<{ itemCount?: number; backgroundColor?: BackgroundColor }>`
    background-color: ${({ backgroundColor }) => backgroundColor || colours.TURQUOISE};
    display: grid;
    gap: 1px;
    grid-template-columns: ${({ itemCount }) => {
      if ((itemCount || 0) === 1) {
        return `repeat(${1}, minmax(50px, 100%))`;
      }
      return `repeat(${2}, minmax(50px, 100%))`;
    }};

    list-style: none;
    margin: 0;
    padding: 0;

    place-items: center;
    li:nth-child(3) {
      &:last-child {
        grid-column: 1 / -1;

        @media ${media.greaterThan('lg')} {
          grid-column: auto;
        }
      }
    }
    @media ${media.greaterThan('lg')} {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: ${({ itemCount }) => `repeat(${itemCount}, minmax(200px, 100%))`};
      place-items: center;
    }
  `,
  MessageItem: styled.li`
    align-items: center;
    color: ${colours.WHITE};
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing.XS};
    height: 100%;
    justify-content: center;
    max-width: 1440px;
    min-height: 28px;
    overflow: hidden;
    width: 100%;

    @media ${media.greaterThan('lg')} {
      flex-wrap: nowrap;
      padding: ${spacing.XXXS} 70px;
    }
  `,
  LinkPartial: styled(LinkPartial)<{ $textColor: string; $defaultTextColor: string }>`
    ${({ $textColor, $defaultTextColor }) => useColor($textColor, $defaultTextColor)}
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 1.5px;
  `,

  DesktopText: styled(FlexibleTextPartial)`
    display: none;

    @media ${media.greaterThan('lg')} {
      display: unset;
    }
  `,

  MobileText: styled(FlexibleTextPartial)`
    @media ${media.greaterThan('lg')} {
      display: none;
    }
  `,
};

function Message({ marketingMessageLink }: { marketingMessageLink: MarketingMessageLink }) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  return (
    <>
      <S.MobileText
        aria-hidden={isDesktop}
        {...marketingMessageLink.messageMobilev2}
        defaultTextColor={colours.BLACK}
        defaultTextSize={TypographyStylesType.CTA_TINY_SEMIBOLD}
      >
        {getLocalizedValue(marketingMessageLink.messageMobilev2?.text)}
      </S.MobileText>
      <S.DesktopText
        {...(marketingMessageLink.messageDesktopv2 || marketingMessageLink.messageMobilev2)}
        aria-hidden={!isDesktop}
        defaultTextColor={colours.BLACK}
        defaultTextSize={TypographyStylesType.CTA_TINY_SEMIBOLD}
      >
        {getLocalizedValue(marketingMessageLink.messageDesktopv2?.text || marketingMessageLink.messageMobilev2?.text)}
      </S.DesktopText>
    </>
  );
}

function MessageLink({ marketingMessageLink }: { marketingMessageLink: MarketingMessageLink }) {
  const { link } = marketingMessageLink;
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const label = getLocalizedValue(
    isDesktop
      ? marketingMessageLink.messageDesktopv2?.text || marketingMessageLink.messageMobilev2?.text
      : marketingMessageLink.messageMobilev2?.text,
  );

  if (link) {
    return (
      <S.LinkPartial
        {...link}
        ariaLabel={label}
        $textColor={
          isDesktop
            ? marketingMessageLink.messageDesktopv2?.textColor || marketingMessageLink.messageMobilev2?.textColor
            : marketingMessageLink.messageMobilev2?.textColor
        }
        $defaultTextColor={colours.BLACK}
      >
        <Message marketingMessageLink={marketingMessageLink} />
      </S.LinkPartial>
    );
  }

  return <Message marketingMessageLink={marketingMessageLink} />;
}

function MessageList({
  marketingComponent,
  backgroundColor,
}: {
  marketingComponent: MarketingMessageProps['marketingComponent'];
  backgroundColor?: BackgroundColor;
}) {
  return (
    <S.MessageList itemCount={marketingComponent?.length} backgroundColor={backgroundColor}>
      {marketingComponent?.map((messages) => (
        <S.MessageItem key={JSON.stringify(messages)}>
          {messages.marketingMessages?.map((message, idx) => (
            <MessageLink key={`${JSON.stringify(messages)}-${idx}`} marketingMessageLink={message} />
          ))}
        </S.MessageItem>
      ))}
    </S.MessageList>
  );
}

export function MarketingMessage(props: MarketingMessageProps) {
  const { marketingComponent, backgroundColor } = props;
  const isDisplayed = marketingComponent?.find(({ marketingMessages }) =>
    marketingMessages?.find(({ messageMobilev2, messageDesktopv2 }) => messageMobilev2?.text || messageDesktopv2?.text),
  );
  const { setIsMarketingMessageInView } = useOverlayContext();
  const [myRef, inView] = useInViewRef();

  useEffect(() => {
    setIsMarketingMessageInView(inView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return isDisplayed ? (
    <S.MarketingMessage id="marketing-message" ref={myRef}>
      <MessageList marketingComponent={marketingComponent} backgroundColor={backgroundColor} />
    </S.MarketingMessage>
  ) : null;
}
