import type { FooterIconsBlock as FooterIconsBlockSchema } from '@amplience/content-types/typings/c-footer-icons-block';
import { getLocalizedValue } from '../../../utils/transformers';
import { BlockContainer, BlockIconList, BlockText } from './FooterIconsBlock.styles';
import { CircleIcon } from './circleIcon/CircleIcon';
import { RectangleIcon } from './rectangleIcon/RectangleIcon';

export type FooterIconsBlockProps = FooterIconsBlockSchema;

export function FooterIconsBlock({ footerIconsBlockList, footerIconsBlockText, iconShape }: FooterIconsBlockProps) {
  return (
    <BlockContainer>
      {footerIconsBlockText && (
        <BlockText $isCircle={iconShape === 'Circle'}>{getLocalizedValue(footerIconsBlockText)}</BlockText>
      )}
      <BlockIconList $isCircle={iconShape === 'Circle'}>
        {footerIconsBlockList?.map((icon, i) =>
          iconShape === 'Circle' ? (
            <CircleIcon key={i} image={icon.image} link={icon.link} />
          ) : (
            <RectangleIcon key={i} image={icon.image} link={icon.link} />
          ),
        )}
      </BlockIconList>
    </BlockContainer>
  );
}
