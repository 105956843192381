import type { ReactElement } from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import type { CopyBlock as CopyBlockPartialSchema } from '@amplience/content-types/typings/p-partials';
import { useColor } from '../color';

const S = {
  Text: styled.span<CopyBlockPartialSchema>`
    ${({ color }) => useColor(color)}
  `,
};

const separator = '\\n';

export type CopyBlockProps = CopyBlockPartialSchema & {
  className?: string;
};

export function CopyBlockPartial({ body, className, color }: CopyBlockProps): ReactElement | null {
  if (!body) {
    return null;
  }

  return (
    <p className={className}>
      <S.Text color={color}>
        {body.split(separator).map((part, i, arr) => (
          <Fragment key={i}>
            {part}
            {i < arr.length - 1 && <br />}
          </Fragment>
        ))}
      </S.Text>
    </p>
  );
}
