/* eslint-disable promise/prefer-await-to-then */

/* eslint-disable promise/catch-or-return */

/* eslint-disable promise/always-return */

/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable max-classes-per-file */
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { IncomingMessage, ServerResponse } from 'http';
import MutationQueueLink from '@adobe/apollo-link-mutation-queue';
import type { FetchResult, NextLink, Operation } from '@apollo/client/core';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client/core';
import { ApolloProvider as ApolloClientProvider } from '@apollo/client/react/context/ApolloProvider';
import { getCookie } from '../../auth';
import type { DiscountCode, LineItem } from '../../codegen/types';
import { getHeadersToSend } from '../../utils/getHeadersToSend';
import { logger } from '../../utils/logger';
import { isClient, isServer } from '../../utils/ssr';
import { getApolloFetchUrl } from '../../utils/url';
import { updateAuthSessionOnGqlOperation } from './cachedQueries';
import { errorLink } from './errorLink';

export type ApolloClientInstance = ApolloClient<object>;

const cache = new InMemoryCache({
  resultCacheMaxSize: 30_000, // Limit cache to 30K object entries
  typePolicies: {
    // We disable normalization for types that are locale-dependent or localisable
    // This is because the object returned for different locales can have the same keyFields,
    // thus prompting Apollo to normalize them into the same reference object.
    // When normalization is disabled, we make objects query-specific so that each response
    // maps to 1 query (cache is still active in this case)
    GiftCardProduct: {
      keyFields: false,
    },
    Product: {
      keyFields: false,
    },
    PathCategory: {
      keyFields: false,
    },
    Category: {
      keyFields: false,
    },
    AncestorCategory: {
      keyFields: false,
    },
    Cart: {
      fields: {
        lineItems: {
          merge(_existing: LineItem[] | [], incoming: LineItem[] | []) {
            return [...incoming];
          },
        },
        discountCodes: {
          merge(_existing: DiscountCode[] | [], incoming: DiscountCode[] | []) {
            return [...incoming];
          },
        },
      },
    },
    CartVariant: {
      keyFields: ['sku'],
    },
  },
});

// https://www.apollographql.com/docs/react/caching/garbage-collection/#cachegc
// Perform garbage collection every 20 minutes for unreachable objects
setInterval(() => {
  cache.gc({ resetResultCache: true, resetResultIdentities: true });
}, 1_000 * 60 * 20);

class LogTimeLink extends ApolloLink {
  isServerSide: boolean;

  constructor(isServerSide: boolean) {
    super();
    this.isServerSide = isServerSide;
  }

  request(operation: Operation, forward: NextLink) {
    if (this.isServerSide) {
      operation.setContext({
        ...operation.getContext(),
        start: performance.now(),
      });

      return forward(operation).map((result) => {
        const time = Math.round(performance.now() - operation.getContext().start);
        logger.info(`GraphQL operation "${operation.operationName}" took ${time} ms`);
        return result;
      });
    }

    return forward(operation);
  }
}

class LogRequestLink extends ApolloLink {
  isServerSide: boolean;

  constructor(shouldLog: boolean) {
    super();
    this.isServerSide = shouldLog;
  }

  request(operation: Operation, forward: NextLink) {
    const observable = forward(operation);

    if (this.isServerSide) {
      observable.subscribe({
        next: (value: FetchResult) => {
          const debugEnabled = logger.isLevelEnabled('debug');

          if (debugEnabled) {
            logger.debug(
              {
                query: operation.query.loc?.source.body,
                variables: operation.variables,
                response: value,
              },
              `GraphQL trace operation "${operation.operationName}"`,
            );
          } else if (value.errors) {
            logger.warn(`GraphQL operation "${operation.operationName}" failed: ${JSON.stringify(value.errors)}`);
          }
        },
      });
    }

    return observable;
  }
}

const operationNameLink = (res?: ServerResponse | null) =>
  new ApolloLink((operation, forward) => {
    const { operationName } = { ...operation };
    const headers = operation.getContext?.().headers || {};
    operation.setContext({ headers: { 'x-apollo-operation': operationName, ...headers } });
    if (res) {
      updateAuthSessionOnGqlOperation(operationName, res);
    }
    return forward(operation);
  });

const createApolloClient = (req?: IncomingMessage | null, res?: ServerResponse | null) => {
  const uri = getApolloFetchUrl();

  const httpLink = new HttpLink({
    uri,
    headers: {
      cookie: req?.headers?.cookie,
      ...(isServer() && {
        'x-api-key': process.env.APPSYNC_API_KEY,
        ...getHeadersToSend(req, getCookie(req?.headers.cookie)),
      }),
    },
  });

  const logTimeLink = new LogTimeLink(isServer());
  const logRequestLink = new LogRequestLink(isServer());
  const mutationQueueLink = new MutationQueueLink();

  return new ApolloClient({
    ssrMode: isServer(),
    link: from([
      ...(isClient() ? [mutationQueueLink] : []),
      logTimeLink,
      logRequestLink,
      operationNameLink(res),
      errorLink,
      httpLink,
    ]),
    cache,
  });
};

let apolloClient: ApolloClientInstance = null!;

export function initializeApollo(req: IncomingMessage | null = null, res: ServerResponse | null = null) {
  const client = apolloClient ?? createApolloClient(req, res);

  // For SSG and SSR always create a new Apollo Client
  if (isServer()) {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
}

export function useApollo() {
  const store = useMemo(initializeApollo, []);
  return store;
}

export function ApolloProvider({ children }: { children: ReactNode }) {
  const apolloClient1 = useApollo();
  return <ApolloClientProvider client={apolloClient1}>{children}</ApolloClientProvider>;
}
