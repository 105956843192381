import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import {
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '../../../baseComponents/dialog';
import { useCheckoutConfirmationContext } from '../../../context/checkoutConfirmationContext';
import { TypographyStyles, colours, spacing, zNeedHelpButton } from '../../../stylings';
import { NeedHelpContent } from './NeedHelpContent';

const S = {
  NeedHelpButton: styled(DialogTrigger)`
    ${TypographyStyles.Body.Small.SemiBold}
    background-color: ${colours.NAVY_BLUE};
    border: 0;
    border-radius: 4px 4px 0px 0px;
    color: ${colours.WHITE};
    height: 44px;
    padding: ${spacing.XXXS} ${spacing.XS};
    position: absolute;
    right: 400px;
    top: 50vh;
    transform: translateX(calc(50% - 22px)) rotate(-90deg);
    width: max-content;
    z-index: ${zNeedHelpButton};

    .cart-page & {
      right: 100%;
      top: 250px;
    }

    &:focus-visible {
      outline-color: ${colours.DARK_ORANGE};
    }
  `,
  NeedHelpLabel: styled(DialogTitle)`
    ${TypographyStyles.Headings.H5}
    display: block;
  `,
  NeedHelpContent: styled(DialogDescription)`
    ${TypographyStyles.Body.Small.Regular}
  `,
};

export function NeedHelpModal({ isCartEmpty, className }: { isCartEmpty: boolean; className?: string }) {
  const { t } = useTranslation(['lib-global-common']);
  const { isConfirmationPage } = useCheckoutConfirmationContext();

  if (isCartEmpty && !isConfirmationPage) return null;

  return (
    <DialogRoot>
      <S.NeedHelpButton className={className} type="button" data-testid="cartNeedHelpButton">
        {t('cart.need.help')}
      </S.NeedHelpButton>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent data-testid="needHelpDialog">
          <S.NeedHelpLabel>{t('cart.need.help')}</S.NeedHelpLabel>
          <S.NeedHelpContent asChild>
            <NeedHelpContent />
          </S.NeedHelpContent>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
}
