import type { ReactNode } from 'react';
import styled from 'styled-components';
import type { StyledComponent } from 'styled-components';
import { TypographyStyles, media, spacing } from '../../stylings';

const S = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    max-width: 328px;
    @media ${media.greaterThan('lg')} {
      max-width: 408px;
    }
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
  `,
  IconWrapper: styled.figure`
    margin: 0;
    padding: 7px 0 0;
    @media ${media.greaterThan('lg')} {
      padding: 7px 0 0 4px;
      svg {
        transform: scale(1.5);
      }
    }
  `,
  Heading: styled.h3`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  `,
  SubText: styled.span`
    ${TypographyStyles.Body.Small.Regular}
  `,
  TextWithLink: styled.span`
    ${TypographyStyles.Body.Small.Regular}
  `,
};

interface DynamicMessageProps {
  icon?: StyledComponent<never, never> | ReactNode;
  heading?: string;
  subText?: string;
  textWithLink?: ReactNode;
  children?: ReactNode;
  testId?: string;
}

export function DynamicMessage({ icon, heading, subText, textWithLink, children, testId }: DynamicMessageProps) {
  return (
    <S.Container>
      <S.Info data-testid={testId} role="status">
        {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
        {heading && <S.Heading>{heading}</S.Heading>}
        {subText && <S.SubText>{subText}</S.SubText>}
        {textWithLink && <S.TextWithLink>{textWithLink}</S.TextWithLink>}
        {children && children}
      </S.Info>
    </S.Container>
  );
}
