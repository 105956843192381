import * as yup from 'yup';

// eslint-disable-next-line no-useless-escape
const CHARS_REGEX = /^(?!.*(HTTP|Http|WWW|HTTP|Http|WWW|http|www|[&$=+#<>\|{}()£\[\]<>?!:;@\/\\"\%\^\*]|\s{3,})).*$/g;

export const DeliveryInstructionsSchema = yup.object().shape({
  instructions: yup.string().nullable().required('delivery.instructions.validation.instructions'),
  message: yup
    .string()
    .matches(CHARS_REGEX, 'delivery.instructions.validation.message')
    .max(256, 'delivery.instructions.validation.message'),
});
