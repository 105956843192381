import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForgotPasswordSchema } from '@shared/validation/src/b2c/forgotPassword';
import { BackButton, BlueButton } from '../../baseComponents/button';
import { FieldContainer, Form, SectionContainer } from '../../baseComponents/containers';
import { ErrorMessage } from '../../baseComponents/errors';
import { InputField } from '../../baseComponents/inputs/Input';
import type { FormValues } from '../../baseComponents/inputs/Input';
import { Channel } from '../../codegen/types';
import { useForgotPasswordMutation } from '../../graphqlDocument/auth/forgetPassword.generated';
import { useLoadingCursor } from '../../hooks/useLoadingCursor';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { Locale } from '../../utils/localeHelper';
import { PasswordErrorMessage } from './PasswordErrorMessage';

const S = {
  StyledForm: styled(Form)`
    gap: ${spacing.XS};
  `,
  TextContainer: styled.section`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    margin: 0;
  `,
  Title: styled.h4`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    @media ${media.greaterThan('lg')} {
      ${TypographyStyles.Headings.H4SemiBold}
    }
  `,
  Message: styled.p`
    ${TypographyStyles.Body.Small.Regular}
    margin: 0;
    padding: 0;
  `,
  Back: styled(BackButton)`
    color: ${colours.BLACK};
    gap: ${spacing.XXXXS};
    margin-bottom: 0;
  `,
};

interface ForgotPasswordFormProps {
  title: string;
  message: string;
}

export function ForgotPasswordForm({ title, message }: ForgotPasswordFormProps) {
  const router = useRouter();
  const { t } = useTranslation(['lib-global-common']);
  const [forgotPassword, { data, error, loading }] = useForgotPasswordMutation();

  useLoadingCursor(loading);

  if (data) {
    router.push(
      {
        pathname: '/reset-password/request',
      },
      undefined,
      {
        locale: router.locale,
      },
    );
  }
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: formError },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const emailValue = useWatch({
    control,
    name: 'email',
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ email }: FormValues) => {
    try {
      await forgotPassword({
        variables: {
          input: {
            username: email,
            locale: router.locale as string,
            channel: (router.locale as string) === Locale.STOREFRONT_OUTLET_LOCALE ? Channel.OUTLET : Channel.FULLPRICE,
          },
        },
      });
    } catch (e: unknown) {
      console.error(e);
    }
  };

  return (
    <SectionContainer>
      <S.StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
        <S.Back />
        <S.TextContainer role="status">
          <S.Title data-testid="forgotPasswordHeader">{title}</S.Title>
          <S.Message data-testid="forgotPasswordMessage">{message}</S.Message>
        </S.TextContainer>
        <FieldContainer data-cs-mask>
          <InputField
            isInputError={!!formError.email}
            aria-label={t('forgot.password.email')}
            placeholder={`${t('forgot.password.email')}*`}
            type="email"
            aria-describedby="email-invalid"
            aria-invalid={!!formError.email}
            register={register('email')}
            isEmpty={!emailValue}
            autoComplete="email"
            required
          />
          <ErrorMessage id="email-invalid" data-testid="forgotPasswordEmailError" {...formError?.email}>
            {t(formError?.email?.message as string)}
          </ErrorMessage>
          {!formError.email && error && <PasswordErrorMessage error={error} id="forgot-password-error" />}
        </FieldContainer>

        {/* Submit  button */}
        <BlueButton type="submit" disabled={loading || !!data} data-testid="forgotPasswordButton">
          {t('forgot.password.button.label')}
        </BlueButton>
      </S.StyledForm>
    </SectionContainer>
  );
}

export default ForgotPasswordForm;
