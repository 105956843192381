import { createGlobalStyle } from 'styled-components';
import { colours } from './colours';
import { spacing } from './spacing';

export const ResetCSS = createGlobalStyle`

@font-face {
    ascent-override: 96.8%;
    descent-override: 25.1%;
    font-family: 'Montserrat-fallback';
    line-gap-override: 0%;
    src: local('Arial');
}

@font-face {
    ascent-override: 96.8%;
    descent-override: 25.1%;
    font-family: 'Montserrat-fallback-android';
    line-gap-override: 0%;
    src: local('Roboto');
}

@font-face {
    ascent-override: 119.3%;
    descent-override: 28.9%;
    font-family: 'Oswald-fallback';
    line-gap-override: 0%;
    src: local('Arial');
}

@font-face {
    ascent-override: 119.3%;
    descent-override: 28.9%;
    font-family: 'Oswald-fallback-android';
    line-gap-override: 0%;
    src: local('Roboto');
}

@font-face {
    ascent-override: 106.88%;
    descent-override: 29.3%;
    font-family: 'OpenSans-fallback';
    line-gap-override: 0%;
    src: local('Arial');
}

@font-face {
    ascent-override: 106.88%;
    descent-override: 29.3%;
    font-family: 'OpenSans-fallback-android';
    line-gap-override: 0%;
    src: local('Roboto');
}

*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    display: revert;
}


/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.grecaptcha-badge { visibility: hidden; }

/* stylelint-disable property-no-vendor-prefix*/
body {
    font-family: 'Montserrat', 'Montserrat-fallback', 'Montserrat-fallback-android', sans-serif;
    margin: 0;
    -webkit-text-size-adjust: 100%;
    
}

/* Reapply the pointer cursor for anchor tags */
a[href], button {
  cursor: pointer;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-width: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
    border-radius: 0px;
    user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    appearance: revert;
}

/* for a11y and hiding text */
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable='false'])) {
    overflow-wrap: break-word;
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable='true']) {
    -webkit-user-drag: element;
}

/* hiding manage preference button */
#ot-sdk-btn {
    display: none;
}

#ot-sdk-btn-floating > .ot-floating-button__back > button[aria-hidden='true'], 
#ot-sdk-btn-floating > .ot-floating-button__front > button[aria-hidden='true'] {
    display: none;
}

a,button {
    &:focus-visible {
        outline-color: ${colours.OUTLINE_BORDER};
    }
}


:where(input[placeholder])::placeholder {
  color: ${colours.GREY};
}

@media print {
    header * {
        display: none;
    }

    footer * {
        display: none;
    }

    #store-map {
        height: 282px;
    }

    #print-section {
        flex-direction: column-reverse;
        gap: ${spacing.M};
    }

    #onetrust-consent-sdk {
        display: none;
    }
}

`;
