import { Channel } from '../codegen/types';
import type { SocialProvider } from '../codegen/types';
import { useCartContext, useLocaleContext, useUserContext } from '../context';
import { useGenerateSocialProviderLinkMutation } from '../graphqlDocument/socials/generateSocialProviderLink.generated';
import { Locale, getCtLocale } from '../utils/localeHelper';

export function useSocialLogin(isFromLogin?: boolean) {
  const [generateSocialProviderLink] = useGenerateSocialProviderLinkMutation();
  const { customerData } = useUserContext();
  const { anonymousId, cartId } = useCartContext();

  const { locale } = useLocaleContext();

  async function generateSocialLoginURL(provider: SocialProvider) {
    const channel = locale === Locale.STOREFRONT_OUTLET_LOCALE ? Channel.OUTLET : Channel.FULLPRICE;
    try {
      const { data } = await generateSocialProviderLink({
        variables: {
          provider,
          channel,
          state: [
            {
              key: 'channel',
              value: channel,
            },
            {
              key: 'signupRegion',
              value: customerData?.signupRegion ? customerData.signupRegion : locale,
            },
            {
              key: 'socialIndicator',
              value: isFromLogin ? 'login' : 'register',
            },
            {
              key: 'locale',
              value: getCtLocale(locale as string),
            },
            ...(anonymousId
              ? [
                  {
                    key: 'anonymousId',
                    value: anonymousId,
                  },
                ]
              : []),
            ...(cartId
              ? [
                  {
                    key: 'cartId',
                    value: cartId,
                  },
                ]
              : []),
          ],
        },
      });
      window.location.replace(data?.generateSocialProviderLink as string);
    } catch (e) {
      console.error(e);
    }
  }

  return { generateSocialLoginURL };
}
