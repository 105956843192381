import { css } from 'styled-components';

export const useColor = (color: string | undefined, fallbackColor?: string) =>
  color
    ? css`
        color: ${color};
      `
    : css`
        color: ${fallbackColor};
      `;
