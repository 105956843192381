import { LocaleStar2, STAR1Locales, countryNameMapperROE, getCountryKey, siteMapper } from '../../utils/localeHelper';
import { logger } from '../../utils/logger';
import { withNewRelic } from '../newrelic';
import { sendFilterApiRequest } from './amplienceFilterApi';
import { getNavigationHierarchyData } from './getHierarchyData';

interface MasterLayoutParam {
  locale: string;
  staging?: boolean;
}
const getMasterLayoutBase = async ({ locale, staging }: MasterLayoutParam) => {
  const parameterLocale = Object.prototype.hasOwnProperty.call(
    countryNameMapperROE,
    getCountryKey(locale).toUpperCase(),
  )
    ? locale.split('-')[0]
    : locale;

  const requestBody = {
    filterBy: [
      {
        path: '/_meta/schema',
        value: 'https://clarks.com/hierarchy/h-master-layout.json',
      },
      {
        path: '/isDefault',
        value:
          siteMapper[
            Object.prototype.hasOwnProperty.call(countryNameMapperROE, getCountryKey(locale).toUpperCase())
              ? LocaleStar2.ROE
              : locale.toLowerCase()
          ],
      },
    ],
    parameters: {
      depth: 'all',
      format: 'inlined',
      locale: parameterLocale,
    },
  };

  const requestBodyEurope = {
    filterBy: [
      {
        path: '/_meta/schema',
        value: 'https://clarks.com/hierarchy/h-master-layout.json',
      },
      {
        path: '/isDefault',
        value: 'Europe',
      },
    ],
    parameters: {
      depth: 'all',
      format: 'inlined',
      locale: parameterLocale,
    },
  };

  try {
    const response = await sendFilterApiRequest({ requestBody });

    // TODO logger

    let responseEurope = [];

    if (!STAR1Locales.includes(locale) && response.length === 0) {
      responseEurope = await sendFilterApiRequest({ requestBody: requestBodyEurope });
    }

    if (response?.length > 0 || responseEurope?.length > 0) {
      const { content: masterLayoutData } = response[0] || responseEurope[0];

      const navigationHierarchyData = await getNavigationHierarchyData(
        masterLayoutData.headerComponent?.navigationBar?.topCategories,
        parameterLocale,
        staging,
      );

      // TODO logger

      return { masterLayoutData, navigationHierarchyData };
    }
  } catch (e) {
    logger.error({ error: e }, `Failed to fetch master layout`);
  }

  return { masterLayoutData: {}, navigationHierarchyData: {} };
};

export const getMasterLayout = withNewRelic('getMasterLayout', getMasterLayoutBase);
