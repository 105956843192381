import { createContext, useContext, useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import type { Footer } from '@amplience/content-types/typings/c-footer';
import type { Header } from '@amplience/content-types/typings/c-header';
import type { MarketingMessage } from '@amplience/content-types/typings/c-marketing-message';
import type { NavigationBar } from '@amplience/content-types/typings/c-navigation-bar';
import type { NotificationBar } from '@amplience/content-types/typings/c-notification-bar';
import type { PromotionalLinksNav } from '@amplience/content-types/typings/c-promotional-links-nav';
import type { FreeShippingDelivery } from '@amplience/content-types/typings/c-shipping-section';
import type { SubNav } from '@amplience/content-types/typings/c-sub-nav';
import type { MasterLayout as MasterLayoutSchema } from '@amplience/content-types/typings/h-master-layout';
import type { HandlebarTemplateProps } from '../globalComponents/handlebarTemplate';

export type ExtendedWindow = Window & { BY?: { show?: () => void } };

export type MasterLayoutContextType = {
  masterLayoutData: MasterLayoutSchema & {
    headerComponent?: Header & {
      marketingMessage?: MarketingMessage;
      navigationBar?: NavigationBar;
      notificationBar?: NotificationBar;
      promotionalLinksNav?: PromotionalLinksNav;
      subNav?: SubNav;
    };
    freeShippingAndReturn?: FreeShippingDelivery;
    footerComponent?: Footer;
  };
  masterLayoutHandlebarComponents: HandlebarTemplateProps[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigationHierarchyData: any;
};

const MasterLayoutContext = createContext<MasterLayoutContextType>({
  masterLayoutData: {
    _meta: {
      schema: '',
    },
  },
  masterLayoutHandlebarComponents: null,
  navigationHierarchyData: [],
});

export const useMasterLayoutContext = () => useContext(MasterLayoutContext);

export function MasterLayoutProvider({
  children,
  masterLayoutData,
  masterLayoutHandlebarComponents,
  navigationHierarchyData,
}: PropsWithChildren<MasterLayoutContextType>) {
  const value = useMemo(
    () => ({
      masterLayoutData,
      masterLayoutHandlebarComponents,
      navigationHierarchyData,
    }),
    [masterLayoutData, masterLayoutHandlebarComponents, navigationHierarchyData],
  );

  return <MasterLayoutContext.Provider value={value}>{children}</MasterLayoutContext.Provider>;
}
