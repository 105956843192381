import type { IncomingMessage } from 'http';

export const cookiesToSend = [
  'auth_sessionTimeout',
  'auth_idToken',
  'auth_refreshToken',
  'auth_rememberMe',
  'auth_guestToken',
  'auth_csatoken',
  'x-recaptcha-token',
];

export function getHeadersToSend(req: IncomingMessage | null | undefined, cookies: Record<string, string> = {}) {
  const reqHeaders = req?.headers || {};
  return cookiesToSend.reduce((acc, cur) => {
    if (reqHeaders[cur.toLowerCase()]) {
      if (cur === 'x-recaptcha-token') {
        acc[`${cur}`] = reqHeaders[cur] as string;
      } else {
        acc[`X-${cur}`] = reqHeaders[cur.toLowerCase()] as string;
      }
    } else if (cookies[cur]) {
      acc[`X-${cur}`] = cookies[cur];
    }
    return acc;
  }, {} as Record<string, string>);
}
