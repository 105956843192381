import type { ParsedUrlQuery } from 'querystring';

export const createURLParameter = (query: ParsedUrlQuery) => {
  const newQuery: Record<string, string> = {};
  Object.entries(query)
    .filter(([_, value]) => value)
    .forEach(([key, value]) => {
      newQuery[key] = value as string;
    });

  const searchParams = new URLSearchParams(newQuery);

  return searchParams.toString() ? `?${searchParams.toString()}` : '';
};
