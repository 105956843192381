import type { StoreAddressType } from '../types/store';
import { Locale } from './localeHelper';

export function getFormattedAddress(address: StoreAddressType, locale: string) {
  const { mallName, streetNumber, streetName, postalCode, town, region, province, country } = address;
  const localesArray = [Locale.EN_US, Locale.EN_CA, Locale.FR_CA];
  const formatRegionOrProvince = (locales: string[]) => (locales.includes(locale) ? region : province) || '';
  const displayedAddress: (string | undefined)[] = [
    mallName,
    streetNumber,
    streetName,
    town,
    formatRegionOrProvince(localesArray),
    postalCode,
    country,
  ];
  return displayedAddress
    .map((part) => (typeof part === 'string' && part.endsWith(',') ? part.slice(0, -1) : part))
    .filter(Boolean)
    .join(', ');
}

export function isAddressDummy(key?: string | null) {
  return key?.includes('DUMMY');
}
