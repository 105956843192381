import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import { useCurrentRefinements } from 'react-instantsearch';
import { createFacetAttributeNames } from '../utils/algoliaFilterUtils';

export function useAlgoliaAppliedFilter() {
  const { items: currentRefinements } = useCurrentRefinements({
    excludedAttributes: ['price.centAmount', 'query'],
  });
  const router = useRouter();

  const clearAll = () => {
    const newQuery: ParsedUrlQuery = {};

    const refinementList = createFacetAttributeNames(router.locale).concat(['minPrice', 'maxPrice']);
    Object.entries(router.query || {})
      .filter(([key]) => {
        const newKey = key.split('[')[0];
        return !refinementList.includes(newKey);
      })
      .forEach(([key, value]) => {
        newQuery[key] = value;
      });

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      {
        shallow: true,
      },
    );
  };
  return {
    currentRefinements,
    clearAll,
  };
}
