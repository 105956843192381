import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useAfterCookieBannerContext } from '../../context/afterCookieBannerContext';
import { useDidMount } from '../../hooks/useDidMount';
import { useGeoIp } from '../../hooks/useGeoIp';
import { Cookies } from '../../utils/cookies';

const DynamicNoLocationPopUp = dynamic(
  async () => {
    const { NoLocationPopUp } = await import('../popUps/NoLocationPopUp');
    return NoLocationPopUp;
  },
  { ssr: false },
);

const DynamicRedirectPopUp = dynamic(
  async () => {
    const { RedirectPopUp } = await import('../popUps/RedirectPopUp');
    return RedirectPopUp;
  },
  { ssr: false },
);

export function GeoIpPopUp() {
  const isMounted = useDidMount();
  const { promptRedirectAfterCookie, setPromptRedirectAfterCookie } = useAfterCookieBannerContext();

  const { hasLocationInfo, loadingGeoIpInfo, showRedirectPopUp, redirectInfo } = useGeoIp();

  useEffect(() => {
    if (isMounted && !loadingGeoIpInfo) {
      setPromptRedirectAfterCookie(Cookies.get('OptanonAlertBoxClosed'));
    }
  }, [isMounted, loadingGeoIpInfo, setPromptRedirectAfterCookie]);

  if (loadingGeoIpInfo || !promptRedirectAfterCookie) return null;

  return (
    <>
      {!hasLocationInfo && <DynamicNoLocationPopUp hasLocationInfo={hasLocationInfo} />}
      {showRedirectPopUp && <DynamicRedirectPopUp redirectInfo={redirectInfo} />}
    </>
  );
}

export default GeoIpPopUp;
