import styled from 'styled-components';
import type { ProductBadgeTextual } from '../../../codegen/types';
import { media } from '../../../stylings';

interface ITextualBadgeContainerProps {
  $backgroundColor?: ProductBadgeTextual['backgroundColor'];
  $textColor?: ProductBadgeTextual['textColor'];
  $pdpBadge?: boolean;
}

export const TextualBadgeContainer = styled.div<ITextualBadgeContainerProps>`
  align-items: center;
  background-color: ${({ $backgroundColor }) => `rgba(${$backgroundColor})`};
  bottom: 0;
  color: ${({ $textColor }) => `rgba(${$textColor})`};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  min-height: 12.5%;
  position: absolute;
  text-align: center;
  top: auto;
  width: 100%;
  word-break: break-word;
  z-index: 3;

  ${({ $pdpBadge }) =>
    !$pdpBadge &&
    `
      @media ${media.greaterThan('md')} {
        font-size: 18px;
      }

      @media ${media.greaterThan('lg')} {
        font-size: 14px;
      }

      @media ${media.greaterThan('xl')} {
        font-size: 18px;
      }
    `}

  ${({ $pdpBadge }) =>
    $pdpBadge &&
    `
      font-size: 18px;
      min-height: 12.5%;

      @media ${media.greaterThan('md')} and ${media.lessThan('lg')} {
        font-size: 22px;
      }

      @media ${media.greaterThan('lg')} {
        bottom: unset;
        height: auto;
        left: 0;
        min-height: auto;
        padding: 16.5px 38px;
        top: 40px;
        width: fit-content;
      }
    `}
`;
