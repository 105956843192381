import styled from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../../stylings';

export const NeedHelpSectionCheckoutContacts = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
  padding-left: 0;

  & a,
  & button {
    ${TypographyStyles.CallToAction.Tiny.SemiBold}
    align-items: center;
    background: none;
    border: none;
    color: ${colours.BLACK};
    cursor: pointer;
    display: flex;
    gap: ${spacing.XXXS};
    padding: 0;
    text-align: left;
    text-decoration: underline;
  }

  & li {
    &:hover,
    &:focus {
      svg {
        transform: translateY(-2px);
      }
    }
  }
`;

export const NeedHelpSectionCheckoutIconContainer = styled.span`
  height: 15px;
  width: 15px;
`;
