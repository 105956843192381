import styled from 'styled-components';
import { Loader } from './Loader';

const S = {
  Container: styled.div`
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
  `,
};

export function PageLoader() {
  return (
    <S.Container>
      <Loader isDark />
    </S.Container>
  );
}
