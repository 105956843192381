import type { TFunction } from 'next-i18next';

export const renderDeliveryOptionsTranslation = (key: string, t: TFunction, name: string) => {
  const translation: Record<string, string> = {
    'standard-courier': t('delivery.options.standard', { ns: 'lib-global-common' }),
    'standard-delivery': t('delivery.options.standard', { ns: 'lib-global-common' }),
    'express-courier-air': t('delivery.options.express', { ns: 'lib-global-common' }),
  };

  return translation[key] ?? name;
};
