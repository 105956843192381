import { memo, useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled, { keyframes } from 'styled-components';
import type { Image } from '@amplience/content-types/typings/c-header';
import type { NavigationBar as NavigationBarSchema } from '@amplience/content-types/typings/c-navigation-bar';
import type { PromotionalLinksNav as PromotionalLinksNavProps } from '@amplience/content-types/typings/c-promotional-links-nav';
import type { SubNav as SubNavProps } from '@amplience/content-types/typings/c-sub-nav';
import { useCartContext } from '../../context/cartContext';
import { useLocaleContext } from '../../context/localeContext';
import { useOverlayContext } from '../../context/overlayContext';
import { useEscapeKeyListener } from '../../hooks/useEscapeKeyListener';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { Account, Close, Hamburger, Search } from '../../icons';
import { ImagePartial } from '../../partials/image';
import { colours, media, spacing, zAutocomplete, zAutocompleteOverlay, zStickyHeader } from '../../stylings';
import type { AmplienceContextProps } from '../../types/amplience';
import { getBasketPageLink } from '../../utils/cartUtils';
import { Locale } from '../../utils/localeHelper';
import { showProgressBar } from '../../utils/progressBarStatus';
import { CartIcon } from '../cart/cartIcon';
import type { EmptyBasketProps } from '../cart/emptyBasket';
import type { MiniCartSliderProps } from '../cart/miniCart/miniCartSlider/MiniCartSliderProps';
import { HeaderNavigation } from '../navigation/headerNavigation/HeaderNavigation';
import { SearchBar } from './SearchBar';

/* eslint-disable promise/prefer-await-to-then */
const DynamicMiniCartSlider = dynamic<MiniCartSliderProps>(
  () => import('../cart/miniCart/miniCartSlider/MiniCartSlider').then((mod) => mod.MiniCartSlider),
  {
    ssr: false,
  },
);

const MobileHamburgerMenu = dynamic(() => import('./MobileHamburgerMenu').then((mod) => mod.MobileHamburgerMenu), {
  ssr: false,
});

const DynamicCheckoutCtaMenu = dynamic(
  () => import('../cart/checkoutCtaMenu/CheckoutCtaMenu').then((mod) => mod.CheckoutCtaMenu),
  {
    ssr: false,
  },
);

const MobileSearchBar = dynamic(() => import('./MobileSearchBar').then((mod) => mod.MobileSearchBar), {
  ssr: false,
});
const DesktopSearchBar = dynamic(() => import('./DesktopSearchBar').then((mod) => mod.DesktopSearchBar), {
  ssr: false,
});

/* eslint-disable promise/prefer-await-to-then */

const showNavBar = keyframes`
  to {
    top: 0;
  }
`;

const hideNavBar = keyframes`
  from {
    top: 0;
  }

  to {
    top: -100px;
  }
`;

const S = {
  Cart: styled.a`
    & svg {
      height: 20px;
      width: 20px;

      @media ${media.greaterThan('lg')} {
        height: 20px;
        width: 20px;
      }
    }

    @media ${media.greaterThan('lg')} {
      margin-right: 0;
    }
  `,
  Hamburger: styled.button`
    align-items: center;
    background: none;
    border: 0;
    display: flex;
    padding: 0;

    & svg {
      height: 22px;
      width: 22px;
    }

    @media ${media.greaterThan('lg')} {
      display: none;
    }
  `,
  IconsWrapper: styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${spacing.XS};

    & a {
      align-items: center;
      display: flex;
    }

    @media ${media.greaterThan('lg')} {
      gap: ${spacing.S};
    }
  `,
  Logo: styled.div`
    display: flex;
    width: 95px;

    img {
      height: 100%;
      width: 100%;
    }

    & a {
      display: flex;
      height: 100%;
      width: 100%;
    }

    @media ${media.greaterThan('lg')} {
      width: 120px;
    }
  `,
  MyAccount: styled.a`
    transition: transform 0.3s ease;
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }

    & svg {
      height: 20px;
      width: 20px;

      @media ${media.greaterThan('lg')} {
        height: 20px;
        width: 20px;
      }
    }

    &:hover,
    &:focus {
      transform: translateY(-2px);

      .hover-bg {
        background-color: ${colours.LIGHT_BLUE};
      }
    }
  `,
  MyAccountHoverBackground: styled.div`
    border-radius: 50%;
    height: 20px;
    left: 0%;
    position: absolute;
    top: 8%;
    width: 20px;
    z-index: -1;
  `,
  Wrapper: styled.div`
    align-items: center;
    background-color: ${colours.WHITE};
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    max-width: 1440px;
    min-height: 60px;
    padding: ${spacing.XS} ${spacing.S};
    position: relative;
    z-index: ${zAutocomplete};

    .sticky & {
      padding-bottom: ${spacing.S};
    }

    @media ${media.greaterThan('lg')} {
      flex-wrap: nowrap;
      height: min-content;
      justify-content: space-between;
      margin: 0 auto;
      min-height: 80px;
      padding: 0 ${spacing.S};
      position: static;

      .sticky & {
        padding-bottom: 0;
      }
    }
    @media ${media.greaterThan('xl')} {
      margin: 0 auto;
      padding: 0 70px;
    }
    @media ${media.greaterThan('xx')} {
      gap: 96px;
    }
  `,
  StickyWrapper: styled.div`
    background-color: ${colours.WHITE};
    z-index: ${zStickyHeader};

    &.sticky {
      left: 0;
      position: fixed;
      right: 0;
      top: -100px;
    }

    &.hide-navbar {
      animation: ${hideNavBar} 0.5s linear forwards;
    }

    &.show-navbar {
      animation: ${showNavBar} 0.5s linear forwards;
    }

    @media screen and (prefers-reduced-motion: reduce) {
      &.hide-navbar {
        animation: none;
        top: -100px;
      }

      &.show-navbar {
        animation: none;
        top: 0;
      }
    }
  `,
  NavBarWrapperContainer: styled.div`
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    position: relative;
  `,
  Placeholder: styled.div`
    height: 0;

    &.sticky {
      display: block;
      height: 48px;
    }

    @media ${media.greaterThan('lg')} {
      &.sticky {
        height: 80px;
      }
    }
  `,
  CartIconWrapper: styled.div`
    position: relative;
  `,
  SearchIcon: styled.button`
    align-items: center;
    background: none;
    border: 0;
    display: flex;
    padding: 0;

    & svg {
      height: 20px;
      width: 20px;
    }

    @media ${media.greaterThan('lg')} {
      display: none;
    }
  `,

  SearchOverlay: styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: ${zAutocompleteOverlay};
  `,
};

export type NavigationBarProps = NavigationBarSchema &
  AmplienceContextProps & {
    subNavData?: SubNavProps;
    promotionalLinksNavData?: PromotionalLinksNavProps;
    emptyBasketComponent?: EmptyBasketProps;
    headerLogo?: Image;
  };

function SearchSection() {
  const { t } = useTranslation(['lib-global-common']);
  const { isSearchOverlayOpen, setIsSearchOverlayOpen } = useOverlayContext();
  const handleSearchIconClick = useCallback(() => {
    setIsSearchOverlayOpen((prev) => !prev);
  }, [setIsSearchOverlayOpen]);
  return (
    <>
      <SearchBar forDesktop idPrefix="desktop" />
      <S.SearchIcon
        data-testid="mobileSearchButton"
        onClick={handleSearchIconClick}
        aria-label={isSearchOverlayOpen ? t('hamburger.menu.close') : t('search.placeholder')}
      >
        {isSearchOverlayOpen ? <Close viewBox="-10 -4 40 25" aria-hidden /> : <Search aria-hidden />}
      </S.SearchIcon>
    </>
  );
}

const SearchSectionMemo = memo(SearchSection);

export function NavigationBar({
  context,
  mobileOnlyFeaturedCards,
  subNavData,
  promotionalLinksNavData,
  emptyBasketComponent,
  headerLogo,
}: NavigationBarProps) {
  const { t } = useTranslation('lib-global-common');
  const { hierarchyData } = context ?? { hierarchyData: [] };
  const { locale } = useLocaleContext();
  const { checkoutSecurely, itemsInCart } = useCartContext();

  const {
    isMobileMenuOpen,
    setIsMobileMenuOpen,
    isMiniCartOpen,
    isSearchOverlayOpen,
    setIsSearchOverlayOpen,
    isMarketingMessageInView,
    setIsMiniCartOpen,
  } = useOverlayContext();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const router = useRouter();
  const routerAsPath = router.asPath.split('?')[0];
  const [sliderOffset, setSliderOffset] = useState(0);

  const handleCloseSearchOverLay = useCallback(() => {
    setIsSearchOverlayOpen(false);
  }, [setIsSearchOverlayOpen]);

  useEffect(() => {
    let lastScrollTop = 0;

    const onScroll = () => {
      const currentScrollTop = window.scrollY;
      const placeholderTop = placeholderRef.current?.offsetTop || 0;
      const wrapperHeight = wrapperRef.current?.clientHeight || 0;
      const headerTop = Math.max(placeholderTop - wrapperHeight, 1);

      if (document.documentElement.scrollTop >= Math.max(headerTop, placeholderTop) + wrapperHeight) {
        placeholderRef.current?.classList.add('sticky');
        wrapperRef.current?.classList.add('sticky');
      } else if (document.documentElement.scrollTop <= Math.max(headerTop, placeholderTop)) {
        placeholderRef.current?.classList.remove('sticky');
        wrapperRef.current?.classList.remove('sticky');
      }

      if (lastScrollTop > currentScrollTop) {
        wrapperRef.current?.classList.add('show-navbar');
        wrapperRef.current?.classList.remove('hide-navbar');
      } else {
        wrapperRef.current?.classList.add('hide-navbar');
        wrapperRef.current?.classList.remove('show-navbar');
      }

      lastScrollTop = currentScrollTop;
    };

    window.addEventListener('scroll', onScroll, { passive: true });

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    setIsMiniCartOpen(false);
  }, [routerAsPath, setIsMiniCartOpen]);

  useEffect(() => {
    const notificationBarHeight = document.getElementById('clarks-notification-bar')?.offsetHeight ?? 0;
    const searchBarHeight = document.getElementById('nav-bar-sticky-wrapper')?.offsetHeight ?? 0;
    const searchModalHeight = document.getElementById('search-modal-container')?.offsetHeight ?? 0;
    const headerHeight = document.getElementById('clarks-header')?.offsetHeight ?? 0;
    const marketingMessageHeight = document.getElementById('marketing-message')?.offsetHeight ?? 0;
    const marketingMessageInView = isMarketingMessageInView ? 79 + marketingMessageHeight : 49;

    let sliderOffsetValue: number;

    if (!isDesktop) {
      sliderOffsetValue = isSearchOverlayOpen ? searchBarHeight + notificationBarHeight : marketingMessageInView;
    } else {
      sliderOffsetValue = isSearchOverlayOpen ? searchModalHeight + notificationBarHeight : headerHeight;
    }

    setSliderOffset(sliderOffsetValue);
  }, [isSearchOverlayOpen, isDesktop, isMarketingMessageInView]);

  useEffect(() => {
    const searchModalInputElement: HTMLInputElement | null = document.querySelector('.aa-Input');
    const handleEscapeKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && searchModalInputElement?.value === '') {
        setIsSearchOverlayOpen(false);
      }
    };

    if (isSearchOverlayOpen) {
      searchModalInputElement?.addEventListener('keydown', handleEscapeKeyPress);
    }

    return () => searchModalInputElement?.removeEventListener('keydown', handleEscapeKeyPress);
  }, [setIsSearchOverlayOpen, isSearchOverlayOpen]);

  const handleOnMyAccountClick = useCallback(() => {
    showProgressBar();
    sessionStorage.setItem('lastLocation', '/my-account');
  }, []);

  const handleCloseCartKeydown = useCallback(async (): Promise<void> => {
    setIsMiniCartOpen(false);
  }, [setIsMiniCartOpen]);

  useEscapeKeyListener(handleCloseCartKeydown);

  const openMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prev) => !prev);
  }, [setIsMobileMenuOpen]);

  return (
    <>
      <S.Placeholder ref={placeholderRef} />
      <S.StickyWrapper ref={wrapperRef} id="nav-bar-sticky-wrapper">
        <S.NavBarWrapperContainer>
          {isDesktop && isSearchOverlayOpen && <DesktopSearchBar headerLogo={headerLogo} />}
          <S.Wrapper data-testid="navBarWrapper">
            <S.Logo data-testid="logoComponent">
              <Link href="/" locale={locale} passHref data-testid="logoLink">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <a aria-label={t('header.link.home')} onClick={handleCloseSearchOverLay}>
                  <ImagePartial
                    altText={headerLogo?.altText}
                    src={headerLogo?.image?.name ?? ''}
                    assetType={headerLogo?.assetType}
                    useOriginalSize
                    defaultHost={headerLogo?.image?.defaultHost}
                    data-testid="logoImage"
                  />
                </a>
              </Link>
            </S.Logo>

            {hierarchyData && <HeaderNavigation hierarchyData={hierarchyData} />}

            <S.IconsWrapper>
              <SearchSectionMemo />
              <Link href="/my-account" passHref locale={locale}>
                <S.MyAccount
                  onClick={handleOnMyAccountClick}
                  aria-label={t('header.link.my.account')}
                  data-testid="myAccountIcon"
                >
                  <S.MyAccountHoverBackground className="hover-bg" />
                  <Account aria-hidden />
                </S.MyAccount>
              </Link>
              <S.CartIconWrapper>
                <Link
                  href="/cart"
                  as={`/${getBasketPageLink(locale)}`}
                  passHref
                  locale={locale === Locale.STOREFRONT_OUTLET_LOCALE ? false : locale}
                >
                  <S.Cart aria-label={t('header.link.cart')}>
                    <CartIcon itemsInCart={itemsInCart} aria-hidden />
                  </S.Cart>
                </Link>
              </S.CartIconWrapper>
              <S.Hamburger type="button" aria-label={t('navigation.bar.hamburger.menu')} onClick={openMobileMenu}>
                <Hamburger aria-hidden />
              </S.Hamburger>
            </S.IconsWrapper>
          </S.Wrapper>
        </S.NavBarWrapperContainer>

        {!isDesktop && isSearchOverlayOpen && <MobileSearchBar />}
      </S.StickyWrapper>
      {isSearchOverlayOpen && <S.SearchOverlay onClick={handleCloseSearchOverLay} />}
      {isMobileMenuOpen && (
        <MobileHamburgerMenu
          mobileOnlyFeaturedCards={mobileOnlyFeaturedCards}
          setMobileMenuState={setIsMobileMenuOpen}
          subNavData={subNavData}
          promotionalLinksNavData={promotionalLinksNavData}
          hierarchyData={hierarchyData}
        />
      )}
      {isMiniCartOpen && (
        <DynamicMiniCartSlider
          emptyBasketComponent={emptyBasketComponent}
          scrollTop={document.documentElement.scrollTop}
          sliderOffset={sliderOffset}
          isDesktop={isDesktop}
        />
      )}
      {!isMiniCartOpen && checkoutSecurely && <DynamicCheckoutCtaMenu sliderOffset={sliderOffset} />}
    </>
  );
}
