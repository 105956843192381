import type { TFunction } from 'next-i18next';

export const handleCountryTranslations = (t: TFunction, locale: string) => {
  const translationNamespace = { ns: 'lib-global-countries-common' };
  const lowerCasedLocale = locale.toLowerCase();
  const countryMap: { [key: string]: string } = {
    gb: 'uk',
    britannique: 'uk',
    ie: 'roi',
    canada: 'ca',
    china: 'cn',
    chine: 'cn',
    japan: 'jp',
    japon: 'jp',
    korea: 'kr',
    coréen: 'kr',
    malaysia: 'my',
    malaisie: 'my',
    australia: 'au',
    australie: 'au',
    singapore: 'sg',
    singapour: 'sg',
    india: 'in',
    uae: 'ae',
    eu: 'Europe',
    européen: 'Europe',
  };

  const countryKey = countryMap[lowerCasedLocale];
  if (countryKey) {
    return countryKey === 'Europe' ? countryKey : t(`country.${countryKey}`, translationNamespace);
  }

  return t(`country.${lowerCasedLocale}`, translationNamespace);
};
