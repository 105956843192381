import Handlebars from 'handlebars';
import { startsWithLocale } from '../../utils/localeHelper';

export type HandlebarTemplateProps = {
  template: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: Record<string, any>;
};

type UrlObjectType = {
  url?: string;
  internalUrl?: string;
  externalUrl?: string;
};

export function HandlebarTemplate({ template, input }: HandlebarTemplateProps) {
  try {
    Handlebars.registerHelper('getHrefFromLinkObject', (locale?: string, urlObject: UrlObjectType = {}) => {
      const { url, internalUrl, externalUrl } = urlObject;

      let urlStr = '/';
      if (externalUrl) {
        urlStr = externalUrl;
      } else if (internalUrl) {
        urlStr = startsWithLocale(internalUrl) || !locale ? `/${internalUrl}` : `/${locale}/${internalUrl}`;
      } else if (url) {
        urlStr = url;
      }

      return new Handlebars.SafeString(` href=${urlStr}`);
    });

    Handlebars.registerHelper(
      'getFirstTruthyString',
      (...args: Array<unknown>) => args.find((arg) => typeof arg === 'string' && arg?.trim() !== '') ?? '',
    );

    // Partial that invokes the helper function, since helper functions break Amplience visualizations for business components
    // A corresponding partial b-partial-get-href is created in Amplience Content Hub
    // but it is only used for the Amplience visualization, the actual rendering logic lives here
    Handlebars.registerPartial('b-partial-get-href', `{{getHrefFromLinkObject locale urlObject}}`);

    const compiledTemplate = Handlebars.compile(template);

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: compiledTemplate(input) }} />;
  } catch (e) {
    console.error('There was an error compiling your Handlebars template', e);
    return null;
  }
}
