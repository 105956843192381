import styled, { css } from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';

export const PriceWrapper = styled.div<{ $discountedPrice?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.XXXS};
  margin-bottom: 2px;
  row-gap: 0;

  & .now-price {
    ${({ $discountedPrice }) =>
      $discountedPrice
        ? css`
            ${TypographyStyles.Body.Medium.RegularStrikethrough}
            color: ${colours.DARK_GREY};
          `
        : css`
            ${TypographyStyles.Body.Medium.SemiBold}
          `}
  }

  & .discounted-price {
    ${TypographyStyles.Body.Medium.SemiBold}
    color: ${colours.DISCOUNT_RED};
  }

  @media ${media.greaterThan('lg')} {
    margin-bottom: 3px;
  }
`;
