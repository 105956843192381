import type { InputHTMLAttributes, ReactNode, RefObject } from 'react';
import { forwardRef } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { Tick } from '../../icons';
import { IconWrapper, Input, Label, Wrapper } from './Checkbox.styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  register?: UseFormRegisterReturn;
  testId?: string;
  isInputError?: boolean;
  className?: string;
  testLabel?: string;
}

export const Checkbox = forwardRef(
  ({ isInputError, label, id, register, className, testId, testLabel, ...other }: CheckboxProps, ref) => (
    <Label className={className} data-testid={typeof label === 'object' ? testLabel : label}>
      <Wrapper $isChecked={!!other.checked} $isValidationError={isInputError}>
        <Input
          type="checkbox"
          ref={ref as RefObject<HTMLInputElement>}
          id={id}
          data-testid={testId}
          {...other}
          {...register}
        />
        <IconWrapper>{other.checked && <Tick viewBox="-6 0 40 25" aria-hidden />}</IconWrapper>
      </Wrapper>
      {label}
    </Label>
  ),
);
