/* eslint-disable @typescript-eslint/no-explicit-any */
import type { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import type { HygienePageRightSlotContents } from '@amplience/content-types/typings/s-hygiene-page-layout';
import type { HandlebarTemplateProps } from '../globalComponents/handlebarTemplate/HandlebarTemplate';
import {
  getHierarchyData,
  getLayoutContentWithChildData,
  getLinkedContentWithChildData,
} from '../services/amplience/getHierarchyData';
import type { AmplienceContentType, AmplienceContext, AmplienceDCItem, AmplienceSlot } from '../types/amplience';

export enum HeaderNavigationContentType {
  HEADER = 'https://clarks.com/content/c-header.json',
  NAVBAR = 'https://clarks.com/content/c-navigation-bar.json',
  MEGAMENU = 'https://clarks.com/hierarchy/h-megamenu-layout.json',
  HEADER_NAVIGATION = 'https://clarks.com/hierarchy/h-header-navigation.json',
}

type AmplienceComponentsType = Record<
  string,
  (props: any) => ReactElement<any, string | JSXElementConstructor<any>> | null
>;

export type AmplienceComponentMapper = {
  [k: string]: (props: any) => ReactElement | null;
};

export function generateHashCode(hashLength: number): string {
  let localLength = hashLength;
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let hash = '';

  while (localLength) {
    hash += characters[Math.floor(Math.random() * characters.length)];
    localLength -= 1;
  }

  return hash;
}

export function isAmplienceSlot(data: AmplienceDCItem): data is AmplienceSlot {
  const schema = data?._meta.schema;
  const schemaParts = schema?.split('/') || [];

  return !!schemaParts[schemaParts.length - 1]?.startsWith('s-');
}

export function getTopCategoriesData(data: AmplienceDCItem) {
  if (data?._meta.schema === HeaderNavigationContentType.HEADER) {
    return data.navigationBar.topCategories;
  }

  if (data?._meta.schema === HeaderNavigationContentType.NAVBAR) {
    return data.topCategories;
  }
  return null;
}

export async function getMegamenuLayoutData(data: AmplienceDCItem, locale: string) {
  const topCategory =
    (await getHierarchyData({ id: data?._meta?.hierarchy?.parentId as string, locale, staging: true })) ?? [];
  const linkedContent = await getLinkedContentWithChildData(topCategory[0]?.linkedContent, locale, true);
  const layoutContent = getLayoutContentWithChildData(topCategory[0]?.content?.layoutContent, linkedContent);

  return { ...data, ...{ megamenuData: { ...{ layoutContent } } } };
}

export function renderAmplienceDCItem(
  amplienceComponents: AmplienceComponentMapper,
  item: AmplienceDCItem,
  context: AmplienceContext,
): ReactNode {
  if (!item) return null;

  if (isAmplienceSlot(item)) {
    return renderAmplienceSlot(amplienceComponents, item, context);
  }

  return renderAmplienceContentType(amplienceComponents, item, context);
}

export function renderAmplienceContentType(
  amplienceComponents: AmplienceComponentMapper,
  contentType: AmplienceContentType,
  context: AmplienceContext,
  key: string = generateHashCode(10),
): ReactElement | null {
  if (contentType._meta.schema in amplienceComponents) {
    const Component = amplienceComponents[contentType._meta.schema];

    return <Component {...contentType} context={context} key={key} />;
  }

  if (isHandlebarComponent(contentType._meta.schema)) {
    const Component = amplienceComponents['handlebar-component'];
    const { template } =
      (contentType?.handlebarComponents as HandlebarTemplateProps[])?.find(
        (item) => item.input._meta.schema === contentType._meta.schema,
      ) ?? {};

    if (!template) return null;

    return <Component input={contentType} template={template} />;
  }

  return null;
}

export function renderAmplienceContentTypeArray(
  amplienceComponents: AmplienceComponentMapper,
  arr: AmplienceContentType[],
  context: AmplienceContext,
): ReactNode[] {
  return arr.map((contentTypeData, index) =>
    renderAmplienceContentType(
      amplienceComponents,
      contentTypeData,
      context,
      `${contentTypeData._meta.deliveryId}_${index.toString()}`,
    ),
  );
}

export function renderAmplienceSlot(
  amplienceComponents: AmplienceComponentMapper,
  slot: AmplienceSlot,
  context: AmplienceContext,
): ReactNode {
  const { slotContent } = slot;

  if (!slotContent) {
    return null;
  }

  const { topContent, LeftContent, RightContent, ...rest } = slotContent;

  return [
    topContent,
    LeftContent,
    (RightContent as unknown as HygienePageRightSlotContents)?.pageContent,
    ...Object.values(rest),
  ].flatMap((slotContentItem) => {
    if (!slotContentItem) {
      return [];
    }

    return Array.isArray(slotContentItem)
      ? renderAmplienceContentTypeArray(amplienceComponents, slotContentItem as AmplienceContentType[], context)
      : renderAmplienceContentType(amplienceComponents, slotContentItem, context);
  });
}

/**
 * If schema contains `/b-` then it is a Handlebar component
 *
 * @param schema Amplience schema ID
 * @returns
 */
export function isHandlebarComponent(schema: string) {
  return /\/b-/gm.test(schema);
}

export const BUSINESS_COMPONENT = 'https://clarks.com/slot/b-component-slot.json';

export const isBusinessComponent = (item: Record<string, any>) => item?._meta?.schema === BUSINESS_COMPONENT;

export const getListOfHandlebarComponents = (arrayOfBusinessComponents: Record<string, any>[] | null | undefined) => {
  if (!arrayOfBusinessComponents) return [];

  const listOfHandlebarComponents = arrayOfBusinessComponents.flatMap(
    (businessComponent) => businessComponent?.slotContent?.components as Record<string, any>[],
  );

  return listOfHandlebarComponents;
};

export function renderAmplienceSlotComponents<T extends unknown[]>(
  array: T,
  components: AmplienceComponentsType,
  templateLookup?: HandlebarTemplateProps[] | null,
  locale?: string,
) {
  const returnedArray = array?.flatMap((comp, i) => {
    const componentProps = comp as any;

    if (isBusinessComponent(componentProps) && templateLookup) {
      const handlebarComponents = componentProps?.slotContent?.components as Record<string, any>[];

      return handlebarComponents?.flatMap((handlebarComponent, j) => {
        const Component: (props: any) => ReactElement | null = components['handlebar-component'];
        const { template } =
          templateLookup?.find((temp) => temp.input._meta.schema === handlebarComponent._meta.schema) ?? {};

        if (!template) return null;

        const inputWithLocale = {
          ...handlebarComponent,
          locale,
        };

        return (
          Component && (
            <Component
              input={locale ? inputWithLocale : handlebarComponent}
              template={template}
              key={`${handlebarComponent._meta.schema}-${i}-${j}`}
              index={j}
            />
          )
        );
      });
    }

    const Component: (props: any) => ReactElement | null = components[componentProps._meta.schema];

    return Component && <Component {...componentProps} key={`${componentProps._meta.schema}-${i}`} index={i} />;
  });

  return returnedArray?.filter((item) => !!item);
}
