import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type AgilOneTokenQueryVariables = Types.Exact<{
  locale: Types.Scalars['String'];
  refresh?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type AgilOneTokenQuery = {
  __typename?: 'Query';
  agilOneToken: { __typename?: 'AgilOneTokenResponse'; tenantId: number; accessKey: string };
};

export const AgilOneTokenDocument = gql`
  query AgilOneToken($locale: String!, $refresh: Boolean) {
    agilOneToken(locale: $locale, refresh: $refresh) {
      tenantId
      accessKey
    }
  }
`;

/**
 * __useAgilOneTokenQuery__
 *
 * To run a query within a React component, call `useAgilOneTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgilOneTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgilOneTokenQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      refresh: // value for 'refresh'
 *   },
 * });
 */
export function useAgilOneTokenQuery(
  baseOptions: Apollo.QueryHookOptions<AgilOneTokenQuery, AgilOneTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgilOneTokenQuery, AgilOneTokenQueryVariables>(AgilOneTokenDocument, options);
}
export function useAgilOneTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgilOneTokenQuery, AgilOneTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgilOneTokenQuery, AgilOneTokenQueryVariables>(AgilOneTokenDocument, options);
}
export type AgilOneTokenQueryHookResult = ReturnType<typeof useAgilOneTokenQuery>;
export type AgilOneTokenLazyQueryHookResult = ReturnType<typeof useAgilOneTokenLazyQuery>;
export type AgilOneTokenQueryResult = Apollo.QueryResult<AgilOneTokenQuery, AgilOneTokenQueryVariables>;
