import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { BlueButton, WhiteButton } from '../../baseComponents/button';
import { SectionContainer } from '../../baseComponents/containers/SectionContainer';
import { CheckCircle } from '../../icons';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

const S = {
  ResetPasswordMessage: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    justify-content: center;
    margin: 0;
    max-width: 328px;
    padding: 0;
    @media ${media.greaterThan('lg')} {
      max-width: 408px;
    }
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    margin: 0;
  `,
  Title: styled.h4`
    ${TypographyStyles.Headings.H3}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    @media ${media.greaterThan('lg')} {
      ${TypographyStyles.Headings.H4SemiBold}
    }
  `,
  Message: styled.p`
    ${TypographyStyles.Body.Small.Regular}
    margin: 0;
    padding: 0;
  `,
  Info: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};

    @media ${media.greaterThan('lg')} {
      flex-direction: row;
    }
  `,
  Login: styled(BlueButton)`
    margin-bottom: 0;
    width: 100%;
  `,
  ContinueShopping: styled(WhiteButton)`
    width: 100%;
  `,
  IconWrapper: styled.figure`
    margin: 0;
    padding: ${spacing.XXXXS} 0 0;
    path {
      fill: ${colours.SUCCESS_PRIMARY};
    }
  `,
};

export function SuccessResetMessage() {
  const { t } = useTranslation(['lib-global-common']);
  const router = useRouter();
  const handleToHomepage = () => {
    router.push(
      {
        pathname: '/',
      },
      undefined,
      {
        locale: router.locale,
      },
    );
  };

  const handleToLogin = () =>
    router.push(
      {
        pathname: '/login',
      },
      undefined,
      {
        locale: router.locale,
      },
    );

  return (
    <SectionContainer>
      <S.ResetPasswordMessage>
        <S.TextContainer role="status">
          <S.Info>
            <S.IconWrapper>
              <CheckCircle />
            </S.IconWrapper>
            <S.Title data-testid="successResetPasswordTitle">{t('forgot.password.reset.success.title')}</S.Title>
          </S.Info>

          <S.Message data-testid="successResetPasswordMessage">{t('forgot.password.reset.success.message')}</S.Message>
        </S.TextContainer>
        <S.Login onClick={handleToLogin}>{t('forgot.password.login')}</S.Login>
        <S.ContinueShopping type="button" onClick={handleToHomepage}>
          {t('forgot.password.continue.shopping')}
        </S.ContinueShopping>
      </S.ResetPasswordMessage>
    </SectionContainer>
  );
}

export default SuccessResetMessage;
