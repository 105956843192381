import getConfig from 'next/config';
import { getCookieValue } from './cookies';

const config = getConfig()?.publicRuntimeConfig || process.env;

export async function grecaptcha() {
  return new Promise((resolve) => {
    const bypassRecaptchaValue = getCookieValue('x-recaptcha-token');

    if (bypassRecaptchaValue) {
      resolve(bypassRecaptchaValue);
      return;
    }

    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(config.RECAPTCHA_SITE_KEY as string, {
        action: 'submit',
      });
      resolve(token);
      return token;
    });
  });
}
