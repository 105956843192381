import styled from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';
import { getNonMultiImageSize } from './ProductLineItemImages';

export const ImagesWrapper = styled.div`
  display: flex;
  gap: ${spacing.XXXS};
`;
export const PrimaryImageWrapper = styled.a<{ $multiImage?: boolean; $type?: string }>`
  background-color: ${colours.PALE_GREY};
  height: 100px;
  position: relative;
  width: 100px;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  @media ${media.greaterThan('lg')} {
    height: ${({ $multiImage, $type }) => ($multiImage ? '198px' : getNonMultiImageSize($type, '198px'))};
    width: ${({ $multiImage, $type }) => ($multiImage ? '198px' : getNonMultiImageSize($type, '301px'))};
  }
`;
export const SecondaryImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XXXS};
`;
export const SecondaryImage = styled.div`
  background-color: ${colours.PALE_GREY};
  height: 95px;
  position: relative;
  width: 95px;
`;
export const SaleBadge = styled.div`
  background-color: ${colours.LIGHT_BLUE};
  display: flex;
  height: 20px;
  padding: 3px 11px;
  position: absolute;
  text-transform: uppercase;
  top: 10px;
  ${TypographyStyles.Label.Small.Medium} @media ${media.greaterThan('lg')} {
    padding: ${spacing.XXXXS} 14px;
  }
`;
