import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

export function sendCreateAccountErrorEvent(errMsg: string) {
  sendDataLayerEvent(EventNamesType.create_account_error, {
    custom_data: {
      error_message: errMsg,
    },
  });
}
