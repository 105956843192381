import type {
  ChangeEvent,
  ComponentType,
  Dispatch,
  ForwardedRef,
  ReactNode,
  RefObject,
  SelectHTMLAttributes,
  SetStateAction,
} from 'react';
import { forwardRef, useEffect, useState } from 'react';
import type { FieldValues, UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import { ArrowDown as DefaultArrowDown } from '../../icons';
import { colours, media, spacing } from '../../stylings';
import { type GroupedByCountry, setCountryAlias } from '../../utils/country';
import { transformTextToId } from '../../utils/transformers';

const S = {
  Select: styled.select`
    all: unset;
    font-size: 16px;
    padding: ${spacing.XS} ${spacing.XXXS};
    width: 100%;

    &:focus {
      outline: 2px solid ${colours.OUTLINE_BORDER};
      width: 97%;
    }
  `,
  SelectLabel: styled.label`
    background-color: ${colours.WHITE};
    font-size: 12px;
    font-weight: 200;
    left: ${spacing.XXXXS};
    line-height: 12px;
    padding: 0 ${spacing.XXXXS};
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    white-space: nowrap;
  `,
  SelectWrapper: styled.div`
    border: 1px solid ${colours.BLACK};
    height: fit-content;
    max-height: 44px;
    min-height: 44px;
    position: relative;
    @media ${media.greaterThan('lg')} {
      min-height: 50px;
    }
  `,
  SelectArrowWrapper: styled.div`
    pointer-events: none;
    position: absolute;
    right: ${spacing.XXXS};
    top: 50%;
    transform: translateY(-50%);
  `,
  PhoneNumberValue: styled.div`
    padding: ${spacing.XXS} ${spacing.XXXS};
  `,
  PhoneNumberContainer: styled.div`
    display: flex;

    > :first-child {
      border: 1px solid ${colours.GREY};
      border-right: none;
      width: 92px;

      select {
        border: none;
        bottom: -2px;
        filter: alpha(opacity=0);
        height: 100%;
        left: 0;
        min-height: 100%;
        opacity: 0;
        padding: ${spacing.XXS} ${spacing.XXXS};
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }

    > :last-child {
      width: calc(100% - 92px);
    }
  `,
};

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  register?: UseFormRegisterReturn;
  label?: string;
  ArrowDown?: ComponentType | null;
  hiddenLabel?: boolean;
}

export const Select = forwardRef(
  (
    { label, ArrowDown = DefaultArrowDown, children, register, defaultValue, hiddenLabel, ...others }: SelectProps,
    ref,
  ) => {
    const id = transformTextToId(label || register?.name);

    return (
      <S.SelectWrapper>
        {label ? (
          <S.SelectLabel htmlFor={id} className={hiddenLabel ? 'visually-hidden' : ''}>
            {label}
          </S.SelectLabel>
        ) : null}
        <S.Select
          id={id}
          ref={ref as RefObject<HTMLSelectElement>}
          {...register}
          defaultValue={defaultValue}
          {...others}
        >
          {children}
        </S.Select>
        {ArrowDown && (
          <S.SelectArrowWrapper>
            <ArrowDown aria-hidden />
          </S.SelectArrowWrapper>
        )}
      </S.SelectWrapper>
    );
  },
);

export const PhoneNumberSelect = forwardRef(
  (
    {
      label,
      ArrowDown = DefaultArrowDown,
      children,
      register,
      defaultValue,
      hiddenLabel,
      value,
      ...others
    }: SelectProps,
    ref,
  ) => {
    const id = transformTextToId(label || register?.name);

    return (
      <S.SelectWrapper>
        <S.PhoneNumberValue>{value || defaultValue}</S.PhoneNumberValue>
        {label ? (
          <S.SelectLabel htmlFor={id} className={hiddenLabel ? 'visually-hidden' : ''}>
            {label}
          </S.SelectLabel>
        ) : null}
        <S.Select
          id={id}
          ref={ref as RefObject<HTMLSelectElement>}
          {...register}
          defaultValue={defaultValue}
          {...others}
        >
          {children}
        </S.Select>
        {ArrowDown && (
          <S.SelectArrowWrapper>
            <ArrowDown aria-hidden />
          </S.SelectArrowWrapper>
        )}
      </S.SelectWrapper>
    );
  },
);

type SelectedCountries = {
  phone: string;
  countryAlias?: string | null;
};

type CountryCodeSelectProps<T extends FieldValues> = {
  countries: GroupedByCountry;
  children: ReactNode;
  selectedCountry?: SelectedCountries;
  setSelectedCountries: Dispatch<SetStateAction<SelectedCountries>>;
  setValue?: UseFormSetValue<T | Record<string, string>>;
};

export const CountryCodeSelect = forwardRef(
  <T extends FieldValues>(
    { countries, children, selectedCountry, setSelectedCountries, setValue }: CountryCodeSelectProps<T>,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const { selectedIndex } = event.target;
      const selectedOption = event.target.options[selectedIndex];
      const value = selectedOption.getAttribute('data-country-code');
      setCountryAlias({
        value,
        phone: event.target.value,
        countryAlias: value,
        setSelectedCountry: setSelectedCountries,
        setValue,
      });
    };
    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    return (
      <S.PhoneNumberContainer>
        <PhoneNumberSelect
          ref={ref}
          id="your-select-id"
          value={selectedCountry?.phone}
          onChange={handleSelectChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          defaultValue={selectedCountry?.phone}
        >
          {Object.entries(countries).map(([countryCode, { country, countryCode: phoneCode }]) => (
            <option key={`${countryCode}-${phoneCode}`} value={phoneCode} data-country-code={countryCode}>
              {isFocused ? `${country} (${phoneCode})` : phoneCode}
            </option>
          ))}
        </PhoneNumberSelect>
        {children}
      </S.PhoneNumberContainer>
    );
  },
);
