import { useCallback, useState } from 'react';
import { useIntersectionObserverRef } from 'rooks';

// Check if component is in view only once to not cause any further re-render
// because of state change
export function useIntersectOnce(options: IntersectionObserverInit = {}) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const callback = useCallback(([entry]: IntersectionObserverEntry[]) => {
    if (entry && entry.isIntersecting) {
      setIsIntersecting(true);
    }
  }, []);

  const [elementRef] = useIntersectionObserverRef(callback, options);

  return { elementRef, isIntersecting };
}
