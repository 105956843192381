import { currencyMapper } from '@shared/utils/src/ct';
import type { Cart, Customer } from '../../../../codegen/types';
import type { GetOrderedCartQuery } from '../../../../graphqlDocument/cart/getOrderedCart.generated';
import { AttributeName } from '../../../../types/newrelic';
import { getAmount } from '../../../currencyHelper';
import { isStar2Check } from '../../../globalEUtils';
import { countryNameMapper, countryNameMapperROE, countryTerritoryMapper } from '../../../localeHelper';
import { setNewRelicAttributes } from '../../../newrelic';
import { createLineItem } from '../../utils/createLineItem';
import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

// GA4 client ID is a unique identifier assigned to each user
const getGAValue = () => {
  let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
  const raw = match ? decodeURIComponent(match[1]) : null;
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/);
  }
  return match ? match[1] : null;
};

export const sendPageLoadedEvent = (
  history: string[],
  isOutlet: boolean,
  isDesktop: boolean,
  locale?: string,
  metaTitle?: string,
  pageType?: string,
  isUserLoggedIn?: boolean,
  customerData?: Customer,
  customerEmailHash?: string | null,
  miniCart?: Cart,
  orderData?: GetOrderedCartQuery,
  pageCategory?: string,
  pageCategoryId?: string,
) => {
  const [language, country] = locale?.split('-') ?? ['', ''];
  const mainPath = `${isOutlet ? '' : `/${locale}`}`;
  const lastHistoryItem = history.slice(-1)[0];
  const currentPath = `${mainPath}${lastHistoryItem === '/' ? '' : lastHistoryItem}`;
  const lastPath = history?.slice(-2, -1)[0];
  const referrer = lastPath ? `${document.location.origin}${mainPath}${lastPath}` : document.referrer;
  const currency = currencyMapper[locale ?? ''];
  const territory = countryTerritoryMapper[country] ?? countryTerritoryMapper.roe;
  const star = isStar2Check({ locale }) ? '2' : '1';

  const { customerNumber, shoeSize, shoeWidth, email, phone, firstName, lastName } = customerData ?? {};
  const { lineItems, id } = miniCart ?? {};
  const { deliveryAddress } = orderData?.getOrderedCart ?? {};

  const clientId = getGAValue();

  const cart = {
    cart_total: +getAmount(miniCart?.total),
    ...(id && { cart_id: id }),
    items: lineItems?.map((item, index) => createLineItem({ ...item, index })) ?? [],
  };

  const page = {
    page_title: metaTitle,
    page_location: window.location.href,
    page_path: currentPath,
    page_hostname: window.location.hostname,
    ...(pageType && { page_type: pageType }),
    ...(referrer && { page_referrer: referrer }),
    ...(lastPath && { previous_page: `${window.location.origin}/${locale}${lastPath}` }),
    page_category: pageCategory,
    page_category_id: pageCategoryId,
    language_display: language,
    site_region: locale,
    territory,
    star,
    environment: isDesktop ? 'desktop' : 'mobile',
  };

  const user =
    isUserLoggedIn && !!customerData
      ? {
          user_logged_in: true,
          client_id: clientId,
          ...(customerNumber && { user_id: customerNumber }),
          ...(shoeSize && { shoe_size: shoeSize }), // TODO: translate shoeSize to more readable format
          ...(shoeWidth && { shoe_width: shoeWidth }), // TODO: translate shoeWidth to more readable format
          // TODO: total_orders: total number of orders associated with this customer_id
          ...(customerEmailHash && { email_hash: customerEmailHash }),
          ...(email && { email }),
          ...(phone && { phone }),
          first_name: firstName,
          last_name: lastName,
          ...((deliveryAddress?.line1 || deliveryAddress?.line2) && {
            street: [deliveryAddress?.line1, deliveryAddress?.line2].filter(Boolean).join(', '),
          }),
          ...(deliveryAddress?.city && { city: deliveryAddress?.city }),
          ...(deliveryAddress?.state && { region: deliveryAddress?.state }),
          ...(deliveryAddress?.country && {
            country: countryNameMapper[deliveryAddress?.country] || countryNameMapperROE[deliveryAddress?.country],
          }),
          ...(deliveryAddress?.postalCode && { postcode: deliveryAddress?.postalCode }),
          ...(deliveryAddress?.country && { iso_country: deliveryAddress?.country }),
        }
      : {
          user_logged_in: false,
          client_id: clientId,
        };

  // set new-relic attributes with gtm data before send
  setNewRelicAttributes({
    [AttributeName.PAGE_TITLE]: metaTitle || null,
    [AttributeName.PAGE_PATH]: currentPath,
    [AttributeName.REFERRER]: referrer,
    [AttributeName.SITE_BRAND]: 'Clarks',
    [AttributeName.SITE_LOCALE]: locale || null,
    // TODO: Add site_version
    // [AttributeName.SITE_VERSION]: process.env.NEXT_PUBLIC_VERSION ?? '',
    [AttributeName.SITE_ENVIRONMENT]: process.env.NEXT_PUBLIC_ENV_NAME || null,
    [AttributeName.SITE_COUNTRY]: country || null,
    [AttributeName.LANGUAGE_DISPLAY]: language.toUpperCase() || null,
    [AttributeName.SITE_CURRENCY]: currency || null,
    [AttributeName.PAGE_TYPE]: pageType || null,
  });

  sendDataLayerEvent(EventNamesType.page_loaded, {
    currency: currency.toLowerCase(),
    cart,
    page,
    user,
  });
};
