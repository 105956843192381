import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import type { OpeningHoursType } from '../../types';
import { StoreStatusContainer } from './StoreStatus.styles';

export function StoreStatusComponent({
  openingHours,
  timezone,
}: {
  openingHours: OpeningHoursType[];
  timezone: string | undefined;
}) {
  const { t } = useTranslation(['lib-global-common', 'lib-products-common']);
  const optionsDay: Intl.DateTimeFormatOptions = useMemo(
    () => ({
      timeZone: timezone,
      weekday: 'long',
    }),
    [timezone],
  );
  const formatterDay = useMemo(() => new Intl.DateTimeFormat([], optionsDay), [optionsDay]);
  const currentDate = new Date();
  // format dateString always to US format for consistency, independently of locale
  const dateString = currentDate.toLocaleString('en-US', { timeZone: timezone });
  const nowTimestamp = new Date(dateString).getTime();
  const currentDay = formatterDay.format(currentDate).toUpperCase();
  const todayOpeningHours = openingHours?.find(({ day }) => day === currentDay);

  const isOpenCheck = useCallback(() => {
    if (!todayOpeningHours) return false;

    const now = new Date();
    const [openHour, openMinute] = todayOpeningHours.openingTime.split(':').map(Number);
    const [closeHour, closeMinute] = todayOpeningHours.closingTime.split(':').map(Number);
    const openTime = new Date(now).setHours(openHour, openMinute, 0, 0);
    const closeTime = new Date(now).setHours(closeHour, closeMinute, 0, 0);
    return nowTimestamp >= openTime && nowTimestamp < closeTime;
  }, [todayOpeningHours, nowTimestamp]);
  const isOpen = isOpenCheck();
  if (!openingHours || !timezone) {
    return null;
  }

  return (
    <StoreStatusContainer $isOpen={isOpen}>
      {'\u00A0'}
      {`(${isOpen ? t('pdp.store.open').toLowerCase() : t('pdp.store.closed').toLowerCase()})`}
    </StoreStatusContainer>
  );
}
