import styled from 'styled-components';
import type { FinalSaleAttributes } from '../../codegen/types';
import { fontWeights, fonts } from '../../stylings';

interface FinalSaleProps {
  $textColor?: FinalSaleAttributes['textColor'];
  $bgColor?: FinalSaleAttributes['backgroundColor'];
  $isProductDetail?: boolean;
}

export const FinalSaleText = styled.span<FinalSaleProps>`
  ${fonts.Montserrat}
  ${fontWeights.Medium500}
  align-items: center;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $textColor }) => $textColor};
  display: inline-flex;
  font-size: 14px;
  line-height: 16px;
  padding: ${({ $bgColor }) => ($bgColor ? '4px 12px' : '4px 0')};
  word-break: break-word;
`;
