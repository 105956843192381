import { useEffect } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { BlueButton } from '../../../baseComponents/button';
import { FieldContainer } from '../../../baseComponents/containers/FieldContainer';
import { ErrorMessage as ErrorMessageComponent } from '../../../baseComponents/errors';
import { Checkbox } from '../../../baseComponents/inputs/Checkbox';
import { InputField, PasswordField } from '../../../baseComponents/inputs/Input';
import { SocialProvider } from '../../../codegen/types';
import { useLocaleContext } from '../../../context/localeContext';
import { useMountRecaptchaScript } from '../../../hooks/useMountRecaptchaScript';
import { useRegister } from '../../../hooks/useRegister';
import { Apple, Facebook, Google } from '../../../icons';
import { sendCreateAccountStartEvent } from '../../../utils/gtm/events/create_account_start/sendCreateAccountStartEvent';
import { createAnchorTagHref } from '../../../utils/transformers';
import { getTranslation } from '../../footer/NewsletterSignUp';
import ExistingEmailError from '../ExistingEmailError';
import PasswordStrengthBar from '../PasswordStrengthBar';
import { SocialLogins } from '../SocialLogins';
import UnconfirmedEmailError from '../UnconfirmedEmailError';
import {
  AllFields,
  EmailChoices,
  ErrorMessage,
  Form,
  Headline,
  MarketingChoices,
  MasterCheckboxContainer,
  PasswordHint,
  SubscribeText,
  TermsAndCondition,
  TransLink,
} from './RegistrationForm.styles';

enum RegistrationError {
  USER_EXISTS = 'UsernameExistsException',
  NON_CONFIRMED_USER = 'NonConfirmedUserAuthError',
  RECAPTCHA_ERROR = 'BadDataError',
}

export function RegistrationGQLError({ email, emailError }: { email: string; emailError: string | null | undefined }) {
  if (emailError === RegistrationError.USER_EXISTS) return <ExistingEmailError />;
  if (emailError === RegistrationError.NON_CONFIRMED_USER) return <UnconfirmedEmailError email={email} />;

  return null;
}

export interface OptInFormValues {
  [key: string]: boolean;
}

export function RegistrationForm() {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();
  const {
    email,
    emailError,
    recaptchaError,
    firstName,
    formError,
    getValues,
    handleCheckboxChange,
    handleMasterCheckboxOnChange,
    handleSubmit,
    onSubmit,
    isRoiOrUk,
    register,
    onPasswordBlur,
    password,
    lastName,
    isBadPassword,
    itemLength,
    masterIsEnabled,
    loading,
    marketingPreferences,
    categoryChoices,
    data,
  } = useRegister();
  const { componentRef } = useMountRecaptchaScript();

  useEffect(() => {
    sendCreateAccountStartEvent();
  }, []);

  return (
    <Form ref={componentRef} onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* Email address input */}
      {isRoiOrUk && <Headline>{t('register.headline.message')}</Headline>}

      <AllFields label={t('account.registration.details')}>
        <FieldContainer data-cs-mask>
          <InputField
            isInputError={!!formError.email}
            aria-label={t('register.email.address')}
            placeholder={`${t('register.email.address')}*`}
            type="email"
            aria-describedby="email-invalid"
            aria-invalid={!!formError.email}
            register={register('email')}
            isEmpty={!email}
            autoComplete="email"
            required
            maxLength={128}
          />
          <ErrorMessageComponent id="email-invalid" {...formError?.email}>
            {t(formError?.email?.message as string)}
          </ErrorMessageComponent>
          <RegistrationGQLError email={getValues('email')} emailError={emailError} />
        </FieldContainer>

        {/* First name input */}
        <FieldContainer data-cs-mask>
          <InputField
            isInputError={!!formError.firstName}
            aria-label={t('register.first.name')}
            placeholder={`${t('register.first.name')}*`}
            type="text"
            aria-describedby="first-name-invalid"
            aria-invalid={!!formError.firstName}
            register={register('firstName')}
            isEmpty={!firstName}
            autoComplete="given-name"
            required
          />
          <ErrorMessageComponent id="first-name-invalid" {...formError?.firstName}>
            {t(formError?.firstName?.message as string)}
          </ErrorMessageComponent>
        </FieldContainer>

        {/* Last name input */}
        <FieldContainer data-cs-mask>
          <InputField
            isInputError={!!formError.lastName}
            aria-label={t('register.last.name')}
            placeholder={`${t('register.last.name')}*`}
            type="text"
            {...register('lastName')}
            aria-describedby="last-name-invalid"
            aria-invalid={!!formError.lastName}
            register={register('lastName')}
            isEmpty={!lastName}
            autoComplete="family-name"
            required
          />
          <ErrorMessageComponent id="last-name-invalid" {...formError?.lastName}>
            {t(formError?.lastName?.message as string)}
          </ErrorMessageComponent>
        </FieldContainer>

        {/* Password  input */}
        <FieldContainer data-cs-mask>
          <PasswordField
            aria-label={t('register.password')}
            placeholder={`${t('register.password')}*`}
            {...register('password')}
            aria-describedby="password-invalid"
            aria-invalid={!!formError.password}
            isInputError={!!formError.password}
            isEmpty={!password}
            register={register('password', {
              onBlur: onPasswordBlur,
            })}
            autoComplete="new-password"
            required
            minLength={10}
          />
          <ErrorMessageComponent id="password-invalid" {...formError?.password}>
            {t(formError?.password?.message as string)}
          </ErrorMessageComponent>
        </FieldContainer>
      </AllFields>

      <PasswordStrengthBar
        realTimePassword={password}
        isBadPassword={isBadPassword}
        passwordLevelText={[
          t('register.weak.password'),
          t('register.okay.password'),
          t('register.strong.password'),
          t('register.very.strong.password'),
        ]}
      />
      <PasswordHint>
        {'>'} {t('register.password.minimum')}
      </PasswordHint>

      {/* Auto Opt-In */}
      <MarketingChoices label={t('register.subscribe.to.our.emails')}>
        <SubscribeText>{t('register.subscribe.to.our.emails')}</SubscribeText>
        <MasterCheckboxContainer>
          <Checkbox
            label={t('register.subscribe.to.all.emails')}
            checked={!!masterIsEnabled}
            onChange={handleMasterCheckboxOnChange}
            testId="marketingAllCheckbox"
          />
        </MasterCheckboxContainer>
        <EmailChoices $itemLength={itemLength}>
          {Object.entries(marketingPreferences?.marketingPreferences ?? {}).map(([key, item]) => (
            <Checkbox
              key={item.key}
              label={t(getTranslation(item.key))}
              onChange={handleCheckboxChange}
              checked={categoryChoices[+key].state}
              name={key}
              testId="marketingSingleCheckbox"
            />
          ))}
        </EmailChoices>
      </MarketingChoices>
      {recaptchaError === RegistrationError.RECAPTCHA_ERROR && (
        <ErrorMessage id="recaptcha-invalid">{t('recaptcha.error.message')}</ErrorMessage>
      )}
      {/* Submit  button */}
      <BlueButton
        type="submit"
        aria-label={t('register.create.account')}
        disabled={loading || isBadPassword || !!data}
        data-testid="createAccountButton"
      >
        {t('register.create.account')}
      </BlueButton>

      <TermsAndCondition>
        <Trans
          i18nKey="register.agreement"
          t={t}
          components={[
            <TransLink
              aria-label={t('register.terms')}
              href={createAnchorTagHref({
                locale,
                path: '/terms-and-conditions',
              })}
              key="terms"
            />,
            <TransLink
              aria-label={t('register.policy')}
              href={createAnchorTagHref({
                locale,
                path: '/privacy-policy',
              })}
              key="privacy"
            />,
          ]}
          values={{
            terms: t('register.terms'),
            and: t('register.agreement.and'),
            policy: t('register.policy'),
          }}
        />
      </TermsAndCondition>

      <SocialLogins
        socials={[
          {
            label: 'register.social.apple',
            element: <Apple />,
            provider: SocialProvider.SIGNINWITHAPPLE,
          },
          {
            label: 'register.social.google',
            element: <Google />,
            provider: SocialProvider.GOOGLE,
          },
          {
            label: 'register.social.facebook',
            element: <Facebook />,
            provider: SocialProvider.FACEBOOK,
          },
        ]}
      />
    </Form>
  );
}

export default RegistrationForm;
