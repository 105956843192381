import styled from 'styled-components';
import { spacing } from '../../../stylings';

export const ProductsList = styled.ul`
  margin: ${spacing.XS} 0 ${spacing.S};
  padding: 0;
`;

export const ProductsListItem = styled.li`
  padding: ${spacing.XXXXS};
  &:not(:last-child) {
    margin-bottom: ${spacing.S};
  }
`;
