import type { HandlebarTemplateProps } from '../../globalComponents/handlebarTemplate';
import { isHandlebarComponent } from '../../utils/amplienceUtils';
import { logger } from '../../utils/logger';
import { withNewRelic } from '../newrelic';
import { cache } from '../redis-cache';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHandlebarTemplateComponentsBase = async (contentArray: Record<string, any>[]) => {
  const handlebarComponents = contentArray.filter((content) =>
    isHandlebarComponent(content?._meta?.schema as unknown as string),
  );

  if (!handlebarComponents || handlebarComponents?.length < 1) return null;

  const cacheKey = `handlebarPairsResult:${handlebarComponents
    .map((content) => content?._meta?.schema as unknown as string)
    .join()}`;
  const cachedResult = await cache().get(cacheKey);
  if (cachedResult) {
    return cachedResult;
  }

  const handlebarPairs: (HandlebarTemplateProps | null)[] = await Promise.all(
    handlebarComponents.map(async (component) => {
      const splitSchema = (component._meta.schema as string).split('/');
      const slug = splitSchema[splitSchema.length - 1].replace('.json', '');

      try {
        // Ensure the latest version is retrieved by appending `?version=${Date.now()}`
        const response = await fetch(`https://cdn.c1.amplience.net/c/clarks/${slug}?version=${Date.now()}`);
        const result = await response.text();

        if (result && response.status === 200) {
          const templateProps = {
            input: component,
            template: result,
          } as HandlebarTemplateProps;

          return templateProps;
        }

        if (response.status !== 200) {
          logger.debug({ response: result }, 'There was an error fetching handlebar template');
          return null;
        }

        return null;
      } catch (e) {
        logger.debug({ error: e }, 'There was an error fetching handlebar template');
        return null;
      }
    }),
  );

  // Cache the result of handlebarPairs.flatMap((pair) => pair ?? [])
  const flatMappedResult = handlebarPairs.flatMap((pair) => pair ?? []);
  await cache().set(cacheKey, flatMappedResult, { ttl: 60 * 10 });

  return flatMappedResult;
};

export const getHandlebarTemplateComponents = withNewRelic(
  'getHandlebarTemplateComponents',
  getHandlebarTemplateComponentsBase,
);
