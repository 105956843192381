import styled from 'styled-components';
import { colours, media } from '../../stylings';

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  .error {
    border: solid ${colours.RED} 1px;
    max-height: 44px;

    @media ${media.greaterThan('lg')} {
      max-height: 50px;
    }
  }
`;
