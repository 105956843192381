import { useEffect, useRef } from 'react';
import type { AnchorHTMLAttributes } from 'react';
import Link from 'next/link';
import ReactMarkdown from 'react-markdown';
import type { CodeProps, OrderedListProps, UnorderedListProps } from 'react-markdown/lib/ast-to-react';
import type { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { TableCSS, TypographyStyles, media } from '../../stylings';
import { simpleMarkdownTransform } from '../../utils/transformers';

export type RichTextProps = {
  text: string;
  fontSize: FlattenSimpleInterpolation;
  className?: string;
};

const S = {
  RichTextComponent: styled(ReactMarkdown)<{ $fontSize: FlattenSimpleInterpolation }>`
    ${({ $fontSize }) => $fontSize}

    hr {
      all: revert;
    }

    h1 {
      ${TypographyStyles.Headings.H1}
    }

    h2 {
      ${TypographyStyles.Headings.H2}
    }

    h3 {
      ${TypographyStyles.Headings.H3}
    }

    h4 {
      ${TypographyStyles.Headings.H4}
    }

    h5 {
      ${TypographyStyles.Headings.H5}
    }

    li p {
      margin: 0;
    }
  `,
  List: styled.ul<{ $type?: string }>`
    list-style: ${({ $type }) => $type ?? 'disc'};
    padding-left: 20px;
  `,
  NumberedList: styled.ol<{ $type?: string }>`
    list-style-type: ${({ $type }) => $type ?? 'numerical'};
    padding-left: 20px;
  `,
  BlockQuote: styled.blockquote`
    all: revert;
  `,
};

// prevents from adding node="[object Object]" to the anchor tag
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function NextLink({ href, children, node, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement> & ReactMarkdownProps) {
  if (href?.startsWith('mailto:')) {
    return <span>{children}</span>;
  }

  if (href?.includes('http')) {
    return (
      <a href={href} target="_blank" rel="noreferrer" {...rest}>
        {children}
      </a>
    );
  }

  // reverses simpleMarkdownTransform changes that turn underscores to em in links
  const hrefWithUnderscores = href?.replaceAll(/<em>|<\/em>/g, '_');

  return (
    <Link href={hrefWithUnderscores as string} passHref>
      <a {...rest}>{children}</a>
    </Link>
  );
}
function List({ children, depth }: UnorderedListProps) {
  if (depth === 0) return <S.List $type="disc">{children}</S.List>;
  if (depth === 1) return <S.List $type="circle">{children}</S.List>;
  return <S.List $type="square">{children}</S.List>;
}
function NumberedList({ children, depth }: OrderedListProps) {
  if (depth === 0) return <S.NumberedList $type="decimal">{children}</S.NumberedList>;
  if (depth === 1) return <S.NumberedList $type="lower-alpha">{children}</S.NumberedList>;
  return <S.NumberedList $type="lower-roman">{children}</S.NumberedList>;
}
function Table({ children }: ReactMarkdownProps) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (!isDesktop && tableRef.current) {
      const thElements = tableRef.current.querySelectorAll('th');
      const thValues = Array.from(thElements).map((th) => th.textContent);
      const tdElements = tableRef.current.querySelectorAll('td');

      Array.from(tdElements).forEach((td, index) => {
        if (td.innerHTML.trim() === '') {
          // eslint-disable-next-line no-param-reassign
          td.innerHTML = '&nbsp;';
        }
        const thValue = thValues[index % thValues.length];
        td.setAttribute('data-label', thValue ?? '');
      });
    }
  }, [isDesktop, tableRef]);

  return (
    <>
      <TableCSS />
      <table ref={tableRef}>{children}</table>
    </>
  );
}
function BlockQuote({ children }: ReactMarkdownProps) {
  return <S.BlockQuote>{children}</S.BlockQuote>;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Code({ inline, className, children, style, ...props }: CodeProps) {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <code {...props}>{String(children).replace(/\n$/, '')}</code>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
}

export function RichTextPartial({ text, fontSize, className }: RichTextProps) {
  return (
    <S.RichTextComponent
      components={{
        a: NextLink,
        ul: List,
        ol: NumberedList,
        table: Table,
        blockquote: BlockQuote,
        code: Code,
      }}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
      $fontSize={fontSize}
      className={className}
    >
      {simpleMarkdownTransform(text)}
    </S.RichTextComponent>
  );
}
