/* eslint-disable import/no-duplicates */
import {
  Address,
  Attribute,
  Cart,
  CartSetBillingAddressAction,
  CartSetShippingAddressAction,
  CartUpdateAction,
  CategoryReference,
  LineItem,
  Order,
  Product,
  ProductProjection,
} from '@commercetools/platform-sdk';
import { ProductVariant } from '@commercetools/platform-sdk/dist/declarations/src/generated/models/product';
import { Locale, ProductTypesEnum, Star2Store, Store as StoreEnum } from '@ct/types';
import { strict as assert } from 'assert';
import { Locale as DateFnsLocale } from 'date-fns';
import { de, deAT, enCA, enGB, enIE, enUS, es, fr, frCA, frCH, it, itCH, nl } from 'date-fns/locale';

export * from './inventories';
export * from './shippingMethods';

export type Store =
  | 'uk'
  | 'ca'
  | 'roi'
  | 'outlet-uk'
  | 'us'
  | 'fr'
  | 'de'
  | 'it'
  | 'es'
  | 'nl'
  | 'roe-at'
  | 'roe-be'
  | 'roe-ch'
  | 'roe-lu'
  | 'roe-sm'
  | 'roe-dk'
  | 'roe-pl'
  | 'roe-scand'
  | 'roe';

export enum AgeGroup {
  ADULT = 'adult',
  JUNIOR = 'junior',
  INFANT = 'infant',
}

export enum Locales {
  EN_GB = 'en-GB',
  EN_GB_OUTLET = 'en-GB-OUTLET',
  EN_US = 'en-US',
  EN_IE = 'en-IE',
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
  FR_FR = 'fr-FR',
  EN_FR = 'en-FR',
  DE_DE = 'de-DE',
  EN_DE = 'en-DE',
  IT_IT = 'it-IT',
  EN_IT = 'en-IT',
  ES_ES = 'es-ES',
  EN_ES = 'en-ES',
  NL_NL = 'nl-NL',
  EN_NL = 'en-NL',
  EN_AT = 'en-AT',
  DE_AT = 'de-AT',
  EN_BE = 'en-BE',
  NL_BE = 'nl-BE',
  FR_BE = 'fr-BE',
  EN_CH = 'en-CH',
  DE_CH = 'de-CH',
  FR_CH = 'fr-CH',
  IT_CH = 'it-CH',
  EN_DK = 'en-DK',
  EN_PL = 'en-PL',
  EN_SM = 'en-SM',
  IT_SM = 'it-SM',
  EN_LU = 'en-LU',
  FR_LU = 'fr-LU',
  EN_EUROPE = 'en-EUROPE',
  EN_SCAND = 'en-SCAND',
}

export enum ChannelFromEvent {
  UK = 'eCommerceUK',
  ROI = 'eCommerceROI',
  OUTLET_UK = 'eCommerceOutletUK',
  US = 'eCommerceUS',
  CA = 'eCommerceCA',
  DE = 'eCommerceDE',
  FR = 'eCommerceFR',
  IT = 'eCommerceIT',
  ES = 'eCommerceES',
  NL = 'eCommerceNL',
  ROE = 'eCommerceEU',
}

export const csaChannelSuffix = '-CSA';

export const mapChannelFromEvent: (channelName: string) => ChannelFromEvent = (channel) => {
  const channelName = channel.replace(csaChannelSuffix, '');
  assert(
    Object.values(ChannelFromEvent).includes(channelName as ChannelFromEvent),
    `channel: ${channelName} must be one of from ChannelFromEvent`,
  );
  return channelName as ChannelFromEvent;
};

export const channelToLocale: { [key in ChannelFromEvent]: Locales[] } = {
  [ChannelFromEvent.UK]: [Locales.EN_GB],
  [ChannelFromEvent.ROI]: [Locales.EN_IE],
  [ChannelFromEvent.OUTLET_UK]: [Locales.EN_GB_OUTLET],
  [ChannelFromEvent.US]: [Locales.EN_US],
  [ChannelFromEvent.CA]: [Locales.EN_CA, Locales.FR_CA],
  [ChannelFromEvent.DE]: [Locales.DE_DE, Locales.EN_DE],
  [ChannelFromEvent.FR]: [Locales.FR_FR, Locales.EN_FR],
  [ChannelFromEvent.IT]: [Locales.IT_IT, Locales.EN_IT],
  [ChannelFromEvent.ES]: [Locales.ES_ES, Locales.EN_ES],
  [ChannelFromEvent.NL]: [Locales.NL_NL, Locales.EN_NL],
  [ChannelFromEvent.ROE]: [
    Locales.EN_EUROPE,
    Locales.DE_AT,
    Locales.EN_AT,
    Locales.NL_BE,
    Locales.FR_BE,
    Locales.EN_BE,
    Locales.DE_CH,
    Locales.FR_CH,
    Locales.IT_CH,
    Locales.EN_CH,
    Locales.FR_LU,
    Locales.EN_LU,
    Locales.IT_SM,
    Locales.EN_SM,
    Locales.EN_DK,
    Locales.EN_PL,
    Locales.EN_SCAND,
  ],
};
// source(Akeneo):[destination1(ct),destination2(ct)]
export const localeMappingStar2: Record<Locale, Locale[]> = {
  en: [Locale.EN],
  [Locale.EN_EU]: [
    Locale.EN_DE,
    Locale.EN_FR,
    Locale.EN_IT,
    Locale.EN_ES,
    Locale.EN_NL,
    Locale.EN_AT,
    Locale.EN_CH,
    Locale.EN_SM,
    Locale.EN_PL,
    Locale.EN_BE,
    Locale.EN_DK,
    Locale.EN_LU,
    Locale.EN_EUROPE,
    Locale.EN_SCAND,
  ],
  [Locale.EN_GB]: [Locale.EN_GB],
  [Locale.FR_FR]: [Locale.FR_FR, Locale.FR_CH, Locale.FR_BE, Locale.FR_LU],
  [Locale.IT_IT]: [Locale.IT_IT, Locale.IT_CH, Locale.IT_SM],
  [Locale.DE_DE]: [Locale.DE_DE, Locale.DE_AT, Locale.DE_CH],
  [Locale.NL_NL]: [Locale.NL_NL, Locale.NL_BE],
  [Locale.ES_ES]: [Locale.ES_ES],
  [Locale.EN_GB_OUTLET]: [Locale.EN_GB_OUTLET],
  [Locale.EN_US]: [Locale.EN_US],
  [Locale.EN_IE]: [Locale.EN_IE],
  [Locale.EN_CA]: [Locale.EN_CA],
  [Locale.FR_CA]: [Locale.FR_CA],
  [Locale.EN_FR]: [],
  [Locale.EN_DE]: [],
  [Locale.EN_IT]: [],
  [Locale.EN_ES]: [],
  [Locale.EN_NL]: [],
  [Locale.EN_AT]: [],
  [Locale.DE_AT]: [],
  [Locale.EN_BE]: [],
  [Locale.NL_BE]: [],
  [Locale.FR_BE]: [],
  [Locale.EN_CH]: [],
  [Locale.DE_CH]: [],
  [Locale.FR_CH]: [],
  [Locale.IT_CH]: [],
  [Locale.EN_DK]: [],
  [Locale.EN_PL]: [],
  [Locale.EN_SM]: [],
  [Locale.IT_SM]: [],
  [Locale.EN_LU]: [],
  [Locale.FR_LU]: [],
  [Locale.EN_EUROPE]: [],
  [Locale.EN_SCAND]: [],
};
export const eventChannelToStore: { [key in ChannelFromEvent]: Store } = {
  [ChannelFromEvent.UK]: 'uk',
  [ChannelFromEvent.ROI]: 'roi',
  [ChannelFromEvent.CA]: 'ca',
  [ChannelFromEvent.US]: 'us',
  [ChannelFromEvent.OUTLET_UK]: 'outlet-uk',
  [ChannelFromEvent.DE]: 'de',
  [ChannelFromEvent.FR]: 'fr',
  [ChannelFromEvent.IT]: 'it',
  [ChannelFromEvent.ES]: 'es',
  [ChannelFromEvent.NL]: 'nl',
  [ChannelFromEvent.ROE]: 'roe',
};

export const eventChannelToCTChannel: { [key in ChannelFromEvent]: string } = {
  [ChannelFromEvent.UK]: 'fullPrice',
  [ChannelFromEvent.ROI]: 'fullPrice',
  [ChannelFromEvent.CA]: 'fullPrice',
  [ChannelFromEvent.US]: 'fullPrice',
  [ChannelFromEvent.OUTLET_UK]: 'outlet',
  [ChannelFromEvent.DE]: 'fullPrice',
  [ChannelFromEvent.FR]: 'fullPrice',
  [ChannelFromEvent.IT]: 'fullPrice',
  [ChannelFromEvent.ES]: 'fullPrice',
  [ChannelFromEvent.NL]: 'fullPrice',
  [ChannelFromEvent.ROE]: 'fullPrice',
};

export const storeToLocales = new Map<Store, Locales[]>([
  ['uk', [Locales.EN_GB]],
  ['ca', [Locales.EN_CA, Locales.FR_CA]],
  ['roi', [Locales.EN_IE]],
  ['outlet-uk', [Locales.EN_GB_OUTLET]],
  ['us', [Locales.EN_US]],
  ['de', [Locales.DE_DE, Locales.EN_DE]],
  ['fr', [Locales.FR_FR, Locales.EN_FR]],
  ['it', [Locales.IT_IT, Locales.EN_IT]],
  ['es', [Locales.ES_ES, Locales.EN_ES]],
  ['nl', [Locales.NL_NL, Locales.EN_NL]],
  ['roe-ch', [Locales.EN_CH, Locales.DE_CH, Locales.FR_CH, Locales.IT_CH]],
  ['roe-pl', [Locales.EN_PL]],
  ['roe-dk', [Locales.EN_DK]],
  ['roe-be', [Locales.EN_BE, Locales.FR_BE, Locales.NL_BE]],
  ['roe-at', [Locales.EN_AT, Locales.DE_AT]],
  ['roe-lu', [Locales.EN_LU, Locales.FR_LU]],
  ['roe-sm', [Locales.EN_SM, Locales.IT_SM]],
  ['roe', [Locales.EN_EUROPE]],
  ['roe-scand', [Locales.EN_SCAND]],
]);

export function getIndexedStoresList(): Store[] {
  return Array.from(storeToLocales.keys());
}

export const localesConfluent = [
  'en_GB',
  'en_CA',
  'fr_CA',
  'en_IE',
  'en_GB_OUTLET',
  'en_US',
  'fr_FR',
  'en_FR',
  'de_DE',
  'en_DE',
  'it_IT',
  'en_IT',
  'es_ES',
  'en_ES',
  'nl_NL',
  'en_NL',
  'en_AT',
  'de_AT',
  'en_BE',
  'nl_BE',
  'fr_BE',
  'en_CH',
  'de_CH',
  'fr_CH',
  'it_CH',
  'en_DK',
  'en_PL',
  'en_SM',
  'it_SM',
  'en_LU',
  'fr_LU',
  'en_EUROPE',
  'en_SCAND',
];

export const defaultLocale = 'en';

export const currencyMapper: {
  [key: string]: string;
} = {
  en: '',
  'en-gb': 'GBP',
  'en-ie': 'EUR',
  'en-us': 'USD',
  'en-ca': 'CAD',
  'fr-ca': 'CAD',
  'en-vg': 'GBP',
  'en-gb-outlet': 'GBP',
  'de-de': 'EUR',
  'en-de': 'EUR',
  'fr-fr': 'EUR',
  'en-fr': 'EUR',
  'it-it': 'EUR',
  'en-it': 'EUR',
  'es-es': 'EUR',
  'en-es': 'EUR',
  'nl-nl': 'EUR',
  'en-nl': 'EUR',
  'de-at': 'EUR',
  'en-at': 'EUR',
  'de-ch': 'CHF',
  'fr-ch': 'CHF',
  'it-ch': 'CHF',
  'en-ch': 'CHF',
  'it-sm': 'EUR',
  'en-sm': 'EUR',
  'en-pl': 'PLN',
  'nl-be': 'EUR',
  'fr-be': 'EUR',
  'en-be': 'EUR',
  'en-dk': 'DKK',
  'fr-lu': 'EUR',
  'en-lu': 'EUR',
  'en-ba': 'EUR',
  'en-bg': 'EUR',
  'en-hr': 'EUR',
  'en-cy': 'EUR',
  'en-cz': 'EUR',
  'en-ee': 'EUR',
  'en-fi': 'EUR',
  'en-ge': 'EUR',
  'en-gi': 'EUR',
  'en-gr': 'EUR',
  'en-hu': 'EUR',
  'en-is': 'EUR',
  'en-lv': 'EUR',
  'en-li': 'EUR',
  'en-lt': 'EUR',
  'en-mt': 'EUR',
  'en-mc': 'EUR',
  'en-me': 'EUR',
  'en-mk': 'EUR',
  'en-no': 'EUR',
  'en-pt': 'EUR',
  'en-md': 'EUR',
  'en-ro': 'EUR',
  'en-rs': 'EUR',
  'en-sk': 'EUR',
  'en-si': 'EUR',
  'en-se': 'EUR',
  'en-tr': 'EUR',
  'en-europe': 'EUR',
  'en-scand': 'EUR',
};

/**
 * Needed for zones in the shipping methods
 */

//  STAR2-TODO - WE NEED TO REFACTOR THIS BECAUSE NOW THERE ARE MANY COUNTRIES WITH SAME CURRENCY
export const currencyCountryMapper: {
  [key: string]: string;
} = {
  GBP: 'UK',
  EUR: 'IE',
  USD: 'US',
  CAD: 'CA',
};

export function countryToCurrencyMapper(country: string) {
  const [currency] = Object.entries(currencyCountryMapper).find(([_, value]) => value === country.toUpperCase()) ?? [];
  return currency;
}

const countryCurrencyMapper: {
  [key: string]: string;
} = {
  UK: 'GBP',
  IE: 'EUR',
  US: 'USD',
  PR: 'USD',
  CA: 'CAD',
  DE: 'EUR',
  IT: 'EUR',
  FR: 'EUR',
  NL: 'EUR',
  ES: 'EUR',
  //  STAR2 ENTRIES
  AT: 'EUR',
  BE: 'EUR',
  LU: 'EUR',
  SM: 'EUR',
  DK: 'DKK',
  PL: 'PLN',
  CH: 'CHF',
};

export function mapCountryToCurrency(country: string): string | undefined {
  return countryCurrencyMapper[country?.toUpperCase()];
}

const storeKeyMap: {
  [key: string]: string;
} = {
  en: '',
  'en-gb': 'uk',
  'en-ie': 'roi',
  'en-us': 'us',
  'en-ca': 'ca',
  'fr-ca': 'ca',
  'en-vg': 'outlet-uk',
  'en-gb-outlet': 'outlet-uk',
  'de-de': 'de',
  'en-de': 'de',
  'fr-fr': 'fr',
  'en-fr': 'fr',
  'it-it': 'it',
  'en-it': 'it',
  'es-es': 'es',
  'en-es': 'es',
  'nl-nl': 'nl',
  'en-nl': 'nl',
  'de-at': 'roe-at',
  'en-at': 'roe-at',
  'de-ch': 'roe-ch',
  'fr-ch': 'roe-ch',
  'it-ch': 'roe-ch',
  'en-ch': 'roe-ch',
  'it-sm': 'roe-sm',
  'en-sm': 'roe-sm',
  'en-pl': 'roe-pl',
  'nl-be': 'roe-be',
  'fr-be': 'roe-be',
  'en-be': 'roe-be',
  'en-dk': 'roe-dk',
  'fr-lu': 'roe-lu',
  'en-lu': 'roe-lu',
  'en-ba': 'roe',
  'en-bg': 'roe',
  'en-hr': 'roe',
  'en-cy': 'roe',
  'en-cz': 'roe',
  'en-ee': 'roe',
  'en-fi': 'roe-scand',
  'en-ge': 'roe',
  'en-gi': 'roe',
  'en-gr': 'roe',
  'en-hu': 'roe',
  'en-is': 'roe',
  'en-lv': 'roe',
  'en-li': 'roe',
  'en-lt': 'roe',
  'en-mt': 'roe',
  'en-mc': 'roe',
  'en-me': 'roe',
  'en-mk': 'roe',
  'en-no': 'roe-scand',
  'en-pt': 'roe',
  'en-md': 'roe',
  'en-ro': 'roe',
  'en-rs': 'roe',
  'en-sk': 'roe',
  'en-si': 'roe',
  'en-se': 'roe-scand',
  'en-tr': 'roe',
  'en-europe': 'roe',
  'en-scand': 'roe-scand',
};

export const ctLocaleToStoreLocales = (locale: string): string[] => {
  const multiStoreLocalesMap: Record<string, string[]> = {
    'en-scand': ['en-fi', 'en-no', 'en-se'],
    'en-europe': [
      'en-ba',
      'en-bg',
      'en-hr',
      'en-cy',
      'en-cz',
      'en-ee',
      'en-ge',
      'en-gi',
      'en-gr',
      'en-hu',
      'en-is',
      'en-lv',
      'en-li',
      'en-lt',
      'en-mt',
      'en-mc',
      'en-me',
      'en-mk',
      'en-pt',
      'en-md',
      'en-ro',
      'en-rs',
      'en-sk',
      'en-si',
      'en-tr',
    ],
  };

  return multiStoreLocalesMap[locale.toLowerCase()] ?? [locale];
};

const ctLocaleToISO: {
  [key: string]: string;
} = {
  en: '',
  'en-gb': 'en-gb',
  'en-ie': 'en-ie',
  'en-us': 'en-us',
  'en-ca': 'en-ca',
  'fr-ca': 'fr-ca',
  'en-vg': 'en-gb',
  'en-gb-outlet': 'en-gb',
};

const localeToRegisteredFromMap: {
  [key: string]: string;
} = {
  'en-at': 'at',
  'en-ca': 'ca',
  'en-ch': 'ch',
  'de-de': 'de',
  'en-gb': 'uk',
  'en-gb-outlet': 'outletuk',
  'en-ie': 'roi',
  'en-pl': 'pl',
  'en-pt': 'pt',
  'en-se': 'se',
  'en-us': 'us',
  'en-vg': 'outletuk',
  'es-es': 'es',
  'fr-ca': 'ca',
  'fr-fr': 'fr',
};

export function getCtLocleToISO(locale: string) {
  const lowerLocale = locale.toLowerCase();
  return ctLocaleToISO[lowerLocale];
}

export function storeKeyMapper(locale: string | undefined) {
  const lowerLocale = locale?.toLowerCase();
  return storeKeyMap[lowerLocale as string];
}

export function localeToRegisteredFromMapper(locale: string) {
  const lowerLocale = locale.toLowerCase();
  return localeToRegisteredFromMap[lowerLocale] || 'Unknown';
}

export function isStar2StoreKey(key: string): key is Star2Store {
  return Object.values(Star2Store).includes(key as Star2Store);
}

const storeToChannelsMap: {
  [key in Store]: ChannelFromEvent;
} = {
  uk: ChannelFromEvent.UK,
  ca: ChannelFromEvent.CA,
  us: ChannelFromEvent.US,
  roi: ChannelFromEvent.ROI,
  'outlet-uk': ChannelFromEvent.OUTLET_UK,
  fr: ChannelFromEvent.FR,
  de: ChannelFromEvent.DE,
  it: ChannelFromEvent.IT,
  es: ChannelFromEvent.ES,
  nl: ChannelFromEvent.NL,
  'roe-at': ChannelFromEvent.ROE,
  'roe-be': ChannelFromEvent.ROE,
  'roe-ch': ChannelFromEvent.ROE,
  'roe-lu': ChannelFromEvent.ROE,
  'roe-sm': ChannelFromEvent.ROE,
  'roe-dk': ChannelFromEvent.ROE,
  'roe-pl': ChannelFromEvent.ROE,
  'roe-scand': ChannelFromEvent.ROE,
  roe: ChannelFromEvent.ROE,
};

const storeToFitMap: {
  [key in Store]: string;
} = {
  uk: 'ukFit',
  ca: 'usFit',
  us: 'usFit',
  roi: 'ukFit',
  'outlet-uk': 'ukFit',
  fr: 'euFit',
  de: 'euFit',
  it: 'euFit',
  es: 'euFit',
  nl: 'euFit',
  'roe-at': 'euFit',
  'roe-be': 'euFit',
  'roe-ch': 'euFit',
  'roe-lu': 'euFit',
  'roe-sm': 'euFit',
  'roe-dk': 'euFit',
  'roe-pl': 'euFit',
  'roe-scand': 'euFit',
  roe: 'euFit',
};

const storeToFitLabelMap: {
  [key in Store]: string;
} = {
  uk: 'ukFitLabel',
  ca: 'usFitLabel',
  us: 'usFitLabel',
  roi: 'ukFitLabel',
  'outlet-uk': 'ukFitLabel',
  fr: 'euFitLabel',
  de: 'euFitLabel',
  it: 'euFitLabel',
  es: 'euFitLabel',
  nl: 'euFitLabel',
  'roe-at': 'euFitLabel',
  'roe-be': 'euFitLabel',
  'roe-ch': 'euFitLabel',
  'roe-lu': 'euFitLabel',
  'roe-sm': 'euFitLabel',
  'roe-dk': 'euFitLabel',
  'roe-pl': 'euFitLabel',
  'roe-scand': 'euFitLabel',
  roe: 'euFitLabel',
};

const storeToSizeMap: {
  [key in Store]: string;
} = {
  uk: 'ukSize',
  ca: 'usSize',
  us: 'usSize',
  roi: 'ukSize',
  'outlet-uk': 'ukSize',
  fr: 'euSize',
  de: 'euSize',
  it: 'euSize',
  es: 'euSize',
  nl: 'euSize',
  'roe-at': 'euSize',
  'roe-be': 'euSize',
  'roe-ch': 'euSize',
  'roe-lu': 'euSize',
  'roe-sm': 'euSize',
  'roe-dk': 'euSize',
  'roe-pl': 'euSize',
  'roe-scand': 'euSize',
  roe: 'euSize',
};

type StoreFitKey = keyof typeof storeToFitMap;
type StoreSizeKey = keyof typeof storeToSizeMap;

export function getFitAttributeName(store: Store) {
  return storeToFitMap[store as StoreFitKey];
}

export function getFitLabelAttributeName(store: Store) {
  return storeToFitLabelMap[store as StoreFitKey];
}

export function getSizeAttributeName(store: Store) {
  return storeToSizeMap[store as StoreSizeKey];
}

export function fitMapper(locale: string) {
  return getFitAttributeName(storeKeyMapper(locale) as Store);
}

export function fitLabelMapper(locale: string) {
  return getFitLabelAttributeName(storeKeyMapper(locale) as Store);
}

export function sizeMapper(locale: string) {
  return getSizeAttributeName(storeKeyMapper(locale) as Store);
}

export function storeToChannelsMapper(store: string) {
  return storeToChannelsMap[store as Store];
}

export function getAgeGroup(globalSize: string) {
  const globalSizeNumber = parseInt(globalSize, 10);
  if (globalSizeNumber >= 8020 && globalSizeNumber <= 9170) {
    return AgeGroup.ADULT;
  }
  if (globalSizeNumber >= 20 && globalSizeNumber <= 135) {
    return AgeGroup.INFANT;
  }
  if (globalSizeNumber >= 6010 && globalSizeNumber <= 6105) {
    return AgeGroup.JUNIOR;
  }
  return null;
}

const localeToCountryCode: {
  [key: string]: string;
} = {
  en: '',
  'en-gb': 'GB',
  'en-ie': 'IE',
  'en-us': 'US',
  'en-ca': 'CA',
  'fr-ca': 'CA',
  'en-vg': 'GB',
  'en-gb-outlet': 'GB',
};

export function countryCodeMapper(locale: string) {
  const lowerLocale = locale.toLowerCase();
  return localeToCountryCode[lowerLocale];
}

export const isStar1Locale = (locale: string | undefined): boolean => {
  const star1Locales = ['en-gb', 'en-ie', 'en-us', 'en-ca', 'fr-ca', 'en-vg', 'en-gb-outlet', 'en'];
  return !!locale && star1Locales.includes(locale.toLowerCase());
};

const localeToChannel: {
  [key: string]: string;
} = {
  'en-gb': 'fullPrice',
  'en-ie': 'fullPrice',
  'en-us': 'fullPrice',
  'en-ca': 'fullPrice',
  'fr-ca': 'fullPrice',
  'en-vg': 'outlet',
  'en-gb-outlet': 'outlet',
  'fr-fr': 'fullPrice',
  'en-fr': 'fullPrice',
  'de-de': 'fullPrice',
  'en-de': 'fullPrice',
  'it-it': 'fullPrice',
  'en-it': 'fullPrice',
  'es-es': 'fullPrice',
  'en-es': 'fullPrice',
  'nl-nl': 'fullPrice',
  'en-nl': 'fullPrice',
  'de-at': 'fullPrice',
  'en-at': 'fullPrice',
  'nl-be': 'fullPrice',
  'fr-be': 'fullPrice',
  'en-be': 'fullPrice',
  'de-ch': 'fullPrice',
  'fr-ch': 'fullPrice',
  'it-ch': 'fullPrice',
  'en-ch': 'fullPrice',
  'fr-lu': 'fullPrice',
  'en-lu': 'fullPrice',
  'it-sm': 'fullPrice',
  'en-sm': 'fullPrice',
  'en-dk': 'fullPrice',
  'en-pl': 'fullPrice',
  'en-europe': 'fullPrice',
  'en-scand': 'fullPrice',
};
export function channelMapper(locale: string) {
  const lowerLocale = locale.toLowerCase();
  return localeToChannel[lowerLocale];
}

export enum StockStatus {
  IN_STOCK = 'inStock',
  OUT_OF_STOCK = 'outOfStock',
}

const localeToDateFnsLocale: {
  [key: string]: DateFnsLocale;
} = {
  'en-gb': enGB,
  'en-ie': enIE,
  'en-us': enUS,
  'en-ca': enCA,
  'fr-ca': frCA,
  'en-vg': enGB,
  'en-gb-outlet': enGB,
  'fr-fr': fr,
  'en-fr': enGB,
  'de-de': de,
  'en-de': enGB,
  'it-it': it,
  'en-it': enGB,
  'es-es': es,
  'en-es': enGB,
  'nl-nl': nl,
  'en-nl': enGB,
  'de-at': deAT,
  'en-at': enGB,
  'nl-be': nl,
  'fr-be': fr,
  'en-be': enGB,
  'de-ch': de,
  'fr-ch': frCH,
  'it-ch': itCH,
  'en-ch': enGB,
  'fr-lu': fr,
  'en-lu': enGB,
  'it-sm': it,
  'en-sm': enGB,
  'en-dk': enGB,
  'en-pl': enGB,
  'en-europe': enGB,
  'en-scand': enGB,
};

export function localeToDateFnsLocaleMapper(locale: string) {
  return localeToDateFnsLocale[locale.toLowerCase()];
}

export enum DeliveryInstructionsKeys {
  RECEPTION = 'reception',
  NEIGHBOUR = 'neighbour',
  SAFE_PLACE = 'safe-place',
  OTHER = 'other',
}

export const deliveryInstructionsKeys = Object.values(DeliveryInstructionsKeys) as string[];

export enum FulfillmentGroup {
  UK = 'uk_ecom',
  UK_OUTLET = 'uk_outlet_ecom',
  US = 'us_ecom',
  ROI = 'uk_ecom',
  CA = 'canada_ecom',
}

const localeToFulfillmentGroup: {
  [key: string]: FulfillmentGroup;
} = {
  'en-gb': FulfillmentGroup.UK,
  'en-ie': FulfillmentGroup.ROI,
  'en-us': FulfillmentGroup.US,
  'en-ca': FulfillmentGroup.CA,
  'fr-ca': FulfillmentGroup.CA,
  'en-vg': FulfillmentGroup.UK_OUTLET,
  'en-gb-outlet': FulfillmentGroup.UK_OUTLET,
};

export function fulfillmentGroupMapper(locale: string) {
  return localeToFulfillmentGroup[locale.toLowerCase()];
}

export type PrimaryCategory = {
  name: 'primaryCategory';
  value: CategoryReference;
};

export type Scope = {
  name: 'scope';
  value: string;
};

export type PrimaryCategories = [Scope, PrimaryCategory][];

function getAttributes(product: ProductProjection | Product | ProductVariant): Attribute[] | undefined {
  let attributes;
  if ('masterData' in product) {
    attributes = product.masterData.current.masterVariant.attributes;
  } else if ('masterVariant' in product) {
    attributes = product.masterVariant.attributes;
  } else {
    attributes = product.attributes;
  }
  return attributes;
}

export function getAttributeValue(attributeName: string, product: ProductProjection | Product | ProductVariant) {
  const findFunction = (attr: Attribute) => attr.name === attributeName;
  const attributeObject = getAttributes(product)?.find(findFunction);
  return attributeObject?.value;
}

export const getPrimaryCategory = (
  product: Product | ProductProjection | ProductVariant,
  rootCategory: CategoryReference,
  locale: string | string[],
) => {
  const primaryCategories: PrimaryCategories | undefined = getAttributeValue('primaryCategories', product);

  //  [{name: 'scope'; value: string;}, {name: 'primaryCategory'; value: CategoryReference;}]
  const primaryCategoryEntry = primaryCategories?.find((primaryCategoryArray) => {
    const scope = primaryCategoryArray.find((entry) => entry.name === 'scope') as Scope;
    const locales = Array.isArray(locale) ? locale : [locale];
    return locales.includes(scope?.value);
  });
  const primaryCategoryReference = primaryCategoryEntry?.find((entry) => entry.name === 'primaryCategory')
    ?.value as CategoryReference;

  const findRootCategory = primaryCategoryReference?.obj?.ancestors[0]?.id === rootCategory.id;
  return (findRootCategory && primaryCategoryReference?.obj) || undefined;
};

export function isDigitalProduct(product: LineItem | Product, skipExpandedProductCheck?: boolean): boolean {
  if (skipExpandedProductCheck !== true) {
    assert(product.productType.obj, 'Product type is not expanded');
  }

  return ProductTypesEnum.EGIFT_CARD === product.productType?.obj?.key;
}

export function isPhysicalGiftCardProduct(product: LineItem | Product): boolean {
  assert(product.productType.obj, 'Product type is not expanded');
  return ProductTypesEnum.GIFT_CARD === product.productType.obj.key;
}

export function isGiftCardProduct(product: LineItem | Product): boolean {
  assert(product.productType.obj, 'Product type is not expanded');
  return [ProductTypesEnum.EGIFT_CARD, ProductTypesEnum.GIFT_CARD].includes(
    product.productType.obj.key as ProductTypesEnum,
  );
}

export function isDigitalCart(cart: Cart | Order, skipExpandedProductCheck?: boolean) {
  return (
    cart?.lineItems?.length > 0 &&
    cart?.lineItems?.every((lineItem) => isDigitalProduct(lineItem, skipExpandedProductCheck))
  );
}

export function isGiftCardCart(cart: Cart | Order) {
  return cart?.lineItems?.length > 0 && cart?.lineItems?.every((lineItem) => isGiftCardProduct(lineItem));
}

export function cartHasPhysicalGiftCard(cart: Cart | Order): boolean {
  return cart?.lineItems?.length > 0 && cart?.lineItems?.some((lineItem) => isPhysicalGiftCardProduct(lineItem));
}

export function cartHasGiftCard(cart: Cart | Order): boolean {
  return cart.lineItems.some((lineItem) => isGiftCardProduct(lineItem));
}

export const DUMMY_ADDRESS_PREFIX = 'DUMMY-';

type CartLike = Cart | { store: { key: string } };

export const getDummyAddress = <T extends CartLike>(cart: T): Address => {
  const key = `${DUMMY_ADDRESS_PREFIX}${cart.store?.key}-${new Date().getTime()}`;
  switch (cart.store?.key) {
    case 'uk':
      return {
        key,
        country: 'GB',
        postalCode: 'BA16 0EQ',
        firstName: 'Clarks',
        lastName: 'Clarks',
        phone: '012345678',
        city: 'Somerset',
        company: 'Clarks',
        streetName: 'High Street',
        streetNumber: '40',
      };
    case 'outlet-uk':
      return {
        key,
        country: 'GB',
        postalCode: 'BA16 0EQ',
        firstName: 'Clarks',
        lastName: 'Clarks',
        phone: '012345678',
        city: 'Somerset',
        company: 'Clarks',
        streetName: 'High Street',
        streetNumber: '40',
      };
    case 'roi':
      return {
        key,
        country: 'IE',
        postalCode: 'DN06069',
        firstName: 'Clarks',
        lastName: 'Clarks',
        phone: '012345678',
        city: 'Dublin',
        company: 'Clarks',
        streetName: 'Henry Street',
        streetNumber: '25',
      };
    case 'ca':
      return {
        key,
        country: 'CA',
        postalCode: 'L5L 5X5',
        firstName: 'Clarks',
        lastName: 'Clarks',
        phone: '012345678',
        city: 'Mississauga',
        company: 'Clarks',
        state: 'ON',
        streetName: 'Ridgeway Drive, Unit #1',
        streetNumber: '4090',
      };
    case 'us':
      return {
        key,
        country: 'US',
        postalCode: '02494',
        firstName: 'Clarks',
        lastName: 'Clarks',
        phone: '012345678',
        city: 'Needham',
        company: 'Clarks',
        streetName: 'Kendrick Street',
        streetNumber: '140',
        state: 'MA',
      };
    default:
      throw new Error(`Unsupported store: ${cart.store?.key}`);
  }
};

export const hasDummyAddress = (cart: Cart | Order): boolean => (cart.shippingAddress?.key ?? '').startsWith('DUMMY-');

export const hasValidDummyAddress = (cart: Cart | Order): boolean =>
  (cart.shippingAddress?.key ?? '').startsWith(`DUMMY-${cart.store?.key}`);

/**
 * Override shipping address for digital carts.
 * If no shipping and no billing address, we default to the clarks HQ address.
 * If we do have a billing address and it doesn't match existing shipping address, then we use billing as shipping address.
 */
const getShippingAddressOverride = (cart: Cart, cartActions: CartUpdateAction[]) => {
  if (!isDigitalCart(cart)) {
    return null;
  }

  const shippingMethodAction = cartActions.find(
    (cartAction): cartAction is CartSetShippingAddressAction => cartAction.action === 'setShippingAddress',
  );

  if (shippingMethodAction) {
    return null;
  }

  const billingAddressAction = cartActions.find(
    (cartAction): cartAction is CartSetBillingAddressAction => cartAction.action === 'setBillingAddress',
  );
  const billingAddress = billingAddressAction?.address || cart.billingAddress;
  const isUpdatedBillingAddress = !!billingAddress && cart.shippingAddress?.key !== billingAddress?.key;

  const shouldUpdateShippingAddress = !cart.shippingAddress || isUpdatedBillingAddress;

  if (!shouldUpdateShippingAddress) {
    return null;
  }

  return billingAddress || getDummyAddress(cart);
};

const hasProductTypeExpanded = (cart: Cart): boolean =>
  !!(cart.lineItems.length > 0 && cart.lineItems[0].productType.obj);

export const getDigitalCartUpdateActions = (
  cart: Cart,
  initialActions: CartUpdateAction[] = [],
): CartUpdateAction[] => {
  if (cart.lineItems.length === 0) {
    return hasDummyAddress(cart)
      ? [
          {
            action: 'setShippingAddress',
          },
        ]
      : [];
  }
  if (hasProductTypeExpanded(cart)) {
    if (hasDummyAddress(cart) && !isDigitalCart(cart)) {
      return [
        {
          action: 'setShippingAddress',
        },
      ];
    }

    const shippingAddress = getShippingAddressOverride(cart, initialActions);

    if (shippingAddress) {
      return [
        {
          action: 'setShippingAddress',
          address: shippingAddress,
        },
      ];
    }
  }
  return [];
};

export const INVENTORY_RESERVATION_STORES = ['outlet-uk'];

export const isStoreWithStockReservation = (storeKey: string | undefined): boolean =>
  storeKey ? INVENTORY_RESERVATION_STORES.includes(storeKey) : false;

// https://pangaea.atlassian.net/browse/REP-11799
export function upperCasePromoCode(code: string) {
  if (code.length <= 15) {
    return code.toUpperCase();
  }
  return code;
}
export function isOutlet(cart: Cart) {
  return isOutletLocale(cart.locale);
}

export function isOutletLocale(locale: string | undefined) {
  return locale?.toLocaleLowerCase() === 'en-gb-outlet' || locale?.toLocaleLowerCase() === 'en-vg';
}

export function generateInvalidateDelivery(cart: Cart): CartUpdateAction[] {
  const actions: CartUpdateAction[] = [];
  if (cart.shippingInfo?.shippingMethod) {
    actions.push({
      action: 'setShippingMethod',
    });
  }
  if (cart.custom?.fields?.deliveryInfo) {
    actions.push({
      action: 'setCustomField',
      name: 'deliveryInfo',
    });
  }
  if (cart.custom?.fields?.shippingType) {
    actions.push({
      action: 'setCustomField',
      name: 'shippingType',
    });
  }

  return actions;
}

export function parseToCents(amount: number | undefined): number {
  if (typeof amount !== 'number') {
    throw new Error('Amount must be type of number to parse it to cents format.');
  }

  return Math.round(amount * 100);
}
