import type { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { Image, Link, SubNav as SubNavSchema } from '@amplience/content-types/typings/c-sub-nav';
import { Separator } from '../../baseComponents/separator/Separator';
import { useUserContext } from '../../context/userContext';
import { useLogout } from '../../hooks/useLogout';
import { ImagePartial } from '../../partials/image';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, colours, maxWidthPartial, media, spacing } from '../../stylings';
import { sendNavigationEvent } from '../../utils/gtm/events/navigation/sendNavigationEvent';
import { getLocalizedValue } from '../../utils/transformers';

export type SubNavProps = SubNavSchema & {
  forDesktop?: boolean;
};

const S = {
  Nav: styled.nav<{ $forDesktop: boolean }>`
    display: ${({ $forDesktop }) => $forDesktop && 'none'};
    margin-top: ${({ $forDesktop }) => ($forDesktop ? 'initial' : '16px')};

    @media ${media.greaterThan('lg')} {
      background-color: ${colours.MEDIUM_GREY};
      display: ${({ $forDesktop }) => $forDesktop && 'block'};
    }
  `,
  NavList: styled.ul`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
    ${maxWidthPartial({ withPaddingXL: true })}

    @media ${media.greaterThan('lg')} {
      align-items: center;
      flex-direction: row;
      height: 33px;
      justify-content: flex-end;
      list-style: none;
    }
  `,
  NavItem: styled.li`
    a {
      color: ${colours.BLACK};
      display: flex;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }

      @media ${media.greaterThan('lg')} {
        align-items: center;
        justify-content: center;
      }
    }
  `,
  Label: styled.span`
    ${TypographyStyles.Body.Small.Regular}
    padding: 0 ${spacing.XXS};

    @media ${media.greaterThan('lg')} {
      ${TypographyStyles.Body.Tiny.Regular}
      padding: 0 0 0 ${spacing.XXS};
    }
  `,
  LogoutBtn: styled.button`
    align-items: center;
    background-color: inherit;
    border: none;
    color: ${colours.BLACK};
    display: flex;
    margin: 0;
    padding: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `,
};

function NavLink({ link, label, icon }: { link: Link; label: string; icon: Image }) {
  const onClick = () => {
    sendNavigationEvent({
      component_type: 'link ribbon',
      link_text: label,
    });
  };
  return (
    <LinkPartial onClick={onClick} {...link} ariaLabel={label}>
      <>
        <ImagePartial
          layout="intrinsic"
          altText={icon?.altText}
          src={icon?.image?.name || ''}
          assetType={icon.assetType}
          height={16}
          width={16}
          objectFit="contain"
          defaultHost={icon.image?.defaultHost}
          useOriginalSize={icon.useOriginalSize}
        />
        <S.Label>{label}</S.Label>
      </>
    </LinkPartial>
  );
}
function NavLinks({
  subNavComponent,
  forDesktop,
}: {
  subNavComponent: SubNavProps['subNavComponent'];
  forDesktop: boolean;
}) {
  const { t } = useTranslation(['app-orders-common', 'lib-global-common']);
  const { isUserLoggedIn, isUserLoggedInLoading } = useUserContext();
  const router = useRouter();
  const { logoutUser } = useLogout();

  const handleLogout = async () => {
    await logoutUser();
  };

  const handleLoginRegister = () => {
    sessionStorage.setItem('lastLocation', router.asPath);
    router.push('/login');
  };

  return (
    <>
      <Separator isVisible={!forDesktop} />

      <S.Nav $forDesktop={forDesktop} role="complementary" aria-label="Top Header">
        <S.NavList>
          {!isUserLoggedInLoading &&
            (isUserLoggedIn ? (
              <S.NavItem key={t('log.out', { ns: 'lib-global-common' })} onClick={handleLogout}>
                <S.LogoutBtn type="button" data-testid="logoutButton">
                  <LoginImage isVisible={!forDesktop} />
                  <S.Label>{t('log.out', { ns: 'lib-global-common' })}</S.Label>
                </S.LogoutBtn>
              </S.NavItem>
            ) : (
              <S.NavItem key={t('login.register', { ns: 'lib-global-common' })} onClick={handleLoginRegister}>
                <LinkPartial internalLink="login" data-testid="headerLoginLink">
                  <LoginImage isVisible={!forDesktop} />

                  <S.Label>{t('login.register', { ns: 'lib-global-common' })}</S.Label>
                </LinkPartial>
              </S.NavItem>
            ))}

          {subNavComponent?.map((subNav) => {
            const label = getLocalizedValue(subNav.label);
            return (
              <S.NavItem key={label} data-testid="navBarItem">
                <NavLink icon={subNav.icon} link={subNav.link} label={label} />
              </S.NavItem>
            );
          })}
        </S.NavList>
      </S.Nav>
    </>
  );
}
export function SubNav({ subNavComponent, forDesktop }: SubNavProps): ReactElement {
  return <NavLinks forDesktop={!!forDesktop} subNavComponent={subNavComponent} />;
}

function LoginImage({ isVisible }: { isVisible: boolean }) {
  if (!isVisible) return null;
  return <ImagePartial src="Account" width={16} height={16} assetType="static" objectFit="contain" alt="account" />;
}
