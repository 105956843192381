import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useCartContext } from '../../../context/cartContext';
import { useLocaleContext } from '../../../context/localeContext';
import { CheckCircle } from '../../../icons';
import { TypographyStyles, colours, spacing } from '../../../stylings';
import { Locale } from '../../../utils/localeHelper';

const S = {
  AddToBagConfirmationMessageContainer: styled.div`
    align-items: center;
    background-color: ${colours.SUCCESS_SECONDARY};
    display: flex;
    height: 44px;
    justify-content: center;
    margin-top: ${spacing.XS};

    svg {
      stroke: ${colours.BLACK};
    }
  `,
  AddToBagConfirmationMessage: styled.span`
    margin-left: ${spacing.XXXS};
    ${TypographyStyles.Body.Small.SemiBold}
  `,
  StockReservedMessage: styled.span`
    ${TypographyStyles.Body.Tiny.Regular}
    background-color: ${colours.SUCCESS_SECONDARY};
    display: block;
    padding-bottom: ${spacing.XXXS};
    text-align: center;
  `,
};

export function ATBConfirmationMessage() {
  const { t } = useTranslation(['lib-global-common']);
  const { locale } = useLocaleContext();
  const { showConfirmationMessage, isCartEmpty } = useCartContext();

  const isOutlet = locale.toLowerCase() === Locale.STOREFRONT_OUTLET_LOCALE.toLowerCase();

  if (!showConfirmationMessage || isCartEmpty) return null;

  return (
    <>
      <S.AddToBagConfirmationMessageContainer role="status">
        <CheckCircle />
        <S.AddToBagConfirmationMessage data-testid="addToBagConfirmationMessage">
          {t('cart.added.to.bag')}
        </S.AddToBagConfirmationMessage>
      </S.AddToBagConfirmationMessageContainer>
      {isOutlet && <S.StockReservedMessage>{t('cart.stock.reserved')}</S.StockReservedMessage>}
    </>
  );
}
