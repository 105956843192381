import type { QuerySizeCalculatorArgs, SizeWithCategory } from '../../codegen/types';
import { SizeCalculatorDocument } from '../../graphqlDocument/sizeCalculator/calculator.generated';
import type {
  SizeCalculatorQuery,
  SizeCalculatorQueryVariables,
} from '../../graphqlDocument/sizeCalculator/calculator.generated';
import { ONE_MINUTE, queryCacheEvictor } from '../../utils/checkCache';
import type { ApolloClientInstance } from './apolloClient';

type ReturnValue = { data?: SizeWithCategory; error?: Error };

export const getSizeCalculator = async (
  apolloClient: ApolloClientInstance,
  { input }: QuerySizeCalculatorArgs,
): Promise<ReturnValue> => {
  try {
    const {
      data: { sizeCalculator },
    } = await apolloClient.query<SizeCalculatorQuery, SizeCalculatorQueryVariables>({
      query: SizeCalculatorDocument,
      variables: {
        input,
      },
    });

    queryCacheEvictor({ isField: false, storeObject: sizeCalculator }, ONE_MINUTE * 10, apolloClient);

    return {
      data: sizeCalculator,
    } as { data?: SizeWithCategory };
  } catch (e) {
    return {
      error: e as Error,
      data: undefined,
    };
  }
};
