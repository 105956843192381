import type { ReactElement } from 'react';
import BottomSpacingSchema from '@amplience/content-types/src/content-schemas/c-bottom-spacing.json';
import CategoryFeaturedCardSchema from '@amplience/content-types/src/content-schemas/c-category-featured-card.json';
import EmptyBasketSchema from '@amplience/content-types/src/content-schemas/c-empty-basket.json';
import FooterAccordionSchema from '@amplience/content-types/src/content-schemas/c-footer-accordion.json';
import FooterIconsBlockSchema from '@amplience/content-types/src/content-schemas/c-footer-icons-block.json';
import FooterKeyLinksSchema from '@amplience/content-types/src/content-schemas/c-footer-key-links.json';
import MainLinksColumnSchema from '@amplience/content-types/src/content-schemas/c-footer-main-links-column.json';
import NewsletterSignUpSchema from '@amplience/content-types/src/content-schemas/c-footer-newsletter-sign-up.json';
import FooterSchema from '@amplience/content-types/src/content-schemas/c-footer.json';
import HeaderSchema from '@amplience/content-types/src/content-schemas/c-header.json';
import MarketingMessageSchema from '@amplience/content-types/src/content-schemas/c-marketing-message.json';
import MediaGridCarouselSchema from '@amplience/content-types/src/content-schemas/c-media-grid-carousel.json';
import NavigationBarSchema from '@amplience/content-types/src/content-schemas/c-navigation-bar.json';
import NeedHelpSectionCheckoutSchema from '@amplience/content-types/src/content-schemas/c-need-help-section-checkout.json';
import NotificationBarSchema from '@amplience/content-types/src/content-schemas/c-notification-bar.json';
import PaymentMethodsSchema from '@amplience/content-types/src/content-schemas/c-payment-methods.json';
import PriceTooltipSchema from '@amplience/content-types/src/content-schemas/c-price-tooltip.json';
import ProductCarouselSchema from '@amplience/content-types/src/content-schemas/c-product-carousel.json';
import PromotionalLinksNavSchema from '@amplience/content-types/src/content-schemas/c-promotional-links-nav.json';
import RelatedProductsCarouselSchema from '@amplience/content-types/src/content-schemas/c-related-products-carousel.json';
import ScrollToTopSchema from '@amplience/content-types/src/content-schemas/c-scroll-to-top.json';
import ShippingSectionSchema from '@amplience/content-types/src/content-schemas/c-shipping-section.json';
import SimpleAccordionsSchema from '@amplience/content-types/src/content-schemas/c-simple-accordions.json';
import SiteSwitcherSchema from '@amplience/content-types/src/content-schemas/c-site-switcher.json';
import SizeCalculatorSchema from '@amplience/content-types/src/content-schemas/c-size-calculator.json';
import SmallFooterLinksSchema from '@amplience/content-types/src/content-schemas/c-small-footer-links.json';
import SubNavSchema from '@amplience/content-types/src/content-schemas/c-sub-nav.json';
import TrendingCarouselSchema from '@amplience/content-types/src/content-schemas/c-trending-carousel.json';
import HeaderNavigationSchema from '@amplience/content-types/src/content-schemas/h-header-navigation.json';
import MegamenuLayoutSchema from '@amplience/content-types/src/content-schemas/h-megamenu-layout.json';
import { MediaCarousel } from './carousel/MediaCarousel';
import { TrendingCarousel } from './carousel/TrendingCarousel';
import { ProductCarousel } from './carousel/productCarousel/ProductCarousel';
import { RelatedProductsCarousel } from './carousel/relatedProductsCarousel/RelatedProductsCarousel';
import { EmptyBasket } from './cart/emptyBasket/EmptyBasket';
import { NeedHelpSectionCheckout } from './cart/needHelp/NeedHelpSectionCheckout';
import { FooterComponent } from './footer/Footer';
import { FooterAccordion } from './footer/FooterAccordion';
import { FooterKeyLinks } from './footer/FooterKeyLinks';
import { MainLinksColumn } from './footer/MainLinksColumn';
import { NewsletterSignUp } from './footer/NewsletterSignUp';
import { SiteSwitcher } from './footer/SiteSwitcher';
import { SmallFooterLinks } from './footer/SmallFooterLinks';
import { FooterIconsBlock } from './footer/footerIconsBlock/FooterIconsBlock';
import { ScrollToTop } from './footer/scrollToTop/ScrollToTop';
import { HandlebarTemplate } from './handlebarTemplate/HandlebarTemplate';
import { HeaderComponent } from './header/Header';
import { MarketingMessage } from './header/MarketingMessage';
import { NavigationBar } from './header/NavigationBar';
import { NotificationBar } from './header/NotificationBar';
import { PromotionalLinksNav } from './header/PromotionalLinksNav';
import { SubNav } from './header/SubNav';
import { CategoryFeaturedCard } from './navigation/CategoryFeaturedCard';
import { Megamenu } from './navigation/MegaMenu';
import { HeaderNavigation } from './navigation/headerNavigation/HeaderNavigation';
import { AmpliencePaymentMethods } from './paymentMethods/AmpliencePaymentMethods';
import { ShippingSection } from './shippingSection';
import { SimpleAccordions } from './simpleAccordions';
import { SizeCalculator } from './sizeCalculator';
import { Spacer } from './spacer';
import { PriceTooltip } from './tooltip/PriceTooltip';

export const GlobalAmplienceComponents: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: (props: any) => ReactElement | null;
} = {
  [HeaderSchema.$id]: HeaderComponent,
  [MarketingMessageSchema.$id]: MarketingMessage,
  [NavigationBarSchema.$id]: NavigationBar,
  [NotificationBarSchema.$id]: NotificationBar,
  [PromotionalLinksNavSchema.$id]: PromotionalLinksNav,
  [SubNavSchema.$id]: SubNav,
  [ScrollToTopSchema.$id]: ScrollToTop,
  [SmallFooterLinksSchema.$id]: SmallFooterLinks,
  [FooterIconsBlockSchema.$id]: FooterIconsBlock,
  [NewsletterSignUpSchema.$id]: NewsletterSignUp,
  [FooterAccordionSchema.$id]: FooterAccordion,
  [CategoryFeaturedCardSchema.$id]: CategoryFeaturedCard,
  [MainLinksColumnSchema.$id]: MainLinksColumn,
  [SiteSwitcherSchema.$id]: SiteSwitcher,
  [FooterSchema.$id]: FooterComponent,
  [MegamenuLayoutSchema.$id]: Megamenu,
  [HeaderNavigationSchema.$id]: HeaderNavigation,
  [PaymentMethodsSchema.$id]: AmpliencePaymentMethods,
  [MediaGridCarouselSchema.$id]: MediaCarousel,
  [EmptyBasketSchema.$id]: EmptyBasket,
  [PriceTooltipSchema.$id]: PriceTooltip,
  [ProductCarouselSchema.$id]: ProductCarousel,
  [FooterKeyLinksSchema.$id]: FooterKeyLinks,
  [TrendingCarouselSchema.$id]: TrendingCarousel,
  [SizeCalculatorSchema.$id]: SizeCalculator,
  [ShippingSectionSchema.$id]: ShippingSection,
  [RelatedProductsCarouselSchema.$id]: RelatedProductsCarousel,
  [SimpleAccordionsSchema.$id]: SimpleAccordions,
  [BottomSpacingSchema.$id]: Spacer,
  [NeedHelpSectionCheckoutSchema.$id]: NeedHelpSectionCheckout,
  'handlebar-component': HandlebarTemplate,
};
