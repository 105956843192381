import type { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { WhiteButton } from '../../../baseComponents/button';
import { TypographyStyles, colours, spacing } from '../../../stylings';

const S = {
  ContinueShoppingButton: styled(WhiteButton)`
    ${TypographyStyles.Button.SemiBold}
    align-items: center;
    background-color: ${colours.WHITE};
    border: 1px solid ${colours.DARK_BLUE};
    color: ${colours.DARK_BLUE};
    display: flex;
    gap: ${spacing.XXS};
    height: 48px;
    justify-content: center;
    max-width: 328px;
    text-transform: uppercase;
    width: 100%;
  `,
};

export function ContinueShoppingButton({ onClick }: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { t } = useTranslation(['lib-global-common']);
  return (
    <S.ContinueShoppingButton onClick={onClick} data-testid="continueShoppingButton">
      <span>{t('cart.continue.shopping')}</span>
    </S.ContinueShoppingButton>
  );
}
