import type { MouseEvent } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import type { MediaGridCarousel3 as MediaGridItemSchema } from '@amplience/content-types/typings/c-media-grid-carousel';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import type { MediaPartialProps } from '../../partials';
import { useColor } from '../../partials/color';
import { FlexibleTextPartial } from '../../partials/flexibleText';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, TypographyStylesType, colours, maxWidthPartial, media, spacing } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';

/* eslint-disable promise/prefer-await-to-then */
const MediaPartialComponent = dynamic(
  () => import('../../partials/media/MediaPartial').then((mod) => mod.MediaPartial),
  { ssr: false },
);

export type MediaGridItemProps = {
  mediaGridCarouselItems: MediaGridItemSchema;
  handleClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
};

const S = {
  Container: styled.div`
    ${maxWidthPartial()}
  `,
  SwiperSlideContainer: styled(SwiperSlide)`
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: space-between;
    max-width: 273px;
    width: 100%;

    @media ${media.greaterThan('lg')} {
      max-width: 400px;
    }
  `,
  MediaContainer: styled.div<{ $mediaFit?: 'cover' | 'fill' | 'contain' }>`
    height: 175px;
    position: relative;
    width: 273px;

    @media ${media.greaterThan('lg')} {
      height: 275px;
      width: 400px;
    }

    video {
      height: 100%;
      object-fit: ${({ $mediaFit }) => $mediaFit};
      width: 100%;
    }
  `,
  TagLine: styled(FlexibleTextPartial)`
    display: block;
    margin: 10px 0;

    @media ${media.greaterThan('lg')} {
      margin: ${spacing.XS} 0;
    }
  `,
  Title: styled(FlexibleTextPartial)`
    display: block;
    margin: 0 0 10px;
    text-transform: uppercase;

    @media ${media.greaterThan('lg')} {
      margin: 0 0 ${spacing.XS};
    }
  `,
  TextLink: styled(LinkPartial)<{ $mobileTextColor?: string; $desktopTextColor?: string }>`
    ${TypographyStyles.CallToAction.Large.SemiBold}
    ${({ $mobileTextColor }) => useColor($mobileTextColor)}
    display: block;
    text-decoration: none;
    width: fit-content;

    .cta-text {
      text-decoration: underline;
    }

    @media ${media.greaterThan('lg')} {
      font-size: ${spacing.XS};
      ${({ $desktopTextColor, $mobileTextColor }) => useColor($desktopTextColor, $mobileTextColor)}
    }
  `,
};

export function MediaGridItem({ mediaGridCarouselItems, handleClick }: MediaGridItemProps) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { t } = useTranslation(['lib-products-common']);

  return (
    <>
      {mediaGridCarouselItems.map(({ mediaItem, date, title, ctaText, link }, i) => (
        <S.SwiperSlideContainer key={`${title}-${i}`}>
          <S.TextLink
            {...link}
            onClick={handleClick}
            ariaLabel={`${getLocalizedValue(ctaText?.text) || t('read.the.article')}${
              title?.text ? `, ${title.text}` : ''
            }`}
            $mobileTextColor={ctaText?.textColor ?? colours.BLACK}
            $desktopTextColor={ctaText?.desktopTextColor ?? colours.BLACK}
          >
            <S.MediaContainer $mediaFit={mediaItem?.media?.mediaFit}>
              <MediaPartialComponent
                {...(mediaItem?.media as MediaPartialProps)}
                width={isDesktop ? 410 : 272}
                height={isDesktop ? 273 : 176}
                isImageLayoutFill
                videoQuality={mediaItem?.media?.videoQuality}
                mediaFit={mediaItem?.media?.mediaFit}
                altText={mediaItem?.media?.altText}
                isMediaFocusable={false}
              />
            </S.MediaContainer>
            <div>
              <S.TagLine
                {...date}
                text={date?.text}
                defaultTextColor={colours.BLACK}
                defaultTextSize={TypographyStyles.Body.Small.Regular}
              />
              <S.Title
                {...title}
                text={title?.text}
                defaultTextSize={TypographyStylesType.HEADINGS_H4_SEMIBOLD}
                defaultTextColor={colours.BLACK}
              />
              <FlexibleTextPartial
                {...ctaText}
                text={ctaText?.text || t('read.the.article')}
                defaultTextColor={colours.BLACK}
                defaultTextSize={TypographyStyles.CallToAction.Large.SemiBold}
                className="cta-text"
              />
            </div>
          </S.TextLink>
        </S.SwiperSlideContainer>
      ))}
    </>
  );
}

MediaGridItem.displayName = 'SwiperSlide';
