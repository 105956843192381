import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import type { CategoryFeaturedCard as CategoryFeaturedCardSchema } from '@amplience/content-types/typings/c-category-featured-card';
import type { MobileOnlyFeaturedCardsComponent } from '@amplience/content-types/typings/c-navigation-bar';
import type { PromotionalLinksNav as PromotionalLinksNavProps } from '@amplience/content-types/typings/c-promotional-links-nav';
import type { SubNav as SubNavProps } from '@amplience/content-types/typings/c-sub-nav';
import { useExitAnimation } from '../../hooks/useExitAnimation';
import { ArrowLeft, ArrowRight, Close } from '../../icons';
import { LinkPartial } from '../../partials/link';
import {
  TypographyStyles,
  colours,
  fonts,
  hoverUp,
  media,
  slideToLeftHamburgerMenuTraysAnimation,
  spacing,
  zMobileHamburgerMenu,
  zMobileHamburgerMenuContent,
  zSubMenuTopContainer,
} from '../../stylings';
import type {
  Column,
  HeaderNavigationLevel2,
  HeaderNavigationLevel3Content,
  NavigationHierarchyData,
  NavigationHierarchyData1,
  NavigationList1,
} from '../../types/navigationHierarchyData';
import { sendNavigationEvent } from '../../utils/gtm/events/navigation/sendNavigationEvent';
import { FocusTrap } from '../focusTrap';
import { CategoryFeaturedCard } from '../navigation/CategoryFeaturedCard';
import { PromotionalLinksNav } from './PromotionalLinksNav';
import { SubNav } from './SubNav';

const S = {
  MenuContainer: styled.div`
    display: flex;
    height: 100%;
    justify-content: flex-end;
    overflow-x: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: ${zMobileHamburgerMenu};

    @media ${media.greaterThan('lg')} {
      display: none;
    }
  `,
  MenuContainerBackground: styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    height: 100%;
    right: 0;
    top: 0;
    width: 100%;
  `,
  MenuContent: styled.section`
    background-color: ${colours.WHITE};
    display: flex;
    flex-direction: column;
    gap: ${spacing.M};
    height: 100%;
    max-width: 310px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0 ${spacing.S};
    position: fixed;
    right: 0;
    top: 0;
    width: 85%;
    z-index: ${zMobileHamburgerMenuContent};
    ${slideToLeftHamburgerMenuTraysAnimation}

    a {
      color: ${colours.BLACK};
      text-decoration: none;
    }
  `,
  CloseMenuButton: styled.button`
    align-items: flex-start;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: left;
    padding: 0px;
    width: 20px;

    ${hoverUp()}

    svg {
      color: ${colours.WHITE};
      transform: scale(0.5);
    }
  `,
  CloseMenuContainer: styled.div`
    display: flex;
    justify-content: flex-end;
    margin: ${spacing.L} 0 0;
  `,
  PromotionalLinksNavContainer: styled.div`
    display: flex;
    flex-direction: column;
    > div {
      ul {
        justify-content: left;
        padding: 0;
      }
    }
  `,
  FeatureCardsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.M};
    justify-content: left;
    padding: 0;
  `,
  SubNavContainer: styled.div`
    background: ${colours.WHITE};
    bottom: 0;

    position: sticky;
    width: 100%;
    > nav {
      max-width: 230px;
      padding: ${spacing.XS} 0;

      > ul {
        padding: 0;
      }
    }
  `,
  MainMenu: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  `,
  MenuItem: styled.button`
    align-items: center;
    background: ${colours.WHITE};
    border: none;
    color: ${colours.BLACK};
    display: flex;
    font: inherit;
    justify-content: space-between;
    padding: 0;
    text-transform: uppercase;
    width: 100%;

    > span {
      ${TypographyStyles.Label.Large.Medium}
      font-size: 16px;
    }
  `,
  MainMenuItem: styled.li<{ $isDisabled?: boolean }>`
    opacity: ${({ $isDisabled }) => $isDisabled && 0.5};
    pointer-events: ${({ $isDisabled }) => $isDisabled && 'none'};

    a {
      align-items: center;
      background: ${colours.WHITE};
      border: none;
      color: ${colours.BLACK};
      display: flex;
      font: inherit;
      justify-content: space-between;
      padding: 0;
      text-transform: uppercase;
      width: 100%;

      > span {
        ${TypographyStyles.Label.Large.Medium}
        font-size: 16px;
      }
    }
  `,
  SubMenuTopContainer: styled.div`
    align-items: center;
    background: ${colours.WHITE};
    display: flex;
    justify-content: space-between;
    margin: ${spacing.L} 0 0;
    position: sticky;
    top: 0;
    z-index: ${zSubMenuTopContainer};
  `,
  SubMenuMidContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XS};
  `,
  SubMenuBackButton: styled.button`
    align-items: center;
    background: ${colours.WHITE};
    border: none;
    color: ${colours.BLACK};
    display: flex;
    font: inherit;
    gap: 5px;
    padding: 0px;
    position: sticky;

    > span {
      ${TypographyStyles.CallToAction.Tiny.SemiBold}
      font-size: 16px;
      text-decoration: underline;
    }
  `,
  SubMenuHeading: styled.span`
    text-transform: uppercase;
    ${TypographyStyles.Headings.H4SemiBold}
  `,
  SubMenuList: styled.ul`
    margin: 0;
    padding: 0;
  `,
  SubMenuItem: styled.button`
    align-items: center;
    background: ${colours.WHITE};
    border: none;
    color: ${colours.BLACK};
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    margin: 0 0 ${spacing.XS};
    min-height: ${spacing.S};
    padding: 0;
    text-align: left;
    width: 100%;
    ${fonts.Montserrat}
  `,
  SubMenuProductTypeItem: styled.span`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.XS};
    min-height: ${spacing.S};
    ${TypographyStyles.Body.Small.SemiBold}
  `,
  SubMenuFeaturedCards: styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.M};
    justify-content: center;
    padding: 0;
  `,
  AllLink: styled.span`
    a {
      ${fonts.Montserrat}
      font-size: 14px;
      padding: 3px 0;
    }
  `,
};

export type MobileHamburgerMenuProps = {
  mobileOnlyFeaturedCards?: MobileOnlyFeaturedCardsComponent;
  setMobileMenuState: Dispatch<SetStateAction<boolean>>;
  subNavData?: SubNavProps;
  promotionalLinksNavData?: PromotionalLinksNavProps;
  hierarchyData: NavigationHierarchyData;
};

type SubMenuLevelProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  activeMenu: MegaMenuLevel;
  goToMenu: (submenu?: HeaderNavigationLevel2) => () => void;
  backButtonText: string | NavigationHierarchyData1;
  closeMenu: () => void;
  exitMenu: () => void;
  setMobileMenuState: Dispatch<SetStateAction<boolean>>;
};

type MegaMenu = {
  firstLevel: boolean;
  secondLevel: boolean;
  thirdLevel: boolean;
};

enum MegaMenuLevel {
  FIRST_LEVEL = 1,
  SECOND_LEVEL,
  THIRD_LEVEL,
}

function SubMenuFeaturedCards({
  data,
  tertiaryLevel,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  tertiaryLevel?: boolean;
}) {
  if (!data.navigationHierarchyDataSubLevel) return null;
  if (tertiaryLevel) {
    return (
      data.categoryLevelFeaturedCards?.length && (
        <S.SubMenuFeaturedCards>
          {data?.categoryLevelFeaturedCards.map(
            (categoryLevelFeaturedCard: CategoryFeaturedCardSchema, idx: number) => (
              <CategoryFeaturedCard {...categoryLevelFeaturedCard} key={`category-level-${idx}`} />
            ),
          )}
        </S.SubMenuFeaturedCards>
      )
    );
  }

  const subMenuFeaturedCards =
    data && data.navigationHierarchyDataSubLevel[0].layoutContent
      ? data.navigationHierarchyDataSubLevel[0].layoutContent.filter(({ featuredCards }: Column) => featuredCards)
      : [];

  return subMenuFeaturedCards?.length ? (
    <S.SubMenuFeaturedCards>
      {subMenuFeaturedCards.map(
        (featuredCardData: Column, idx: number) =>
          featuredCardData.featuredCards && (
            <CategoryFeaturedCard {...featuredCardData.featuredCards.linkedContentChildData} key={idx} />
          ),
      )}
    </S.SubMenuFeaturedCards>
  ) : null;
}

function SubMenuLevel({
  data,
  activeMenu,
  goToMenu,
  backButtonText,
  closeMenu,
  exitMenu,
  setMobileMenuState,
}: SubMenuLevelProps) {
  const { allLink, link, text, linkedContentChildData: productTypes } = data.content || data;
  const { t } = useTranslation(['lib-global-common']);

  const subCategories =
    data && data.navigationHierarchyDataSubLevel
      ? data.navigationHierarchyDataSubLevel[0].layoutContent
          ?.filter(({ navigationList }: Column) => navigationList)
          .map(({ navigationList }: Column) => navigationList?.filter(({ category }: NavigationList1) => category))
          .flat()
      : [];

  const handleClick = (linkText: string) => () => {
    sendNavigationEvent({
      component_header: text,
      component_type: 'main nav - text link',
      link_text: linkText,
    });
    setMobileMenuState(false);
  };

  return (
    <>
      <S.SubMenuTopContainer>
        <S.SubMenuBackButton aria-label={t('hamburger.submenu.back')} type="button" onClick={closeMenu}>
          <ArrowRight aria-hidden />
          <span>{typeof backButtonText === 'string' ? `${backButtonText}` : backButtonText.content.text}</span>
        </S.SubMenuBackButton>
        <S.CloseMenuButton aria-label={t('hamburger.menu.close')} type="button" onClick={exitMenu}>
          <Close aria-hidden />
        </S.CloseMenuButton>
      </S.SubMenuTopContainer>
      <S.SubMenuMidContainer>
        {text && (
          <LinkPartial ariaLabel={text} onClick={handleClick(text)} {...link}>
            <S.SubMenuHeading>{text}</S.SubMenuHeading>
          </LinkPartial>
        )}

        {allLink && (
          <S.AllLink>
            <LinkPartial ariaLabel={allLink.text} onClick={handleClick(allLink.text)} {...allLink.link}>
              {allLink.text}
            </LinkPartial>
          </S.AllLink>
        )}
        {subCategories?.length > 0 && (
          <S.SubMenuList>
            {subCategories.map((subCategoryData: NavigationList1, idx: number) => {
              const { linkedContentChildData: subCategory } = subCategoryData.category || {};
              return subCategory?.text ? (
                <li key={idx}>
                  <S.SubMenuItem onClick={goToMenu(subCategory)} type="button" disabled={!subCategory}>
                    {subCategory?.text}
                    <ArrowLeft />
                  </S.SubMenuItem>
                </li>
              ) : null;
            })}
          </S.SubMenuList>
        )}
        {activeMenu === MegaMenuLevel.THIRD_LEVEL && productTypes && (
          <S.SubMenuList>
            {productTypes.map((productType: HeaderNavigationLevel3Content, idx: number) => {
              const { text: productTypeText, link: productTypeLink } = productType.content || {};
              return (
                <li key={idx}>
                  <LinkPartial
                    ariaLabel={productTypeText}
                    onClick={handleClick(productTypeText as string)}
                    {...productTypeLink}
                  >
                    <S.SubMenuProductTypeItem>{productTypeText}</S.SubMenuProductTypeItem>
                  </LinkPartial>
                </li>
              );
            })}
          </S.SubMenuList>
        )}
      </S.SubMenuMidContainer>
    </>
  );
}

export function MobileHamburgerMenu({
  mobileOnlyFeaturedCards,
  setMobileMenuState,
  subNavData,
  promotionalLinksNavData,
  hierarchyData,
}: MobileHamburgerMenuProps) {
  const [activeMenu, setActiveMenu] = useState(MegaMenuLevel.FIRST_LEVEL);
  const [secondaryData, setSecondaryData] = useState({ content: { text: '' } } as unknown as NavigationHierarchyData1);
  const [tertiaryData, setTertiaryData] = useState({});
  const [activeMegaMenu, setActiveMegaMenu] = useState<MegaMenu>({
    firstLevel: true,
    secondLevel: false,
    thirdLevel: false,
  });

  const { t } = useTranslation(['lib-global-common']);

  const closeMenu = useCallback(() => {
    setActiveMenu(MegaMenuLevel.FIRST_LEVEL);
    setMobileMenuState(false);
  }, [setMobileMenuState]);

  const exitMenu = () => {
    setMobileMenuState(false);
  };

  const toggleBack = () => {
    const menuContent = document.getElementById('menu-content');
    if (menuContent) menuContent.scrollTop = 0;
    setActiveMenu(activeMenu - 1);
    if (activeMenu === MegaMenuLevel.SECOND_LEVEL) {
      setActiveMegaMenu({ ...activeMegaMenu, secondLevel: false });
    } else if (activeMenu === MegaMenuLevel.THIRD_LEVEL) {
      setActiveMegaMenu({ ...activeMegaMenu, thirdLevel: false });
    }
  };

  const handleCloseMenuAndToggleBack = () => {
    if (activeMenu === MegaMenuLevel.SECOND_LEVEL || activeMenu === MegaMenuLevel.THIRD_LEVEL) {
      toggleBack();
    } else {
      closeMenu();
    }
  };

  const { handleExitAnimation: handleReturnAnimation, isClosing: isReturning } = useExitAnimation({
    callbackFunction: handleCloseMenuAndToggleBack,
  });
  const { handleExitAnimation, isClosing } = useExitAnimation({
    callbackFunction: exitMenu,
  });

  const goToMenu = (subMenuData?: NavigationHierarchyData1 | HeaderNavigationLevel2) => () => {
    const menuContent = document.querySelector('[data-menu-content]');
    if (menuContent) menuContent.scrollTop = 0;
    setActiveMenu(activeMenu + 1);
    if (activeMenu === MegaMenuLevel.FIRST_LEVEL) {
      setSecondaryData(subMenuData as unknown as NavigationHierarchyData1);
      setActiveMegaMenu({ ...activeMegaMenu, secondLevel: true });
    } else if (activeMenu === MegaMenuLevel.SECOND_LEVEL) {
      setActiveMegaMenu({ ...activeMegaMenu, thirdLevel: true });
      setTertiaryData(subMenuData as unknown as HeaderNavigationLevel2);
    }
  };
  const isClosingState = isReturning ? 'is-closing' : '';
  const isExitingSate = isClosing ? 'is-closing' : '';

  return (
    <S.MenuContainer className={isExitingSate}>
      <S.MenuContainerBackground onClick={handleExitAnimation} />
      <FocusTrap disabled={activeMenu !== MegaMenuLevel.FIRST_LEVEL}>
        <S.MenuContent
          data-menu-content
          className={`${activeMenu === MegaMenuLevel.FIRST_LEVEL ? isClosingState : ''}`}
        >
          <S.CloseMenuContainer>
            <S.CloseMenuButton aria-label={t('hamburger.menu.close')} type="button" onClick={handleReturnAnimation}>
              <Close aria-hidden />
            </S.CloseMenuButton>
          </S.CloseMenuContainer>
          <S.MainMenu>
            {hierarchyData?.map((category: NavigationHierarchyData1, idx: number) => (
              <S.MainMenuItem
                key={`menu-item-${idx}`}
                $isDisabled={category.navigationHierarchyDataSubLevel.length === 0 && !category.content.link}
              >
                {category && category.navigationHierarchyDataSubLevel.length >= 1 ? (
                  <S.MenuItem
                    onClick={goToMenu(category)}
                    type="button"
                    disabled={category.navigationHierarchyDataSubLevel.length === 0}
                  >
                    <span>{category?.content.text}</span>
                    <ArrowLeft aria-hidden />
                  </S.MenuItem>
                ) : (
                  <LinkPartial {...category.content.link} role="menuitem">
                    <span>{category?.content.text}</span>
                    <ArrowLeft aria-hidden />
                  </LinkPartial>
                )}
              </S.MainMenuItem>
            ))}
          </S.MainMenu>
          {promotionalLinksNavData && (
            <S.PromotionalLinksNavContainer>
              <PromotionalLinksNav {...(promotionalLinksNavData as PromotionalLinksNavProps)} />
            </S.PromotionalLinksNavContainer>
          )}
          {mobileOnlyFeaturedCards && (
            <S.FeatureCardsContainer>
              {mobileOnlyFeaturedCards &&
                mobileOnlyFeaturedCards?.map((card, index) => <CategoryFeaturedCard {...card} key={index} />)}
            </S.FeatureCardsContainer>
          )}
          {subNavData && (
            <S.SubNavContainer>
              <SubNav {...(subNavData as SubNavProps)} />
            </S.SubNavContainer>
          )}
        </S.MenuContent>
      </FocusTrap>

      {activeMegaMenu.secondLevel && (
        <FocusTrap disabled={activeMenu !== MegaMenuLevel.SECOND_LEVEL}>
          <S.MenuContent
            data-menu-content
            className={`${activeMenu === MegaMenuLevel.SECOND_LEVEL ? isClosingState : ''}`}
          >
            <SubMenuLevel
              activeMenu={activeMenu}
              data={secondaryData}
              goToMenu={goToMenu}
              backButtonText={`${t('navigation.mobile.menu.all')}`}
              closeMenu={handleReturnAnimation}
              exitMenu={handleExitAnimation}
              setMobileMenuState={setMobileMenuState}
            />
            <SubMenuFeaturedCards data={secondaryData} />
            {subNavData && (
              <S.SubNavContainer>
                <SubNav {...(subNavData as SubNavProps)} />
              </S.SubNavContainer>
            )}
          </S.MenuContent>
        </FocusTrap>
      )}

      {activeMegaMenu.thirdLevel && (
        <FocusTrap disabled={activeMenu !== MegaMenuLevel.THIRD_LEVEL}>
          <S.MenuContent
            data-menu-content
            className={`${activeMenu === MegaMenuLevel.THIRD_LEVEL ? isClosingState : ''}`}
          >
            <SubMenuLevel
              activeMenu={activeMenu}
              data={tertiaryData}
              goToMenu={goToMenu}
              backButtonText={secondaryData}
              closeMenu={handleReturnAnimation}
              setMobileMenuState={setMobileMenuState}
              exitMenu={handleExitAnimation}
            />
            <SubMenuFeaturedCards data={tertiaryData} tertiaryLevel />
            {subNavData && (
              <S.SubNavContainer>
                <SubNav {...(subNavData as SubNavProps)} />
              </S.SubNavContainer>
            )}
          </S.MenuContent>
        </FocusTrap>
      )}
    </S.MenuContainer>
  );
}
