import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { BlueButton, WhiteButton } from '../../baseComponents/button';
import { CheckCircle } from '../../icons';
import { colours } from '../../stylings';
import { DynamicMessage } from './DynamicMessage';

const S = {
  StyledCheck: styled(CheckCircle)`
    path {
      fill: ${colours.SUCCESS_PRIMARY};
    }
  `,
  Login: styled(BlueButton)`
    margin-bottom: 0;
  `,
};

export function ConfirmedMessage() {
  const router = useRouter();
  const { t } = useTranslation(['lib-global-common']);
  const handleOnClick = (pathname: string) => () => {
    router.push(
      {
        pathname,
      },
      undefined,
      {
        locale: router.locale,
      },
    );
  };

  return (
    <DynamicMessage
      testId="welcomeMessage"
      icon={<S.StyledCheck />}
      heading={t('register.welcome.message')}
      subText={t('register.short.info')}
    >
      <S.Login
        type="button"
        aria-label={t('register.login')}
        onClick={handleOnClick('/login')}
        data-testid="myAccountButton"
      >
        {t('register.login')}
      </S.Login>
      <WhiteButton
        type="button"
        aria-label={t('register.continue.shopping')}
        onClick={handleOnClick('/')}
        data-testid="continueShoppingButton"
      >
        {t('register.continue.shopping')}
      </WhiteButton>
    </DynamicMessage>
  );
}
export default ConfirmedMessage;
