import type { ReactElement } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import type { Image } from '@amplience/content-types/typings/c-header';
import type { MarketingMessage as MarketingMessageProps } from '@amplience/content-types/typings/c-marketing-message';
import type { NavigationBar as NavigationBarProps } from '@amplience/content-types/typings/c-navigation-bar';
import type { NotificationBar as NotificationBarProps } from '@amplience/content-types/typings/c-notification-bar';
import type { PromotionalLinksNav as PromotionalLinksNavProps } from '@amplience/content-types/typings/c-promotional-links-nav';
import type { SubNav as SubNavProps } from '@amplience/content-types/typings/c-sub-nav';
import type { MasterLayoutContextType } from '../../context/masterLayoutContext';
import { colours, media } from '../../stylings';
import type { AmplienceContext, AmplienceContextProps } from '../../types/amplience';
import { isBusinessComponent, renderAmplienceSlotComponents } from '../../utils/amplienceUtils';
import type { EmptyBasketProps } from '../cart/emptyBasket';
import { HandlebarTemplate, type HandlebarTemplateProps } from '../handlebarTemplate/HandlebarTemplate';
import { MarketingMessage } from './MarketingMessage';
import { NavigationBar } from './NavigationBar';
import { NotificationBar } from './NotificationBar';
import { PromotionalLinksNav } from './PromotionalLinksNav';
import { SubNav } from './SubNav';

const S = {
  Wrapper: styled.div`
    @media ${media.greaterThan('lg')} {
      border-top: 1px solid ${colours.LIGHT_GREY};
    }
  `,
};

export type HeaderProps = {
  context?: AmplienceContextProps;
  headerData: MasterLayoutContextType['masterLayoutData']['headerComponent'];
  emptyBasketComponent?: EmptyBasketProps;
  handlebarComponents: HandlebarTemplateProps[] | null;
};

export type HeaderComponentProps = {
  context?: AmplienceContext;
  marketingMessage?: MarketingMessageProps;
  navigationBar?: NavigationBarProps;
  notificationBar?: NotificationBarProps;
  promotionalLinksNav?: PromotionalLinksNavProps;
  subNav?: SubNavProps;
  emptyBasketComponent?: EmptyBasketProps;
  headerLogo?: Image;
  handlebarComponents: HandlebarTemplateProps[] | null;
};

export function HeaderComponent({
  context,
  marketingMessage,
  navigationBar,
  notificationBar,
  promotionalLinksNav,
  subNav,
  emptyBasketComponent,
  headerLogo,
  handlebarComponents,
}: HeaderComponentProps): ReactElement {
  const navigationBarData = navigationBar &&
    context && {
      ...navigationBar,
      context,
    };
  const { locale } = useRouter();

  const renderNotificationBar = () => {
    const isBusinessComponentSlot = notificationBar ? isBusinessComponent(notificationBar) : false;

    if (!notificationBar) return null;

    return isBusinessComponentSlot ? (
      renderAmplienceSlotComponents(
        [notificationBar],
        { 'handlebar-component': HandlebarTemplate },
        handlebarComponents,
        locale,
      )
    ) : (
      <NotificationBar {...notificationBar} />
    );
  };

  const renderMarketingMessage = () => {
    const isBusinessComponentSlot = marketingMessage ? isBusinessComponent(marketingMessage) : false;

    if (!marketingMessage) return null;

    return isBusinessComponentSlot ? (
      renderAmplienceSlotComponents(
        [marketingMessage],
        { 'handlebar-component': HandlebarTemplate },
        handlebarComponents,
        locale,
      )
    ) : (
      <MarketingMessage {...marketingMessage} />
    );
  };

  return (
    <header id="clarks-header">
      {renderNotificationBar()}
      {subNav && <SubNav forDesktop {...subNav} />}
      {navigationBarData && (
        <NavigationBar
          {...navigationBarData}
          subNavData={subNav}
          promotionalLinksNavData={promotionalLinksNav}
          emptyBasketComponent={emptyBasketComponent}
          headerLogo={headerLogo}
        />
      )}
      {promotionalLinksNav && (
        <S.Wrapper>
          <PromotionalLinksNav forDesktop {...promotionalLinksNav} />
        </S.Wrapper>
      )}
      {renderMarketingMessage()}
    </header>
  );
}

export function Header({ context, headerData, emptyBasketComponent, handlebarComponents }: HeaderProps): ReactElement {
  return (
    <HeaderComponent
      {...headerData}
      context={context?.context}
      emptyBasketComponent={emptyBasketComponent}
      handlebarComponents={handlebarComponents}
    />
  );
}

export default Header;
