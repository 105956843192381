import type { KeyboardEvent } from 'react';
import type { BaseHit, Hit } from 'instantsearch.js';
import type { UseInfiniteHitsProps } from 'react-instantsearch';
import { useMediaMatch } from '../../../hooks/useMediaMatch';
import { ImagePartial } from '../../../partials/image';
import { media } from '../../../stylings';
import { toSnakeCase } from '../../../utils/convertToSnakeCase';
import { sendSelectItemEvent } from '../../../utils/gtm/events/select_item/sendSelectItemEvent';
import type { ProductHitTypes } from '../../../utils/transformers';
import { ImageBadge, TextualBadge } from '../../badges';
import { ProductItemPrice } from '../../carousel/productItemPrice/ProductItemPrice';
import {
  ImageAndBadgeContainer,
  Price,
  ProductCategory,
  ProductItemContainer,
  ProductLink,
  ProductName,
} from './AlgoliaHits.styles';

export type HitProps = {
  hit: Hit<BaseHit>;
};
export type HitSearchProps = {
  hit: ProductHitTypes;
  priceRangeFeatureFlag?: boolean;
  localeProp?: string;
};
export type BaseHitProps = BaseHit;

export type AlgoliaInfiniteHits = UseInfiniteHitsProps;

export function ProductHitSearch({ hit, priceRangeFeatureFlag, localeProp }: HitSearchProps) {
  const {
    price,
    wasPrice,
    thenPrice,
    objectID,
    name,
    color,
    image,
    rangeGroupDescription,
    brand,
    primaryCategory,
    badgesV2,
  } = hit;
  const priceObject = [{ actualPrice: price, wasPrice, thenPrice }];
  const isDesktop = useMediaMatch(media.greaterThan('lg'));

  const handleOnClick = () => {
    sendSelectItemEvent(
      {
        itemListName: 'Suggested Products',
        itemListId: toSnakeCase('Suggested Products'),
        primaryCategoryKey: primaryCategory?.key ?? undefined,
        primaryCategoryName: primaryCategory?.name ?? undefined,
        key: objectID,
        name: name ?? '',
        brand,
        color: color ?? '',
      },
      priceObject,
    );
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (!(event.code === 'Enter' || event.code === 'Space')) return;
    event.preventDefault();
    const element = (event.target as HTMLElement)?.closest('li') as HTMLLIElement;
    element?.click();
  };

  return (
    <ProductItemContainer
      tabIndex={-1}
      onKeyDown={handleOnKeyDown}
      onClick={handleOnClick}
      data-testid="searchSuggestionsItem"
    >
      <ProductItemContainer>
        <ProductLink data-testid="searchSuggestionsItemLink">
          <ImageAndBadgeContainer>
            <TextualBadge badge={badgesV2?.textual} />
            <ImageBadge badge={badgesV2?.image} />

            <ImagePartial
              src={image[0]}
              width={isDesktop ? 424 : 300}
              height={isDesktop ? 424 : 300}
              data-testid="searchSuggestionsItemImage"
            />
          </ImageAndBadgeContainer>
          <ProductName title={name} data-testid="searchSuggestionsItemTitle">
            {name}
          </ProductName>
          <ProductCategory>
            <p title={color}>{color || ''}</p>
            <p title={rangeGroupDescription}>{rangeGroupDescription || ''}</p>
          </ProductCategory>
          <Price data-testid="searchSuggestionsItemPrice">
            {priceObject.map((priceItem, i) => (
              <ProductItemPrice
                price={priceItem}
                key={i}
                priceRangeFeatureFlag={priceRangeFeatureFlag}
                localeProp={localeProp}
              />
            ))}
          </Price>
        </ProductLink>
      </ProductItemContainer>
    </ProductItemContainer>
  );
}
