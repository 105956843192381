import type { KeyboardEvent, ReactElement } from 'react';
import styled from 'styled-components';
import type {
  FooterKeyLink,
  FooterKeyLinks as FooterKeyLinksSchema,
} from '@amplience/content-types/typings/c-footer-key-links';
import { ImagePartial } from '../../partials/image';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';

export type FooterKeyLinksProps = FooterKeyLinksSchema;

const S = {
  Container: styled.div`
    border-bottom: 1px solid ${colours.BLACK};
    grid-area: keyLinks;
    padding: ${spacing.M} 0;
  `,
  ImageContainer: styled.div`
    border: 1px solid ${colours.BLACK};
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    flex-shrink: 0;
    height: 44px;
    place-items: center;
    width: 44px;
  `,
  KeyLinks: styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ${spacing.S};
    margin: 0;
    padding: 0;

    @media ${media.greaterThan('lg')} {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: ${spacing.M};
    }

    @media ${media.greaterThan('xl')} {
      gap: ${spacing.XXL};
    }
  `,
  KeyLink: styled.li<{ $hide?: boolean }>`
    cursor: pointer;
    display: ${({ $hide }) => ($hide ? 'none' : 'flex')};

    & a {
      color: ${colours.BLACK};
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  `,
  KeyLinkContainer: styled.div`
    align-items: flex-start;
    display: flex;
    gap: ${spacing.S};

    &:hover,
    &:focus {
      .keylink-icon {
        background-color: ${colours.LIGHT_BLUE};
      }
    }
  `,
  KeyLinkTitle: styled.span`
    ${TypographyStyles.Body.Tiny.SemiBold}
  `,
  KeyLinkDescription: styled.p`
    ${TypographyStyles.Body.Tiny.Regular}
    margin: ${spacing.XXXXS} 0;
  `,
};

interface KeyLinkContainerProps {
  keyLink: FooterKeyLink;
}

// eslint-disable-next-line react/function-component-definition
const KeyLinkContainer: ({ keyLink }: KeyLinkContainerProps) => null | ReactElement = ({ keyLink }) => (
  <S.KeyLinkContainer>
    <S.ImageContainer className="keylink-icon" aria-hidden>
      <ImagePartial
        altText={keyLink.icon?.altText}
        src={keyLink.icon?.image?.name ?? ''}
        assetType={keyLink.icon?.assetType}
        height={16}
        width={16}
        objectFit="contain"
        defaultHost={keyLink.icon?.image?.defaultHost}
        useOriginalSize={keyLink.icon?.useOriginalSize}
      />
    </S.ImageContainer>
    <div>
      <S.KeyLinkTitle>{getLocalizedValue(keyLink.title)}</S.KeyLinkTitle>
      <S.KeyLinkDescription>{getLocalizedValue(keyLink.description)}</S.KeyLinkDescription>
    </div>
  </S.KeyLinkContainer>
);

function KeyLinkButton({ keyLink, show }: { keyLink: FooterKeyLink; show(): void }) {
  const handleClick = () => {
    show?.();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      show?.();
    }
  };

  return (
    <S.KeyLinkContainer role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleKeyDown}>
      <S.ImageContainer aria-hidden>
        <ImagePartial
          altText={keyLink.icon?.altText}
          src={keyLink.icon?.image?.name ?? ''}
          assetType={keyLink.icon?.assetType}
          height={16}
          width={16}
          objectFit="contain"
          defaultHost={keyLink.icon?.image?.defaultHost}
          useOriginalSize={keyLink.icon?.useOriginalSize}
        />
      </S.ImageContainer>
      <div>
        <S.KeyLinkTitle>{getLocalizedValue(keyLink.title)}</S.KeyLinkTitle>
        <S.KeyLinkDescription>{getLocalizedValue(keyLink.description)}</S.KeyLinkDescription>
      </div>
    </S.KeyLinkContainer>
  );
}

export function FooterKeyLinks({ footerKeyLinks }: FooterKeyLinksProps) {
  return (
    <S.Container>
      <S.KeyLinks>
        {footerKeyLinks?.map((keyLink, idx) => {
          const { link } = keyLink || {};

          if (keyLink) {
            return (
              <S.KeyLink key={idx}>
                <LinkPartial
                  {...link}
                  ariaLabel={`${getLocalizedValue(keyLink.title)}, ${getLocalizedValue(keyLink.description)}`}
                >
                  <KeyLinkContainer keyLink={keyLink} />
                </LinkPartial>
              </S.KeyLink>
            );
          }

          return null;
        })}
      </S.KeyLinks>
    </S.Container>
  );
}

export default FooterKeyLinks;
