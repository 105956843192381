import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { DiscountCodeErrorCode } from '../../codegen/types';
import { useCartContext } from '../../context';
import { StateModalResult } from '../../types';
import StateModal from './StateModal';

export function DiscountExpiredModal() {
  const { t } = useTranslation(['lib-global-common']);
  const { miniCart } = useCartContext();

  const [isDiscountExpired, setIsDiscountExpired] = useState<boolean>(false);

  const handleOverlayDiscountExpired = useCallback(() => {
    setIsDiscountExpired((prev) => !prev);
  }, []);

  const expiredDiscountCodes = useMemo<string[]>(
    () =>
      miniCart?.errors
        ?.filter(
          (error) =>
            error?.__typename === 'DiscountCodeError' &&
            [DiscountCodeErrorCode.NOT_VALID, DiscountCodeErrorCode.NOT_ACTIVE].includes(error?.errorCode),
        )
        ?.map((error: any) => error.discountCode) ?? [],
    [miniCart?.errors],
  );

  const expiredDiscountCodeName = useMemo<string>(() => expiredDiscountCodes?.join(', ') ?? '', [expiredDiscountCodes]);

  useEffect(() => {
    setIsDiscountExpired(
      Boolean(
        miniCart?.errors?.some(
          (error) =>
            error?.__typename === 'DiscountCodeError' &&
            [DiscountCodeErrorCode.NOT_VALID, DiscountCodeErrorCode.NOT_ACTIVE].includes(error?.errorCode),
        ),
      ),
    );
  }, [miniCart?.errors]);

  return (
    <StateModal
      warningMessage={t('cart.promo.error.message.promo.expired', {
        discountName: expiredDiscountCodeName,
        count: expiredDiscountCodes.length,
      })}
      handleOpenOverlay={handleOverlayDiscountExpired}
      isOverLayOpen={isDiscountExpired}
      result={StateModalResult.WARNING}
    />
  );
}
