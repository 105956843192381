import styled, { css } from 'styled-components';
import { colours, media, spacing } from '../../stylings';
import { PaymentMethodsPlacement } from './PaymentMethods.utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`;

export const IconList = styled.ul<{ $placement: PaymentMethodsPlacement }>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  width: 100%;

  ${({ $placement }) => {
    switch ($placement) {
      case PaymentMethodsPlacement.FOOTER:
        return css`
          gap: 4.5px;

          @media ${media.greaterThan('lg')} {
            gap: 7.7px;
          }
        `;
      case PaymentMethodsPlacement.PDP:
        return css`
          gap: 3.45px;

          @media ${media.greaterThan('lg')} {
            gap: 23.5px;
          }
        `;
      case PaymentMethodsPlacement.CART:
        return css`
          gap: 7.45px;

          @media ${media.greaterThan('lg')} {
            gap: 3.45px;
          }
        `;
      case PaymentMethodsPlacement.MINI_CART:
        return css`
          gap: 4.8px;

          @media ${media.greaterThan('lg')} {
            gap: 8.75px;
          }
        `;
      case PaymentMethodsPlacement.CHECKOUT:
        return css`
          gap: 7.4px;

          @media ${media.greaterThan('lg')} {
            gap: 8.75px;
          }
        `;
      default:
        return null;
    }
  }}
`;

export const IconContainer = styled.li<{ $placement: PaymentMethodsPlacement }>`
  align-items: center;
  background-color: ${colours.WHITE};
  border: 1px solid ${colours.DARK_GREY};
  display: flex;
  grid-column: initial;
  justify-content: center;

  ${({ $placement }) => {
    switch ($placement) {
      case PaymentMethodsPlacement.CART:
        return css`
          height: 22.9px;
          width: 34.3px;

          @media ${media.greaterThan('lg')} {
            height: 20.2px;
            width: 30.3px;
          }
        `;
      case PaymentMethodsPlacement.FOOTER:
      case PaymentMethodsPlacement.PDP:
        return css`
          height: 24px;
          width: 34px;

          @media ${media.greaterThan('lg')} {
            height: 36.4px;
            width: 54.5px;
          }
        `;
      case PaymentMethodsPlacement.MINI_CART:
        return css`
          height: 18.83px;
          width: 28.5px;

          @media ${media.greaterThan('lg')} {
            height: 22.9px;
            width: 34.3px;
          }
        `;
      case PaymentMethodsPlacement.CHECKOUT:
        return css`
          height: 18.83px;
          width: 28.5px;

          @media ${media.greaterThan('lg')} {
            height: 22.9px;
            width: 34.3px;
          }
        `;
      default:
        return null;
    }
  }}

  & > span {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const PaymentMethodsWrapper = styled.div<{ $placement: PaymentMethodsPlacement }>`
  margin: ${spacing.XXS} 0;

  ${({ $placement }) => {
    switch ($placement) {
      case PaymentMethodsPlacement.PDP:
        return css`
          margin: 14px 0;
          max-width: 328px;

          @media ${media.greaterThan('lg')} {
            max-width: 523px;
          }
        `;
      case PaymentMethodsPlacement.FOOTER:
        return css`
          max-width: 320px;

          @media ${media.greaterThan('lg')} {
            max-width: 301px;
          }
        `;
      case PaymentMethodsPlacement.CART:
        return css`
          max-width: 328px;

          @media ${media.greaterThan('lg')} {
            max-width: 298px;
          }
        `;
      case PaymentMethodsPlacement.MINI_CART:
        return css`
          max-width: 262px;

          @media ${media.greaterThan('lg')} {
            max-width: 336px;
          }
        `;
      case PaymentMethodsPlacement.CHECKOUT:
        return css`
          max-width: 280px;

          @media ${media.greaterThan('lg')} {
            max-width: 336px;
          }
        `;
      default:
        return null;
    }
  }}
`;
