/* eslint-disable security/detect-unsafe-regex */
import * as yup from 'yup';
import { EMAIL_REGEX } from './register';

export const CognitoCustomerOfflineSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().matches(EMAIL_REGEX).max(128),
  locale: yup.string().required(),
  registeredFrom: yup.string().required(),
});
