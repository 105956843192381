import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetRedirectInfoDataQueryVariables = Types.Exact<{
  country: Types.Scalars['String'];
  currentLocale: Types.Scalars['String'];
  currentSite: Types.Scalars['String'];
}>;

export type GetRedirectInfoDataQuery = {
  __typename?: 'Query';
  redirectInfo?: {
    __typename?: 'RedirectInformation';
    type: Types.RedirectType;
    url: string;
    hint?: string | null;
  } | null;
  redirectInfoRecord?: {
    __typename?: 'RedirectInformation';
    type: Types.RedirectType;
    url: string;
    hint?: string | null;
  } | null;
};

export const GetRedirectInfoDataDocument = gql`
  query GetRedirectInfoData($country: String!, $currentLocale: String!, $currentSite: String!) {
    redirectInfo(country: $country, currentLocale: $currentLocale, currentSite: $currentSite) {
      type
      url
      hint
    }
    redirectInfoRecord: redirectInfo(country: $country, currentLocale: "", currentSite: "") {
      type
      url
      hint
    }
  }
`;

/**
 * __useGetRedirectInfoDataQuery__
 *
 * To run a query within a React component, call `useGetRedirectInfoDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedirectInfoDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedirectInfoDataQuery({
 *   variables: {
 *      country: // value for 'country'
 *      currentLocale: // value for 'currentLocale'
 *      currentSite: // value for 'currentSite'
 *   },
 * });
 */
export function useGetRedirectInfoDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetRedirectInfoDataQuery, GetRedirectInfoDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRedirectInfoDataQuery, GetRedirectInfoDataQueryVariables>(
    GetRedirectInfoDataDocument,
    options,
  );
}
export function useGetRedirectInfoDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRedirectInfoDataQuery, GetRedirectInfoDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRedirectInfoDataQuery, GetRedirectInfoDataQueryVariables>(
    GetRedirectInfoDataDocument,
    options,
  );
}
export type GetRedirectInfoDataQueryHookResult = ReturnType<typeof useGetRedirectInfoDataQuery>;
export type GetRedirectInfoDataLazyQueryHookResult = ReturnType<typeof useGetRedirectInfoDataLazyQuery>;
export type GetRedirectInfoDataQueryResult = Apollo.QueryResult<
  GetRedirectInfoDataQuery,
  GetRedirectInfoDataQueryVariables
>;
