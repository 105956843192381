import { useState } from 'react';
import styled from 'styled-components';
import type {
  AccordionBodyRichText,
  AccordionItemHeading,
  AnchorID,
} from '@amplience/content-types/typings/c-simple-accordions';
import { AccordionContent, AccordionHeader, AccordionItem, AccordionTrigger } from '../../baseComponents/accordion';
import { ArrowDown } from '../../icons';
import { RichTextPartial } from '../../partials/richText';
import {
  TypographyStyles,
  accordionArrowAnimation,
  colours,
  media,
  radixAccordionSlideDown,
  radixAccordionSlideUp,
  spacing,
} from '../../stylings';
import { sendAccordionClickEvent } from '../../utils/gtm/events/accordion_click/sendAccordionClickEvent';
import { getLocalizedValue, transformTextToId } from '../../utils/transformers';

export type SimpleAccordionProps = Partial<{
  accordionHeading: AccordionItemHeading;
  accordionBody: AccordionBodyRichText;
  accordionAnchorId: AnchorID;
  value: string;
}>;

const S = {
  AccordionItem: styled(AccordionItem)`
    border-bottom: 1px solid ${colours.BLACK};
  `,
  Description: styled(AccordionContent)`
    overflow: hidden;
    overflow-wrap: anywhere;

    &[data-state='open'] {
      animation: ${radixAccordionSlideDown} 0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }

    &[data-state='closed'] {
      animation: ${radixAccordionSlideUp} 0.3s cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }

    p {
      margin: 0 0 ${spacing.XS} 0;
    }

    a {
      color: ${colours.BLACK};
    }

    @media (prefers-reduced-motion) {
      &[data-state='open'] {
        animation: none;
      }

      &[data-state='closed'] {
        animation: none;
      }
    }
  `,

  Heading: styled(AccordionTrigger)`
    all: unset;
    align-items: center; /* stylelint-disable-line */
    cursor: pointer;
    display: flex;
    gap: ${spacing.XS};
    justify-content: space-between;
    overflow-wrap: anywhere;
    padding: ${spacing.XXS} 0;
    width: 100%;

    ${accordionArrowAnimation()}

    &:focus-visible {
      outline: 2px solid ${colours.OUTLINE_BORDER};
    }

    @media ${media.greaterThan('lg')} {
      padding: 20px 0;
    }
  `,

  HeadingText: styled(AccordionHeader)`
    ${TypographyStyles.Body.Small.Regular}
    font-weight: 600;
    margin: 0;
  `,
};

export function SimpleAccordion({ accordionHeading, accordionBody, accordionAnchorId, value }: SimpleAccordionProps) {
  const anchorId = transformTextToId(accordionAnchorId ?? '');
  const richText = (accordionBody?.[0] as AccordionBodyRichText)?.richText;
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const handleOnClick = (accordionName: AccordionItemHeading | undefined) => () => {
    if (!isAccordionOpen) {
      sendAccordionClickEvent(getLocalizedValue(accordionName));
    }
    setIsAccordionOpen((prev) => !prev);
  };

  return (
    <S.AccordionItem value={`#${value}`}>
      <S.Heading onClick={handleOnClick(accordionHeading)}>
        <S.HeadingText as="span" id={anchorId}>
          {getLocalizedValue(accordionHeading)}
        </S.HeadingText>
        <ArrowDown className="accordion-arrow-down" />
      </S.Heading>
      <S.Description>
        <RichTextPartial text={getLocalizedValue(richText)} fontSize={TypographyStyles.Body.Small.Regular} />
      </S.Description>
    </S.AccordionItem>
  );
}

export default SimpleAccordion;
