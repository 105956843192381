import styled from 'styled-components';
import { TypographyStyles, colours, fontWeights, media, spacing } from '../../../stylings';

export const TrackingContainer = styled.div``;

export const TrackingWholeDetails = styled.div`
  display: grid;
  gap: ${spacing.XS};
  @media ${media.greaterThan('lg')} {
    gap: ${spacing.M};
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const TrackingDetails = styled.div`
  background-color: ${colours.LIGHT_BLUE};
  display: flex;
  gap: 20px;
  height: 132px;
  order: 2;
  padding: ${spacing.S};
  @media ${media.greaterThan('lg')} {
    order: 1;
  }
`;

export const TrackingTexts = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
`;

export const Carrier = styled.p`
  margin: 0;
  padding: 0;
`;

export const CarrierService = styled.span`
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;
`;

export const ShippedAt = styled.p`
  margin: 0;
  padding: 0;
`;

export const TrackingIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  svg {
    path {
      fill: ${colours.BLACK};
    }
  }
`;

export const TrackingItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XXS};
  margin: 0;
  order: 1;
  padding: 0;
  @media ${media.greaterThan('lg')} {
    gap: 20px;
    order: 2;
  }
`;

export const TrackingCard = styled.div<{ $isCanceled: boolean }>`
  border: ${({ $isCanceled }) => ($isCanceled ? `solid 1px ${colours.ERROR_PRIMARY}` : 'none')};
  display: flex;
  gap: ${spacing.XS};
  padding: ${({ $isCanceled }) => ($isCanceled ? '12px' : 'initial')};
`;

export const CardImageContainer = styled.div`
  height: 132px;
  position: relative;
  width: 100px;
  @media ${media.greaterThan('lg')} {
    width: 132px;
  }
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: ${spacing.XXXXS};
  justify-content: center;
  @media ${media.greaterThan('lg')} {
    gap: ${spacing.XXXS};
  }
`;

export const Name = styled.p`
  ${TypographyStyles.Label.Large.Medium}
  margin: 0;
  max-width: 156px;
  padding: 0;
  text-transform: uppercase;
`;

export const Price = styled.p`
  ${TypographyStyles.Body.Medium.SemiBold}
  grid-area: price;
  margin: 0;
  padding: 0;
`;

export const Canceled = styled.p`
  background-color: ${colours.ERROR_SECONDARY};
  color: ${colours.ERROR_PRIMARY};
  font-weight: ${fontWeights.SemiBold600};
  grid-area: canceled;
  margin: 0;
  padding: 6px ${spacing.XXS};
  text-align: center;
`;

export const Property = styled.td`
  ${TypographyStyles.Body.Tiny.Regular}
  ${fontWeights.Regular400}
    padding-right: ${spacing.XXXXS};
  vertical-align: baseline;
`;

export const Value = styled.td`
  ${TypographyStyles.Body.Tiny.SemiBold}
  ${fontWeights.SemiBold600}
    word-break: break-word;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const TrackingCode = styled.span<{ $isDisabled: boolean }>`
  ${TypographyStyles.Body.Small.SemiBold}
  color: ${colours.BLACK};
`;

export const FinalSaleContainer = styled.div`
  padding-bottom: ${spacing.XXXS};
`;
