import { gql } from '@apollo/client';
import type * as Types from '../codegen/types.js';

export type PricesFragment = {
  __typename?: 'LineItem';
  price: {
    __typename?: 'LineItemPrice';
    discountedPercentage?: number | null;
    wasPriceValidFrom?: string | null;
    wasPriceValidUntil?: string | null;
    thenPriceValidFrom?: string | null;
    thenPriceValidUntil?: string | null;
    value: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
    wasPrice?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
    thenPrice?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
    discountedPrice?: {
      __typename?: 'PriceInfo';
      centAmount: number;
      currencyCode: string;
      fractionDigits: number;
    } | null;
    totalDiscountedPrice?: {
      __typename?: 'PriceInfo';
      centAmount: number;
      currencyCode: string;
      fractionDigits: number;
    } | null;
  };
  totalPrice: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
  taxedPrice?: {
    __typename?: 'TaxedPrice';
    totalGross: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
    totalNet: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
    totalTax?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
  } | null;
  taxRate?: { __typename?: 'TaxRate'; amount: number } | null;
};

export type LineItemsFragment = {
  __typename?: 'Cart';
  lineItems: Array<{
    __typename?: 'LineItem';
    id: string;
    brand?: string | null;
    productId: string;
    quantity: number;
    productKey?: string | null;
    productSlug?: string | null;
    name: string;
    imageURLs: Array<string>;
    size?: string | null;
    colour?: string | null;
    fit?: string | null;
    fitLabel?: string | null;
    discounts: Array<string>;
    isLastItem?: boolean | null;
    isOutOfStock?: boolean | null;
    productTypeKey?: string | null;
    giftCardRecipient?: string | null;
    giftCardMessage?: string | null;
    giftCardSender?: string | null;
    giftCardRecipientEmail?: string | null;
    giftCardOccasion?: string | null;
    availableQuantity?: number | null;
    backInStockNotificationsEnabled: boolean;
    variant: { __typename?: 'CartVariant'; id?: number | null; sku?: string | null };
    finalSale?: {
      __typename?: 'FinalSaleAttributes';
      backgroundColor?: string | null;
      emailText?: string | null;
      isFinalSale?: boolean | null;
      text?: string | null;
      textColor?: string | null;
    } | null;
    discountsDescriptions: Array<{
      __typename?: 'DiscountDescription';
      type?: Types.DiscountType | null;
      description?: string | null;
      shortDescription?: string | null;
    }>;
    badges: Array<{ __typename?: 'LineItemBadge'; code: string; type?: string | null }>;
    primaryCategory?: { __typename?: 'PrimaryCategoryObject'; key?: string | null; name?: string | null } | null;
    price: {
      __typename?: 'LineItemPrice';
      discountedPercentage?: number | null;
      wasPriceValidFrom?: string | null;
      wasPriceValidUntil?: string | null;
      thenPriceValidFrom?: string | null;
      thenPriceValidUntil?: string | null;
      value: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
      wasPrice?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
      thenPrice?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
      discountedPrice?: {
        __typename?: 'PriceInfo';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
      } | null;
      totalDiscountedPrice?: {
        __typename?: 'PriceInfo';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
      } | null;
    };
    totalPrice: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
    taxedPrice?: {
      __typename?: 'TaxedPrice';
      totalGross: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
      totalNet: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
      totalTax?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
    } | null;
    taxRate?: { __typename?: 'TaxRate'; amount: number } | null;
  }>;
};

export type PaymentsFragment = {
  __typename?: 'Cart';
  payments?: Array<{
    __typename?: 'Payment';
    paymentMethod?: string | null;
    amount?: { __typename?: 'PriceInfo'; currencyCode: string; centAmount: number } | null;
  } | null> | null;
};

export type DeliveryInstructionsFragment = {
  __typename?: 'Cart';
  deliveryInstructions?: { __typename?: 'DeliveryInstructions'; type: string; message?: string | null } | null;
};

export type DiscountCodesFragment = {
  __typename?: 'Cart';
  discountCodes?: Array<{
    __typename?: 'DiscountCode';
    discountCode: string;
    discountId: string;
    discountName?: string | null;
    discountValue?: number | null;
  } | null> | null;
};

export type DeliveryMethodFragment = {
  __typename?: 'Cart';
  deliveryMethod?: {
    __typename?: 'DeliveryMethod';
    description: string;
    key: string;
    name: string;
    shippingType?: string | null;
    price: number;
    deliveryTime?: string | null;
  } | null;
};

export type DeliveriesFragment = {
  __typename?: 'Cart';
  deliveries?: Array<{
    __typename?: 'Delivery';
    deliveryDate: string;
    storeName?: string | null;
    deliveryStoreId?: string | null;
    deliveryName?: string | null;
    fulfillmentNodeType?: string | null;
    serviceLevelIdentifier?: string | null;
    lineItems: Array<{ __typename?: 'LineItemIdentifier'; id: string }>;
  }> | null;
};

export type AddressDetailFragment = {
  __typename?: 'CartAddress';
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  company?: string | null;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country: string;
  state?: string | null;
  key?: string | null;
  id?: string | null;
  countryAlias?: string | null;
};

export type GetAddressesFragmentFragment = {
  __typename?: 'Address';
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  countryAlias?: string | null;
  company?: string | null;
  line1?: string | null;
  line2?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country: string;
  state?: string | null;
  key?: string | null;
  id?: string | null;
  isDefaultBilling?: boolean | null;
  isDefaultShipping?: boolean | null;
};

export type AddressesFragment = {
  __typename?: 'Cart';
  billingAddress?: {
    __typename?: 'CartAddress';
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    company?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postalCode?: string | null;
    country: string;
    state?: string | null;
    key?: string | null;
    id?: string | null;
    countryAlias?: string | null;
  } | null;
  deliveryAddress?: {
    __typename?: 'CartAddress';
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    company?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postalCode?: string | null;
    country: string;
    state?: string | null;
    key?: string | null;
    id?: string | null;
    countryAlias?: string | null;
  } | null;
};

export type CartErrorsFragment = {
  __typename?: 'Cart';
  errors: Array<
    | {
        __typename?: 'DiscountCodeError';
        validUntil?: any | null;
        validFrom?: any | null;
        errorCode: Types.DiscountCodeErrorCode;
        discountCode: string;
      }
    | { __typename?: 'LineItemError'; lineItemId: string }
  >;
};

export type CartDetailsFragment = {
  __typename?: 'Cart';
  id: string;
  guid: string;
  version: number;
  anonymousId?: string | null;
  customerEmail?: string | null;
  customerId?: string | null;
  subtotal: number;
  deliveryTotal: number;
  discountTotal: number;
  taxes?: number | null;
  idMeDiscount?: number | null;
  isDigitalCart: boolean;
  total: number;
  currency: string;
  hasFreeDelivery: boolean;
  shippingType?: string | null;
  active?: boolean | null;
  errors: Array<
    | {
        __typename?: 'DiscountCodeError';
        validUntil?: any | null;
        validFrom?: any | null;
        errorCode: Types.DiscountCodeErrorCode;
        discountCode: string;
      }
    | { __typename?: 'LineItemError'; lineItemId: string }
  >;
  discountCodes?: Array<{
    __typename?: 'DiscountCode';
    discountCode: string;
    discountId: string;
    discountName?: string | null;
    discountValue?: number | null;
  } | null> | null;
  lineItems: Array<{
    __typename?: 'LineItem';
    id: string;
    brand?: string | null;
    productId: string;
    quantity: number;
    productKey?: string | null;
    productSlug?: string | null;
    name: string;
    imageURLs: Array<string>;
    size?: string | null;
    colour?: string | null;
    fit?: string | null;
    fitLabel?: string | null;
    discounts: Array<string>;
    isLastItem?: boolean | null;
    isOutOfStock?: boolean | null;
    productTypeKey?: string | null;
    giftCardRecipient?: string | null;
    giftCardMessage?: string | null;
    giftCardSender?: string | null;
    giftCardRecipientEmail?: string | null;
    giftCardOccasion?: string | null;
    availableQuantity?: number | null;
    backInStockNotificationsEnabled: boolean;
    variant: { __typename?: 'CartVariant'; id?: number | null; sku?: string | null };
    finalSale?: {
      __typename?: 'FinalSaleAttributes';
      backgroundColor?: string | null;
      emailText?: string | null;
      isFinalSale?: boolean | null;
      text?: string | null;
      textColor?: string | null;
    } | null;
    discountsDescriptions: Array<{
      __typename?: 'DiscountDescription';
      type?: Types.DiscountType | null;
      description?: string | null;
      shortDescription?: string | null;
    }>;
    badges: Array<{ __typename?: 'LineItemBadge'; code: string; type?: string | null }>;
    primaryCategory?: { __typename?: 'PrimaryCategoryObject'; key?: string | null; name?: string | null } | null;
    price: {
      __typename?: 'LineItemPrice';
      discountedPercentage?: number | null;
      wasPriceValidFrom?: string | null;
      wasPriceValidUntil?: string | null;
      thenPriceValidFrom?: string | null;
      thenPriceValidUntil?: string | null;
      value: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
      wasPrice?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
      thenPrice?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
      discountedPrice?: {
        __typename?: 'PriceInfo';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
      } | null;
      totalDiscountedPrice?: {
        __typename?: 'PriceInfo';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
      } | null;
    };
    totalPrice: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
    taxedPrice?: {
      __typename?: 'TaxedPrice';
      totalGross: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
      totalNet: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number };
      totalTax?: { __typename?: 'PriceInfo'; centAmount: number; currencyCode: string; fractionDigits: number } | null;
    } | null;
    taxRate?: { __typename?: 'TaxRate'; amount: number } | null;
  }>;
  billingAddress?: {
    __typename?: 'CartAddress';
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    company?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postalCode?: string | null;
    country: string;
    state?: string | null;
    key?: string | null;
    id?: string | null;
    countryAlias?: string | null;
  } | null;
  deliveryAddress?: {
    __typename?: 'CartAddress';
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    company?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    postalCode?: string | null;
    country: string;
    state?: string | null;
    key?: string | null;
    id?: string | null;
    countryAlias?: string | null;
  } | null;
  deliveryMethod?: {
    __typename?: 'DeliveryMethod';
    description: string;
    key: string;
    name: string;
    shippingType?: string | null;
    price: number;
    deliveryTime?: string | null;
  } | null;
  deliveries?: Array<{
    __typename?: 'Delivery';
    deliveryDate: string;
    storeName?: string | null;
    deliveryStoreId?: string | null;
    deliveryName?: string | null;
    fulfillmentNodeType?: string | null;
    serviceLevelIdentifier?: string | null;
    lineItems: Array<{ __typename?: 'LineItemIdentifier'; id: string }>;
  }> | null;
};

export type PriceDetailsFragment = {
  __typename?: 'Price';
  lastOfferedAtDate?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  actualPrice: {
    __typename?: 'CentPrecisionMoney';
    centAmount: number;
    currencyCode: string;
    fractionDigits: number;
    type: string;
  };
  thenPrice?: {
    __typename?: 'CentPrecisionMoney';
    centAmount: number;
    currencyCode: string;
    fractionDigits: number;
    type: string;
  } | null;
  wasPrice?: {
    __typename?: 'CentPrecisionMoney';
    centAmount: number;
    fractionDigits: number;
    type: string;
    currencyCode: string;
  } | null;
};

export type GiftCardVariantsFragment = {
  __typename?: 'GiftCardProduct';
  giftCardVariants: Array<{
    __typename?: 'GiftCardVariant';
    imageUrls: Array<string>;
    occasion?: string | null;
    priceDenomination?: string | null;
    sku: string;
    price: Array<{
      __typename?: 'Price';
      lastOfferedAtDate?: string | null;
      fromDate?: string | null;
      toDate?: string | null;
      actualPrice: {
        __typename?: 'CentPrecisionMoney';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
        type: string;
      };
      thenPrice?: {
        __typename?: 'CentPrecisionMoney';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
        type: string;
      } | null;
      wasPrice?: {
        __typename?: 'CentPrecisionMoney';
        centAmount: number;
        fractionDigits: number;
        type: string;
        currencyCode: string;
      } | null;
    }>;
  }>;
};

export type ProductVariantsFragment = {
  __typename?: 'Product';
  variants: Array<{
    __typename?: 'Variant';
    backInStockNotificationsEnabled?: boolean | null;
    fit?: string | null;
    globalFit?: string | null;
    globalSize?: string | null;
    fitLabel?: string | null;
    size?: string | null;
    sku: string;
    badges?: Array<{ __typename?: 'ProductBadge'; code: string; type?: string | null } | null> | null;
    badgesV2?: {
      __typename?: 'ProductBadgeV2';
      cacheTTL?: number | null;
      textual?: {
        __typename?: 'ProductBadgeTextual';
        url?: string | null;
        textColor?: string | null;
        text?: string | null;
        code?: string | null;
        backgroundColor?: string | null;
      } | null;
      image?: {
        __typename?: 'ProductBadgeImage';
        src?: string | null;
        code?: string | null;
        alt?: string | null;
        url?: string | null;
      } | null;
    } | null;
    stock: { __typename?: 'Stock'; availableQuantity: number; status: Types.StockStatus };
    price: Array<{
      __typename?: 'Price';
      lastOfferedAtDate?: string | null;
      fromDate?: string | null;
      toDate?: string | null;
      actualPrice: {
        __typename?: 'CentPrecisionMoney';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
        type: string;
      };
      thenPrice?: {
        __typename?: 'CentPrecisionMoney';
        centAmount: number;
        currencyCode: string;
        fractionDigits: number;
        type: string;
      } | null;
      wasPrice?: {
        __typename?: 'CentPrecisionMoney';
        centAmount: number;
        fractionDigits: number;
        type: string;
        currencyCode: string;
      } | null;
    }>;
  }>;
};

export const PaymentsFragmentDoc = gql`
  fragment Payments on Cart {
    payments {
      paymentMethod
      amount {
        currencyCode
        centAmount
      }
    }
  }
`;
export const DeliveryInstructionsFragmentDoc = gql`
  fragment DeliveryInstructions on Cart {
    deliveryInstructions {
      type
      message
    }
  }
`;
export const GetAddressesFragmentFragmentDoc = gql`
  fragment GetAddressesFragment on Address {
    firstName
    lastName
    phone
    countryAlias
    company
    line1
    line2
    city
    postalCode
    country
    state
    key
    id
    isDefaultBilling
    isDefaultShipping
  }
`;
export const CartErrorsFragmentDoc = gql`
  fragment CartErrors on Cart {
    errors {
      ... on DiscountCodeError {
        validUntil
        validFrom
        errorCode
        discountCode
      }
      ... on LineItemError {
        lineItemId
      }
    }
  }
`;
export const DiscountCodesFragmentDoc = gql`
  fragment DiscountCodes on Cart {
    discountCodes {
      discountCode
      discountId
      discountName
      discountValue
    }
  }
`;
export const PricesFragmentDoc = gql`
  fragment Prices on LineItem {
    price {
      value {
        centAmount
        currencyCode
        fractionDigits
      }
      wasPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      thenPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      discountedPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      totalDiscountedPrice {
        centAmount
        currencyCode
        fractionDigits
      }
      discountedPercentage
      wasPriceValidFrom
      wasPriceValidUntil
      thenPriceValidFrom
      thenPriceValidUntil
    }
    totalPrice {
      centAmount
      currencyCode
      fractionDigits
    }
    taxedPrice {
      totalGross {
        centAmount
        currencyCode
        fractionDigits
      }
      totalNet {
        centAmount
        currencyCode
        fractionDigits
      }
      totalTax {
        centAmount
        currencyCode
        fractionDigits
      }
    }
    taxRate {
      amount
    }
  }
`;
export const LineItemsFragmentDoc = gql`
  fragment LineItems on Cart {
    lineItems {
      id
      brand
      variant {
        id
        sku
      }
      productId
      quantity
      productKey
      productSlug
      name
      imageURLs
      size
      colour
      finalSale {
        backgroundColor
        emailText
        isFinalSale
        text
        textColor
      }
      fit
      fitLabel
      discounts
      discountsDescriptions {
        type
        description
        shortDescription
      }
      isLastItem
      badges {
        code
        type
      }
      isOutOfStock
      productTypeKey
      primaryCategory {
        key
        name
      }
      ...Prices
      giftCardRecipient
      giftCardMessage
      giftCardSender
      giftCardRecipientEmail
      giftCardOccasion
      availableQuantity
      backInStockNotificationsEnabled
    }
  }
  ${PricesFragmentDoc}
`;
export const AddressDetailFragmentDoc = gql`
  fragment AddressDetail on CartAddress {
    firstName
    lastName
    phone
    company
    line1
    line2
    city
    postalCode
    country
    state
    key
    id
    countryAlias
  }
`;
export const AddressesFragmentDoc = gql`
  fragment Addresses on Cart {
    billingAddress {
      ...AddressDetail
    }
    deliveryAddress {
      ...AddressDetail
    }
  }
  ${AddressDetailFragmentDoc}
`;
export const DeliveryMethodFragmentDoc = gql`
  fragment DeliveryMethod on Cart {
    deliveryMethod {
      description
      key
      name
      shippingType
      price
      deliveryTime
    }
  }
`;
export const DeliveriesFragmentDoc = gql`
  fragment Deliveries on Cart {
    deliveries {
      deliveryDate
      storeName
      deliveryStoreId
      deliveryName
      fulfillmentNodeType
      serviceLevelIdentifier
      lineItems {
        id
      }
    }
  }
`;
export const CartDetailsFragmentDoc = gql`
  fragment CartDetails on Cart {
    id
    guid
    version
    anonymousId
    customerEmail
    customerId
    subtotal
    deliveryTotal
    discountTotal
    taxes
    idMeDiscount
    isDigitalCart
    total
    currency
    hasFreeDelivery
    shippingType
    active
    ...CartErrors
    ...DiscountCodes
    ...LineItems
    ...Addresses
    ...DeliveryMethod
    ...Deliveries
  }
  ${CartErrorsFragmentDoc}
  ${DiscountCodesFragmentDoc}
  ${LineItemsFragmentDoc}
  ${AddressesFragmentDoc}
  ${DeliveryMethodFragmentDoc}
  ${DeliveriesFragmentDoc}
`;
export const PriceDetailsFragmentDoc = gql`
  fragment PriceDetails on Price {
    actualPrice {
      centAmount
      currencyCode
      fractionDigits
      type
    }
    thenPrice {
      centAmount
      currencyCode
      fractionDigits
      type
    }
    wasPrice {
      centAmount
      fractionDigits
      type
      currencyCode
    }
    lastOfferedAtDate
    fromDate
    toDate
  }
`;
export const GiftCardVariantsFragmentDoc = gql`
  fragment GiftCardVariants on GiftCardProduct {
    giftCardVariants {
      imageUrls
      occasion
      priceDenomination
      sku
      price {
        ...PriceDetails
      }
    }
  }
  ${PriceDetailsFragmentDoc}
`;
export const ProductVariantsFragmentDoc = gql`
  fragment ProductVariants on Product {
    variants {
      backInStockNotificationsEnabled
      fit
      globalFit
      globalSize
      fitLabel
      size
      sku
      badges {
        code
        type
      }
      badgesV2 {
        textual {
          url
          textColor
          text
          code
          backgroundColor
        }
        image {
          src
          code
          alt
          url
        }
        cacheTTL
      }
      stock {
        availableQuantity
        status
      }
      price {
        ...PriceDetails
      }
    }
  }
  ${PriceDetailsFragmentDoc}
`;
