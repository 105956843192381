import { css } from 'styled-components';
import { fontWeights } from '../stylings';

enum Status {
  AWAITING_SOURCING = 'AWAITING_SOURCING',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  DC_PICKING = 'DC_PICKING',
  CANCELLED = 'CANCELLED',
  SHIPPED = 'SHIPPED',
  DELIVERYATTEMPTED = 'DELIVERYATTEMPTED',
  DELIVERYFAILED = 'DELIVERYFAILED',
  DELIVERYCOMPLETE = 'DELIVERYCOMPLETE',
  STORE_PICKING = 'STORE_PICKING',
  AWAITINGCOLLECTION = 'AWAITINGCOLLECTION',
  COLLECTED = 'COLLECTED',
  RETURNED = 'RETURNED',
}
export const SemiBoldValue = css`
  .value {
    ${fontWeights.SemiBold600}
  }
`;

export function getDeliveryStatus(status: string, returnLink?: string) {
  switch (status) {
    case 'DELIVERYCOMPLETE':
    case 'COLLECTED':
      return {
        color: 'green',
        canBeReturned: !!returnLink,
        isCanceled: false,
        isDelivered: true,
      };
    case 'CANCELLED':
      return {
        color: 'red',
        canBeReturned: !!returnLink,
        isCanceled: true,
        isDelivered: false,
      };

    default:
      return {
        color: 'blue',
        canBeReturned: !!returnLink,
        isCanceled: false,
        isDelivered: false,
      };
  }
}

export function getStatusText(status: string) {
  switch (status) {
    case Status.ACKNOWLEDGED:
    case Status.AWAITING_SOURCING:
      return 'order.details.status.processing';
    case Status.DC_PICKING:
    case Status.STORE_PICKING:
      return 'order.details.status.picking';
    case Status.CANCELLED:
      return 'order.details.status.cancelled';
    case Status.SHIPPED:
      return 'order.details.status.shipped';
    case Status.DELIVERYATTEMPTED:
      return 'order.details.status.delivery.attempted';
    case Status.DELIVERYFAILED:
      return 'order.details.status.delivery.failed';
    case Status.DELIVERYCOMPLETE:
      return 'order.details.status.delivery.completed';
    case Status.AWAITINGCOLLECTION:
      return 'order.details.status.awaiting.collection';
    case Status.COLLECTED:
      return 'order.details.status.collected';
    case Status.RETURNED:
      return 'order.details.status.returned';

    default:
      return 'order.details.status.processing';
  }
}
