import type { CustomAttributes } from '../types/newrelic';

export function newRelicAvailabilityChecker<T>(newRelicCallback: () => T) {
  // Check if newrelic is available than invoke the callback function
  if (typeof window !== 'undefined' && typeof window.newrelic !== 'undefined') {
    newRelicCallback();
  } else {
    // eslint-disable-next-line no-console
    console.warn('New Relic is not available');
  }
}

export async function hashEmail(email: string) {
  try {
    if (!window.TextEncoder || !window.crypto || !window.crypto.subtle) {
      return null;
    }

    const utf8 = new TextEncoder().encode(email);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((hash) => hash.toString(16).padStart(2, '0')).join('');
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function setNewRelicAttributes(customAttributes: CustomAttributes, options?: { interactionOnly?: boolean }) {
  newRelicAvailabilityChecker(() => {
    Object.entries(customAttributes).forEach(([name, value]) => {
      if (value === null) return;

      if (options?.interactionOnly) {
        window.newrelic.interaction().setAttribute(name, value);
      } else {
        window.newrelic.setCustomAttribute(name, value);
      }
    });
  });
}
