import type { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import * as Tabs from '@radix-ui/react-tabs';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

const S = {
  TabsRoot: styled(Tabs.Root)`
    margin-top: ${spacing.S};

    @media screen and (max-width: 400px) {
      margin-top: ${spacing.XXXL};
    }
  `,
  TabList: styled(Tabs.List)`
    border-bottom: solid ${colours.BLACK} 1px;
    @media ${media.greaterThan('lg')} {
      border-bottom: none;
    }
  `,
  ResultsCounter: styled.div`
    ${TypographyStyles.Body.Tiny.SemiBold}
    margin-left: auto;
  `,
  TabTrigger: styled(Tabs.Trigger)`
    ${TypographyStyles.Body.Small.Regular}
    background-color: inherit;
    border: none;
    color: ${colours.BLACK};
    min-width: 80px;
    @media ${media.greaterThan('lg')} {
      margin-right: ${spacing.XXXXS};
      padding: 0 ${spacing.XXXS};
    }

    &[data-state='active'] {
      border-bottom: solid ${colours.BLACK} 2px;
      cursor: default;
      font-weight: 600;
      ${TypographyStyles.Body.Small.SemiBold}
      @media ${media.greaterThan('lg')} {
        border-bottom-width: 1px;
      }
    }
    a {
      color: ${colours.BLACK};
      text-align: center;
      text-decoration: none;
    }
  `,
  TabContent: styled(Tabs.Content)`
    margin: ${spacing.M} 0 0;
    width: 100%;
    &:focus-visible {
      outline-color: ${colours.OUTLINE_BORDER};
      outline-width: 2px;
    }
  `,
  TopContainer: styled.div<{ $alignRight?: boolean }>`
    align-items: baseline;
    display: flex;
    justify-content: flex-start;

    @media ${media.greaterThan('lg')} {
      justify-content: ${({ $alignRight }) => ($alignRight ? 'flex-end' : 'flex-start')};
    }
  `,
};

export interface StoreListTabSwitcherProps {
  listViewTab: ReactNode;
  mapViewTab: ReactNode;
  activeTab?: string;
  setActiveTab?: (value: string) => void;
  navAlignRight?: boolean;
  resultsCount?: number;
}

export const listViewTabId = 'listViewTab';
export const mapViewTabId = 'mapViewTab';

export function StoreListTabSwitcher({
  listViewTab,
  mapViewTab,
  activeTab,
  setActiveTab,
  navAlignRight,
  resultsCount,
}: StoreListTabSwitcherProps) {
  const { t } = useTranslation(['lib-global-common']);
  const listViewTitle = t('stores.list.view');
  const mapViewTitle = t('stores.map.view');
  const results = resultsCount === undefined ? null : t('stores.results', { count: resultsCount });

  return (
    <S.TabsRoot defaultValue={listViewTabId} value={activeTab} onValueChange={setActiveTab}>
      <S.TopContainer $alignRight={navAlignRight}>
        <S.TabList>
          <S.TabTrigger aria-label={listViewTitle} value={listViewTabId} data-testid={listViewTabId}>
            {listViewTitle}
          </S.TabTrigger>
          <S.TabTrigger aria-label={mapViewTitle} value={mapViewTabId} data-testid={mapViewTabId}>
            {mapViewTitle}
          </S.TabTrigger>
        </S.TabList>
        {results && (
          <S.ResultsCounter role="status" data-testid="storesCounter">
            {results}
          </S.ResultsCounter>
        )}
      </S.TopContainer>
      <S.TabContent value={listViewTabId}>{listViewTab}</S.TabContent>
      <S.TabContent value={mapViewTabId}>{mapViewTab}</S.TabContent>
    </S.TabsRoot>
  );
}

export default StoreListTabSwitcher;
