import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GenerateSocialProviderLinkMutationVariables = Types.Exact<{
  provider: Types.SocialProvider;
  channel: Types.Channel;
  state?: Types.InputMaybe<Array<Types.Pair> | Types.Pair>;
}>;

export type GenerateSocialProviderLinkMutation = { __typename?: 'Mutation'; generateSocialProviderLink: string };

export const GenerateSocialProviderLinkDocument = gql`
  mutation generateSocialProviderLink($provider: SocialProvider!, $channel: Channel!, $state: [Pair!]) {
    generateSocialProviderLink(provider: $provider, channel: $channel, state: $state)
  }
`;
export type GenerateSocialProviderLinkMutationFn = Apollo.MutationFunction<
  GenerateSocialProviderLinkMutation,
  GenerateSocialProviderLinkMutationVariables
>;

/**
 * __useGenerateSocialProviderLinkMutation__
 *
 * To run a mutation, you first call `useGenerateSocialProviderLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSocialProviderLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSocialProviderLinkMutation, { data, loading, error }] = useGenerateSocialProviderLinkMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      channel: // value for 'channel'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGenerateSocialProviderLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSocialProviderLinkMutation,
    GenerateSocialProviderLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSocialProviderLinkMutation, GenerateSocialProviderLinkMutationVariables>(
    GenerateSocialProviderLinkDocument,
    options,
  );
}
export type GenerateSocialProviderLinkMutationHookResult = ReturnType<typeof useGenerateSocialProviderLinkMutation>;
export type GenerateSocialProviderLinkMutationResult = Apollo.MutationResult<GenerateSocialProviderLinkMutation>;
export type GenerateSocialProviderLinkMutationOptions = Apollo.BaseMutationOptions<
  GenerateSocialProviderLinkMutation,
  GenerateSocialProviderLinkMutationVariables
>;
