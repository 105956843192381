export function withNewRelic<TReturn, TArgs extends unknown[]>(
  segmentName: string,
  handler: (...args: TArgs) => TReturn,
) {
  return async (...args: TArgs) => {
    if (typeof window === 'undefined') {
      const newrelic = await import('newrelic');
      return newrelic.startSegment(segmentName, true, () => handler(...args));
    }
    return handler(...args);
  };
}
