import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { STAR1Locales } from '../utils/localeHelper';

export const useStar2 = () => {
  const { locale } = useRouter();

  const [isStar2, setIsStar2] = useState(locale && !STAR1Locales.includes(locale));

  useEffect(() => {
    setIsStar2(locale && !STAR1Locales.includes(locale));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return { isStar2 };
};
