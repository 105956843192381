import type { MutableRefObject, ReactNode } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { colours, slideToLeftTraysAnimation, spacing, zFilterMenu } from '../../stylings';
import { FocusTrap } from '../focusTrap';

const S = {
  Overlay: styled.div`
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${zFilterMenu};
  `,
  Menu: styled.menu`
    align-items: center;
    background-color: ${colours.WHITE};
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: ${spacing.L} ${spacing.S};
    width: 310px;
    ${slideToLeftTraysAnimation}
  `,
};

export type OverLayProps = {
  isOverLayOpen?: boolean;
  children?: string | ReactNode;
  testId?: string;
  handleOpenOverlay?: () => void;
  isClosing?: boolean;
};
export function OverLay({
  isOverLayOpen = false,
  children,
  testId,
  handleOpenOverlay = () => {},
  isClosing,
}: OverLayProps) {
  const menuRef = useRef(null);
  useOutsideClick(menuRef as unknown as MutableRefObject<HTMLMenuElement>, () => handleOpenOverlay());

  if (!isOverLayOpen) return null;
  return (
    <FocusTrap>
      <S.Overlay>
        <S.Menu className={isClosing ? 'is-closing' : ''} ref={menuRef} data-testid={testId}>
          {children}
        </S.Menu>
      </S.Overlay>
    </FocusTrap>
  );
}
export default OverLay;
