import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import type {
  FlexibleText as FlexibleTextSchema,
  TextAlign,
  TextTransform,
} from '@amplience/content-types/typings/p-partials';
import { TypographyStyleMapping, TypographyStylesType, media } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';
import { useColor } from '../color';
import { useTextAlign } from '../textAlign';
import { useTextTransform } from '../textTransform';

type StyledFlexibleTextProps = {
  $textSize: string;
  $defaultTextSize: string;
  $mobileTextColor?: string;
  $desktopTextColor?: string;
  $mobileTextAlign?: TextAlign;
  $desktopTextAlign?: TextAlign;
  $defaultMobileTextColor: string;
  $defaultDesktopTextColor: string;
  $textTransform?: TextTransform;
};

const S = {
  // prettier-ignore
  FlexibleText: styled.span<StyledFlexibleTextProps>`
    ${({ $mobileTextAlign }) => useTextAlign($mobileTextAlign)}
    ${({ $textSize, $defaultTextSize }) =>
      $textSize === TypographyStylesType.DEFAULT || !$textSize
        ? css`
            ${TypographyStyleMapping[$defaultTextSize]}
          `
        : css`
            ${TypographyStyleMapping[$textSize]}
          `}

    ${({ $mobileTextColor, $defaultMobileTextColor }) => useColor($mobileTextColor, $defaultMobileTextColor)}
    ${({ $textTransform }) => useTextTransform($textTransform)}

    @media ${media.greaterThan('lg')} {
      ${({ $desktopTextAlign }) => useTextAlign($desktopTextAlign)}
      ${({ $desktopTextColor, $defaultDesktopTextColor }) => useColor($desktopTextColor, $defaultDesktopTextColor)}
    }
  `,
};

export type FlexibleTextPartialProps = FlexibleTextSchema & {
  defaultTextSize: TypographyStylesType;
  defaultTextColor: string;
  defaultDesktopTextColor?: string;
  className?: string;
  children?: ReactNode;
};

export const FlexibleTextPartial = forwardRef<HTMLElement, FlexibleTextPartialProps>(
  (
    {
      text,
      tag = 'p',
      anchorId,
      textSize,
      textColor: mobileTextColor,
      desktopTextColor = mobileTextColor,
      textAlign: mobileTextAlign,
      desktopTextAlign = mobileTextAlign,
      textTransform,
      defaultTextSize,
      defaultTextColor: defaultMobileTextColor,
      defaultDesktopTextColor = defaultMobileTextColor,
      className,
      children,
      ...props
    },
    ref,
  ) => (
    <S.FlexibleText
      {...props}
      {...(!!anchorId && { id: anchorId })}
      ref={ref}
      as={tag as keyof JSX.IntrinsicElements}
      $textSize={textSize ?? ''}
      $defaultTextSize={defaultTextSize}
      $mobileTextColor={mobileTextColor}
      $desktopTextColor={desktopTextColor}
      $mobileTextAlign={mobileTextAlign}
      $desktopTextAlign={desktopTextAlign}
      $defaultMobileTextColor={defaultMobileTextColor}
      $defaultDesktopTextColor={defaultDesktopTextColor}
      $textTransform={textTransform}
      className={className}
    >
      {children || getLocalizedValue(text)}
    </S.FlexibleText>
  ),
);

FlexibleTextPartial.displayName = 'FlexibleTextPartial';
