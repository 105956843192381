import type { Breakpoint } from './breakpoints';
import { breakpoints } from './breakpoints';

function lessThan(bp: Breakpoint): string {
  return `(max-width: calc(${breakpoints[bp]}rem - 0.0625rem))`;
}

function greaterThan(bp: Breakpoint): string {
  return `(min-width: ${breakpoints[bp]}rem)`;
}

function between(minBp: Breakpoint, maxBp: Breakpoint): string {
  return `${lessThan(maxBp)} and ${greaterThan(minBp)}`;
}

export const media = {
  lessThan,
  greaterThan,
  between,
};
