import type { GetFeatureFlagsResponse } from '../../codegen/types';
import { GetFeatureFlagsDocument } from '../../graphqlDocument/featureFlag/featureFlag.generated';
import type {
  GetFeatureFlagsQuery,
  GetFeatureFlagsQueryVariables,
} from '../../graphqlDocument/featureFlag/featureFlag.generated';
import { getCtLocale } from '../../utils';
import type { ApolloClientInstance } from '../gql/apolloClient';
import { withNewRelic } from '../newrelic';

const getAppFeatureFlagsBase = async (apolloClient: ApolloClientInstance, locale: string) => {
  try {
    const ctLocale = getCtLocale(locale);
    const featureFlagsData = await apolloClient.query<GetFeatureFlagsQuery, GetFeatureFlagsQueryVariables>({
      fetchPolicy: 'no-cache',
      query: GetFeatureFlagsDocument,
      variables: {
        locale: ctLocale,
      },
    });

    const {
      data: { getFeatureFlags },
    } = featureFlagsData;

    return getFeatureFlags;
  } catch (e) {
    return {} as GetFeatureFlagsResponse;
  }
};

export const getAppFeatureFlags = withNewRelic('getAppFeatureFlags', getAppFeatureFlagsBase);
