import type { NextWebVitalsMetric } from 'next/app';
import { AttributeName } from '../types/newrelic';
import { logger } from './logger';
import { setNewRelicAttributes } from './newrelic';

const metricNameMapping = {
  'Next.js-hydration': AttributeName.NEXTJS_HYDRATION,
  'Next.js-render': AttributeName.NEXTJS_RENDER,
  'Next.js-route-change-to-render': AttributeName.NEXTJS_ROUTE_CHANGE_TO_RENDER,
};

/**
 * Passes the following 3 'custom' metrics generated by NextJs to New Relic:
 * Next.js-hydration, Next.js-route-change-to-render, Next.js-render
 * Docs: https://next-site-4vixvljn1.vercel.sh/docs/advanced-features/measuring-performance
 * @param metric Object created by NextJS and passed to our <App>'s reportWebVitals()
 * @returns void
 */
export function onReportWebVitals(metric: NextWebVitalsMetric) {
  try {
    // Interested in 'custom' metrics only; 'web-vital' ones captured separately
    if (metric.label !== 'custom') return;

    const customMetricName = Object.hasOwn(metricNameMapping, metric.name)
      ? metricNameMapping[metric.name]
      : metric.name;

    setNewRelicAttributes({
      [customMetricName]: metric.value,
    });
  } catch (error) {
    logger.error(`Error sending 'custom' web vital metric to New Relic: ${metric.name}`, error);
  }
}
