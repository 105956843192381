import type { Link } from '@amplience/content-types/typings/p-partials';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { media } from '../../stylings';

export function useGetLinkAttributes(link?: Link) {
  const isDesktop = useMediaMatch(media.greaterThan('md'));
  return {
    openInNewTab: isDesktop ? !!(link?.localizedExternalLink || link?.externalLink) : false,
  };
}

export function hasHref(link?: Link) {
  return link?.internalLink || link?.externalLink || link?.localizedInternalLink || link?.localizedExternalLink;
}

export * from './LinkPartial';
