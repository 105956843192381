import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { type RefusalReasonType, type ValidityStatus, paymentMethods } from '../types';
import { getItem } from './storage';

export const IS_GC_PAYMENT_METHOD = 'IS_GC_PAYMENT_METHOD';

export enum PaymentMethodTypes {
  CREDIT_OR_DEBIT = 'scheme',
  PAYPAL = 'paypal',
  GOOGLE_PAY = 'googlepay',
  APPLE_PAY = 'applepay',
  GIFT_CARD = 'giftcard',
  KLARNA = 'klarna',
  KLARNA_ACCOUNT = 'klarna_account',
}

const RefusalReason: RefusalReasonType = {
  Refused: 'Refused',
  Cancelled: 'Cancelled',
  Error: 'Error',
};

export function isValidOrNot(validityStatus: ValidityStatus) {
  const keys: (keyof ValidityStatus)[] = ['encryptedSecurityCode', 'encryptedExpiryDate'];
  return (
    !keys.some((key) => validityStatus[key] != null) ||
    (validityStatus.encryptedSecurityCode == null && validityStatus.encryptedExpiryDate == null)
  );
}

export const isCheckOrNot = (value?: boolean) => value === undefined || value === false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const paymentRefusalReason = (result: any) => result === RefusalReason[result];

export function RenderSelectedPayment() {
  const { t } = useTranslation(['app-orders-common', 'lib-global-common']);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  useEffect(() => {
    setSelectedPaymentMethod(getItem('CUSTOMER_PAYMENT_METHOD', true, 'localStorage') as string);
  }, []);

  switch (selectedPaymentMethod) {
    case paymentMethods.scheme:
      return t('checkout.payment.card.or.debit');
    case paymentMethods.giftcard:
      return t('checkout.giftcard');
    case paymentMethods.groupklarna:
      return t('klarna.title', { ns: 'lib-global-common' });
    default:
      return selectedPaymentMethod;
  }
}
