import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { RadioFilled } from '../../icons';
import { colours, media, spacing } from '../../stylings';

const S = {
  Label: styled.label`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    gap: 10px;
    letter-spacing: 0;
    margin: 0;
    padding: ${spacing.XXXS};

    @media screen and (min-width: 376px) {
      width: 100%;
    }

    ${media.greaterThan('lg')} {
      width: auto;
    }
  `,
  Input: styled.input`
    accent-color: ${colours.DARK_BLUE};
    appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    height: 0;
    margin: 0;
    min-height: 20px;
    min-width: 20px;
    opacity: 0;
    position: absolute;
    width: 0;

    &:focus {
      outline-color: ${colours.OUTLINE_BORDER};
      outline-width: 2px;
    }

    @media ${media.greaterThan('lg')} {
      min-height: 24px;
      min-width: 24px;
    }
  `,
  Wrapper: styled.span<{ $isChecked: boolean }>`
    ${({ $isChecked }) =>
      $isChecked
        ? css`
            background-color: transparent;
            border: 0;
          `
        : css`
            background-color: transparent;
            border: 1px solid ${colours.DARK_BLUE};
          `}
    border-radius: 50%;
    height: 24px;
    margin: 0;
    min-width: 24px;
    padding: 0;
    position: relative;
    width: 24px;

    &:focus-within {
      outline: 2px solid ${colours.OUTLINE_BORDER};
    }

    @media ${media.greaterThan('lg')} {
      height: 24px;
      min-width: 24px;
      width: 24px;
    }
  `,
  IconWrapper: styled.span`
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    svg {
      height: ${spacing.S};
      width: ${spacing.S};
    }
  `,
};

type RadioProps = Omit<JSX.IntrinsicElements['input'], 'ref'> & {
  label: ReactNode;
  register?: UseFormRegisterReturn;
  testId?: string;
  $isSelected?: boolean;
  $isDisabled?: boolean;
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ label, id, register, testId, className, checked, ...other }, ref) => (
    <S.Label className={className}>
      <S.Wrapper $isChecked={!!checked} className="radio-button-circle">
        <S.Input type="radio" ref={ref} id={id} data-testid={testId} {...other} {...register} />
        <S.IconWrapper>{checked && <RadioFilled viewBox="0 0 24 24" aria-hidden />}</S.IconWrapper>
      </S.Wrapper>
      {label}
    </S.Label>
  ),
);
