import type { RefObject } from 'react';
import { forwardRef } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import { ArrowDown } from '../../icons';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import { transformTextToId } from '../../utils/transformers';
import type { SelectProps } from './Select';

const getSvgString = () => encodeURIComponent(renderToString(<ArrowDown />));

const S = {
  Select: styled.select`
    ${TypographyStyles.Body.Medium.Regular}
    all: unset;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=utf-8,${getSvgString()}');
    background-position: right 8px center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: block;
    overflow: hidden;
    padding: 11px 30px 11px 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 44px);
    @media ${media.greaterThan('lg')} {
      padding: 9px 30px 9px 14px;
    }
    &:focus-visible {
      outline: 2px solid ${colours.OUTLINE_BORDER};
    }
  `,
  SelectLabel: styled.label`
    background-color: ${colours.WHITE};
    font-size: 12px;
    font-weight: 200;
    left: ${spacing.XXXXS};
    padding: 0 ${spacing.XXXXS};
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  `,
  SelectWrapper: styled.div`
    height: fit-content;
    position: relative;
    @media ${media.greaterThan('lg')} {
      min-height: 48px;
    }
  `,
};

export const MyAccountSelect = forwardRef(
  ({ label, children, register, defaultValue, hiddenLabel, ...others }: SelectProps, ref) => {
    const id = transformTextToId(label || register?.name);
    return (
      <S.SelectWrapper>
        {label ? (
          <S.SelectLabel htmlFor={id} className={hiddenLabel ? 'visually-hidden' : ''}>
            {label}
          </S.SelectLabel>
        ) : null}
        <S.Select
          id={id}
          ref={ref as RefObject<HTMLSelectElement>}
          {...register}
          defaultValue={defaultValue}
          {...others}
        >
          {children}
        </S.Select>
      </S.SelectWrapper>
    );
  },
);
