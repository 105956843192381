import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../stylings';

const S = {
  Container: styled.ul`
    display: flex;
    padding: 0;
  `,
  Link: styled.li`
    list-style: none;
    span.separator {
      padding: 0 ${spacing.XS};
    }
    a {
      ${TypographyStyles.Body.Tiny.Regular}
      color: ${colours.BLACK};
      text-decoration: none;
      text-transform: capitalize;

      &.is-active {
        ${TypographyStyles.Body.Tiny.SemiBold}
      }

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  `,
};

const getTranslation = (value: string) => {
  if (value === 'find-order') return 'breadcrumbs.find.order';
  if (value === 'book-a-fitting') return 'breadcrumbs.book.a.fitting';
  return '';
};

export function Breadcrumbs() {
  const { t } = useTranslation(['lib-global-common']);
  const router = useRouter();
  const { pathname } = router;
  const allRoutes = pathname.split('/');

  return (
    <nav>
      <S.Container>
        {allRoutes.map((route, idx) => {
          const isHome = route === '';
          const url = !isHome ? `${allRoutes.slice(0, idx + 1).join('/')}` : '/';
          const isLast = idx === allRoutes.length - 1;

          return (
            <S.Link key={isHome ? 'home' : route}>
              {idx > 0 && <span className="separator">{' | '}</span>}
              <Link href={url} passHref>
                <a className={isLast ? 'is-active' : ''}>
                  {isHome ? `${t('find.order.home')}` : `${t(getTranslation(route)) || route.replace(/-/g, ' ')}`}
                </a>
              </Link>
            </S.Link>
          );
        })}
      </S.Container>
    </nav>
  );
}
