import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../stylings';

const S = {
  Root: styled.div`
    margin: ${spacing.XXXXS} 0;
  `,
  ProgressBar: styled.div`
    background: linear-gradient(
      to right,
      ${colours.LIGHT_BLUE} 33%,
      ${colours.LIGHT_BLUE} 66%,
      ${colours.LIGHT_BLUE} 100%
    );
    height: ${spacing.XXXS};
    margin-top: ${spacing.XXXXXS};
    overflow: hidden;
    pointer-events: none;
    position: relative;
    transform: translateZ(0);
    width: 100%;
  `,
  ProgressBarValue: styled.div<{ $passwordScore: number; $barColor: string }>`
    background-color: ${({ $barColor }) => $barColor};
    height: ${spacing.XXXS};
    margin-bottom: ${spacing.XXXXXS};
    width: ${({ $passwordScore }) => `${$passwordScore}%`};
  `,
  ProgressBarLabel: styled.p<{ $labelColor: string }>`
    ${TypographyStyles.Body.Tiny.Medium}
    color: ${({ $labelColor }) => $labelColor};
    margin: 0;
    margin-bottom: ${spacing.XXXXXS};
  `,
};

interface PasswordStrengthBarProps {
  passwordLevelText: string[];
  realTimePassword?: string;
  isBadPassword?: boolean;
}

const color = [colours.ERROR_PRIMARY, colours.WARNING_PRIMARY, colours.LIGHT_GREEN, colours.SUCCESS_PRIMARY];

function checkPasswordStrength(password: string) {
  const upper = /[A-Z]/;
  const lower = /[a-z]/;
  const number = /[0-9]/;
  const special = /[^A-Za-z0-9]/;
  let count = 0;

  if (upper.test(password)) count += 1;
  if (lower.test(password)) count += 1;
  if (number.test(password)) count += 1;
  if (special.test(password)) count += 1;

  return count;
}

export function PasswordStrengthBar({ passwordLevelText, isBadPassword, realTimePassword }: PasswordStrengthBarProps) {
  const { t } = useTranslation(['lib-global-common']);
  const [passwordScore, setPasswordScore] = useState({ password: 0, label: '', color: '' });

  useEffect(() => {
    if ((realTimePassword && realTimePassword.length < 10) || isBadPassword) {
      setPasswordScore({
        password: 25,
        label: passwordLevelText[0],
        color: color[0],
      });
    } else {
      const score = realTimePassword ? checkPasswordStrength(realTimePassword) : 0;
      const scoreLevel = score > 1 ? score - 1 : 0;

      setPasswordScore({
        password: score * 25,
        label: realTimePassword ? passwordLevelText[scoreLevel] : '',
        color: color[scoreLevel],
      });
    }
  }, [isBadPassword, passwordLevelText, realTimePassword]);

  return (
    <S.Root>
      <S.ProgressBarLabel id="progress-bar-label" $labelColor={passwordScore.color} aria-live="polite">
        {passwordScore.label}
      </S.ProgressBarLabel>
      <S.ProgressBar
        role="meter"
        aria-label={t('register.password.minimum')}
        aria-labelledby="progress-bar-label"
        aria-valuenow={passwordScore.password}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <S.ProgressBarValue
          $passwordScore={passwordScore.password}
          $barColor={passwordScore.color}
          data-testid="passwordStrengthBar"
          aria-hidden
        />
      </S.ProgressBar>
    </S.Root>
  );
}

export default PasswordStrengthBar;
