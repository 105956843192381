import { forwardRef } from 'react';
import type { ReactNode } from 'react';
import { Trigger } from '@radix-ui/react-dialog';
import type { DialogTriggerProps as RadixTrigger } from '@radix-ui/react-dialog';

type DialogTriggerProps = RadixTrigger & {
  children: ReactNode;
  className?: string;
};

export const DialogTrigger = forwardRef<HTMLButtonElement, DialogTriggerProps>(
  ({ children, className, ...rest }, ref) => (
    <Trigger className={className} ref={ref} {...rest}>
      {children}
    </Trigger>
  ),
);
