import * as yup from 'yup';
import { PHONE_FIELD_REGEXP, RegisterSchema, EMAIL_REGEX } from '../b2c';

export const UpdateCustomer = RegisterSchema.shape({
  phone: yup
    .string()
    .max(16, 'register.validation.phone')
    .required('register.validation.empty.phone')
    .matches(PHONE_FIELD_REGEXP, 'register.validation.phone')
    .nullable(),
  password: yup.string(),
});

export const VerifyEmailFormSchema = yup.object().shape({
  currentEmail: yup
    .string()
    .required('login.validation.empty.email')
    .max(128, 'login.validation.email')
    .matches(EMAIL_REGEX, 'login.validation.email'),
  password: yup.string().required('login.validation.password').min(10, 'login.validation.min.password'),
});
