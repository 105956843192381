import type { BaseHit } from 'instantsearch.js';
import type { CentPrecisionMoney, Maybe, PrimaryCategory, ProductBadgeV2, StyleProduct } from '../codegen/types';
import { Locale, getCtLocale } from './localeHelper';

export type ProductHitTypes = {
  name: string;
  description: string;
  slug: string;
  gender: string[];
  category: string;
  fastening: string;
  technologies: string[];
  color: string;
  colors: StyleProduct[];
  key: string;
  price: CentPrecisionMoney;
  wasPrice: CentPrecisionMoney;
  thenPrice: CentPrecisionMoney;
  objectID: string;
  image: string[];
  merchandiseCategory: string;
  rangeGroupDescription: string;
  brand: string;
  primaryCategory: PrimaryCategory;
  badgesV2: ProductBadgeV2;
  styleName: string;
};

export const getLocalizedValue = <T = string, Y = unknown>(localizedValue: Y): T => localizedValue as unknown as T;

export const transformTextToId = (text?: string) =>
  text ? text.replace(/\s/g, '-').toString().toLocaleLowerCase() : '';

export const simpleMarkdownTransform = (markdown: string) =>
  markdown
    ?.replace(/(\*\*)(?!\*)(.*?)\1/g, '<strong>$2</strong>')
    ?.replace(/([*_])(?!\1)(\S[\s\S]*?\S)\1/g, '<em>$2</em>')
    ?.replace(
      /!\[(.*?)\]\((.*?)\s*("(.*?)")?\)/g,
      (_: string, p1: string, p2: string, p3: string, p4: string) =>
        `<img src="${p2}" alt="${p1}"${p3 ? ` title="${p4}"` : ''} />`,
    )
    ?.replace(
      /\[(.*?)\]\((.*?)\s*("(.*?)")?\)/g,
      (_: string, p1: string, p2: string, p3: string, p4: string) =>
        `<a href="${p2}"${p3 ? ` title="${p4}"` : ''}>${p1}</a>`,
    );

export const createAnchorTagHref = ({ locale, path }: { path: string; locale?: string }) => {
  const lowerCaseLocale = locale?.toLowerCase();
  const isOutlet =
    lowerCaseLocale === Locale.STOREFRONT_OUTLET_LOCALE || lowerCaseLocale === Locale.CT_OUTLET_LOCALE.toLowerCase();
  const relativePath = path.startsWith('/') ? path.slice(1, path.length) : path;

  if (isOutlet) {
    return `/${relativePath}`;
  }

  return `/${lowerCaseLocale}/${relativePath}`;
};

type Colors = {
  key: string;
  image: string;
  [k: string]: string;
};

export const createProductHit = ({ item, locale }: { item: BaseHit; locale: string }): ProductHitTypes => {
  const ctLocale = getCtLocale(locale);

  const {
    [`name.${ctLocale}`]: name,
    [`description.${ctLocale}`]: description,
    [`slug.${ctLocale}`]: slug,
    [`gender.${ctLocale}`]: gender,
    [`category.${ctLocale}`]: category,
    [`fastening.${ctLocale}`]: fastening,
    [`technologies.${ctLocale}`]: technologies,
    [`color.${ctLocale}`]: shortColor,
    [`mediumColor.${ctLocale}`]: mediumColor,
    [`merchandiseCategory.${ctLocale}`]: merchandiseCategory,
    [`rangeGroupDescription.${ctLocale}`]: rangeGroupDescription,
    [`brand.${ctLocale}`]: brand,
    [`productBadgesV2.${ctLocale}`]: productBadgesV2,
    [`styleName.${ctLocale}`]: styleName,
    price,
    wasPrice,
    thenPrice,
    objectID,
    image,
    images,
    colors,
    key,
    id,
    primaryCategory = {},
  } = item;

  const itemColors = colors as unknown as Colors[];
  const itemImages = images as unknown as string[];
  const transformedColors = itemColors
    ?.map((itemColor: Colors) => {
      const { key: colorKey, image: colorImage, [`color.${ctLocale}`]: localizedColor } = itemColor;
      return { key: colorKey, image: colorImage, color: localizedColor };
    })
    .filter((itemColor: StyleProduct) => itemColor.key !== objectID);
  const transformedImages = (itemImages && itemImages) || (image && [image]) || [' '];

  const { [`primaryCategory.${ctLocale}`]: primaryName, key: primaryKey } = primaryCategory as {
    [key: string]: string;
  };

  return {
    name: name as string,
    description: description as string,
    slug: slug as string,
    gender: gender as string[],
    category: category as string,
    fastening: fastening as string,
    technologies: technologies as string[],
    color: (mediumColor as string) || (shortColor as string),
    key: (key as string) || (id as string),
    price: price as CentPrecisionMoney,
    wasPrice: wasPrice as CentPrecisionMoney,
    thenPrice: thenPrice as CentPrecisionMoney,
    objectID: objectID as string,
    image: transformedImages as string[],
    colors: transformedColors as StyleProduct[],
    merchandiseCategory: merchandiseCategory as string,
    brand: brand as string,
    primaryCategory: {
      key: primaryKey || undefined,
      name: primaryName || undefined,
    },
    badgesV2: productBadgesV2 as ProductBadgeV2,
    styleName: styleName as string,
    rangeGroupDescription: rangeGroupDescription as string,
  };
};

export const transformTitleTextToID = (localizedValue: unknown | Text): string =>
  getLocalizedValue(localizedValue)?.split(' ').join('_').toLowerCase();

export const transformStringToNumber = (value: Maybe<string> | string | undefined) => {
  if (typeof value === 'number') return value;
  if (!value) return NaN;
  return parseFloat(value.match?.(/-?\d+(\.\d+)?/g)?.[0] || '');
};

export const transformStringToBase64 = (value?: string) => {
  if (!value) return '';
  return Buffer.from(value, 'utf-8').toString('base64');
};

export const transformBase64ToString = (value?: string) => {
  if (!value) return '';
  return Buffer.from(value, 'base64').toString('utf-8');
};

export const removeTrailingSlash = (urlString: string) => urlString.replace(/\/+$/, '');
