import type { AssetType } from '@amplience/content-types/typings/p-partials';
import type { DynamicMediaProps } from './ImagePartial';

const amplienceParamsMap: { [key: string]: string } = {
  format: 'fmt',
  quality: 'qlt',
  progressive: 'fmt.jpeg.interlaced',
  width: 'w',
  maxWidth: 'maxW',
  height: 'h',
  maxHeight: 'maxH',
  notFoundImage: 'img404',
};

const notFoundImage = 'imageNotFound';

const formatAutoParams = {
  format: 'auto',
  notFoundImage,
};

export interface AmplienceImageUrlsProps {
  name: string;
  defaultHost?: string;
  endpoint?: string;
  assetType?: AssetType;
  dynamicMediaProps?: DynamicMediaProps;
}

export function getAmplienceImageUrls({
  defaultHost = 'cdn.media.amplience.net',
  endpoint = 'clarks',
  name,
  assetType,
  dynamicMediaProps,
}: AmplienceImageUrlsProps) {
  if (assetType === 'static') {
    return `https://clarks.a.bigcontent.io/v1/static/${name}`;
  }

  const assetUrl = `https://${defaultHost}/i/${endpoint}/${name}`;

  const path = name.startsWith('https://') ? name : assetUrl;

  const formatAutoURL = new URL(path);

  Object.entries({ ...formatAutoParams, ...dynamicMediaProps }).forEach(([key, value]) => {
    if (value) formatAutoURL.searchParams.set(amplienceParamsMap[key], value as string);
  });

  return formatAutoURL.toString();
}

export const getSingleAmplienceImageUrl = (
  defaultHost?: string,
  endpoint?: string,
  name?: string,
  assetType?: string,
) => {
  const assetUrl = `https://${defaultHost}/i/${endpoint}/${name}`;

  const path = name?.startsWith('https://') ? name : assetUrl;

  const formatPath = new URL(path);

  return assetType === 'static' ? `https://clarks.a.bigcontent.io/v1/static/${name}` : formatPath.toString();
};

export const BROKEN_IMAGE_SRC = 'BrokenImage';
