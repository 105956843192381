import { createGlobalStyle } from 'styled-components';
import { colours } from './colours';
import { media } from './media';
import { spacing } from './spacing';
import { TypographyStyles } from './typography';

export const TableCSS = createGlobalStyle`
/* DESKTOP */
  table {
    background-color: ${colours.TABLE_BORDER};
    border: 1px solid ${colours.TABLE_BORDER};
    border-collapse: collapse;
    border-radius: 5px;
    empty-cells: show;
    margin: 0 auto;
    overflow: hidden;
    padding: 0px;
    table-layout: fixed;
    width: 100%;
    ${TypographyStyles.Body.Small.Regular}
  }
  table th {
    background-color: ${colours.TABLE_HEADING};
    border: 1px solid ${colours.TABLE_BORDER};
    color: ${colours.BLACK};
    font-weight: 400;
    padding: ${spacing.XXS} 15px;
    text-align: center;
    text-align: left;
  }
  table td {
    border: 1px solid ${colours.TABLE_BORDER};
    padding: ${spacing.XXS} 15px;
    word-break: break-word;

    a {
      color: ${colours.BLACK};
    }
  }
  table tr {
    background-color: ${colours.WHITE};
    color: ${colours.BLACK};
    text-align: left;
  }
  table td::before {
    color: ${colours.BLACK};
    content: attr(data-label);
    display: block;
    float: left;
    font-weight: 700;
    margin-right: ${spacing.XXS};
    margin-top: ${spacing.XXXXS};
    text-align: left;
    ${TypographyStyles.Body.Tiny.Regular}
    @media ${media.greaterThan('lg')} {
      content: none;
      display: none;
    }
  }
  @media screen and (max-width: 1023px) {
    /* MOBILE */
    table {
      background-color: ${colours.TABLE_BORDER};
      border: 1px solid ${colours.TABLE_BORDER};
      border-collapse: collapse;
      empty-cells: show;
      margin: 0 auto;
      padding: 0px;
      table-layout: fixed;
      width: 100%;
      ${TypographyStyles.Body.Small.Regular}
    }
    table td::before {
      font-weight: 700;
    }
    table td {
      border: 1px solid ${colours.TABLE_BORDER};
      display: block;
      float: left\9;
      padding: ${spacing.XXXS};
      text-align: left;
      width: 100%\9;
      word-break: break-word;
    }
    table tr {
      background-color: ${colours.WHITE};
      color: ${colours.BLACK};
      display: block;
      text-align: left;
    }
    thead {
      display: none;
    }
    span {
      br {
        content: '';
        &::after {
          content: ' ';
        }
      }
    }
  }
`;
