import { isStar2Check } from '../../utils/globalEUtils';
import { GTMZoneIds } from '../../utils/localeHelper';

export function GTMBody({ locale }: { locale: string }) {
  const GTM_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;
  const GTM_STAR2_ID = process.env.NEXT_PUBLIC_GTM_STAR2_CONTAINER_ID;

  return (
    <>
      <noscript>
        <iframe
          title="gtm-full-site"
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>

      {isStar2Check({ locale }) ? (
        <noscript>
          <iframe
            title="gtm-star2-site"
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_STAR2_ID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      ) : (
        <noscript>
          <iframe
            title="gtm-zone"
            src={`https://www.googletagmanager.com/ns.html?id=${GTMZoneIds[locale as string]}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
    </>
  );
}

export default GTMBody;
