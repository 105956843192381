import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

export const RecaptchaContext = createContext({
  enableRecaptchaScript: false,
  handleEnableRecaptchaScript: () => {},
});

export const useRecaptchaContext = () => useContext(RecaptchaContext);

export function RecaptchaProvider({ children }: { children: ReactNode }) {
  const [enableRecaptchaScript, setEnableRecaptchaScript] = useState(false);

  const handleEnableRecaptchaScript = () => {
    setEnableRecaptchaScript(true);
  };

  const value = useMemo(
    () => ({
      enableRecaptchaScript,
      handleEnableRecaptchaScript,
    }),
    [enableRecaptchaScript],
  );

  return <RecaptchaContext.Provider value={value}>{children}</RecaptchaContext.Provider>;
}
