import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import type { FooterAccordionLink } from '@amplience/content-types/typings/c-footer-accordion';
import { ArrowDownSmall } from '../../icons';
import { TypographyStyles, accordionAnimation, accordionArrowAnimation, colours, spacing } from '../../stylings';
import { transformTextToId } from '../../utils/transformers';
import { AccordionLinkLabel } from './FooterAccordion';

export const footerAccordionOpen = keyframes`
    from {
       margin-bottom: unset;
       max-height: 0;
    }

    to {
      margin-bottom: ${spacing.XXS};
      max-height: 2000px;
    }
`;

export const footerAccordionClose = keyframes`
    from {
       margin-bottom: ${spacing.XXS};
       max-height: 2000px;
    }

    to { 
       margin-bottom: unset;
       max-height: 0;
    }
`;

const S = {
  MobileAccordionHeader: styled.button<{ $isAccordionOpen: boolean }>`
    align-items: center;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 23px ${spacing.XXXS} 23px 0;
    width: 100%;
    ${({ $isAccordionOpen }) => accordionArrowAnimation($isAccordionOpen)}
    svg {
      display: inline-block;
      height: 5.73px;
      width: 9.75px;
    }
  `,
  MobileAccordionHeaderText: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
    color: ${colours.BLACK};
    overflow-wrap: anywhere;
  `,
  MobileAccordionLinks: styled.ul<{ $isAccordionOpen: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${spacing.XXXS};
    ${({ $isAccordionOpen }) => accordionAnimation($isAccordionOpen, footerAccordionOpen, footerAccordionClose)}
    margin: 0;
    overflow: hidden;
    padding: 0;

    @media (prefers-reduced-motion) {
      display: ${({ $isAccordionOpen }) => ($isAccordionOpen ? 'flex' : 'none')};
      margin-bottom: ${({ $isAccordionOpen }) => ($isAccordionOpen ? `${spacing.XXS}` : 'unset')};
    }
  `,
  MobileAccordionCommonLinkItem: styled.li`
    ${TypographyStyles.Body.Small.Regular}
    overflow-wrap: anywhere;

    a {
      color: ${colours.BLACK};
      text-decoration: none;
    }
  `,
};

export type MobileFooterAccordionProps = {
  title: string;
  commonLinks: FooterAccordionLink[];
};

export function MobileFooterAccordion({ title, commonLinks }: MobileFooterAccordionProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => setIsAccordionOpen((prev) => !prev);

  return (
    <>
      <S.MobileAccordionHeader
        onClick={toggleAccordion}
        aria-labelledby={`${transformTextToId(title)}-accordion-header-button`}
        aria-expanded={isAccordionOpen}
        aria-controls={isAccordionOpen ? `footer-links-${transformTextToId(title)}` : undefined}
        type="button"
        $isAccordionOpen={isAccordionOpen}
      >
        <S.MobileAccordionHeaderText id={`${transformTextToId(title)}-accordion-header-button`}>
          {title}
        </S.MobileAccordionHeaderText>
        <ArrowDownSmall aria-hidden className="accordion-arrow-down" />
      </S.MobileAccordionHeader>

      <S.MobileAccordionLinks $isAccordionOpen={isAccordionOpen} id={`footer-links-${transformTextToId(title)}`}>
        {commonLinks?.map(({ label, link }, index) => (
          <S.MobileAccordionCommonLinkItem key={`${label}-${index}`}>
            <AccordionLinkLabel link={link} label={label} />
          </S.MobileAccordionCommonLinkItem>
        ))}
      </S.MobileAccordionLinks>
    </>
  );
}
