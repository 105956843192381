import { useState } from 'react';
import { useIntersectionObserverRef } from 'rooks';

export function useIsComponentInView() {
  const [isInView, setIsInView] = useState(false);
  const callback = (entries: { isIntersecting: boolean | ((prevState: boolean) => boolean) }[]) => {
    if (entries && entries[0]) {
      setIsInView(entries[0].isIntersecting);
    }
  };
  const [componentRef] = useIntersectionObserverRef(callback);

  return { componentRef, isInView };
}
