import type { ReactNode } from 'react';
import styled from 'styled-components';
import { TypographyStyleMapping } from '../../stylings';
import type { TypographyStylesType } from '../../stylings';

const S = {
  Typography: styled.span<{ $fontStyle: string }>`
    ${({ $fontStyle }) => ($fontStyle ? TypographyStyleMapping[$fontStyle] : null)}
  `,
};

export type StyledTypographyProps = {
  children?: ReactNode;
  fontStyle: keyof typeof TypographyStylesType;
  /**
   * HTML DOM element to render
   */
  tag: keyof JSX.IntrinsicElements;
};

export function StyledTypography({ children, fontStyle, tag }: StyledTypographyProps) {
  return (
    <S.Typography as={tag} $fontStyle={fontStyle}>
      {children}
    </S.Typography>
  );
}
