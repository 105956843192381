import styled from 'styled-components';
import { TypographyStyles, colours, spacing } from '../../../stylings';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XS};
`;

export const ShipmentPart = styled.p`
  ${TypographyStyles.Body.Small.SemiBold}
  margin: 0;
  padding: 0;
`;

export const ShipmentDetails = styled.div``;

export const ShipmentPartOf = styled.p`
  margin: 0;
  padding: 0;
`;

export const ShipmentStatus = styled.h5<{ $color: string }>`
  ${TypographyStyles.Headings.H5}
  color: ${({ $color }) => {
    if ($color === 'green') {
      return colours.SUCCESS_PRIMARY;
    }
    if ($color === 'red') {
      return colours.ERROR_PRIMARY;
    }
    return colours.NAVY_BLUE;
  }};
  margin: 0;
  padding: 0;
`;

export const ShipmentDeliveryExpected = styled.p`
  margin: 0;
  padding: 0;
`;
