import type { ChangeEvent, InputHTMLAttributes, RefObject } from 'react';
import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled, { css } from 'styled-components';
import { Search, Target } from '../../icons';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

const HEIGHT_MOBILE = '44px';
const HEIGHT_DESKTOP = '50px';

export interface LocationInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isCurrentLocation: boolean;
  onCurrentLocation: (position: GeolocationPosition | null) => void;
  onLocationQueryChange: (query: string) => void;
  isInputError?: boolean;
  isRequired?: boolean;
}

const S = {
  Container: styled.div`
    height: ${HEIGHT_MOBILE};
    margin-bottom: ${spacing.XXXS};
    position: relative;
    width: 100%;

    @media ${media.greaterThan('lg')} {
      height: ${HEIGHT_DESKTOP};
    }
  `,
  IconWrapper: styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    left: ${spacing.XXS};
    pointer-events: none;
    position: absolute;
    top: 0;
  `,
  Input: styled.input<{ $isInputError?: boolean }>`
    ${TypographyStyles.Body.Medium.Regular}

    appearance: none;
    border: 1px solid ${({ $isInputError }) => ($isInputError ? colours.ERROR_PRIMARY : colours.GREY)};
    border-radius: 0;
    height: 100%;
    padding: 0 ${HEIGHT_MOBILE} 0 ${spacing.L};
    width: 100%;

    &::placeholder {
      color: ${colours.GREY};
    }

    &:focus {
      border-color: ${({ $isInputError }) => ($isInputError ? colours.ERROR_PRIMARY : colours.DARK_GREY)};
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    @media ${media.greaterThan('lg')} {
      padding-right: ${HEIGHT_DESKTOP};
    }
  `,
  Button: styled.button<{ $isLoading?: boolean }>`
    align-items: center;
    background-color: inherit;
    border: none;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: ${HEIGHT_MOBILE};

    @keyframes blink {
      50% {
        opacity: 0.25;
      }
    }

    ${({ $isLoading }) =>
      $isLoading &&
      css`
        animation: blink 1s linear infinite;
      `}

    @media ${media.greaterThan('lg')} {
      width: ${HEIGHT_DESKTOP};
    }
  `,
};

export const LocationInput = forwardRef(
  (
    {
      onCurrentLocation,
      onLocationQueryChange,
      isCurrentLocation,
      isInputError,
      isRequired,
      ...props
    }: LocationInputProps,
    ref,
  ) => {
    const { t } = useTranslation(['lib-global-common']);
    const locateTitle = t('location.input.current.location.title');
    /* const locationInputRef = useRef<HTMLInputElement>(null); */
    const [isLoading, setIsLoading] = useState(false);

    const handleLocate = useCallback(() => {
      setIsLoading(true);
      navigator.geolocation?.getCurrentPosition(
        (position) => {
          (ref as RefObject<HTMLInputElement>)?.current?.blur();
          setIsLoading(false);
          onLocationQueryChange('');
          onCurrentLocation(position);
        },
        // disabling because this browser API method doesn't have a promise version
        // eslint-disable-next-line promise/prefer-await-to-callbacks
        (error) => {
          window.console.error('Unable to retrieve location: ', error);
          setIsLoading(false);
        },
      );
    }, [setIsLoading, onLocationQueryChange, onCurrentLocation, ref]);

    const handleLocationFocus = () => {
      onCurrentLocation(null);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onLocationQueryChange(event.target.value);
    };

    const placeholder = `${t('location.input.placeholder')}${isRequired ? '*' : ''}`;

    return (
      <S.Container data-cs-mask>
        <S.IconWrapper aria-hidden>
          <Search />
        </S.IconWrapper>
        <S.Input
          ref={ref as RefObject<HTMLInputElement>}
          aria-label={placeholder}
          placeholder={placeholder}
          $isInputError={isInputError}
          onFocus={handleLocationFocus}
          onChange={handleChange}
          {...props}
          value={isCurrentLocation ? t('location.input.current.location') : props.value}
          data-testid="locationInput"
        />
        <S.Button
          type="button"
          aria-label={locateTitle}
          title={locateTitle}
          onClick={handleLocate}
          $isLoading={isLoading}
        >
          <Target />
        </S.Button>
      </S.Container>
    );
  },
);
