import { forwardRef } from 'react';
import { Content } from '@radix-ui/react-accordion';
import type { AccordionContentProps } from '@radix-ui/react-accordion';

export const AccordionContent = forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ children, ...props }, forwardedRef) => (
    <Content {...props} ref={forwardedRef}>
      {children}
    </Content>
  ),
);

export default AccordionContent;
