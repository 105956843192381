import styled from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';
import { SemiBoldValue } from '../../../utils';

export const Container = styled.div`
  background-color: ${colours.LIGHT_GREY_2};
  left: -${spacing.S};
  padding: ${spacing.S};
  position: relative;
  width: calc(100% + 48px);
  @media ${media.greaterThan('lg')} {
    left: 0;
    padding: ${spacing.M};
    width: initial;
  }
`;
export const Title = styled.h3`
  ${TypographyStyles.Headings.H3}
  margin: 0;
  padding: 0;
  text-transform: uppercase;
`;
export const Info = styled.div`
  display: grid;
  gap: ${spacing.XS};
  grid-template-areas: 'discounts' 'subTotal' 'delivery' 'totalDiscounts';
  grid-template-columns: repeat(1, 1fr);
  left: -${spacing.S};
  padding: ${spacing.S} ${spacing.S} 15px ${spacing.S};
  position: relative;
  width: calc(100% + 48px);
  @media ${media.greaterThan('lg')} {
    gap: ${spacing.XXS};
  }
`;
export const DiscountsRootContainer = styled.div`
  ${SemiBoldValue}
  display: flex;
  grid-area: discounts;
  justify-content: space-between;
  width: 100%;
`;
export const LozengeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing.XXXS};
`;
export const Lozenge = styled.div`
  ${TypographyStyles.Body.Tiny.Regular}
  align-items: center;
  background-color: ${colours.NAVY_BLUE};
  border: none;
  color: ${colours.WHITE};
  display: flex;
  max-width: fit-content;
  padding: 0 6px;
  text-transform: uppercase;
`;
export const Delivery = styled.div<{ $isDiscountsAvailable?: boolean }>`
  ${SemiBoldValue}
  display: flex;
  grid-area: ${({ $isDiscountsAvailable }) => ($isDiscountsAvailable ? 'delivery' : 'totalDiscounts')};
  justify-content: space-between;
  width: 100%;
`;
export const SubTotal = styled.div<{ $isDiscountsAvailable?: boolean }>`
  ${SemiBoldValue}
  display: flex;
  grid-area: ${({ $isDiscountsAvailable }) => ($isDiscountsAvailable ? 'subTotal' : 'delivery')};
  justify-content: space-between;
  width: 100%;
`;
export const TotalDiscounts = styled.div`
  ${SemiBoldValue}
  display: flex;
  grid-area: totalDiscounts;
  justify-content: space-between;
  width: 100%;
`;
export const Total = styled.div`
  ${SemiBoldValue}
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.XS};
`;
