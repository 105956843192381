import { useCallback, useEffect, useState } from 'react';
import { useAgilOneTokenLazyQuery } from '../graphqlDocument/agilOne/agilOneToken.generated';
import { getItem, setItem } from '../utils/storage';

type AgilOneResponse = {
  A1_auth: string;
  A1_tid: number;
};

export const AGIL_TOKEN_CALL_TIMEOUTS = [0, 10_000, 300_000, 600_000];

const useGetAgileOneToken = (isMounted: boolean, locale?: string) => {
  const [getAgilToken] = useAgilOneTokenLazyQuery();

  const getAgileOneTokenLocale = () => getItem('agileOneTokenCallLocale', true, 'sessionStorage');
  const setAgileOneTokenLocale = (tokenLocale: string) => {
    setItem('agileOneTokenCallLocale', tokenLocale, true, 'sessionStorage');
  };

  const getAgileOneTokenInSessionStorage = () => getItem('clientAgileOneToken', true, 'sessionStorage');
  const setAgileOneTokenInSessionStorage = (sessionToken: AgilOneResponse | boolean) => {
    setItem('clientAgileOneToken', sessionToken, true, 'sessionStorage');
  };

  const getAgileOneTokenCallTries = () => +getItem('agileOneTokenCallTries', true, 'sessionStorage') || 0;
  const setAgileOneTokenCallTries = (tries: number) => setItem('agileOneTokenCallTries', tries, true, 'sessionStorage');

  const [agilTokenCallsCount, setAgilTokenCallsCount] = useState(getAgileOneTokenCallTries());

  const agilToken = useCallback(async () => {
    try {
      const response = await getAgilToken({
        variables: {
          locale: locale as string,
          refresh: false,
        },
      });
      const { agilOneToken } = response?.data ?? {};
      if (agilOneToken) {
        setAgileOneTokenInSessionStorage({
          A1_auth: agilOneToken.accessKey || '',
          A1_tid: agilOneToken.tenantId || 0,
        });
        setAgileOneTokenLocale(locale || '');
      } else {
        setAgileOneTokenCallTries(agilTokenCallsCount + 1);
        setAgilTokenCallsCount((prevState) => prevState + 1);
      }
    } catch (e) {
      setAgileOneTokenCallTries(agilTokenCallsCount + 1);
      setAgilTokenCallsCount((prevState) => prevState + 1);
      console.error('Fetch AgilOneToken error', e);
    }
  }, [getAgilToken, agilTokenCallsCount, locale]);

  useEffect(() => {
    const tokenInSessionStorage = getAgileOneTokenInSessionStorage();
    const agileOneTokenLocale = getAgileOneTokenLocale();
    if (isMounted && (!tokenInSessionStorage || (tokenInSessionStorage && agileOneTokenLocale !== locale))) {
      // if this is not the first call, because of any error from the previous call
      // delay next call regarding AGIL_TOKEN_CALL_TIMEOUTS, but not bigger than its max index
      const timeoutIndex =
        agilTokenCallsCount >= AGIL_TOKEN_CALL_TIMEOUTS.length - 1
          ? AGIL_TOKEN_CALL_TIMEOUTS.length - 1
          : agilTokenCallsCount;
      if (agilTokenCallsCount > 0) {
        setTimeout(() => {
          agilToken();
        }, AGIL_TOKEN_CALL_TIMEOUTS[timeoutIndex]);
      } else {
        agilToken();
      }
    }
  }, [agilToken, agilTokenCallsCount, isMounted, locale]);
};

export default useGetAgileOneToken;
