import type { ComponentType, Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { TypographyStyles, media, spacing } from '../../stylings';
import type { CommonStoreItemProps, HandleCheckAvailabilityType, StoreItemProps } from '../../types';
import type { LatLng } from '../../types/store';
import { Loader } from '../Loader/Loader';
import type { StoreMapMarker } from './StoreMap';
import { StoreMap } from './StoreMap';

export interface StoreMapViewProps {
  storesItems: CommonStoreItemProps[];
  itemComponent: ComponentType<StoreItemProps>;
  isStoresLoading?: boolean;
  activeId: string;
  setActiveId: Dispatch<SetStateAction<string>>;
  searchLocation?: {
    lat: number;
    lng: number;
  } | null;
}

const S = {
  SelectedItem: styled.div`
    margin: ${spacing.XS} 0;

    @media ${media.greaterThan('lg')} {
      margin-top: ${spacing.M};
    }
  `,
  NoResultsMessage: styled.div`
    ${TypographyStyles.Body.Medium.SemiBold}

    margin: ${spacing.XS} 0;
    text-align: center;
  `,
};

/**
 * Generic stores map view component. When no store items provided, will display "No results found" message.
 * If searchLocation is provided, it will fit the nearest store and search location on the map.
 * If searchLocation is not provided, the map will simply fit the viewport to display all stores.
 * storeItems are expected to be sorted by distance, so "nearest store" is basically first store in the array.
 */
export function StoreMapView({
  storesItems = [],
  itemComponent,
  isStoresLoading = false,
  activeId,
  setActiveId,
  searchLocation,
  handleCheckAvailability,
}: StoreMapViewProps & HandleCheckAvailabilityType) {
  const { t } = useTranslation('lib-global-common');

  const StoreItem = itemComponent;
  const stores: Array<StoreMapMarker> = useMemo(
    () =>
      storesItems
        .filter((s) => s.latitude && s.longitude)
        .map((s, index) => ({
          id: s.id,
          mapLabel: (index + 1).toString().padStart(2, '0'),
          lat: s.latitude || 0,
          lng: s.longitude || 0,
        })),
    [storesItems],
  );

  const selectedStore = useMemo(() => storesItems.find((s) => s.id === activeId), [activeId, storesItems]);
  const zoomedMarkerLocation = { lat: selectedStore?.latitude, lng: selectedStore?.longitude } as unknown as LatLng;

  if (isStoresLoading) {
    return <Loader isDark />;
  }

  return (
    <>
      <StoreMap
        stores={stores}
        selectedStoreId={activeId}
        onStoreSelect={setActiveId}
        defaultLocation={searchLocation}
        fitType={searchLocation ? 'center-point' : 'markers'}
        zoomedMarkerLocation={zoomedMarkerLocation}
      />
      {selectedStore && (
        <S.SelectedItem>
          <StoreItem
            key={selectedStore.id}
            id={selectedStore.id}
            appointeddStoreId={selectedStore.appointeddStoreId}
            displayName={selectedStore.displayName}
            address={selectedStore.address}
            distance={selectedStore.distance}
            storeType={selectedStore.storeType}
            openingHours={selectedStore.openingHours}
            features={selectedStore.features}
            stockStatus={selectedStore.stockStatus}
            storeTags={selectedStore.storeTags}
            timezone={selectedStore.timezone}
            inStorePickupOption={selectedStore.inStorePickupOption}
            isCollectionPoint={selectedStore.isCollectionPoint}
            isActiveStore
            handleCheckAvailability={handleCheckAvailability}
          />
        </S.SelectedItem>
      )}
      {!storesItems.length && <S.NoResultsMessage>{t('map.no.results.found')}</S.NoResultsMessage>}
    </>
  );
}
