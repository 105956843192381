import type { ReactNode } from 'react';
import { useEffect } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from '@shared/validation/src/storefront/login';
import { BlueButton } from '../../baseComponents/button';
import { FieldContainer } from '../../baseComponents/containers/FieldContainer';
import { Form } from '../../baseComponents/containers/Form';
import { ErrorMessage } from '../../baseComponents/errors';
import { Checkbox } from '../../baseComponents/inputs/Checkbox';
import { Fieldset } from '../../baseComponents/inputs/Fieldset';
import type { FormValues } from '../../baseComponents/inputs/Input';
import { InputField, PasswordField } from '../../baseComponents/inputs/Input';
import type { SignInResponse } from '../../codegen/types';
import { SocialProvider } from '../../codegen/types';
import { useLocaleContext } from '../../context/localeContext';
import { useMountRecaptchaScript } from '../../hooks/useMountRecaptchaScript';
import { Apple, Facebook, Google } from '../../icons';
import type { BffError } from '../../services/errors';
import { TypographyStyles, colours, media, spacing } from '../../stylings';
import type { UserCredentials } from '../../types/user';
import { sendLoginStartEvent } from '../../utils/gtm/events/login_start/sendLoginStartEvent';
import { Locale } from '../../utils/localeHelper';
import { SocialLogins } from '../registration/SocialLogins';
import UnconfirmedEmailError from '../registration/UnconfirmedEmailError';
import { MigratedUserError } from './MigratedUserError';

const S = {
  Login: styled.div`
    display: flex;
    flex-direction: column;
    form > a {
      color: ${colours.BLACK};
      ${TypographyStyles.CallToAction.Large.Regular}
      font-weight: 600;
      @media ${media.greaterThan('lg')} {
        ${TypographyStyles.CallToAction.Large.SemiBold}
      }
    }

    fieldset {
      > *:not(:last-child) {
        margin-bottom: ${spacing.S};
      }
    }
  `,
  Form: styled(Form)`
    gap: ${spacing.S};
  `,
  Headline: styled.h1`
    margin: 0;
    ${TypographyStyles.Body.Small.Regular}
  `,
  SubmitButton: styled(BlueButton)`
    margin: 0;
    width: 100%;
  `,
  ErrorMessage: styled(ErrorMessage)`
    padding-bottom: ${spacing.XXXS};
  `,
};

export interface LoginFormProps {
  onSubmit: (data: UserCredentials) => Promise<void>;
  error: BffError | null;
  loading: boolean;
  data: SignInResponse | null;
}

const UserNotConfirmedException = 'UserNotConfirmedException';
const MigratedUserErrorType = 'userInMigrationState';
const RecaptchaError = 'BadDataError';

function UnConfirmedUser({ isNotConfirmed, email }: { isNotConfirmed?: boolean; email: string }) {
  if (!isNotConfirmed) {
    return null;
  }
  return <UnconfirmedEmailError email={email} />;
}

function MigratedUser({ isMigratedUser }: { isMigratedUser: boolean }) {
  if (!isMigratedUser) {
    return null;
  }

  return <MigratedUserError />;
}

function Headline({ children }: { children: ReactNode | string }) {
  const { locale } = useLocaleContext();

  if (![Locale.EN_IE, Locale.EN_GB].includes(locale || '')) {
    return null;
  }
  return <S.Headline data-testid="loginHeadline">{children}</S.Headline>;
}

export function LoginForm({ onSubmit, error, loading, data }: LoginFormProps) {
  const { t } = useTranslation('lib-global-common');

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors: formError },
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      rememberMe: false,
    },
  });

  const [emailValue, passwordValue, rememberMe] = useWatch({
    control,
    name: ['email', 'password', 'rememberMe'],
  });

  useEffect(() => {
    sendLoginStartEvent();
  }, []);

  const { componentRef } = useMountRecaptchaScript();
  return (
    <S.Login ref={componentRef}>
      <S.Form data-testid="loginForm" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Headline>{t('login.headline')}</Headline>
        <Fieldset label={t('account.login.details')}>
          <FieldContainer data-cs-mask>
            <InputField
              aria-label={t('login.email.address')}
              placeholder={`${t('login.email.address')}*`}
              type="email"
              aria-describedby="email-invalid"
              aria-invalid={!!formError.email}
              isInputError={!!formError.email}
              register={register('email')}
              isEmpty={!emailValue}
              testId="loginEmail"
              autoComplete="email"
              required
              maxLength={128}
            />
            <ErrorMessage id="email-invalid" data-testid="loginEmailError" {...formError?.email}>
              {t(formError?.email?.message as string)}
            </ErrorMessage>
            <UnConfirmedUser
              isNotConfirmed={error?.errorType === UserNotConfirmedException}
              email={getValues('email')}
            />
          </FieldContainer>

          <FieldContainer data-cs-mask>
            <PasswordField
              aria-label={t('login.password')}
              placeholder={`${t('login.password')}*`}
              aria-describedby="password-invalid"
              aria-invalid={!!formError.password}
              isInputError={!!formError.password}
              register={register('password')}
              isEmpty={!passwordValue}
              testId="loginPassword"
              autoComplete="current-password"
              required
            />
            <ErrorMessage id="password-invalid" data-testid="loginEmailError" {...formError?.password}>
              {t(formError?.password?.message as string)}
            </ErrorMessage>
            {error &&
              error?.errorType !== UserNotConfirmedException &&
              error?.errorType !== MigratedUserErrorType &&
              error?.errorType !== RecaptchaError && (
                <ErrorMessage id="login-invalid" data-testid="loginPasswordError">
                  {t('login.password.error')}
                </ErrorMessage>
              )}
            <MigratedUser isMigratedUser={error?.errorType === MigratedUserErrorType} />
          </FieldContainer>
        </Fieldset>

        <Checkbox
          label={t('login.remember.me')}
          register={register('rememberMe')}
          id="remember-me"
          testId="ckbRememberMe"
          checked={rememberMe}
        />

        <Link href="/reset-password" data-testid="linkForgotPassword">
          {t('login.forgotten.password')}
        </Link>
        <div>
          {error?.errorType === RecaptchaError && (
            <S.ErrorMessage id="recaptcha-invalid">{t('recaptcha.error.message')}</S.ErrorMessage>
          )}
          <S.SubmitButton
            type="submit"
            aria-label={t('login.button')}
            disabled={loading || !!data}
            data-testid="loginButton"
          >
            {t('login.button')}
          </S.SubmitButton>
        </div>
      </S.Form>

      <SocialLogins
        socials={[
          {
            label: 'login.social.apple',
            element: <Apple />,
            provider: SocialProvider.SIGNINWITHAPPLE,
          },
          {
            label: 'login.social.google',
            element: <Google />,
            provider: SocialProvider.GOOGLE,
          },
          {
            label: 'login.social.facebook',
            element: <Facebook />,
            provider: SocialProvider.FACEBOOK,
          },
        ]}
        isFromLogin
      />
    </S.Login>
  );
}
