import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useStar2 } from '../../hooks/useStar2';
import { colours, media } from '../../stylings';
import { countryMapper } from '../../utils/mapUtils';

const markerIconProps: google.maps.Symbol = {
  path: 'M -18,-18 h 36 v 36 h -36 v -36', // draw a square from -18,-18 to 18,18 where 0,0 is marker center
  fillOpacity: 1,
  scale: 1,
  strokeWeight: 2, // lesser values look blurry
  fillColor: colours.WHITE,
  strokeColor: colours.BLACK,
};

const selectedMarkerIconProps: google.maps.Symbol = {
  ...markerIconProps,
  fillColor: colours.DARK_BLUE,
  strokeColor: colours.WHITE,
};

const labelFont = {
  fontSize: '14px',
  fontFamily: "'Montserrat', sans-serif",
};

const containerStyle = {
  width: '100%',
  height: '100%',
};

const S = {
  Container: styled.div<{
    $height?: string;
    $maxHeight?: string;
    $mobileNoBorder?: boolean;
    $desktopNoBorder?: boolean;
  }>`
    border: ${({ $mobileNoBorder }) => ($mobileNoBorder ? 'none' : `2px solid ${colours.MID_GREY}`)};
    height: ${({ $height }) => $height};
    max-height: ${({ $maxHeight }) => $maxHeight};
    width: 100%;

    @media ${media.greaterThan('lg')} {
      border: ${({ $desktopNoBorder }) => ($desktopNoBorder ? 'none' : `2px solid ${colours.MID_GREY}`)};
    }
  `,
};

const options: google.maps.MapOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  restriction: {
    latLngBounds: { north: 85, south: -85, west: -180, east: 180 },
    strictBounds: true,
  },
};

const worldwidePoint = {
  // a neutral point between North America and Europe
  lat: 36,
  lng: -40,
};
const defaultZoom = 10;

export type MapFitType = 'markers' | 'center-point';

export interface StoreMapMarker {
  lat: number;
  lng: number;
  mapLabel: string;
  zIndex?: number;
  id: string;
  icon?: google.maps.Icon;
}

export interface StoreMapProps {
  stores?: Array<StoreMapMarker>;
  selectedStoreId?: string;
  defaultLocation?: {
    lat: number;
    lng: number;
  } | null;
  onStoreSelect?: (id: string) => void;
  height?: string;
  fitType?: MapFitType;
  maxHeight?: string;
  desktopNoBorder?: boolean;
  mobileNoBorder?: boolean;
  zoomedMarkerLocation?: {
    lat: number;
    lng: number;
  };
  mapId?: string;
}

/**
 * Renders a Google Map containing clickable stores as markers.
 * Map bounds are automatically fit to provided stores.
 * If no stores provided, defaultLocation will be used.
 * If no stores and defaultLocation not provided, map will stay in current locale view.
 */
export function StoreMap({
  stores = [],
  selectedStoreId,
  defaultLocation,
  onStoreSelect,
  height = '290px',
  fitType = 'markers',
  maxHeight,
  desktopNoBorder = false,
  mobileNoBorder = false,
  zoomedMarkerLocation,
  mapId,
}: StoreMapProps) {
  const { t } = useTranslation(['lib-global-common']);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
  });
  const { locale } = useRouter();
  const { isStar2 } = useStar2();
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const mapLoaded = isLoaded && map !== null;

  const onLoad = useCallback((m: google.maps.Map) => {
    setMap(m);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onMarkerClick = useCallback(
    (id: string) => {
      if (onStoreSelect) {
        onStoreSelect(id);
      }
    },
    [onStoreSelect],
  );

  useEffect(() => {
    map?.setCenter(zoomedMarkerLocation as unknown as google.maps.LatLng | google.maps.LatLngLiteral);
    map?.setZoom(20);
  }, [zoomedMarkerLocation, map]);

  useEffect(() => {
    const configureMapViewportForEmptyStoreList = () => {
      if (mapLoaded) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: countryMapper[locale || 'en-gb'] }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results?.length) {
            map.setCenter(results[0].geometry.location);
            map.fitBounds(results[0].geometry.viewport);
          }
        });
      }
    };

    if (!stores.length && !isStar2) {
      configureMapViewportForEmptyStoreList();
    }
  }, [stores, locale, map, mapLoaded, isStar2]);

  useEffect(() => {
    const configureMapViewportForEmptyStoreList = () => {
      if (mapLoaded) {
        const centerOfEurope = { lat: 54.526, lng: 15.2551 };
        map.setCenter(centerOfEurope);
        map.setZoom(4);
      }
    };

    if (!stores.length && isStar2) {
      configureMapViewportForEmptyStoreList();
    }
  }, [isStar2, map, mapLoaded, stores.length]);

  useEffect(() => {
    const configureCenterPointMap = () => {
      if (mapLoaded) {
        map.setZoom(zoomedMarkerLocation ? 16 : defaultZoom);

        const eventListener = google.maps.event.addListenerOnce(map, 'idle', () => {
          // assuming stores are sorted by distance
          const nearestStoreLocation = zoomedMarkerLocation || {
            lat: stores[0].lat,
            lng: stores[0].lng,
          };
          const nearestStoreVisible = map.getBounds()?.contains(nearestStoreLocation);
          if (!nearestStoreVisible) {
            const b = new google.maps.LatLngBounds();

            if (defaultLocation) {
              b.extend(defaultLocation);
            }
            b.extend(nearestStoreLocation);

            map.fitBounds(b);
          }
        });

        return () => {
          eventListener.remove();
        };
      }

      return undefined;
    };

    const configureMarkersMap = () => {
      if (mapLoaded) {
        if (stores.length === 1) {
          const [{ lat, lng }] = stores;
          map.setCenter({ lat, lng });
          map.setZoom(16);
        } else {
          const b = new google.maps.LatLngBounds();
          stores.forEach(({ lat, lng }) => {
            b.extend({ lat, lng });
          });
          map.fitBounds(b);
        }
      }
    };

    const configureMapViewportForPopulatedStoreList = () => {
      if (fitType === 'markers') {
        configureMarkersMap();
      } else {
        return configureCenterPointMap();
      }

      return undefined;
    };

    if (stores.length) {
      return configureMapViewportForPopulatedStoreList();
    }

    return undefined;
  }, [defaultLocation, fitType, map, mapLoaded, stores, zoomedMarkerLocation]);

  const markers = useMemo(
    () =>
      stores.map(({ id, lat, lng, mapLabel, icon, zIndex }) => {
        const isSelected = selectedStoreId === id;

        return (
          <MarkerF
            key={id}
            position={{ lat, lng }}
            options={{
              zIndex: zIndex && (isSelected ? 11 : zIndex),
              icon: icon || (isSelected ? selectedMarkerIconProps : markerIconProps),
              label:
                (mapLabel && {
                  color: isSelected ? colours.WHITE : colours.BLACK,
                  text: mapLabel,
                  ...labelFont,
                }) ||
                undefined,
            }}
            onClick={() => onMarkerClick(id)}
          />
        );
      }),
    [onMarkerClick, selectedStoreId, stores],
  );

  if (loadError) {
    return <S.Container>{t('map.loading.error.generic')}</S.Container>;
  }

  const hasStores = stores?.length > 0;
  // when stores are present, center point is not used because of auto fit
  const center = hasStores && fitType === 'markers' ? undefined : defaultLocation || worldwidePoint;

  return (
    <S.Container
      $height={height}
      $maxHeight={maxHeight}
      $mobileNoBorder={mobileNoBorder}
      $desktopNoBorder={desktopNoBorder}
      id={mapId}
      data-testid="storesMapContainer"
    >
      {isLoaded && (
        <GoogleMap
          options={options}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={defaultZoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {markers}
        </GoogleMap>
      )}
    </S.Container>
  );
}
