import type { MouseEvent } from 'react';
import { useTranslation } from 'next-i18next';
import { useMediaMatch } from '../../../hooks';
import { ImagePartial } from '../../../partials';
import { media } from '../../../stylings';
import { roundSaleBadgeValue } from '../../../utils';
import {
  ImagesWrapper,
  PrimaryImageWrapper,
  SaleBadge,
  SecondaryImage,
  SecondaryImagesWrapper,
} from './ProductLineItemImages.styles';

export enum ProductLineItemType {
  SINGLE_IMAGE = 'singleImage',
  MULTI_IMAGE = 'multiImage',
}

export const getNonMultiImageSize = (lineItemType: string | undefined, trueValue: string) =>
  lineItemType === ProductLineItemType.MULTI_IMAGE ? trueValue : '110px';

export default function Images({
  type,
  imageURLs,
  saleBadgeCode,
  handleClick,
  altText,
}: {
  type: ProductLineItemType;
  imageURLs: string[];
  saleBadgeCode?: string;
  handleClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  altText?: string;
}) {
  const { t } = useTranslation(['lib-global-common']);
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const multiImage = isDesktop && type === ProductLineItemType.MULTI_IMAGE && imageURLs.length >= 3;

  return (
    <ImagesWrapper>
      {multiImage && (
        <SecondaryImagesWrapper>
          {imageURLs[1] && (
            <SecondaryImage>
              <ImagePartial src={imageURLs[1]} layout="fill" />
            </SecondaryImage>
          )}
          {imageURLs[2] && (
            <SecondaryImage>
              <ImagePartial src={imageURLs[2]} layout="fill" />
            </SecondaryImage>
          )}
        </SecondaryImagesWrapper>
      )}
      <PrimaryImageWrapper $multiImage={multiImage} $type={type} onClick={handleClick}>
        <ImagePartial
          src={imageURLs[0]}
          layout="fill"
          objectFit={type === ProductLineItemType.MULTI_IMAGE && imageURLs.length < 3 ? 'contain' : 'cover'}
          altText={altText}
          role="link"
          tabIndex={0}
        />
        {!!saleBadgeCode && (
          <SaleBadge>{t('badge.sale.code.exact', { discount: roundSaleBadgeValue(saleBadgeCode) })}</SaleBadge>
        )}
      </PrimaryImageWrapper>
    </ImagesWrapper>
  );
}
