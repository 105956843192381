import { sendDataLayerEvent } from '../../utils/dataLayerInteractions';
import EventNamesType from '../eventNamesType';

type ContentDataType = {
  component_header: string;
  component_type: string;
  link_text: string;
  link_url: string;
};

export function sendSelectContentEvent(contentData: ContentDataType) {
  sendDataLayerEvent(EventNamesType.select_content, {
    custom_data: {
      ...contentData,
    },
  });
}
