import type { ReactNode } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { OverlayContextProvider } from '../../context';
import { AfterCookieBannerContextProvider } from '../../context/afterCookieBannerContext';
import { LocaleContext } from '../../context/localeContext';
import { useMasterLayoutContext } from '../../context/masterLayoutContext';
import { Locale } from '../../utils/localeHelper';
import { Footer } from '../footer/Footer';
import { GeoIpPopUp } from '../geoIp';
import { Header } from '../header/Header';
import { SkipMainContentCTA } from '../skipMainContent';

export type MasterLayoutProps = {
  children: ReactNode;
  hideFooter?: boolean;
};

const S = {
  FocusReset: styled.div`
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    position: fixed;
  `,
  Main: styled.main`
    &:empty {
      min-height: 100vh;
    }
  `,
};

export function useResetFocusOnNavigation() {}

export function MasterLayout({ children, hideFooter }: MasterLayoutProps) {
  const router = useRouter();
  const { locale = null } = { ...router };

  const { masterLayoutData, navigationHierarchyData, masterLayoutHandlebarComponents } = useMasterLayoutContext();
  const { headerComponent, footerComponent, emptyBasketComponent } = {
    ...masterLayoutData,
  };

  const masterLayoutContext = {
    context: {
      hierarchyData: navigationHierarchyData,
      locale: locale || 'en',
    },
  };

  const localeValue = useMemo(
    () => ({
      locale: locale ?? Locale.DEFAULT_LOCALE,
    }),
    [locale],
  );

  const focusResetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    focusResetRef.current?.focus();
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      focusResetRef.current?.focus();
      router?.events?.off('routeChangeComplete', handleRouteChange);
    };

    const handleClick = (event: Event) => {
      const element = (event.target as HTMLElement).closest('a');
      if (document.querySelector('header')?.contains(element) || document.querySelector('footer')?.contains(element))
        router?.events?.on('routeChangeComplete', handleRouteChange);
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [router?.events]);

  return (
    <LocaleContext.Provider value={localeValue}>
      <OverlayContextProvider>
        <AfterCookieBannerContextProvider>
          <S.FocusReset ref={focusResetRef} tabIndex={-1} aria-hidden />
          <SkipMainContentCTA />
          <Header
            context={masterLayoutContext}
            headerData={headerComponent}
            emptyBasketComponent={emptyBasketComponent}
            handlebarComponents={masterLayoutHandlebarComponents}
          />
          <S.Main id="main-content">{children}</S.Main>
          {!hideFooter && <Footer footerData={footerComponent} />}
          <GeoIpPopUp />
        </AfterCookieBannerContextProvider>
      </OverlayContextProvider>
    </LocaleContext.Provider>
  );
}
