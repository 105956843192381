import { createAnchorTagHref } from './transformers';

type RedirectUserProps = {
  cookie: string | undefined;
  locale: string | undefined;
  path: string;
};

export const redirectUser = ({ cookie, locale, path }: RedirectUserProps) => {
  if (cookie) {
    return {
      redirect: {
        destination: createAnchorTagHref({ locale, path }),
        permanent: true,
      },
      props: {},
    };
  }
  return {
    props: {},
  };
};
