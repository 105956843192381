import { useMemo } from 'react';
import type { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import { useLocaleContext } from '../../../context/localeContext';
import { useAlgoliaAppliedFilter } from '../../../hooks/useAlgoliaAppliedFilters';
import type { UseAlgoliaFilterReturn } from '../../../hooks/useAlgoliaFilter';
import { useAlgoliaFilter } from '../../../hooks/useAlgoliaFilter';
import { useExitAnimation } from '../../../hooks/useExitAnimation';
import { useMediaMatch } from '../../../hooks/useMediaMatch';
import { useMobileOverlay } from '../../../hooks/useMobileOverlay';
import { Close, Refine } from '../../../icons';
import { media } from '../../../stylings';
import { getCtLocale } from '../../../utils/localeHelper';
import OverLay from '../OverLay';
import { AppliedFilters } from './AppliedFilters';
import ColorFilter from './ColorFilter';
import { FilterCheckboxAccordion } from './FilterCheckboxAccordion';
import {
  CloseButton,
  Container,
  Divider,
  FacetsContainer,
  FilterButton,
  FilterContainer,
  Header,
} from './FilterMenu.styles';
import { HeelHeightFilter } from './HeelHeightFilter';
import { PriceFilter } from './PriceFilter';
import RatingsFilter from './RatingsFilter';
import SizeFilter from './sizeFilter/SizeFilter';

enum FacetNames {
  SIZE = 'size',
  FIT = 'width',
  HEEL_HEIGHT = 'heel height',
  GENDER = 'gender',
  COLOR = 'color',
  PRODUCT = 'product type',
  STYLE = 'style name',
  FASTENING = 'fastening ',
  TECHNOLOGY = 'technology',
  TOP_RATED = 'top rated',
  BRAND = 'brand',
  MATERIAL = 'material',
  PRICE = 'price',
}
const initialArrangementOfFacets: string[] = Object.values(FacetNames).map((value) => value);
type FilterMenuProps = { facetSequence?: string[]; productCount: number };
export function FilterMenu({ facetSequence = [], productCount }: FilterMenuProps) {
  const { locale } = useLocaleContext();
  const upperCaseLocale = getCtLocale(locale);

  const algoliaFilter = useAlgoliaFilter(upperCaseLocale);

  const { clearAll, currentRefinements } = useAlgoliaAppliedFilter();

  if (algoliaFilter.isFiltersEmpty) return null;

  return (
    <Container>
      <AppliedFilters priceRange={algoliaFilter.priceRange} items={currentRefinements} clearAll={clearAll} />
      <DisplayFacets productCount={productCount} facetSequence={facetSequence} algoliaFilter={algoliaFilter} />
    </Container>
  );
}

export function MobileFilterMenu({ facetSequence = [], productCount }: FilterMenuProps) {
  const { locale } = useLocaleContext();
  const upperCaseLocale = getCtLocale(locale);

  const algoliaFilter = useAlgoliaFilter(upperCaseLocale);

  const { t } = useTranslation(['lib-global-common']);

  const isDesktop = useMediaMatch(media.greaterThan('lg'));

  const { handleOpenOverlay, isOverLayOpen } = useMobileOverlay();
  const { handleExitAnimation, isClosing } = useExitAnimation({ callbackFunction: handleOpenOverlay });

  if (isDesktop || algoliaFilter.isFiltersEmpty) return null;

  return (
    <>
      <FilterContainer>
        <FilterButton onClick={handleOpenOverlay} data-testid="filterButton">
          {t('filter.menu.filter')}
          <Refine />
        </FilterButton>
      </FilterContainer>

      <OverLay
        isClosing={isClosing}
        handleOpenOverlay={handleExitAnimation}
        isOverLayOpen={isOverLayOpen}
        testId="filterMenu"
      >
        <>
          <Header data-testid="filterHeader">
            <h4>{t('filter.menu.filter.by')}</h4>
            <CloseButton aria-label="close filter" onClick={handleExitAnimation} data-testid="filterCloseButton">
              <Close viewBox="0 -1 20 20" />
            </CloseButton>
          </Header>
          <Divider />

          <DisplayFacets productCount={productCount} facetSequence={facetSequence} algoliaFilter={algoliaFilter} />
        </>
      </OverLay>
    </>
  );
}

function RefinementFacet({
  algoliaFilter,
  facet,
  productCount,
}: {
  algoliaFilter: UseAlgoliaFilterReturn;
  facet: string;
  productCount: number;
}): ReactElement | null {
  const { t } = useTranslation(['lib-global-common']);

  const {
    sizeRefinements,
    priceRange,
    colorRefinements,
    styleRefinements,
    fasteningRefinements,
    materialsRefinements,
    ratingRefinements,
    widthRefinements,
    technologiesRefinements,
    productTypeRefinements,
    heelHeightRefinements,
    genderRefinements,
    brandRefinements,
  } = algoliaFilter;

  switch (facet) {
    case FacetNames.SIZE:
      return <SizeFilter {...sizeRefinements} productCount={productCount} />;

    case FacetNames.FIT:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.width')}
          {...widthRefinements}
        />
      );

    case FacetNames.HEEL_HEIGHT:
      return (
        <HeelHeightFilter
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.heel.height')}
          {...heelHeightRefinements}
        />
      );

    case FacetNames.COLOR:
      return <ColorFilter accordionLabel={t('filter.menu.filter.colour')} {...colorRefinements} />;

    case FacetNames.GENDER:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.gender')}
          {...genderRefinements}
        />
      );

    case FacetNames.PRODUCT:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.product.type')}
          {...productTypeRefinements}
        />
      );
    case FacetNames.STYLE:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.style')}
          {...styleRefinements}
        />
      );

    case FacetNames.FASTENING:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.fastening')}
          {...fasteningRefinements}
        />
      );

    case FacetNames.TECHNOLOGY:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.technology')}
          {...technologiesRefinements}
        />
      );

    case FacetNames.TOP_RATED:
      return (
        <RatingsFilter
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.top.rated')}
          {...ratingRefinements}
        />
      );

    case FacetNames.BRAND:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.brand')}
          {...brandRefinements}
        />
      );

    case FacetNames.MATERIAL:
      return (
        <FilterCheckboxAccordion
          productCount={productCount}
          accordionLabel={t('filter.menu.filter.material')}
          {...materialsRefinements}
        />
      );

    case FacetNames.PRICE:
      return <PriceFilter {...priceRange} />;

    default:
      return null;
  }
}
function DisplayFacets({
  algoliaFilter,
  facetSequence = [],
  productCount,
}: {
  algoliaFilter: UseAlgoliaFilterReturn;
  facetSequence?: string[];
  productCount: number;
}): ReactElement {
  const arrangeFilterFacets = useMemo(() => {
    const facetNames: string[] = facetSequence.map((facet) => getFacetName(facet)).filter((facet) => facet);
    const newArrangementOfFacets = Array.from(new Set(facetNames.concat(initialArrangementOfFacets)));
    return newArrangementOfFacets;
  }, [facetSequence]);

  return (
    <FacetsContainer>
      {arrangeFilterFacets.map((filterFacet) => (
        <li key={filterFacet}>
          <RefinementFacet productCount={productCount} facet={filterFacet} algoliaFilter={algoliaFilter} />
        </li>
      ))}
    </FacetsContainer>
  );
}

function getFacetName(facet: string) {
  const lowerCaseFacet = facet.toLocaleLowerCase();

  if (FacetNames.SIZE.includes(lowerCaseFacet)) return FacetNames.SIZE;

  if (FacetNames.FIT.includes(lowerCaseFacet)) return FacetNames.FIT;

  if (FacetNames.HEEL_HEIGHT.includes(lowerCaseFacet)) return FacetNames.HEEL_HEIGHT;

  if (FacetNames.COLOR.includes(lowerCaseFacet)) return FacetNames.COLOR;

  if (FacetNames.GENDER.includes(lowerCaseFacet)) return FacetNames.GENDER;

  if (FacetNames.PRODUCT.includes(lowerCaseFacet)) return FacetNames.PRODUCT;

  if (FacetNames.STYLE.includes(lowerCaseFacet)) return FacetNames.STYLE;

  if (FacetNames.FASTENING.includes(lowerCaseFacet)) return FacetNames.FASTENING;

  if (FacetNames.TECHNOLOGY.includes(lowerCaseFacet)) return FacetNames.TECHNOLOGY;

  if (FacetNames.TOP_RATED.includes(lowerCaseFacet)) return FacetNames.TOP_RATED;

  if (FacetNames.BRAND.includes(lowerCaseFacet)) return FacetNames.BRAND;

  if (FacetNames.MATERIAL.includes(lowerCaseFacet)) return FacetNames.MATERIAL;

  if (FacetNames.PRICE.includes(lowerCaseFacet)) return FacetNames.PRICE;

  return '';
}

export default FilterMenu;
