import { useEffect, useState } from 'react';
import type { TrendingItemsQuery } from '@algolia/recommend';
import type { useTrendingItems } from '@algolia/recommend-react';
import { getTrendingProducts } from '../services/algolia/getTrendingProducts';

type TrendingItems = Array<
  ReturnType<typeof useTrendingItems>['recommendations'][number] & {
    images?: string[];
  }
>;

export function useAlgoliaTrendingItems({ query, isInView }: { isInView: boolean; query: TrendingItemsQuery[] }) {
  const [isLoading, setIsLoading] = useState(false);
  const [trendingItems, setTrendingItems] = useState<TrendingItems>([]);
  const [called, setCalled] = useState(false);

  useEffect(() => {
    if (!isInView || called) return;

    (async () => {
      try {
        setIsLoading(true);
        const trendingProducts = await getTrendingProducts({ query });
        setTrendingItems(trendingProducts.results.flatMap((result) => result.hits));
      } catch (e) {
        console.error(e);
      } finally {
        setCalled(true);
        setIsLoading(false);
      }
    })();
  }, [called, isInView, query]);

  return { trendingItems, isLoading };
}
