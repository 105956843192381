import styled from 'styled-components';
import { colours } from '../../stylings';

export const OrSeparator = styled.hr`
  border: none;
  border-top: 1px solid ${colours.BLACK};
  color: ${colours.BLACK};
  height: 5px;
  margin: 31px auto;
  overflow: visible;
  text-align: center;
  width: 100%;

  &:after {
    background: ${colours.WHITE};
    content: 'Or';
    padding: 0 6px;
    position: relative;
    top: -10px;
  }
`;
