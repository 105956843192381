import styled, { css } from 'styled-components';
import { useMediaMatch } from '../../hooks/useMediaMatch';
import { LinkPartial } from '../../partials/link';
import { TypographyStyles, anchorTagHoverAnimation, colours, media, spacing } from '../../stylings';
import type {
  Column,
  HeaderNavigationLevel2,
  HeaderNavigationLevel3Content,
  LayoutContent,
  LinkedText,
  NavigationHierarchyDataSubLevel,
  NavigationList1,
} from '../../types/navigationHierarchyData';
import { sendNavigationEvent } from '../../utils/gtm/events/navigation/sendNavigationEvent';
import { CategoryFeaturedCard } from './CategoryFeaturedCard';

const S = {
  MegaMenuWrapper: styled.div`
    gap: 28px;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 70px ${spacing.L};
  `,
  RowWrapper: styled.div<{ $alignLeft?: boolean }>`
    display: flex;
    gap: ${({ $alignLeft }) => $alignLeft && '64px'};
    justify-content: ${({ $alignLeft }) => ($alignLeft ? 'left' : 'space-between')};
    width: 100%;

    a {
      color: ${colours.BLACK};
      text-decoration: none;
    }
  `,
  MenuColumnContainer: styled.div<{ $hasfeaturedCards: boolean; $extendWidth?: boolean }>`
    display: flex;
    ${({ $hasfeaturedCards }) =>
      !$hasfeaturedCards &&
      css`
        flex-basis: content;
        flex-grow: 1;
      `}
    flex-direction: column;
    max-width: ${({ $extendWidth }) => ($extendWidth ? '255px' : '190px')};
  `,
  MenuColumnContent: styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing.M};
    max-width: 190px;
    padding: 0;
    width: fit-content;
  `,
  FeaturedCardContainer: styled.div`
    display: flex;
    gap: 18px;
    margin: 0 auto;
  `,
  ProductTypeList: styled.ul`
    padding: ${spacing.S} 0 0;
    > li {
      margin-bottom: ${spacing.XXS};

      > a {
        ${TypographyStyles.Body.Tiny.Regular}
        white-space: normal;

        @media ${media.greaterThan('lg')} {
          font-size: 14px;
        }
      }
    }
  `,
  NavLinkItemHeader: styled.span`
    ${TypographyStyles.Body.Small.SemiBold}
    white-space: normal;
  `,
  NavigationListItem: styled.li`
    /* stylelint-disable selector-type-no-unknown */
    ${() =>
      anchorTagHoverAnimation({
        backgroundColor: colours.BLACK,
      })}
  `,
};

type NavigationListItemProps = {
  navLink: HeaderNavigationLevel2 & LinkedText;
  handleCloseMenu: () => void;
};
type MegamenuProps = {
  megamenuData: NavigationHierarchyDataSubLevel;
  handleCloseMenu: () => void;
};

function NavigationListItem({ navLink, handleCloseMenu }: NavigationListItemProps) {
  const { text, link, linkedContentChildData } = navLink;
  const handleCLick = (linkText: string) => () => {
    sendNavigationEvent({
      component_header: text,
      component_type: 'main nav - text link',
      link_text: linkText,
    });
    setTimeout(() => {
      handleCloseMenu();
    }, 300);
  };

  return (
    <S.NavigationListItem>
      <LinkPartial ariaLabel={text} {...link} onClick={handleCLick(text as string)}>
        <S.NavLinkItemHeader>{text}</S.NavLinkItemHeader>
      </LinkPartial>
      {linkedContentChildData && (
        <S.ProductTypeList>
          {linkedContentChildData.map((productType: HeaderNavigationLevel3Content, idx: number) => (
            <li key={`product-type-${idx}`}>
              <LinkPartial
                role="menuitem"
                ariaLabel={`${text}, ${productType?.content?.text}`}
                onClick={handleCLick(productType.content?.text as string)}
                {...productType?.content?.link}
              >
                {productType?.content?.text}
              </LinkPartial>
            </li>
          ))}
        </S.ProductTypeList>
      )}
    </S.NavigationListItem>
  );
}

function MegaMenuRow({
  layoutContent,
  extendWidth = false,
  handleCloseMenu,
}: {
  layoutContent: LayoutContent | Column[];
  extendWidth?: boolean;
  handleCloseMenu: () => void;
}) {
  const hasfeaturedCards = Boolean(layoutContent?.find((columnData) => 'featuredCards' in columnData));
  return (
    <>
      {layoutContent.map((columnData: Column, id: number) => {
        const { navigationList, featuredCards } = columnData;

        return (
          <S.MenuColumnContainer
            role="none"
            key={`column-${id}`}
            $hasfeaturedCards={hasfeaturedCards}
            $extendWidth={extendWidth}
          >
            {navigationList &&
              navigationList.map((navigationListData: NavigationList1, idx: number) => {
                const { featuredLinks, category } = navigationListData;
                const { linkedContentChildData } = featuredLinks || {};
                return (
                  <S.MenuColumnContent role="none" key={`column-${id}-featured-${idx}`}>
                    {featuredLinks &&
                      linkedContentChildData &&
                      linkedContentChildData?.featuredLinks?.map((featuredLink: LinkedText, index: number) => (
                        <NavigationListItem
                          navLink={featuredLink}
                          key={`column-${id}-featured-${idx}-content-${index}`}
                          handleCloseMenu={handleCloseMenu}
                        />
                      ))}
                    {category && category.linkedContentChildData && (
                      <NavigationListItem
                        navLink={category.linkedContentChildData}
                        key={`column-${id}-category-content-${idx}`}
                        handleCloseMenu={handleCloseMenu}
                      />
                    )}
                  </S.MenuColumnContent>
                );
              })}
            {featuredCards && (
              <CategoryFeaturedCard {...featuredCards.linkedContentChildData} extendWidth={extendWidth} />
            )}
          </S.MenuColumnContainer>
        );
      })}
    </>
  );
}
export function Megamenu({ megamenuData, handleCloseMenu }: MegamenuProps) {
  const { layoutContent } = megamenuData || {};
  const featuredCardArray = layoutContent?.filter((columnData) => 'featuredCards' in columnData);
  const navigationListArray = layoutContent?.filter((columnData) => 'navigationList' in columnData);
  const separateContent = useMediaMatch(media.between('lg', 'xl'));

  return (
    <S.MegaMenuWrapper role="menu">
      {!separateContent && layoutContent ? (
        <S.RowWrapper $alignLeft={layoutContent.length <= 2}>
          <MegaMenuRow layoutContent={layoutContent} handleCloseMenu={handleCloseMenu} />
        </S.RowWrapper>
      ) : (
        <>
          {navigationListArray && (
            <S.RowWrapper $alignLeft={navigationListArray.length <= 2}>
              <MegaMenuRow layoutContent={navigationListArray} handleCloseMenu={handleCloseMenu} />
            </S.RowWrapper>
          )}
          {featuredCardArray && (
            <S.RowWrapper>
              <S.FeaturedCardContainer>
                <MegaMenuRow layoutContent={featuredCardArray} extendWidth handleCloseMenu={handleCloseMenu} />
              </S.FeaturedCardContainer>
            </S.RowWrapper>
          )}
        </>
      )}
    </S.MegaMenuWrapper>
  );
}

export default Megamenu;
