/* eslint-disable @typescript-eslint/no-explicit-any */
import { isClient } from '../ssr';

export type StorageType = 'localStorage' | 'sessionStorage';

export function getItem<T = any>(key: string, parse = true, storageType: StorageType = 'localStorage'): T | null {
  if (!isClient()) {
    return null;
  }

  try {
    const storageItem = window[storageType].getItem(key);
    if (storageItem) {
      return parse ? JSON.parse(storageItem) : storageItem;
    }
    return null;
  } catch (e) {
    console.error('Web Storage API is not supported', e);
    return null;
  }
}

export function setItem(key: string, value: any, stringify = true, storageType: StorageType = 'localStorage'): void {
  if (!isClient()) {
    return;
  }

  try {
    window[storageType].setItem(key, stringify ? JSON.stringify(value) : value);
  } catch (e) {
    console.error('Web Storage API is not supported', e);
  }
}

export function removeItem(key: string, storageType: StorageType = 'localStorage'): void {
  if (!isClient()) {
    return;
  }

  try {
    window[storageType].removeItem(key);
  } catch (e) {
    console.error('Web Storage API is not supported', e);
  }
}
