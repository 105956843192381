import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import type { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterSchema } from '@shared/validation/src/b2c';
import { Channel } from '../codegen/types';
import { useFeatureFlagsContext } from '../context/featureFlagsContext';
import { useLocaleContext } from '../context/localeContext';
import { useStaticContext } from '../context/staticContext';
import { useSignUpMutation } from '../graphqlDocument/auth/signUp.generated';
import type { BffError } from '../services/errors';
import { grecaptcha } from '../utils/grecaptcha';
import { sendCreateAccountErrorEvent } from '../utils/gtm/events/create_account_error/sendCreateAccountErrorEvent';
import { sendCreateAccountOnCheckoutEvent } from '../utils/gtm/events/create_account_oncheckout/sendCreateAccountOnCheckoutEvent';
import { sendCreateAccountSuccessEvent } from '../utils/gtm/events/create_account_success/sendCreateAccountSuccessEvent';
import { sendSubscribeToNewsletterEvent } from '../utils/gtm/events/subscribe_to_newsletter/sendSubscribeToNewsletterEvent';
import { Locale } from '../utils/localeHelper';
import { progressBarDone, progressBarStart } from '../utils/progressBarStatus';
import { useIsBadPasswords } from './useBadPassword';
import { useLoadingCursor } from './useLoadingCursor';

interface OptInFormValues {
  [key: string]: boolean;
}

type RegisterFormValues = {
  firstName: string;
  lastName: string;
  password: string;
  email: string;
} & OptInFormValues;

export function useRegister(existingOrderId?: string) {
  const { locale } = useLocaleContext();
  const { marketingPreferences } = useStaticContext();
  const router = useRouter();
  const { isAuthRecaptchaEnabled } = useFeatureFlagsContext();
  const { t } = useTranslation(['lib-global-common']);
  const [signUp, { data, loading }] = useSignUpMutation();
  const [emailError, setEmailError] = useState<string | null>();
  const [masterIsEnabled, setMasterIsEnabled] = useState(marketingPreferences?.marketingPreferenceDefaultEnabled);
  const [categoryChoices, setCategoryChoices] = useState(marketingPreferences?.marketingPreferences ?? {});
  const [recaptchaError, setRecaptchaError] = useState<string | null>();
  const isRoiOrUk = locale === Locale.EN_GB || locale === Locale.EN_IE;
  const itemLength = Object.entries(marketingPreferences?.marketingPreferences ?? {}).length;
  const isConfirmationPage = router?.asPath === '/checkout/confirmation';

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setError,
    setValue,
    formState: { errors: formError },
  } = useForm<RegisterFormValues>({
    mode: 'onChange',
    resolver: yupResolver(RegisterSchema),
  });
  const [email, firstName, lastName, password] = useWatch({
    control,
    name: ['email', 'firstName', 'lastName', 'password'],
  });

  const { isBadPassword, checkPassword } = useIsBadPasswords();

  useLoadingCursor(loading);

  useEffect(() => {
    if (data) {
      router.push(
        {
          pathname: '/register/confirmation',
          query: {
            sub: data.signUp.userSub,
          },
        },
        undefined,
        {
          locale: router.locale,
        },
      );
    }
  }, [data, router]);

  useEffect(() => {
    if (isBadPassword) {
      setError('password', { message: t('register.validation.bad.password') });
    }
  }, [isBadPassword, setError, t]);

  const onPasswordBlur = () => {
    checkPassword(password);
  };

  const onSubmit: SubmitHandler<RegisterFormValues> = async (props: RegisterFormValues) => {
    setEmailError(null);
    setRecaptchaError(null);
    progressBarStart();

    let recaptchaToken;
    if (isAuthRecaptchaEnabled) {
      recaptchaToken = await grecaptcha();
    }
    const preferences = Object.entries(categoryChoices).map(([, value]) => ({
      key: value.key,
      optIn: value.state,
    }));
    const isSubscribedToNewsletter = preferences.some((preference) => preference.optIn);
    const isPasswordBad = await checkPassword(props.password);

    if (isPasswordBad) return;
    try {
      await signUp({
        variables: {
          input: {
            password: props.password,
            email: props.email,
            family_name: props.lastName.trim(),
            given_name: props.firstName.trim(),
            locale: locale === Locale.STOREFRONT_OUTLET_LOCALE ? Locale.CT_OUTLET_LOCALE : (locale as string),
            channel: locale === Locale.STOREFRONT_OUTLET_LOCALE ? Channel.OUTLET : Channel.FULLPRICE,
            preferences,
            existingOrderId,
          },
        },
        context: {
          headers: isAuthRecaptchaEnabled ? { 'x-recaptcha-token': recaptchaToken } : {},
        },
        onCompleted: () => {
          if (isSubscribedToNewsletter) sendSubscribeToNewsletterEvent();
          if (isConfirmationPage) {
            const selectedPreferences = preferences
              .filter((preference) => !!preference.optIn)
              .map(
                (selectedPreference) =>
                  `${selectedPreference.key.charAt(0).toUpperCase()}${selectedPreference.key.slice(1)}`,
              )
              .join(', ');
            sendCreateAccountOnCheckoutEvent({ subscription_preferences: selectedPreferences });
          }
          sendCreateAccountSuccessEvent();
          progressBarDone();
        },
      });
    } catch (e: unknown) {
      const error = e as ApolloError;
      const gqlError = error.graphQLErrors[0] as unknown as BffError;
      setEmailError(gqlError.errorType);
      setRecaptchaError(gqlError.errorType);
      sendCreateAccountErrorEvent(gqlError.message);
      progressBarDone();
    }
  };

  const handleMasterCheckboxOnChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setMasterIsEnabled(checked);

    Object.entries(categoryChoices).forEach(([key]) => {
      setCategoryChoices((prev) => ({
        ...prev,
        [key]: {
          ...categoryChoices[+key],
          state: !masterIsEnabled,
        },
      }));
    });
  };

  const handleCheckboxChange = ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
    setCategoryChoices((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[+name],
        state: checked,
      },
    }));

    if (!checked) {
      setMasterIsEnabled(false);
    }
  };

  useEffect(() => {
    setMasterIsEnabled(categoryChoices && Object.entries(categoryChoices).every(([, value]) => value.state));
  }, [categoryChoices]);

  return {
    handleCheckboxChange,
    handleMasterCheckboxOnChange,
    onSubmit,
    email,
    firstName,
    lastName,
    password,
    onPasswordBlur,
    register,
    handleSubmit,
    formError,
    loading,
    emailError,
    recaptchaError,
    isRoiOrUk,
    itemLength,
    getValues,
    setValue,
    isBadPassword,
    masterIsEnabled,
    marketingPreferences,
    categoryChoices,
    data,
  };
}
