import { useRef, useState } from 'react';
import { useIntersectionObserverRef } from 'rooks';

export function useLoadMedia(mp4Url: string, webmUrl: string, options: IntersectionObserverInit = {}) {
  const [isVisible, setIsVisible] = useState(false);
  const hasLoadedRef = useRef(false);

  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    if (entries && entries[0]) {
      setIsVisible(entries[0].isIntersecting);
      if (entries[0].isIntersecting && !hasLoadedRef.current) {
        const video = entries[0].target as HTMLVideoElement;
        if (video.canPlayType('video/mp4') !== '') {
          video.src = mp4Url;
        } else if (video.canPlayType('video/webm') !== '') {
          video.src = webmUrl;
        }

        video.load();
        hasLoadedRef.current = true;
      }
    }
  };

  const [mediaRef] = useIntersectionObserverRef(observerCallback, options);

  return { mediaRef, isVisible };
}
