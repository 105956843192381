export function singleton<T>(creator: () => T): () => T {
  let instance: T;

  return () => {
    if (!instance) {
      instance = creator();
    }
    return instance;
  };
}
