import { useMemo, useState } from 'react';
import type {
  CurrentRefinementsConnectorParamsRefinement,
  CurrentRefinementsRenderState,
} from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';
import type { RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { WhiteButton } from '../../../baseComponents/button';
import { useLocaleContext } from '../../../context/localeContext';
import { Close, Minus, Plus } from '../../../icons';
import { TypographyStyles, colours, media, spacing } from '../../../stylings';
import { getAmount, getCurrencySymbol, localeToCurrencyCode } from '../../../utils/currencyHelper';
import { sendRemoveCategoryFilterEvent } from '../../../utils/gtm/events/remove_category_filter/sendRemoveCategoryFilterEvent';

const S = {
  Button: styled(WhiteButton)`
    align-items: start;
    background: ${colours.WHITE};
    border: 1px solid ${colours.GREY};
    color: ${colours.BLACK};
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100%;
    min-width: 111px;
    padding: 7px 20px;
    text-transform: initial;
    ${TypographyStyles.Body.Small.Regular}
    svg {
      height: 10px;
      margin-block-start: ${spacing.XXXS};
      min-height: 10px;
      min-width: 10px;
      width: 10px;

      @media ${media.greaterThan('lg')} {
        margin-block-start: 10px;
      }
    }
    @media ${media.greaterThan('lg')} {
      padding: 9px 20px;
    }
  `,
  Header: styled.span`
    display: flex;
    height: 24px;
    justify-content: space-between;
    width: 100%;
    @media ${media.greaterThan('lg')} {
      height: 28px;
    }
  `,
  HeaderText: styled.span`
    ${TypographyStyles.Body.Medium.SemiBold}
    color: ${colours.BLACK};
    margin: 0;
    padding: 0;
  `,
  ClearAll: styled.button`
    ${TypographyStyles.CallToAction.Tiny.SemiBold}
    background-color: ${colours.WHITE};
    border: none;
    color: ${colours.BLACK};
    text-decoration: underline;
  `,
  ItemList: styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing.XXXS};
    margin: 0;
    padding: 0;
  `,
  Item: styled.li`
    margin: 0;
    padding: 0;
  `,
  Container: styled.span`
    align-items: flex-start;
    border-top: 1px solid ${colours.BLACK};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: ${spacing.XS} 0px 0px;
    width: 100%;

    @media ${media.greaterThan('lg')} {
      align-items: flex-start;
      border-style: solid;
      border-top: none;
      border-width: 1px 0px;
      gap: 20px;
      padding: ${spacing.M} 0px;
      width: 302px;
    }
  `,
  ShowMoreButton: styled.button`
    ${TypographyStyles.CallToAction.Tiny.SemiBold}
    align-items: center;
    background: none;
    border: 0;
    color: ${colours.BLACK};
    display: flex;
    padding: 0 ${spacing.XXS} 0 0;
    text-align: left;
    text-decoration: underline;

    svg {
      display: inline-block;
      overflow: visible;
      transform: scale(0.5);
    }
  `,
  ShowMoreContainer: styled.div`
    align-items: center;
    display: flex;
    gap: ${spacing.XXXXS};
  `,
};
export type AppliedFiltersProps = {
  clearAll?: () => void;
  items: CurrentRefinementsRenderState['items'];
  priceRange?: RangeRenderState;
};

type CurrentRefinementItemProps = CurrentRefinementsConnectorParamsRefinement & {
  itemRefine: (value: CurrentRefinementsConnectorParamsRefinement) => void;
};

export function AppliedFilters({ items, clearAll, priceRange }: AppliedFiltersProps) {
  const { t } = useTranslation(['lib-global-common']);
  const [showMore, setShowMore] = useState(false);
  const { locale } = useLocaleContext();

  const { refinementListItems, canShowMore } = useMemo(() => {
    let currentRefinementList = items.flatMap((item) =>
      item.refinements.map((refinement) => ({
        ...refinement,
        itemRefine: item.refine,
      })),
    );

    if (priceRange && (priceRange.start[0] !== -Infinity || priceRange.start[1] !== Infinity)) {
      const { start, range, refine: priceRefine } = priceRange;
      const currencySymbol = getCurrencySymbol(localeToCurrencyCode[locale]);
      currentRefinementList = currentRefinementList.concat([
        {
          attribute: 'price.centAmount',
          value: getPriceRangeValue(start, range, currencySymbol),
          itemRefine: () => {
            priceRefine([range.min, range.max]);
          },
          type: 'numeric',
          label: 'price.centAmount',
        },
      ]);
    }
    const showMoreRefinements = currentRefinementList.length > 5;

    if (!showMore && showMoreRefinements) {
      return {
        refinementListItems: currentRefinementList.splice(0, 5),
        canShowMore: showMoreRefinements,
      };
    }
    return {
      refinementListItems: currentRefinementList,
      canShowMore: showMoreRefinements,
    };
  }, [items, locale, priceRange, showMore]);

  const handleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  if (refinementListItems.length === 0) {
    return null;
  }

  return (
    <S.Container data-testid="appliedFiltersSection">
      <S.Header>
        <S.HeaderText>{t('applied.filters.options')}</S.HeaderText>
        <S.ClearAll type="button" onClick={clearAll} data-testid="clearFiltersButton">
          {t('applied.filters.clear.all')}
        </S.ClearAll>
      </S.Header>
      <S.ItemList data-testid="appliedFiltersList">
        {refinementListItems.map((refinementItem) => (
          <S.Item key={`${refinementItem.attribute}-${refinementItem.label}`} data-testid="appliedFilterItem">
            <CurrentRefinement refinementItem={refinementItem} />
          </S.Item>
        ))}
        {canShowMore && (
          <S.ShowMoreButton type="button" onClick={handleShowMore} data-testid="showMoreLessFiltersButton">
            {showMore ? (
              <S.ShowMoreContainer data-testid="showLessFilters">
                <Minus aria-hidden /> {t('applied.filters.less')}
              </S.ShowMoreContainer>
            ) : (
              <S.ShowMoreContainer data-testid="showMoreFilters">
                <Plus aria-hidden /> {t('applied.filters.more')}
              </S.ShowMoreContainer>
            )}
          </S.ShowMoreButton>
        )}
      </S.ItemList>
    </S.Container>
  );
}
function CurrentRefinement({ refinementItem }: { refinementItem: CurrentRefinementItemProps }) {
  const { attribute, value, itemRefine } = refinementItem;
  const { label: attributeLabel, value: attributeValue } = createRefinementLabel(attribute, value);
  const { t } = useTranslation(['lib-global-common']);
  const text = attributeLabel ? `${t(attributeLabel)}: ${attributeValue}` : attributeValue;

  const onRemoveRefinement = () => {
    itemRefine(refinementItem);
    sendRemoveCategoryFilterEvent({
      filter_category: attribute === 'price.centAmount' ? 'Price Range' : `${t(attributeLabel)}`,
      filter_value: `${attributeValue}`,
    });
  };

  return (
    <S.Button
      onClick={onRemoveRefinement}
      data-testid="removeFilter"
      aria-label={`${t('applied.filters.remove.filter', { name: text })}`}
    >
      {text}
      <Close viewBox="-3 7 21 10" aria-hidden />
    </S.Button>
  );
}

function createRefinementLabel(
  attribute: CurrentRefinementsConnectorParamsRefinement['attribute'],
  value: CurrentRefinementsConnectorParamsRefinement['value'],
) {
  if (attribute.includes('brand')) {
    return {
      label: 'filter.menu.filter.brand',
      value,
    };
  }
  if (attribute.includes('productSubType')) {
    return {
      label: 'filter.menu.filter.style',
      value,
    };
  }
  if (attribute.includes('Material')) {
    return {
      label: 'filter.menu.filter.material',
      value,
    };
  }
  if (attribute.includes('fit')) {
    return {
      label: 'filter.menu.filter.width',
      value,
    };
  }
  if (attribute.includes('review.rating')) {
    return {
      label: 'filter.menu.filter.top.rated',
      value,
    };
  }
  if (attribute.includes('gender')) {
    return {
      label: 'filter.menu.filter.gender',
      value,
    };
  }
  if (attribute.includes('Type')) {
    return {
      label: 'filter.menu.filter.product.type',
      value,
    };
  }
  if (attribute.includes('fastening')) {
    return {
      label: 'filter.menu.filter.fastening',
      value,
    };
  }
  if (attribute.includes('heel')) {
    return {
      label: 'filter.menu.filter.heel.height',
      value,
    };
  }
  if (attribute.includes('technologies')) {
    return {
      label: 'filter.menu.filter.technology',
      value,
    };
  }
  if (attribute.includes('size')) {
    return {
      label: 'filter.menu.filter.size',
      value: value.toString().split('_')[0],
    };
  }
  if (attribute.includes('color')) {
    return {
      label: 'filter.menu.filter.colour',
      value,
    };
  }

  return {
    label: '',
    value,
  };
}

function getPriceRangeValue(
  start: RangeRenderState['start'],
  range: RangeRenderState['range'],
  currencySymbol: string,
) {
  const minPrice = parseInt(getAmount(start[0] === -Infinity ? range.min : start[0]).toString(), 10);
  const maxPrice = parseInt(getAmount(start[1] === Infinity ? range.max : start[1]).toString(), 10);
  return `${currencySymbol}${minPrice} - ${currencySymbol}${maxPrice}`;
}

export default AppliedFilters;
