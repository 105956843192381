import styled from 'styled-components';
import { colours } from '../../stylings';

const Hr = styled.hr`
  border: solid 0.5px ${colours.BLACK};
  margin: 0;
  padding: 0;
  width: 100%;
`;

export function Separator({ isVisible }: { isVisible: boolean }) {
  return isVisible ? <Hr aria-hidden /> : null;
}
