import type { ChangeEvent, MouseEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useSortBy } from 'react-instantsearch';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Fieldset } from '../../../baseComponents/inputs/Fieldset';
import { Radio } from '../../../baseComponents/inputs/Radio';
import { useLocaleContext } from '../../../context/localeContext';
import { useExitAnimation } from '../../../hooks/useExitAnimation';
import { useMobileOverlay } from '../../../hooks/useMobileOverlay';
import { ArrowDown, Close } from '../../../icons';
import { TypographyStyles, colours, hoverUp, media, spacing } from '../../../stylings';
import { createSortByItems } from '../../../utils/algoliaSortByUtils';
import OverLay from '../OverLay';

const S = {
  Container: styled.div`
    display: block;
    flex: 1 1 160px;

    @media ${media.greaterThan('lg')} {
      display: none;
    }
  `,
  Trigger: styled(DropdownMenu.Trigger)`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 1px solid ${colours.GREY};
    color: ${colours.BLACK};
    display: flex;
    height: 48px;
    padding: 9px ${spacing.XS};
    width: 150px;
    ${TypographyStyles.Body.Medium.SemiBold}
    &[data-state='open'] {
      border-bottom: none;
      color: ${colours.MIDNIGHT_BLUE};
    }
  `,
  Label: styled.label`
    align-items: center;
    color: ${colours.BLACK};
    cursor: pointer;
    max-width: 108px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${TypographyStyles.Body.Medium.SemiBold}
  `,
  RadioGroup: styled(Fieldset)`
    border: none;
    margin: 0;
    padding: 0;
    width: 262px;
    @media ${media.greaterThan('lg')} {
      border: solid 1px ${colours.BLACK};
      border-top: none;
      width: 150px;
    }
    .checked {
      background-color: ${colours.LIGHT_BLUE};
      ${TypographyStyles.Body.Small.SemiBold}
      @media ${media.greaterThan('lg')} {
        ${TypographyStyles.Body.Tiny.SemiBold}
      }
    }
  `,
  RadioButton: styled(Radio)`
    border-bottom: 1px solid ${colours.GREY};
    gap: ${spacing.XXS};
    height: 56px;
    padding: ${spacing.XXS} ${spacing.XS};
    width: 100%;
    ${TypographyStyles.Body.Small.Regular}
    @media ${media.greaterThan('lg')} {
      ${TypographyStyles.Body.Tiny.Medium}
      border: none;
      gap: ${spacing.XXXXS};
      height: 38px;
      padding: ${spacing.XXXS};
    }
    &:hover,
    &:focus {
      background-color: ${colours.LIGHT_BLUE};
    }
  `,
  ButtonWrapper: styled.div`
    align-items: center;
    display: flex;
    padding: ${spacing.XXS} 0 10px;
  `,
  Button: styled.button`
    align-items: center;
    background-color: ${colours.WHITE};
    border: 1px solid ${colours.GREY};
    color: ${colours.BLACK};
    display: flex;
    height: 48px;
    justify-content: space-between;
    max-width: 160px;
    padding: ${spacing.XXS} ${spacing.XS};
    width: 100%;
  `,
  Header: styled.span`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.L};
    width: 268px;
    h4 {
      ${TypographyStyles.Headings.H4}
      font-weight: 600;
      height: 18px;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      width: 232px;
    }
  `,
  CloseButton: styled.button`
    background-color: inherit;
    border: none;
    ${hoverUp()}
    svg {
      height: 10px;
      width: 10px;
    }
  `,
};

type UseAlgoliaFilterReturn = ReturnType<typeof useSortBy>;

export function MobileSortBy({ refine, currentRefinement, options }: UseAlgoliaFilterReturn) {
  const { t } = useTranslation(['lib-global-common']);

  const { handleOpenOverlay, isOverLayOpen } = useMobileOverlay();
  const [isDefault, setIsDefault] = useState(true);
  const { handleExitAnimation, isClosing } = useExitAnimation({ callbackFunction: handleOpenOverlay });

  const getLabel = (currentSort: string) => options.find(({ value }) => value === currentSort)?.label || '';

  const handleOnChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === currentRefinement) return;
    if (isDefault) {
      setIsDefault(false);
    }
    refine(value);
  };

  const handleOnClick = (e: MouseEvent<HTMLInputElement>) => {
    // This solution was to prevent arrow keys from being fired when navigating with arrow keys eg:(up and down).
    if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
      handleExitAnimation();
    }
  };

  return (
    <S.Container>
      <S.ButtonWrapper>
        <S.Button onClick={handleOpenOverlay} data-testid="sortByButton">
          <S.Label>{t(isDefault ? 'sort.by.menu.sort.by' : getLabel(currentRefinement))}</S.Label>
          <ArrowDown />
        </S.Button>
      </S.ButtonWrapper>

      <OverLay
        isClosing={isClosing}
        isOverLayOpen={isOverLayOpen}
        handleOpenOverlay={handleExitAnimation}
        testId="sortByMenu"
      >
        <S.Header>
          <h4>{t('sort.by.menu.sort.by')}</h4>
          <S.CloseButton aria-label="close filter" onClick={handleExitAnimation} data-testid="sortCloseButton">
            <Close viewBox="0 -1 20 20" />
          </S.CloseButton>
        </S.Header>

        <S.RadioGroup id="sort-by" label={t(isDefault ? 'sort.by.menu.sort.by' : getLabel(currentRefinement))}>
          {options.map(({ value, label }) => (
            <S.RadioButton
              label={t(label)}
              key={value}
              value={value}
              name="sort-by"
              onChange={handleOnChangeValue}
              onClick={handleOnClick}
              checked={currentRefinement === value}
              className={currentRefinement === value ? 'checked' : ''}
            />
          ))}
        </S.RadioGroup>
      </OverLay>
    </S.Container>
  );
}

export function MobileSortByMenu() {
  const { locale } = useLocaleContext();
  const items = createSortByItems(locale);
  const sortBy = useSortBy({
    items,
  });
  return <MobileSortBy {...sortBy} />;
}
export default MobileSortByMenu;
