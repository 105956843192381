// Disabled rule only for this file to match exact same names as events provided
/* eslint-disable @typescript-eslint/naming-convention */

// List of all newrelic/gtm events
enum EventNamesType {
  accordion_click = 'accordion_click',
  add_coupon = 'add_coupon',
  add_payment_info = 'add_payment_info',
  add_shipping_info = 'add_shipping_info',
  add_to_cart = 'add_to_cart',
  apply_category_filter = 'apply_category_filter',
  apply_product_variant = 'apply_product_variant',
  begin_checkout = 'begin_checkout',
  book_a_fitting_complete = 'book_a_fitting_complete',
  book_a_fitting_search = 'book_a_fitting_search',
  cancel_fitting = 'cancel_fitting',
  check_availability = 'check_availability',
  create_a_return = 'create_a_return',
  create_account_error = 'create_account_error',
  create_account_oncheckout = 'create_account_oncheckout',
  create_account_start = 'create_account_start',
  create_account_success = 'create_account_success',
  login_error = 'login_error',
  load_more_items = 'load_more_items',
  login_start = 'login_start',
  login_success = 'login_success',
  navigation = 'navigation',
  notify_me = 'notify_me',
  page_loaded = 'page_loaded',
  purchase = 'purchase',
  refund = 'refund',
  remove_category_filter = 'remove_category_filter',
  remove_from_cart = 'remove_from_cart',
  select_banner = 'select_banner',
  select_content = 'select_content',
  select_item = 'select_item',
  social_link = 'social_link',
  store_locator = 'store_locator',
  subscribe_to_newsletter = 'subscribe_to_newsletter',
  update_shipping_info = 'update_shipping_info',
  user_data_loaded = 'user_data_loaded',
  view_cart = 'view_cart',
  view_item = 'view_item',
  view_item_list = 'view_item_list',
  view_product_image = 'view_product_image',
}

export default EventNamesType;
