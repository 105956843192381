import type { InputHTMLAttributes, MouseEvent, RefObject } from 'react';
import { forwardRef, useRef, useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'next-i18next';
import type {
  CalendarContainerProps,
  ReactDatePicker,
  ReactDatePickerCustomHeaderProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import Datepicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocaleContext } from '../../context/localeContext';
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp, Calendar } from '../../icons';
import { enCA, enGB, enIE, enUS, frCA, getDateLocale, getTomorrow } from '../../utils/dateUtils';
import { Locale } from '../../utils/localeHelper';
import {
  ButtonsHolder,
  Header,
  HeaderHolder,
  IconWrapper,
  Input,
  InputContainer,
  NavButton,
  Calendar as StyledCalendar,
} from './Datepicker.styles';
import { DatepickerVariant } from './DatepickerVariant';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isCalendarOpen: boolean;
  isInputError?: boolean;
  customInputContainerRef: RefObject<HTMLDivElement>;
  variant?: DatepickerVariant;
  isFullWidth?: boolean;
}

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {
  onKeyDown: (e: { code: string }) => void;
}

export interface DatepickerComponentProps extends Pick<ReactDatePickerProps, 'selected' | 'onChange'> {
  placeholder?: string;
  isInputError?: boolean;
  isRequired?: boolean;
  variant?: DatepickerVariant;
  isFullWidth?: boolean;
}

const CustomInput = forwardRef(
  (
    {
      value,
      variant,
      onClick,
      onKeyDown,
      placeholder,
      isCalendarOpen,
      isInputError,
      customInputContainerRef,
      isFullWidth,
    }: CustomInputProps,
    ref,
  ) => (
    <InputContainer
      $isFullWidth={!!isFullWidth}
      onClick={onClick}
      ref={customInputContainerRef}
      onKeyDown={onKeyDown}
      $variant={variant}
    >
      <IconWrapper $wrapperType="left" aria-hidden>
        <Calendar />
      </IconWrapper>
      <Input
        ref={ref as RefObject<HTMLInputElement>}
        value={value}
        placeholder={placeholder}
        readOnly
        aria-label={placeholder}
        $isInputError={isInputError}
        data-testid="datepicker"
      />
      <IconWrapper $wrapperType="right">
        {!isCalendarOpen ? <ArrowDown aria-hidden /> : <ArrowUp aria-hidden />}
      </IconWrapper>
    </InputContainer>
  ),
);

function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  onKeyDown,
}: CustomHeaderProps) {
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common']);

  return (
    <HeaderHolder>
      <Header>
        {format(date, 'LLLL', getDateLocale(locale))} {date.getFullYear()}
      </Header>
      <ButtonsHolder>
        <NavButton
          onClick={decreaseMonth}
          onKeyDown={onKeyDown}
          disabled={prevMonthButtonDisabled}
          aria-label={t('datepicker.prev.month')}
        >
          <ArrowRight />
        </NavButton>
        <NavButton
          onClick={increaseMonth}
          onKeyDown={onKeyDown}
          disabled={nextMonthButtonDisabled}
          aria-label={t('datepicker.next.month')}
        >
          <ArrowLeft />
        </NavButton>
      </ButtonsHolder>
    </HeaderHolder>
  );
}

function CustomContainerDefault({ className, children }: CalendarContainerProps) {
  return <StyledCalendar className={className}>{children}</StyledCalendar>;
}

function CustomContainerSmall({ className, children }: CalendarContainerProps) {
  return (
    <StyledCalendar className={className} $variant={DatepickerVariant.SMALL}>
      {children}
    </StyledCalendar>
  );
}

export function DatepickerComponent({
  selected,
  onChange,
  placeholder,
  isInputError,
  isRequired,
  variant,
  isFullWidth,
}: DatepickerComponentProps) {
  registerLocale('fr-ca', frCA);
  registerLocale('en-ca', enCA);
  registerLocale('en-gb', enGB);
  registerLocale('en-us', enUS);
  registerLocale('en-ie', enIE);
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common']);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const customInputContainerRef = useRef<HTMLDivElement>(null);
  const datePickerContainerRef = useRef<ReactDatePicker>(null);

  const startDate = getTomorrow();

  const onCustomClickOutside = (e: MouseEvent<HTMLDivElement>) => {
    if (!customInputContainerRef?.current?.contains(e.target as HTMLInputElement)) {
      setCalendarOpen(false);
    }
  };
  const onCustomKeyDown = (e: { code: string }) => {
    if (e.code === 'Enter') {
      setCalendarOpen(true);
    } else if (e.code === 'Escape') {
      setCalendarOpen(false);
    }
  };

  const placeholderText = `${placeholder || t('datepicker.placeholder')}${isRequired ? '*' : ''}`;
  const preselectedDateClass = (date: Date) =>
    !selected && startDate.valueOf() === date.valueOf() ? 'datepicker-highlight-preselected-date' : '';
  const dateFormat = locale === Locale.EN_US ? 'E MMM d yyyy' : 'E d MMM yyyy';

  return (
    <Datepicker
      ref={datePickerContainerRef}
      showPopperArrow={false}
      dateFormat={dateFormat}
      formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
      selected={selected}
      onChange={onChange}
      customInput={
        <CustomInput
          isFullWidth={!!isFullWidth}
          isCalendarOpen={isCalendarOpen}
          customInputContainerRef={customInputContainerRef}
          isInputError={isInputError}
          variant={variant}
        />
      }
      renderCustomHeader={(args) => <CustomHeader onKeyDown={onCustomKeyDown} {...args} />}
      calendarContainer={variant === DatepickerVariant.SMALL ? CustomContainerSmall : CustomContainerDefault}
      minDate={startDate}
      onInputClick={() => setCalendarOpen(!isCalendarOpen)}
      onClickOutside={onCustomClickOutside}
      onSelect={() => setCalendarOpen(false)}
      open={isCalendarOpen}
      locale={locale}
      placeholderText={placeholderText}
      onKeyDown={onCustomKeyDown}
      dayClassName={preselectedDateClass}
      popperPlacement="bottom-start"
      popperModifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, -19],
          },
        },
        {
          name: 'sameWidth',
          enabled: true,
          phase: 'main',
          fn: ({ state }) => {
            const styles = {
              ...state.styles,
              popper: {
                ...state.styles.popper,
                width: `${state.rects.reference.width}px`,
              },
            };
            return { ...state, styles };
          },
        },
      ]}
    />
  );
}
