import styled from 'styled-components';
import type { FreeShippingDelivery as FreeShippingAndReturnSchema } from '@amplience/content-types/typings/c-shipping-section';
import { RichTextPartial } from '../../partials/richText';
import { TypographyStyles, colours, spacing } from '../../stylings';
import { getLocalizedValue } from '../../utils/transformers';

const S = {
  RichTextFreeShipping: styled(RichTextPartial)`
    margin-bottom: ${spacing.XXS};
    p {
      margin: 0;
    }
    & a {
      color: ${colours.BLACK};
    }
  `,
};

export type FreeShippingAndReturnsProps = FreeShippingAndReturnSchema & {
  className?: string;
};

export function ShippingSection({ freeShipingAndReturnText, className = '' }: FreeShippingAndReturnsProps) {
  return (
    <S.RichTextFreeShipping
      text={getLocalizedValue(freeShipingAndReturnText?.richText)}
      fontSize={TypographyStyles.Body.Tiny.Regular}
      className={className}
    />
  );
}
