import styled, { css } from 'styled-components';
import { TypographyStyles, colours, media, spacing } from '../../stylings';

export const StoreHolder = styled.div<{ $isActiveStore: boolean; $isClickable: boolean }>`
  ${TypographyStyles.Body.Small.Regular}
  border: 1px solid ${colours.BLACK};
  border-width: ${({ $isActiveStore }) => ($isActiveStore ? '2px' : '1px')};
  cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'initial')};
  padding: ${spacing.XS};
  &:focus-visible {
    outline-color: ${colours.OUTLINE_BORDER};
    outline-width: 2px;
  }
`;
export const StoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.XXS};
  & > div {
    align-items: flex-start;
    display: flex;
    gap: ${spacing.XXXS};
    justify-content: space-between;
  }
  & span {
    display: block;
  }
`;
export const InfoSection = styled.div<{ $isColumn?: boolean }>`
  align-items: flex-start;
  flex-direction: ${({ $isColumn }) => ($isColumn ? 'column' : 'row')};
`;
export const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;
export const Name = styled.span`
  ${TypographyStyles.Body.Small.SemiBold}
  margin: 0;
`;
export const Distance = styled.span`
  flex-shrink: 0;
`;
export const TypeTag = styled.span<{ $bgColor?: string; $color?: string }>`
  ${TypographyStyles.Label.Large.Medium}
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color || colours.WHITE};
  padding: ${spacing.XXXS};
  text-transform: uppercase;
  white-space: nowrap;
  @media ${media.lessThan('sm')} {
    ${TypographyStyles.Label.Small.Medium}
  }
`;
export const StoreSchedule = styled.div<{ $isStar2Locale?: boolean }>`
  align-items: end;
  display: flex;
  justify-content: space-between;
  @media ${media.lessThan('sm')} {
    align-items: start;
    flex-direction: column;
    gap: ${spacing.XXS};
  }
  @media ${media.between('lg', 'xl')} {
    ${({ $isStar2Locale }) =>
      $isStar2Locale &&
      css`
        align-items: start;
        flex-direction: column;
        gap: ${spacing.XXS};
      `}
  }
`;
export const ScheduleTitle = styled.strong`
  ${TypographyStyles.Body.Small.Medium}
  display: flex;
  padding-top: ${spacing.XXS};
  text-wrap: nowrap;
`;
export const OpeningHoursList = styled.ul`
  margin: 0;
  padding: 0;
`;
export const OpeningHoursListItem = styled.li``;
